/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { EMPTY_STRING } from '../../const';
import Button from '../Button';
import useStyles from './styles';

const LinkDirection = ({
  latitude,
  longitude,
  name,
  icon: IconComponent,
  children,
  isInline,
  iconColor = '#0068B2',
  existingUrl,
  sourceLocation,
  ...rest
}) => {
  const styles = useStyles();

  return (
    <Fragment>
      <Button.Link
        css={styles.link(false, isInline)}
        to={
          existingUrl
            ? existingUrl
            : `https://bing.com/maps/default.aspx?rtp=pos${
                sourceLocation
                  ? '.' +
                    sourceLocation.latitude +
                    '_' +
                    sourceLocation.longitude
                  : EMPTY_STRING
              }~pos.${latitude}_${longitude}_${encodeURI(name)}~`
        }
        isExternal
        {...rest}
      >
        {IconComponent && <IconComponent color={iconColor} />}
        <span css={styles.directionSpace}>{children}</span>
      </Button.Link>
      <Button.Link
        css={styles.linkMobile}
        to={`geo:${latitude},${longitude}`}
        isExternal
        {...rest}
      >
        {IconComponent && <IconComponent />}
        <span css={styles.directionSpace}>{children}</span>
      </Button.Link>
    </Fragment>
  );
};

LinkDirection.prototype = {
  latitude: PropTypes.number,
  longitude: PropTypes.number,
  name: PropTypes.string,
  IconComponent: PropTypes.func,
  children: PropTypes.object,
  isInline: PropTypes.bool,
  iconColor: PropTypes.string,
  existingUrl: PropTypes.string,
  sourceLocation: PropTypes.object,
};

export default LinkDirection;
