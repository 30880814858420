import {
  DISABILITY,
  EMPTY_STRING,
  ONBOARDING_PREFERENCES,
  YES,
} from '../../const';
import useLocalStorage from '../useLocalStorage';

// return folder name based age criteria
export default selectedAge => {
  let ageCriteria = EMPTY_STRING;
  const { getLocalItem } = useLocalStorage();
  const preferencesObj = JSON.parse(getLocalItem(ONBOARDING_PREFERENCES)) || {};
  const { Age, SelectedFootballType, Disabilityoption } = preferencesObj;
  const age = selectedAge ? parseInt(selectedAge) : Age;

  if (age >= 5 && age <= 10) {
    ageCriteria = `/5-10`;
  } else if (age >= 11 && age <= 17) {
    ageCriteria = `/11-17`;
  } else if (age >= 18 && age <= 30) {
    ageCriteria = `/18-30`;
  } else if (age >= 31 && age <= 50) {
    ageCriteria = `/31-50`;
  } else if (age > 50) {
    ageCriteria = `/51`;
  }
  
  return selectedAge
    ? ageCriteria
    : `${SelectedFootballType}${
        Disabilityoption === YES ? `/${DISABILITY}` : ageCriteria
      }`;
};
