import {
    AGE_ID,
    DISABILITY_OPTION_ID,
    DISABILITY_TYPE_ID,
    FOOTBALL_TYPE_ID,
    LATITUDE_ID,
    LONGITUDE_ID,
    PAGE_NUMBER,
    PAGE_NUMBER_VALUE,
    PAGE_SIZE,
    PLAY_WITH_ID,
    VENUE_PAGE_SIZE_VALUE,
    WEEKDAYS_ID,
  } from '../../const';
  
  export default {
    [AGE_ID]: 0,
    [PLAY_WITH_ID]: 0,
    [FOOTBALL_TYPE_ID]: null,
    [WEEKDAYS_ID]: '',
    [DISABILITY_OPTION_ID]: 0,
    [DISABILITY_TYPE_ID]: [],
    [LATITUDE_ID]: null,
    [LONGITUDE_ID]: null,
    [PAGE_NUMBER]: PAGE_NUMBER_VALUE,
    [PAGE_SIZE]: VENUE_PAGE_SIZE_VALUE,
  };
  