/** @jsxImportSource @emotion/react */
import useStyles from './styles';

const ToggleSwitch = ({
  children,
  isChecked,
  value,
  name,
  onChange,
  switchId,
  tabIndex,
}) => {
  const styles = useStyles();

  return (
    <label css={[styles.switch, styles.hiddenInputs]} tabIndex={tabIndex}>
      <input
        id={switchId}
        type="checkbox"
        aria-checked={isChecked}
        name={name}
        tabIndex={tabIndex}
        checked={isChecked}
        onChange={e => onChange(e.target.checked)}
        value={value}
      />
      {children}
      <span css={styles.slider(isChecked)} tabIndex={tabIndex}></span>
    </label>
  );
};

export default ToggleSwitch;
