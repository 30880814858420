import { SURFACE_VALUES } from '../../const';

const strToArray = str => str.split(',');

const arraysMatched = (base, comparison) =>
  JSON.stringify(base) === JSON.stringify(comparison);

export default surfaceString => {
  if (!surfaceString) return 'Any';

  // sort surfaceArray based on surface
  const surfaceArray = strToArray(surfaceString).sort(function (a, b) {
    return SURFACE_VALUES.indexOf(a) - SURFACE_VALUES.indexOf(b);
  });

  if (surfaceArray.length === 1) {
    return surfaceArray[0];
  }
  if (arraysMatched(surfaceArray, SURFACE_VALUES)) {
    return 'Any';
  }
  if (surfaceArray.length < SURFACE_VALUES.length) {
    return `${surfaceArray[0]} + ${surfaceArray.length - 1}`;
  }
};
