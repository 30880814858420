/** @jsxImportSource @emotion/react */
import '@reach/slider/styles.css';

import {
  SliderHandle,
  SliderInput,
  SliderRange,
  SliderTrack,
} from '@reach/slider';
import { useState } from 'react';

import { EMPTY_STRING, MAX_TEXT } from '../../const';
import useStyles from './styles';

const Slider = ({
  onChange,
  currentValue,
  valueFormatter,
  min,
  max,
  step,
  labelMin,
  labelMax,
  hasSuffix,
  defaultValue,
  analyticsId,
}) => {
  const styles = useStyles();
  const currentVal = currentValue >= 0 ? currentValue : defaultValue;
  const [sliderValue, setSliderValue] = useState(currentVal);
  const hasLabels = labelMin && labelMax;
  let value = valueFormatter
    ? valueFormatter(sliderValue, { max })
    : sliderValue;

  // if value contains max string, remove it
  value =
    value.indexOf(MAX_TEXT) > -1
      ? value.replace(MAX_TEXT, EMPTY_STRING)
      : value;

  return (
    <div css={styles.slider({ hasLabels, hasSuffix })}>
      {labelMin && <div>{labelMin}</div>}
      <SliderInput
        min={min}
        max={max}
        step={step}
        value={sliderValue}
        onChange={value => {
          setSliderValue(value);
          onChange(value);
        }}
      >
        <SliderTrack>
          <SliderRange />
          <SliderHandle>
            {hasSuffix && (
              <span css={styles.suffix} id={analyticsId}>
                {value}
              </span>
            )}
          </SliderHandle>
        </SliderTrack>
      </SliderInput>
      {labelMax && <div>{labelMax}</div>}
    </div>
  );
};

export default Slider;
