import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { spacing, colors } = useTheme();

  return {
    card: css`
      position: relative;
      &:hover {
        svg {
          transition: 0.4s ease-in;
          transform: translateX(50%);
        }
    `,
    description: css`
      position: relative;
      padding-top: ${spacing.xs};
      margin-top: ${spacing.xs};
      letter-spacing: 0.02rem;
      margin-bottom: ${spacing.xs};
      color: ${colors.navy};
    `,
    infoSection: css`
      label {
        display: block;
      }
    `,
    overlay: css`
      position: relative;
      display: block;
    `,
    imgSection: css`
      width: 100%;
      height: auto;
      position: relative;
      display: block;
    `,
    learnMore: css`
      padding-top: ${spacing.xs};
      color: ${colors.blue};
      cursor: pointer;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      padding: 5px 5px 5px 0;
      font-size: 14px;
      font-weight: normal;
      display: inline-block;
      span {
        padding-right: 5px;
      }

      &:focus {
        outline: 0;
        text-decoration: underline;
        color: ${colors.navy};
        svg {
          color: ${colors.navy};
        }
      }
    `,
    rightArrow: css`
      vertical-align: middle;
      color: ${colors.red};
      margin-left: 0.3125rem;
    `,
  };
};
