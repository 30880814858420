/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import PropTypes from 'prop-types';
import { useEffect, useLayoutEffect, useRef } from 'react';

import { onBoardingModalAnalytics } from '../../analyticsConsts';
import { useOnboardingDataContext } from '../../context/OnboardingData';
import Button from '../Button';
import Text from '../Text';
import useStyles from './styles';

const { ONBOARDING_CTA_NO, ONBOARDING_CTA_YES } = onBoardingModalAnalytics;

const ConfirmationQuestion = ({ onClickYes, onClickNo }) => {
  const titleRef = useRef();
  const styles = useStyles();
  const { OnboardingContext, setOnboardingDataContext } =
    useOnboardingDataContext();

  useLayoutEffect(() => {
    setTimeout(() => {
      titleRef.current?.focus({ preventScroll: true });
    }, 200);
  }, []);

  useEffect(() => {
    setOnboardingDataContext({
      ...OnboardingContext,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div css={styles.confirmation}>
      <div>
        <Text as="h1" size="1" css={styles.title} ref={titleRef} tabIndex={-1}>
          {`Are you sure you want to quit?`}
        </Text>
        <Text
          css={styles.helpText}
        >{`If you quit your progress will be lost`}</Text>
      </div>
      <ul css={styles.options}>
        <li>
          <Button
            id={ONBOARDING_CTA_YES}
            onClick={() => onClickYes()}
          >{`Yes`}</Button>
        </li>
        <li>
          <Button
            id={ONBOARDING_CTA_NO}
            onClick={() => onClickNo()}
          >{`No`}</Button>
        </li>
      </ul>
    </div>
  );
};

ConfirmationQuestion.prototype = {
  onClickNo: PropTypes.func,
  onClickYes: PropTypes.func,
};

export default ConfirmationQuestion;
