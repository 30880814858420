import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, spacing, colors, mediaQueries, borderRadius } =
    useTheme();

  return {
    base: css`
      overflow-x: auto;
      -webkit-overflow-scrolling: auto;
      padding-bottom: 1.25rem;
    `,
    tabs: tabsCount => css`
      display: grid;
      grid-template-columns: repeat(6, 1fr);
      grid-gap: ${spacing.xs};

      ${breakpoints.l} {
        align-items: center;
        justify-content: center;
        grid-gap: ${spacing.s};
        line-height: 18px;
        letter-spacing: 0.02em;
      }
    `,
    tab: isSelected => css`
      color: ${colors.navy};
      border: 1px solid ${colors.blue};
      white-space: nowrap;
      padding: ${spacing.xs} ${spacing.s};
      background-color: transparent;

      &:hover,
      &:not(:disabled):hover {
        background-color: ${colors.lightBlue};
        color: ${colors.navy};
        border: 1px solid ${colors.blue};
      }

      &:focus-visible {
        background-color: transparent;
        outline: ${borderRadius.s} solid ${colors.navy};
        outline-offset: -2px;
        color: ${colors.navy};
        text-decoration: none;
      }

      ${mediaQueries.safariBrowser} {
        @supports (-webkit-appearance: none) {
          &:focus {
            background-color: transparent;
            border: 1px solid ${colors.blue};
            color: ${colors.navy};
            text-decoration: none;
          }
        }
      }

      ${isSelected &&
      css`
        color: ${colors.white};
        background-color: ${colors.blue};

        &:hover,
        &:not(:disabled):hover {
          background-color: ${colors.blue};
          color: ${colors.white};
          border: 1px solid ${colors.blue};
        }

        &:not(:hover):focus-visible {
          color: ${colors.navy};
          svg {
            color: ${colors.navy};
          }
        }

        // for safari browser
        ${mediaQueries.safariBrowser} {
          @supports (-webkit-appearance: none) {
            &:not(:hover):focus {
              color: ${colors.navy};
            }
          }
        }
      `};

      ${breakpoints.l} {
        padding: 0.5rem 2.1875rem;
      }
    `,
  };
};
