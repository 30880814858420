import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { borderRadius, typography, breakpoints, spacing, colors } = useTheme();

  return {
    base: css`
      height: 100%;
      display: grid;
      grid-template-rows: auto auto auto;
      grid-template-columns: 100%;
    `,
    hero: css`
      background-position: center ${spacing.m};

      &::before {
        top: 11vh;

        ${breakpoints.m} {
          top: 15vh;
        }

        ${breakpoints.xl} {
          top: 0;
        }
      }

      h1 {
        ${typography.headings[4].mobile};
        max-width: 90%;
        font-size: 2.625rem;

        ${breakpoints.m} {
          ${typography.headings[3].desktop};
          max-width: 70%;
          margin: auto;
        }
      }

      grid-template-rows: 85% auto;
      align-self: center;

      ${breakpoints.m} {
        grid-template-rows: 70% 50%;
        align-self: self-end;
      }

      ${breakpoints.xl} {
        grid-template-rows: 55% 50%;
        h1 {
          max-width: 50%;
        }
      }
    `,
    wrapper: css`
      width: 100vw;
      max-width: 100%;
      margin: calc(100vh - 18vh) auto 0 auto;
      background-color: ${colors.white};
      position: relative;

      ${breakpoints.m} {
        width: 100vw;
        max-width: 100%;
        margin: calc(100vh - 10vh) auto ${spacing.m};
        border-radius: ${borderRadius.xxl};
      }

      ${breakpoints.xl} {
        max-width: 1196px;
        margin-top: 68vh;
        margin: calc(100vh - 25vh) auto ${spacing.m};
      }
    `,
    venueListContainer: css`
      margin-top: 0.0625rem;
      margin: 0 0 0 ${spacing.m};
      position: relative;
      overflow: hidden;

      ${breakpoints.m} {
        margin: 0 ${spacing.m};
      }

      ${breakpoints.xl} {
        margin: 0 6rem;
      }
    `,
    loader: css`
      padding: ${spacing.m};
    `,
    noResultWrapper: css`
      margin-bottom: ${spacing.m};

      ${breakpoints.m} {
        max-width: 992px;
        width: 90vw;
        margin: 0 auto ${spacing.m};
      }

      ${breakpoints.l} {
        max-width: 1196px;
        margin: 0 auto ${spacing.m};
      }
    `,
    venueRecommendWrapper: css`
      margin-top: ${spacing.m};
    `,
  };
};
