/** @jsxImportSource @emotion/react */
import {
  Fragment,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Redirect } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

import { recommendationsMapPageAnalytics } from '../../analyticsConsts';
import {
  CLUB,
  EMPTY_STRING,
  KICKABOUT_CASUAL,
  MINIONBOARDING_TABS,
  MO_PAGE_SIZE_VALUE,
  PAGE_NUMBER,
  SELECTED_FOOTBALL_TYPE_ID,
} from '../../const';
import { useMORecommendations } from '../../context/moRecommendations';
import { usePreferencesContext } from '../../context/preferences';
import useBodyClass from '../../hooks/useBodyClass';
import useClubVenueCoordination from '../../hooks/useClubVenueCoordination';
import useErrorBoundary from '../../hooks/useErrorBoundary';
import useMediaQuery from '../../hooks/useMediaQuery';
import usePageLoadInfo from '../../hooks/usePageLoadInfo';
import trayStateReducer, {
  EXPAND_ACTION,
  EXPAND_MAP_ACTION,
  SHRINK_ACTION,
  STANDARD,
} from '../../reducers/trayState';
import { getMiniOnboardingRecommendations } from '../../services';
import normaliseRecommendations from '../../utils/normaliseRecommendations';
import AlertHiddenRegion from '../AlertHiddenRegion';
import Button from '../Button';
import { RightArrow } from '../Icons';
import LinkTabs from '../LinkTabs';
import Loader from '../Loader';
import { normalisedCenterData } from '../MiniOnboardingDetailPage';
import MOProviderCenterCard from '../MOProviderCenterCard';
import MOProviderClubCard from '../MOProviderClubCard';
import MORecommendationList from '../MORecommendationList';
import ProvidersMap from '../ProvidersMap';
import useStyles from './styles';

const { MAP_CTA_LOAD_MORE_RECOMMENDATIONS, RECOMMENDATIONS_RESULT_ON_MAP } =
  recommendationsMapPageAnalytics;

const CardMap = {
  [KICKABOUT_CASUAL]: MOProviderCenterCard,
  [CLUB]: MOProviderClubCard,
};

const SHOWN_INCREMENT_CENTRE = 10;
const SHOWN_INCREMENT_CLUB = 5;

const MOMapPage = () => {
  useBodyClass('mapPage', '/results/map');

  const styles = useStyles();
  const throwError = useErrorBoundary();
  const { preferences } = usePreferencesContext();
  const [selectedType, setSelectedType] = useState('MAPVIEW');
  const { [SELECTED_FOOTBALL_TYPE_ID]: selectedFootballType } =
    preferences || {};
  const isDesktop = useMediaQuery('screen and (min-width: 768px)');

  const {
    clubMORecommendations,
    casualMORecommendations,
    addMORecommendations,
  } = useMORecommendations();
  // we pick the general type of recommendations the user has set (e.g. Club)
  const recommendationsGroup =
    selectedFootballType === CLUB
      ? clubMORecommendations
      : casualMORecommendations;
  // we extract the specific football type from the recommendations group (e.g. Futsal)
  const {
    TotalClubsRecords,
    TotalCentresRecords,
    RecommendationClubCartDto,
    RecommendationCentreCartDto,
  } = recommendationsGroup;
  // here we try to read both values, since only one will be populated for a specific football type
  const totalRecommendations = TotalClubsRecords || TotalCentresRecords;
  const recommendations = useMemo(() => {
    if (!RecommendationClubCartDto && !RecommendationCentreCartDto) {
      return null;
    }
    let normalisedData =
      recommendationsGroup?.RecommendationClubCartDto ||
      recommendationsGroup?.RecommendationCentreCartDto;

    if (selectedFootballType !== CLUB) {
      const checkData =
        recommendationsGroup?.RecommendationCentreCartDto &&
        recommendationsGroup?.RecommendationProviderCartDto
          ? recommendationsGroup?.RecommendationCentreCartDto.concat(
              recommendationsGroup?.RecommendationProviderCartDto
            )
          : recommendationsGroup?.RecommendationCentreCartDto &&
            !recommendationsGroup?.RecommendationProviderCartDto
          ? recommendations?.RecommendationCentreCartDto
          : !recommendationsGroup?.RecommendationCentreCartDto &&
            recommendationsGroup?.RecommendationProviderCartDto &&
            recommendations?.RecommendationProviderCartDto;
      normalisedData = normalisedCenterData(checkData);
    }
    // we need to normalise the recommendations to make sure we offset
    // pins that are at the same location
    // sorting data based on distance
    return normaliseRecommendations(normalisedData)?.sort(
      (currentData, nextData) =>
        currentData.DistanceFromUserLocation > nextData.DistanceFromUserLocation
          ? 1
          : currentData.DistanceFromUserLocation <
            nextData.DistanceFromUserLocation
          ? -1
          : 0
    );
  }, [
    RecommendationClubCartDto,
    RecommendationCentreCartDto,
    selectedFootballType,
    recommendationsGroup,
  ]);

  const [trayState, dispatch] = useReducer(trayStateReducer, STANDARD);
  const handlers = useSwipeable({
    onSwipedUp: () => dispatch(EXPAND_ACTION),
    onSwipedDown: () => dispatch(SHRINK_ACTION),
    preventDefaultTouchmoveEvent: true,
  });

  const [numberShown, setNumberShown] = useState(
    preferences['FootballType'] === 1
      ? SHOWN_INCREMENT_CENTRE
      : SHOWN_INCREMENT_CLUB
  );
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const loadingRef = useRef();

  // We keep track of what index the next card will be once we load more cards
  const [nextLoadedFirstIndex, setNextLoadedFirstIndex] = useState();
  const firstOfNewLoadedRef = useRef();

  const [selectedId, setSelectedId] = useState();
  const [isListViewSection, setIsListViewSection] = useState(true);
  const footballTitle = selectedFootballType === CLUB ? 'clubs' : 'venues';

  const shownRecommendations = useMemo(
    () => recommendations?.slice(0, numberShown),
    [numberShown, recommendations]
  );

  const pageTitle = `${selectedFootballType === CLUB ? 'Club' : 'Casual'}`;
  const selectedFootballID =
    selectedFootballType === CLUB ? 'ClubId' : 'PitchFinderId';

  const selectedCards = useMemo(
    () =>
      shownRecommendations?.filter(data => {
        return data[selectedFootballID] === Number(selectedId);
      })[0],
    [shownRecommendations, selectedId, selectedFootballID]
  );

  const sourceLocation = useClubVenueCoordination(
    selectedFootballType,
    preferences
  );

  //setting the list view when ID is selected
  useEffect(() => {
    if (!selectedId) {
      return;
    }
    setIsListViewSection(false);
  }, [selectedId]);

  // set the page title.
  usePageLoadInfo('map', pageTitle);

  // If the number of cards shown changes we want to
  // move the focus to the first new card
  useLayoutEffect(() => {
    setTimeout(() => firstOfNewLoadedRef.current?.focus(), 200);
  }, [numberShown]);

  // set path information for redirect
  useEffect(() => {
    localStorage.setItem('pathInfomation', window.location.pathname);
  }, []);

  // If the user loads more recommendations we want to move the focus to
  // the loading element
  useLayoutEffect(() => {
    setTimeout(() => loadingRef.current?.focus(), 200);
  }, [isLoadingMore]);

  if (!selectedFootballType) {
    return <Redirect to="/" />;
  }

  const backToList = () => {
    setSelectedId();
    setIsListViewSection(true);
  };

  const Card = CardMap[selectedFootballType];

  const showNext = () => {
    setNextLoadedFirstIndex(numberShown + 1);
    setNumberShown(
      numberShown +
        (preferences['FootballType'] === 1
          ? SHOWN_INCREMENT_CENTRE
          : SHOWN_INCREMENT_CLUB)
    );
  };

  const getMoreResults = () => {
    if (!recommendations) {
      return;
    }

    // if we already have more than the current shown amount
    // show the next 10
    if (recommendations.length > numberShown) {
      showNext();
      return;
    }

    setIsLoadingMore(true);
    const sortedData = [];
    getMiniOnboardingRecommendations(selectedFootballType, {
      ...preferences,
      // get the page after the current one
      [PAGE_NUMBER]:
        preferences['FootballType'] === 1
          ? preferences.PageNumber + 1
          : Math.floor(recommendations?.length / MO_PAGE_SIZE_VALUE) + 1,
    })
      .then(({ data }) => {
        if (data) {
          sortedData.push(data);
          addMORecommendations(sortedData[0], selectedFootballType);
        }
        if (preferences['FootballType'] === 1) {
          preferences[PAGE_NUMBER] = preferences.PageNumber + 1;
        }
        setIsLoadingMore(false);
        showNext();
      })
      .catch(throwError);
  };

  if (!recommendations) {
    return <p>No recommendations match the current filters</p>;
  }

  // We announce the new number of cards when it changes
  const ariaAnnouncement = `Showing ${numberShown} recommendations`;

  return (
    <Fragment>
      <AlertHiddenRegion text={ariaAnnouncement} />
      <div css={styles.base}>
        <div css={styles.header}>
          <LinkTabs
            onClick={setSelectedType}
            selectedType={selectedType}
            tabs={MINIONBOARDING_TABS}
            css={styles.tabs}
            isMapPage={true}
          />
        </div>
        <div css={styles.mapAreaWrapper}>
          <ProvidersMap
            data={shownRecommendations}
            handleSelect={setSelectedId}
            momappage={true}
            selectedId={selectedId}
            userLocation={{
              latitude: preferences.Latitude,
              longitude: preferences.Longitude,
            }}
            handleMapMovement={() => dispatch(EXPAND_MAP_ACTION)}
          />
        </div>
        <div
          css={styles.providerCardWrapper(isListViewSection, trayState)}
          id={RECOMMENDATIONS_RESULT_ON_MAP}
        >
          {!isListViewSection && (
            <div css={styles.backToWrapper} tabIndex={0} role="button">
              <Button onClick={backToList} css={styles.primaryBtn}>
                <RightArrow css={styles.arrowRotate} /> Back to {footballTitle}{' '}
                list
              </Button>
            </div>
          )}
          {isListViewSection && (
            <div {...handlers} css={styles.totalResults(isListViewSection)}>
              <span>{`${totalRecommendations} Result${
                totalRecommendations > 1 ? 's' : ''
              }`}</span>
            </div>
          )}
          <div css={styles.scrollContainer}>
            <ul css={styles.providerCards(isListViewSection)}>
              {shownRecommendations.length > 0 &&
                shownRecommendations.map((providerData, index) => {
                  const id = providerData.ClubId || providerData.PitchFinderId;
                  const isFirstOfNewLoaded = index === nextLoadedFirstIndex - 1;

                  return (
                    <Card
                      index={index}
                      onClick={() => {
                        setSelectedId(id);
                        setIsListViewSection(false);
                      }}
                      isSelected={selectedId === id}
                      key={id}
                      ref={isFirstOfNewLoaded ? firstOfNewLoadedRef : null}
                      isMiniOnboarding={true}
                      {...providerData}
                    />
                  );
                })}
              {isLoadingMore ? (
                <Loader
                  inline
                  message={`Loading more ${footballTitle}`}
                  css={styles.loader}
                  ref={loadingRef}
                />
              ) : totalRecommendations &&
                recommendations?.length < totalRecommendations ? (
                <li css={styles.loadMore(isListViewSection)}>
                  <Button
                    id={MAP_CTA_LOAD_MORE_RECOMMENDATIONS}
                    onClick={getMoreResults}
                    primary
                  >
                    Load more
                  </Button>
                </li>
              ) : (
                EMPTY_STRING
              )}
            </ul>
            {!isListViewSection && selectedCards && (
              <ul
                css={styles.recommendationListWrapper(
                  selectedCards?.TeamsCartDto?.length
                )}
              >
                <MORecommendationList
                  data={selectedCards}
                  isDesktop={isDesktop}
                  selectedFootballType={selectedFootballType}
                  isMapPage={true}
                  sourceLocation={sourceLocation}
                ></MORecommendationList>
              </ul>
            )}
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MOMapPage;
