/** @jsxImportSource @emotion/react */
import { Fragment, useEffect, useMemo } from 'react';

import { moRecommendationsPageAnalytics } from '../../analyticsConsts';
import {
  BOYS_OR_MEN_MIXED,
  DAY_PREFERENCE_DATA,
  EMPTY_STRING,
  GIRLS_OR_WOMEN_MIXED,
  LOCATION_DATA,
  MIXED_VALUE,
  YES,
} from '../../const';
import { useFilterPanelToggleContext } from '../../context/filterPanelToggle';
import { useNestedFilterPanelContext } from '../../context/nestedFilterPanel';
import weekdaysFormatter from '../../formatters/weekdays';
import useAgeTitle from '../../hooks/useAgeTitle';
import useDisabilityTitle from '../../hooks/useDisabilityTitle';
import ExternalLink from '../ExternalLink';
import filtersConfig from '../FiltersPanel/filtersConfig';
import LinkButton from '../LinkButton';
import { getRedirectUrl } from '../OnboardingModal/MiniOnboarding';
import Text from '../Text';
import useStyles from './styles';

const {
  MO_RECOMMENDED_FOOTBALL_ONBOARDING_CTA_LEARN_MORE,
  MO_RECOMMENDED_FOOTBALL_ONBOARDING_WRAPPER,
  MO_RECOMMENDED_FOOTBALL_ONBOARDING_DAY_PREFERENCE,
  MO_RECOMMENDED_FOOTBALL_ONBOARDING_LOCATION,
} = moRecommendationsPageAnalytics;

const MOOnboardingCriteria = ({ linkText, preferences, MOPreferences }) => {
  const styles = useStyles();
  const { isFilterPanelToggled, setFilterPanelToggle, isFilterEnabled } =
    useFilterPanelToggleContext();
  const { setSelectedFilters, setNested } = useNestedFilterPanelContext();
  const {
    ReadableLocation,
    SelectedFootballType,
    PlayWith,
    Disabilityoption,
    WeekDays,
  } = preferences;
  const ageTitle = useAgeTitle();
  const disabilityTitle = useDisabilityTitle();
  const { category, age } = MOPreferences;
  const externalUrl = useMemo(() => getRedirectUrl(), []);

  useEffect(() => {
    let ahref = document.querySelectorAll('a');
    let buttonselector = document.querySelectorAll('button');
    let listselector = document.querySelectorAll('li');
    let imageselector = document.querySelectorAll('img');

    const filteropen = [
      ...ahref,
      ...buttonselector,
      ...listselector,
      ...imageselector,
    ].filter(datafilter => {
      return datafilter.id.includes('filters_');
    });

    const filterclosed = [
      ...ahref,
      ...buttonselector,
      ...listselector,
      ...imageselector,
    ].filter(datafilter => {
      return !datafilter.id.includes('filters_');
    });

    filterclosed.forEach((btn, index) => {
      // set first button tabindex to 0
      // and set every other button tabindex to -1

      if (isFilterEnabled === false) {
        btn.setAttribute('tabindex', 0);
      }
      if (isFilterEnabled === true) {
        filteropen.forEach((filterbtn, index) => {
          filterbtn.setAttribute('tabindex', 0);
          btn.setAttribute('tabindex', -1);
        });
      }
    });
  }, [isFilterEnabled]);

  return (
    <div
      css={styles.OnboardingWrapper}
      id={MO_RECOMMENDED_FOOTBALL_ONBOARDING_WRAPPER}
    >
      {preferences && Object.keys(preferences).length !== 0 && (
        <Fragment>
          <Text as="h2" css={styles.link}>
            Showing {category} based on {age} {ageTitle}{' '}
            {(PlayWith === BOYS_OR_MEN_MIXED ||
              PlayWith === GIRLS_OR_WOMEN_MIXED) &&
              `or ${MIXED_VALUE} `}
            football,
            {Disabilityoption === YES && ` catering for ${disabilityTitle},`} on
            <LinkButton
              css={[styles.link, styles.daysLink]}
              id={MO_RECOMMENDED_FOOTBALL_ONBOARDING_DAY_PREFERENCE}
              aria-label={`${weekdaysFormatter(
                WeekDays
              )} - click to open day preference filter`}
              onClick={() => {
                // set day preference data
                setSelectedFilters(
                  filtersConfig[SelectedFootballType][DAY_PREFERENCE_DATA]
                );
                setNested(true);
                setFilterPanelToggle(!isFilterPanelToggled);
              }}
            >
              {weekdaysFormatter(WeekDays)}
            </LinkButton>
            {weekdaysFormatter(WeekDays).toString().toLowerCase() === 'any'
              ? 'day '
              : EMPTY_STRING}
            near
            <LinkButton
              css={styles.link}
              aria-label={`${ReadableLocation} - click to open location filter`}
              id={MO_RECOMMENDED_FOOTBALL_ONBOARDING_LOCATION}
              onClick={() => {
                // set location data
                setSelectedFilters(
                  filtersConfig[SelectedFootballType][LOCATION_DATA]
                );
                setNested(true);
                setFilterPanelToggle(!isFilterPanelToggled);
              }}
            >
              {/* Empty value will show "My location" */}
              {ReadableLocation ? ReadableLocation : 'My location'}
            </LinkButton>
            .
          </Text>
          <div css={styles.linkWrapper}>
            <ExternalLink
              css={styles.externalLink}
              id={MO_RECOMMENDED_FOOTBALL_ONBOARDING_CTA_LEARN_MORE}
              href={externalUrl}
              aria-label={linkText}
            >
              {linkText}
            </ExternalLink>
          </div>
        </Fragment>
      )}
    </div>
  );
};

export default MOOnboardingCriteria;
