/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from 'react';

import {
  BOYS_OR_MEN,
  BOYS_OR_MEN_MIXED,
  EMPTY_STRING,
  GIRLS_OR_WOMEN,
  GIRLS_OR_WOMEN_MIXED,
} from '../../const';
import ToggleSwitch from '../ToggleSwitch';
import useStyles from './styles';

const DELAY = 0;

const MixedFilterItem = ({
  analyticsId,
  label,
  valueFormatter,
  config,
  currentValue,
  onClick,
  isOpen,
}) => {
  const styles = useStyles();

  // If filter has formatter, apply to filter value
  const value =
    typeof valueFormatter === 'function'
      ? valueFormatter(currentValue, config)
      : currentValue;

  const selectedValue =
    currentValue === BOYS_OR_MEN_MIXED || currentValue === GIRLS_OR_WOMEN_MIXED;

  const [isMixed, setIsMixed] = useState(selectedValue);

  // if any changes in mixed selection
  useEffect(() => {
    let timeout;
    if (value) {
      timeout = setTimeout(() => {
        setIsMixed(selectedValue);
      }, DELAY);
    }

    return () => {
      clearTimeout(timeout);
    };
  }, [value, selectedValue]);

  // set gender value
  const setMixedValue = useCallback(isMixed => {
    let value = EMPTY_STRING;
    switch (currentValue) {
      case 1:
      case 4:
        value = isMixed ? BOYS_OR_MEN_MIXED : BOYS_OR_MEN;
        break;
      default:
        value = isMixed ? GIRLS_OR_WOMEN_MIXED : GIRLS_OR_WOMEN;
        break;
    }
    return value;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <button
      onClick={() => onClick(setMixedValue(!isMixed))}
      css={styles.filterItem}
      tabIndex={isOpen ? 0 : -1}
      htmlFor={analyticsId}
    >
      <span
        id={`label-${analyticsId}`}
        css={styles.label}
        tabIndex={isOpen ? 0 : -1}
      >
        {label}
      </span>
      <span css={styles.toggleCheckbox}>
        <ToggleSwitch
          switchId={analyticsId}
          name={`${analyticsId}-optional`}
          isChecked={isMixed}
          value={value}
          tabIndex={isOpen ? 0 : -1}
          onChange={value => {
            setIsMixed(value);
          }}
        ></ToggleSwitch>
      </span>
    </button>
  );
};

export default MixedFilterItem;
