import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, colors } = useTheme();

  return {
    card: css`
      height: 100%;
      display: grid;
      padding: 0 1rem;
      grid-template-rows: 2fr 1fr;
      ${breakpoints.l} {
        padding: 0 0.625rem;
        grid-template-columns: 1fr;
        grid-template-rows: 100%;
      }
      position: relative;
      &:hover,
      &:focus {
        cursor: pointer;
      }
    `,
    description: css`
      color: ${colors.white};
      padding-top: 1.5rem;
      letter-spacing: 0.02rem;
      line-height: 22px;
      position: relative;
      bottom: -10.45rem;
      margin-bottom: 10px;
      width: 100%;
      text-align: center;

      ${breakpoints.l} {
        bottom: -12.45rem;
      }
      p :: after {
        text-decoration: none;
      }
    `,
    imgSection: ({ images }, indexVal) => css`
      width: 100%;
      height: 100%;
      min-height: 10.75rem;
      background: url(${images.mobile.src}) center center no-repeat;
      background-size: cover;
      position: relative;
      display: block;
      text-decoration: none;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background-image: url(${`/images/desktop/home/crops/crop-${indexVal}.svg`});
        background-position: bottom right;
        background-repeat: no-repeat;
      }

      ${breakpoints.l} {
        background: url(${images.desktop.src}) center center no-repeat;
        background-size: cover;
      }
      &:hover,
      &:focus {
        outline: 0;
        p {
          text-decoration: underline;
        }
        &::after {
          background-image: linear-gradient(
            180deg,
            rgba(47, 46, 46, 0.64) 0%,
            rgba(0, 79, 158, 0.64) 100%
          );
          top: 0;
          z-index: 1;
        }
      }
    `,
  };
};
