/** @jsxImportSource @emotion/react */
import { Fragment, useState } from 'react';

import getNextQuestionId from '../../utils/getNextQuestionId';
import NextNav from '../NextNav';
import Slider from '../Slider';
import Text from '../Text';
import useStyles from './styles';

const SliderQuestion = ({
  readableId,
  analyticsId,
  config,
  ageRanges,
  onAnswer,
}) => {
  const styles = useStyles();
  const [age, setAge] = useState(config.min);

  return (
    <Fragment>
      <Text as="div" id={analyticsId} css={styles.ageText}>
        <span>{age}</span> <span>years old</span>
      </Text>
      <Slider {...config} onChange={setAge} />
      <NextNav
        onClick={() => {
          onAnswer({
            answerData: { [readableId]: age },
            nextQuestionId: getNextQuestionId({ ageRanges, selectedAge: age }),
          });
        }}
      />
    </Fragment>
  );
};

export default SliderQuestion;
