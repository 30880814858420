/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';

import { socialMediaAnalytics } from '../../analyticsConsts';
import { EMPTY_STRING, ICONS_MEDIA } from '../../const';
import ExternalLink from '../ExternalLink';
import useStyles from './styles';

const { SOCIAL_MEDIA_WRAPPER, SOCIAL_MEDIA_CTA } = socialMediaAnalytics;

const SocialMedia = ({ title, socialLinks }) => {
  const styles = useStyles();

  return (
    <Fragment>
      {socialLinks?.isPublic && (
        <div css={styles.footer} id={SOCIAL_MEDIA_WRAPPER}>
          <div css={styles.footerWrapper}>
            <ul css={styles.list}>
              <li>{title} social media</li>
              {socialLinks &&
                socialLinks.socialMediaLinks.length > 0 &&
                socialLinks.socialMediaLinks.slice(0, 3).map(link => {
                  const SocialMediaIcon = ICONS_MEDIA[link.mediaType];
                  return (
                    <li key={link.mediaTypeId}>
                      <ExternalLink
                        id={`${SOCIAL_MEDIA_CTA}_${link.mediaTypeId}`}
                        css={styles.link}
                        className={link.className}
                        href={
                          link.prefixUrl +
                          `${link.mediaLink ? link.mediaLink : EMPTY_STRING}`
                        }
                      >
                        <SocialMediaIcon />
                      </ExternalLink>
                    </li>
                  );
                })}
            </ul>
          </div>
        </div>
      )}
    </Fragment>
  );
};

export default SocialMedia;
