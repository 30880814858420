import ageQuestions from './questions/age';
import disabilityOptionQuestions from './questions/disabilityOption';
import disabilityTypeQuestions from './questions/disabilityType';
import footballTypeQuestions from './questions/footballType';
import locationQuestions from './questions/location';
import playWithQuestions from './questions/playWith';
import postcodeQuestions from './questions/postcode';
import searchForQuestions from './questions/searchFor';
import weekdaysQuestions from './questions/weekdays';

const onboardingQuestions = [
  ...searchForQuestions,
  ...ageQuestions,
  ...playWithQuestions,
  ...footballTypeQuestions,
  ...weekdaysQuestions,
  ...disabilityOptionQuestions,
  ...disabilityTypeQuestions,
  ...locationQuestions,
  ...postcodeQuestions,
];

export default onboardingQuestions;
