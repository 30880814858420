/** @jsxImportSource @emotion/react */
import { Fragment, useState } from 'react';

import MenuContent from '../MenuContent';
import NavMenuFooter from '../NavMenuFooter';
import useStyles from './styles';

const NavMenu = ({
  isMobile,
  isMenuOpened,
  menuList,
  dropDownKeys,
  stylingObjects,
  isHamburgerMenu,
  headerHeight,
}) => {
  const styles = useStyles();
  const [keysForDropdown, setKeysForDropdown] = useState(dropDownKeys);
  const [backMenu, setBackMenu] = useState(false);
  const [isSubMenuClicked, setIsSubMenuClicked] = useState(false);
  const [navbarTitle, setNavbarTitle] = useState({
    title: 'Home',
    hasUrl: false,
  });
  const stylingFooter = stylingObjects?.DestinationLink?.value;

  const handleMouseEnter = e => {
    setKeysForDropdown({
      ...keysForDropdown,
      [e.target.id]: true,
    });
    const selectedMenu = menuList.find(
      entry => entry.id === parseInt(e.target.id)
    );
    if (selectedMenu) {
      setNavbarTitle({
        id: selectedMenu.id,
        title: selectedMenu.title,
        hasUrl: true,
      });
    }
  };

  const handleMouseLeave = e => {
    setNavbarTitle({ title: 'Home', hasUrl: false });
    // set all the values of id as false before set value for selected id
    Object.keys(keysForDropdown).forEach(id => (keysForDropdown[id] = false));
    setKeysForDropdown({
      ...keysForDropdown,
      [e.target.id]: false,
    });
  };

  return (
    <Fragment>
      <nav
        css={styles.siteNavigation(
          isMobile,
          isMenuOpened,
          isHamburgerMenu,
          headerHeight
        )}
        role="navigation"
        id="main-nav"
        tabIndex="-1"
      >
        {isMobile && (
          <div css={styles.menuHeader}>
            {navbarTitle && navbarTitle.hasUrl ? (
              <button
                css={styles.leftArrowBtn(isMobile)}
                aria-label="back to home"
                onClick={e => {
                  handleMouseLeave(e);
                  setBackMenu(true);
                  setIsSubMenuClicked(false);
                }}
                id={navbarTitle.id}
              >
                <span>{navbarTitle.title}</span>
              </button>
            ) : (
              <span>{navbarTitle.title}</span>
            )}
          </div>
        )}
        <div
          css={styles.menuContainer(isSubMenuClicked)}
          className="scroller"
        >
          <ul css={styles.menuWrapper(isMobile, backMenu, headerHeight)}>
            {menuList.length > 0 &&
              menuList.map((menu, index) => (
                <MenuContent
                  {...menu}
                  id={menu.id}
                  key={index}
                  stateKeys={keysForDropdown}
                  handleMouseEnter={data => {
                    handleMouseEnter(data);
                    setBackMenu(false);
                    setIsSubMenuClicked(true);
                  }}
                  handleMouseLeave={handleMouseLeave}
                  isMobile={isMobile}
                  stylingObjects={stylingObjects}
                  headerHeight={headerHeight}
                />
              ))}
          </ul>
        </div>
        {stylingObjects && stylingFooter && isMobile && (
          <NavMenuFooter stylingObjects={stylingObjects} />
        )}
      </nav>
    </Fragment>
  );
};

export default NavMenu;
