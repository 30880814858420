/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from "react";

import { useScrollDirectionContext } from "../../context/scrollDirection";
import { useSetHeaderHeightContext } from "../../context/setHeaderHeight";
import useMediaQuery from '../../hooks/useMediaQuery';
import { throttle } from "./lib/tools";
import useStyles from './styles';

const StickyScrollSpyNav = ({
    nav,
    children,
    onClickNav,
    offset = 0,
    style,
    titleHeight,
    title,
    isHidden = false,
}) => {
    const navEl = useRef(null);
    const [navHeight, setNavHeight] = useState(0);
    const [prevIndex, setPrevIndex] = useState(0);
    const [currentIndex, setCurrentIndex] = useState(0);
    const { scrolldirectionContext } = useScrollDirectionContext();
    const { headerHeight } = useSetHeaderHeightContext();
    const isMobile = useMediaQuery(`screen and (max-width: 992px)`);
    const styles = useStyles();

    const onScroll = throttle((e) => {
        const scrollElement = document.scrollingElement || document.documentElement;
        const outerHeight = window.innerHeight;
        const scrollTop = scrollElement.scrollTop;
        const scrollHeight = scrollElement.scrollHeight;
        const firstEl = children[0]?.ref?.current;

        const isNotStart = firstEl && firstEl.offsetTop > scrollTop;
        const isEnd = scrollTop + outerHeight >= scrollHeight;

        if (isNotStart) {
            setCurrentIndex(0);
        } else if (isEnd) {
            setCurrentIndex(children.length - 1);
        } else {
            children.forEach((el, index) => {
                const target = el?.ref?.current;
                if (target) {
                    const visibleVertical =
                        target.offsetTop >= 0 &&
                        scrollElement.scrollTop + navHeight >= target.offsetTop - offset + titleHeight - 180 &&
                        scrollElement.scrollTop + navHeight < target.offsetTop + target.offsetHeight - offset + titleHeight - 180;

                    if (visibleVertical) {
                        setCurrentIndex(index);
                    }
                }
            });
        }
    });

    const handleClick = (index) => {
        const offsetTop = children[index]?.ref?.current.offsetTop || 0;
        const viewPortHeight = isMobile ? prevIndex < index ? 10 : 80 : prevIndex < index ? 100 : 200;
        const extraSpace = isMobile ? 80 : title?.length > 33 ? 128 : 200;
        setPrevIndex(index);
        if (index !== 0) {
            window.scrollTo({ top: offsetTop - navHeight - offset + (titleHeight - viewPortHeight + extraSpace), behavior: "smooth" });
            return;
        }
        window.scrollTo({ top: offsetTop - navHeight - offset + (titleHeight - viewPortHeight), behavior: "smooth" });
        onClickNav && onClickNav(index);
    };

    useEffect(() => {
        setNavHeight(navEl?.current?.clientHeight || 0);
        window.addEventListener("scroll", onScroll);
        return () => {
            window.removeEventListener("scroll", onScroll);
        };
    }, [onScroll]);

    return (
        <div style={style}>
            <nav
                ref={navEl}
                css={styles.navStyles(scrolldirectionContext, isMobile, offset, headerHeight)}
            >
                {nav.map((navItem, index) => (
                    <button
                        name={navItem.label}
                        onClick={() => handleClick(index)}
                        key={navItem.id}
                        css={[styles.navItemStyle(navItem.isHidden), currentIndex === index && styles.navActiveItemStyle]}
                    >
                        {navItem.label}
                    </button>
                ))}
            </nav>
            {children}
        </div>
    );
}

export default StickyScrollSpyNav;
