import { css } from '@emotion/react';

import { SCROLL_UP } from '../../const';
import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors, heights, borderRadius, breakpoints, spacing } = useTheme();

  return {
    navItemStyle: isHidden => css`
      padding: 16px 0;
      margin: 5px 5px 0;
      font-size: ${spacing.s};
      background: transparent;
      z-index: 1;
      border: 0;
      border-bottom: ${borderRadius.s} solid transparent;
      text-align: center;
      cursor: pointer;
      font-family: 'FS Dillon';
      color: ${colors.darkGrey};

      ${isHidden &&
      css`
        display: none;
      `}

      ${breakpoints.m} {
        margin: 15px 5px 0;
        font-size: 1.25rem;
        font-family: 'FS Dillon';
        font-style: normal;
        color: ${colors.darkGrey};
      }
    `,
    navActiveItemStyle: css`
      font-weight: bold;
      border-bottom: ${borderRadius.s} solid ${colors.red};
      color: ${colors.navy};

      ${breakpoints.m} {
        color: ${colors.navy};
      }
    `,
    navStyles: (scrolldirectionContext, isMobile, offset, headerHeight) => css`
      position: sticky;
      background-color: ${colors.white};
      text-align: center;
      z-index: 1;
      display: flex;
      justify-content: center;
      grid-gap: ${spacing.m};
      border-radius: ${borderRadius.xxl} ${borderRadius.xxl} 0 0;
      top: ${scrolldirectionContext === SCROLL_UP
        ? offset
        : `${isMobile ? heights.mainNav.mobile : headerHeight}`};
      border-bottom: 1px solid ${colors.lightGrey};
      border-radius: ${borderRadius.l} ${borderRadius.l} 0 0;

      ${breakpoints.l} {
        grid-gap: ${spacing.xl};
      }
    `,
  };
};
