import { createContext, useCallback, useContext, useState } from 'react';

const SetContactFormDLContext = createContext();

export const SetContactFormDLProvider = ({ children }) => {
  const [contactFormDL, setContactFormDL] = useState({});

  const setContactFormDLValue = useCallback(value => {
    setContactFormDL(value);
  }, []);

  return (
    <SetContactFormDLContext.Provider
      value={{ contactFormDL, setContactFormDLValue }}
    >
      {children}
    </SetContactFormDLContext.Provider>
  );
};

export const useSetContactFormDLContext = () =>
  useContext(SetContactFormDLContext);
