import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors, spacing } = useTheme();

  return {
    filterWrapper: css`
      padding: ${spacing.m} 0;
    `,
    locationLink: css`
      display: flex;
      padding-top: ${spacing.s};
    `,
    locationBtn: css`
      padding: 0;
      margin-left: ${spacing.xs};
    `,
    ctaWrapper: css`
      border-top: 1px solid ${colors.lightGrey};
      padding-top: ${spacing.m};
    `,
    saveBtn: css`
      text-transform: uppercase;
    `,
  };
};
