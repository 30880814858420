import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

const alternateBackgrounds = (elementIndex, numberOfBackgrounds) => {
  const remainder = elementIndex % numberOfBackgrounds;

  return remainder === 0 ? numberOfBackgrounds : remainder;
};

export default () => {
  const { colors, breakpoints, spacing, typography, borderRadius } = useTheme();

  return {
    card: index => css`
      z-index: 1;
      position: relative;
      padding: ${spacing.m} 1.5rem ${spacing.xl} 1.5rem;
      background-color: ${colors.white};
      border-radius: ${borderRadius.l};
      display: grid;
      grid-template-rows: auto auto;
      margin-bottom: ${spacing.m};
      box-shadow: 0px 4px 6px rgba(91, 104, 133, 0.1);

      &::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* we need a border-radius here because
        the image overrides the parent border radius */
        border-radius: ${borderRadius.l};
        background: left bottom no-repeat;
        background-image: url(${publicPath(
          '/images/mobile/recommendations/crop-' +
            alternateBackgrounds(index, 3) +
            '@x1.png'
        )});
        background-size: 100%;
        z-index: -1;
      }

      ${breakpoints.xl} {
        padding: ${spacing.l} ${spacing.xs} ${spacing.m} ${spacing.l};
        grid-template-columns: 38% minmax(auto, 62%);
        grid-template-rows: 12% auto;
        grid-column-gap: ${spacing.xs};
        min-height: 300px;
        border-radius: ${borderRadius.l};
        grid-row-gap: ${spacing.s};

        &::after {
          outline: 0;
          background: 118% bottom no-repeat;
          background-image: url(${publicPath(
            '/images/desktop/recommendations/card-crop.png'
          )});
          background-size: contain;
        }
      }
    `,
    grid: css`
      display: grid;
      grid-template-rows: minmax(215px, auto) 1fr;
      grid-template-areas:
        'middle'
        'bottom';
    `,
    heading: index => css`
      display: grid;
      align-items: center;
      grid-template-columns: 40px auto;
      padding-bottom: ${spacing.xs};
      position: relative;

      &:after {
        border-bottom: 1px solid ${colors.grey};
        content: '';
        position: absolute;
        left: 0;
        bottom: ${index ? '-15px' : '0'};
        width: 100%;
      }

      ${breakpoints.xl} {
        grid-column: 1 / 3;
        padding-bottom: 0.75rem;

        &:after {
          width: 96%;
        }
      }
    `,
    title: css`
      font-size: 1.75rem;
      color: ${colors.navy};
      padding-left: ${spacing.xs};
      position: relative;
      line-height: 36px;
      font-family: FS Dillon;

      ${breakpoints.xl} {
        font-family: FS Dillon;
        padding-left: ${spacing.s};
      }
    `,
    circle: css`
      display: flex;
      align-items: center;
      justify-content: center;
      ${typography.headings[6].mobile};
      color: ${colors.navy};
      width: 32px;
      height: 32px;
      border-radius: 32px;
      border: ${borderRadius.s} solid ${colors.red};

      ${breakpoints.xl} {
        width: 40px;
        height: 40px;
        border-radius: 40px;
        ${typography.headings[6].desktop};
      }
    `,
    middle: css`
      grid-area: middle;
    `,
    bottom: css`
      grid-area: bottom;
    `,
    image: css`
      width: 100%;
      max-width: 100%;
      height: auto;
      margin: ${spacing.xs} 0;

      ${breakpoints.m} {
        margin: ${spacing.s} 0;
      }
    `,
    link: css`
      color: ${colors.navy};
      font-weight: 400;

      &:focus {
        outline: 0;
        color: ${colors.darkGrey};
      }
    `,
    mapCta: css`
      text-transform: uppercase;
      border: ${borderRadius.s} solid transparent;

      svg {
        margin-left: ${spacing.xs};
      }

      ${breakpoints.xl} {
        width: 80%;
        max-width: 294px;

        &:focus:not(:hover) {
          background-color: transparent;
          outline: 0;
          border: ${borderRadius.s} solid ${colors.white};
          border-radius: ${borderRadius.m};
        }

        &:hover {
          outline: 0;
        }
      }
    `,
    cards: (clubCount, totalRecords) => css`
      display: grid;
      grid-area: middle;
      grid-gap: ${spacing.xs};
      grid-template-columns: repeat(
        ${totalRecords > 10 ? 11 : clubCount},
        minmax(195px, auto)
      );
      align-items: center;
      justify-items: center;
      overflow-x: scroll;
      overflow-y: hidden;
      -webkit-overflow-scrolling: touch;

      ${breakpoints.xl} {
        padding-top: 1.5rem;
        overflow-x: hidden;
        ${clubCount <= 5 &&
        css`
          justify-self: center;
          justify-content: center;
        `}
      }
    `,
    gradientFeather: css`
      position: relative;

      &:after {
        content: '';
        position: absolute;
        right: 0;
        width: 36px;
        height: 90%;
        top: 5%;
        bottom: 0;
        background: linear-gradient(
          270deg,
          ${colors.white} 0%,
          rgba(255, 255, 255, 0) 79.17%
        );
      }
    `,
    moreCards: css`
      display: grid;
      grid-area: bottom;
      align-items: center;
      justify-items: center;
      padding: ${spacing.xs} 0 ${spacing.m} 0;

      ${breakpoints.m} {
        padding: ${spacing.m} 0;
      }

      ${breakpoints.xl} {
        padding: ${spacing.s} 0;
      }
    `,
    moreCard: css`
      display: grid;
      width: 195px;
      height: 170px;
      justify-items: center;
      align-items: center;
      text-align: center;
      border-radius: ${borderRadius.l};
      border: 1px solid ${colors.greyShade1};
      box-shadow: 0px 4px 6px rgba(91, 104, 133, 0.1);
      color: ${colors.navy};
      font-weight: 700;
      background-color: ${colors.white};
      outline: 0;

      ${breakpoints.xl} {
        padding: ${spacing.s} 0;
        height: 235px;
      }
    `,
    moreLink: css`
      display: flex;
      flex-direction: column-reverse;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 400;
      text-decoration: none;

      svg {
        margin-bottom: ${spacing.xs};
      }

      ${breakpoints.xl} {
        flex-direction: unset;

        svg {
          margin-left: ${spacing.xs};
        }
      }
    `,
    ageTitle: css`
      text-transform: capitalize;
    `,
    categoryText: css`
      min-height: 2.4rem;
      padding-top: 0.625rem;
    `,
    introDecription: css`
      text-align: left;
      padding-top: 0.625rem;

      ${breakpoints.xl} {
        text-align: center;
        color: ${colors.white};
      }
    `,
  };
};
