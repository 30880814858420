/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import { RightArrow } from '../Icons';
import useStyles from './styles';

const FilterListItem = ({
  analyticsId,
  label,
  valueFormatter,
  config,
  currentValue,
  onClick,
  isOpen,
}) => {
  const styles = useStyles();

  // If filter has formatter, apply to filter value
  const value =
    typeof valueFormatter === 'function'
      ? valueFormatter(currentValue, config)
      : currentValue;

  return (
    <button
      id={analyticsId}
      onClick={onClick}
      css={styles.filterItem}
      tabIndex={isOpen ? 0 : -1}
    >
      <span css={styles.label}>{label}</span>
      <span css={styles.value(!!value)}>{value}</span>
      <RightArrow css={styles.arrow} />
    </button>
  );
};

FilterListItem.propTypes = {
  analyticsId: PropTypes.string,
  label: PropTypes.string,
  faInitiativeDetails: PropTypes.object,
  venueSessions: PropTypes.object,
  isDesktop: PropTypes.bool,
  isProvider: PropTypes.bool,
};

export default FilterListItem;
