import isequal from 'lodash.isequal';
import isobject from 'lodash.isobject';
import transform from 'lodash.transform';

export default (object, base) => {
  function changes(object, base) {
    return transform(object, function (result, value, key) {
      if (!isequal(value, base[key])) {
        result[key] =
          isobject(value) && isobject(base[key])
            ? changes(value, base[key])
            : value;
      }
    });
  }
  return changes(object, base);
};
