/** @jsxImportSource @emotion/react */
import { forwardRef, Fragment, useLayoutEffect, useRef } from 'react';

import { recommendationsMapPageAnalytics } from '../../analyticsConsts';
import { CATEGORY_ICONS } from '../../const';
import formatAddress from '../../utils/formatAddress';
import idFromString from '../../utils/idFromString';
import Button from '../Button';
import HiddenContent from '../HiddenContent';
import { Directions, Pin } from '../Icons';
import Image from '../Image';
import LinkDirection from '../LinkDirection';
import useStyles from '../ProviderClubCard/styles';
import Text from '../Text';
import { providerCardImage } from '../VenuePage/conditionCheck';

const {
  CTA_PROVIDER_VENUE_CARD,
  PROVIDER_VENUE_CARD_TITLE,
  MORE_INFO,
  MAP_DIRECTION,
} = recommendationsMapPageAnalytics;

const ProviderCenterCard = forwardRef(
  (
    {
      index,
      CentreName,
      CentreId,
      Address,
      DistanceFromUserLocation,
      SessionCartDto,
      SessionCount,
      OfferedCentres,
      onClick,
      isSelected,
      Latitude,
      Longitude,
      mapCardLogo,
      isDesktop,
      providerid,
      PitchFinderId,
      pitchfinderid,
      ...rest
    },
    ref
  ) => {
    const cardRef = useRef();
    const styles = useStyles();
    let desktopLogoRef = useRef();
    let mobLogoRef = useRef();
    const isSessionAvailable = SessionCartDto && SessionCartDto.length > 0;
    const selectId = CentreId ? CentreId : providerid;
    const navigate = providerid
      ? `/provider/${providerid}p${PitchFinderId}`
      : `/venue/${pitchfinderid}`;
    const sessionAvailable = isSessionAvailable
      ? 'This venue also offers:'
      : 'Next available session:';

    const handleKeyPress = event => {
      const enterOrSpace = event.which === 13 || event.which === 32;

      if (enterOrSpace) {
        event.preventDefault();
        onClick(selectId);
      }
    };

    // if the card gets selected move the focus to it
    useLayoutEffect(() => {
      if (isSelected && cardRef.current) {
        setTimeout(() => {
          cardRef?.current?.focus();
          cardRef?.current?.scrollIntoView({
            behaviour: 'smooth',
            block: 'nearest',
          });
        }, 300);
      }
    }, [isSelected]);

    const accessibleProps = isSelected ? {} : { role: 'button', tabIndex: 0 };

    return (
      <li css={styles.wrapper(isSelected)} tabIndex={-1} ref={ref}>
        <div
          {...accessibleProps}
          id={`${CTA_PROVIDER_VENUE_CARD}-${index}`}
          aria-expanded={isSelected}
          css={styles.base}
          onClick={() => onClick(selectId)}
          onKeyPress={handleKeyPress}
          ref={cardRef}
        >
          <div css={styles.titleWrapper}>
            <Text
              id={`${PROVIDER_VENUE_CARD_TITLE}-${index}`}
              as="h3"
              css={styles.title}
              size="6"
            >
              {CentreName}
            </Text>
          </div>
          <div css={styles.info(isSessionAvailable, providerid)}>
            <div css={styles.address}>{formatAddress(rest)}</div>
            <div css={styles.distance}>
              <HiddenContent>Distance </HiddenContent>
              <Pin />
              <span>{DistanceFromUserLocation} Miles</span>
            </div>
          </div>
          {isSessionAvailable && (
            <div css={styles.search}>
              {SessionCount} sessions match your search
            </div>
          )}
          {pitchfinderid && (
            <div css={styles.search}>
              View Provider details for session info
            </div>
          )}
          {providerid && (
            <div css={styles.search}>
              View Provider details for session info
            </div>
          )}
          {isSelected && (
            <Fragment>
              <div css={styles.teamInfoWrapper}>
                {isSessionAvailable &&
                  SessionCartDto.map(teamData => {
                    const categoryStringId = idFromString(teamData.Category);
                    const categoryHasIcon =
                      CATEGORY_ICONS.includes(categoryStringId);
                    const CatergoryId = teamData.CategoryTypeId;
                    mapCardLogo?.session?.map(sessionData => {
                      const sessionDataId = sessionData?.sessionId;
                      if (parseInt(sessionDataId) === CatergoryId) {
                        desktopLogoRef.current =
                          sessionData?.images?.desktop?.src;
                        mobLogoRef.current = sessionData?.images?.mobile?.src;
                      }
                      return sessionDataId;
                    });
                    return (
                      <div key={teamData.SessionId} css={styles.teamInfo}>
                        <Text css={styles.teamName}>
                          <HiddenContent>Session name </HiddenContent>
                          {teamData.OrganizationName}
                        </Text>
                        <Text css={styles.teamPlayDay(true)}>
                          <HiddenContent>Plays on </HiddenContent>
                          {teamData.PlaysOnDay}
                        </Text>
                        <Text css={styles.logoWrapper}>
                          <Image
                            css={styles.image}
                            src={providerCardImage(
                              isDesktop,
                              desktopLogoRef,
                              mobLogoRef,
                              categoryHasIcon,
                              categoryStringId
                            )}
                            alt=""
                          />
                        </Text>
                      </div>
                    );
                  })}
              </div>
              {OfferedCentres && (
                <div css={styles.venueOfferWrapper}>
                  <Text css={styles.venueOffer}>{sessionAvailable}</Text>
                  <Text css={styles.otherType}>{OfferedCentres}</Text>
                </div>
              )}
              <div css={styles.buttonWrapper}>
                <div css={styles.options}>
                  <Button.Link
                    primary
                    id={`${MORE_INFO}-${index}`}
                    css={styles.primaryBtn}
                    to={CentreId ? `/venue/${CentreId}` : navigate}
                    aria-label={`More info on ${CentreName}`}
                  >
                    more info
                  </Button.Link>
                  {(isSessionAvailable || providerid || pitchfinderid) && (
                    <LinkDirection
                      id={`${MAP_DIRECTION}-${index}`}
                      css={styles.secondaryBtn}
                      latitude={Latitude}
                      longitude={Longitude}
                      name={CentreName}
                      icon={Directions}
                      aria-label={`Directions to ${CentreName} - opens in a new window`}
                    >
                      Directions
                    </LinkDirection>
                  )}
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </li>
    );
  }
);

export default ProviderCenterCard;
