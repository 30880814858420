/** @jsxImportSource @emotion/react */
import { Link as LinkRouter } from 'react-router-dom';

import useStyles from './styles';

const Link = ({ children, to, ...rest }) => {
  const styles = useStyles();

  return (
    <LinkRouter to={to} css={styles.link} {...rest}>
      {children}
    </LinkRouter>
  );
};

export default Link;
