import { createContext, useCallback, useContext, useState } from 'react';

const NestedFilterPanelContext = createContext();

export const NestedFilterPanelProvider = ({ children }) => {
  const [selectedFilterContext, setSelectedFilterContext] = useState();
  const [isNested, setIsNested] = useState(false);

  // Filter list item click handler
  const setSelectedFilters = useCallback(value => {
    setSelectedFilterContext(value);
  }, []);

  const setNested = useCallback((value = false) => {
    setIsNested(value);
  }, []);

  const resetNestedFilterPanel = () => {
    setSelectedFilterContext({});
  };

  return (
    <NestedFilterPanelContext.Provider
      value={{ selectedFilterContext, setSelectedFilters, isNested, setNested, resetNestedFilterPanel }}
    >
      {children}
    </NestedFilterPanelContext.Provider>
  );
};

export const useNestedFilterPanelContext = () =>
  useContext(NestedFilterPanelContext);
