import { useLayoutEffect } from 'react';
import { useLocation } from 'react-router-dom';

import { usePageHistory } from '../../context/pageHistory';

export default ref => {
  const { pathname } = useLocation();
  const { previousPage } = usePageHistory();

  useLayoutEffect(() => {
    if (pathname === previousPage || !ref?.current) {
      return;
    }

    window.scrollTo(0, 0);

    const clearTimer = setTimeout(() => {
      ref.current.focus();
    }, 200);

    return () => {
      clearTimeout(clearTimer);
    };
  }, [pathname, previousPage, ref]);
};
