/** @jsxImportSource @emotion/react */
import { forwardRef, Fragment, useState } from 'react';

import { REAPPLY_FILTERS, REMOVE_FILTERS } from '../../analyticsConsts';
import Button from '../Button';
import Loader from '../Loader';
import MonthTabs from '../MonthTabs';
import TabPanel from '../TabPanel';
import Text from '../Text';
import VenueExpandableList from '../VenueExpandableList';
import useStyles from './styles';

const VenueClubList = forwardRef(
  (
    {
      title,
      titleAnalyticsId,
      data,
      type,
      isFiltered,
      toggleFiltered,
      canToggle,
      cardAction,
      isClubInfo = false,
      venueTabs,
      isFiltersApplied,
      isRefreshingSessions,
      isDesktop,
      venueTitle,
      logoDetails,
      footballtype,
      providerData,
    },
    ref
  ) => {
    const styles = useStyles();
    const [selectedTab, setSelectedTab] = useState(0);
    const { ref1, ref2 } = ref;

    const onClick = selected => {
      setSelectedTab(selected);
    };

    return (
      <div css={styles.base} tabIndex={-1} ref={ref1}>
        <div css={styles.header}>
          <Text
            id={titleAnalyticsId}
            as="h2"
            size="5"
            css={styles.title(isClubInfo)}
          >
            {title}
          </Text>
          {canToggle && (
            <div css={styles.filterButtonWrapper}>
              <Button
                id={`${isFiltered ? REMOVE_FILTERS : REAPPLY_FILTERS}`}
                onClick={toggleFiltered}
                css={styles.button}
              >
                {isFiltered ? 'Remove filters' : 'Reapply filters'}
              </Button>
            </div>
          )}
        </div>
        <div css={styles.middle}>
          {isRefreshingSessions && (
            <Loader
              message={
                isFiltersApplied ? 'Reapplying filters' : 'Removing filters'
              }
              ref={ref2}
            />
          )}
          {!isRefreshingSessions &&
            data[selectedTab] &&
            data[selectedTab].length > 0 && (
              <Fragment>
                <div css={!isDesktop && styles.gradientFeather}>
                  <MonthTabs
                    tabs={venueTabs}
                    onClick={onClick}
                    selectedType={selectedTab}
                  />
                </div>
                <TabPanel aria-live="polite">
                  <VenueExpandableList
                    index={selectedTab}
                    ariaLabel={`Sessions on`}
                    list={data[selectedTab]}
                    cardAction={cardAction}
                    canToggle={canToggle}
                    isFiltered={isFiltered}
                    onClick={toggleFiltered}
                    isDesktop={isDesktop}
                    venueTitle={venueTitle}
                    logoDetails={logoDetails}
                    footballtype={footballtype}
                    providerData={providerData}
                  />
                </TabPanel>
                {!isDesktop && (
                  <Fragment>
                    <Text css={styles.jumpSession}>Jump to sessions in:</Text>
                    <div css={styles.gradientFeather}>
                      <MonthTabs
                        tabs={venueTabs}
                        onClick={onClick}
                        selectedType={selectedTab}
                      />
                    </div>
                  </Fragment>
                )}
              </Fragment>
            )}
        </div>
      </div>
    );
  }
);

export default VenueClubList;
