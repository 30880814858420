/** @jsxImportSource @emotion/react */

import { venueDetailsPageAnalytics } from '../../analyticsConsts';
import InfoFacilities from '../InfoFacilities';
import Text from '../Text';
import useStyles from './styles';

const { VENUE_ABOUT_TITLE } = venueDetailsPageAnalytics;

const VenueDetails = ({ footballtypes, facilities }) => {
  const styles = useStyles();

  return (
    <div>
      <div css={styles.top}>
        <Text id={VENUE_ABOUT_TITLE} as="h2" size="5" css={styles.title}>
          About this venue
        </Text>
      </div>
      <div css={styles.wrapper}>
        {footballtypes && (
          <div css={styles.footballTypeWrapper}>
            <InfoFacilities {...footballtypes} isVenuePage={true} />
          </div>
        )}
        {facilities && (
          <div css={styles.venueFacilitiesWrapper}>
            <InfoFacilities {...facilities} isVenuePage={true} />
          </div>
        )}
      </div>
    </div>
  );
};

export default VenueDetails;
