import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, colors, spacing } = useTheme();

  return {
    facilities: isTeamInfo => css`
      display: grid;
      grid-template-columns: 30px 1fr;
      align-items: flex-start;
      grid-column-gap: ${spacing.xs};
      border-top: 1px solid ${colors.lightGrey};
      padding: 6px 10px;

      ${breakpoints.m} {
        border-top: 0;
        padding: 0;

        ${isTeamInfo &&
        css`
          &:first-of-type {
            grid-row: 1;
            grid-column: 1 / 3;
          }
        `}
      }
    `,
    title: css`
      font-size: 20px;
      color: ${colors.navy};
      margin-bottom: ${spacing.s};

      ${breakpoints.l} {
        padding-top: ${spacing.s};
        font-family: 'FS Dillon';
        color: ${colors.navy};
        font-weight: bold;
        line-height: 1.1rem;
        font-size: 1.25rem;
        line-height: 1.625rem;
      }
    `,
    infoFacilities: (isTeamInfo, isVenuePage, isProvider) => css`
      display: grid;
      background-color: ${colors.lightGrey};
      padding: ${spacing.s} 0.625rem ${spacing.s} ${spacing.s};
      border-radius: 8px;
      grid-row-gap: ${spacing.xs};

      ${isProvider && css`
        grid-row-gap: 1.5rem;
      `}
      
      ${isVenuePage &&
      css`
        grid-template-rows: repeat(3, auto);
        grid-row-gap: ${spacing.s};
      `}

      ${breakpoints.m} {
        ${!isTeamInfo &&
        !isVenuePage &&
        css`
          grid-template-columns: 1fr 1fr;
        `}
        padding: 1.5rem 0.625rem 1.5rem 1.5rem;
      }
    `,
    listFacilities: css`
      padding-top: 6px;
      padding-left: ${spacing.xs};
    `,
    facilitiesItem: css`
      color: ${colors.darkGrey};
      float: left;
      position: relative;
      padding-bottom: 0.625rem;
      &:not(:last-child):after {
        content: '|';
        padding: 0 0.4375rem;
        color: ${colors.white};
      }
    `,
    iconWrapper: iconName => css`
      background: url(/images/desktop/venue/${iconName}.svg) no-repeat;
      height: 100%;
    `,
    iconWrapperClub: css`
      background-color: ${colors.darkGrey};
      padding: 0px 0.125rem;
      border-radius: 0.3125rem;
      height: ${spacing.m};
      margin: 0 auto;
      width: ${spacing.m};
      display: grid;
      justify-content: center;
      align-items: center;
    `,
  };
};
