import publicPath from '../../utils/publicPath';

const categoryHeroData = {
  1: {
    title: 'Play Kickabout Football',
    footballType: 'casual football',
    images: {
      desktop: {
        src: publicPath('/images/desktop/recommendations/casual-tab.png'),
      },
      mobile: {
        src: publicPath('/images/desktop/recommendations/casual-tab.png'),
      },
    },
    benefitsItems: [
      {
        id: 1,
        value: 'Informal',
      },
      {
        id: 2,
        value: 'All skill and experience levels',
      },
      {
        id: 3,
        value: 'Choose when you want to play',
      },
    ],
  },
  2: {
    title: 'Play Club Football',
    footballType: 'club football',
    images: {
      desktop: {
        src: publicPath('/images/desktop/recommendations/club-tab.png'),
      },
      mobile: {
        src: publicPath('/images/desktop/recommendations/club-tab.png'),
      },
    },
    benefitsItems: [
      {
        id: 1,
        value: 'Competitive',
      },
      {
        id: 2,
        value: 'All skill and experience levels',
      },
      {
        id: 3,
        value: 'Regular team games and training',
      },
    ],
  },
};

export default categoryHeroData;
