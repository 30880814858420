/** @jsxImportSource @emotion/react */
import { Fragment } from 'react';

import { recommendationsPageAnalytics } from '../../analyticsConsts';
import {
  BOYS_OR_MEN_MIXED,
  CASUAL,
  CASUAL_LINK,
  CLUB_LINK,
  CLUBS,
  DAY_PREFERENCE_DATA,
  DONT_MIND,
  EMPTY_STRING,
  GIRLS_OR_WOMEN_MIXED,
  KICKABOUT_CASUAL,
  LOCATION_DATA,
  MIXED_VALUE,
  YES,
} from '../../const';
import { useFilterPanelToggleContext } from '../../context/filterPanelToggle';
import { useNestedFilterPanelContext } from '../../context/nestedFilterPanel';
import { usePreferencesContext } from '../../context/preferences';
import weekdaysFormatter from '../../formatters/weekdays';
import useAgeTitle from '../../hooks/useAgeTitle';
import useDisabilityTitle from '../../hooks/useDisabilityTitle';
import ExternalLink from '../ExternalLink';
import filtersConfig from '../FiltersPanel/filtersConfig';
import LinkButton from '../LinkButton';
import Text from '../Text';
import useStyles from './styles';

const {
  RECOMMENDED_FOOTBALL_ONBOARDING_CTA_LEARN_MORE,
  RECOMMENDED_FOOTBALL_ONBOARDING_WRAPPER,
  RECOMMENDED_FOOTBALL_ONBOARDING_DAY_PREFERENCE,
  RECOMMENDED_FOOTBALL_ONBOARDING_LOCATION,
} = recommendationsPageAnalytics;

const OnboardingCriteria = () => {
  const styles = useStyles();
  const { preferences } = usePreferencesContext();
  const { isFilterPanelToggled, setFilterPanelToggle } =
    useFilterPanelToggleContext();
  const { setSelectedFilters, setNested } = useNestedFilterPanelContext();
  const {
    Age,
    ReadableLocation,
    SelectedFootballType,
    PlayWith,
    Disabilityoption,
    WeekDays,
    FootballType,
  } = preferences;
  const ageTitle = useAgeTitle();
  const disabilityTitle = useDisabilityTitle();
  const footballType =
    SelectedFootballType === KICKABOUT_CASUAL ? CASUAL : CLUBS;
  const volunteer = JSON.parse(sessionStorage.getItem('ISVOLUNTEER'));
  return (
    <div
      css={styles.OnboardingWrapper}
      id={RECOMMENDED_FOOTBALL_ONBOARDING_WRAPPER}
    >
      {preferences && Object.keys(preferences).length !== 0 && (
        <Fragment>
          <Text as="h2" css={styles.link}>
            {!volunteer && `${Age}-year-old`}{' '}
            {ageTitle}{' '}
            {(PlayWith === BOYS_OR_MEN_MIXED ||
              PlayWith === GIRLS_OR_WOMEN_MIXED) &&
              `or ${MIXED_VALUE} `}
            football,
            {Disabilityoption === YES && ` catering for ${disabilityTitle},`} on
            <LinkButton
              css={[styles.link, styles.daysLink]}
              id={RECOMMENDED_FOOTBALL_ONBOARDING_DAY_PREFERENCE}
              aria-label={`${weekdaysFormatter(
                WeekDays
              )} - click to open day preference filter`}
              onClick={() => {
                // set day preference data
                setSelectedFilters(
                  filtersConfig[SelectedFootballType][DAY_PREFERENCE_DATA]
                );
                setNested(true);
                setFilterPanelToggle(!isFilterPanelToggled);
              }}
            >
              {weekdaysFormatter(WeekDays)}
            </LinkButton>
            {weekdaysFormatter(WeekDays).toString().toLowerCase() === 'any'
              ? 'day '
              : EMPTY_STRING}
            near
            <LinkButton
              css={styles.link}
              aria-label={`${ReadableLocation} - click to open location filter`}
              id={RECOMMENDED_FOOTBALL_ONBOARDING_LOCATION}
              onClick={() => {
                // set location data
                setSelectedFilters(
                  filtersConfig[SelectedFootballType][LOCATION_DATA]
                );
                setNested(true);
                setFilterPanelToggle(!isFilterPanelToggled);
              }}
            >
              {/* Empty value will show "My location" */}
              {ReadableLocation ? ReadableLocation : 'My location'}.
            </LinkButton>
          </Text>
          {FootballType !== DONT_MIND && (
            <ExternalLink
              css={styles.externalLink}
              id={RECOMMENDED_FOOTBALL_ONBOARDING_CTA_LEARN_MORE}
              href={
                SelectedFootballType === KICKABOUT_CASUAL
                  ? CASUAL_LINK
                  : CLUB_LINK
              }
              aria-label={`Learn more about ${footballType} - opens in a new window`}
            >
              Learn more about
              <span css={styles.footballLink}> {footballType} </span>football
            </ExternalLink>
          )}
        </Fragment>
      )}
    </div>
  );
};

export default OnboardingCriteria;
