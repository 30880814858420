import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors, breakpoints, spacing, borderRadius } = useTheme();

  return {
    card: css`
      z-index: 1;
      position: relative;
      padding-top: ${spacing.s};
      background-color: ${colors.white};
      display: grid;
      grid-gap: ${spacing.m};

      ${breakpoints.m} {
        padding-top: ${spacing.m};
        grid-template-columns: 1fr 1fr;
        grid-template-rows: minmax(225px, auto);
        grid-gap: ${spacing.s};
        border-radius: ${borderRadius.m};
      }
    `,
    grid: css`
      order: 2;
      display: grid;
      grid-template-rows: 10% 1fr;

      grid-template-areas:
        'top'
        'middle'
        'bottom';

      ${breakpoints.m} {
        grid-template-rows: 10% 35% 20%;
        align-self: center;
        order: 0;
      }

      ${breakpoints.xl} {
        grid-template-rows: 10% 1fr 20%;
      }
    `,
    top: css`
      display: flex;
      align-items: center;
      grid-area: top;
    `,
    title: css`
      color: ${colors.navy};
      position: relative;
      border-bottom: ${borderRadius.s} solid ${colors.red};
    `,
    middle: css`
      grid-area: middle;
      padding-top: 1.5rem;
    `,
    middleWrapper: css`
      display: grid;
      grid-gap: 5px;
      color: ${colors.darkGrey};

      ${breakpoints.xl} {
        grid-template-columns: auto auto;
        grid-auto-flow: dense;
        grid-gap: 5px;

        > div {
          &:nth-of-type(3n + 2) {
            grid-column: 1 / 2;
          }
        }
      }
    `,
    bottom: css`
      grid-area: bottom;
      padding-top: ${spacing.s};
    `,
    link: css`
      color: ${colors.navy};
      font-weight: 400;
      display: inline-block;

      &:focus {
        outline: 0;
        border: 1px solid ${colors.navy};
      }
    `,
    imgWrapper: css`
      display: grid;
      grid-template-rows: minmax(150px, auto);
      grid-gap: ${spacing.s};
      border-bottom: ${borderRadius.s} solid ${colors.red};
      align-items: baseline;
      justify-items: center;
      align-items: end;

      ${breakpoints.m} {
        align-self: start;
      }

      ${breakpoints.l} {
        align-self: unset;
      }

      ${breakpoints.xl} {
        align-items: end;
        border-bottom: 0;
      }
    `,
    imgSection: ({ desktop, mobile }) => css`
      width: 100%;
      height: 100%;
      background: url(${mobile.src}) center bottom no-repeat;
      background-size: contain;

      ${breakpoints.l} {
        background: url(${desktop.src}) center bottom no-repeat;
        background-size: contain;
      }
    `,
  };
};
