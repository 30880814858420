/** @jsxImportSource @emotion/react */
import getNumberSuffix from '../../utils/getNumberSuffix';
import Text from '../Text';
import useStyles from './styles';

const LeaguePosition = ({
  title,
  seasonName,
  divisionName,
  standingsPosition,
  leagueName,
}) => {
  const styles = useStyles();

  return (
    <div css={styles.facilitiesWrapper}>
      <div css={styles.infoFacilities}>
        <Text as="h2" size="7" css={styles.title}>
          {title}
        </Text>
        {leagueName && <Text as="h3" css={styles.subtitle}>
          {leagueName}
        </Text>}
        <Text as="h3" css={styles.subtitle}>
          {divisionName}
        </Text>
        <div css={styles.positionWrapper}>
          <Text as="p" size="7" css={styles.postion}>
            {standingsPosition === "1" ? `Champions` : 
            `Finished  ${standingsPosition}${getNumberSuffix(parseInt(standingsPosition))}`}
          </Text>
        </div>
        <Text as="p" css={[styles.subtitle, styles.seasonText]}>
          {seasonName} season
        </Text>
      </div>
    </div>
  );
};

export default LeaguePosition;
