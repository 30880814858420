// general ids
export const BACK_TO_RESULTS = 'back_to_results';
export const REMOVE_FILTERS = 'remove_filters';
export const REAPPLY_FILTERS = 'reapply_filters';
export const SHOW_MORE = 'show_more';
export const SHOW_LESS = 'show_less';

// home page
export const homePageAnalytics = {
  HERO_BANNER: 'hero_wrapper',
  FOOTBALL_CATEGORIES: 'football_categories_wrapper',
  QUICK_LINKS: 'quick_links_wrapper',
  FA_INITIATIVES: 'fa_initiatives_wrapper',

  CTA_START_SEARCHING: 'cta_start_searching_now',

  FOOTBALL_CATEGORY_TYPES: {
    0: 'club_football_cta_learn_more',
    1: 'casual_football_cta_learn_more',
  },
  QUICK_LINK_TYPES: {
    0: 'traditional_football_cta_learn_more',
    1: 'futsal_football_cta_learn_more',
    2: 'walking_football_cta_learn_more',
    3: 'small_sided_football_cta_learn_more',
  },
  FA_INITIATIVE_TYPES: {
    0: 'initiative_cta_fun_friendly_5_11',
    1: 'initiative_cta_casual_football_everyone',
    2: 'initiative_cta_small_sided_over_50s',
  },
};

// on-boarding Modal
export const onBoardingModalAnalytics = {
  ONBOARDING_CTA_EXIT: 'onboarding_cta_exit',
  ONBOARDING_CTA_YES: 'onboarding_cta_exit_yes',
  ONBOARDING_CTA_NO: 'onboarding_cta_exit_no',
  ONBOARDING_CTA_PREVIOUS_QUESTION: 'onboarding_cta_previous_question',
  ONBOARDING_CTA_NEXT_QUESTION: 'onboarding_cta_next_question',
};

// recommendation page
export const recommendationsPageAnalytics = {
  FOOTBALL_RECOMMENDATIONS_RESULTS_TABS:
    'football_recommendations_results_tabs_wrapper',
  FOOTBALL_RECOMMENDATIONS_RESULT: 'football_recommendations_result_wrapper',

  RECOMMENDED_FOOTBALL_TYPE_TITLE: 'recommended_football_type_title',
  RECOMMENDED_FOOTBALL_TYPE_ML_TITLE: 'recommended_football_type_ml_title',
  RECOMMENDED_FOOTBALL_TYPE_IMAGE: 'recommended_football_type_image',
  RECOMMENDED_FOOTBALL_TYPE_CTA_LEAR_MORE:
    'recommended_football_type_cta_learn_more',

  RECOMMENDED_FOOTBALL_TYPE_CTA_VIEW_MAPS_OF:
    'recommended_football_type_cta_view_maps_of',

  RECOMMENDED_FOOTBALL_TYPE_CTA_CLUB_CARD:
    'recommended_football_type_cta_club_card',
  RECOMMENDED_FOOTBALL_TYPE_CTA_VENUE_CARD:
    'recommended_football_type_cta_venue_card',

  RECOMMENDED_FOOTBALL_TYPE_CTA_ML_CLUB_CARD:
    'recommended_football_type_cta_ml_club_card',
  RECOMMENDED_FOOTBALL_TYPE_CTA_ML_VENUE_CARD:
    'recommended_football_type_cta_ml_venue_card',

  RECOMMENDED_FOOTBALL_TYPE_CTA_VIEW_MORE_CARDS:
    'recommended_football_type_cta_view_more_cards',

  CTA_VIEW_MORE_RECOMMENDATIONS: 'cta_view_more_recommendations',

  RECOMMENDED_FOOTBALL_TYPE_CTA_START_SEARCH:
    'recommended_football_type_cta_view_start_search',
  RECOMMENDED_FOOTBALL_TYPE_CTA_LEARN_MORE:
    'recommended_football_type_cta_view_LEARN_MORE',

  RECOMMENDED_FOOTBALL_TYPE_MIXED_CONTAINER:
    'recommended_football_type_mixed_container',
  RECOMMENDED_FOOTBALL_TYPE_MIXED_WRAPPER:
    'recommended_football_type_mixed_wrapper',
  RECOMMENDED_FOOTBALL_TYPE_CTA_MIXED_RESULTS:
    'recommended_football_type_cta_mixed_results',
  RECOMMENDED_FOOTBALL_MIXED_IMAGE: 'recommended_football_mixed_image',
  RECOMMENDED_FOOTBALL_TYPE_MIXED_HEADING:
    'recommended_football_type_mixed_heading',

  RECOMMENDED_FOOTBALL_TYPE_CTA_EXPAND_SEARCH:
    'recommended_football_type_cta_expand_search',
  RECOMMENDED_FOOTBALL_TYPE_LINK_FILTERS:
    'recommended_football_type_link_filters',
  RECOMMENDED_FOOTBALL_TYPE_LINK_NEW_SEARCH:
    'recommended_football_type_link_new_search',
  RECOMMENDED_FOOTBALL_TYPE_NO_RESULTS_TITLE:
    'recommended_football_type_no_results_title',
  RECOMMENDED_FOOTBALL_TYPE_NO_RESULTS_CONTAINER:
    'recommended_football_type_no_results_container',
  RECOMMENDED_FOOTBALL_TYPE_NO_RESULTS_WRAPPER:
    'recommended_football_type_no_results_wrapper',

  RECOMMENDED_FOOTBALL_ONBOARDING_CTA_LEARN_MORE:
    'recommended_football_onboarding_cta_learn_more',
  RECOMMENDED_FOOTBALL_ONBOARDING_WRAPPER:
    'recommended_football_onboarding_wrapper',
  RECOMMENDED_FOOTBALL_ONBOARDING_DAY_PREFERENCE:
    'recommended_football_onboarding_day_preference',
  RECOMMENDED_FOOTBALL_ONBOARDING_LOCATION:
    'recommended_football_onboarding_location',

  //for no disability results
  RECOMMENDED_NO_RESULTS_DISABILITY_CTA_EXPAND_SEARCH:
    'recommended_no_results_disability_cta_expand_search',
  RECOMMENDED_NO_RESULTS_DISABILITY_LINK_FILTERS:
    'recommended_no_results_disability_link_filters',
  RECOMMENDED_NO_RESULTS_DISABILITY_TITLE:
    'recommended_no_results_disability_title',
  RECOMMENDED_NO_RESULTS_DISABILITY_CONTAINER:
    'recommended_no_results_disability_container',
  RECOMMENDED_NO_RESULTS_DISABILITY_WRAPPER:
    'recommended_no_results_disability_wrapper',

  //for pan-disability suggestion
  RECOMMENDED_PAN_DISABILITY_SUGGESTION_WRAPPER:
    'recommended_pan_disability-suggestion-wrapper',
};

// recommendation page
export const moRecommendationsPageAnalytics = {
  MO_RECOMMENDED_FOOTBALL_ONBOARDING_CTA_LEARN_MORE:
    'mo_recommended_football_onboarding_cta_learn_more',
  MO_RECOMMENDED_FOOTBALL_ONBOARDING_WRAPPER:
    'mo_recommended_football_onboarding_wrapper',
  MO_RECOMMENDED_FOOTBALL_ONBOARDING_DAY_PREFERENCE:
    'mo_recommended_football_onboarding_day_preference',
  MO_RECOMMENDED_FOOTBALL_ONBOARDING_LOCATION:
    'mo_recommended_football_onboarding_location',
  MO_RECOMMENDED_CLUB_fURTHER_AWAY: 'mo_recommended_club_further_away',
  MO_RECOMMENDED_VENUE_fURTHER_AWAY: 'mo_recommended_venue_further_away',

  MO_RESULTS_FOOTBALL_VENUE_NAME: 'mo_results_football_venue_name',
  MO_RESULTS_FOOTBALL_TEAM_NAME: 'mo_results_football_team_name',
  MO_RESULTS_FOOTBALL_TEAM_INFO: 'mo_results_football_team_info',
  MO_RESULTS_FOOTBALL_VENUE_INFO: 'mo_results_football_venue_info',
  MO_MORE_RESULTS: 'mo_more_results',
  MO_RESULTS_FOOTBALL_DISTANCE: 'mo_results_football_distance',
};

// recommendations map page
export const recommendationsMapPageAnalytics = {
  RECOMMENDATIONS_RESULT_ON_MAP: 'recommendations_result_on_map_wrapper',
  CTA_BACK_TO_RECOMMENDATIONS: 'cta_back_to_recommendations',

  CTA_PROVIDER_CLUB_CARD: 'cta_provider_club_card',
  PROVIDER_CLUB_CARD_TITLE: 'provider_club_card_title',

  CTA_PROVIDER_VENUE_CARD: 'cta_provider_venue_card',
  PROVIDER_VENUE_CARD_TITLE: 'provider_venue_card_title',

  MAP_CTA_LOAD_MORE_RECOMMENDATIONS: 'map_cta_load_more_recommendations',
  MORE_INFO: 'more_info',
  MAP_DIRECTION: 'map_direction',
  DIRECTION: 'directions',
};

// Club Details Page
export const clubDetailsPageAnalytics = {
  CLUB_DETAILS_WRAPPER: 'club_details_wrapper',
  CLUB_NAME_HEADING: 'club_name_heading',
  CLUB_TEAM_TITLE: 'club_team_title',
  CLUB_TEAM_FILTER: 'club_team_filter',
  CLUB_TEAM_CARD_TITLE: 'club_team_card_title',
  CLUB_TEAM_CARD_CTA_CONTACT: 'club_team_card_cta_contact',
  CLUB_TEAM_CARD_CTA_SHOW_MORE: 'club_team_card_cta_show_more',
  CLUB_DETAILS_TABS: 'club_details_tabs',
};

//Mo team card
export const moTeamCardAnalytics = {
  CLUB_TEAM_MO_CARD_TITLE: 'club_team_mo_card_title',
  CLUB_TEAM_CARD_MO_CTA_CONTACT: 'club_team_card_mo_cta_contact',
};

// Team Details Page
export const teamDetailsPageAnalytics = {
  TEAM_DETAILS_WRAPPER: 'team_details_wrapper',
  TEAM_NAME_HEADING: 'team_name_heading',
  TEAM_DETAILS_TABS: 'team_details_tabs',
};

// Venue Details Page
export const venueDetailsPageAnalytics = {
  VENUE_DETAILS_WRAPPER: 'venue_details_wrapper',
  VENUE_NAME_HEADING: 'venue_name_heading',
  VENUE_SESSIONS_TITLE: 'venue_sessions_title',
  VENUE_SESSION_FILTER: 'venue_session_filter',
  VENUE_SESSION_CARD_TITLE: 'venue_session_card_title',
  VENUE_SESSION_CARD_CTA_BOOKING: 'venue_session_card_cta_booking',
  VENUE_ABOUT_TITLE: 'about_the_venue_title',
  FA_EVENTS_FIND_MORE: 'fa_events_find_out_more',
};

// Filter Panel & Nested Filters
export const filtersPanelAnalytics = {
  FILTERS_PANEL_CTA_OPEN: 'filters_panel_cta_open',
  FILTERS_PANEL_CTA_CLOSE: 'filters_panel_cta_close',
  FILTERS_PANEL_CTA_BACK_TO: 'filters_panel_cta_back_to',
  FILTERS_PANEL_CTA_APPLY: 'filters_panel_cta_apply_filters',
  FILTERS_PANEL_CTA_SAVE_CHANGES: 'filters_panel_cta_save_changes',
  FILTERS_PANEL_CTA_NEW_SEARCH: 'filters_panel_cta_start_new_search',
  FILTERS_PANEL_CTA_CURRENT_LOCATION:
    'filters_panel_cta_share_current_location',

  FILTERS_PANEL_CTA_DAY_PREFERENCE: 'filters_panel_cta_day_preference',
  FILTERS_PANEL_CTA_LOCATION_PREFERENCE:
    'filters_panel_cta_location_preference',
  FILTERS_PANEL_SELECTED_SESSION_DURATION_PREFERENCE:
    'filters_panel_selected_session_duration_preference',
  FILTERS_PANEL_CTA_INCLUDE_MIXED_GENDER:
    'filters_panel_cta_include_mixed_gender',
  FILTERS_PANEL_CTA_DISABILITY_FACILITY:
    'filters_panel_cta_disability_facility',
  FILTERS_PANEL_CTA_SESSION_PRICE: 'filters_panel_cta_session_price',
  FILTERS_PANEL_CTA_SURFACE_TYPE: 'filters_panel_cta_surface_type',

  FILTERS_PANEL_FILTER_TYPES: 'filters_panel_filter_types_wrapper',
  FILTERS_PANEL_NESTED_FILTER: 'filters_panel_nested_filter_wrapper',

  FILTERS_PANEL_UNSAVED_CHANGES: 'filters_panel_unsaved_changes_wrapper',
  FILTERS_PANEL_UNSAVED_CHANGES_CTA_SAVE:
    'filters_panel_unsaved_changes_cta_save',
  FILTERS_PANEL_UNSAVED_CHANGES_CTA_EXIT:
    'filters_panel_unsaved_changes_cta_exit',
  FILTERS_PANEL_UNSAVED_CHANGES_CTA_BACK:
    'filters_panel_unsaved_changes_cta_go_back',
};

// Footer
export const footerAnalytics = {
  FOOTER_WRAPPER: 'footer_wrapper',
  FOOTER_CTA: 'footer_cta',
};

// Social Media
export const socialMediaAnalytics = {
  SOCIAL_MEDIA_WRAPPER: 'footer_wrapper',
  SOCIAL_MEDIA_CTA: 'footer_cta',
};

export const contactFormModalAnalytics = {
  CONTACT_FORM_CTA_EXIT: 'contact_form_cta_exit',
  CONTACT_FORM_TITLE: 'contact_form_title',
  CONTACT_FROM_MESSAGE_CONFIRMATION: 'message_sent_confirmation',
};
