import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors } = useTheme();

  return {
    link: css`
      display: block;
      color: ${colors.blue};
      text-decoration: underline;
      font-weight: 700;
      transition: color 0.5s;
    `,
  };
};
