const disability = [
  'Toilets',
  'Parking',
  'Doorways',
  'Changing',
  'Spectator Areas',
  'Social',
  'Reception',
  'Emergency Exits',
  'Activities',
];

const strToArray = str => str.split(',');

const arraysMatched = (base, comparison) =>
  JSON.stringify(base) === JSON.stringify(comparison);

export default disabilityString => {
  if (!disabilityString) return 'Any';

  // sort disabilityArray based on disability
  const disabilityArray = strToArray(disabilityString).sort(function (a, b) {
    return disability.indexOf(a) - disability.indexOf(b);
  });

  if (disabilityArray.length === 1) {
    return disabilityArray[0];
  }
  if (arraysMatched(disabilityArray, disability)) {
    return 'All';
  }
  if (disabilityArray.length < disability.length) {
    return `${disabilityArray[0]} + ${disabilityArray.length - 1}`;
  }
};
