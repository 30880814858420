/** @jsxImportSource @emotion/react */
import { useHistory } from 'react-router';
import { Link } from 'react-router-dom';

import {
  moRecommendationsPageAnalytics,
  recommendationsMapPageAnalytics,
} from '../../analyticsConsts';
import {
  CLUB,
  EMPTY_STRING,
  KICKABOUT_CASUAL,
  VENUE_NO_SESSION_INFORMATION,
  VENUE_PROVIDER_INFORMATION,
} from '../../const';
import Button from '../Button';
import {
  DarkIcon,
  Directions,
  ResultAlertIcon,
  RightArrow,
  ShowMore,
} from '../Icons';
import LinkDirection from '../LinkDirection';
import MOTeamCard from '../MOTeamCard';
import Text from '../Text';
import VenueCard from '../VenueCard';
import { recommendationListName } from '../VenuePage/conditionCheck';
import useStyles from './styles';

const {
  MO_RESULTS_FOOTBALL_VENUE_NAME,
  MO_RESULTS_FOOTBALL_TEAM_NAME,
  MO_RESULTS_FOOTBALL_TEAM_INFO,
  MO_RESULTS_FOOTBALL_VENUE_INFO,
  MO_MORE_RESULTS,
  MO_RESULTS_FOOTBALL_DISTANCE,
} = moRecommendationsPageAnalytics;

const { DIRECTION } = recommendationsMapPageAnalytics;

const MORecommendationList = ({
  data,
  cardAction,
  isDesktop,
  selectedFootballType,
  isMapPage = false,
  sourceLocation,
}) => {
  const styles = useStyles();
  const history = useHistory();
  const CardMap = {
    [KICKABOUT_CASUAL]: VenueCard,
    [CLUB]: MOTeamCard,
  };
  const isClub = selectedFootballType === CLUB;
  const Card = CardMap[selectedFootballType];
  const cards = isClub ? data?.TeamsCartDto : data?.totalSession;
  const footballType = isClub ? 'Club' : 'Venue';
  const centerUrl = data.CentreId
    ? `/venue/${data?.CentreId}`
    : data?.totalProvider?.length > 1;
  const providerVenueUrl = centerUrl
    ? `/venue/${data?.CentreId ? data?.CentreId : data?.PitchFinderId}`
    : `/provider/${data?.totalProvider}p${data?.totalPitchFinder}`;
  const redirectUrl = isClub ? `/club/${data.ClubId}` : providerVenueUrl;

  const desktopLength = isDesktop ? 3 : 5;
  const cardLength = isMapPage ? isDesktop && 8 : desktopLength;

  const isClubPage = data.ClubName;
  const selectedCards = cards?.slice(0, cardLength)?.length;
  const teamCardLength = cards?.length > cardLength;
  const cardId = data?.CentreId ? data?.CentreId : data?.PitchFinderId;
  const cardurl =
    !data?.ClubId && data?.totalProvider?.length > 1
      ? `/venue/${cardId}`
      : `/provider/${data?.totalProvider}p${
          data?.totalPitchFinder ? data?.totalPitchFinder : EMPTY_STRING
        }`;
  const setRecommendationsPreference = e => {
    // we want to block the default link and only navigate
    // when we are sure the preferences have been set
    e.preventDefault();

    history.push(cardurl);
  };

  return (
    <div css={styles.base} tabIndex={-1}>
      <div css={styles.header(isClubPage, isMapPage)}>
        <div>
          <Text
            id={
              isClub
                ? MO_RESULTS_FOOTBALL_TEAM_NAME
                : MO_RESULTS_FOOTBALL_VENUE_NAME
            }
            as="h3"
            size="7"
            css={styles.title(isClubPage, isMapPage)}
          >
            {recommendationListName(data)}
          </Text>
          {isClubPage && (
            <Text
              id={MO_RESULTS_FOOTBALL_DISTANCE}
              as="p"
              size="7"
              css={styles.distance}
            >
              ({data.DistanceFromUserLocation} miles)
            </Text>
          )}
        </div>
        <Link
          id={
            isClub
              ? MO_RESULTS_FOOTBALL_TEAM_INFO
              : MO_RESULTS_FOOTBALL_VENUE_INFO
          }
          to={redirectUrl}
          css={styles.linkInfo}
        >
          <ResultAlertIcon
            css={styles.iconStyle(isClubPage)}
            width="25"
            height="25"
          />
          {(isClubPage || isDesktop) && (
            <span css={styles.info}>
              {data?.totalSession?.length > 0 || isClubPage
                ? footballType
                : 'Provider'}{' '}
              info
            </span>
          )}
        </Link>
      </div>
      {!isClubPage && (
        <Text
          id={MO_RESULTS_FOOTBALL_DISTANCE}
          as="p"
          size="7"
          css={styles.distance}
        >
          ({data.DistanceFromUserLocation} miles)
        </Text>
      )}
      {isMapPage && (
        <div css={styles.linkDirSection}>
          <LinkDirection
            id={DIRECTION}
            css={styles.secondaryBtn}
            latitude={data?.Latitude}
            longitude={data?.Longitude}
            name={data.CentreName || data.ClubName}
            icon={Directions}
            aria-label={`Directions to - opens in a new window`}
            sourceLocation={sourceLocation}
          >
            Directions To {footballType}
          </LinkDirection>
        </div>
      )}
      {(data?.totalSession?.length > 0 || isClubPage) && (
        <ul css={styles.sessionContainer(isMapPage, selectedCards, isClubPage)}>
          {cards &&
            cards?.length > 0 &&
            cards.slice(0, cardLength).map((rowData, index) => {
              return (
                <Card
                  key={rowData.SessionId}
                  index={index}
                  data={data}
                  isDesktop={isDesktop}
                  onClick={card => cardAction(card)}
                  rowData={rowData}
                  isMiniOnboarding={true}
                  clubname={data?.ClubName}
                  {...rowData}
                  isMapPage={isMapPage}
                  pitchfinderid={cards.PitchFinderId}
                ></Card>
              );
            })}
        </ul>
      )}
      {!isMapPage && teamCardLength && (
        <div css={styles.buttonWrapper}>
          <Button
            id={MO_MORE_RESULTS}
            onClick={() => {
              history.push(redirectUrl);
            }}
            css={styles.button}
            aria-label="click here to see more results"
          >
            SHOW MORE{' '}
            {isClubPage && (
              <div css={styles.showmore}>
                <ShowMore />
              </div>
            )}
          </Button>
        </div>
      )}
      {data?.totalProvider?.length > 0 && (
        <div css={styles.border}>
          <div css={styles.venueDescription(isMapPage)} role="alert">
            <DarkIcon css={styles.alertIcon} />
            <div>
              <div css={styles.venueInfo}>
                <div css={styles.venues}>
                  {isDesktop === true &&
                    (data?.totalSession?.length > 0
                      ? VENUE_PROVIDER_INFORMATION
                      : VENUE_NO_SESSION_INFORMATION)}
                  {isDesktop === false && (
                    <div css={styles.venueborder}>
                      {data?.totalSession?.length > 0
                        ? VENUE_PROVIDER_INFORMATION
                        : VENUE_NO_SESSION_INFORMATION}
                    </div>
                  )}
                  <Link
                    to={cardurl}
                    onClick={setRecommendationsPreference}
                    css={styles.link(isDesktop, isMapPage)}
                    key={1}
                    aria-label={`Link - opens in a new window`}
                  >
                    More Information <RightArrow css={styles.rightArrow} />
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MORecommendationList;
