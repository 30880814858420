import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors, spacing, breakpoints, typography } = useTheme();

  return {
    answer: css`
      padding-bottom: ${spacing.s};

      button {
        font-size: 1.125rem;
        font-weight: 700;
        padding: 1.1rem ${spacing.s};
        border: 3px solid transparent;

        ${breakpoints.l} {
          &:focus:not(:hover) {
            outline: 0;
            border: 3px solid ${colors.navy};
            background-color: ${colors.white};
            text-decoration: underline;
          }
        }
      }
    `,
    link: css`
      padding: ${spacing.xs};
    `,
    inputWrapper: css`
      display: flex;
      border: 1px solid ${colors.lightGrey};
      border-radius: ${spacing.xs};
      align-items: center;
      padding: 0 ${spacing.m};
      width: 300px;
    `,
    input: css`
      ${typography.base};
      appearance: none;
      background-color: ${colors.white};
      border: none;
      font: inherit;
      color: ${colors.navy};
      line-height: inherit;
      padding: ${spacing.s};
      transition-duration: 0.2s;
      transition-property: border-color;
      width: 100%;

      &:hover,
      &:focus {
        outline: 0;
        border-color: ${colors.navy};
      }
    `,
    error: css`
      padding: ${spacing.xs};
      color: ${colors.red};
    `,
  };
};
