import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { colors, breakpoints, spacing, borderRadius } = useTheme();

  return {
    card: css`
      display: grid;
      align-items: center;
      z-index: 1;
      position: relative;
      padding: ${spacing.m} 1.5rem ${spacing.xl} 1.5rem;
      background-color: ${colors.white};
      border-radius: ${borderRadius.l};
      margin-bottom: ${spacing.m};
      min-height: 300px;
      box-shadow: 0px 4px 6px rgba(91, 104, 133, 0.1);

      &::after {
        z-index: -1;
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        /* we need a border-radius here because
        the image overrides the parent border radius */
        border-radius: ${borderRadius.l};
        background: left bottom no-repeat;
        background-image: url(${publicPath(
          '/images/mobile/recommendations/crop-1@x1.png'
        )});
        background-size: 100%;
        z-index: -1;
      }

      ${breakpoints.xl} {
        padding: ${spacing.l};
        min-height: 500px;
        border-radius: ${borderRadius.l};

        &::after {
          background: 101% bottom no-repeat;
          background-image: url(${publicPath(
            '/images/desktop/recommendations/crop-1@x1.png'
          )});
          background-size: contain;
        }
      }
    `,
  };
};
