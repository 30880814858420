import PropTypes from 'prop-types';

/** @jsxImportSource @emotion/react */
import { filtersPanelAnalytics } from '../../analyticsConsts';
import Button from '../Button';
import useStyles from './styles';

const { FILTERS_PANEL_CTA_SAVE_CHANGES } = filtersPanelAnalytics;

const FilterWrapper = ({
  label,
  helpText,
  currentValue,
  valueFormatter,
  config,
  onClick,
  children,
  disabled,
}) => {
  const styles = useStyles();

  const value =
    typeof valueFormatter === 'function'
      ? valueFormatter(currentValue, config)
      : currentValue;

  return (
    <div>
      <div css={styles.info}>
        <span css={styles.label}>{label}</span>
        {value && <span css={styles.value}>{value}</span>}
      </div>
      {helpText && <div css={styles.helpText}>{helpText}</div>}
      {children}
      <div css={styles.ctaWrapper}>
        <Button
          id={FILTERS_PANEL_CTA_SAVE_CHANGES}
          primary
          css={styles.saveBtn}
          onClick={onClick}
          disabled={disabled}
        >
          Save changes
        </Button>
      </div>
    </div>
  );
};

FilterWrapper.prototype = {
  label: PropTypes.string,
  helpText: PropTypes.string,
  currentValue: PropTypes.string,
  valueFormatter: PropTypes.func,
  config: PropTypes.func,
  onClick: PropTypes.func,
  children: PropTypes.object,
  disabled: PropTypes.bool,
};

export default FilterWrapper;
