import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const {
    colors,
    breakpoints,
    screenSizes,
    spacing,
    typography,
    borderRadius,
  } = useTheme();

  return {
    base: css`
      height: 412px;
      max-height: 50.625rem;

      ${breakpoints.m} {
        height: 700px;
      }
    `,
    hero: css`
      grid-template-rows: 90% 10%;
      position: relative;

      > div {
        margin: 0 auto;
        width: 90vw;
        max-width: 360px;
      }


      ${breakpoints.m} {
        padding: 0;
        grid-template-rows: 1fr;
        background-position: -77px 0;


        > div {
          max-width: ${screenSizes.xl};
          align-self: center;
        }


      ${breakpoints.l} {
        background-position: 0 0;
      }
    `,
    footballTypeSection: css`
      position: relative;
      background-color: ${colors.navy};
      z-index: 1;
      margin-top:-1px;
    `,
    everyOneGameSection: css`
      position: relative;
      display: grid;
      background: url(${publicPath('/images/mobile/home/fa-initiative-bg.svg')})
        0 0 no-repeat;
      background-size: cover;
      width: 100%;

      ${breakpoints.l} {
        background: url(${publicPath(
            '/images/desktop/home/fa-initiatives-bg.svg'
          )})
          0 0 no-repeat;
        background-size: cover;
        margin-top: ${spacing.m};
      }
    `,
    everyOneGameContent: css`
      position: relative;
      display: grid;
      width: 100%;

      ${breakpoints.m} {
        grid-template-rows: 35% 65%;
      }

      ${breakpoints.l} {
        height: 47.5rem;
        grid-template-rows: 50% 50%;
      }
    `,
    footballTypeContainer: css`
      display: grid;
      grid-auto-rows: 14.625rem;
      grid-gap: ${spacing.s};
      padding: ${spacing.l} 0 1.5rem;
      grid-template-columns: 1fr;
      margin: auto;
      width: 90vw;
      justify-items: center;
      max-width: ${screenSizes.m};
      grid-template-rows: 42% 50%;

      ${breakpoints.m} {
        padding-bottom: 2.5rem;
        grid-template-columns: 1fr 1fr;
        grid-template-rows: unset;
        grid-gap: ${spacing.m};
        grid-auto-rows: 20.5rem;
        justify-items: initial;
        max-width: ${screenSizes.xl};
      }

      ${breakpoints.xl} {
        padding-bottom: 1.5rem;
        grid-auto-rows: 27.5rem;
        grid-gap: ${spacing.l};
      }
    `,
    initiativeContainer: count => css`
      display: grid;
      max-width: 500px;
      margin: auto;

      ${breakpoints.m} {
        max-width: ${screenSizes.xl};
        grid-template-columns: repeat(${count}, 1fr);
        grid-auto-rows: minmax(12.5rem, auto);
        align-content: center;
      }
    `,
    quickLinkSection: css`
      position: relative;
      background: url(${publicPath('/images/mobile/home/quick-link-bg.svg')}) 0
        0 no-repeat;
      background-size: contain;
      margin-top: -1px;

      ${breakpoints.l} {
        background: url(${publicPath('/images/desktop/home/quick-link-bg.svg')})
          0 0 no-repeat;
        background-size: cover;

        &::before {
          content: '';
          background: url(/images/desktop/home/play-watermark.svg) bottom right
            no-repeat;
          height: 86%;
          position: absolute;
          width: 25%;
          right: 0;
          top: -16rem;
          bottom: 0;
          z-index: 2;
          pointer-events: none;
        }
      }
    `,
    quickLinkContent: css`
      display: grid;
      grid-template-rows: 26% auto;
      max-width: 500px;
      margin: auto;
      width: 90vw;
      height: 60rem;

      ${breakpoints.m} {
        grid-template-rows: 32% auto;
      }

      ${breakpoints.l} {
        grid-template-rows: 40% 50%;
        max-width: ${screenSizes.xl};
        margin: auto;
        height: 50rem;
      }
    `,
    quickLinkContainer: css`
      display: grid;
      background-color: ${colors.lightGrey};
      grid-template-rows: repeat(4, 1fr);
      grid-gap: ${spacing.s};

      ${breakpoints.l} {
        grid-template-columns: 1fr 1fr;
        grid-template-rows: 1fr 1fr;
        grid-auto-flow: column;
        grid-gap: 1.5rem;
        background-color: transparent;
        z-index: 2;
      }
    `,
    quickLinkHeader: css`
      color: ${colors.navy};
      font-size: 28px;
      line-height: 26px;
      letter-spacing: -0.02em;
      font-weight: bold;
      font-style: italic;
      padding-bottom: ${spacing.s};
      position: relative;

      &::after {
        content: '';
        position: absolute;
        left: 5px;
        bottom: 0;
        height: ${borderRadius.m};
        width: 60px;
        background: ${colors.red};
      }

      ${breakpoints.l} {
        font-size: 48px;
        line-height: 42px;
      }
    `,
    quicklinkDescription: css`
      color: ${colors.darkGrey};
      letter-spacing: 0.02em;
      padding-top: ${spacing.s};
    `,
    initiativeHeading: css`
      ${typography.headings[5].mobile};
      color: ${colors.white};
      font-style: italic;
      text-transform: capitalize;
      margin-top: ${spacing.l};
      position: relative;
      width: 100%;
      display: grid;
      padding: 20px;
      align-content: end;

      div {
        text-align: center;
      }

      &::before {
        content: '';
        position: absolute;
        height: 100%;
        width: 100%;

        bottom: 0;
        align-content: end;

        ${breakpoints.m} {
          background: url(${publicPath(
              '/images/desktop/home/every-one-watermark.svg'
            )})
            center bottom no-repeat;
          background-size: contain;
          bottom: -2rem;
        }
      }

      ${breakpoints.l} {
        font-size: 3.875rem;
        line-height: 2.5rem;
        margin-top: ${spacing.xxl};
        padding: 0;
      }
    `,
    infoSection: css`
      display: grid;
      padding-bottom: 1.5rem;
      align-content: end;
      label {
        display: block;
      }

      ${breakpoints.l} {
        padding-bottom: ${spacing.m};
      }
    `,
  };
};
