/** @jsxImportSource @emotion/react */
import { useMemo, useRef } from 'react';

import Map from '../MapPageService';

const ProvidersMap = ({
  data,
  userLocation,
  handleSelect,
  selectedId,
  handleMapMovement,
  momappage = 'false',
}) => {
  const idref = useRef();

  const pushPins = useMemo(
    () =>
      data.map(
        ({
          ClubId,
          CentreId,
          Longitude,
          Latitude,
          ProviderCartDto,
          PitchFinderId,
          SessionCartDto,
        }) => {
          ProviderCartDto?.map(providerdetails => {
            if (!SessionCartDto && ProviderCartDto?.length < 2) {
              idref.current = providerdetails.ProviderId;
            }
            return providerdetails;
          });
          const id = ClubId
            ? ClubId
            : CentreId && !momappage
            ? CentreId
            : idref.current && !momappage
            ? idref.current
            : PitchFinderId;
          return {
            id,
            longitude: Longitude,
            latitude: Latitude,
            handleClick: () => id && handleSelect(id),
          };
        }
      ),
    [data, handleSelect, momappage]
  );

  return (
    <Map
      pushPins={pushPins}
      mapOptions={{
        center: userLocation,
        handleMapMovement,
      }}
      selectedId={selectedId}
    />
  );
};

export default ProvidersMap;
