import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { borderRadius, breakpoints, colors, screenSizes, spacing } =
    useTheme();

  return {
    title: css`
      margin-bottom: 1.5rem;
      border-bottom: 1px solid ${colors.lightGrey};
      padding-bottom: ${spacing.s};
      color: ${colors.navy};

      ${breakpoints.l} {
        font-size: 1.5rem;
      }
    `,
    list: providerData => css`
      display: grid;
      grid-gap: ${spacing.s};

      ${providerData &&
      css`
        margin-bottom: 2rem;
      `}
      ${breakpoints.m} {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    `,
    buttonWrapper: css`
      margin-top: ${spacing.m};
    `,
    button: css`
      font-weight: normal;
      text-transform: uppercase;
      text-decoration: none;
      background-color: ${colors.blue};
      color: ${colors.white};
      margin-bottom: ${spacing.s};
      margin-top: ${spacing.xs};
      font-size: 0.875rem;
      letter-spacing: 0.01em;
      border: 1px solid ${colors.blue};
      border-radius: ${borderRadius.s};
      max-width: 300px;
      display: block;
      padding: 0.9375rem 1.5rem;
      margin: auto;
    `,
    cancelBtn: css`
      background-color: ${colors.white};
      color: ${colors.blue};
      margin: ${spacing.xs} auto ${spacing.s};
    `,
    buttonIcon: (isExpanded, isTeamCard) => css`
      transform: rotate(90deg);
      > g {
        fill: ${isTeamCard ? colors.blue : colors.white};
      }

      ${isExpanded &&
      css`
        transform: rotate(-90deg);
      `}

      @media screen and (max-width: ${screenSizes.m}) {
        display: none;
      }
    `,
    dateFormat: css`
      color: ${colors.navy};
      border-bottom: 1px solid ${colors.greyShade1};
      margin: ${spacing.s} 0;
      display: grid;
      grid-template-columns: 30px auto;
      align-items: center;
      padding-bottom: 0.5rem;
      min-height: 35px;
      font-weight: 700;

      ${breakpoints.m} {
        font-weight: 700;
      }
    `,
  };
};
