/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { contactFormModalAnalytics } from '../../analyticsConsts';
import { EMPTY_STRING, HYPHEN, YOUTH_AGE } from '../../const';
import { useSetContactFormDLContext } from '../../context/setContactFormDL';
import useLocalStorage from '../../hooks/useLocalStorage';
import useMediaQuery from '../../hooks/useMediaQuery';
import useModalOverflow from '../../hooks/useModalOverflow';
import { submitContactForm } from '../../services';
import contactFormDataLayer from '../../utils/contactFormDataLayer';
import ContactForm from '../ContactForm';
import ContactSuccess from '../ContactSuccess';
import { CircleClose } from '../Icons';
import Loader from '../Loader';
import Modal from '../Modal';
import Text from '../Text';
import { getRequestPayload } from './contactRequestPayload';
import useStyles from './styles';

const { CONTACT_FORM_CTA_EXIT, CONTACT_FORM_TITLE } = contactFormModalAnalytics;
const CONTACT_FORM_DATA = 'contact_form_data';

const ContactFormModal = ({
  onClose,
  isVisible,
  clubDetails,
  isClub,
  selectedTeamData,
}) => {
  const styles = useStyles();
  const [isContactSuccess, setIsContactSuccess] = useState(false);
  const [isAlreadyFormSent, setIsAlreadyFormSent] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const isDesktop = useMediaQuery('screen and (min-width: 992px)');
  const { getLocalItem, setLocalItem } = useLocalStorage();
  const { title, clubid: clubId, accreditationid: WgsClubID } = clubDetails;
  const { contactFormDL } = useSetContactFormDLContext();
  const [queryAbtValue, setQueryAbtValue] = useState('');

  const loader = useRef();

  useModalOverflow();

  // to check whether the form has been already submitted or not
  useEffect(() => {
    const accessedClubs = JSON.parse(getLocalItem(CONTACT_FORM_DATA)) || [];
    setIsAlreadyFormSent(accessedClubs.includes(clubId));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // once mail sent to recipient, save it in session storage to
  // enable captcha section in the form(when user try to submit the form second time)
  const SaveDataToLocalStorage = data => {
    let accessedClubs = [];
    accessedClubs = JSON.parse(getLocalItem(CONTACT_FORM_DATA)) || [];
    if (!accessedClubs.includes(data)) {
      accessedClubs.push(data);
      setLocalItem(CONTACT_FORM_DATA, JSON.stringify(accessedClubs));
    }
  };

  // contact form submit
  const handleContact = data => {
    setContactFormDataLayer(
      data?.queryIsAbout,
      'CTA - SUBMIT',
      data?.age,
      'cta_primary_click',
      'submit'
    );
    setIsSaving(true);
    const isYouth = parseInt(data.age) > YOUTH_AGE;
    const requestPayload = getRequestPayload(
      WgsClubID,
      data,
      isYouth,
      false,
      title
    );
    const playerParentPayload = getRequestPayload(
      WgsClubID,
      data,
      isYouth,
      true,
      title
    );

    setQueryAbtValue(data?.queryIsAbout);

    Promise.all([
      submitContactForm(playerParentPayload),
      submitContactForm(requestPayload),
    ])
      .then(response => {
        if (response) {
          setIsContactSuccess(true);
          SaveDataToLocalStorage(clubId);
          setContactFormDataLayer(
            data?.queryIsAbout,
            `${
              isYouth ? 'Email sent to club' : 'Email sent to welfare officer'
            }`,
            data.age,
            'generate_lead',
            EMPTY_STRING,
            `${
              isYouth ? 'Email sent to club' : 'Email sent to welfare officer'
            }`
          );
        }
        setIsSaving(false);
      })
      .catch(() => {
        setIsSaving(false);
      });
  };

  const setContactFormDataLayer = (
    queryValue,
    text,
    age,
    eventText,
    linkText,
    submitText
  ) => {
    contactFormDataLayer({
      ...contactFormDL,
      eventLabel: `FF Club Contact Form  ${HYPHEN + text}`,
      eventparameter2: `${selectedTeamData?.TeamName}`,
      eventparameter3: queryValue,
      eventparameter4: age ? age : contactFormDL?.eventparameter4,
      eventparameter5: contactFormDL?.eventparameter5,
      item_list_name: isClub ? 'club info' : 'team info',
      event_name: eventText,
      category_type: queryValue,
      link_text: linkText,
      submission_status: submitText,
    });
  };
  // when we save we want to send the focus to the loader
  // so that it is announced to screen readers
  useLayoutEffect(() => {
    if (isSaving) {
      loader.current?.focus({ preventScroll: true });
    }
  }, [isSaving, loader]);

  return (
    <Modal isOpen={isVisible} onClose={onClose} skipInitialFocus>
      <div css={styles.modalSize}>
        <div css={styles.base}>
          <Fragment>
            {isSaving ? (
              <Loader message="Sending Mail..." ref={loader} />
            ) : (
              <Fragment>
                <button
                  id={CONTACT_FORM_CTA_EXIT}
                  aria-label="Close"
                  css={styles.closeBtn}
                  onClick={() => {
                    onClose();
                    !isContactSuccess &&
                      setContactFormDataLayer(
                        queryAbtValue,
                        'CTA - CLOSE',
                        contactFormDL?.eventparameter4,
                        'close_button_click'
                      );
                  }}
                >
                  {<CircleClose fillColor="transparent" />}
                </button>
                {isContactSuccess && (
                  <ContactSuccess
                    onClick={() => {
                      onClose();
                    }}
                    isDesktop={isDesktop}
                  />
                )}
                {!isContactSuccess && (
                  <Fragment>
                    <Text
                      as="h3"
                      size="7"
                      css={styles.title}
                      id={CONTACT_FORM_TITLE}
                    >
                      Your message to {title}
                    </Text>
                    <div css={styles.question} className="scroller">
                      <ContactForm
                        handleContact={handleContact}
                        onClick={queryValue => {
                          onClose();
                          setContactFormDataLayer(
                            queryValue,
                            'CTA - CANCEL',
                            contactFormDL?.eventparameter4,
                            'cta_secondary_click',
                            'cancel'
                          );
                          setQueryAbtValue(queryValue);
                        }}
                        isAlreadyFormSent={isAlreadyFormSent}
                        isDesktop={isDesktop}
                      />
                    </div>
                    <div css={styles.fakeButtonOverlay}></div>
                  </Fragment>
                )}
              </Fragment>
            )}
          </Fragment>
        </div>
      </div>
    </Modal>
  );
};

ContactFormModal.proptype = {
  onClose: PropTypes.func,
  isVisible: PropTypes.bool,
  clubDetails: PropTypes.object,
  isClub: PropTypes.bool,
  selectedTeamData: PropTypes.object,
};

export default ContactFormModal;
