import { DISABILITY_TYPES_MAPPING, ONBOARDING_PREFERENCES } from '../../const';
import useLocalStorage from '../../hooks/useLocalStorage';

const rule = [
  DISABILITY_TYPES_MAPPING[2], // Blind
  DISABILITY_TYPES_MAPPING[12], // Powerchair
  DISABILITY_TYPES_MAPPING[10], // Pan-Disability
];

export default () => {
  const { getLocalItem } = useLocalStorage();
  const preferencesObj = JSON.parse(getLocalItem(ONBOARDING_PREFERENCES)) || {};
  const { DisabilityType: prefDisabilityTypes } = preferencesObj;
  // when disability not present in preferences.
  if (prefDisabilityTypes && prefDisabilityTypes.length === 0) {
    return;
  }

  // when multiple disability in preferences.
  let title = 'Pan Disability';

  // when one disability in preferences, check against the rule to prepare title.
  if (prefDisabilityTypes && prefDisabilityTypes.length === 1) {
    const disability =
      DISABILITY_TYPES_MAPPING[prefDisabilityTypes[0]?.DisabilityId];

    return rule.includes(disability) ? disability : `${disability} & ${title}`;
  }

  return title;
};
