/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Fragment } from 'react';

import {
  AGE_UK,
  JUST_PLAY,
  JUST_PLAY_PROTEIN,
  SQUAD,
  UNITE,
  WEETABIX_WILDCATS,
  WILDCATS,
} from '../../const';
import Text from '../Text';
import VenueEvent from '../VenueEvents';
import useStyles from './styles';

const EVENTS_MAPPING = {
  [WILDCATS]: 1,
  [WEETABIX_WILDCATS]: 1,
  [JUST_PLAY]: 2,
  [JUST_PLAY_PROTEIN]: 2,
  [AGE_UK]: 3,
  [UNITE]: 4,
  [SQUAD]: 4,
};

const normaliseEventsImages = (events, pageData) =>
  events
    .map(event => {
      const { initiativeLogo, ...rest } =
        // get the correct initiative in the page data (from Sitecore)
        pageData?.faInitiatives?.find(
          eventContent => eventContent.id === EVENTS_MAPPING[event]
        ) || {};

      return {
        logos: initiativeLogo,
        initiative: event,
        ...rest,
      };
    })
    // filter out any non-found event
    ?.filter(Boolean);

const FAInitiativeEvent = ({
  events,
  pageData,
  faInitiativeDetails,
  venueSessions,
  isDesktop,
  isProvider,
  pitchid,
}) => {
  const styles = useStyles();

  // add below mentioned types alone in FAInitiative
  events = events?.filter(type => {
    return (
      type === WILDCATS ||
      type === WEETABIX_WILDCATS ||
      type === JUST_PLAY ||
      type === JUST_PLAY_PROTEIN
    );
  });

  const faEvents = events && normaliseEventsImages(events, pageData);

  return (
    <Fragment>
      {faEvents && faEvents.length > 0 && (
        <div css={styles.middle}>
          <Text as="h3" size="7" css={styles.title}>
            FA Events
          </Text>
          <div css={styles.content}>
            <div css={styles.eventsWrapper}>
              <div css={styles.events}>
                {faEvents.map(event => (
                  <VenueEvent
                    key={event.initiative}
                    faInitiativeDetails={faInitiativeDetails}
                    venueSessions={venueSessions}
                    {...event}
                    isDesktop={isDesktop}
                    isProvider={isProvider}
                    pitchid={pitchid}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </Fragment>
  );
};

FAInitiativeEvent.prototype = {
  events: PropTypes.object,
  pageData: PropTypes.object,
  faInitiativeDetails: PropTypes.object,
  venueSessions: PropTypes.object,
  isDesktop: PropTypes.bool,
  isProvider: PropTypes.bool,
};

export default FAInitiativeEvent;
