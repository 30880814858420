/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';

import { filtersPanelAnalytics } from '../../analyticsConsts';
import useGeolocation, { LOADING_STATE } from '../../hooks/useGeolocation';
import FilterWrapper from '../FilterWrapper';
import LinkButton from '../LinkButton';
import Loader from '../Loader';
import LocationAutoSuggest from '../LocationAutoSuggest';
import useStyles from './styles';

const { FILTERS_PANEL_CTA_CURRENT_LOCATION } = filtersPanelAnalytics;

const FilterLocation = ({
  onSave,
  preferenceKey,
  currentValue: value,
  ...rest
}) => {
  const styles = useStyles();
  const [currentValue, setCurrentValue] = useState(value);
  // saves the value from the autocomplete widget
  const [locationData, setLocationData] = useState();
  // saves the value from the browser geolocation
  const { geolocationData, getGeolocationData } = useGeolocation();
  const { coordinates, status, statusMessage } = geolocationData || {};

  const locationSelected = locationData || geolocationData;

  // Updates current value based on selected data
  useEffect(() => {
    if (locationData) {
      const { readableLocation } = locationData;
      setCurrentValue(readableLocation);
    }
    // Empty value will show "My location"
    if (geolocationData) {
      setCurrentValue();
    }
  }, [locationData, geolocationData]);

  const onClick = () => {
    const { latitude, longitude, readableLocation } =
      coordinates || locationData;

    onSave({
      [preferenceKey]: readableLocation,
      Latitude: latitude,
      Longitude: longitude,
    });
  };

  return (
    <FilterWrapper
      currentValue={currentValue}
      onClick={onClick}
      disabled={!locationSelected}
      {...rest}
    >
      <div css={styles.filterWrapper}>
        <LocationAutoSuggest
          placeholder="Enter your postcode"
          setLocationData={setLocationData}
        />
        <div css={styles.locationLink}>
          <div aria-live="polite">
            {status === LOADING_STATE ? (
              <Loader inline message={statusMessage} />
            ) : (
              statusMessage
            )}
          </div>
          {!locationSelected && (
            <Fragment>
              Or{' '}
              <LinkButton
                id={FILTERS_PANEL_CTA_CURRENT_LOCATION}
                css={styles.locationBtn}
                onClick={() => {
                  getGeolocationData();
                  // Reset location data (from autosuggest)
                  setLocationData();
                }}
              >
                share current location
              </LinkButton>
            </Fragment>
          )}
        </div>
      </div>
    </FilterWrapper>
  );
};

FilterLocation.prototype = {
  onSave: PropTypes.func,
  preferenceKey: PropTypes.object,
  value: PropTypes.string,
};

export default FilterLocation;
