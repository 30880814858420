/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import useTheme from '../../hooks/useTheme';
import Text from '../Text';
import useStyles from './styles';

const InfoFacilities = ({
  title,
  values,
  isTeamInfo = false,
  isVenuePage = false,
  isProvider = false,
}) => {
  const styles = useStyles();
  const { colors } = useTheme();

  return (
    <div css={styles.facilitiesWrapper}>
      <Text as="h3" size="6" css={styles.title}>
        {title}
      </Text>
      <div css={styles.infoFacilities(isTeamInfo, isVenuePage, isProvider)}>
        {values &&
          values.length > 0 &&
          values.map((selectedValue, ind) => {
            if (selectedValue?.rows && selectedValue?.rows.length === 0) {
              return null;
            }
            const Icon = selectedValue?.icon;
            return (
              <div key={ind} css={styles.facilities(isTeamInfo)}>
                <div css={styles.iconWrapperClub}>
                  <Icon color={colors.white} height={32}></Icon>
                </div>
                <ul
                  css={styles.listFacilities}
                  aria-label={selectedValue?.title}
                >
                  {selectedValue?.rows &&
                  selectedValue?.rows.length > 0 &&
                  Array.isArray(selectedValue?.rows) ? (
                    selectedValue?.rows.map((facility, index) => (
                      <li key={index} css={styles.facilitiesItem}>
                        {facility}
                      </li>
                    ))
                  ) : (
                    <li key={1} css={styles.facilitiesItem}>
                      {selectedValue?.rows}
                    </li>
                  )}
                </ul>
              </div>
            );
          })}
      </div>
    </div>
  );
};

InfoFacilities.prototype = {
  title: PropTypes.string,
  values: PropTypes.object,
  isTeamInfo: PropTypes.bool,
  isVenuePage: PropTypes.bool,
  isProvider: PropTypes.bool,
};

export default InfoFacilities;
