import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { breakpoints, colors, spacing } = useTheme();

  return {
    dropdownContainer: css`
      display: inline-block;
      text-align: left;
      border-bottom: 1px solid ${colors.grey};
      margin-left: 8px;

      ${breakpoints.l} {
        text-align: center;
        border-bottom: 1px solid transparent;
        padding-bottom: 0;
        position: unset;
      }
    `,
    dropdown: css`
      background: transparent;
      color: ${colors.navy};
      font-size: 18px;
      font-weight: 500;
      text-transform: uppercase;
      white-space: nowrap;
      border-bottom: ${spacing.xs} solid transparent;
      padding: 20px 0 12px 0;
      text-decoration: none;

      ${breakpoints.l} {
        display: inline; 
        text-align: center;
        color: ${colors.darkGrey};
        font-size: 14px;
        height: 74px;
        svg {
          margin-left: 10px;
        }
      }

      &::before {
        content: '';
        position: absolute;
        left: 0;
        width: 0;
        border-radius: 2px;
        z-index: 1;
        border-bottom: 1px solid transparent;
        transition: width 0.4s ease-in-out;
      }

      &:not(:disabled):hover {
        background: transparent;
        position: relative;
        color: ${colors.navy};
        ${breakpoints.l} {
          &::before {
            width: 100%;
          }
        }
      }
    `,
    activeMenu: css`
      position: relative;
      ${breakpoints.l} {
        color: ${colors.navy};
        &::before {
          content: '';
          position: absolute;
          right: 0;
          width: 100%;
          top: 0;
          bottom: 0;
          border-bottom: 0.2rem solid ${colors.red};
        }
      }
    `,
    dropdownContent: (backgroundImg, isOpened, isDesktop, headerHeight) => css`
      position: absolute;
      margin: 0 auto;
      right: 0;
      left: 0;
      top: 0;
      width: 100%;
      padding: 0 1rem;
      z-index: 2;
      background: ${colors.lightGrey};
      height: calc(100vh - 212px);
      overflow-y: auto;

      ${isDesktop &&
      isOpened &&
      css`
        animation: sliding 0.4s ease-in-out 1 forwards;

        @keyframes sliding {
          0% {
            transform: translateX(100%);
          }
          100% {
            transform: translateX(0%);
          }
        }
      `}

      ${breakpoints.l} {
        position: absolute;
        backdrop-filter: blur(16px);
        text-align: center;
        box-shadow: 0px 8px 16px 0px rgba(0, 0, 0, 0.2);
        background: rgba(255, 255, 255, 0.9) url(${backgroundImg});
        top: ${headerHeight};
        padding: 0;
        height: auto;
        z-index: 1;
        overflow: hidden;
      }
    `,
    dropdownMenu: css`
      ${breakpoints.l} {
        display: grid;
        grid-auto-flow: column;
        justify-content: center;
        padding: ${spacing.s} ${spacing.m};
      }
    `,
    dropdownBtn: css`
      background: none;
      border: 1px solid transparent;
      vertical-align: middle;
      outline: 0;
      height: 74px;

      &:not(:disabled):focus {
        outline: 2px solid ${colors.navy};
        height: auto;
      }
    `,
    grid: length => css`
      display: grid;
      margin-left: 8px;

      @media screen and (min-width: 1024px) {
        grid-auto-flow: column;
        grid-column-gap: 2rem;
        margin-left: 0;
        grid-template-columns: repeat(${length}, 150px);
      }

      @media screen and (min-width: 1440px) {
        grid-template-columns: repeat(${length}, 160px);
      }
    `,
    noChildGrid: css`
      margin-left: 8px;
      position: relative;

      li {
        border-bottom: 1px solid ${colors.greyShade1};
        text-align: left;
        display: grid;
      }

      ${breakpoints.l} {
        padding-left: 2rem;
        margin-left: 0;
        li {
          position: relative;

          &:not(:disabled):hover {
            border-bottom: 1px solid ${colors.blue};
            a {
              color: ${colors.blue};
              display: initial;

              &::after {
                transform: translateX(0) rotate(-90deg);
                opacity: 1;
              }
            }
          }
        }
      }
    `,
    rightArrowBtn: isMobile => css`
      padding: 0;
      right: 0;
      top: 0;
      float: right;
      width: 44px;
      &:focus,
      &:not(:disabled):focus {
        outline: none;
        box-shadow: none;
      }
      svg {
        color: ${colors.darkGrey};
      }
      ${isMobile &&
      css`
        display: block;
        text-align: left;
        &::after {
          content: '';
          background: url(${publicPath('/images/common/accordion-arrow.svg')})
            no-repeat;
          float: right;
          height: 0.8rem;
          width: 30px;
        }
      `}
    `,
    container: isDesktop => css`
      ${isDesktop &&
      css`
        display: flex;
        grid-auto-flow: column;
        align-items: center;
        justify-content: space-between;
      `}
    `,
    promoContainer: css`
      ${breakpoints.m} {
        display: flex;
        flex-basis: 100%;
        grid-template-columns: repeat(4, 16.375rem);
        justify-content: center;
        max-width: 65.625rem;
        margin: 0 auto;
        padding: ${spacing.m} 0;
        grid-column-gap: ${spacing.s};
      }
    `,
    dropdownIcon: isExpanded => css`
      color: ${colors.red};
      transition: transform 0.3s ease-in-out;
      transform: rotate(${!isExpanded ? '1deg' : '180deg'});
      margin-bottom: 3px;
    `,
    subTitle: css`
      color: ${colors.navy};
      background: transparent;
      text-decoration: none;
      font-size: 1.125rem;
      line-height: 1.625rem;
      font-weight: normal;
      text-transform: uppercase;
      font-weight: normal;
      padding: 10px 0 6px 0;
      margin: 10px 0 6px 0;
      position: relative;

      ${breakpoints.l} {
        &::after {
          content: '';
          background: url(${publicPath('/images/common/triangle-arrow.svg')})
            no-repeat center center;
          transform: translateX(-2rem) rotate(-90deg);
          transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
          opacity: 0;
          height: 20px;
          width: 20px;
          position: absolute;
          display: inline-block;
          z-index: 1;
        }
      }

      &:focus {
        color: ${colors.blue};
        background-color: transparent;

        &::after {
          transform: translateX(0) rotate(-90deg);
          opacity: 1;
        }
      }
    `,
  };
};
