/** @jsxImportSource @emotion/react */

import { onBoardingModalAnalytics } from '../../analyticsConsts';
import { CircleArrow } from '../Icons';
import LinkButton from '../LinkButton';
import useStyles from './styles';

const { ONBOARDING_CTA_NEXT_QUESTION } = onBoardingModalAnalytics;

const NextNav = ({ onAnswer, ...rest }) => {
  const styles = useStyles();

  return (
    <LinkButton
      id={ONBOARDING_CTA_NEXT_QUESTION}
      css={styles.link}
      onClick={() => onAnswer()}
      aria-label="Go to the next question"
      {...rest}
    >
      Next <CircleArrow />
    </LinkButton>
  );
};

export default NextNav;
