/** @jsxImportSource @emotion/react */
import { forwardRef, Fragment, useLayoutEffect, useRef } from 'react';

import { recommendationsMapPageAnalytics } from '../../analyticsConsts';
import formatAddress from '../../utils/formatAddress';
import HiddenContent from '../HiddenContent';
import { Pin } from '../Icons';
import useStyles from '../MOProviderClubCard/styles';
import Text from '../Text';

const { CTA_PROVIDER_VENUE_CARD, PROVIDER_VENUE_CARD_TITLE } =
  recommendationsMapPageAnalytics;

const ProviderCenterCard = forwardRef(
  (
    {
      index,
      CentreName,
      CentreId,
      Address,
      DistanceFromUserLocation,
      SessionCartDto,
      SessionCount,
      OfferedCentres,
      onClick,
      isSelected,
      Latitude,
      Longitude,
      providerid,
      PitchFinderId,
      providerName,
      ...rest
    },
    ref
  ) => {
    const cardRef = useRef();
    const styles = useStyles();
    const isSessionAvailable = SessionCartDto && SessionCartDto.length > 0;
    const handleKeyPress = event => {
      const enterOrSpace = event.which === 13 || event.which === 32;

      if (enterOrSpace) {
        event.preventDefault();
        onClick(CentreId);
      }
    };

    // if the card gets selected move the focus to it
    useLayoutEffect(() => {
      if (isSelected && cardRef.current) {
        setTimeout(() => {
          cardRef.current.focus();
          cardRef.current.scrollIntoView({
            behaviour: 'smooth',
            block: 'nearest',
          });
        }, 300);
      }
    }, [isSelected]);

    const accessibleProps = isSelected ? {} : { role: 'button', tabIndex: 0 };

    return (
      <Fragment>
        <li tabIndex={-1} ref={ref}>
          <div
            {...accessibleProps}
            id={`${CTA_PROVIDER_VENUE_CARD}-${index}`}
            aria-expanded={isSelected}
            css={styles.base}
            onClick={() => onClick(CentreId ? CentreId : providerid)}
            onKeyPress={handleKeyPress}
            ref={cardRef}
          >
            <div css={styles.titleWrapper()}>
              <Text
                id={`${PROVIDER_VENUE_CARD_TITLE}-${index}`}
                css={styles.title()}
                size="6"
              >
                {providerName.length > 0 && !CentreId
                  ? providerName
                  : CentreName.toLowerCase()}
              </Text>
            </div>
            <div
              css={styles.info(
                isSessionAvailable ? isSessionAvailable : PitchFinderId
              )}
            >
              <div css={styles.address}>{formatAddress(rest)}</div>
              <div css={styles.distance}>
                <HiddenContent>Distance </HiddenContent>
                <Pin />
                <span>{DistanceFromUserLocation} Miles</span>
              </div>
            </div>
            {isSessionAvailable && (
              <div css={styles.search}>
                {SessionCount} sessions match your search
              </div>
            )}
            {PitchFinderId && !CentreId && (
              <div css={styles.search}>
                View Provider details for session info
              </div>
            )}
          </div>
        </li>
      </Fragment>
    );
  }
);

export default ProviderCenterCard;
