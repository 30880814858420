import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors, spacing, typography } = useTheme();

  return {
    info: css`
      display: grid;
      grid-template-columns: 50% 50%;
      align-items: center;
      width: 100%;
      padding: ${spacing.s} 0;
      border-bottom: 1px solid ${colors.lightGrey};
      color: ${colors.navy};
    `,
    label: css`
      ${typography.headings[6].mobile};
      text-align: left;
    `,
    value: css`
      text-align: right;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `,
    helpText: css`
      padding-top: ${spacing.s};
      color: ${colors.darkGrey};
    `,
    ctaWrapper: css`
      border-top: 1px solid ${colors.lightGrey};
      padding-top: ${spacing.s};
    `,
    saveBtn: css`
      text-transform: uppercase;
    `,
  };
};
