import { useEffect, useState } from 'react';

import {
  ADULT_CLUB,
  AGE_UK,
  JUST_PLAY_PROTEIN,
  LETGIRLSPLAY,
  ONBOARDING_PREFERENCES,
  SQUAD,
  WEETABIX_WILDCATS,
  YOUTH_CLUBS_TYPE,
} from '../../const';
import useLocalStorage from '../useLocalStorage';

export default pageData => {
  if (!pageData) {
    return;
  }
  const { getLocalItem } = useLocalStorage();
  const [filteredData, setFilteredData] = useState();
  const preferencesObj = JSON.parse(getLocalItem(ONBOARDING_PREFERENCES)) || {};
  const { Age, Category } = preferencesObj;
  const YOUTH_CLUB = YOUTH_CLUBS_TYPE.filter(data => data === Category)[0];

  useEffect(() => {
    let initiatives = [];
    switch (Category) {
      case LETGIRLSPLAY:
        initiatives = [3, 4, 6];
        break;
      case WEETABIX_WILDCATS:
        initiatives = [4, 5, 6];
        break;
      case JUST_PLAY_PROTEIN:
        initiatives = Age > 55 ? [3] : [7, 8];
        break;
      case AGE_UK:
        initiatives = [2];
        break;
      case ADULT_CLUB:
        initiatives = [2];
        break;
      case YOUTH_CLUB:
        initiatives = [6];
        break;
      case SQUAD:
        initiatives = [5, 6, 1];
        break;
      default:
        initiatives = [];
        break;
    }
    const filteredData = pageData?.faInitiatives.filter(item =>
      initiatives.includes(item.id)
    );
    setFilteredData(filteredData);
  }, [Age, Category, pageData, YOUTH_CLUB]);

  return filteredData;
};
