/** @jsxImportSource @emotion/react */
import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import {
  BACK_TO_RESULTS,
  venueDetailsPageAnalytics,
} from '../../analyticsConsts';
import {
  EMPTY_STRING,
  KICKABOUT_CASUAL,
  PAGE_NUMBER,
  PAGE_SIZE,
  PREVIOUSPAGE,
  SELECTED_RECOMMENDATIONS_TYPE_ID,
  VENUE_INFO,
  VENUE_NO_SESSION_INFORMATION,
  VENUE_PROVIDER_INFORMATION,
  VENUE_TAB,
} from '../../const';
import { useMLRecommendations } from '../../context/mlRecommendations';
import { useMORecommendations } from '../../context/moRecommendations';
import { usePageHistory } from '../../context/pageHistory';
import { usePageInfo } from '../../context/pageInfo';
import { usePreferencesContext } from '../../context/preferences';
import { useRecommendations } from '../../context/recommendations';
import weekdaysFormatter from '../../formatters/weekdays';
import useClubVenueCoordination from '../../hooks/useClubVenueCoordination';
import useElementHeight from '../../hooks/useElementHeight';
import useErrorBoundary from '../../hooks/useErrorBoundary';
import useLocalStorage from '../../hooks/useLocalStorage';
import useMediaQuery from '../../hooks/useMediaQuery';
import normaliseSessionLogo from '../../normaliser/sessionlogo';
import {
  getProviderDetails,
  getSessionLogo,
  getVenueDetails,
  getVenuePageData,
} from '../../services';
import normaliseProviderDetails from '../../utils/normaliseProviderDetails';
import normaliseVenueDetails from '../../utils/normaliseVenueDetails';
import useStylesClub from '../ClubPage/styles';
import ContactMap from '../ContactMap';
import ExternalLink from '../ExternalLink';
import FAInitiativeEvent from '../FAInitiativeEvent';
import Footer from '../Footer';
import HeroProvider from '../HeroProvider';
import { DarkIcon, RightArrow } from '../Icons';
import Link from '../Link';
import Loader from '../Loader';
import StickyScrollSpyNav from '../StickyScrollSpyNav';
import Text from '../Text';
import VenueDetails from '../VenueDetails';
import VenueList from '../VenueList';
import {
  backURL,
  casualDataCal,
  filteredData,
  linkUrl,
  mlCard,
  providerPreviousPageName,
  refreshingSession,
  titleName,
  venueData,
  venueDetailsCond,
  venueSession,
} from './conditionCheck';
import defaultPreferences from './defaultPreferences';
import useStyles from './styles';

const { VENUE_NAME_HEADING, VENUE_SESSIONS_TITLE, VENUE_DETAILS_WRAPPER } =
  venueDetailsPageAnalytics;

// TODO: change the weekdaysFormatter to say `Any days`
export const getWeekdays = days =>
  weekdaysFormatter(days) === 'Any' ? 'any days' : weekdaysFormatter(days);

const VenuePage = () => {
  const { id } = useParams();
  const throwError = useErrorBoundary();
  const [providerPageData, setProviderPageData] = useState();
  const [isLoadingPageData, setIsLoadingPageData] = useState(true);
  const { preferences } = usePreferencesContext();
  const {
    [SELECTED_RECOMMENDATIONS_TYPE_ID]: selectedRecommendationType,
    Age,
    Category,
  } = preferences || EMPTY_STRING;
  const [venueDetails, setVenueDetails] = useState();
  const [logoDetails, setLogoDetails] = useState();
  const [filteredSessions, setFilteredSessions] = useState();
  const [unfilteredSessions, setUnfilteredSessions] = useState();
  const isDesktop = useMediaQuery('screen and (min-width: 768px)');
  const [isFiltersApplied, setisfiltersapplied] = useState(
    // if we have a selected recommendation type then we'll use the preferences
    // when getting the provider details, otherwise we show all sessions
    !!selectedRecommendationType || !!preferences?.Category
  );
  const { casualRecommendations } = useRecommendations();
  const { casualMORecommendations } = useMORecommendations();
  const [isLoading, setIsLoading] = useState(false);
  const [isVenueDetailsLoading, setIsVenueDetailsLoading] = useState(false);
  const [venueTabs, setVenueTabs] = useState();
  const loadingRef = useRef();
  const pitchfinderref = useRef();
  const venueLoadingRef = useRef();
  const listRef = useRef();
  const providerRef = useRef();
  const { height: titleHeight, elementRef: titleElement } = useElementHeight();
  const styles = useStyles();
  const stylesClub = useStylesClub();
  const { isMLCard } = useMLRecommendations();
  const [, setPageInfo] = usePageInfo();
  const { getLocalItem } = useLocalStorage();
  const previousUrl = getLocalItem(PREVIOUSPAGE);
  const { previousPage } = usePageHistory();
  const backUrl = backURL(previousPage, previousUrl);

  const previousPageName = providerPreviousPageName(previousUrl);

  const sourceLoction = useClubVenueCoordination(
    selectedRecommendationType,
    preferences
  );

  const [providersData, setProvidersData] = useState([]);
  const [isProviderData, setIsProviderData] = useState();
  const totalcenters = [];
  totalcenters.push(casualMORecommendations);
  const casualData = casualDataCal(
    casualMORecommendations,
    totalcenters,
    casualRecommendations
  );

  useEffect(() => {
    setProvidersData([]);
    venueData(
      casualData,
      id,
      pitchfinderref,
      setIsProviderData,
      setProvidersData,
      providerRef
    );

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [casualRecommendations, id, venueDetails]);

  // set page info only when we have the venue name
  useEffect(() => {
    if (!venueDetails?.title) {
      return;
    }
    setPageInfo('venue', venueDetails.title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venueDetails]);

  useEffect(() => {
    localStorage.setItem('pathInfomation', window.location.pathname);
  }, []);

  useEffect(() => {
    getVenuePageData()
      .then(({ data }) => {
        const venuePageDetails = data;
        const crypto = window.crypto;
        const randomNumber =
          Math.round(crypto.getRandomValues(new Uint8Array(10))[0] / 64) + 1;
        venuePageDetails.hero.images.desktop.src = `/images/desktop/venue/hero/${randomNumber}.jpg`;
        venuePageDetails.hero.images.mobile.src = `/images/mobile/venue/hero/${randomNumber}.jpg`;
        setProviderPageData(venuePageDetails);
        setIsLoadingPageData(false);
      })
      .catch(throwError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSessionLogo()
      .then(({ data }) => {
        const logoData = normaliseSessionLogo(data);
        setLogoDetails(logoData);
      })
      .catch(throwError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    setIsVenueDetailsLoading(true);

    const preferenceWithPagination = {
      ...defaultPreferences, // get the page after the current one
      [PAGE_SIZE]: 6,
      [PAGE_NUMBER]: 1,
    };

    Promise.all([
      getProviderDetails(
        id,
        providerRef.current,
        mlCard(
          isMLCard,
          preferenceWithPagination,
          isFiltersApplied,
          defaultPreferences,
          preferences
        )
      )
        .then(({ data }) => {
          if (data) {
            const { sessions, sessionsCount, venueTabs, ...normalisedDetails } =
              normaliseProviderDetails(data);
            venueDetailsCond(venueDetails, setVenueDetails, normalisedDetails);
            setIsLoading(false);
            setIsVenueDetailsLoading(false);
          }
        })
        .catch(() => {
          getVenueDetails(
            id,
            mlCard(
              isMLCard,
              preferenceWithPagination,
              isFiltersApplied,
              defaultPreferences,
              preferences
            )
          ).then(({ data }) => {
            if (data) {
              const {
                sessions,
                sessionsCount,
                venueTabs,
                ...normalisedDetails
              } = normaliseVenueDetails(data);
              setVenueTabs(venueTabs);
              venueDetailsCond(
                venueDetails,
                setVenueDetails,
                normalisedDetails
              );
              filteredData(
                isFiltersApplied,
                setFilteredSessions,
                filteredSessions,
                sessions,
                setUnfilteredSessions,
                unfilteredSessions
              );
              setIsLoading(false);
              setIsVenueDetailsLoading(false);
            }
          });
        }),
    ]);

    // we send the preferences only if we want the filters applied
    // otherwise we pass an empty object

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filteredSessions, isFiltersApplied, unfilteredSessions]);

  // if we are loading and we already have the venue details and
  // at least one type of session then we are fetching
  // the other type of sessions
  const isRefreshingSessions = refreshingSession(
    isLoading,
    venueDetails,
    filteredSessions,
    unfilteredSessions
  );

  // we are using useLayoutEffect here because we cannot rely on
  // setIsRefreshingDetails having run before we shift focus above
  // due to React async setting of state
  useLayoutEffect(() => {
    // if we're refreshing then shift focus to the loading message
    if (isRefreshingSessions) {
      loadingRef.current?.focus({ preventScroll: true });
      return;
    }
    // filtersApplied has changed
    // if both filtered and unfiltered data have been loaded
    // we can shift the focus to the list when the button is clicked
    if (filteredSessions && unfilteredSessions) {
      setTimeout(() => {
        listRef.current?.focus({ preventScroll: true });
      }, 300);
    }
  }, [
    filteredSessions,
    isFiltersApplied,
    isRefreshingSessions,
    unfilteredSessions,
  ]);

  useLayoutEffect(() => {
    if (isVenueDetailsLoading) {
      venueLoadingRef.current?.focus();
    }
  }, [isVenueDetailsLoading]);

  // if there is no selected recommendations type or
  // the filters are removed show different title
  const title = titleName(selectedRecommendationType, isFiltersApplied, Age);

  const venueSessions = venueSession(
    isFiltersApplied,
    filteredSessions,
    unfilteredSessions
  );

  useEffect(() => {
    if (isLoadingPageData) {
      return <Loader message="Loading venue info" />;
    }
  }, [isLoadingPageData]);

  return (
    <div css={styles.base}>
      <section>
        {providerPageData && (
          <HeroProvider
            title={venueDetails?.title}
            address={venueDetails?.address}
            distance={venueDetails?.distance}
            latitude={venueDetails?.latitude}
            longitude={venueDetails?.longitude}
            titleHeight={titleHeight}
            css={styles.hero}
            type={'casual football'}
            textSize={3}
            ref={titleElement}
            hasPreferences={!!selectedRecommendationType}
            analyticsId={VENUE_NAME_HEADING}
            previousPageData={{ name: previousPageName, backUrl: backUrl }}
            sourceLoction={sourceLoction}
            {...providerPageData.hero}
            Category={Category}
            isvenue="true"
          />
        )}
      </section>
      <section
        id={VENUE_DETAILS_WRAPPER}
        css={styles.wrapper(titleHeight, !!selectedRecommendationType)}
      >
        <div css={stylesClub.card}>
          {isVenueDetailsLoading && (
            <Loader
              ref={venueLoadingRef}
              css={stylesClub.loader}
              message="Loading venue Details"
            />
          )}
          {!isVenueDetailsLoading && (
            <StickyScrollSpyNav
              nav={VENUE_TAB}
              titleHeight={titleHeight}
              title={venueDetails?.title}
              isVenue="true"
            >
              <section ref={React.createRef()} css={stylesClub.navContent}>
                {linkUrl(selectedRecommendationType, Category, isDesktop) && (
                  <Link
                    id={BACK_TO_RESULTS}
                    to={backUrl}
                    css={[stylesClub.backLink, styles.backButton]}
                  >
                    <RightArrow css={stylesClub.arrowRotate} />{' '}
                    <span>Back to {previousPageName} view</span>
                  </Link>
                )}
                {venueDetails?.providerid && (
                  <div>
                    <Text
                      id={VENUE_SESSIONS_TITLE}
                      as="h2"
                      size="5"
                      css={styles.title}
                    >
                      {title}
                    </Text>
                  </div>
                )}
                {venueSessions && (
                  <VenueList
                    title={title}
                    titleAnalyticsId={VENUE_SESSIONS_TITLE}
                    data={venueSessions}
                    type={KICKABOUT_CASUAL}
                    canToggle={selectedRecommendationType && !isMLCard}
                    isFiltered={isFiltersApplied}
                    toggleFiltered={() =>
                      setisfiltersapplied(!isFiltersApplied)
                    }
                    providerData={isProviderData}
                    venueTabs={venueTabs}
                    ref={{ ref1: listRef, ref2: loadingRef }}
                    isRefreshingSessions={isRefreshingSessions}
                    isDesktop={isDesktop}
                    venueTitle={venueDetails?.title}
                    logoDetails={logoDetails}
                    footballtype={venueDetails?.footballtype?.values[0]?.rows}
                  />
                )}
                {isProviderData && (
                  <div css={styles.border}>
                    <div css={styles.venueDescription} role="alert">
                      <DarkIcon css={styles.alertIcon} />
                      <div>
                        <div css={styles.venueInfo}>
                          {id.length < 12
                            ? VENUE_NO_SESSION_INFORMATION
                            : VENUE_PROVIDER_INFORMATION}
                        </div>
                        <div css={styles.venueInfo}>{VENUE_INFO}</div>
                        <div css={styles.externalLink}>
                          {providersData.map((providerCard, index) => {
                            return (
                              <ExternalLink
                                href={`/provider/${providerCard.ProviderId}p${
                                  venueDetails?.pitchfinderid
                                    ? venueDetails?.pitchfinderid
                                    : pitchfinderref.current
                                }`}
                                css={styles.link}
                                key={index}
                                aria-label={`Link - opens in a new window`}
                                target="_blank"
                                rel="noopener"
                              >
                                {' '}
                                {providerCard.ProviderName}
                                <RightArrow css={styles.rightArrow} />
                              </ExternalLink>
                            );
                          })}{' '}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </section>
              <section ref={React.createRef()} css={stylesClub.navContent}>
                {venueDetails && (
                  <Fragment>
                    <VenueDetails
                      {...venueDetails}
                      pageData={providerPageData}
                    />
                  </Fragment>
                )}
              </section>
              <section ref={React.createRef()} css={stylesClub.navContent}>
                {venueDetails && (
                  <Fragment>
                    <ContactMap
                      {...venueDetails}
                      titleHeader="Venue Contact"
                      subTitle="For information about a specific session, please get in touch with the relevant session contact"
                      locationDetails={{
                        latitude: venueDetails.latitude,
                        longitude: venueDetails.longitude,
                        title: 'New Club',
                      }}
                      contact={venueDetails.contact}
                      sourceLoction={sourceLoction}
                      isVenueLocation="true"
                    />
                    {(venueSessions || venueDetails?.pitchfinderid) && (
                      <FAInitiativeEvent
                        {...venueDetails}
                        pageData={providerPageData}
                        faInitiativeDetails={logoDetails}
                        venueSessions={venueSessions}
                        isDesktop={isDesktop}
                        pitchid={venueDetails?.pitchfinderid}
                      />
                    )}
                  </Fragment>
                )}
              </section>
            </StickyScrollSpyNav>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default VenuePage;
