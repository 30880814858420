/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';

import { CHARLIMIT_HOMEPAGE } from '../../const';
import Button from '../Button';
import OnboardingCriteria from '../OnboardingCriteria';
import Text from '../Text';
import useStyles from './styles';

const Hero = ({
  textSize = 1,
  text,
  images,
  overlayVariation = 4,
  ctaText,
  ctaId,
  wrapperId,
  ctaOnClick,
  isOnBoardingCriteriaIncluded = false,
  scrolldirectionContext,
  isHomePage = false,
  headerHeight,
  isRec = false,
  ...rest
}) => {
  const styles = useStyles();
  const hasCta = ctaText && ctaOnClick;
  const [hasLoaded, setHasLoaded] = useState(false);
  const { main, secondary } = text;

  useEffect(() => {
    setTimeout(() => {
      setHasLoaded(true);
    }, 300);
  }, []);

  return (
    <div
      id={wrapperId}
      css={styles.hero(
        images,
        overlayVariation,
        scrolldirectionContext,
        headerHeight,
        isRec
      )}
      {...rest}
    >
      <div
        data-test-id="hero-text"
        css={styles.textWrapper(hasLoaded)}
        {...rest}
      >
        {main && (
          <Text as="h1" size={textSize} css={styles.mainText(isHomePage)}>
            {main}
          </Text>
        )}
        {secondary && (
          <Text css={styles.secondaryText}>
            {secondary.substring(0, CHARLIMIT_HOMEPAGE)}
          </Text>
        )}
        {hasCta && (
          <Button
            primary
            id={ctaId}
            css={styles.cta}
            onClick={() => ctaOnClick()}
          >
            {ctaText}
          </Button>
        )}
        {isOnBoardingCriteriaIncluded && <OnboardingCriteria />}
      </div>
    </div>
  );
};

Hero.prototype = {
  textSize: PropTypes.number,
  text: PropTypes.object,
  images: PropTypes.object,
  overlayVariation: PropTypes.number,
  ctaText: PropTypes.string,
  ctaId: PropTypes.string,
  wrapperId: PropTypes.string,
  ctaOnClick: PropTypes.func,
  isOnBoardingCriteriaIncluded: PropTypes.bool,
  scrolldirectionContext: PropTypes.string,
  isHomePage: PropTypes.bool,
  headerHeight: PropTypes.string,
  isRec: PropTypes.bool,
};

export default Hero;
