import publicPath from '../../utils/publicPath';

const clubPage = {
  hero: {
    images: {
      desktop: {
        src: publicPath(
          '/images/desktop/club/hero/1.jpg'
        ),
      },
      mobile: {
        src: publicPath(
          '/images/mobile/club/hero/1.jpg'
        ),
      },
    },
  },
};

export default clubPage;
