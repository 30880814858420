import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, colors, spacing, typography, borderRadius } = useTheme();

  return {
    base: css`
      min-height: 130px;
      max-width: 365px;
      border-radius: ${borderRadius.l};
      box-shadow: 0px 4px 6px rgba(91, 104, 133, 0.1);
      color: ${colors.navy};
      font-weight: 700;
      background-color: ${colors.white};
      cursor: pointer;
      text-decoration: none;
      margin: 0 ${spacing.xs} ${spacing.xs};
      padding: ${spacing.s};
      border: 3px solid transparent;
      outline: 0;

      &:focus {
        border: 3px solid ${colors.navy};
      }

      &[aria-expanded='true'] {
        margin: ${spacing.xs};
        margin-bottom: 0;
        border-bottom-left-radius: 0;
        border-bottom-right-radius: 0;

        height: 100%;
        cursor: default;

        ${breakpoints.l} {
          height: auto;
          border: 1px solid ${colors.navy};
          border-radius: ${borderRadius.l};
        }
      }

      &[aria-expanded='false']:hover {
        background-color: ${colors.lightBlue};
      }
    `,
    wrapper: isSelected => css`
      ${isSelected &&
      css`
        display: contents;

        ${breakpoints.l} {
          display: block;

          &:not(:first-of-type) {
            border-top: 1px solid ${colors.white};
            margin-top: ${spacing.s};
            padding-top: ${spacing.s};
          }

          &:not(:last-of-type) {
            border-bottom: 1px solid ${colors.white};
            margin-bottom: ${spacing.s};
            padding-bottom: ${spacing.s};
          }
        }
      `}
    `,
    titleWrapper: css`
      position: relative;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -4px;
        width: 32px;
        border-bottom: ${borderRadius.s} solid ${colors.red};
      }
    `,
    title: css`
      font-family: 'FS Dillon Bold';
      font-weight: bold;
      line-height: 1;
      width: 95%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      ${breakpoints.l} {
        font-family: 'FS Dillon Bold';
        font-weight: bold;
        line-height: 1.1;
      }
    `,
    info: (isTeamAvailable, providerid) => css`
      ${typography.body.mobile};
      display: grid;
      grid-template-columns: 100%;
      ${isTeamAvailable | providerid &&
      css`
        grid-template-columns: 70% 30%;
      `},
      grid-column-gap: 0.5rem;
      margin-top: 14px;
    `,
    distance: css`
      color: ${colors.darkGrey};
      font-size: 14px;
      line-height: 18px;
      font-weight: normal;

      svg {
        margin-right: 0.3rem;
      }
    `,
    address: css`
      font-size: 14px;
      line-height: 18px;
      color: ${colors.darkGrey};
      letter-spacing: 0.02em;
      font-weight: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      text-transform: capitalize;
    `,
    search: css`
      font-size: 14px;
      line-height: 20px;
      margin: 7px 0;
    `,
    teamInfo: css`
      color: ${colors.darkGrey};
      padding-bottom: 5px;
      display: grid;
      grid-template-columns: 2fr auto 1fr;
      p {
        font-size: 14px;
        line-height: 20px;
        font-weight: normal;
        span {
          padding-left: 7px;
        }
      }
    `,
    teamName: css`
      width: 95%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    `,
    teamPlayDay: isCasual => css`
      text-align: center;
      &::before {
        content: '|';
        padding-right: 5px;
      }
      ${isCasual &&
      css`
        &::after {
          content: '|';
          padding-left: 4px;
        }
      `}
    `,
    image: css`
      max-width: 100%;
      max-height: 30px;
      margin: 0 auto;
    `,
    venueOfferWrapper: css`
      margin-top: 7px;
      p {
        font-size: 14px;
        line-height: 16px;
        &:first-of-type {
          font-weight: bold;
        }
      }
    `,
    otherType: css`
      color: ${colors.darkGrey};
      padding: 7px 0;
    `,
    buttonWrapper: css`
      margin-top: ${spacing.s};
      a {
        height: 2.063rem;
        padding: 9px 16px;
      }
    `,
    options: css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-gap: 10px;
    `,
    primaryBtn: css`
      text-transform: uppercase;
    `,
    secondaryBtn: css`
      background-color: ${colors.white};
      border: 1px solid ${colors.blue};
    `,
    logoWrapper: css`
      display: flex;
      align-items: center;
    `,
  };
};
