import isempty from 'lodash.isempty';

import {
  ADULT_CLUB,
  ADULT_CLUB_AGE_QUESTION,
  ADULT_CLUB_CANCEL_URL,
  AGE_UK,
  AGE_UK_CANCEL_URL,
  EMPTY_STRING,
  JUST_PLAY_AGE_QUESTION,
  JUST_PLAY_CANCEL_URL,
  JUST_PLAY_PROTEIN,
  LET_GIRLS_PLAY_CANCEL_URL,
  LET_GO_GIRLS_PLAY,
  LETGIRLSPLAY,
  MY_CHILD,
  SQUAD,
  SQUAD_CANCEL_URL,
  VOLUNTEER_CLUB_CANCEL_URL,
  WEETABIX_WILDCATS,
  WILD_CATS_CANCEL_URL,
  YOUTH_CLUB_CANCEL_URL,
  YOUTH_CLUBS_TYPE,
} from '../../const';
import ageGroup from '../../mocks/data/questions/ageGroup';

const filteredMiniOnboardingData = data => {
  let questions = [];
  switch (true) {
    case JSON.parse(sessionStorage.getItem('ISLETGOGIRLSPLAY')):
      questions = [2, 6, 7, 8];
      break;
    case JSON.parse(sessionStorage.getItem('ISWILDCATS')):
      questions = [2, 6, 7, 8];
      break;
    case JSON.parse(sessionStorage.getItem('ISJUSTPLAY')):
      questions = [2, 3, 6, 7, 8];
      break;
    case JSON.parse(sessionStorage.getItem('ISSQUADGIRLS')):
      questions = [6, 7, 8];
      break;
    case JSON.parse(sessionStorage.getItem('ISAGEUK')):
      questions = [2, 3, 6, 7, 8];
      break;
    case JSON.parse(sessionStorage.getItem('ISYOUTHCLUB')):
      questions = [2, 3, 6, 7, 8];
      const selectedQuestions = data.filter(item =>
        questions.includes(item.questionOrder)
      );
      return [...selectedQuestions, ...ageGroup];
    case JSON.parse(sessionStorage.getItem('ISADULTCLUB')):
      questions = [2, 3, 6, 7, 8];
      break;
    case JSON.parse(sessionStorage.getItem('ISVOLUNTEER')):
      questions = [2, 3, 6, 7, 8];
      break;
    default:
      questions = [];
      break;
  }
  return !isempty(questions)
    ? data.filter(item => questions.includes(item.questionOrder))
    : data;
};

const getPayloadData = answerData => {
  switch (true) {
    case JSON.parse(sessionStorage.getItem('ISLETGOGIRLSPLAY')):
      return {
        FootballType: 1,
        PlayWith: 2,
        SearchForUser: 'Myself',
        WeekDays: '1,2,3,4,5,6,7',
        ...answerData,
      };
    case JSON.parse(sessionStorage.getItem('ISWILDCATS')):
      return {
        FootballType: 1,
        PlayWith: 2,
        SearchForUser: 'Myself',
        WeekDays: '1,2,3,4,5,6,7',
        ...answerData,
      };
    case JSON.parse(sessionStorage.getItem('ISJUSTPLAY')):
      return {
        FootballType: 1,
        SearchForUser: 'Myself',
        WeekDays: '1,2,3,4,5,6,7',
        ...answerData,
      };
    case JSON.parse(sessionStorage.getItem('ISAGEUK')):
      return {
        FootballType: 1,
        SearchForUser: 'Myself',
        WeekDays: '1,2,3,4,5,6,7',
        ...answerData,
      };
    case JSON.parse(sessionStorage.getItem('ISSQUADGIRLS')):
      return {
        FootballType: 1,
        PlayWith: 2,
        SearchForUser: MY_CHILD,
        WeekDays: '1,2,3,4,5,6,7',
        Age: 13,
        ...answerData,
      };
    case JSON.parse(sessionStorage.getItem('ISYOUTHCLUB')):
      return {
        FootballType: 2,
        SearchForUser: MY_CHILD,
        WeekDays: '1,2,3,4,5,6,7',
        ...answerData,
      };
    case JSON.parse(sessionStorage.getItem('ISADULTCLUB')):
      return {
        FootballType: 2,
        SearchForUser: 'Myself',
        WeekDays: '1,2,3,4,5,6,7',
        ...answerData,
      };
    case JSON.parse(sessionStorage.getItem('ISVOLUNTEER')):
      return {
        FootballType: 2,
        SearchForUser: 'Myself',
        WeekDays: '1,2,3,4,5,6,7',
        ...answerData,
      };
    default:
      return answerData;
  }
};

const getRedirectUrl = () => {
  switch (true) {
    case JSON.parse(sessionStorage.getItem('ISLETGOGIRLSPLAY')):
      return LET_GIRLS_PLAY_CANCEL_URL;
    case JSON.parse(sessionStorage.getItem('ISWILDCATS')):
      return WILD_CATS_CANCEL_URL;
    case JSON.parse(sessionStorage.getItem('ISJUSTPLAY')):
      return JUST_PLAY_CANCEL_URL;
    case JSON.parse(sessionStorage.getItem('ISAGEUK')):
      return AGE_UK_CANCEL_URL;
    case JSON.parse(sessionStorage.getItem('ISSQUADGIRLS')):
      return SQUAD_CANCEL_URL;
    case JSON.parse(sessionStorage.getItem('ISYOUTHCLUB')):
      return YOUTH_CLUB_CANCEL_URL;
    case JSON.parse(sessionStorage.getItem('ISADULTCLUB')):
      return ADULT_CLUB_CANCEL_URL;
    case JSON.parse(sessionStorage.getItem('ISVOLUNTEER')):
      return VOLUNTEER_CLUB_CANCEL_URL;
    default:
      return;
  }
};

const ageHeaderTitle = questionTitle => {
  switch (true) {
    case JSON.parse(sessionStorage.getItem('ISLETGOGIRLSPLAY')):
      return LET_GO_GIRLS_PLAY;
    case JSON.parse(sessionStorage.getItem('ISJUSTPLAY')):
    case JSON.parse(sessionStorage.getItem('ISYOUTHCLUB')):
      return JUST_PLAY_AGE_QUESTION;
    case JSON.parse(sessionStorage.getItem('ISADULTCLUB')):
      return ADULT_CLUB_AGE_QUESTION;
    case JSON.parse(sessionStorage.getItem('ISVOLUNTEER')):
      return 'For what age group would you like to volunteer?';
    default:
      return questionTitle;
  }
};

const genderHeaderTitle = questionTitle => {
  switch (true) {
    case JSON.parse(sessionStorage.getItem('ISVOLUNTEER')):
      return 'For what gender teams would you like to volunteer?';
    default:
      return questionTitle;
  }
};

const getInitialAgeLimit = inputTitle => {
  switch (true) {
    case JSON.parse(sessionStorage.getItem('ISJUSTPLAY')):
      return {
        startingAge: 16,
        endingAge: 99,
        ageText: 'Enter your age (between 16 & 99)',
        validationErrorMsg: `Please enter ages within 16-99`,
      };
    case JSON.parse(sessionStorage.getItem('ISWILDCATS')):
      return {
        startingAge: 5,
        endingAge: 11,
        ageText: 'Enter your age (between 5 & 11)',
        validationErrorMsg: `Please enter ages within 5-11`,
      };
    case JSON.parse(sessionStorage.getItem('ISYOUTHCLUB')):
      return {
        startingAge: 5,
        endingAge: 18,
        ageText: "Enter the player's age (between 5 & 18)",
        validationErrorMsg:
          'Youth club football is only available to 5-18 year olds',
      };
    case JSON.parse(sessionStorage.getItem('ISADULTCLUB')):
      return {
        startingAge: 18,
        endingAge: 99,
        ageText: 'Enter your age (between 18 & 99)',
        validationErrorMsg: 'Please enter ages within 18-99',
      };
    case JSON.parse(sessionStorage.getItem('ISVOLUNTEER')):
      return {
        startingAge: 5,
        endingAge: 99,
        ageText: 'Enter team age (between 5 & 99)',
        validationErrorMsg: `Please enter ages within 5-99`,
      };
    default:
      return {
        startingAge: 5,
        endingAge: 99,
        ageText: inputTitle,
        validationErrorMsg: `Please enter ages within 5-99`,
      };
  }
};

const getMOHeroProperties = ({ Age, AgeGroup, Category }) => {
  //filtering youthClub title and category for specific age group
  const YOUTH_CLUB = YOUTH_CLUBS_TYPE.filter(data => data === Category)[0];
  switch (Category) {
    case LETGIRLSPLAY:
      return {
        isLogo: true,
        title: EMPTY_STRING,
        img: {
          desktop: '/images/desktop/moHeaderLogo/letgirlsplay.svg',
          mobile: '/images/mobile/moHeaderLogo/letgirlsplay.svg',
        },
        linkText: 'Learn more about LetsGirlsPlay',
        categoryName: WEETABIX_WILDCATS,
        category:
          Age > 17 ? "Women's Football sessions" : "Girl's Football session",
        age: `${Age} year-old`,
      };
    case WEETABIX_WILDCATS:
      return {
        isLogo: true,
        title: EMPTY_STRING,
        img: {
          desktop: '/images/desktop/moHeaderLogo/weetabix-wildcats.svg',
          mobile: '/images/mobile/moHeaderLogo/weetabix-wildcats.svg',
        },
        linkText: 'Learn more about Wildcats',
        categoryName: WEETABIX_WILDCATS,
        category: 'Wildcats sessions',
        age: `${Age} year-old`,
      };
    case JUST_PLAY_PROTEIN:
      return {
        isLogo: true,
        title: EMPTY_STRING,
        img: {
          desktop:
            '/images/desktop/moHeaderLogo/snickers-protein-just-play.svg',
          mobile: '/images/mobile/moHeaderLogo/snickers-protein-just-play.svg',
        },
        linkText: 'Learn more about Justplay',
        categoryName: JUST_PLAY_PROTEIN,
        category: 'Snickers Protein Just Play',
        age: `${Age} year-old`,
      };
    case SQUAD:
      return {
        isLogo: true,
        title: EMPTY_STRING,
        img: {
          desktop:
            '/images/desktop/moHeaderLogo/squad-girls-football-header.svg',
          mobile: '/images/mobile/moHeaderLogo/squad-girls-football-header.svg',
        },
        linkText: 'Learn more about Squad girls football',
        categoryName: SQUAD,
        category: "Squad Girls' Football sessions",
        age: '12-15 year-old',
      };
    case AGE_UK:
      return {
        isLogo: true,
        title: EMPTY_STRING,
        img: {
          desktop: '/images/desktop/moHeaderLogo/age-uk-walking-football.svg',
          mobile: '/images/mobile/moHeaderLogo/age-uk-walking-football.svg',
        },
        linkText: 'Learn more about Ageuk',
        categoryName: JUST_PLAY_PROTEIN,
        category: 'Age UK sessions',
      };
    case YOUTH_CLUB:
      const youthClub = {
        isLogo: false,
        title: 'Youth Football (Development - U6)',
        img: EMPTY_STRING,
        linkText: 'Learn more about youth football',
      };
      switch (AgeGroup) {
        case 'U6':
          return {
            ...youthClub,
            title: 'Youth Football (Development - U6)',
            category: 'Development clubs',
          };
        case 'U7':
        case 'U8':
        case 'U9':
        case 'U10':
          return {
            ...youthClub,
            title: `Youth Football (Mini Soccer - ${AgeGroup})`,
            category: 'Mini Soccer clubs',
          };
        case 'U11':
        case 'U12':
          return {
            ...youthClub,
            title: `Youth Football (9v9 - ${AgeGroup})`,
            category: 'Youth 9v9 clubs',
          };
        case 'U13':
        case 'U14':
        case 'U15':
        case 'U16':
        case 'U17':
        case 'U18':
          return {
            ...youthClub,
            title: `Youth Football (11v11 - ${AgeGroup})`,
            category: 'Youth 11v11 clubs',
          };
        default:
          return {
            ...youthClub,
            title: 'Adult 11v11',
            category: 'Adult 11v11 clubs',
          };
      }
    case ADULT_CLUB:
      return {
        isLogo: false,
        title: 'Adult 11v11',
        img: EMPTY_STRING,
        linkText: 'Learn more about adult football',
        category: 'Adult 11v11 clubs',
      };
    default:
      return;
  }
};

const getCategoryName = preference => {
  switch (true) {
    case JSON.parse(sessionStorage.getItem('ISLETGOGIRLSPLAY')):
      return LETGIRLSPLAY;
    case JSON.parse(sessionStorage.getItem('ISWILDCATS')):
      return WEETABIX_WILDCATS;
    case JSON.parse(sessionStorage.getItem('ISJUSTPLAY')):
      return JUST_PLAY_PROTEIN;
    case JSON.parse(sessionStorage.getItem('ISVOLUNTEER')):
      return JUST_PLAY_PROTEIN;
    case JSON.parse(sessionStorage.getItem('ISSQUADGIRLS')):
      return SQUAD;
    case JSON.parse(sessionStorage.getItem('ISAGEUK')):
      return AGE_UK;
    case JSON.parse(sessionStorage.getItem('ISYOUTHCLUB')):
      if (!preference) {
        return 'Youth club';
      }
      if (preference) {
        let categoryName;
        const ageGroup = preference?.AgeGroup;
        switch (true) {
          case ['U5', 'U6'].includes(ageGroup):
            categoryName = 'Development';
            break;
          case ['U7', 'U8', 'U9', 'U10'].includes(ageGroup):
            categoryName = 'Mini Soccer';
            break;
          case ['U11', 'U12'].includes(ageGroup):
            categoryName = '9v9';
            break;
          case [
            'U13',
            'U14',
            'U15',
            'U16',
            'U17',
            'U18',
            'U19',
            'Open Aged',
          ].includes(ageGroup):
            categoryName = '11v11';
            break;
          default:
            categoryName = EMPTY_STRING;
        }
        return categoryName;
      }
      break;
    case JSON.parse(sessionStorage.getItem('ISADULTCLUB')):
      return '11v11';
    default:
      return;
  }
};

export {
  ageHeaderTitle,
  filteredMiniOnboardingData,
  genderHeaderTitle,
  getCategoryName,
  getInitialAgeLimit,
  getMOHeroProperties,
  getPayloadData,
  getRedirectUrl,
};
