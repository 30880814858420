import { MY_CHILD, MYSELF, SEARCH_FOR_ID, SOMEONE_ELSE } from '../../../const';
import publicPath from '../../../utils/publicPath';

export default [
  {
    id: '588f380b-5900-4bc6-a771-88bfc61964ff',
    questionOrder: 1,
    readableId: SEARCH_FOR_ID,
    type: 'single',
    title: {
      [MYSELF]: 'Who are you searching for?',
      [SOMEONE_ELSE]: 'Who are you searching for?',
    },
    answers: [
      {
        answerId: 1,
        analyticsId: 'my_self',
        title: MYSELF,
        answerType: 'primary',
        value: MYSELF,
        image: publicPath('/images/common/cutouts/myself.png'),
        keylineVariant: 3,
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: '346dbfab-03fa-4a3a-ab18-b461d0d56dc7',
          },
        ],
      },
      {
        answerId: 2,
        analyticsId: 'my_child',
        title: MY_CHILD,
        answerType: 'primary',
        value: SOMEONE_ELSE,
        image: publicPath('/images/common/cutouts/my-child.png'),
        keylineVariant: 2,
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: '346dbfab-03fa-4a3a-ab18-b461d0d56dc7',
          },
        ],
      },
      {
        answerId: 3,
        analyticsId: 'someone_else',
        title: SOMEONE_ELSE,
        answerType: 'secondary',
        value: SOMEONE_ELSE,
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: '346dbfab-03fa-4a3a-ab18-b461d0d56dc7',
          },
        ],
      },
    ],
  },
];
