/** @jsxImportSource @emotion/react */
import { Fragment, useEffect, useState } from 'react';

import { EMPTY_STRING } from '../../const';
import publicPath from '../../utils/publicPath';
import Image from '../Image';
import Text from '../Text';
import useStyles from './styles';

export const getformatFixtureDate = (selectedDate) => {
  if (!selectedDate)
    return EMPTY_STRING;
  const dateOj = selectedDate.split(" ");
  const day = selectedDate.substring(6, 8);
  const month = selectedDate.substring(4, 6);
  const year = selectedDate.substring(2, 4);
  return `${day}/${month}/${year}  ${dateOj[1]}`;
}

const MatchDetails = ({
  title,
  matchDateTime,
  locationAddress,
  leagueName,
  homeTeamName,
  roadTeamName,
  agegroup,
  hasAddress,
  ...rest
}) => {
  const styles = useStyles();
  const [homeName, setHomeName] = useState([]);
  const [roadName, setRoadName] = useState([]);

  useEffect(() => {
    if (!homeTeamName || !roadTeamName) {
      return;
    }
    const homeName = homeTeamName.split(agegroup);
    const roadName = roadTeamName.split(agegroup);
    setHomeName(homeName);
    setRoadName(roadName);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [homeTeamName, roadTeamName])

  return (
    <div css={styles.matchDetailsWrapper(hasAddress)}>
      <div css={styles.facilitiesWrapper(hasAddress)}>
        <div css={styles.infoFacilities}>
          <Text as="h2" size="7" css={styles.title}>
            {title}
          </Text>
          <Text as="h3" size="6" css={styles.subtitle}>
            {leagueName}
          </Text>
        </div>
        <Fragment>
          <div css={[styles.fixturesTeam, styles.homeNameWrapper]}>
            <div css={styles.nextFixture}>
              <Text as="div" css={[styles.nextFixtureName, styles.homeNameFixture]} aria-label={homeTeamName}>
                {homeName[0]}
                <Text as="div" css={styles.ageGroupHome}>{agegroup} {homeName[1]}</Text>
              </Text>
              <Text as="div">
                <div css={styles.imgWrapper}>
                  <Image
                    id='homeTeamLogo'
                    css={styles.image}
                    src={publicPath(`/images/common/ef-emblem.svg`)}
                    alt="logo for home team"
                  />
                </div>
              </Text>
            </div>
            <div css={styles.fixturesSplit}>
              {!rest.homeScore && <Text as="p" css={styles.fixturesSplitVersus} aria-label="Versus">
                VS
              </Text>}
              {rest.homeScore && <Text as="p" css={styles.fixturesSplitScore} aria-label="Versus">
                {rest.homeScore} - {rest.roadScore}
              </Text>}
            </div>
            <div css={[styles.nextFixture, styles.roadNameWrapper]}>
              <Text as="div">
                <div css={styles.imgWrapper}>
                  <Image
                    id='homeTeamLogo'
                    css={styles.image}
                    src={publicPath(`/images/common/ef-emblem.svg`)}
                    alt="logo for home team"
                  />
                </div>
              </Text>
              <Text as="div" css={[styles.nextFixtureName, styles.roadNameAgeGroup]} aria-label={roadTeamName}>
                {roadName[0]}
                <Text as="div" css={styles.roadNameAgeGroup}>{agegroup} {roadName[1]}</Text>
              </Text>
            </div>
          </div>
        </Fragment>
      </div>
      {hasAddress && <div css={styles.address}>
        <div css={styles.addressContainer}>
          <div css={styles.mapIcon}></div>
          <div>
            <Text as="p" css={styles.locationAddress}>{rest.venueName}</Text>
            <Text as="p" css={styles.matchDateTime}>
              {getformatFixtureDate(rest.fixtureDate)}
            </Text>
          </div>
        </div>
      </div>}
    </div>
  );
};

export default MatchDetails;
