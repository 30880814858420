/** @jsxImportSource @emotion/react */
import { Fragment, useCallback } from 'react';
import { Link } from 'react-router-dom';

import { moTeamCardAnalytics } from '../../analyticsConsts';
import { HYPHEN } from '../../const';
import { useSetSelectedClubDetailsContext } from '../../context/setSelectedClubDetails';
import { RightArrow } from '../Icons';
import Text from '../Text';
import useStyles from './styles';

const { CLUB_TEAM_CARD_MO_TITLE, CLUB_TEAM_CARD_MO_CTA_CONTACT } =
  moTeamCardAnalytics;

const MOTeamCard = ({
  data,
  index,
  TeamName,
  League,
  FootballType,
  Division,
  PlaysOnDay,
  isOpen,
  Gender,
  isMapPage,
  clubname,
  ...rest
}) => {
  const styles = useStyles();
  const { setSelectedClubDetailsValue } = useSetSelectedClubDetailsContext();

  //converting clubname and teamname to array
  const splitClub = clubname.toLowerCase().split(' ');
  const spliTeam = TeamName.toLowerCase().split(' ');
  // trimming the string
  const trimClubName = clubname.toLowerCase().trim();
  const trimTeamName = TeamName.toLowerCase().trim();

  //filtering the clubname from teamname if string match returns null
  const filterTeam = spliTeam?.filter(value => !splitClub?.includes(value));
  const filterTeamTitle = filterTeam.join(' ');

  //filter for repeated names
  const filterMatched = spliTeam?.filter(value => splitClub?.includes(value));

  //finding match and replacing it
  const matchTeams = trimTeamName.match(trimClubName);
  const modifiedTeam = matchTeams
    ? trimTeamName.replace(matchTeams, '').trim()
    : filterTeamTitle.trim();

  // to remove repeated strings
  const removeRepeatedName = filterMatched.filter(
    (
      s => v =>
        s.has(v) || !s.add(v)
    )(new Set())
  );
  const filteredTeamName =
    !matchTeams && !filterTeamTitle ? removeRepeatedName.join(' ') : '';

  const getRedirectOption = useCallback(() => {
    setSelectedClubDetailsValue({
      ...data,
      selectedTeamDetails: data.TeamsCartDto[index],
    });
    window.location.href = `/team/${rest.TeamId}`;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  return (
    <button css={styles.base} onClick={getRedirectOption}>
      {TeamName && (
        <div css={styles.titleWrapper}>
          <Text
            id={`${CLUB_TEAM_CARD_MO_TITLE}-${index}`}
            as="h4"
            size="6"
            css={styles.title}
          >
            {filteredTeamName
              ? filteredTeamName
              : modifiedTeam && modifiedTeam !== '-' && modifiedTeam !== '.'
              ? modifiedTeam
              : TeamName}
          </Text>
        </div>
      )}
      <div css={styles.infoWrapper}>
        <Fragment>
          <Text>League</Text>
          <Text as="span" css={styles.infoValue}>
            {League || HYPHEN}
          </Text>
        </Fragment>

        <div css={styles.formatWrapper}>
          <div>
            <Text>
              Division
              <Text as="span" css={styles.infoValue}>
                {Division || HYPHEN}
              </Text>
            </Text>
          </div>
          <div>
            <Text css={styles.format}>
              Gender
              <Text as="span" css={styles.infoValue}>
                {Gender || HYPHEN}
              </Text>
            </Text>
          </div>
          <div>
            <Text css={styles.format}>
              Playing
              <Text as="span" css={styles.infoValue}>
                {PlaysOnDay || HYPHEN}
              </Text>
            </Text>
          </div>
        </div>

        <div css={styles.linkWrapper}>
          <Link
            id={`${CLUB_TEAM_CARD_MO_CTA_CONTACT}-${index}`}
            css={styles.link}
            to={`/team/${rest.TeamId}`}
            aria-label={`Show more about Team - opens in a new tab`}
          >
            More Info <span css={styles.TeamName}>for {TeamName}</span>
            <RightArrow css={styles.externalLink} />
          </Link>
        </div>
      </div>
    </button>
  );
};

export default MOTeamCard;
