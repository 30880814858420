/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';

import { venueDetailsPageAnalytics } from '../../analyticsConsts';
import { useFilterPanelToggleContext } from '../../context/filterPanelToggle';
import idFromString from '../../utils/idFromString';
import ExternalLink from '../ExternalLink';
import { RightArrow } from '../Icons';
import Text from '../Text';
import useStyles from './styles';

const { FA_EVENTS_FIND_MORE } = venueDetailsPageAnalytics;

const VenueEvent = ({
  description,
  images,
  logos,
  initiative,
  href,
  faInitiativeDetails,
  eventId,
  venueSessions,
  isDesktop,
  isProvider,
  pitchid,
}) => {
  const styles = useStyles();
  const [faDeskImage, setFaDeskImage] = useState({});
  const [faMobileImage, setFaMobileImage] = useState({});
  const [faDescription, setFaDescription] = useState();
  const [ctaLink, setCtaLink] = useState();
  const faImg = faMobileImage?.mobileImage
    ? faMobileImage?.mobileImage
    : images.desktop.src;
  const deskImg = isDesktop ? faDeskImage?.deskImage : faImg;
  const faImage = faMobileImage?.mobileImage
    ? `${faMobileImage?.mobileImage} ${faMobileImage?.mobileImageWidth}w`
    : `${images.desktop.src} ${images.desktop.width}w,
${images.mobile.src} ${images.mobile.width}w`;
  const { isFilterEnabled } = useFilterPanelToggleContext();

  useEffect(() => {
    venueSessions?.map(data => {
      const venueData = data?.map(value => {
        const categoryId = value.CategoryTypeId;
        if (faInitiativeDetails) {
          faInitiativeDetails?.FA?.map(event => {
            const sessionId = event?.sessionId;
            if (parseInt(sessionId) === categoryId) {
              const deskImage = event?.initiativeLogo?.desktop?.src;
              const deskImageWidth = event?.initiativeLogo?.desktop?.width;
              setFaDeskImage({ deskImage, deskImageWidth });
              const mobileImage = event?.initiativeLogo?.mobile?.src;
              const mobileImageWidth = event?.initiativeLogo?.mobile?.width;
              setFaMobileImage({ mobileImage, mobileImageWidth });
              const initiativeDes = event?.description;
              setFaDescription(initiativeDes);
              const link = event?.href;
              setCtaLink(link);
            }
            return sessionId;
          });
        }
        return categoryId;
      });
      return venueData;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [faInitiativeDetails, eventId]);

  return (
    <div css={styles.card}>
      <ExternalLink
        href={ctaLink ? ctaLink : href || '/'}
        css={styles.overlay}
        tabIndex={isFilterEnabled === true ? -1 : 0}
      >
        {images && (
          <img
            srcSet={
              isDesktop
                ? `${faDeskImage?.deskImage} ${faDeskImage?.deskImageWidth}w`
                : faImage
            }
            sizes={
              faDeskImage && faMobileImage
                ? `(min-width: 600px) ${faDeskImage.deskImageWidth}px,
              ${faMobileImage.mobileImageWidth}px`
                : `(min-width: 600px) ${images.desktop.width}px,
              ${images.mobile.width}px`
            }
            src={
              isProvider || pitchid
                ? isDesktop
                  ? images.desktop.src
                  : images.mobile.src
                : deskImg
            }
            alt={`Find out more about ${initiative}`}
            css={styles.imgSection}
          />
        )}
      </ExternalLink>
      <div css={styles.infoSection}>
        <Text css={styles.description}>
          {faDescription ? faDescription : description}
        </Text>
        <ExternalLink
          tabIndex={isFilterEnabled === true ? -1 : 0}
          id={`${FA_EVENTS_FIND_MORE}-${idFromString(initiative, '_')}`}
          href={ctaLink ? ctaLink : href || '/'}
          aria-label={`Find out more about ${initiative} - opens in a new window`}
          css={styles.learnMore}
        >
          <span>Find out More</span> <RightArrow css={styles.rightArrow} />
        </ExternalLink>
      </div>
    </div>
  );
};

export default VenueEvent;
