import { createContext, useCallback, useContext, useState } from 'react';

import {
  CLUB,
  DONT_MIND,
  KICKABOUT_CASUAL,
  RECOMMENDATIONS_ML_CASUAL,
  RECOMMENDATIONS_ML_CLUB,
} from '../const';
import {
  getLocalItem,
  removeLocalItem,
  setLocalItem,
} from '../hooks/useLocalStorage';

const localKeyMapping = {
  [KICKABOUT_CASUAL]: RECOMMENDATIONS_ML_CASUAL,
  [CLUB]: RECOMMENDATIONS_ML_CLUB,
  [DONT_MIND]: RECOMMENDATIONS_ML_CLUB,
};

const localMLClubRecommendations = getLocalItem(RECOMMENDATIONS_ML_CLUB);
const mlClubRecommendationsValue = localMLClubRecommendations
  ? JSON.parse(localMLClubRecommendations)
  : null;
const localMLCasualRecommendations = getLocalItem(RECOMMENDATIONS_ML_CASUAL);
const mlCasualRecommendationsValue = localMLCasualRecommendations
  ? JSON.parse(localMLCasualRecommendations)
  : null;

export const MLRecommendationsContext = createContext();

export const MLRecommendationsProvider = ({ children }) => {
  const [clubMLRecommendationsContext, setClubMLRecommendationsContext] =
    useState(mlClubRecommendationsValue);
  const [casualMLRecommendationsContext, setCasualMLRecommendationsContext] =
    useState(mlCasualRecommendationsValue);
  const [isMLCard, setIsMLCard] = useState(false);

  // set MLRecommedation details
  const setMLRecommendations = useCallback((data, type = CLUB) => {
    const localKey = localKeyMapping[type];
    setLocalItem(localKey, JSON.stringify(data));

    if (type === KICKABOUT_CASUAL) {
      setCasualMLRecommendationsContext(data);
      return;
    }

    setClubMLRecommendationsContext(data);
  }, []);

  // reset ML recommendation
  const resetMLRecommendations = () => {
    removeLocalItem(RECOMMENDATIONS_ML_CLUB);
    setClubMLRecommendationsContext(null);
    removeLocalItem(RECOMMENDATIONS_ML_CASUAL);
    setCasualMLRecommendationsContext(null);
    setIsMLCard(false);
  };

  // to check whether ML or recommendation card clicked
  const setMLCard = value => {
    setIsMLCard(value);
  };

  return (
    <MLRecommendationsContext.Provider
      value={{
        clubMLRecommendations: clubMLRecommendationsContext,
        casualMLRecommendations: casualMLRecommendationsContext,
        setMLRecommendations,
        resetMLRecommendations,
        isMLCard,
        setMLCard,
      }}
    >
      {children}
    </MLRecommendationsContext.Provider>
  );
};

export const useMLRecommendations = () => useContext(MLRecommendationsContext);
