export default (state, action) => {
  switch (action.type) {
    case 'add':
      state = [];
      return [...state, { AgeGroupId: action.payload.id }];
    case 'remove':
      return state.filter(
        answerId => answerId.AgeGroupId !== action.payload.id
      );
    default:
      return state;
  }
};
