export default (state, action) => {
  switch (action.type) {
    case 'add':
      return [...state, action.payload.value];
    case 'remove':
      return state.filter(value => value !== action.payload.value);
    default:
      return state;
  }
};
