import {
  BOYS_OR_MEN,
  BOYS_OR_MEN_MIXED,
  EMPTY_STRING,
  GIRLS_OR_WOMEN,
  GIRLS_OR_WOMEN_MIXED,
  MIXED_VALUE,
} from '../../const';
import useAgeTitle from '../../hooks/useAgeTitle';

export default playWith => {
  let genderString = EMPTY_STRING;
  const ageTitle = useAgeTitle();
  switch (playWith) {
    case BOYS_OR_MEN:
    case GIRLS_OR_WOMEN:
      genderString = ageTitle;
      break;
    case BOYS_OR_MEN_MIXED:
    case GIRLS_OR_WOMEN_MIXED:
      genderString = `${ageTitle} + ${MIXED_VALUE}`;
      break;
    default:
      genderString = MIXED_VALUE;
      break;
  }

  return genderString.replace("s'", EMPTY_STRING);
};
