/** @jsxImportSource @emotion/react */
import { useEffect, useReducer, useState } from 'react';

import { useOnboardingDataContext } from '../../context/OnboardingData';
import disabilitiesReducer from '../../reducers/disabilities';
import getCategoryDataLayer from '../../utils/getCategoryDataLayer';
import getNextQuestionId from '../../utils/getNextQuestionId';
import idFromString from '../../utils/idFromString';
import NextNav from '../NextNav';
import useStyles from './styles';

const MultipleQuestion = ({
  readableId,
  answers,
  ageRanges,
  onAnswer,
  selectedAge,
  headerTitle,
}) => {
  const styles = useStyles();
  const [answersState, dispatch] = useReducer(disabilitiesReducer, []);
  const { OnboardingContext, setOnboardingDataContext } =
    useOnboardingDataContext();
  const [selectedAnswerValue, setSelectedAnswerValue] = useState();

  const onChange = event => {
    const id = parseInt(event.target.value, 10);

    if (event.target.checked) {
      dispatch({ type: 'add', payload: { id } });
    } else {
      dispatch({ type: 'remove', payload: { id } });
    }
  };

  useEffect(() => {
    if (!answers) {
      return;
    }
    // filter value of selected answer
    const selectedAnswers = answers
      .filter(item => {
        return answersState.some(ans => item.answerId === ans.DisabilityId);
      })
      .map(ele => ele.value)
      .toString();
    setSelectedAnswerValue(selectedAnswers);
  }, [answers, answersState]);

  // set basic data in onboardingContext
  useEffect(() => {
    setOnboardingDataContext({
      ...OnboardingContext,
      question: headerTitle,
      step: 2,
      disability: selectedAnswerValue,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedAnswerValue, headerTitle]);

  return (
    <div css={styles.base}>
      {answers &&
        answers.map(({ answerId, value }) => {
          const optionId = idFromString(value);
          const isChecked = !!answersState.find(
            answer => answer.DisabilityId === parseInt(answerId, 10)
          );

          return (
            <label css={styles.label} key={answerId}>
              <input
                css={styles.checkbox}
                type="checkbox"
                id={optionId}
                name={optionId}
                value={answerId}
                onChange={onChange}
              />
              <span css={styles.answer(isChecked)}>{value}</span>
            </label>
          );
        })}
      <NextNav
        disabled={answersState.length === 0}
        onClick={() => {
          onAnswer({
            answerData: { [readableId]: answersState },
            nextQuestionId: getNextQuestionId({ ageRanges, selectedAge }),
          });
          // pushing into dataLayer
          getCategoryDataLayer({
            eventAction: `Step ${OnboardingContext.step} - Next CTA`,
            eventLabel: OnboardingContext.question,
            eventparameter1: `${OnboardingContext.age}${
              OnboardingContext.agegroup
                ? ' , ' + OnboardingContext.agegroup
                : ''
            }`,
            eventparameter2: selectedAnswerValue,
            event_name: 'next_button_click',
            gender_group: OnboardingContext.gender_group,
          });
          setOnboardingDataContext({
            ...OnboardingContext,
            disability: selectedAnswerValue,
          });
        }}
      />
    </div>
  );
};

export default MultipleQuestion;
