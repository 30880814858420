import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, colors, spacing, typography } = useTheme();

  return {
    base: css`
      background-color: ${colors.darkGrey};
      color: ${colors.white};
      box-shadow: 0px 4px 6px rgba(91, 104, 133, 0.1);
      border-radius: 8px;
      position: relative;
      display: flex;
      align-items: center;
      justify-items: center;
      width: 100%;
      padding: ${spacing.m} ${spacing.s};
      grid-auto-columns: 1fr;
      text-align: left;
      min-height: 152px;
      max-height: 152px;
      ${typography.headings[7].mobile};
      margin: 2rem 0 2rem 0;
      font-weight: 400;

      ${breakpoints.m} {
        ${typography.headings[7].desktop};
        margin: 2rem 0 2rem 0;
        min-height: 94px;
        max-height: 94px;
        padding: 32px 56px 32px 56px;
        font-weight: 400;
      }
    `,
    alertIcon: css`
      padding-right: 0.5rem;
      svg {
        transform: rotate(180deg);
        transform-origin: 50% 50%;
      }

      ${breakpoints.m} {
        align-items: flex-start;
      }
    `,
  };
};
