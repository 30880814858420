import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors, spacing, breakpoints } = useTheme();

  return {
    footer: css`
      background-color: ${colors.lightGrey};
      color: ${colors.blue};
      position: relative;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.125rem;
      padding: 1rem 1.5rem;
      letter-spacing: 0.02em;
      display: grid;
      justify-content: center;

      ${breakpoints.m} {
        padding: ${spacing.s} ${spacing.m};
      }
    `,
    footerWrapper: css`
      display: grid;
      justify-content: center;

      ${breakpoints.m} {
        grid-template-columns: auto 1fr;
        grid-column-gap: ${spacing.s};
      }
    `,
    list: css`
      display: grid;
      grid-template-columns: repeat(4, auto);
      grid-gap: 1.5rem;

      li { 
        color: ${colors.navy};
        font-weight: normal;
        font-size: 18px;
        font-weight: 500;
        display: grid;
        align-items: center;
        > a {
        color: ${colors.darkGrey};
        &:hover, &:focus {
          outline: 0;
          color: ${colors.blue};
        }
      }
    }

    ${breakpoints.m} {
      grid-auto-flow: column;
    }
    `,
  };
};
