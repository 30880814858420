/** @jsxImportSource @emotion/react */
import { Fragment, useEffect, useMemo, useState } from 'react';

import { REAPPLY_FILTERS, REMOVE_FILTERS } from '../../analyticsConsts';
import Button from '../Button';
import VenueCard from '../VenueCard';
import useStyles from './styles';

export const INITIAL_SHOWN_CARDS_MOBILE = 8;

const VenueExpandableList = ({
  index: analyticsIndex,
  list: totalList,
  ariaLabel,
  cardAction,
  canToggle,
  isFiltered,
  onClick,
  isDesktop,
  venueTitle,
  logoDetails,
  footballtype,
  providerData,
}) => {
  const styles = useStyles();
  const [isShowMore, setIsShowMore] = useState({});

  const hiddenCardsNumber = totalList.length - INITIAL_SHOWN_CARDS_MOBILE;

  useEffect(() => {
    setIsShowMore({ ...isShowMore, [analyticsIndex]: false });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const list = useMemo(
    () =>
      isShowMore[analyticsIndex]
        ? totalList
        : isDesktop
        ? totalList
        : totalList.slice(0, INITIAL_SHOWN_CARDS_MOBILE),
    [totalList, isShowMore, analyticsIndex, isDesktop]
  );

  return (
    <Fragment>
      <ul css={styles.list(providerData)} aria-label={ariaLabel}>
        {list &&
          list.length > 0 &&
          list.map((card, index) => {
            return (
              <VenueCard
                key={index}
                isDesktop={isDesktop}
                analyticsIndex={analyticsIndex}
                venueTitle={venueTitle}
                onClick={card => cardAction(card)}
                rowData={card}
                logoDetails={logoDetails}
                footballtype={footballtype}
              ></VenueCard>
            );
          })}
      </ul>
      {!isDesktop && hiddenCardsNumber > 0 && !isShowMore[analyticsIndex] && (
        <div css={styles.buttonWrapper}>
          <Button
            id="SHOW_MORE"
            onClick={() =>
              setIsShowMore({ ...isShowMore, [analyticsIndex]: true })
            }
            css={styles.button}
            aria-label="click here to see more results"
          >
            SHOW MORE
          </Button>
        </div>
      )}
      {!isDesktop && canToggle && (
        <Button
          id={`${isFiltered ? REMOVE_FILTERS : REAPPLY_FILTERS}`}
          onClick={onClick}
          css={[styles.button, styles.cancelBtn]}
        >
          {isFiltered ? 'Remove filters' : 'Reapply filters'}
        </Button>
      )}
    </Fragment>
  );
};

export default VenueExpandableList;
