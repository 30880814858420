/** @jsxImportSource @emotion/react */

import { useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';

import {
  BOYS_OR_MEN_MIXED,
  CLUB,
  DONT_MIND,
  GIRLS_OR_WOMEN_MIXED,
  KICKABOUT_CASUAL,
  RECOMMENDATIONS_PAGE_TITLE_MAPPING,
} from '../../const';
import { usePreferencesContext } from '../../context/preferences';
import { useRecommendations } from '../../context/recommendations';
import { useScrollDirectionContext } from '../../context/scrollDirection';
import { useSetHeaderHeightContext } from '../../context/setHeaderHeight';
import useErrorBoundary from '../../hooks/useErrorBoundary';
import useImage from '../../hooks/useImage';
import usePageLoadInfo from '../../hooks/usePageLoadInfo';
import {
  getRecommendations,
  getRecommendationsPageData,
} from '../../services';
import FootballTypeTabs from '../FootballTypeTabs';
import Footer from '../Footer';
import Hero from '../Hero';
import MixedSignPost from '../MixedSignPost';
import NoResultsRecommendations from '../NoResultsRecommendations';
import RecommendationCardLoading from '../RecommendationCardLoading';
import RecommendationFooter from '../RecommendationFooter';
import RecommendationsList from '../RecommendationsList';
import TabPanel from '../TabPanel';
import useStyles from './styles';

const SetImagePath = (data, FootballType, imgData) => {
  const dataKey = Object.keys(data.hero);
  dataKey.forEach(detailKey => {
    if (FootballType === parseInt(detailKey)) {
      data.hero[
        detailKey
      ].images.desktop.src = `/images/desktop/recommendations/hero/${imgData}.jpg`;

      data.hero[
        detailKey
      ].images.mobile.src = `/images/mobile/recommendations/hero/${imgData}.jpg`;
    }
  });
  return data;
};

const RecommendationsPage = () => {
  const styles = useStyles();
  const throwError = useErrorBoundary();
  const [pageData, setPageData] = useState();
  const [footballTypeFilter, setFootballTypeFilter] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const loadingRef = useRef();
  const { preferences } = usePreferencesContext();
  const {
    FootballType,
    SelectedFootballType: selectedFootballType,
    PlayWith: playWith,
  } = preferences || {};
  const { clubRecommendations, casualRecommendations, setRecommendations } =
    useRecommendations();
  // to get dynamic image url path
  const imagePath = useImage();
  const { scrolldirectionContext } = useScrollDirectionContext();
  const { headerHeight } = useSetHeaderHeightContext();

  const recommendations =
    selectedFootballType === CLUB ? clubRecommendations : casualRecommendations;

  // set the page title based on user preference of football type.
  usePageLoadInfo(
    'recommendations',
    `${RECOMMENDATIONS_PAGE_TITLE_MAPPING[FootballType]}`
  );

  useEffect(() => {
    if (JSON.parse(sessionStorage.getItem('ISADULTCLUB'))) {
      const filterFootballType = recommendations?.filter(
        item => item.FootballType === '11v11'
      );
      setFootballTypeFilter(filterFootballType);
    } else {
      setFootballTypeFilter(recommendations);
    }
  }, [recommendations]);

  useEffect(() => {
    getRecommendationsPageData()
      .then(({ data }) => {
        if (data) {
          // set dynamic src for hero images
          data = SetImagePath(data, FootballType, imagePath);
          setPageData({ ...data });
        }
      })
      .catch(throwError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFootballType, imagePath]);

  useEffect(() => {
    if (!recommendations && !!selectedFootballType) {
      const previouslyFocusedElement = document.activeElement;
      setIsLoading(true);

      Promise.all([
        getRecommendations(selectedFootballType, preferences).catch(throwError),
      ]).then(data => {
        if (data) {
          const recommendationRecords = data[0].data; // recommendation data
          setRecommendations(recommendationRecords, selectedFootballType);

          setIsLoading(false);

          setTimeout(() => {
            // if the user focuses on the loading message
            // we want to focus back on the previously focused element
            if (loadingRef.current?.contains(document.activeElement)) {
              previouslyFocusedElement?.focus();
            }
          }, 100);
        }
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    preferences,
    selectedFootballType,
    setRecommendations,
    recommendations,
    throwError,
  ]);

  useEffect(() => {
    localStorage.setItem('pathInfomation', window.location.pathname);
  }, []);

  if (!selectedFootballType) {
    return <Redirect to="/" />;
  }

  return (
    <div css={styles.base}>
      {selectedFootballType && pageData?.hero && (
        <Hero
          css={styles.hero}
          textSize={3}
          {...pageData.hero[FootballType]}
          isOnBoardingCriteriaIncluded={true}
          scrolldirectionContext={scrolldirectionContext}
          headerHeight={headerHeight}
          isRec={true}
        />
      )}
      <div css={styles.wrapper}>
        {FootballType === DONT_MIND && <FootballTypeTabs />}
        <TabPanel selectedType={selectedFootballType} id={selectedFootballType}>
          {isLoading && (
            <RecommendationCardLoading
              selectedType={selectedFootballType}
              ref={loadingRef}
            />
          )}
          {recommendations && selectedFootballType && pageData && (
            <RecommendationsList
              selectedFootballType={selectedFootballType}
              recommendations={footballTypeFilter}
              pageData={pageData?.recommendations}
            />
          )}

          {recommendations && recommendations.length === 0 && (
            <div css={styles.noResultWrapper}>
              <NoResultsRecommendations />
            </div>
          )}
        </TabPanel>
      </div>
      {recommendations && (
        <div
          aria-hidden={recommendations.length === 0}
          css={styles.visibility(recommendations.length)}
        >
          {!(
            playWith === BOYS_OR_MEN_MIXED || playWith === GIRLS_OR_WOMEN_MIXED
          ) &&
            selectedFootballType === KICKABOUT_CASUAL && <MixedSignPost />}
          <RecommendationFooter />
        </div>
      )}
      <Footer />
    </div>
  );
};

export default RecommendationsPage;
