import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, borderRadius, colors, typography, spacing, heights } =
    useTheme();

  return {
    base: css`
      height: 100%;
      display: grid;
      grid-template-rows: auto 1fr;
      grid-template-columns: 100%;
    `,
    hero: css`
      h1 {
        font-size: 2.625rem;

        ${breakpoints.m} {
          ${typography.headings[3].desktop};
          max-width: 85%;
          text-align: center;
          padding: 7px 20px;
        }
      }
    `,
    wrapperRef: (titleHeight, hasPreferences) => css`
      width: 90vw;
      margin: 0 auto;
      margin-top: calc(
        ${titleHeight || '500'}px - ${heights.mainNav.mobile} -
          ${hasPreferences ? heights.filterNav : '0px'} + ${spacing.m}
      );
      padding: ${spacing.s} 0;

      ${breakpoints.m} {
        margin-top: calc(
          ${titleHeight || '360'}px - ${heights.mainNav.mobile} + 6rem
        );
      }

      ${breakpoints.l} {
        max-width: 1196px;
        margin-top: calc(
          ${titleHeight || '360'}px - ${heights.mainNav.desktop} + 9rem
        );
        padding: 0;
      }
    `,
    wrapper: (titleHeight, hasPreferences) => css`
      margin-top: calc(100vh - 95px);
      ${hasPreferences &&
      css`
        margin-top: calc(100vh - 256px);
      `}
      padding: ${spacing.s} 0;

      ${breakpoints.m} {
        margin-top: calc(100vh - 75px);
        padding: 0;
      }

      ${breakpoints.l} {
        margin: 0 auto;
        width: 90vw;
        max-width: 1196px;
        margin-top: calc(100vh - 75px);
      }
    `,
    card: css`
      position: relative;
      padding: ${spacing.s};
      background-color: ${colors.white};
      border-radius: ${borderRadius.l};
      margin-bottom: ${spacing.m};
      box-shadow: 0px 4px 6px rgba(91, 104, 133, 0.1);
      ${breakpoints.xl} {
        padding: ${spacing.l};
        grid-template-rows: unset;
        grid-gap: ${spacing.s};
      }
    `,
    tabs: css`
      margin-bottom: ${spacing.s};
      outline: 0;

      ${breakpoints.xl} {
        margin-bottom: ${spacing.m};

        Button {
          border: ${borderRadius.s} solid transparent;
          outline: 0;
          &:focus:not(:hover) {
            outline: 0;
            border: ${borderRadius.s} solid ${colors.navy};
          }
        }
      }
    `,
    backButton: css`
      margin: 0 0 1.2rem;
    `,
    border: css`
      border-top: 1px solid ${colors.lightGrey};
    `,
    venueDescription: css`
      background-color: ${colors.lightGrey};
      color: ${colors.navy};
      border-radius: ${borderRadius.l};
      padding: 1.5rem;
      display: grid;
      grid-template-columns: 15% 60% 25%;
      align-items: inherit;
      margin-top: 33px;
      border-radius: ${borderRadius.l};
      p {
        line-height: 1.5rem;
      }
      ${breakpoints.m} {
        grid-template-columns: 5% 80% 15%;
      }
    `,
    title: isClubInfo => css`
      display: inline-block;
      font-size: 1.75rem;
      color: ${colors.navy};
      text-decoration: underline ${colors.red} 2px;
      -webkit-text-decoration-line: underline;
      -webkit-text-decoration-color: ${colors.red};
      -webkit-text-decoration-style: solid;
      -webkit-text-decoration-thickness: 2px;
      margin-bottom: ${spacing.xs};

      ${breakpoints.l} {
        ${typography.headings[4].desktop};
      }
    `,
    externalLink: css`
      display: initial;
      grid-gap: 10px;
      border-top: 1px solid ${colors.red};
      width: 40px;
      padding-top: 1rem;

      &::before {
        content: '';
        position: absolute;
        width: 40px;
        border-bottom: 1px solid ${colors.red};

        ${breakpoints.m} {
          padding-top: 1rem;
        }
      }
    `,
    venueInfo: css`
      padding-bottom: 1rem;
      font-weight: 600;
      font-size: 14px;

      ${breakpoints.m} {
        padding-top: 0.5rem;
        padding-bottom: 0;
        font-size: 18px;
      }
    `,
    link: css`
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      padding-top: 1rem;
      word-break: break-word;

      ${breakpoints.m} {
        font-size: 16px;

        &:first-of-type {
          padding-top: 2.3rem;
        }
      }
    `,
    alertIcon: css`
      ${breakpoints.m} {
        margin-top: 0.5rem;
      }
    `,
    rightArrow: css`
      vertical-align: middle;
      color: ${colors.red};
      margin-left: 0.3125rem;
    `,
  };
};
