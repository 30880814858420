/** @jsxImportSource @emotion/react */
import isempty from 'lodash.isempty';
import React, { Fragment, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';

import {
  BACK_TO_RESULTS,
  teamDetailsPageAnalytics,
} from '../../analyticsConsts';
import {
  MATCH_AGEGROUP,
  SELECTED_RECOMMENDATIONS_TYPE_ID,
  TEAMS_TAB,
} from '../../const';
import { usePageHistory } from '../../context/pageHistory';
import useClubAccreditation from '../../hooks/useClubAccreditation';
import useClubContactDetails from '../../hooks/useClubContactDetails';
import useElementHeight from '../../hooks/useElementHeight';
import useMediaQuery from '../../hooks/useMediaQuery';
import useScrollTop from '../../hooks/useScrollTop';
import useTeamPageAPI from '../../hooks/useTeamPageAPI';
import useStylesClub from '../ClubPage/styles';
import ContactFormModal from '../ContactFormModal';
import ContactMap from '../ContactMap';
import Footer from '../Footer';
import HeroProvider from '../HeroProvider';
import { RightArrow } from '../Icons';
import LinkButton from '../LinkButton';
import Loader from '../Loader';
import SocialMedia from '../SocialMedia';
import StickyScrollSpyNav from '../StickyScrollSpyNav';
import TeamInfo from '../TeamInfo';
import TeamStatus from '../TeamStatus';
import { venuePreviousPage } from '../VenuePage/conditionCheck';
import useStyles from './styles';

const { TEAM_NAME_HEADING, TEAM_DETAILS_WRAPPER, TEAM_DETAILS_TABS } =
  teamDetailsPageAnalytics;

const TeamDetails = () => {
  const pageRef = useRef();
  useScrollTop(pageRef);
  const {
    teamPageData,
    isLoadingPageData,
    socialDataLinks,
    teamDetails,
    isLoading,
    selectedClubDetails,
    sourceLocation,
    preferences,
  } = useTeamPageAPI();
  const { [SELECTED_RECOMMENDATIONS_TYPE_ID]: selectedRecommendationType } =
    preferences || {};
  const { Category } = preferences;
  const { height: titleHeight, elementRef: titleElement } = useElementHeight();
  const styles = useStyles();
  const stylesClub = useStylesClub();
  const history = useHistory();
  const [backUrl, setBackUrl] = useState();
  const [menuTab, setMenuTab] = useState(null);
  const [contactFormVisible, setContactFormVisible] = useState(false);
  const { selectedTeamDetails } = selectedClubDetails || {};
  const wgsClubID =
    selectedClubDetails?.WgsClubId || selectedClubDetails?.accreditationid;
  // when we get the club details we want to fetch the accreditation and contact details
  const accreditation = useClubAccreditation(wgsClubID);
  const contactDetails = useClubContactDetails(wgsClubID);
  const isDesktop = useMediaQuery('screen and (min-width: 768px)');
  const previousUrl = localStorage.getItem('pathInfomation');
  const { previousPage } = usePageHistory();

  useEffect(() => {
    if (!previousPage && !previousUrl) {
      return;
    }
    setBackUrl(
      previousPage
        ? previousPage
        : previousUrl
        ? previousUrl
        : '/recommendation'
    );
  }, [previousPage, previousUrl]);
  const teamPage = true;
  const previousPageName = venuePreviousPage(previousUrl, teamPage);

  // based on condition modifying isHidden flag in tabs array
  useEffect(() => {
    TEAMS_TAB[1].isHidden = !(
      (!isempty(teamDetails?.lastresults) ||
        !isempty(teamDetails?.standingspositions)) &&
      MATCH_AGEGROUP.indexOf(teamDetails?.agegroup) === -1
    );
    setMenuTab(TEAMS_TAB);
  }, [teamDetails, menuTab, isLoading]);

  if (isLoadingPageData) {
    return <Loader message="Loading team info" />;
  }

  return (
    <div css={styles.base(isDesktop, isLoading)} ref={pageRef}>
      <section>
        {teamPageData && (
          <HeroProvider
            title={teamDetails?.title}
            address={teamDetails?.address}
            distance={teamDetails?.distance}
            latitude={teamDetails?.latitude}
            longitude={teamDetails?.longitude}
            county={teamDetails?.county}
            accreditation={accreditation}
            css={stylesClub.hero}
            type={'club'}
            textSize={3}
            ref={titleElement}
            titleHeight={titleHeight}
            hasPreferences={!!selectedRecommendationType}
            previousPageData={{ name: previousPageName, backUrl: backUrl }}
            analyticsId={TEAM_NAME_HEADING}
            isInternalLink="true"
            sourceLoction={sourceLocation}
            Category={Category}
            isTeamPage="true"
            {...teamPageData.hero}
          />
        )}
      </section>
      <section
        id={TEAM_DETAILS_WRAPPER}
        css={styles.wrapper(titleHeight, !!selectedRecommendationType)}
      >
        <div css={stylesClub.card}>
          {isLoading && (
            <Loader css={stylesClub.loader} message="Loading Team Details" />
          )}
          {!isLoading && teamDetails && menuTab && (
            <Fragment>
              <StickyScrollSpyNav
                nav={menuTab}
                titleHeight={titleHeight}
                title={teamDetails?.title}
                id={TEAM_DETAILS_TABS}
              >
                <section ref={React.createRef()} css={stylesClub.navContent}>
                  {isDesktop && (
                    <LinkButton
                      id={BACK_TO_RESULTS}
                      onClick={history.goBack}
                      css={stylesClub.backLink}
                    >
                      <RightArrow css={stylesClub.arrowRotate} />{' '}
                      <span>Back to {previousPageName} page</span>
                    </LinkButton>
                  )}
                  {teamDetails && (
                    <Fragment>
                      <TeamInfo
                        contact={contactDetails}
                        teamDetails={teamDetails}
                        pageData={teamPageData}
                        onClick={() => setContactFormVisible(true)}
                      />
                    </Fragment>
                  )}
                </section>
                {(!isempty(teamDetails?.lastresults) ||
                  !isempty(teamDetails?.standingspositions)) &&
                  MATCH_AGEGROUP.indexOf(teamDetails?.agegroup) === -1 && (
                    <section
                      ref={React.createRef()}
                      css={[stylesClub.navContent]}
                    >
                      <TeamStatus {...teamDetails} />
                    </section>
                  )}
                <section ref={React.createRef()} css={stylesClub.navContent}>
                  {teamDetails && (
                    <ContactMap
                      {...teamDetails}
                      titleHeader="Club Contact"
                      subTitle="For information about a specific team, please get in touch with the club contact."
                      locationDetails={{
                        latitude: teamDetails?.latitude,
                        longitude: teamDetails?.longitude,
                        title: 'New Club',
                      }}
                      contact={contactDetails}
                      sourceLoction={sourceLocation}
                      onClick={() => setContactFormVisible(true)}
                    />
                  )}
                </section>
              </StickyScrollSpyNav>
            </Fragment>
          )}
          {socialDataLinks && (
            <SocialMedia
              title={selectedClubDetails?.clubName}
              socialLinks={socialDataLinks}
            ></SocialMedia>
          )}
        </div>
      </section>
      {contactFormVisible && (
        <ContactFormModal
          onClose={() => {
            setContactFormVisible(false);
          }}
          isVisible={contactFormVisible}
          clubDetails={selectedClubDetails}
          isClub={false}
          selectedTeamData={selectedTeamDetails}
        />
      )}
      {<Footer />}
    </div>
  );
};

export default TeamDetails;
