import { createContext, useCallback, useContext, useState } from 'react';

const FilterPanelToggleContext = createContext();

export const FilterPanelToggleProvider = ({ children }) => {
  const [isFilterPanelToggled, setIsFilterPanelToggled] = useState(false);
  const [isFilterEnabled, setIsFilterEnabledTab] = useState(false);

  const setFilterPanelToggle = useCallback(toggleValue => {
    setIsFilterPanelToggled(toggleValue);
  }, []);

  const setFilterEnabledTab = useCallback(toggleValue => {
    setIsFilterEnabledTab(toggleValue);
  }, []);

  const resetFilterPanelToggle = () => {
    setIsFilterPanelToggled(false);
  };

  return (
    <FilterPanelToggleContext.Provider
      value={{
        isFilterPanelToggled,
        setFilterPanelToggle,
        resetFilterPanelToggle,
        setFilterEnabledTab,
        isFilterEnabled,
      }}
    >
      {children}
    </FilterPanelToggleContext.Provider>
  );
};

export const useFilterPanelToggleContext = () =>
  useContext(FilterPanelToggleContext);
