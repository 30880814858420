import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors, breakpoints } = useTheme();

  return {
    progressBar: progress => css`
      width: 100%;
      border-top: 1px solid ${colors.grey};
      position: relative;

      ${breakpoints.l} {
        width: 90%;
      }

      &::before {
        content: '';
        position: absolute;
        top: -2px;
        left: 0;
        height: 3px;
        width: ${progress}%;
        transition: width 0.5s;
        background-color: ${colors.red};
      }
    `,
  };
};
