import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { borderRadius, utility, colors } = useTheme();

  return {
    hiddenInputs: css`
      input {
        ${utility.checkboxHidden}
      }

      input:focus:not(:hover) ~ span {
        border: ${borderRadius.s} solid ${colors.navy};
      }
    `,
    container: css`
      display: block;
      position: relative;
      padding-left: 2.188rem;
      margin-bottom: 0.75rem;
      cursor: pointer;
      user-select: none;

      input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
        &:checked {
          ~ span:after {
            display: block;
          }
          ~ p {
            color: ${colors.navy};
          }
        }
      }

      p {
        color: ${colors.darkGrey};
      }
    `,
    checkmark: isChecked => css`
      position: absolute;
      top: 0;
      left: 0;
      background-color: ${colors.white};
      width: 1.5rem;
      height: 1.5rem;
      border: 1.5px solid ${isChecked ? colors.navy : colors.darkGrey};
      border-radius: ${borderRadius.s};

      &:after {
        content: '';
        position: absolute;
        display: none;
        left: 0;
        right: 0;
        top: ${borderRadius.s};
        margin: auto;
        width: 0.313rem;
        height: 0.75rem;
        border: solid ${colors.navy};
        border-width: 0 ${borderRadius.s} ${borderRadius.s} 0;
        transform: rotate(45deg);
      }
    `,
  };
};
