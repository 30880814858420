import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { breakpoints, colors, screenSizes, spacing } = useTheme();

  return {
    pageWrapper: bgImage => css`
      background: url(${publicPath(
      '/images/desktop/errorPages/' + bgImage + '.png'
    )})
        center center / cover no-repeat;
      width: 100%;
      position: relative;
      display: grid;
      align-items: center;
      justify-items: center;
      // dynamic height set - footer - height
      height: calc(100vh - 70px);
      padding: 0 1.5rem;
      z-index: 1;

      &::after {
        content: '';
        background-color: rgba(1, 30, 65, 0.6);
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        width: 100%;
      }

      ${breakpoints.m} {
        // footer height
        height: calc(100vh - 50px);
        padding: 0;
      }

      ${breakpoints.l} {
        // footer height
        height: calc(100vh - 50px);
      }
    `,
    textWrapper: css`
      text-align: center;
      color: ${colors.white};
      z-index: 2;
    `,
    mainText: css`
      color: ${colors.white};
      letter-spacing: -0.02em;
      padding-bottom: ${spacing.s};

      @media screen and (max-width: ${screenSizes.m}) {
        font-size: 2.625rem;
        line-height: 2.5rem;
      }
    `,
    cta: css`
      background-color: ${colors.red};
      color: ${colors.white};
      text-transform: uppercase;
      width: 16rem;
      margin: ${spacing.s} auto;
    `,
  };
};
