import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const {
    breakpoints,
    borderRadius,
    colors,
    spacing,
  } = useTheme();

  return {
    base: (isDesktop) => css`
      height: 100%;
      display: grid;
      grid-template-rows: auto 1fr;
      grid-template-columns: 100%;
    `,
    wrapper: (titleHeight, hasPreferences) => css`
      margin-top: calc(100vh - 20%);
      ${hasPreferences && css`
        margin-top: calc(100vh - 236px);
      `}
      padding: ${spacing.s} 0;

      ${breakpoints.m} {
        margin-top: calc(100vh - 75px);	
        padding: 0;
      }

      ${breakpoints.l} {
        margin: 0 auto;
        width: 90vw;
        max-width: 1196px;
        margin-top: calc(100vh - 75px);
      }
    `,
    score: css`
      max-width: 13.875rem;
      height: 48px;
      background-color: ${colors.lightGrey};
      border-radius: ${borderRadius.l};
      display: grid;
      grid-gap: 0.875rem;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      padding: 0.9rem ${spacing.s} ${spacing.s};
      display: none;
  `,
    title: css`
     color: ${colors.navy};
     word-spacing: ${spacing.xs};

     span {
      font-weight: bold;
     }
   `,
  };
};
