import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, colors, typography, spacing } = useTheme();

  return {
    base: css`
      height: 100%;
      display: grid;
      grid-template-rows: auto 1fr;
      grid-template-columns: 100%;
    `,
    hero: css`
      h1 {
        font-size: 2.625rem;

        ${breakpoints.m} {
          ${typography.headings[3].desktop};
          max-width: 85%;
          text-align: center;
          padding: 7px 20px;
        }
      }
    `,
    navSection: css`
      padding-bottom: 0;
    `,
    title: isClubInfo => css`
      display: inline-block;
      font-size: 1.75rem;
      color: ${colors.navy};
      text-decoration: underline ${colors.red} 2px;
      -webkit-text-decoration-line: underline;
      -webkit-text-decoration-color: ${colors.red};
      -webkit-text-decoration-style: solid;
      -webkit-text-decoration-thickness: 2px;
      margin-bottom: ${spacing.xs};

      ${breakpoints.l} {
        ${typography.headings[4].desktop};
      }
    `,
    externalLink: css`
      display: grid;
      grid-template-columns: max-content;
      grid-gap: 10px;
      border-top: 1px solid ${colors.red};
      width: 40px;
      padding-top: 1rem;

      ${breakpoints.m} {
        padding-top: 0.5rem;
      }
    `,
    venueInfo: css`
      padding-bottom: 1rem;
      font-weight: 500;
      font-size: 14px;

      ${breakpoints.m} {
        padding-top: 0.5rem;
        font-size: 18px;
      }
    `,
    link: css`
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      padding-top: 1rem;

      ${breakpoints.m} {
        font-size: 16px;
      }
    `,
    alertIcon: css`
      fill: ${colors.darkGrey};
      color: ${colors.white};
      transform: rotate(180deg);

      ${breakpoints.m} {
        margin-top: 0.5rem;
      }
    `,
    rightArrow: css`
      vertical-align: middle;
      color: ${colors.red};
      margin-left: 0.3125rem;
    `,
  };
};
