import {
  BOYS_AND_MEN,
  BOYS_OR_MEN,
  BOYS_OR_MEN_MIXED,
  GIRLS_AND_WOMEN_MIXED,
  GIRLS_OR_WOMEN,
  GIRLS_OR_WOMEN_MIXED,
  MIXED,
  MYSELF,
  PLAY_WITH_ID,
  SOMEONE_ELSE,
} from '../../../const';
import publicPath from '../../../utils/publicPath';

export default [
  {
    id: 'd9572be5-72f2-42af-87ea-a3298c6eacd7',
    questionOrder: 3,
    readableId: PLAY_WITH_ID,
    type: 'multi_optional',
    title: {
      [MYSELF]: 'What type of football do you want to play?',
      [SOMEONE_ELSE]: 'What type of football do they want to play?',
    },
    helpText: 'Select your preferences',
    multiOptional: {
      mandate: [
        {
          answerId: 6,
          analyticsId: 'female_football',
          title: 'Female football',
          answerType: 'primary',
          value: BOYS_AND_MEN,
          combinedValue: GIRLS_AND_WOMEN_MIXED,
          image: publicPath('/images/common/cutouts/club-girl.png'),
          keylineVariant: 3,
          ageRanges: [
            {
              min: 0,
              max: 11,
              nextQuestionId: 'dde0352b-5420-494a-bdee-0a7e5260e617',
            },
            {
              min: 12,
              max: 14,
              nextQuestionId: '123735b9-f394-4e48-800b-a6fcfd9a479d',
            },
            {
              min: 15,
              max: 15,
              nextQuestionId: '4760ff1b-41f2-4450-8bf4-83dc7384b594',
            },
            {
              min: 16,
              max: 99,
              nextQuestionId: '123735b9-f394-4e48-800b-a6fcfd9a479d',
            },
          ],
        },
      ],
    },
    answers: {
      mandatory: [
        {
          answerId: 1,
          analyticsId: 'female_football',
          title: 'Female football',
          answerType: 'primary',
          value: GIRLS_OR_WOMEN,
          combinedValue: GIRLS_OR_WOMEN_MIXED,
          image: publicPath('/images/common/cutouts/club-girl.png'),
          keylineVariant: 3,
          ageRanges: [
            {
              min: 0,
              max: 11,
              nextQuestionId: 'dde0352b-5420-494a-bdee-0a7e5260e617',
            },
            {
              min: 12,
              max: 14,
              nextQuestionId: '123735b9-f394-4e48-800b-a6fcfd9a479d',
            },
            {
              min: 15,
              max: 15,
              nextQuestionId: '4760ff1b-41f2-4450-8bf4-83dc7384b594',
            },
            {
              min: 16,
              max: 99,
              nextQuestionId: '123735b9-f394-4e48-800b-a6fcfd9a479d',
            },
          ],
        },
        {
          answerId: 2,
          analyticsId: 'male_football',
          title: 'Male football',
          answerType: 'primary',
          value: BOYS_OR_MEN,
          combinedValue: BOYS_OR_MEN_MIXED,
          image: publicPath('/images/common/cutouts/boy.png'),
          keylineVariant: 1,
          ageRanges: [
            {
              min: 5,
              max: 99,
              nextQuestionId: '61459727-eb01-4eff-96d6-d677fdc63e3d',
            },
          ],
        },
      ],
      optional: {
        answerId: 3,
        analyticsId: 'mixed_football',
        title: 'Mixed football',
        answerType: 'primary',
        value: MIXED,
        image: publicPath('/images/common/cutouts/mixed-child.png'),
        ageRanges: [
          {
            min: 0,
            max: 15,
            nextQuestionId: '4760ff1b-41f2-4450-8bf4-83dc7384b594',
          },
          {
            min: 16,
            max: 17,
            nextQuestionId: '123735b9-f394-4e48-800b-a6fcfd9a479d',
          },
          {
            min: 18,
            max: 99,
            nextQuestionId: '4760ff1b-41f2-4450-8bf4-83dc7384b594',
          },
        ],
      },
    },
    infoLink: {
      text: 'Find out more on FA policy',
      href: '',
    },
  },
  {
    id: 'd43569c8-1a01-4c53-9575-128698905fbc',
    questionOrder: 3,
    readableId: PLAY_WITH_ID,
    type: 'multi_optional',
    title: {
      [MYSELF]: 'What type of football do you want to play?',
      [SOMEONE_ELSE]: 'What type of football do they want to play?',
    },
    helpText: 'Select your preferences',
    multiOptional: {
      mandate: [
        {
          answerId: 1,
          analyticsId: 'female_football',
          title: 'Female football',
          answerType: 'primary',
          value: BOYS_AND_MEN,
          combinedValue: GIRLS_AND_WOMEN_MIXED,
          image: publicPath('/images/common/cutouts/female.png'),
          keylineVariant: 3,
          ageRanges: [
            {
              min: 0,
              max: 0,
              nextQuestionId: '123735b9-f394-4e48-800b-a6fcfd9a479d',
            },
          ],
        },
      ],
    },
    answers: {
      mandatory: [
        {
          answerId: 1,
          analyticsId: 'female_football',
          title: 'Female football',
          answerType: 'primary',
          value: GIRLS_OR_WOMEN,
          combinedValue: GIRLS_OR_WOMEN_MIXED,
          image: publicPath('/images/common/cutouts/female.png'),
          keylineVariant: 3,
          ageRanges: [
            {
              min: 0,
              max: 0,
              nextQuestionId: '123735b9-f394-4e48-800b-a6fcfd9a479d',
            },
          ],
        },
        {
          answerId: 2,
          analyticsId: 'male_football',
          title: 'Male football',
          answerType: 'primary',
          value: BOYS_OR_MEN,
          combinedValue: BOYS_OR_MEN_MIXED,
          image: publicPath('/images/common/cutouts/male.png'),
          keylineVariant: 1,
          ageRanges: [
            {
              min: 0,
              max: 0,
              nextQuestionId: '61459727-eb01-4eff-96d6-d677fdc63e3d',
            },
          ],
        },
      ],
      optional: {
        answerId: 3,
        analyticsId: 'mixed_football',
        title: 'Mixed football',
        answerType: 'primary',
        value: MIXED,
        image: publicPath('/images/common/cutouts/mixed-adult.png'),
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: '4760ff1b-41f2-4450-8bf4-83dc7384b594',
          },
        ],
      },
    },
    infoLink: {
      text: 'Find out more on FA policy',
      href: '',
    },
  },
];
