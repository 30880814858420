import { css } from '@emotion/react';

import { SCROLL_UP } from '../../const';
import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors, breakpoints, screenSizes } = useTheme();

  return {
    navlink: css`
      display: inline-block;
      margin: auto;

      ${breakpoints.l} {
        max-width: unset;
      }
    `,
    navigation: (withBorder, scrollDirection) => css`
      position: relative;
      width: 100%;
      text-align: center;
      box-shadow: 0px 2px 3px rgba(1, 30, 65, 0.14);
      position: fixed;
      top: 0;
      left: 0;
      right: 0;
      transition: transform 0.4s;
      z-index: 11;

      ${scrollDirection === SCROLL_UP
        ? css`
            visibility: visible;
            transition: all 0.5s;
            transform: translateY(-100%);
          `
        : css`
            // visibility: visible;
            transition: all 0.5s;
          `}

      ${withBorder &&
      css`
        ${breakpoints.l} {
          &::after {
            content: '';
            position: absolute;
            left: 0;
            bottom: 0;
            width: 100%;
            height: 1px;
            background: rgb(225, 38, 28);
            background: linear-gradient(
              90deg,
              ${colors.greyShade1} 34%,
              ${colors.greyShade1} 65%
            );
          }
        `}

      img {
        display: block;
        width: 100%;
      }

      ${breakpoints.m} {
        text-align: left;
        background-color: ${colors.white};
      }
    `,
    logoWrapper: isMobile => css`
      ${isMobile &&
      css`
        min-height: 70px;
        display: grid;
        align-items: center;
        background-color: ${colors.white};
      `}
    `,
    logoImage: css`
      max-width: 92px;
      min-width: 92px;
      max-height: 32px;
      height: 100%;

      ${breakpoints.m} {
        max-width: 122px;
        min-width: 122px;
        max-height: 42px;
      }
    `,
    globalText: textcolor => css`
      font-size: 16px;
      color: ${textcolor};
      font-weight: 500;
      text-decoration: auto;
      line-height: 16px;
      text-align: left;
      padding-left: 8px;

      ${breakpoints.m} {
        font-weight: 500;
        line-height: 20px;
        padding-left: 0;
      }
    `,
    globalExternal: textcolor => css`
      color: ${textcolor};
      display: initial;
      padding-left: 2px;
      text-decoration: underline;

      ${breakpoints.m} {
        display: initial;
      }

      ${breakpoints.xl} {
        display: inline-block;
      }
    `,
    globalLogo: css`
      fill: ${colors.purpleBlue};
      max-height: 25px;
    `,
    logoImageGlobal: css`
      max-width: 83px;
      min-width: 60px;
      max-height: 54px;
      height: 100%;
      margin-top: 3px;

      ${breakpoints.m} {
        max-width: 122px;
        min-width: 122px;
        max-height: 61.27px;
      }

      ${breakpoints.l} {
        margin-left: 75px;
      }

      ${breakpoints.xl} {
        margin-left: 75px;
      }
    `,
    inner: css`
      position: relative;
      height: calc(100% - 110px);

      ${breakpoints.l} {
        display: grid;
        max-width: ${screenSizes.xl};
        margin: 0 auto;
        width: 90vw;
        grid-auto-flow: column;
        align-items: center;
        height: 74px;
        position: initial;
        grid-template-columns: 20% 2fr 20%;
      }
    `,
    innerGlobal: (desktopImage, mobileImage, bgcolor) => css`
      position: relative;
      height: 87px;
      ${
        mobileImage &&
        css`
          background: url(${mobileImage}) 0 0 repeat;
        `
      }
      ${
        !mobileImage &&
        css`
          background-color: ${bgcolor};
        `
      }
      display: grid;
      grid-template-columns: 86% auto;
      align-items: center;

      ${breakpoints.m} {
        padding: 0;
        display: grid;
        width: 100vw;
        grid-auto-flow: column;
        align-items: center;
        height: 80px;
        position: initial;
        grid-template-columns: 85% auto;
        ${
          desktopImage &&
          css`
            background: url(${desktopImage}) 0 0 no-repeat;
          `
        }
      ${
        !desktopImage &&
        css`
          background-color: ${bgcolor};
        `
      }
        background-size: cover;
        }
      }

      ${breakpoints.l} {
        grid-template-columns: 85% auto;
      }

      ${breakpoints.xl} {
        grid-template-columns: 84% auto;
      }
    `,
    bannerClick: css`
      display: grid;
      grid-template-columns: 25% 75%;
      height: 80px;
      align-items: center;
      text-decoration-color: transparent;

      ${breakpoints.m} {
        grid-template-columns: 25% 75%;
      }

      ${breakpoints.l} {
        grid-template-columns: 27% 73%;
      }

      ${breakpoints.xl} {
        grid-template-columns: 20% 80%;
      }
    `,
    alignLogo: css`
      display: grid;
      grid-template-columns: 22% auto;
      align-items: center;
      align-self: center;
      cursor: pointer;
      padding-left: 12px;
      width: 30px;
      background-color: transparent;
      border: none;

      ${breakpoints.m} {
        align-self: center;
        width: 60px;
      }

      ${breakpoints.xl} {
        grid-template-columns: 18% auto;
      }
    `,
    hamburger: css`
      border: none;
      position: absolute;
      left: 1.3rem;
      width: 28px;
      height: 28px;
      color: red;
      padding: 0;
      background-color: transparent;
      top: 25px;

      &:not(:disabled):hover,
      &:focus {
        background-color: transparent;
      }

      svg {
        color: ${colors.red};
      }
    `,
    navigationOpen: css`
      overflow: hidden;
      padding-top: 5.6rem;
    `,
    topMenu: css`
      background-color: ${colors.lightGrey};
      text-align: right;
      height: 36px;
      display: grid;
      grid-auto-flow: column;
      justify-content: flex-end;
      align-items: center;
      padding-right: 15px;
      li {
        margin-right: 0.5rem;
        &:not(:last-child):after {
          content: '';
          border-right: 1px solid ${colors.greyShade1};
          height: 2.1rem;
          width: 0.1rem;
          margin-left: 0.8rem;
        }
      }
      a {
        color: ${colors.darkGrey};
        text-decoration: none;
        font-size: 14px;
        line-height: 18px;
      }
    `,
    navIcon4: isOpened => css`
      width: 100%;
      height: 100%;
      position: relative;
      transform: translateY(0px) rotate(0deg);
      transition: 0.5s ease-in-out;
      cursor: pointer;

      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: ${isOpened ? colors.red : colors.darkGrey};
        border-radius: 9px;
        opacity: 1;
        right: 0;
        transform: translateY(0px) rotate(0deg);
        transition: 0.25s ease-in-out;

        &:nth-of-type(1) {
          top: 0px;
          transform-origin: right center;
        }

        &:nth-of-type(2) {
          top: 8px;
          transform-origin: right center;
        }
        &:nth-of-type(3) {
          top: 16px;
          transform-origin: right center;
        }

        ${isOpened &&
        css`
          &:nth-of-type(1) {
            transform: rotate(314deg);
          }
          &:nth-of-type(2) {
            width: 0%;
            opacity: 0;
          }
          &:nth-of-type(3) {
            top: 20px;
            transform: rotate(45deg);
          }
        `}
      }
    `,
  };
};
