/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useLayoutEffect, useMemo, useRef, useState } from 'react';

import { SHOW_LESS, SHOW_MORE } from '../../analyticsConsts';
import { CLUB, KICKABOUT_CASUAL } from '../../const';
import { Chevron } from '../Icons';
import LinkButton from '../LinkButton';
import SessionCard from '../SessionCard';
import TeamCard from '../TeamCard';
import Text from '../Text';
import useStyles from './styles';

const INITIAL_SHOWN_CARDS = 3;

const ExpandableList = ({
  index: analyticsIndex,
  title,
  list: totalList,
  type,
  ariaLabel,
  cardAction,
  clubname,
}) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const styles = useStyles();

  const list = useMemo(
    () => (isExpanded ? totalList : totalList.slice(0, INITIAL_SHOWN_CARDS)),
    [totalList, isExpanded]
  );
  const cardToFocus = useRef();
  const hiddenCardsNumber = totalList.length - INITIAL_SHOWN_CARDS;

  useLayoutEffect(() => {
    // if the list has been expanded we should focus on the first new card
    if (isExpanded && cardToFocus.current) {
      setTimeout(() => {
        cardToFocus.current?.focus({ preventScroll: true });
      }, 300);
    }
  }, [isExpanded]);

  const Card = type === KICKABOUT_CASUAL ? SessionCard : TeamCard;
  const isTeamCard = type === CLUB;
  const buttonText =
    type === KICKABOUT_CASUAL
      ? `(${hiddenCardsNumber}) More on this date`
      : `More ${title}`;

  return (
    <div css={styles.base(isTeamCard)}>
      <Text as="h3" size={6} css={styles.title}>
        {title}
      </Text>
      <ul css={styles.list} aria-label={ariaLabel}>
        {list &&
          list.length > 0 &&
          list.map((card, index) => (
            <li
              key={card.SessionId || card.TeamId}
              tabIndex={-1}
              // if card is the first hidden one we pass the ref for focus
              ref={index === INITIAL_SHOWN_CARDS ? cardToFocus : null}
            >
              <Card
                index={analyticsIndex}
                {...card}
                onClick={() => cardAction(card)}
                clubname={clubname}
              />
            </li>
          ))}
      </ul>
      {hiddenCardsNumber > 0 && (
        <div css={styles.buttonWrapper(isTeamCard)}>
          <LinkButton
            id={`${isExpanded ? SHOW_LESS : SHOW_MORE}`}
            onClick={() => setIsExpanded(!isExpanded)}
            css={styles.button(isTeamCard)}
          >
            {isExpanded ? 'Hide' : 'Show'} {buttonText}{' '}
            <Chevron css={styles.buttonIcon(isExpanded, isTeamCard)} />
          </LinkButton>
        </div>
      )}
    </div>
  );
};

ExpandableList.prototype = {
  index: PropTypes.number,
  title: PropTypes.string,
  list: PropTypes.object,
  type: PropTypes.number,
  ariaLabel: PropTypes.string,
  cardAction: PropTypes.func,
  clubname: PropTypes.string,
};

export default ExpandableList;
