import { AGE_GROUP_ID, MYSELF } from '../../../const';

export default [
  {
    id: '346dbfab-03fa-4a3a-ab18-b461d0d56dc8',
    questionOrder: 2,
    readableId: AGE_GROUP_ID,
    analyticsId: 'selected_age_group',
    type: 'age_group_volunteer',
    title: {
      [MYSELF]: 'For what age group would you like to volunteer?',
      Mychild: 'For what age group would you like to volunteer?',
    },
    helpText: 'Please choose the age group that best suits you',
    answers: [
      {
        age: 5,
        ageOptions: ['U6,U7'],
        options: ['U6-U7'],
      },
      {
        age: 8,
        ageOptions: ['U8,U9,U10'],
        options: ['U8-U10'],
      },
      {
        ageOptions: ['U11,U12'],
        age: 10,
        options: ['U11-U12'],
      },
      {
        age: 12,
        options: ['U13-U14'],
        ageOptions: ['U13,U14'],
      },
      {
        ageOptions: ['U15,U16'],
        age: 14,
        options: ['U15-U16'],
      },
      {
        age: 16,
        ageOptions: ['U17,U18'],
        options: ['U17-U18'],
      },
      {
        options: ['Open Aged'],
        ageOptions: ['Open Aged'],
        age: 21,
      },
    ],
    ageRanges: [
      {
        min: 0,
        max: 0,
        nextQuestionId: 'd9572be5-72f2-42af-87ea-a3298c6eacd7',
      },
    ],
  },
];
