/* eslint(sonarjs/cognitive-complexity) */
import isempty from 'lodash.isempty';

import {
  CarIcon,
  Disability,
  FootballTypes,
  Person,
  Pitch,
  TrophyIcon,
} from '../../components/Icons';
import {
  disabilityRow,
  facilityRow,
  teamsRow,
  walkingFootball,
} from '../../components/VenuePage/conditionCheck';
import { AGEGROUP_YOUNGER, EMPTY_STRING } from '../../const';
import formatAddress from '../formatAddress';

const getGender = (genderType, ageGroup) => {
  if (!genderType && !ageGroup) {
    return;
  }
  const gender = genderType && genderType.toLowerCase();
  const age = ageGroup && ageGroup.toLowerCase();

  switch (gender) {
    case 'male':
      return AGEGROUP_YOUNGER.includes(age) ? "Boy's" : "Men's";
    case 'female':
      return AGEGROUP_YOUNGER.includes(age) ? "Girl's" : "Women's";
    default:
      return AGEGROUP_YOUNGER.includes(age)
        ? "Boy's & Girl's"
        : "Men's & Women's";
  }
};

const normaliseTeamDetails = apiData => {
  const {
    TeamName,
    DistanceFromUserLocation,
    CountyName,
    GroundName,
    TeamsInfo,
    TeamsCount,
    TeamsPreferenceCount,
    TeamsTab,
    latitude,
    longitude,
    WgsClubId,
    Facility,
    DisableFacility,
    PitchTypes,
    League,
    discoverFootballTeamList,
    Category,
    Agegroup,
    DivisionName,
    LeagueName,
    Gender,
    Disability: DisabilityType,
    PlaysOnDay,
    ...rest
  } = apiData;

  const {
    primaryLeagueName,
    nextFixture,
    lastResult,
    standingsPositionPreviousSeason: standingsPosition,
  } = !isempty(discoverFootballTeamList) && discoverFootballTeamList[0];
  const ageGroup = walkingFootball(Agegroup);
  return {
    title: TeamName,
    address: formatAddress(rest) || rest.address,
    contactAddress:
      formatAddress(rest, '\n') || rest.address.replace(/,/g, '\n'),
    county: CountyName,
    accreditationid: rest.accreditationid || rest.WgsClubId,
    distance: DistanceFromUserLocation || rest.distance,
    latitude: latitude || rest.Latitude,
    longitude: longitude || rest.Longitude,
    gender: Gender,
    clubname: rest?.title || rest.ClubName,
    teaminfo: {
      title: '',
      values: [
        {
          title: 'Teams football types',
          icon: TrophyIcon,
          rows: teamsRow(DivisionName, ageGroup, LeagueName),
        },
        {
          title: 'Teams gender groups',
          icon: Person,
          rows: [getGender(Gender, ageGroup), ageGroup],
        },
        {
          title: 'Teams disability types',
          icon: FootballTypes,
          rows:
            DisabilityType !== 'Non Disability'
              ? [Category, PlaysOnDay, DisabilityType]
              : [Category, PlaysOnDay],
        },
      ],
    },
    facilities: Facility
      ? {
          title: 'Team ground facilities',
          values: [
            {
              title: 'Facilities',
              icon: CarIcon,
              rows: facilityRow(Facility),
            },
            {
              title: 'Disability Facilities',
              icon: Disability,
              rows: disabilityRow(DisableFacility),
            },
            {
              title: 'Pitch types',
              icon: Pitch,
              rows: PitchTypes,
            },
          ],
        }
      : EMPTY_STRING,
    agegroup: ageGroup,
    nextfixtures: nextFixture
      ? {
          title: 'Next Match',
          leagueName: primaryLeagueName,
          homeTeamName: nextFixture?.homeTeamName,
          roadTeamName: nextFixture?.roadTeamName || 'TBC',
          venueName: nextFixture?.venueName,
          fixtureDate: nextFixture?.fixtureDate,
          agegroup: ageGroup,
          hasAddress: true,
        }
      : EMPTY_STRING,
    lastresults: lastResult
      ? {
          title: 'Last Match',
          leagueName: primaryLeagueName,
          fixtureDate: lastResult?.fixtureDate,
          fixtureID: lastResult?.fixtureID,
          homeScore: lastResult?.homeScore,
          homeTeamName: lastResult?.homeTeamName,
          roadScore: lastResult?.roadScore,
          roadTeamName: lastResult?.roadTeamName,
          venueName: lastResult?.venueName,
          agegroup: ageGroup,
          hasAddress: false,
        }
      : EMPTY_STRING,
    standingspositions: standingsPosition
      ? {
          title: 'Last Season League Position',
          leagueName: standingsPosition?.leagueName,
          divisionName: standingsPosition?.divisionName,
          standingsPosition: standingsPosition?.standingsPosition,
          seasonName: standingsPosition?.seasonName,
        }
      : EMPTY_STRING,
  };
};

export default normaliseTeamDetails;
