const b2cEnvVariables = env => {
  let b2cSettings = {};
  const envVar = env && env.toLowerCase();
  switch (envVar) {
    case 'development':
      b2cSettings = {
        ClientId: '6b748580-64dd-4975-90fa-6a8533e76c90',
        GlobalLogout:
          'https://zdevpffapimngt1.azure-api.net/b2cuseraccount-api-authz/v1/api/User',
        AuthUrl: 'https://b2cdevthefa.b2clogin.com',
        Tenant: 'B2CDevTheFa.onmicrosoft.com',
        SignUpPolicyId: 'B2C_1A_FASIGNUPCUSTOMOTPPFF_STRUIVERDYN',
        SignInPolicyId: 'B2C_1A_SIGNUP_SIGNINMFA',
        PasswordResetPolicyId: 'B2C_1A_PWRESETCUSTOMOTPPFF_STR', // Password Reset Policy Name
        ForgottenEmailPolicyId: 'b2c_1a_signup_signinprofileeditfancodev3js', // SignIn and Recovery Using FAN Code policy
        DontKnowMyFanPolicyId: 'b2c_1a_farecoverypff_struiver', // Policy for Account Recovery
        ClientSecret: 'gXB7Q~OsUJWP~BlR0ocOGTT.XjAfMIfB~Dgrm',
        FAAppToken: 'FIN',
        MyAccountUrl: 'https://zdevpffwebapp11.z6.web.core.windows.net/#/home',
        b2cScopes: [
          'https://b2cdevthefa.onmicrosoft.com/ActScopeApp/WRITE',
          'https://b2cdevthefa.onmicrosoft.com/ActScopeApp/READ',
          'https://b2cdevthefa.onmicrosoft.com/ActScopeApp/user_impersonation',
        ],
      };
      break;
    case 'qa':
      b2cSettings = {
        ClientId: 'e9b66cc3-cba4-4de7-aab6-c8561df2463a',
        GlobalLogout:
          'https://zqapffapimngt1.azure-api.net/b2cuseraccount-api-authz/v1/api/User',
        AuthUrl: 'https://b2cqathefa.b2clogin.com',
        Tenant: 'b2cqathefa.onmicrosoft.com',
        SignUpPolicyId: 'B2C_1A_FASIGNUPCUSTOMOTPPFF_STRUIVERDYN',
        SignInPolicyId: 'B2C_1A_SIGNUP_SIGNINMFA',
        PasswordResetPolicyId: 'B2C_1A_PWRESETCUSTOMOTPPFF_STR', // Password Reset Policy Name
        ForgottenEmailPolicyId:
          'B2C_1A_SIGNUP_SIGNINPROFILEEDITCUSTOMOTPFANCODEV3JSENFORCEEMAILDYN', // SignIn and Recovery Using FAN Code policy
        DontKnowMyFanPolicyId: 'B2C_1A_FARECOVERYCUSTOMOTPPFF_STRUIVERDYN', // Policy for Account Recovery
        ClientSecret: 'PvQ7Q~_KrdZ9SMRptBLPx-hgxhlMkj6pp_PMz',
        FAAppToken: 'FIN',
        MyAccountUrl: 'https://zqapffwebapp11.z6.web.core.windows.net/#/home',
        b2cScopes: [
          'https://b2cqathefa.onmicrosoft.com/ACTScopeApp/user_impersonation',
          'https://b2cqathefa.onmicrosoft.com/ACTScopeApp/READ',
          'https://b2cqathefa.onmicrosoft.com/ACTScopeApp/WRITE',
        ],
      };
      break;
    case 'staging':
      b2cSettings = {
        ClientId: '2c521b1e-3317-4dc6-940e-2f276e9269c1',
        GlobalLogout:
          'https://zhcpppffapimngt1.azure-api.net/b2cuseraccount-api-authz/v1/api/User',
        AuthUrl: 'https://b2cppthefa.b2clogin.com',
        Tenant: 'b2cppthefa.onmicrosoft.com',
        SignUpPolicyId: 'B2C_1A_FASIGNUPCUSTOMOTPPFF_STRUIVER',
        SignInPolicyId: 'B2C_1A_SIGNUP_SIGNINMFA',
        PasswordResetPolicyId: 'B2C_1A_PWRESETCUSTOMOTPPFF_STR', // Password Reset Policy Name
        ForgottenEmailPolicyId:
          'B2C_1A_SIGNUP_SIGNINPROFILEEDITCUSTOMOTPFANCODEV3JSENFORCEEMAIL', // SignIn and Recovery Using FAN Code policy
        DontKnowMyFanPolicyId: 'B2C_1A_FARECOVERYCUSTOMOTPPFF_STRUIVER', // Policy for Account Recovery
        ClientSecret: '.1d7Q~BSksmCkYxAOj6CaqdL.DfQ3P2qpd3SA',
        FAAppToken: 'FIN',
        MyAccountUrl: 'https://pffmyaccountpp.thefa.com/#/home',
        b2cScopes: [
          'https://b2cppthefa.onmicrosoft.com/ACTScopeApp/user_impersonation',
          'https://b2cppthefa.onmicrosoft.com/ACTScopeApp/READ',
          'https://b2cppthefa.onmicrosoft.com/ACTScopeApp/WRITE',
        ],
      };
      break;
    default:
      // prod
      b2cSettings = {
        ClientId: 'c1523737-bddc-48f0-b96e-807306a8c506',
        GlobalLogout:
          'https://zhcpffapimngt1.azure-api.net/b2cuseraccount-api-authz/v1/api/User',
        AuthUrl: 'https://login.thefa.com',
        Tenant: 'b2cthefa.onmicrosoft.com',
        SignUpPolicyId: 'B2C_1A_FASIGNUPCUSTOMOTPPFF_STRUIVER',
        SignInPolicyId: 'B2C_1A_SIGNUP_SIGNINMFA',
        PasswordResetPolicyId: 'B2C_1A_PWRESETCUSTOMOTPPFF_STR', // Password Reset Policy Name
        ForgottenEmailPolicyId:
          'b2c_1a_signup_signinprofileeditcustomotpfancodev3js', // SignIn and Recovery Using FAN Code policy
        DontKnowMyFanPolicyId: 'B2C_1A_FARECOVERYCUSTOMOTPPFF_STRUIVER', // Policy for Account Recovery
        ClientSecret: '3xV7Q~CnizXt.iELkWy5Tqng6xZVsaCCWZjTN',
        FAAppToken: 'FIN',
        MyAccountUrl: 'https://myaccount.thefa.com/#/home',
        b2cScopes: [
          'https://b2cthefa.onmicrosoft.com/ActScopeApp/WRITE',
          'https://b2cthefa.onmicrosoft.com/ActScopeApp/READ',
          'https://b2cthefa.onmicrosoft.com/ActScopeApp/user_impersonation',
        ],
      };
      break;
  }
  return b2cSettings;
};

export default b2cEnvVariables;
