import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const {
    borderRadius,
    breakpoints,
    colors,
    screenSizes,
    spacing,
  } = useTheme();

  return {
    base: (isTeamCard) => css`
      margin-bottom: 1.5rem;
      
      ${isTeamCard && css`
        padding: ${spacing.m} 0 0;
        &:not(:last-of-type) {
          padding: ${spacing.m} 0;
        }
          margin-bottom: 0;
      `}
    `,
    title: css`
      margin-bottom: 1.5rem;
      border-bottom: 1px solid ${colors.lightGrey};
      padding-bottom: ${spacing.s};
      color: ${colors.navy};

      ${breakpoints.l} {
        font-size: 1.5rem;
      }
    `,
    list: css`
      display: grid;
      grid-gap: ${spacing.s};

      ${breakpoints.m} {
        grid-template-columns: repeat(3, minmax(0, 1fr));
      }
    `,
    buttonWrapper: (isTeamCard) => css`
      margin-top: ${spacing.m};
      ${isTeamCard && css`
        background-color: ${colors.blue};
      `}

      ${breakpoints.m} {
        border-top: 1px solid ${colors.lightGrey};
        border-bottom: 1px solid ${colors.lightGrey};
      }
    `,
    button: isTeamCard => css`
      display: flex;
      align-items: center;
      margin: 0 auto;
      font-weight: normal;
      text-transform: uppercase;
      text-decoration: none;

      ${isTeamCard && css`
        background-color: ${colors.blue};
        color: ${colors.white};
      ` }

      @media screen and (max-width: ${screenSizes.m}) {
        background-color: ${colors.blue};
        color: ${colors.white};
        font-size: 0.875rem;
        letter-spacing: 0.01em;
        border-radius: ${borderRadius.s};
        width: 100%;
        max-width: 300px;
        display: block;
        padding: 0.9375rem 1.5rem;
      }
    `,
    buttonIcon: (isExpanded, isTeamCard) => css`
      transform: rotate(90deg);
      > g {
        fill: ${isTeamCard ? colors.blue : colors.white};
      }

      ${isExpanded &&
      css`
        transform: rotate(-90deg);
      `}

      @media screen and (max-width: ${screenSizes.m}) {
        display: none;
      }
    `,
  };
};
