/** @jsxImportSource @emotion/react */
import { useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';

import { loginRequest } from '../../authConfig';
import b2cEnvVariables from '../../b2cEnvVariables';
import HiddenContent from '../HiddenContent';

const DontKnowMyFan = () => {
  const { instance } = useMsal();
  const appConfig = b2cEnvVariables(process.env.REACT_APP_ENVIRONMENT);

  // for account recovery - don't know my fan
  const ACCOUNT_RECOVERY = {
    ...loginRequest,
    authority: `${appConfig.AuthUrl}/${appConfig.Tenant}/${appConfig.DontKnowMyFanPolicyId}`,
  };

  useEffect(() => {
    localStorage.setItem('rootUrl', localStorage.getItem('pathInfomation'));
    instance.loginRedirect(ACCOUNT_RECOVERY);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <HiddenContent>Redirecting to don't know my fan page</HiddenContent>
  );
};

export default DontKnowMyFan;
