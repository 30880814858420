/** @jsxImportSource @emotion/react */

import { Fragment } from 'react';

import useStyles from '../ClubDetails/styles';
import ContactDetails from '../ContactDetails';
import InfoFacilities from '../InfoFacilities';
import Text from '../Text';

const ProviderDetails = ({
  footballtypes,
  contactAddress,
  onClick,
  providercontact,
  organisername,
  providerfootballtypes,
  category,
}) => {
  const styles = useStyles();
  const isprovider = true;

  return (
    <Fragment>
      <div css={styles.middle(isprovider)}>
        <div css={styles.leftContent}>
          <div css={styles.top}>
            <Text as="h2" size="5" css={styles.title}>
              About The Provider
            </Text>
          </div>
          <div css={styles.clubDescriptionWrapper}>
            <p>
              This approved provider currently has no sessions bookable via
              England Football’s online system. To find out when their{' '}
              {category} sessions are taking place please contact them directly
              via the details provided.
            </p>
          </div>
          <div css={styles.footballTypeWrapper}>
            {footballtypes && (
              <InfoFacilities
                isProvider={true}
                isClubInfo={true}
                {...providerfootballtypes}
                title="This Provider runs sessions catering for: "
              />
            )}
          </div>
        </div>
        {
          <div css={styles.rightContents}>
            <ContactDetails
              title="Provider Contact"
              subTitle="Please contact the provider to find out more:"
              organisername={organisername}
              isProvider={true}
              contactDetails={providercontact}
              isClubInfo={true}
              onClick={onClick}
            >
              <span css={styles.wrapText}>{contactAddress}</span>
            </ContactDetails>
          </div>
        }
      </div>
    </Fragment>
  );
};

export default ProviderDetails;
