import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const {
    screenSizes,
    spacing,
    colors,
    utility,
    borderRadius,
    breakpoints,
    mediaQueries,
  } = useTheme();

  return {
    closeBtn: css`
      ${utility.buttonDefault};
      position: absolute;
      border-radius: 50%;
      top: ${spacing.s};
      right: ${spacing.s};
      width: 40px;
      height: 40px;
      outline: 0;

      svg {
        padding: 0.1rem;
        border: ${borderRadius.s} solid transparent;
      }

      &:focus {
        svg {
          padding: 0.1rem;
          border: ${borderRadius.s} solid ${colors.navy};
          border-radius: 50%;
        }
      }

      ${breakpoints.l} {
        right: auto;
        left: ${spacing.s};
      }

      ${mediaQueries.zoomedIn} {
        background: ${colors.white};
        z-index: 2;
      }
    `,
    question: css`
      @media (max-width: ${screenSizes.l}) {
        height: 100%;
        display: grid;
        align-items: center;
        position: relative;
      }

      ${mediaQueries.zoomedIn} {
        position: unset;
        overflow: auto;
      }
    `,
    backLink: css`
      display: flex;
      padding: 0.2rem;
      border-radius: ${borderRadius.m};
      align-items: center;
      position: absolute;
      bottom: ${spacing.s};
      left: ${spacing.m};
      color: ${colors.white};
      border: 1.5px solid transparent;
      text-decoration: none;
      outline: 0;

      &:focus:not(:hover) {
        padding: 0.2rem;
        border: 1.5px solid ${colors.navy};
        border-radius: ${borderRadius.m};
        text-decoration: underline;
      }

      svg {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-right: ${spacing.xs};
      }

      &:hover {
        outline: 0;
        text-decoration: underline;
      }

      ${breakpoints.l} {
        bottom: ${spacing.xl};
        left: 7rem;
        color: ${colors.navy};
      }

      ${mediaQueries.zoomedIn} {
        bottom: ${spacing.xs};
        color: ${colors.navy};
        z-index: 2;

        &:hover {
          color: ${colors.blue};
          outline: 0;
        }
      }
    `,
    formGroup: css`
      padding-bottom: ${spacing.s};

      input,
      textarea,
      select {
        border-radius: ${borderRadius.s};
        display: block;
        width: 100%;
        padding: 0.45rem 0.75rem;
        font-size: 0.875rem;
        line-height: 1.5;
        background-color: ${colors.white};
        resize: none;
        color: ${colors.darkGrey};
        margin-bottom: ${spacing.xs};
      }

      textarea,
      select {
        border: 1px solid transparent;
      }
    `,
    label: css`
      display: block;
      color: ${colors.navy};
      padding-bottom: ${spacing.xs};
      font-weight: 600;
      &::after {
        content: '*';
        color: ${colors.red};
        padding-left: ${borderRadius.s};
      }
    `,
    notes: css`
      color: ${colors.darkGrey};
      font-size: 0.875rem;
      line-height: 1.125rem;
      padding-bottom: ${spacing.xs};

      ${breakpoints.l} {
        font-size: 0.875rem;
        line-height: 1.125rem;
      }
    `,
    checkBoxText: css`
      span {
        width: 20px;
        height: 20px;
        border: ${borderRadius.s} solid transparent;

        &:after {
          top: 0;
        }
      }
      p {
        font-size: 0.875rem;
        line-height: 1.125rem;
      }
      ${breakpoints.l} {
        p {
          font-size: 0.875rem;
          line-height: 1.125rem;
        }
      }
    `,
    title: css`
      padding-bottom: ${spacing.s};
      color: ${colors.navy};
    `,
    ageGroup: css`
      width: 60%;

      ${breakpoints.l} {
        width: 35%;
      }
    `,
    btn: css`
      border-radius: ${borderRadius.s};
      padding: 14px 24px 14px 24px;
      border: ${borderRadius.s} solid transparent;
      margin-bottom: ${spacing.s};
      text-transform: uppercase;
      font-weight: normal;
      width: 100%;
      cursor: pointer;

      &:disabled {
        background-color: ${colors.greyShade1};
        color: ${colors.white};
      }
    `,
    submitBtn: css`
      background-color: ${colors.red};
      color: ${colors.white};
    `,
    cancelBtn: css`
      background-color: transparent;
      color: ${colors.navy};
      border: ${borderRadius.s} solid ${colors.navy};
    `,
    notesSafeguard: css`
      h6 {
        font-weight: bold;
        padding: ${spacing.s} 0 ${spacing.xs};
        color: ${colors.navy};
      }
      ul {
        list-style: disc;
        padding-left: ${spacing.s};

        li {
          color: ${colors.darkGrey};
          padding-bottom: ${spacing.xs};
          font-size: 0.875rem;
          line-height: 1.125rem;
        }
      }
    `,
    buttonWrapper: css`
      margin-top: ${spacing.s};
    `,
    confirmCheck: css`
      margin-top: ${spacing.xs};
    `,
    textDanger: css`
      padding-top: ${spacing.xs};
      color: ${colors.red};
      font-size: ${spacing.s};
    `,
    danger: css`
      border: 1px solid ${colors.red};

      &:focus,
      &:focus-visible {
        border: 1px solid ${colors.red};
        outline: 0;
      }
    `,
    labelDanger: css`
      color: ${colors.boldRed};
    `,
    inputField: css`
      border: 1px solid transparent;
    `,
    recaptchaContainer: css`
      transform: scale(0.95);
      transform-origin: 0 0;
    `,
    form: css`
      margin: 0.3rem;
    `,
  };
};
