/** @jsxImportSource @emotion/react */
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useParams } from 'react-router-dom';

import { clubDetailsPageAnalytics } from '../../analyticsConsts';
import {
  CLUB,
  SELECTED_RECOMMENDATIONS_TYPE_ID,
  SESSION,
  VENUE
} from '../../const';
import { useMLRecommendations } from '../../context/mlRecommendations';
import { usePageInfo } from '../../context/pageInfo';
import { usePreferencesContext } from '../../context/preferences';
import useClubAccreditation from '../../hooks/useClubAccreditation';
import useClubContactDetails from '../../hooks/useClubContactDetails';
import useElementHeight from '../../hooks/useElementHeight';
import useErrorBoundary from '../../hooks/useErrorBoundary';
import { getClubDetails, getClubPageData } from '../../services';
import normaliseClubDetails from '../../utils/normaliseClubDetails';
import ClubDetails from '../ClubDetails';
import Footer from '../Footer';
import HeroProvider from '../HeroProvider';
import Loader from '../Loader';
import TabPanel from '../TabPanel';
import Tabs from '../Tabs';
import VenueClubList from '../VenueClubList';
import { getWeekdays } from '../VenuePage';
import defaultPreferences from '../VenuePage/defaultPreferences';
import useStyles from './styles';


const { CLUB_NAME_HEADING, CLUB_TEAM_TITLE, CLUB_DETAILS_WRAPPER } =
  clubDetailsPageAnalytics;

const tabs = [
  {
    id: SESSION,
    label: 'TEAMS',
  },
  {
    id: VENUE,
    label: 'CLUB INFORMATION',
  },
];

const ClubPageRef = () => {
  const { id } = useParams();
  const throwError = useErrorBoundary();
  const [providerPageData, setProviderPageData] = useState();
  const [isLoadingPageData, setIsLoadingPageData] = useState(true);
  const { preferences } = usePreferencesContext();
  const {
    [SELECTED_RECOMMENDATIONS_TYPE_ID]: selectedRecommendationType,
    Age,
    WeekDays,
  } = preferences || {};
  const [clubDetails, setClubDetails] = useState();
  const [filteredTeams, setFilteredTeams] = useState();
  const [unfilteredTeams, setUnfilteredTeams] = useState();
  // when we get the club details we want to fetch the accreditation and contact details
  const accreditation = useClubAccreditation(clubDetails?.accreditationid);
  const contactDetails = useClubContactDetails(clubDetails?.accreditationid);
  const [isFiltersApplied, setIsFiltersApplied] = useState(
    // if we have a selected recommendation type then we'll use the preferences
    // when getting the provider details, otherwise we show all sessions
    !!selectedRecommendationType
  );
  const [isLoading, setIsLoading] = useState(false);
  const [selectedType, setSelectedType] = useState(SESSION);
  const loadingRef = useRef();
  const listRef = useRef();
  const { height: titleHeight, elementRef: titleElement } = useElementHeight();
  const { isMLCard } = useMLRecommendations();
  const styles = useStyles();

  const [, setPageInfo] = usePageInfo();
  // set page info only when we have the venue name
  useEffect(() => {
    if (!clubDetails?.title) {
      return;
    }

    setPageInfo('club', clubDetails.title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubDetails]);

  useEffect(() => {
    getClubPageData()
      .then(({ data }) => {
        setProviderPageData(data);
        setIsLoadingPageData(false);
      })
      .catch(throwError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set path information
  useEffect(() => {
    localStorage.setItem('pathInfomation', window.location.pathname);
  }, []);


  useEffect(() => {
    // if we have already collected data for both states
    // or we are already loading the data
    // skip the fetching
    if (
      (isFiltersApplied && filteredTeams) ||
      (!isFiltersApplied && unfilteredTeams) ||
      isLoading
    ) {
      return;
    }

    setIsLoading(true);

    // we send the preferences only if we want the filters applied
    // otherwise we pass an empty object
    getClubDetails(
      id,
      // use default preference if ML card selected
      isMLCard
        ? defaultPreferences
        : isFiltersApplied
          ? { ...defaultPreferences, ...preferences }
          : defaultPreferences
    )
      .then(({ data }) => {
        if (data) {
          const { teams, ...normalisedDetails } = normaliseClubDetails(data);
          // update the details only if we don't already have them
          // they don't change with filters
          if (!clubDetails) {
            setClubDetails(normalisedDetails);
          }

          if (isFiltersApplied) {
            setFilteredTeams(teams);
            setIsLoading(false);
            return;
          }

          setUnfilteredTeams({
            ...{ teams: teams },
            totalTeams: normalisedDetails.totalTeams,
          });
          setIsLoading(false);
        }
      })
      .catch(throwError);
  }, [
    filteredTeams,
    id,
    isMLCard,
    isFiltersApplied,
    unfilteredTeams,
    clubDetails,
    isLoading,
    preferences,
    throwError,
  ]);

  // if we are loading and we already have the venue details and
  // at least one type of session then we are fetching
  // the other type of sessions
  const isRefreshingTeams =
    isLoading && clubDetails && (filteredTeams || unfilteredTeams);
  const isFirstLoad = isLoading && !clubDetails;

  // we are using useLayoutEffect here because we cannot rely on
  // setIsRefreshingDetails having run before we shift focus above
  // due to React async setting of state
  useLayoutEffect(() => {
    // if we're refreshing then shift focus to the loading message
    if (isRefreshingTeams) {
      loadingRef.current?.focus({ preventScroll: true });
      return;
    }
    // filtersApplied has changed
    // if both filtered and unfiltered data have been loaded
    // we can shift the focus to the list when the button is clicked
    if (filteredTeams && unfilteredTeams) {
      setTimeout(() => {
        listRef.current?.focus({ preventScroll: true });
      }, 300);
    }
  }, [filteredTeams, isFiltersApplied, isRefreshingTeams, unfilteredTeams]);

  const onTeamCardClick = () => {
    setSelectedType(VENUE);
  };

  // if there is no selected recommendations type or
  // the filters are removed show different title
  const title =
    selectedRecommendationType && isFiltersApplied
      ? `${Age} year old ${selectedRecommendationType} Teams`
      : 'All teams at this club';

  // if there is no selected recommendations type or
  // the filters are removed don't show subtitle
  const subtitle =
    selectedRecommendationType && isFiltersApplied
      ? `${clubDetails?.totalteams
      } Teams that match my search playing on ${getWeekdays(WeekDays)}`
      : `${unfilteredTeams?.totalteams} Teams at this club`;

  const clubTeams = isFiltersApplied ? filteredTeams : unfilteredTeams?.teams;

  if (isLoadingPageData) {
    return <Loader message="Loading club info" />;
  }

  return (
    <div css={styles.base}>
      <section>
        {providerPageData && (
          <HeroProvider
            title={clubDetails?.title}
            address={clubDetails?.address}
            distance={clubDetails?.distance}
            latitude={clubDetails?.latitude}
            longitude={clubDetails?.longitude}
            county={clubDetails?.county}
            accreditation={accreditation}
            css={styles.hero}
            type={'club'}
            textSize={3}
            ref={titleElement}
            titleHeight={titleHeight}
            hasPreferences={!!selectedRecommendationType}
            analyticsId={CLUB_NAME_HEADING}
            {...providerPageData.hero}
          />
        )}
      </section>
      <section
        id={CLUB_DETAILS_WRAPPER}
        css={styles.wrapper(!!selectedRecommendationType)}
      >
        <div css={styles.card}>
          <Fragment>
            <Tabs
              onClick={setSelectedType}
              selectedType={selectedType}
              tabs={tabs}
              css={styles.tabs}
            />
            {isFirstLoad ? (
              <Loader message="Loading team info" />
            ) : (
              <Fragment>
                <TabPanel selectedType={selectedType} id={SESSION}>
                  {isRefreshingTeams && (
                    <Loader
                      message={
                        isFiltersApplied
                          ? 'Reapplying filters'
                          : 'Removing filters'
                      }
                      ref={loadingRef}
                    />
                  )}
                  {clubTeams && (
                    <VenueClubList
                      title={title}
                      titleAnalyticsId={CLUB_TEAM_TITLE}
                      subtitle={subtitle}
                      data={clubTeams}
                      type={CLUB}
                      canToggle={selectedRecommendationType && !isMLCard}
                      isFiltered={isFiltersApplied}
                      toggleFiltered={() =>
                        setIsFiltersApplied(!isFiltersApplied)
                      }
                      ref={listRef}
                      cardAction={onTeamCardClick}
                    />
                  )}
                </TabPanel>
                <TabPanel selectedType={selectedType} id={VENUE}>
                  {clubDetails && (
                    <ClubDetails
                      {...clubDetails}
                      contact={contactDetails}
                      pageData={providerPageData}
                    />
                  )}
                </TabPanel>
              </Fragment>
            )}
          </Fragment>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ClubPageRef;
