import { useEffect } from 'react';
import { Route, Switch } from 'react-router-dom';

import DontKnowMyFan from '../components/Account/DontKnowMyFan';
import SignInFan from '../components/Account/SignInFan';
import SignUp from '../components/Account/SignUp';
import ClubPage from '../components/ClubPage';
import ClubPageRef from '../components/ClubPageRef';
import MiniOnboardingDetailPage from '../components/MiniOnboardingDetailPage';
import MOMapPage from '../components/MOMapPage';
import TeamPage from '../components/TeamPage';
import { useAccessTokenContext } from '../context/accessToken';
import { setLocalItem } from '../hooks/useLocalStorage';
import HomePage from './../components/HomePage';
import MapPage from './../components/MapPage';
import OnboardingModal from './../components/OnboardingModal';
import Page from './../components/Page';
import Page404 from './../components/Page404';
import PageWithFilters from './../components/PageWithFilters';
import ProviderPage from './../components/ProviderPage';
import RecommendationsPage from './../components/RecommendationsPage';
import VenuePage from './../components/VenuePage';
import { EMPTY_STRING, MAP_PAGE, RECOMMENDATION_PAGE } from './../const';

const setLocalStorageUrl = () => {
  let urlValue = true;
  let locationName = window.location.href;
  let localStorageName;
  let isMiniOnboarding = true;
  switch (true) {
    case locationName.indexOf('letgirlsplay') > -1:
      localStorageName = 'ISLETGOGIRLSPLAY';
      isMiniOnboarding = false;
      break;
    case locationName.indexOf('wildcats') > -1:
      localStorageName = 'ISWILDCATS';
      break;
    case locationName.indexOf('justplay') > -1:
      localStorageName = 'ISJUSTPLAY';
      break;
    case locationName.indexOf('ageuk') > -1:
      localStorageName = 'ISAGEUK';
      break;
    case locationName.indexOf('youthclub') > -1:
      localStorageName = 'ISYOUTHCLUB';
      break;
    case locationName.indexOf('adultclub') > -1:
      localStorageName = 'ISADULTCLUB';
      break;
    case locationName.indexOf('volunteer') > -1:
      localStorageName = 'ISVOLUNTEER';
      isMiniOnboarding = false;
      break;
    case locationName.indexOf('squadgirls') > -1:
      localStorageName = 'ISSQUADGIRLS';
      break;
    case locationName.indexOf('external') > -1:
      localStorageName = 'ISIFRAME';
      isMiniOnboarding = false;
      break;
    default:
      localStorageName =
        window.location.pathname === '/'
          ? null
          : JSON.parse(sessionStorage.getItem('MOPREVIOUSURL'));
      break;
  }

  setLocalItem('MOPREVIOUSURL', JSON.stringify(localStorageName));
  setLocalItem('ISMINIONBOARDING', localStorageName ? isMiniOnboarding : false);

  return localStorageName
    ? setLocalItem(localStorageName, urlValue)
    : EMPTY_STRING;
};

const AppRoutes = ({ accesstoken }) => {
  const { setTokenID } = useAccessTokenContext();

  // Check the site is external or not
  useEffect(() => {
    let footballTypes = [
      'ISIFRAME',
      'ISLETGOGIRLSPLAY',
      'ISWILDCATS',
      'ISJUSTPLAY',
      'ISAGEUK',
      'ISYOUTHCLUB',
      'ISADULTCLUB',
      'ISSQUADGIRLS',
      'ISVOLUNTEER',
    ];
    footballTypes.forEach(type => sessionStorage.removeItem(type));
    setLocalStorageUrl();
  });

  useEffect(() => {
    setTokenID(accesstoken);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accesstoken]);

  return (
    <Switch>
      <Route exact path="/">
        <Page isBanner="true">
          <HomePage />
        </Page>
      </Route>
      <Route exact path="/onboarding/external">
        <OnboardingModal isVisible="true" />
      </Route>
      <Route exact path="/onboarding/letgirlsplay">
        <OnboardingModal isLetsGoGirlsPlay={true} isVisible="true" />
      </Route>
      <Route exact path="/onboarding/wildcats">
        <OnboardingModal isWildCats={true} isVisible="true" />
      </Route>
      <Route exact path="/onboarding/justplay">
        <OnboardingModal isJustPlay={true} isVisible="true" />
      </Route>
      <Route exact path="/onboarding/ageuk">
        <OnboardingModal isVisible="true" />
      </Route>
      <Route exact path="/onboarding/youthclub">
        <OnboardingModal isVisible="true" />
      </Route>
      <Route exact path="/onboarding/adultclub">
        <OnboardingModal isVisible="true" />
      </Route>
      <Route exact path="/onboarding/volunteer">
        <OnboardingModal isVisible="true" />
      </Route>
      <Route exact path="/onboarding/squadgirls">
        <OnboardingModal isVisible="true" />
      </Route>
      <Route exact path="/account/signup">
        <SignUp />
      </Route>
      <Route exact path="/results">
        <PageWithFilters pageName={RECOMMENDATION_PAGE}>
          <MiniOnboardingDetailPage />
        </PageWithFilters>
      </Route>
      <Route exact path="/account/signinfan">
        <SignInFan />
      </Route>
      <Route exact path="/account/idontknowmyfan">
        <DontKnowMyFan />
      </Route>
      <Route exact path="/recommendations">
        <PageWithFilters pageName={RECOMMENDATION_PAGE}>
          <RecommendationsPage />
        </PageWithFilters>
      </Route>
      <Route exact path="/recommendations/map">
        <PageWithFilters pageName={MAP_PAGE}>
          <MapPage />
        </PageWithFilters>
      </Route>
      <Route exact path="/results/map">
        <PageWithFilters pageName={MAP_PAGE}>
          <MOMapPage />
        </PageWithFilters>
      </Route>
      <Route exact path="/venue/:id">
        <Page>
          <VenuePage />
        </Page>
      </Route>
      <Route exact path="/provider/:id">
        <Page>
          <ProviderPage />
        </Page>
      </Route>
      <Route exact path="/club/:id">
        <Page>
          <ClubPage />
        </Page>
      </Route>
      <Route exact path="/team/:id">
        <Page>
          <TeamPage />
        </Page>
      </Route>
      <Route exact path="/clubRef/:id">
        <Page>
          <ClubPageRef />
        </Page>
      </Route>
      <Route path="*">
        <Page404 />
      </Route>
    </Switch>
  );
};

export default AppRoutes;
