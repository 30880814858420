/** @jsxImportSource @emotion/react */

import Button from '../Button';
import useStyles from './styles';

// this allows us to get the previous or next
// sibling for our current target and shift focus
const handleKeyboardNavigation = event => {
  if (event.key !== 'ArrowRight' && event.key !== 'ArrowLeft') {
    return;
  }

  const elementToFocus =
    event.key === 'ArrowRight'
      ? event.target?.nextSibling
      : event.target?.previousSibling;

  if (!elementToFocus) {
    return;
  }

  // focus on the other button
  elementToFocus.focus();
  // simulate a click to switch between tabs
  elementToFocus.click();
};

// This component should be used along the TabPanel component,
// it uses the same pattern for `id` and `aria-controls`
const MonthTabs = ({ onClick, selectedType, ariaLabel, tabs, ...rest }) => {
  const styles = useStyles();

  return (
    <div css={styles.base} role="tablist" aria-label={ariaLabel} {...rest}>
      {tabs && tabs.length > 0 && (
        <div css={styles.tabs(tabs.length)}>
          {tabs.map(tab => {
            const isSelected = selectedType === tab.id;

            return (
              <Button
                key={tab.id}
                css={styles.tab(isSelected)}
                primary={isSelected}
                onClick={() => onClick(tab.id)}
                onKeyUp={handleKeyboardNavigation}
                role="tab"
                aria-selected={isSelected}
                id={`tab-${tab.id}`}
                aria-controls={`tabpanel-${tab.id}`}
                tabIndex={isSelected ? 0 : -1}
              >
                {tab.label}
              </Button>
            );
          })}
        </div>
      )}
    </div>
  );
};

export default MonthTabs;
