import { createContext, useCallback, useContext, useState } from 'react';

import {
  CLUB,
  DONT_MIND,
  KICKABOUT_CASUAL,
  RECOMMENDATIONS_CASUAL,
  RECOMMENDATIONS_CLUB,
} from '../const';
import {
  getLocalItem,
  removeLocalItem,
  setLocalItem,
} from '../hooks/useLocalStorage';

const localKeyMapping = {
  [KICKABOUT_CASUAL]: RECOMMENDATIONS_CASUAL,
  [CLUB]: RECOMMENDATIONS_CLUB,
  [DONT_MIND]: RECOMMENDATIONS_CLUB,
};

const localClubRecommendations = getLocalItem(RECOMMENDATIONS_CLUB);
const clubRecommendationsValue = localClubRecommendations
  ? JSON.parse(localClubRecommendations)
  : null;
const localCasualRecommendations = getLocalItem(RECOMMENDATIONS_CASUAL);
const casualRecommendationsValue = localCasualRecommendations
  ? JSON.parse(localCasualRecommendations)
  : null;

export const RecommendationsContext = createContext();

// From the api we receive the next page of each football type
// so we need to merge the results for each of them
const mergeRecommendations = (oldRecommendations, newRecommendations) => {
  return oldRecommendations.reduce((acc, oldType) => {
    const newRecommendationsSection = newRecommendations.findIndex(
      section =>
        section.FootballType === oldType.FootballType &&
        section.DisabilityId === oldType.DisabilityId
    );

    // if the api didn't return results for one of the types do not update it
    if (newRecommendationsSection === -1) {
      return [...acc, oldType];
    }

    const arrayKey = oldType.RecommendationClubCartDto
      ? 'RecommendationClubCartDto'
      : oldType.RecommendationCentreCartDto.length < 0
      ? 'RecommendationProviderCartDto'
      : 'NULL';
    const arrayData = `${
      arrayKey === 'NULL' ? 'RecommendationProviderCartDto' : arrayKey
    }`;
    const arrayKeyData = `${
      arrayKey === 'NULL'
        ? 'RecommendationCentreCartDto'
        : 'RecommendationClubCartDto'
    }`;

    return [
      ...acc,
      {
        ...oldType,
        [arrayData]: [
          ...oldType[arrayData],
          ...(arrayKeyData === 'RecommendationCentreCartDto'
            ? newRecommendations[newRecommendationsSection][arrayKeyData]
            : []),
          ...newRecommendations[newRecommendationsSection][arrayData],
        ],
      },
    ];
    // eslint-disable-next-line
  }, []);
};

export const RecommendationsProvider = ({ children }) => {
  const [clubRecommendationsContext, setClubRecommendationsContext] = useState(
    clubRecommendationsValue
  );
  const [casualRecommendationsContext, setCasualRecommendationsContext] =
    useState(casualRecommendationsValue);

  const setRecommendations = useCallback((data, type = CLUB) => {
    const localKey = localKeyMapping[type];
    setLocalItem(localKey, JSON.stringify(data));

    if (type === KICKABOUT_CASUAL) {
      setCasualRecommendationsContext(data);
      return;
    }

    setClubRecommendationsContext(data);
  }, []);

  // we export the add recommendations method to avoid
  // having the merging logic in each component
  const addRecommendations = useCallback(
    (data, type) => {
      if (data.length === 0) {
        return;
      }

      if (type === KICKABOUT_CASUAL) {
        const newData = mergeRecommendations(
          casualRecommendationsContext,
          data
        );

        setCasualRecommendationsContext(newData);
        setLocalItem(RECOMMENDATIONS_CASUAL, JSON.stringify(newData));
        return;
      }

      const newData = mergeRecommendations(clubRecommendationsContext, data);

      setClubRecommendationsContext(newData);
      setLocalItem(RECOMMENDATIONS_CLUB, JSON.stringify(newData));
    },
    [casualRecommendationsContext, clubRecommendationsContext]
  );

  const resetRecommendations = () => {
    removeLocalItem(RECOMMENDATIONS_CLUB);
    setClubRecommendationsContext(null);
    removeLocalItem(RECOMMENDATIONS_CASUAL);
    setCasualRecommendationsContext(null);
  };

  return (
    <RecommendationsContext.Provider
      value={{
        clubRecommendations: clubRecommendationsContext,
        casualRecommendations: casualRecommendationsContext,
        setRecommendations,
        addRecommendations,
        resetRecommendations,
      }}
    >
      {children}
    </RecommendationsContext.Provider>
  );
};

export const useRecommendations = () => useContext(RecommendationsContext);
