/** @jsxImportSource @emotion/react */
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import React, { useEffect } from 'react';

import { loginRequest } from '../../authConfig';
import b2cEnvVariables from '../../b2cEnvVariables';
import HiddenContent from '../HiddenContent';

const SignInFan = () => {
  const { instance } = useMsal();
  const appConfig = b2cEnvVariables(process.env.REACT_APP_ENVIRONMENT);
  const isAuthenticated = useIsAuthenticated();

  // for forgot email
  const SIGNIN_FAN_REQUEST = {
    ...loginRequest,
    authority: `${appConfig.AuthUrl}/${appConfig.Tenant}/${appConfig.ForgottenEmailPolicyId}`,
  };

  useEffect(() => {
    if (!isAuthenticated) {
      localStorage.setItem('rootUrl', localStorage.getItem('pathInfomation'));
      instance.loginRedirect(SIGNIN_FAN_REQUEST)
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  return (
    <HiddenContent>Redirecting to forgot email page</HiddenContent>
  );
};

export default SignInFan;
