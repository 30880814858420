import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const {
    breakpoints,
    colors,
    typography,
    spacing,
  } = useTheme();

  return {
    base: css`
      height: 100%;
      display: grid;
      grid-template-rows: auto 1fr;
      grid-template-columns: 100%;
    `,
    hero: css`
      h1 {
        text-transform: capitalize;
        ${typography.headings[4].mobile};
        text-align: center;
        
        &::after {
          background: none;
        } 

        ${breakpoints.l} {
          ${typography.headings[3].desktop};
          max-width: 100%;
          text-align: center;
          width: 80%;
          margin: 0 auto;
        }
      }   
    `,
    wrapper: (hasPreferences) => css`
      width: 100%;
      margin: 0 auto;
      margin-top: calc(100vh - 80px);
      padding: ${spacing.s} 0;

      ${breakpoints.m} {
        margin-top: calc(100vh - 100px);
        padding: 0;
      }

      ${breakpoints.l} {
        max-width: 1196px;
        margin-top: calc(100vh - 50px);
      }
    `,
    card: css`
      position: relative;
      padding: ${spacing.s};
      background-color: ${colors.white};
      margin-bottom: ${spacing.m};
      height: 95%;
      box-shadow: 0px 4px 6px rgba(91, 104, 133, 0.1);

      ${breakpoints.xl} {
        padding: ${spacing.l};
        grid-template-rows: unset;
        grid-gap: ${spacing.s};
      }
    `,
    tabs: css`
      margin-bottom: ${spacing.s};

      ${breakpoints.xl} {
        margin-bottom: ${spacing.m};
      }
    `,
  };
};
