/** @jsxImportSource @emotion/react */
import useStyles from './styles';

const ProgressBar = ({ progress, ...rest }) => {
  const styles = useStyles();
  return (
    <div
      css={styles.progressBar(progress)}
      {...rest}
      data-testid="progress-bar"
    />
  );
};

export default ProgressBar;
