import { MAX_TEXT } from "../../const";

export default (duration, { max = 0 } = {}) => {
  if (!duration) return '';

  let durationValue = duration;
  let suffix = durationValue === 1 ? 'hr' : 'hrs';

  // Eg.: if max is 4 we want to show 3+
  if (durationValue === max) {
    durationValue = max - 1;
    suffix = `${suffix}+`;
  }

  return `${durationValue} ${suffix}  ${MAX_TEXT}`;
};
