import { LogLevel } from '@azure/msal-browser';

import b2cEnvVariables from './b2cEnvVariables';

const ua = window.navigator.userAgent;
const msie = ua.indexOf('MSIE ');
const msie11 = ua.indexOf('Trident/');
const msedge = ua.indexOf('Edge/');
const firefox = ua.indexOf('Firefox');
const isIE = msie > 0 || msie11 > 0;
const isEdge = msedge > 0;
const isFirefox = firefox > 0; // Only needed if you need to support the redirect flow in Firefox incognito

const appConfig = b2cEnvVariables(process.env.REACT_APP_ENVIRONMENT);

export const msalConfig = {
  auth: {
    clientId: appConfig.ClientId,
    authority: `${appConfig.AuthUrl}/${appConfig.Tenant}/${appConfig.SignInPolicyId}`,
    redirectUri: window.location.origin + '/recommendations',
    knownAuthorities: [appConfig.AuthUrl.replace(/(^\w+:|^)\/\//, '')],
    validateAuthority: false,
    clientSecret: appConfig.ClientSecret,
    b2cScopes: appConfig.b2cScopes,
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage',
    storeAuthStateInCookie: isIE || isEdge || isFirefox,
  },
  system: {
    loggerOptions: {
      loggerCallback: (level, message, containsPii) => {
        if (containsPii) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
            console.error(message);
            return;
          case LogLevel.Info:
            console.info(message);
            return;
          case LogLevel.Verbose:
            console.debug(message);
            return;
          case LogLevel.Warning:
            console.warn(message);
            return;
          default:
            return;
        }
      },
    },
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: msalConfig.auth.b2cScopes,
  extraQueryParameters: { FAAppToken: appConfig.FAAppToken },
};


// for password reset request
export const PASSWORD_RESET_REQUEST = {
  ...loginRequest,
  authority: `${appConfig.AuthUrl}/${appConfig.Tenant}/${appConfig.PasswordResetPolicyId}`,
};