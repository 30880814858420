/** @jsxImportSource @emotion/react */
import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';
import { useParams } from 'react-router-dom';

import {
  BACK_TO_RESULTS,
  venueDetailsPageAnalytics,
} from '../../analyticsConsts';
import {
  PAGE_NUMBER,
  PAGE_SIZE,
  PREVIOUSPAGE,
  PROVIDER_TAB,
  SELECTED_RECOMMENDATIONS_TYPE_ID,
} from '../../const';
import { useMLRecommendations } from '../../context/mlRecommendations';
import { usePageHistory } from '../../context/pageHistory';
import { usePageInfo } from '../../context/pageInfo';
import { usePreferencesContext } from '../../context/preferences';
import { useRecommendations } from '../../context/recommendations';
import weekdaysFormatter from '../../formatters/weekdays';
import useClubVenueCoordination from '../../hooks/useClubVenueCoordination';
import useElementHeight from '../../hooks/useElementHeight';
import useErrorBoundary from '../../hooks/useErrorBoundary';
import useLocalStorage from '../../hooks/useLocalStorage';
import useMediaQuery from '../../hooks/useMediaQuery';
import normaliseSessionLogo from '../../normaliser/sessionlogo';
import {
  getProviderDetails,
  getSessionLogo,
  getVenueDetails,
  getVenuePageData,
} from '../../services';
import normaliseProviderPage from '../../utils/normaliseProviderPage';
import normaliseVenueDetails from '../../utils/normaliseVenueDetails';
import useStylesClub from '../ClubPage/styles';
import ContactMap from '../ContactMap';
import FAInitiativeEvent from '../FAInitiativeEvent';
import Footer from '../Footer';
import HeroProvider from '../HeroProvider';
import { RightArrow } from '../Icons';
import Link from '../Link';
import Loader from '../Loader';
import ProviderDetails from '../ProviderDetails';
import StickyScrollSpyNav from '../StickyScrollSpyNav';
import VenueDetails from '../VenueDetails';
import {
  backURL,
  providerMLCard,
  providerPreviousPageName,
} from '../VenuePage/conditionCheck';
import useStylesVenue from '../VenuePage/styles';
import defaultPreferences from './defaultPreferences';
import useStyles from './styles';

const { VENUE_NAME_HEADING, VENUE_DETAILS_WRAPPER } = venueDetailsPageAnalytics;

//change the weekdaysFormatter to say `Any days`
export const getWeekdays = days =>
  weekdaysFormatter(days) === 'Any' ? 'any days' : weekdaysFormatter(days);

const ProviderPage = () => {
  const { id } = useParams();
  const throwError = useErrorBoundary();
  const [providerPageData, setProviderPageData] = useState();
  const [isLoadingPageData, setIsLoadingPageData] = useState(true);
  const { preferences } = usePreferencesContext();
  const {
    [SELECTED_RECOMMENDATIONS_TYPE_ID]: selectedRecommendationType,
    Category,
  } = preferences || {};
  const [venueDetails, setVenueDetails] = useState();
  const [logoDetails, setLogoDetails] = useState();
  const isDesktop = useMediaQuery('screen and (min-width: 768px)');
  const { casualRecommendations } = useRecommendations();
  const [isLoading, setIsLoading] = useState(false);
  const [isVenueDetailsLoading, setIsVenueDetailsLoading] = useState(false);
  const loadingRef = useRef();
  const venueLoadingRef = useRef();
  const providerRef = useRef();
  const { height: titleHeight, elementRef: titleElement } = useElementHeight();
  const styles = useStyles();
  const stylesClub = useStylesClub();
  const stylesVenue = useStylesVenue();
  const { isMLCard } = useMLRecommendations();
  const [, setPageInfo] = usePageInfo();
  const { getLocalItem } = useLocalStorage();
  const previousUrl = getLocalItem(PREVIOUSPAGE);
  const { previousPage } = usePageHistory();
  const backUrl = backURL(previousPage, previousUrl);
  const previousPageName = providerPreviousPageName(previousUrl);

  const sourceLoction = useClubVenueCoordination(
    selectedRecommendationType,
    preferences
  );

  useEffect(() => {
    casualRecommendations?.map(casualItem => {
      if (casualItem?.RecommendationProviderCartDto) {
        casualItem?.RecommendationProviderCartDto?.map(centerData => {
          centerData?.ProviderCartDto?.map(providerItem => {
            if (parseInt(id) === providerItem.ProviderId) {
              providerRef.current = centerData?.PitchFinderId;
            }
            return providerItem;
          });
          return centerData;
        });
      }
      return casualItem;
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [casualRecommendations, id, venueDetails]);

  // set page info only when we have the venue name
  useEffect(() => {
    if (!venueDetails?.title) {
      return;
    }
    setPageInfo('venue', venueDetails.title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [venueDetails]);

  useEffect(() => {
    localStorage.setItem('pathInfomation', window.location.pathname);
  }, []);

  useEffect(() => {
    getVenuePageData()
      .then(({ data }) => {
        const venuePageDetails = data;
        const randomNumber = Math.round(Math.random() * 4) + 1;
        venuePageDetails.hero.images.desktop.src = `/images/desktop/venue/hero/${randomNumber}.jpg`;
        venuePageDetails.hero.images.mobile.src = `/images/mobile/venue/hero/${randomNumber}.jpg`;
        setProviderPageData(venuePageDetails);
        setIsLoadingPageData(false);
      })
      .catch(throwError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getSessionLogo()
      .then(({ data }) => {
        const logoData = normaliseSessionLogo(data);
        setLogoDetails(logoData);
      })
      .catch(throwError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isLoading) {
      return;
    }
    setIsLoading(true);
    setIsVenueDetailsLoading(true);

    const preferenceWithPagination = {
      ...defaultPreferences, // get the page after the current one
      [PAGE_SIZE]: 6,
      [PAGE_NUMBER]: 1,
    };

    const idsplit = id.indexOf('p');
    const sliceid = id.slice(0, idsplit);

    Promise.all([
      getProviderDetails(
        id.slice(idsplit + 1),
        sliceid,
        providerMLCard(
          isMLCard,
          preferenceWithPagination,
          defaultPreferences,
          preferences
        )
      )
        .then(({ data }) => {
          if (data) {
            const { sessions, sessionsCount, venueTabs, ...normalisedDetails } =
              normaliseProviderPage(data);
            if (!venueDetails) {
              setVenueDetails(normalisedDetails);
            }

            setIsLoading(false);
            setIsVenueDetailsLoading(false);
          }
        })
        .catch(() => {
          getVenueDetails(
            id,
            providerMLCard(
              isMLCard,
              preferenceWithPagination,
              defaultPreferences,
              preferences
            )
          ).then(({ data }) => {
            if (data) {
              const {
                sessions,
                sessionsCount,
                venueTabs,
                ...normalisedDetails
              } = normaliseVenueDetails(data);
              if (!venueDetails) {
                setVenueDetails(normalisedDetails);
              }

              setIsLoading(false);
              setIsVenueDetailsLoading(false);
            }
          });
        }),
    ]);

    // we send the preferences only if we want the filters applied
    // otherwise we pass an empty object

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // if we are loading and we already have the venue details and
  // at least one type of session then we are fetching
  // the other type of sessions
  const isRefreshingSessions = isLoading && venueDetails;

  // we are using useLayoutEffect here because we cannot rely on
  // setIsRefreshingDetails having run before we shift focus above
  // due to React async setting of state
  useLayoutEffect(() => {
    // if we're refreshing then shift focus to the loading message
    if (isRefreshingSessions) {
      loadingRef.current?.focus({ preventScroll: true });
      return;
    }
    // filtersApplied has changed
    // if both filtered and unfiltered data have been loaded
    // we can shift the focus to the list when the button is clicked
  }, [isRefreshingSessions]);

  useLayoutEffect(() => {
    if (isVenueDetailsLoading) {
      venueLoadingRef.current?.focus();
    }
  }, [isVenueDetailsLoading]);

  if (isLoadingPageData) {
    return <Loader message="Loading venue info" />;
  }

  return (
    <div css={styles.base}>
      <section>
        {providerPageData && (
          <HeroProvider
            latitude={venueDetails?.latitude}
            textSize={3}
            address={venueDetails?.address}
            longitude={venueDetails?.longitude}
            title={venueDetails?.title}
            type={'casual football'}
            css={styles.hero}
            ref={titleElement}
            distance={venueDetails?.distance}
            hasPreferences={!!selectedRecommendationType}
            sourceLoction={sourceLoction}
            titleHeight={titleHeight}
            previousPageData={{ name: previousPageName, backUrl: backUrl }}
            analyticsId={VENUE_NAME_HEADING}
            {...providerPageData.hero}
            isvenue="true"
            Category={Category}
          />
        )}
      </section>
      <section
        css={stylesVenue.wrapper(titleHeight, !!selectedRecommendationType)}
        id={VENUE_DETAILS_WRAPPER}
      >
        <div css={stylesClub.card}>
          {isVenueDetailsLoading && (
            <Loader
              message="Loading venue Details"
              css={stylesClub.loader}
              ref={venueLoadingRef}
            />
          )}
          {!isVenueDetailsLoading && (
            <StickyScrollSpyNav
              title={venueDetails?.title}
              titleHeight={titleHeight}
              nav={PROVIDER_TAB}
            >
              <section ref={React.createRef()} css={stylesClub.navContent}>
                {(!!selectedRecommendationType || Category) && isDesktop && (
                  <Link
                    id={BACK_TO_RESULTS}
                    to={backUrl}
                    css={[stylesClub.backLink, stylesVenue.backButton]}
                  >
                    <RightArrow css={stylesClub.arrowRotate} />{' '}
                    <span>Back to {previousPageName} view</span>
                  </Link>
                )}
                {venueDetails && (
                  <Fragment>
                    <ProviderDetails
                      {...venueDetails}
                      pageData={providerPageData}
                      providerPage={true}
                      providercontact={venueDetails?.providercontact}
                    />
                  </Fragment>
                )}
              </section>
              <section ref={React.createRef()} css={stylesClub.navContent}>
                <Fragment>
                  <VenueDetails
                    {...venueDetails}
                    pageData={providerPageData}
                    isProvider={true}
                  />
                </Fragment>
              </section>
              <section ref={React.createRef()} css={stylesClub.navContent}>
                {venueDetails && (
                  <Fragment>
                    <ContactMap
                      {...venueDetails}
                      titleHeader="Venue Contact"
                      subTitle="For information about a specific session, please get in touch with the relevant session contact"
                      locationDetails={{
                        latitude: venueDetails.latitude,
                        longitude: venueDetails.longitude,
                        title: 'New Club',
                      }}
                      providerName={venueDetails?.title}
                      centername={venueDetails.centername}
                      contact={venueDetails.contact}
                      sourceLoction={sourceLoction}
                      isVenueLocation="true"
                    />
                    {
                      <FAInitiativeEvent
                        {...venueDetails}
                        pageData={providerPageData}
                        faInitiativeDetails={logoDetails}
                        isDesktop={isDesktop}
                        isProvider={true}
                      />
                    }
                  </Fragment>
                )}
              </section>
            </StickyScrollSpyNav>
          )}
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ProviderPage;
