import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { spacing, colors, breakpoints } = useTheme();

  return {
    footer: css`
      background-color: ${colors.white};
      position: relative;
      display: grid;
      align-items: center;
      width: 100%;
      padding: 0 ${spacing.m} ${spacing.s};
      text-align: center;

      ${breakpoints.l} {
        padding: 1.5rem;
        justify-items: center;
      }
    `,
    textWrapper: css`
      position: relative;
      z-index: 1;
      text-align: center;
    `,
    mainText: css`
      font-size: 20px;
      line-height: 22px;
      color: ${colors.navy};
      letter-spacing: -0.02em;
      font-weight: 700;
    `,
    cta: css`
      text-transform: uppercase;
      margin: ${spacing.s} auto 1.5rem auto;

      &:focus {
        border: 1px solid ${colors.blue};
        background-color: transparent;
        color: ${colors.blue};
      }

      ${breakpoints.l} {
        width: 21.5625rem;
      }
    `,
  };
};
