import { useEffect, useState } from 'react';

import { EMAIL, EMPTY_STRING, PHONE_NUMBER, WEBSITE } from '../../const';
import { getClubContactDetails } from '../../services';

const getContactDetails = details => {
  const { isAllowPublic, phone, email, website, individualName } = details;

  //  check whether the website contains http or https
  const href = website
    ? /^https?:\/\//i.test(website) ? website
      : /^http?:\/\//i.test(website) ? website : `http://${website}`
    : EMPTY_STRING;

  if (!isAllowPublic || (!phone && !email)) {
    return {
      details: {
        ...(href && { [WEBSITE]: href }),
      },
      otherDetails: {
        ...(email && { [EMAIL]: email }),
        ...(phone && { [PHONE_NUMBER]: phone }),
      },
      isPublic: false,
    };
  }

  return {
    details: {
      ...(email && { [EMAIL]: email }),
      ...(phone && { [PHONE_NUMBER]: phone }),
      ...(href && { [WEBSITE]: href }),
    },
    name: individualName,
    isPublic: true,
  };
};

export default id => {
  const [contactDetails, setContactDetails] = useState();

  useEffect(() => {
    if (!id) {
      return;
    }

    getClubContactDetails(id)
      .then(({ data }) => {
        setContactDetails(getContactDetails(data));
      })
      // don't throw an error if we don't have the accreditation
      // fail gracefully
      .catch(() => { });
  }, [id]);

  return contactDetails;
};
