import React, { useMemo } from 'react';

import HiddenContent from '../HiddenContent';

const AlertHiddenRegion = ({ text, live = 'polite' }) => {
  const memoedText = useMemo(() => text || '', [text]);

  return <HiddenContent aria-live={live}>{memoedText}</HiddenContent>;
};

export default AlertHiddenRegion;
