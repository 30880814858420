
import { useCallback, useEffect, useState } from "react";

import { SCROLL_DOWN, SCROLL_UP } from "../../const";
import { useScrollDirectionContext } from "../../context/scrollDirection";

export default () => {
  // storing this to get the scroll direction
  const [lastScrollTop, setLastScrollTop] = useState(0);
  // scroll direction would be either up or down
  const [scrollDirection, setScrollDirection] = useState(SCROLL_DOWN);
  const { setScroll } = useScrollDirectionContext();

  const listener = useCallback(() => {
    let position = window.pageYOffset || document.documentElement.scrollTop;
    if (position > 100 && lastScrollTop <= position) {
      setScrollDirection(lastScrollTop > -position ? SCROLL_UP : SCROLL_DOWN);
      setScroll(lastScrollTop > -position ? SCROLL_UP : SCROLL_DOWN);
      setLastScrollTop(-position);
      return;
    }
    setScrollDirection(SCROLL_DOWN);
    setScroll(SCROLL_DOWN);
    setLastScrollTop(position <= 0 ? 0 : position);
  }, [lastScrollTop, setScroll]);

  useEffect(() => {
    window.addEventListener("scroll", listener, { passive: true });
    return () => {
      window.removeEventListener("scroll", listener);
    };
  }, [listener]);

  return {
    scrollDirection
  };
}