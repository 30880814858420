import { useEffect, useState } from 'react';

import {
  BOY,
  CASUAL,
  CLUBS,
  DISABILITY,
  EMPTY_STRING,
  GIRL,
  KICKABOUT_CASUAL,
  MEN,
  MIXED_ADULT,
  MIXED_CHILD,
  ONBOARDING_PREFERENCES,
  WOMEN,
  YES,
  YOUTH_AGE,
} from '../../const';
import useLocalStorage from '../../hooks/useLocalStorage';

const getMixedImg = (Age, young, adult) => {
  return Age <= YOUTH_AGE ? young : adult;
};

export default () => {
  const { getLocalItem } = useLocalStorage();
  const [imgPath, setImgPath] = useState();
  const preferencesObj = JSON.parse(getLocalItem(ONBOARDING_PREFERENCES)) || {};
  const { Age, PlayWith, Disabilityoption, SelectedFootballType } =
    preferencesObj;

  useEffect(() => {
    let img = EMPTY_STRING;
    switch (PlayWith) {
      case 1:
      case 4:
        img = getMixedImg(Age, BOY, MEN);
        break;
      case 2:
      case 5:
        img = getMixedImg(Age, GIRL, WOMEN);
        break;
      default:
        img = getMixedImg(Age, MIXED_CHILD, MIXED_ADULT);
        break;
    }

    const imagePath =
      Disabilityoption === YES
        ? (img === 'mixed_child') | (img === 'mixed_adult')
          ? `${DISABILITY}/mixed`
          : DISABILITY
        : SelectedFootballType === KICKABOUT_CASUAL
        ? CASUAL
        : CLUBS && (img === 'mixed_child') | (img === 'mixed_adult')
        ? 'mixed'
        : CLUBS;

    setImgPath(`${imagePath}/${img}`);
  }, [Age, Disabilityoption, PlayWith, SelectedFootballType]);

  return imgPath;
};
