/** @jsxImportSource @emotion/react */

import { useCallback, useState } from 'react';
import Carousel, { consts } from 'react-elastic-carousel';

import Button from '../Button';
import { NextArrow, PreviousArrow } from '../Icons';
import useStyles from './styles';

const CarouselCard = ({
  children,
  itemtoscroll = 1,
  itemtoshow = 3,
  itemspadding = [20, 7, 10, 0],
}) => {
  const styles = useStyles();
  const [isGradient, setIsGradient] = useState(false);

  const setActiveBtn = type => {
    if (type === consts.PREV) {
      document.getElementById('rec-next-arrow').classList.add('active');
      document.getElementById('rec-prev-arrow').classList.remove('active');
    } else {
      document.getElementById('rec-prev-arrow').classList.add('active');
      document.getElementById('rec-next-arrow').classList.remove('active');
    }
  };

  // to set custom icon for left and right arrow
  const myArrow = useCallback(
    ({ type, onClick, isEdge }) => {
      const pointer =
        type === consts.PREV ? (
          <PreviousArrow id="rec-prev-arrow" css={styles.arrowStyle} />
        ) : (
          <NextArrow id="rec-next-arrow" css={styles.arrowStyle} />
        );

      return (
        <div css={styles.carouselButton(type, isGradient)}>
          <Button
            tabIndex="0"
            onClick={() => {
              onClick();
              setActiveBtn(type);
            }}
            disabled={isEdge}
            aria-label={`Carousel ${type === consts.PREV ? 'previous' : 'next'
              }`}
          >
            {pointer}
          </Button>
        </div>
      );
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [isGradient]
  );

  const rollbackGradientEffect = useCallback(() => {
    setIsGradient(false);
    document.getElementById('rec-prev-arrow').classList.remove('active');
    document.getElementById('rec-next-arrow').classList.remove('active');
  }, []);

  const setGradientEffect = useCallback(() => {
    setIsGradient(true);
  }, []);

  return (
    <div css={styles.base}>
      <Carousel
        itemsToScroll={itemtoscroll}
        itemsToShow={itemtoshow}
        itemPadding={itemspadding}
        onNextStart={setGradientEffect}
        onPrevStart={setGradientEffect}
        renderArrow={e => myArrow(e)}
        onChange={rollbackGradientEffect}
      >
        {children}
      </Carousel>
    </div>
  );
};

export default CarouselCard;
