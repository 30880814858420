import { css } from '@emotion/react';

import { AGE_GROUP_ID } from '../../const';
import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, screenSizes, spacing, colors, utility, mediaQueries } =
    useTheme();

  return {
    base: css`
      display: grid;
      align-items: center;
      grid-gap: ${spacing.s};
      width: 100%;

      ${breakpoints.l} {
        grid-template-columns: 1fr 1fr;
        align-items: center;
        grid-gap: ${spacing.l};
      }

      ${mediaQueries.zoomedIn} {
        padding-bottom: ${spacing.m};
      }
    `,
    answers: css`
      ${mediaQueries.zoomedIn} {
        padding-left: 5px;
        padding-right: 5px;
      }
    `,
    title: readableId => css`
      font-family: 'FS Dillon Bold';
      font-size: ${spacing.m};
      font-style: italic;
      line-height: 2.25rem;
      text-align: center;
      color: ${colors.navy};
      margin-bottom: ${spacing.xs};
      width: ${readableId === AGE_GROUP_ID ? '91%' : '85%'};
      margin: auto;

      &:focus {
        outline: none;
      }

      ${breakpoints.l} {
        width: 90%;
        text-align: left;
        font-size: 3.25rem;
        line-height: 3.125rem;
        margin-bottom: ${spacing.s};
        margin-left: 0;
      }
    `,
    helpText: readableId => css`
      color: ${colors.darkGrey};
      text-align: center;
      margin-top: ${spacing.xs};
      margin-bottom: ${spacing.s};

      ${breakpoints.l} {
        margin-right: ${readableId === AGE_GROUP_ID ? '5rem' : '10rem'};
        margin-bottom: 0;
        text-align: left;
      }
    `,
    fieldset: css`
      display: contents;

      legend {
        ${utility.checkboxHidden}
      }
    `,
    infoLink: css`
      margin-top: ${spacing.s};
      text-align: center;

      ${breakpoints.l} {
        margin-top: ${spacing.m};
      }

      ${mediaQueries.zoomedIn} {
        background-position: 0 180%;
      }
    `,
    progressBar: css`
      margin-bottom: ${spacing.m};

      @media (max-width: ${screenSizes.l}) {
        position: absolute;
        top: 14%;
        left: 0;
      }

      ${mediaQueries.zoomedIn} {
        top: 0;
        left: 50%;
        transform: translateX(-50%);
        width: calc(100% - 2.5rem);
      }
    `,
    helpSecText: css`
      margin-bottom: 0;

      ${breakpoints.l} {
        margin: 0.8rem 5rem 0 0;
        padding-bottom: 1.5rem;
      }
    `,
  };
};
