import { Facebook, Instagram, Twitter } from './components/Icons';

// Total number of steps of the onboarding
// Fixed number to calculate progress
// We can't rely on the questions array due to the dynamic nature
// of the implementation
export const TOTAL_STEPS = 8;
export const EMPTY_STRING = '';
export const ZERO = 0;
export const HYPHEN = '-';

//character limit
export const CHARLIMIT_HOMEPAGE = 100;

// Question types
export const SINGLE_QUESTION = 'single';
export const MULTI_QUESTION = 'multiple';
export const MULTI_OPTIONAL_QUESTION = 'multi_optional';
export const SLIDER_QUESTION = 'slider';
export const GEOLOCATION_QUESTION = 'location';
export const POSTCODE_QUESTION = 'postcode';
export const AGE_QUESTION = 'age';
export const AGE_GROUP_QUESTION = 'age_group';
export const AGE_GROUP_VOLUNTEER_QUESTION = 'age_group_volunteer';

// CTAs types
export const PRIMARY_CTA = 'primary';
export const SECONDARY_CTA = 'secondary';
export const BASIC_CTA = 'basic';

// Local storage keys
export const ONBOARDING_PREFERENCES = 'onboarding_preferences';
export const RECOMMENDATIONS_CLUB = 'recommendations_club';
export const RECOMMENDATIONS_CASUAL = 'recommendations_casual';
export const RECOMMENDATIONS_ML_CLUB = 'recommendations_ml_club';
export const RECOMMENDATIONS_ML_CASUAL = 'recommendations_ml_casual';
export const RECOMMENDATIONS_MO_CLUB = 'recommendations_mo_club';
export const RECOMMENDATIONS_MO_CASUAL = 'recommendations_mo_casual';
export const FILTERS_APPLIED = 'filters_applied';
export const FILTERS_APPLIED_CASUAL = 'filters_applied_tmp_casual';

// Questions readable Ids
export const SEARCH_FOR_ID = 'SearchForUser';
export const AGE_ID = 'Age';
export const AGE_GROUP_ID = 'AgeGroup';
export const GENDER_ID = 'Gender';
export const PLAY_WITH_ID = 'PlayWith';
export const FOOTBALL_TYPE_ID = 'FootballType';
export const WEEKDAYS_ID = 'WeekDays';
export const DISABILITY_OPTION_ID = 'Disabilityoption';
export const DISABILITY_TYPE_ID = 'DisabilityType';
export const OPT_CURRENT_LOCATION_ID = 'OptforCurrentLocation';
export const POSTCODE_ID = 'PostCode';
export const PAGE_NUMBER = 'PageNumber';
export const PAGE_SIZE = 'PageSize';
export const SESSION_DURATION = 'SessionDuration';
export const DISABILITY_FACILITY = 'DisabilityFacility';
export const SURFACE_TYPE = 'SurfaceType';
export const SESSION_PRICE = 'SessionPrice';

// Helper preferences properties
export const SELECTED_FOOTBALL_TYPE_ID = 'SelectedFootballType';
export const SELECTED_RECOMMENDATIONS_TYPE_ID = 'SelectedRecommendationsType';
export const DISABILITY_TYPE = 'SelectedDisabilityType';

//provider informations
export const RECOMMENDATION_PROVIDER =
  'View provider details for session information';
export const VENUE_PROVIDER_INFORMATION =
  'This venue also hosts FA approved providers who choose to manage their sessions outside of our system';
export const VENUE_NO_SESSION_INFORMATION =
  'This venue hosts FA approved providers who choose to manage their sessions outside of our system';
export const VENUE_INFO =
  'Click a provider name below for details and booking information';

// Mapping Ids for API

// Play with
export const BOYS_OR_MEN = 1;
export const GIRLS_OR_WOMEN = 2;
export const MIXED = 3;
export const BOYS_OR_MEN_MIXED = 4;
export const GIRLS_OR_WOMEN_MIXED = 5;
export const BOYS_AND_MEN = 6;
export const GIRLS_AND_WOMEN_MIXED = 7;

// Football type
export const KICKABOUT_CASUAL = 1;
export const CLUB = 2;
export const DONT_MIND = 3;

// Days
export const ALL_DAYS = '1,2,3,4,5,6,7';
export const WEEKDAYS = '1,2,3,4,5';
export const WEEKENDS = '6,7';

// Disability options
export const YES = 1;
export const NO = 2;
export const NOT_SURE = 3;

// Onboarding session Id
export const SESSION_ID = 'SearchId';

// Location options
export const LATITUDE_ID = 'Latitude';
export const LONGITUDE_ID = 'Longitude';

export const PAGE_NUMBER_VALUE = 1;
export const PAGE_SIZE_VALUE = 10;
export const MO_PAGE_SIZE_VALUE = 5;

export const VENUE_PAGE_SIZE_VALUE = 3;

// Search for variations
export const MYSELF = 'Myself';
export const MY_CHILD = 'My child';
export const SOMEONE_ELSE = 'Someone else';
export const LET_GO_GIRLS_PLAY = "What's the player's age?";
export const JUST_PLAY_AGE_QUESTION = 'How old is the player?';
export const ADULT_CLUB_AGE_QUESTION = 'How old is the player?';

export const YOUTH_AGE = 17;

// Provider details
export const SESSION = 'Session';
export const VENUE = 'Venue';
export const CONTACT = 'Contact';
export const EMAIL = 'email';
export const PHONE_NUMBER = 'number';
export const WEBSITE = 'website';

// Filter types
export const MULTI_FILTER = 'multiple';
export const LOCATION_FILTER = 'location';
export const SLIDER_FILTER = 'slider';
export const MIXED_GENDER_FILTER = 'mixed';

// Filter data types
export const DAY_PREFERENCE_DATA = 0;
export const LOCATION_DATA = 1;

// Event details
export const WILDCATS = 'Wildcats';
export const WEETABIX_WILDCATS = 'Weetabix Wildcats';
export const AGE_UK = 'Age UK Walking Football';
export const LETGIRLSPLAY = '#LetGirlsPlay Football Sessions';
export const JUST_PLAY = 'The FA Snickers Just Play';
export const JUST_PLAY_PROTEIN = 'Snickers Protein Just Play';
export const UNITE = 'Unite';
export const SQUAD = 'Squad Girls Football';
export const DISABILITY_RECREATION_CENTRES =
  'England Football Disability Recreation Centres';
export const GIRLS_PILOT = 'The FA 12-14 Girls Football Pilot';

// club event details
export const ADULT_CLUB = '11v11';

// Session duration filter helpers
export const MAX_DURATION = 4;
export const MAX_DURATION_OVERRIDE = 168; // 7 days

// Session duration filter helpers
export const MIN_SESSION_PRICE = 0;
export const MAX_SESSION_PRICE = 5;

export const MAX_TEXT = '(max)';

// scroll events
export const SCROLL_UP = 'up';
export const SCROLL_DOWN = 'down';

export const DISABILITY_TYPES_MAPPING = {
  1: 'Amputee',
  2: 'Blind',
  3: 'Cerebral Palsy',
  4: 'Deaf/Hearing Impaired',
  5: 'Downs syndrome',
  6: 'Dwarf',
  7: 'Frame',
  8: 'Learning Disability',
  9: 'Mental Health',
  10: 'Pan-Disability',
  11: 'Partially Sighted',
  12: 'Powerchair',
  13: 'Autism',
};

// Query parameters
export const ONBOARDING_PARAM = 'onboarding';

// App Error types
export const UnspecifiedError = 'unspecified';
export const App404 = 'notFound';
export const Api404 = 'api404';
export const Api500 = 'api500';

// page meta info - title map for dynamic values
export const RECOMMENDATIONS_PAGE_TITLE_MAPPING = {
  1: 'Casual',
  2: 'Clubs',
  3: 'Clubs and Casual',
};

export const CATEGORY_ICONS = [
  'weetabix-wildcats',
  'wildcats',
  'the-fa-snickers-just-play',
  'age-uk-walking-football',
  'unite',
  'snickers-protein-just-play',
  'squad-girls-football',
  '-letgirlsplay-football-sessions',
];

// England Football Team Url
export const EF_LOGO_HREF = 'https://www.englandfootball.com';

// captcha site key
export const CAPTCHA_SITE_KEY = '6Ldrj8UeAAAAANHPppzAW8uvUJxlxmK3UepBXTiv';

// common folder name to set recommendation images dynamically
export const BOY = 'boy';
export const MEN = 'men';
export const GIRL = 'girl';
export const WOMEN = 'women';
export const MIXED_VALUE = 'mixed';
export const MIXED_CHILD = 'mixed_child';
export const MIXED_ADULT = 'mixed_adult';
export const DISABILITY = 'disability';
export const CASUAL = 'casual';
export const CLUBS = 'club';
export const AGE = 'age';
export const CLUB_ID = 'ClubId';
export const CENTER_ID = 'CentreId';

// Gender for contact form analytics
export const MALE = 'Male';
export const FEMALE = 'Female';
export const GENDER_MIXED = 'Mixed';
export const MALE_MIXED = 'Male | Mixed';
export const FEMALE_MIXED = 'Female | Mixed';

export const SUBCATEGORY_TYPES = [
  '11v11',
  'futsal',
  'small-sided',
  'walking-football',
  'football',
];

export const SURFACE_VALUES = ['3G or Astroturf', 'Grass', 'Indoor', 'Others'];

//Gobal close banner link
export const BANNER_LINK =
  'https://play.englandfootball.thefa.com/BigFootballDay';

//onboarding modal cutout image path
export const ONBOARDING_IMAGE_URL = '/images/common/cutouts/';

export const RECOMMENDATION_DESCRIPTION = {
  1: 'Venues offering casual sessions matching your search',
  2: 'Clubs offering football teams that match your search',
};

export const CLUB_INTRO_TEXT = {
  development: 'Reduced sized football for children aged 5-11',
  'mini-soccer': 'Reduced size football for under-7s to under-10s',
  u11: '9-a-side football for children aged 11 and under',
  u12: '9-a-side football for children aged 12 and under',
  u13: 'Competitive 11-a-side football for those aged 13 and under',
  u14: 'Competitive 11-a-side football for those aged 14 and under',
  u15: 'Competitive 11-a-side football for those aged 15 and under',
  u16: 'Competitive 11-a-side football for those aged 16 and under',
  u17: 'Competitive 11-a-side football for those aged 17 and under',
  u18: 'Competitive 11-a-side football for those aged 18 and under',
  '11v11': 'The classic form the game with competitive 11-a-side leagues',
  'small-sided':
    'Competitive matches played with reduced number of players on smaller pitches.',
  futsal: 'Fast-paced five-a-side played with a smaller ball on hard courts',
  'walking-football': 'Slower paced form of the game with reduced contact',
};

export const CASUAL_INTRO_TEXT = {
  football:
    'Informal games with team sizes adapting to the number of players on the day. Easy sign-up and open to all abilty levels.',
  futsal: 'Five-a-side played with a smaller ball on hard courts',
  'walking-football': 'Slower paced form of the game with reduced contact',
  'small-sided':
    'Informal games with team sizes adapting to the number of players on the day. Easy sign-up and open to all abilty levels.',
  wildcats: 'Fun and friendly football sessions for girls aged 5-11',
};

export const DISABILITY_INTRO_TEXT = {
  'powerchair-football':
    'Indoor matches tailored to powerchair users, featuring a smaller pitch and a larger ball.',
  'pan-disability-futsal':
    'A faster-paced form of the game played on hard courts with a smaller ball and team sizes.',
};

// learn more about link for club
export const CLUB_LEARN_MORE_LINK = {
  development: {
    name: 'youth club football',
    link: 'https://www.englandfootball.com/play/youth-football/Youth-Club-Football',
  },
  'mini-soccer': {
    name: 'youth club football',
    link: 'https://www.englandfootball.com/play/youth-football/Youth-Club-Football',
  },
  u11: {
    name: 'U11 football',
    link: 'https://www.englandfootball.com/play/youth-football/Youth-Club-Football/U11-U12',
  },
  u12: {
    name: 'U12 football',
    link: 'https://www.englandfootball.com/play/youth-football/Youth-Club-Football/U11-U12',
  },
  u13: {
    name: 'U13 football',
    link: 'https://www.englandfootball.com/play/youth-football/Youth-Club-Football/U13-U14',
  },
  u14: {
    name: 'U14 football',
    link: 'https://www.englandfootball.com/play/youth-football/Youth-Club-Football/U13-U14',
  },
  u15: {
    name: 'U15 football',
    link: 'https://www.englandfootball.com/play/youth-football/Youth-Club-Football/U15-U16',
  },
  u16: {
    name: 'U16 football',
    link: 'https://www.englandfootball.com/play/youth-football/Youth-Club-Football/U15-U16',
  },
  u17: {
    name: 'U17 football',
    link: 'https://www.englandfootball.com/play/youth-football/Youth-Club-Football/U17-U18',
  },
  u18: {
    name: 'U18 football',
    link: 'https://www.englandfootball.com/play/youth-football/Youth-Club-Football/U17-U18',
  },
  '11v11': {
    name: '11-a-side',
    link: 'https://www.englandfootball.com/play/11-a-side-football',
  },
  'small-sided': {
    name: 'small-sided',
    link: 'https://www.englandfootball.com/play/small-sided-football',
  },
  futsal: {
    name: 'Futsal',
    link: 'https://www.englandfootball.com/play/ways-to-play/futsal',
  },
  'walking-football': {
    name: 'Walking Football',
    link: 'https://www.englandfootball.com/play/ways-to-play/walking-football',
  },
};

// learn more about link for casual
export const CASUAL_LEARN_MORE_LINK = {
  football: {
    name: 'casual football',
    link: 'https://www.englandfootball.com/play/casual-football',
  },
  futsal: {
    name: 'Futsal',
    link: 'https://www.englandfootball.com/play/ways-to-play/futsal',
  },
  'walking-football': {
    name: 'Walking Football',
    link: 'https://www.englandfootball.com/play/ways-to-play/walking-football',
  },
};

// CLUB and CASUAL link
export const CLUB_LINK = 'https://www.englandfootball.com/play/club-football';

export const CASUAL_LINK =
  'https://www.englandfootball.com/play/casual-football';

// ONLINE FORM - HERE LINK
export const HERE_LINK =
  'https://www.englandfootball.com/explore/county-football-associations';

// CANCEL URLS OF MINI ONBOARDING
export const LET_GIRLS_PLAY_CANCEL_URL =
  'https://www.englandfootball.com/play/in-education/letgirlsplay';
export const WILD_CATS_CANCEL_URL =
  'https://www.englandfootball.com/play/womens-and-girls-football/weetabix-wildcats';
export const JUST_PLAY_CANCEL_URL =
  'https://www.englandfootball.com/play/adult-football/just-play';
export const AGE_UK_CANCEL_URL =
  'https://www.englandfootball.com/play/adult-football/age-uk';
export const YOUTH_CLUB_CANCEL_URL =
  'https://www.englandfootball.com/play/youth-football';
export const ADULT_CLUB_CANCEL_URL =
  'https://www.englandfootball.com/play/11-a-side-football';
export const VOLUNTEER_CLUB_CANCEL_URL =
  'https://www.englandfootball.com/participate/volunteer';
export const SQUAD_CANCEL_URL =
  'https://www.englandfootball.com/Women-and-Girls/Play/Squad-Girls-Football';

// club event url
export const ADULT_FUTSAL_URL = 'https://www.englandfootball.com/play/futsal';

// page name for filter page
export const MAP_PAGE = 'map';
export const RECOMMENDATION_PAGE = 'recommendation';

export const RECOMMENDATION_TYPE = {
  0: 'RecommendationClubCartDto',
  1: 'RecommendationCentreCartDto',
  2: 'RecommendationMlClubCartDto',
  3: 'RecommendationMlCentreCartDto',
};

export const DISABILITY_FACILITIES_MAPPING = {
  ReceptionArea: 'Reception',
  Doorways: 'Doorways',
  ChangingFacility: 'Changing',
  ActivityArea: 'Activities',
  Toilets: 'Toilets',
  SocialAreas: 'Social',
  SpectatorAreas: 'Spectator Areas',
  EmergencyExit: 'Emergency Exits',
  parking: 'Parking',
};

export const ONBOARDING_URL = '/?onboarding=true';
export const ONBOARDING_IFRAME_URL = '/onboarding/external';
export const LETGOGIRLSPLAY_URL = '/onboarding/letgirlsplay';
export const JUST_PLAY_URL = '/onboarding/justplay';
export const WILDCATS_URL = '/onboarding/wildcats';
export const YOUTH_CLUB_URL = '/onboarding/youthclub';
export const AGE_UK_URL = '/onboarding/ageuk';
export const ADULTCLUB_URL = '/onboarding/adultclub';
export const SQUAD_GIRLS_URL = '/onboarding/squadgirls';
export const VOLUNTEER_URL = '/onboarding/volunteer';

// Onboarding disability ID
export const DISABILITY_ID = '191d8b36-171e-4227-836f-58a0b597744a';

// agegroup array
export const AGEGROUP_ELDER = [
  'open aged',
  'walking football',
  'u23',
  'u21',
  'u19',
];
export const AGEGROUP_YOUNGER = [
  'u5',
  'u6',
  'u7',
  'u8',
  'u9',
  'u10',
  'u11',
  'u12',
  'u13',
  'u14',
  'u15',
  'u16',
  'u17',
  'u18',
];
export const PREVIOUSPAGE = 'PREVIOUS_PAGE';
export const WALKING_FOOTBALL = 'walking football';
export const MATCH_AGEGROUP = ['U12', 'U11', 'U10', 'U9', 'U8', 'U7', 'U6'];

// menu for club details page
export const CLUB_TAB = [
  {
    id: VENUE,
    label: 'CLUB INFO',
  },
  {
    id: SESSION,
    label: 'TEAMS',
  },
  {
    id: 'CONTACT',
    label: 'CONTACT',
  },
];

export const TEAMS_TAB = [
  {
    id: 'TEAM_INFO',
    label: 'TEAM INFO',
    isHidden: false,
  },
  {
    id: 'TEAMS_STATS',
    label: 'TEAM STATS',
    isHidden: false,
  },
  {
    id: 'CONTACT',
    label: 'CONTACT',
    isHidden: false,
  },
];

// socialIcons
export const ICONS_MEDIA = {
  Facebook: Facebook,
  Twitter: Twitter,
  Instagram: Instagram,
};

export const VENUE_TAB = [
  {
    id: 'SESSIONS',
    label: 'SESSIONS',
    isHidden: false,
  },
  {
    id: 'VENUE_INFO',
    label: 'VENUE INFO',
    isHidden: false,
  },
  {
    id: 'CONTACT',
    label: 'CONTACT',
    isHidden: false,
  },
];

export const PROVIDER_TAB = [
  {
    id: 'PROVIDER DETAILS',
    label: 'PROVIDER DETAILS',
    isHidden: false,
  },
  {
    id: 'VENUE_INFO',
    label: 'VENUE INFO',
    isHidden: false,
  },
  {
    id: 'CONTACT',
    label: 'CONTACT',
    isHidden: false,
  },
];

export const QUERYISABOUT_OPTIONS = [
  {
    id: 1,
    option: 'Playing',
  },
  {
    id: 2,
    option: 'Coaching',
  },
  {
    id: 3,
    option: 'Volunteering',
  },
  {
    id: 4,
    option: 'Training',
  },
];

export const QUERYISABOUT_OPTIONS_VOLUNTEER = [
  {
    id: 1,
    option: 'Volunteering',
  },
  {
    id: 2,
    option: 'Coaching',
  },
  {
    id: 3,
    option: 'Playing',
  },
  {
    id: 4,
    option: 'Training',
  },
];

// CONTACT FORM FIELDS
export const FIRSTNAME = 'firstName';
export const LASTNAME = 'lastName';
export const QUERYISABOUT = 'queryIsAbout';
export const DESCRIPTION = 'description';
export const CONFIRMAGE = 'confirmAge';
export const CONFIRMSAFEGUARD = 'confirmSafeGuard';
export const GOOGLECAPTCHA = 'googleCaptcha';
export const SUBMIT_BTN = 'SUBMIT';
export const CANCEL = 'Cancel';
export const MESSAGE_VOLUNTEER_TEXT =
  'eg. Please could you contact me as I am interested in finding out about volunteering at your club.';
export const MESSAGE_TEXT =
  'eg. what days are training on, what days are matches on?';

// Event ID
export const EVENT_ID = {
  WELFARE_OFFICER: 4903,
  CLUB_CONTACT: 4904,
  PLAYER_PARENT: 4905,
};

export const MINIONBOARDING_TABS = [
  {
    id: 'LISTVIEW',
    label: 'List View',
    url: '/results',
  },
  {
    id: 'MAPVIEW',
    label: 'Map View',
    url: '/results/map',
  },
];

export const YOUTH_CLUBS_TYPE = ['Development', 'Mini Soccer', '9v9', '11v11'];
