const normaliseHomePageData = apiData => {
  const placeholder = apiData.sitecore.route.placeholders;
  const {
    Hero: [{ fields: heroFirstItemFields } = {}],
    FootballTypes,
    QuickLinks,
    FAInitiatives,
    BannerMessage,
  } = placeholder;
  const bannerInfo = BannerMessage[0].fields;
  const hexacolor = bannerInfo?.CopyFont?.fields?.CategoryValue?.value;
  const colorName = bannerInfo?.CopyFont?.fields?.CategoryType?.value;
  const textColorCheck = hexacolor ? hexacolor : colorName;
  const hexaBgColor = bannerInfo?.BGColour?.fields?.CategoryValue?.value;
  const colorBgName = bannerInfo?.BGColour?.fields?.CategoryType?.value;
  const bgColorCheck = hexaBgColor ? hexaBgColor : colorBgName;

  return {
    hero: {
      text: {
        main: heroFirstItemFields?.Tittle.value,
        secondary: heroFirstItemFields?.Description.value,
      },
      ctaText: heroFirstItemFields?.CTA_Text.value,
      images: {
        desktop: {
          src: heroFirstItemFields?.Desktop_Image.value.src,
        },
        mobile: {
          src: heroFirstItemFields?.Mobile_Image.value.src,
        },
      },
    },
    bannerInfo: {
      desktopBgImage: bannerInfo?.DesktopBG?.value?.src,
      mobileBgImage: bannerInfo?.MobileBG?.value?.src,
      bgColor: bgColorCheck,
      bannerUrl: bannerInfo?.Url?.value?.href,
      desktopLogo: bannerInfo?.DesktopLogo?.value?.src,
      mobileLogo: bannerInfo?.MobileLogo?.value?.src,
      closeButton: bannerInfo?.IsCloseButton?.value,
      bannerText: bannerInfo?.Copy?.value,
      textColor: textColorCheck,
      textLink: bannerInfo?.TextLink?.value?.text,
      textLinkUrl: bannerInfo?.TextLink?.value?.url,
    },
    banner: BannerMessage[0].fields,
    footballTypeHeadings: FootballTypes.map(({ fields }, index) => {
      return {
        id: index + 1,
        // can be used whenever safe checks are required.
        ...(fields?.Tittle.value && {
          heading: fields?.Tittle.value,
        }),
        description: fields?.Description.value,
        href: fields?.CTA_Link.value.href,
        images: {
          desktop: {
            src: fields?.Desktop_Image.value.src,
          },
          mobile: {
            src: fields?.Mobile_Image.value.src,
          },
        },
      };
    }),
    quickLinks: QuickLinks.map(({ fields }, index) => {
      return {
        id: index + 1,
        heading: fields?.Tittle.value,
        href: fields?.CTA_Link.value.href,
        description: fields?.Description.value,
        images: {
          desktop: {
            src: fields?.Desktop_Image.value.src,
          },
          mobile: {
            src: fields?.Mobile_Image.value.src,
          },
        },
      };
    }),
    faInitiatives: FAInitiatives.map(({ fields }, index) => {
      return {
        id: index + 1,
        description: fields?.Description.value,
        href: fields?.CTA_Link.value.href,
        initiativeLogo: {
          desktop: {
            src: fields?.Desktop_Logo.value.src,
          },
          mobile: {
            src: fields?.Mobile_Logo.value.src,
          },
        },
        images: {
          desktop: {
            src: fields?.Desktop_Image.value.src,
          },
          mobile: {
            src: fields?.Mobile_Image.value.src,
          },
        },
      };
    }),
  };
};

export default normaliseHomePageData;
