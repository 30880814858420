import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors, breakpoints, spacing, borderRadius } = useTheme();

  return {
    moreCard: css`
      display: grid;
      width: 195px;
      height: 170px;
      justify-items: center;
      align-items: center;
      text-align: center;
      border-radius: ${borderRadius.l};
      border: 1px solid ${colors.greyShade1};
      box-shadow: 0px 4px 6px rgba(91, 104, 133, 0.1);
      color: ${colors.navy};
      font-weight: 700;
      background-color: ${colors.white};

      ${breakpoints.xl} {
        padding: ${spacing.s} 0;
        height: 235px;

        &:focus {
          outline: 0;
          border: ${borderRadius.spacing} solid ${colors.navy};
        }
      }
    `,
    moreLink: css`
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 400;
      text-decoration: none;
      outline: 0;

      svg {
        margin-bottom: ${spacing.xs};
        width: ${spacing.l};
        height: ${spacing.l};
      }

      ${breakpoints.xl} {
        svg {
          margin-left: ${spacing.xs};
        }
      }
    `,
  };
};
