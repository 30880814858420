import { getCategoryName } from '../../components/OnboardingModal/MiniOnboarding';

export default eventDetails => {
  const categoryName = getCategoryName();
  window.dataLayer.push({
    event: 'All Events',
    eventCategory: eventDetails?.eventCategory
      ? eventDetails?.eventCategory
      : 'FF Onboarding',
    eventAction: eventDetails?.eventAction,
    eventLabel: eventDetails?.eventLabel,
    eventparameter1: eventDetails?.eventparameter1,
    eventparameter2: eventDetails?.eventparameter2,
    eventparameter3: eventDetails?.eventparameter3,
    eventparameter4: categoryName ? categoryName : undefined,
    item_list_name: eventDetails?.eventLabel?.toLowerCase(),
    age_group: eventDetails?.eventparameter1,
    link_text: eventDetails?.link_text?.toLowerCase(),
    disability: eventDetails?.eventparameter2?.toLowerCase(),
    location: eventDetails?.location?.toLowerCase(),
    gender_group: eventDetails?.gender_group,
    event_name: eventDetails?.event_name?.toLowerCase(),
    journey: categoryName ? categoryName?.toLowerCase() : 'find football',
  });
  window.dataLayer.push({
    event: 'Removal Tag',
    eventparameter1: undefined,
    eventparameter2: undefined,
    eventparameter3: undefined,
    eventparamenter4: undefined,
    item_list_name: undefined,
    age_group: undefined,
    link_text: undefined,
    disability: undefined,
    location: undefined,
    gender_group: undefined,
    event_name: undefined,
    journey: undefined,
  });
};
