/** @jsxImportSource @emotion/react */
import { useMsal } from '@azure/msal-react';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useForm } from 'react-hook-form';

import {
  AGE_QUESTION,
  CANCEL,
  CONFIRMAGE,
  CONFIRMSAFEGUARD,
  DESCRIPTION,
  EMAIL,
  EMPTY_STRING,
  FIRSTNAME,
  GOOGLECAPTCHA,
  LASTNAME,
  MESSAGE_TEXT,
  MESSAGE_VOLUNTEER_TEXT,
  ONBOARDING_PREFERENCES,
  QUERYISABOUT,
  QUERYISABOUT_OPTIONS,
  QUERYISABOUT_OPTIONS_VOLUNTEER,
  SUBMIT_BTN,
} from '../../const';
import useLocalStorage from '../../hooks/useLocalStorage';
import CheckboxAnswer from '../CheckboxAnswer';
import CustomSelectDropDown from '../CustomSelectDropDown';
import Text from '../Text';
import useStyles from './styles';

const volunteer = JSON.parse(sessionStorage.getItem('ISVOLUNTEER'));
const getAgeText = value => {
  switch (value) {
    case 'Playing':
      return 'player';
    case 'Training':
      return 'player';
    case 'Coaching':
      return 'coach';
    case 'Volunteering':
      return 'volunteer';
    default:
      return JSON.parse(sessionStorage.getItem('ISVOLUNTEER')) === true
        ? 'volunteer'
        : 'player';
  }
};

const getMessageText = value => {
  switch (value) {
    case 'Playing':
      return MESSAGE_TEXT;
    case 'Training':
    case 'Coaching':
      return MESSAGE_TEXT;
    case 'Volunteering':
      return MESSAGE_VOLUNTEER_TEXT;
    default:
      return JSON.parse(sessionStorage.getItem('ISVOLUNTEER')) === true
        ? MESSAGE_VOLUNTEER_TEXT
        : MESSAGE_TEXT;
  }
};

export const contactFormOptions = {
  firstName: { required: 'Firstname is required', maxLength: 255 },
  lastName: { required: 'Lastname is required', maxLength: 255 },
  email: {
    required: 'Email is required',
    pattern: {
      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i,
      message: 'Please enter a valid email address',
    },
    maxLength: 255,
  },
  age: {
    required: 'Age is required',
    min: 5,
    max: 100,
    maxLength: {
      value: 2,
    },
  },
  volunteerAge: {
    required: 'Age is required',
    min: 14,
    max: 100,
    maxLength: {
      value: 2,
    },
  },
  description: { required: 'Message is required', maxLength: 500 },
  queryIsAbout: { required: true },
};

const ContactForm = ({
  handleContact,
  onClick,
  isAlreadyFormSent,
  isDesktop,
}) => {
  const styles = useStyles();
  const {
    register,
    handleSubmit,
    setValue,
    setFocus,
    getValues,
    formState: { errors, isValid },
  } = useForm({ mode: 'onChange' });
  const [isAbove16State, setIsAbove16State] = useState(false);
  const [ageError, setAgeError] = useState(false);
  const [isConfirmSafeGuard, setIsConfirmSafeGuard] = useState(false);
  const [prevIsQueryAbout, setPrevIsQueryAbout] = useState(null);
  const [reCaptchaValue, setReCaptchaValue] = useState(null);
  const [queryData, setQueryData] = useState(
    JSON.parse(sessionStorage.getItem('ISVOLUNTEER')) === true
      ? 'Volunteering'
      : ''
  );
  const [ageText, setAgeText] = useState(getAgeText());
  const [messageText, setMessageText] = useState(getMessageText());
  const { accounts } = useMsal();
  const { getLocalItem } = useLocalStorage();
  const preferencesObj = JSON.parse(getLocalItem(ONBOARDING_PREFERENCES)) || {};
  const { Age: playerAge } = preferencesObj;

  const selectedDropDownOption = data => {
    setQueryData(data);
  };

  useEffect(() => {
    setTimeout(() => {
      const checkvalue =
        queryData === 'Volunteering'
          ? errors?.age?.ref?.value < contactFormOptions?.volunteerAge?.min
          : errors?.age?.ref?.value < contactFormOptions?.age?.min;
      setAgeError(checkvalue);
    }, 100);
  }, [errors, errors?.age?.ref?.value, queryData]);

  useEffect(() => {
    // if user is logged in populate data in relevent fields
    if (accounts.length > 0) {
      const accountsClaims = accounts[0].idTokenClaims;
      if (accountsClaims) {
        setValue(FIRSTNAME, accountsClaims?.given_name);
        setValue(LASTNAME, accountsClaims?.family_name);
        setValue(EMAIL, accountsClaims?.signInName);
      }
    }

    // populate age from onboarding preference
    if (playerAge) {
      setValue(
        AGE_QUESTION,
        queryData === 'Volunteering' ? '' : parseInt(playerAge)
      );
    }

    setValue(
      QUERYISABOUT,
      JSON.parse(sessionStorage.getItem('ISVOLUNTEER')) === true
        ? QUERYISABOUT_OPTIONS_VOLUNTEER[0].option
        : QUERYISABOUT_OPTIONS[0].option
    );
    setPrevIsQueryAbout(
      JSON.parse(sessionStorage.getItem('ISVOLUNTEER')) === true
        ? QUERYISABOUT_OPTIONS_VOLUNTEER[0].option
        : QUERYISABOUT_OPTIONS[0].option
    );

    // set initial focus on firstname
    setFocus(FIRSTNAME);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryData]);

  const onRecaptchaChange = value => {
    setReCaptchaValue(value);
  };

  // dynamically change text of age of player field
  const selectQueryOption = value => {
    setValue(
      QUERYISABOUT,
      isDesktop
        ? volunteer === true
          ? QUERYISABOUT_OPTIONS_VOLUNTEER[value].option
          : QUERYISABOUT_OPTIONS[value].option
        : value
    );
    // const valueofvalue = getValues(QUERYISABOUT);
    // provide some time to execute setValue request
    setTimeout(() => {
      setAgeText(getAgeText(isDesktop ? queryData : value));
      setMessageText(getMessageText(isDesktop ? queryData : value));
    }, 300);
  };

  return (
    <Fragment>
      <form
        onSubmit={handleSubmit(handleContact)}
        autoComplete="off"
        css={styles.form}
      >
        <div css={styles.formGroup}>
          <label
            htmlFor={FIRSTNAME}
            css={[styles.label, errors?.firstName && styles.labelDanger]}
          >
            First name
          </label>
          <input
            id={FIRSTNAME}
            name={FIRSTNAME}
            type="text"
            css={[styles.inputField, errors?.firstName && styles.danger]}
            {...register(FIRSTNAME, contactFormOptions.firstName)}
          />
          <small css={styles.textDanger}>
            {errors?.firstName && errors.firstName.message}
            {errors?.firstName && errors.firstName.type === 'maxLength' && (
              <div className="error">Only 255 characters are allowed</div>
            )}
          </small>
        </div>
        <div css={styles.formGroup}>
          <label
            htmlFor={LASTNAME}
            css={[styles.label, errors?.lastName && styles.labelDanger]}
          >
            Last name
          </label>
          <input
            id={LASTNAME}
            name={LASTNAME}
            type="text"
            css={[styles.inputField, errors?.lastName && styles.danger]}
            {...register(LASTNAME, contactFormOptions.lastName)}
          />
          <small css={styles.textDanger}>
            {errors?.lastName && errors.lastName.message}
            {errors?.lastName && errors.lastName.type === 'maxLength' && (
              <div className="error">Only 255 characters are allowed</div>
            )}
          </small>
        </div>
        <div css={styles.formGroup}>
          <label
            htmlFor={EMAIL}
            css={[styles.label, errors?.email && styles.labelDanger]}
          >
            Email
          </label>
          <input
            id={EMAIL}
            type={EMAIL}
            name={EMAIL}
            {...register(EMAIL, contactFormOptions.email)}
            css={[styles.inputField, errors?.email && styles.danger]}
          />
          <small css={styles.textDanger}>
            {errors?.email && errors.email.message}
            {errors?.email && errors.email.type === 'maxLength' && (
              <div className="error">Only 255 characters are allowed</div>
            )}
          </small>
        </div>
        <div css={styles.formGroup}>
          <label htmlFor={QUERYISABOUT} css={styles.label}>
            Your query is about
          </label>
          <CustomSelectDropDown
            id={QUERYISABOUT}
            name={QUERYISABOUT}
            optionsList={
              JSON.parse(sessionStorage.getItem('ISVOLUNTEER')) === true
                ? QUERYISABOUT_OPTIONS_VOLUNTEER
                : QUERYISABOUT_OPTIONS
            }
            {...register(QUERYISABOUT, contactFormOptions.queryIsAbout)}
            selectedOptionValue={selectQueryOption}
            ref={null}
            dropDownData={selectedDropDownOption}
          />
        </div>
        <div css={[styles.formGroup, styles.ageGroup]}>
          <label
            htmlFor={AGE_QUESTION}
            css={[styles.label, errors?.age && ageError && styles.labelDanger]}
          >
            Age of {ageText}
          </label>
          <input
            id={AGE_QUESTION}
            name={AGE_QUESTION}
            css={[styles.inputField, errors?.age && ageError && styles.danger]}
            type="text"
            onKeyPress={event => {
              if (!/[0-9]/.test(event.key)) {
                event.preventDefault();
              }
            }}
            {...register(
              AGE_QUESTION,
              queryData === 'Volunteering'
                ? contactFormOptions.volunteerAge
                : contactFormOptions.age
            )}
            minLength="1"
            maxLength="2"
          ></input>
          <small css={styles.textDanger}>
            {errors?.age && !getValues(AGE_QUESTION) && errors.age.message}
            {errors?.age &&
            errors.age.type === 'min' &&
            ((queryData === 'Volunteering' &&
              errors?.age?.ref?.value < contactFormOptions?.volunteerAge.min) ||
              (queryData !== 'Volunteering' &&
                errors?.age?.ref?.value < contactFormOptions?.age?.min)) ? (
              <div className="error">
                {queryData === 'Volunteering'
                  ? 'Please enter age 14+ years old'
                  : 'Please enter age within 5-99'}
              </div>
            ) : (
              ''
            )}
          </small>
        </div>
        <div css={styles.formGroup}>
          <label
            htmlFor={DESCRIPTION}
            css={[styles.label, errors?.description && styles.labelDanger]}
          >
            Your message
          </label>
          <Text css={styles.notes}>{messageText}</Text>
          <textarea
            id={DESCRIPTION}
            name={DESCRIPTION}
            rows="3"
            cols="50"
            {...register(DESCRIPTION, contactFormOptions.description)}
            css={[styles.inputField, errors?.description && styles.danger]}
          ></textarea>
          <small css={styles.textDanger}>
            {errors?.description && errors.description.message}
            {errors?.description && errors.description.type === 'maxLength' && (
              <div className="error">Only 500 characters are allowed</div>
            )}
          </small>
        </div>
        <div>
          <CheckboxAnswer
            css={styles.checkBoxText}
            isChecked={isAbove16State}
            value={isAbove16State}
            name={CONFIRMAGE}
            id={CONFIRMAGE}
            onChange={() => setIsAbove16State(!isAbove16State)}
            tabIndex={-1}
          >
            I confirm I'm aged 16 or over and/or I am enquiring on behalf of my
            child
          </CheckboxAnswer>
        </div>
        <div css={styles.notesSafeguard}>
          <Text as="h6">Please note safeguards for all</Text>
          <ul>
            <li>
              Meetings should always take place at a venue the club regularly
              uses, during the relevant training hours and when other members
              are present
            </li>
            <li>
              16/17-year-olds must inform their parent/carer of their intention
              to meet with a club
            </li>
            <li>Under-16s must be accompanied by their parent/carer</li>
            <li>
              {queryData === 'Volunteering'
                ? 'Initial meetings between club officials and prospective volunteers should include a minimum of 2 club officials'
                : 'Meetings should never be held at an individuals home'}
            </li>
            <li>
              {queryData === 'Volunteering'
                ? 'Under-18s must never be asked to meet a club official alone.'
                : 'Under-18s should never be asked to meet a club official alone'}
            </li>
          </ul>
          <div css={styles.confirmCheck}>
            <CheckboxAnswer
              css={styles.checkBoxText}
              isChecked={isConfirmSafeGuard}
              value={isConfirmSafeGuard}
              name={CONFIRMSAFEGUARD}
              id={CONFIRMSAFEGUARD}
              onChange={() => setIsConfirmSafeGuard(!isConfirmSafeGuard)}
              tabIndex={-1}
            >
              I confirm I have read and understand the safeguarding requirements
              above
            </CheckboxAnswer>
          </div>
          <div>
            {isAlreadyFormSent && (
              <ReCAPTCHA
                sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
                onChange={onRecaptchaChange}
                id={GOOGLECAPTCHA}
              />
            )}
          </div>
        </div>
        <div css={styles.buttonWrapper}>
          <button
            type="submit"
            id={SUBMIT_BTN}
            css={[styles.btn, styles.submitBtn]}
            disabled={
              !isValid ||
              !isConfirmSafeGuard ||
              !isAbove16State ||
              (isAlreadyFormSent && reCaptchaValue === null)
            }
          >
            Submit
          </button>
          <button
            id={CANCEL}
            css={[styles.btn, styles.cancelBtn]}
            onClick={() => {
              onClick(
                prevIsQueryAbout !== getValues(QUERYISABOUT)
                  ? getValues(QUERYISABOUT)
                  : EMPTY_STRING
              );
            }}
          >
            Cancel
          </button>
        </div>
      </form>
    </Fragment>
  );
};

ContactForm.prototype = {
  handleContact: PropTypes.func,
  onClick: PropTypes.func,
  isAlreadyFormSent: PropTypes.bool,
  isDesktop: PropTypes.bool,
};

export default ContactForm;
