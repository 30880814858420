import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const {
    breakpoints,
    utility,
    colors,
    spacing,
    borderRadius,
    heights,
    screenSizes,
  } = useTheme();

  return {
    toggle: css`
      height: ${heights.filterNav};
      box-shadow: 0px ${borderRadius.s} 3px rgba(1, 30, 65, 0.14);

      ${breakpoints.l} {
        max-width: ${screenSizes.xl};
        box-shadow: none;
        margin: 0 auto;
        width: 90vw;
      }
    `,
    inner: css`
      height: 100%;
      background-color: ${colors.white};
      display: grid;
      grid-auto-flow: column;

      ${breakpoints.m} {
        display: inline-block;
        background-color: transparent;
      }
    `,
    toggleBtn: css`
      ${utility.buttonDefault};
      display: flex;
      align-items: center;
      color: ${colors.navy};
      font-size: 0.875rem;
      font-weight: 500;
      letter-spacing: 0.01em;
      background-color: ${colors.white};
      padding: ${spacing.s};
      text-transform: uppercase;
      height: 100%;
      outline: 0;
      position: relative;

      &:focus {
        &::before {
          content: '';
          position: absolute;
          top: 75%;
          left: 2;
          width: 75%;
          height: 0.15rem;
        }
      }

      svg {
        color: ${colors.navy};
        transition: all 0.3s ease-in;

        ${breakpoints.m} {
          color: ${colors.red};
        }
      }

      span {
        margin-left: ${spacing.xs};
      }

      &:hover {
        svg {
          color: ${colors.navy};
        }
      }

      ${breakpoints.m} {
        font-size: 1.125rem;
        border-radius: 0 0 ${borderRadius.m} ${borderRadius.m};
        box-shadow: 0px ${borderRadius.s} 3px rgba(1, 30, 65, 0.14);
      }
    `,
    Button: css`
      position: relative;
      text-decoration: underline;
      background-color: ${colors.red};

      &::before {
        content: '';
        position: absolute;
        top: 150%;
        left: 0;
        width: 100%;
        height: 0.6rem;
        background-color: rgb(61, 61, 61);
      }
    `,
    counter: css`
      display: none;

      ${breakpoints.m} {
        display: block;
        font-size: 0.875rem;
        line-height: 1.125rem;
        font-weight: 700;
        width: 18px;
        height: 18px;
        border-radius: 18px;
        background-color: ${colors.red};
        color: ${colors.white};
      }
    `,
    valuesWrapper: css`
      display: grid;
      grid-auto-flow: column;
      grid-gap: 5px;
      width: calc(100% - 12px);
      overflow: scroll;
      align-items: center;
      padding-left: ${borderRadius.s};

      ${breakpoints.m} {
        display: none;
      }
    `,
    value: css`
      border: 1px solid ${colors.blue};
      text-transform: none;
      padding: ${spacing.xs};
      white-space: nowrap;

      &:first-of-type {
        margin-left: 0;
      }
    `,
  };
};
