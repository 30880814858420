import {
  ADULT_CLUB_CANCEL_URL,
  ADULT_FUTSAL_URL,
  AGE_UK_CANCEL_URL,
  LET_GIRLS_PLAY_CANCEL_URL,
  SQUAD_CANCEL_URL,
  YOUTH_CLUB_CANCEL_URL,
} from '../../const';
import publicPath from '../../utils/publicPath';

const moRecommendations = {
  hero: {
    1: {
      text: {
        main: 'Casual football recommendations',
      },
      images: {
        desktop: {
          src: publicPath('/images/desktop/recommendations/casual-hero@x1.jpg'),
        },
        mobile: {
          src: publicPath('/images/mobile/recommendations/casual-hero@x1.jpg'),
        },
      },
    },
    2: {
      text: {
        main: 'Club football recommendations',
      },
      images: {
        desktop: {
          src: publicPath('/images/desktop/recommendations/club-hero@x1.jpg'),
        },
        mobile: {
          src: publicPath('/images/mobile/recommendations/club-hero@x1.jpg'),
        },
      },
    },
    3: {
      text: {
        main: 'Football recommendations',
      },
      images: {
        desktop: {
          src: publicPath('/images/desktop/recommendations/mixed-hero@x2.jpg'),
        },
        mobile: {
          src: publicPath('/images/desktop/recommendations/mixed-hero@x2.jpg'),
        },
      },
    },
  },
  faInitiatives: [
    {
      id: 1,
      description: 'Fun and friendly football for girls aged 5-11. ',
      href:
        'https://www.englandfootball.com/play/womens-and-girls-football/weetabix-wildcats',
      initiativeLogo: {
        desktop: {
          src: publicPath('/images/desktop/home/wildcats-logo.svg'),
        },
        mobile: {
          src: publicPath('/images/desktop/home/wildcats-logo.svg'),
        },
      },
      images: {
        desktop: {
          src: publicPath('/images/desktop/venue/fa-events/wildcats.png'),
          width: 754,
        },
        mobile: {
          src: publicPath('/images/desktop/venue/fa-events/wildcats.png'),
          width: 754,
        },
      },
    },
    {
      id: 2,
      description:
        'Casual football for everyone. Fancy a game? Just say, Just Play. ',
      href: 'https://www.englandfootball.com/play/adult-football/just-play',
      initiativeLogo: {
        desktop: {
          src: publicPath('/images/desktop/home/just-play-logo.svg'),
        },
        mobile: {
          src: publicPath('/images/desktop/home/just-play-logo.svg'),
        },
      },
      images: {
        desktop: {
          src: publicPath('/images/desktop/venue/fa-events/just-play.png'),
          width: 754,
        },
        mobile: {
          src: publicPath('/images/desktop/venue/fa-events/just-play.jpg'),
          width: 754,
        },
      },
    },
    {
      id: 3,
      description:
        'Stay active with small-sided Walking Football for over 50s.',
      href: AGE_UK_CANCEL_URL,
      initiativeLogo: {
        desktop: {
          src: publicPath('/images/desktop/home/age-uk-logo.svg'),
        },
        mobile: {
          src: publicPath('/images/desktop/home/age-uk-logo.svg'),
        },
      },
      images: {
        desktop: {
          src: publicPath('/images/desktop/home/age-uk.png'),
          width: 752,
        },
        mobile: {
          src: publicPath('/images/desktop/home/age-uk.png'),
          width: 752,
        },
      },
    },
    {
      id: 4,
      description:
        "Squad Girls' Football. Fun, non-competitive football for Girls aged 12-14.",
      href: SQUAD_CANCEL_URL,
      images: {
        desktop: {
          src: publicPath('/images/desktop/home/squad.png'),
          width: 752,
        },
        mobile: {
          src: publicPath('/images/desktop/home/squad.png'),
          width: 752,
        },
      },
    },
    {
      id: 5,
      description:
        'Let Girls Play. Find casual sessions for girls and women for all ages.',
      href: LET_GIRLS_PLAY_CANCEL_URL,
      images: {
        desktop: {
          src: publicPath('/images/desktop/home/letgirlsplay.png'),
          width: 752,
        },
        mobile: {
          src: publicPath('/images/desktop/home/letgirlsplay.png'),
          width: 752,
        },
      },
    },
    {
      id: 6,
      description:
        'Youth club football.Paving the way for the next generation.',
      href: YOUTH_CLUB_CANCEL_URL,
      header: 'All youth club',
      images: {
        desktop: {
          src: publicPath('/images/desktop/home/youth-club-football.png'),
          width: 752,
        },
        mobile: {
          src: publicPath('/images/desktop/home/youth-club-football.png'),
          width: 752,
        },
      },
    },
    {
      id: 7,
      description: "11-a-side football, the game we love in it's purist form.",
      href: ADULT_CLUB_CANCEL_URL,
      header: 'Adult 11v11',
      images: {
        desktop: {
          src: publicPath('/images/desktop/home/adult-club.png'),
          width: 752,
        },
        mobile: {
          src: publicPath('/images/desktop/home/adult-club.png'),
          width: 752,
        },
      },
    },
    {
      id: 8,
      description: 'Get involved with Futsal to improve your technique ',
      href: ADULT_FUTSAL_URL,
      header: 'Adult Futsal',
      images: {
        desktop: {
          src: publicPath('/images/desktop/home/adult-futsal.png'),
          width: 752,
        },
        mobile: {
          src: publicPath('/images/desktop/home/adult-futsal.png'),
          width: 752,
        },
      },
    },
    {
      id: 9,
      description:
        'Discover Walking Football and play the game you love for longer.',
      // href: WALKING_FOOTBALL_CASUAL_CANCEL_URL,
      header: 'Walking Football Sessions',
      images: {
        desktop: {
          src: publicPath('/images/desktop/home/age-uk.png'),
          width: 752,
        },
        mobile: {
          src: publicPath('/images/desktop/home/age-uk.png'),
          width: 752,
        },
      },
    },
    {
      id: 9,
      description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
      // href: WALKING_FOOTBALL_CLUB_CANCEL_URL,
      header: 'Walking Football',
      images: {
        desktop: {
          src: publicPath('/images/desktop/home/adult-futsal.png'),
          width: 752,
        },
        mobile: {
          src: publicPath('/images/desktop/home/adult-futsal.png'),
          width: 752,
        },
      },
    },
  ],
};

export default moRecommendations;
