import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, colors, spacing } = useTheme();

  return {
    b2cNavContainer: css`
      text-align: right;
    `,
    signInDesktop: css`
      padding: 0;
      background-color: transparent;
      text-decoration: underline;
      color: ${colors.darkGrey};
      font-size: ${spacing.s};
      padding-right: 4px;

      &:not(:disabled):hover {
        background-color: transparent;
      }
    `,
    signIn: isDesktop => css`
      font-size: ${spacing.s};
      line-height: 1.5rem;
      display: grid;
      color: ${colors.darkGrey};
      float: ${isDesktop ? 'right' : 'left'};
      padding: 8px 0;
      font-weight normal;
      cursor: pointer;
    
    ${
      !isDesktop &&
      css`
        position: absolute;
        top: 0;
        height: 70px;
        line-height: 70px;
        align-items: center;
        right: 1.5rem;
      `
    }

    a {
      display: inline-block;
      padding-left: 5px;
      color: ${colors.darkGrey};
      font-weight: normal;
      padding-right: 10px;

      &:hover {
        color: ${colors.navy};
      }
    }

    button {
      &:hover {
        color: ${colors.navy};
      }
    }

    &::after {
      content: '';
      background: url('/images/common/profile.svg') no-repeat;
      width: 24px;
      height: 24px;
      display: inline-block;
      position: relative;
      top: 0;
      right: 0;
    }

    ${breakpoints.l} {
      grid-auto-flow: column;
    }
  `,
    siteMyAccount: isDesktop => css`
      font-size: ${spacing.s};
      color: ${colors.dodgerBlue};
      font-weight: 500;
      text-align: right;
      display: inline-block;
      background: transparent;
      &:focus,
      &:hover,
      &:not(:disabled):hover {
        background: transparent;
      }

      span {
        cursor: pointer;
      }

      ${!isDesktop &&
      css`
        text-align: left;
        display: inline-block;
        position: absolute;
        width: 80px;
        height: 100px;
        top: -14px;
        left: 0;
      `}
    `,
    myAccount: css`
      color: ${colors.white};
      font-size: ${spacing.s};
      background-color: ${colors.dodgerBlue};
      border-radius: 7rem;
      width: 34px;
      height: 34px;
      display: inline-block;
      vertical-align: middle;
      text-align: center;
      line-height: 2.125rem;
      cursor: pointer;

      ${breakpoints.l} {
        margin-left: 10px;
      }
    `,
    authenticatedTemplate: isDesktop => css`
      ${isDesktop &&
      css`
        position: relative;
      `}
    `,
    displayNone: css`
      display: none;
    `,
  };
};
