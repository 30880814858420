import { css } from '@emotion/react';

import { EMPTY_STRING } from '../../const';
import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, spacing } = useTheme();

  return {
    base: (isDesktop, isLoading) => css`
      height: 100%;
      display: grid;
      grid-template-rows: auto 1fr;
      grid-template-columns: 100%;

      ${!isDesktop &&
      css`
        animation-name: ${isLoading ? 'slide' : EMPTY_STRING};
        animation-duration: 1s;
        animation-timing-function: ease-out;
        animation-iteration-count: 1;
        animation-fill-mode: forwards;

        @keyframes slide {
          0% {
            transform: translateX(100%);
            opacity: 0;
          }
          100% {
            transform: translateX(0%);
            opacity: 1;
          }
        }
      `}
    `,
    wrapper: (titleHeight, hasPreferences) => css`
      margin-top: calc(100vh - 228px);
      padding: ${spacing.s} 0;
      width: 100%;

      ${breakpoints.m} {
        margin-top: calc(100vh - 77px);
        padding: 0;
      }

      ${breakpoints.l} {
        margin: calc(100vh - 80px) auto auto;
        width: 90vw;
        max-width: 1196px;
      }
    `,
    displayNone: css`
      display: none;
    `,
  };
};
