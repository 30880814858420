/** @jsxImportSource @emotion/react */
import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import { recommendationsPageAnalytics } from '../../analyticsConsts';
import {
  BOYS_OR_MEN,
  BOYS_OR_MEN_MIXED,
  GIRLS_OR_WOMEN_MIXED,
  PLAY_WITH_ID,
} from '../../const';
import { usePreferencesContext } from '../../context/preferences';
import { useRecommendations } from '../../context/recommendations';
import useErrorBoundary from '../../hooks/useErrorBoundary';
import { getRecommendations } from '../../services';
import publicPath from '../../utils/publicPath';
import Button from '../Button';
import Image from '../Image';
import Loader from '../Loader';
import Text from '../Text';
import useStyles from './styles';

const {
  RECOMMENDED_FOOTBALL_TYPE_CTA_MIXED_RESULTS,
  RECOMMENDED_FOOTBALL_TYPE_MIXED_HEADING,
  RECOMMENDED_FOOTBALL_MIXED_IMAGE,
  RECOMMENDED_FOOTBALL_TYPE_MIXED_CONTAINER,
  RECOMMENDED_FOOTBALL_TYPE_MIXED_WRAPPER,
} = recommendationsPageAnalytics;

const DELAY = 500;

const MixedSignPost = () => {
  const styles = useStyles();
  const [isLoading, setIsLoading] = useState(false);
  const loadingRef = useRef();
  const throwError = useErrorBoundary();
  const { preferences, setPreferences } = usePreferencesContext();
  const { SelectedFootballType: selectedFootballType, PlayWith: playWith } =
    preferences || {};
  const { setRecommendations } = useRecommendations();
  const isMounted = useRef(true);

  useEffect(() => {
    return () => {
      // ComponentWillUnmount to avoid memory leak issue
      isMounted.current = false;
    };
  }, []);

  const getMixedRecommendations = () => {
    setIsLoading(true);
    const playWithID =
      playWith === BOYS_OR_MEN ? BOYS_OR_MEN_MIXED : GIRLS_OR_WOMEN_MIXED;

    const updatedPreference = {
      ...preferences,
      [PLAY_WITH_ID]: playWithID,
    };

    Promise.all([
      getRecommendations(selectedFootballType, updatedPreference),
    ])
      .then(data => {
        if (isMounted.current && data) {
          const recommendationRecords = data[0].data; // recommendation data
          setRecommendations(recommendationRecords, selectedFootballType);

          // when we are sure the preferences have been set
          setPreferences(updatedPreference);

          setTimeout(() => {
            // to scroll top of the page
            window.scrollTo(0, 0);
          }, DELAY);

          setIsLoading(false);
        }
      })
      .catch(throwError);
  };

  useLayoutEffect(() => {
    if (isLoading) {
      loadingRef.current?.focus();
    }
  }, [isLoading]);

  return (
    <div
      css={styles.signPostWrapper}
      id={RECOMMENDED_FOOTBALL_TYPE_MIXED_WRAPPER}
    >
      {!isLoading ? (
        <div
          css={styles.signPostContainer}
          id={RECOMMENDED_FOOTBALL_TYPE_MIXED_CONTAINER}
        >
          <div css={styles.textWrapper}>
            <div css={styles.innerTextWrapper}>
              <Text
                as="h2"
                css={styles.mainText}
                id={RECOMMENDED_FOOTBALL_TYPE_MIXED_HEADING}
              >
                Try mixed football
              </Text>
              {playWith === BOYS_OR_MEN ? (
                <Text css={styles.description}>
                  Many Casual sessions are run for a mix of genders. Find more
                  social games to try out by including mixed football options in
                  your search.
                </Text>
              ) : (
                <Text css={styles.description}>
                  Casual sessions are fun and friendly, with many ran for a mix
                  of genders. Expand your search results by including mixed
                  football options.
                </Text>
              )}
              <div css={styles.bottom}>
                <Button
                  id={RECOMMENDED_FOOTBALL_TYPE_CTA_MIXED_RESULTS}
                  css={styles.cta}
                  onClick={() => getMixedRecommendations()}
                >
                  Include mixed football results
                </Button>
              </div>
            </div>
          </div>
          <div css={styles.imgWrapper}>
            <Image
              id={RECOMMENDED_FOOTBALL_MIXED_IMAGE}
              css={styles.image}
              src={publicPath(`/images/common/signpost.jpg`)}
              alt="Include mixed football results"
            />
          </div>
        </div>
      ) : (
        <Loader message="Loading mixed football results..." ref={loadingRef} />
      )}
    </div>
  );
};

export default MixedSignPost;
