import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, colors, spacing, borderRadius } = useTheme();

  return {
    sessionCardWrapper: css`
      border: ${borderRadius.s} solid transparent;
      &:focus-visible {
        border: ${borderRadius.s} solid ${colors.navy};
      }
    `,
    base: (availableSession, isMiniOnboarding) => css`
      border: 1px solid
        ${availableSession
          ? colors.red
          : isMiniOnboarding
          ? colors.darkGrey
          : colors.lightGrey};
      padding: ${spacing.xs} 1.5rem ${spacing.s};
      border-radius: ${borderRadius.l};
      position: relative;
      background-color: ${colors.white};
      > div {
        padding-bottom: ${borderRadius.s};
        min-height: 47px;
        align-items: center;
      }

      &:focus {
        border: 1px solid ${colors.navy};
      }
    `,
    title: css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${colors.navy};
      text-decoration: underline;
      text-decoration-color: ${colors.red};
      text-transform: capitalize;
    `,
    image: css`
      min-height: 35px;
      max-height: 45px;
    `,
    titleWrapper: css`
      position: relative;
      display: grid;
      grid-template-columns: 2fr 1fr;
    `,
    infoWrapper: css`
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 2fr 1fr;
      grid-gap: ${spacing.xs};

      > label {
        color: ${colors.darkGrey};
        font-size: ${borderRadius.xl};
        line-height: 10px;
        &:not(:last-child) {
          border-right: 1px solid ${colors.lightGrey};
          padding-right: ${spacing.s};
        }
        p {
          color: ${colors.navy};
          white-space: nowrap;
        }
      }
    `,
    priceInfo: css`
      padding-left: 10px;
    `,
    ageGroupWrapper: css`
      grid-template-columns: repeat(3, 1fr);
      grid-gap: ${spacing.s};
    `,
    linkWrapper: css`
      padding-top: ${borderRadius.s};
      a {
        text-decoration: none;
        font-weight: normal;
        text-transform: uppercase;

        &:focus {
          outline: none;
          color: ${colors.navy};

          svg {
            color: ${colors.navy};
          }
        }
      }
    `,
    logoWrapper: css`
      align-self: center;
      justify-self: flex-end;
    `,
    externalLink: css`
      vertical-align: middle;
      color: ${colors.red};
    `,
    sessionBookedWrapper: (SpacesAvailable, isMiniOnboarding) => css`
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.125rem;
      margin-top: -10px;
      position: relative;
      background-color: ${SpacesAvailable ? colors.lightGrey : colors.red};
      color: ${SpacesAvailable ? colors.navy : colors.white};
      padding: 4px ${spacing.s} 4px ${spacing.s};
      border-radius: 0 0 ${borderRadius.l} ${borderRadius.l};
      letter-spacing: 0.02em;
      border: 1px solid ${isMiniOnboarding ? colors.darkGrey : 'transparent'};
      border-top: none;
    `,
    sessionText: isFullyBooked => css`
      display: grid;
      text-align: center;
      font-size: 0.75rem;
      text-transform: ${isFullyBooked && 'uppercase'};

      ${breakpoints.l} {
        font-size: 0.75rem;
      }
    `,
    fullBooking: css`
      background-color: ${colors.blue};
      color: ${colors.white};
      text-align: center;
      padding: ${borderRadius.m} 16px 0 16px;
      height: 36px;
      line-height: 18px;
      border-radius: ${borderRadius.xs};
      font-size: 0.875rem;
      letter-spacing: 0.02em;
      border-radius: ${borderRadius.s};

      svg {
        color: ${colors.white};
        font-size: 0.875rem;
      }

      &:hover,
      &:focus {
        background-color: ${colors.lightBlue};
        text-decoration: none;
        color: ${colors.navy};
        svg {
          color: ${colors.navy};
        }
      }
    `,
    dateFormat: css`
      color: ${colors.navy};
      border-bottom: 1px solid ${colors.greyShade1};
      margin: ${spacing.s} 0;
      display: grid;
      grid-template-columns: 30px auto;
      align-items: center;
      padding-bottom: ${spacing.xs};
    `,
  };
};
