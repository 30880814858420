/** @jsxImportSource @emotion/react */
import { useIsAuthenticated, useMsal } from '@azure/msal-react';
import { useCallback, useEffect, useState } from 'react';

import { loginRequest } from '../../authConfig';
import { useAccessTokenContext } from '../../context/accessToken';
import useMediaQuery from '../../hooks/useMediaQuery';
import { revokeSignIn } from '../../services';
import AccountDetails from '../AccountDetails';
import Button from '../Button';
import ExternalLink from '../ExternalLink';
import Link from '../Link';
import useStyles from './styles';

const B2cSignIn = ({ isHamburgerMenu, accountDetailsClick, isB2cMenu }) => {
  const styles = useStyles();
  const [isMouseOver, setIsMouseOver] = useState(false);
  const isAuthenticated = useIsAuthenticated();
  const { instance, inProgress, accounts } = useMsal();
  const [name, setName] = useState(null);
  const isDesktop = useMediaQuery('screen and (min-width: 992px)');
  const { token } = useAccessTokenContext();

  useEffect(() => {
    if (accounts.length > 0) {
      const accountsClaims = accounts[0].idTokenClaims;
      const name =
        accountsClaims &&
        `${accountsClaims.given_name.charAt(
          0
        )}${accountsClaims.family_name.charAt(0)}`;
      setName(name);
    } else {
      setName(null);
    }
  }, [accounts]);

  const handleLogin = useCallback(() => {
    localStorage.setItem('rootUrl', localStorage.getItem('pathInfomation'));
    instance.loginRedirect(loginRequest);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const logOut = useCallback(() => {
    const accountUser = accounts[0]?.idTokenClaims?.sub;
    //revoking before logout
    revokeSignIn(token, accountUser)
      .then(revokeResponse => {
        if (revokeResponse) {
          localStorage.setItem(
            'rootUrl',
            localStorage.getItem('pathInfomation')
          );
        }
      })
      .catch(() => {});
    localStorage.setItem('rootUrl', localStorage.getItem('pathInfomation'));
    instance.logout(loginRequest);
    localStorage.clear();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [instance, accounts, token, inProgress]);

  return (
    <div css={styles.b2cNavContainer}>
      {!isAuthenticated && isDesktop && (
        <div css={[styles.signIn(isDesktop, name), styles.displayNone]}>
          <Button onClick={() => handleLogin()} css={styles.signInDesktop}>
            <span>Sign in</span>
          </Button>{' '}
          <span>or</span>
          <Link to={'/account/signup'}>
            <span>Register Now</span>
          </Link>
        </div>
      )}

      {!isAuthenticated && (
        <ExternalLink
          onClick={() => handleLogin()}
          css={styles.signIn(isDesktop)}
        >
          {/* <span class="mob-only">Sign in/Up</span> */}
        </ExternalLink>
      )}

      {isAuthenticated && !isHamburgerMenu && (
        <div
          css={styles.authenticatedTemplate(isDesktop)}
          onMouseOver={() => setIsMouseOver(true)}
          onMouseLeave={() => isDesktop && setIsMouseOver(false)}
          onFocus={() => setIsMouseOver(true)}
          onBlur={() => isDesktop && setIsMouseOver(false)}
        >
          <Button
            css={styles.siteMyAccount(isDesktop)}
            aria-controls="myaccount-nav"
            aria-haspopup={isAuthenticated}
            aria-expanded="false"
            data-username={name}
            onClick={accountDetailsClick}
          >
            {isDesktop && <span>My Account</span>}{' '}
            <span css={styles.myAccount}>{name}</span>
          </Button>
          {isMouseOver && isB2cMenu && (
            <AccountDetails
              logOut={() => logOut()}
              isDesktop={isDesktop}
            ></AccountDetails>
          )}
        </div>
      )}
    </div>
  );
};

export default B2cSignIn;
