import isempty from 'lodash.isempty';

import {
  CarIcon,
  Disability,
  FaInitiative,
  FootballTypes,
  Person,
  Pitch,
} from '../../components/Icons';
import {
  DISABILITY_FACILITIES_MAPPING,
  DISABILITY_TYPES_MAPPING,
  EMAIL,
  EMPTY_STRING,
  PHONE_NUMBER,
  WEBSITE,
} from '../../const';
import formatAddress from '../../utils/formatAddress';

const normaliseProviderDetails = apiData => {
  const {
    CentreName,
    ProviderId,
    ProviderName,
    DisableFacility,
    DistanceFromUserLocation,
    PitchFinderId,
    PitchTypes,
    Facility,
    SessionInfo,
    ProviderInfo,
    EmailAddress,
    OrganiserEmail,
    Website,
    TelephoneNumber,
    SessionTab,
    Latitude,
    Longitude,
    OrganiserFirstName,
    OrganiserLastName,
    MaxAge,
    MinAge,
    PlaysOnDay,
    Price,
    Category,
    ...rest
  } = apiData;

  const pitchCategory = PitchTypes?.split('|');
  const genderAge = ProviderInfo.Gender.map(gender => `${gender} Football `);
  genderAge.push(`Age ${MinAge}-${MaxAge}`);
  const isDisableFacilityAvailable =
    DisableFacility &&
    Object.keys(DisableFacility).every(value => !DisableFacility[value]);
  const isFacilityAvailable =
    Facility && Object.keys(Facility).every(value => !Facility[value]);

  const PlaysOnDayPrice = PlaysOnDay.map(day => {
    const days = day.charAt(0).toUpperCase();
    return `${days.concat(day.slice(1))}`;
  });
  PlaysOnDayPrice.push(`£${Price} per session`);

  return {
    title: ProviderName,
    centername: CentreName,
    address: formatAddress(rest),
    contactAddress: formatAddress(rest, '\n'),
    distance: DistanceFromUserLocation,
    latitude: Latitude,
    longitude: Longitude,
    facilities:
      isFacilityAvailable && isDisableFacilityAvailable && isempty(PitchTypes)
        ? EMPTY_STRING
        : {
            title: 'Venue Facilities',
            values: [
              {
                title: 'Facilities',
                icon: CarIcon,
                rows: [
                  ...(Facility.CarPark && Facility.CarParkCapacity > 0
                    ? [`${Facility.CarParkCapacity} Parking Spaces`]
                    : []),
                  ...(Facility.ChangingRooms ? ['Changing Rooms'] : []),
                  ...(Facility.Floodlit ? ['Floodlights'] : []),
                ],
              },
              ...(PitchTypes
                ? [
                    {
                      title: 'Pitch types',
                      icon: Pitch,
                      rows: pitchCategory,
                    },
                  ]
                : []),
              {
                title: 'Disability Facilities',
                icon: Disability,
                rows: Object.keys(DisableFacility)
                  .map(
                    facility =>
                      DisableFacility[facility] &&
                      DISABILITY_FACILITIES_MAPPING[facility]
                  )
                  .filter(Boolean),
              },
            ],
          },

    footballtypes: !isempty(
      ProviderInfo.FootballType &&
        ProviderInfo.DisabilityType &&
        ProviderInfo.Gender
    )
      ? {
          title: 'Football types played here',
          values: [
            {
              title: 'Sessions football types',
              icon: FootballTypes,
              rows: ProviderInfo.FootballType,
            },
            {
              title: 'Sessions disability types',
              icon: FaInitiative,
              rows: ProviderInfo.DisabilityType.map(
                type => DISABILITY_TYPES_MAPPING[type]
              ).filter(Boolean),
            },
            {
              title: 'Sessions gender groups',
              icon: Person,
              rows: ProviderInfo.Gender.map(gender => `${gender} Football`),
            },
          ],
        }
      : EMPTY_STRING,
    providerfootballtypes: !isempty(
      ProviderInfo.FootballType &&
        ProviderInfo.DisabilityType &&
        ProviderInfo.Gender
    )
      ? {
          title: 'Football types played here',
          values: [
            {
              title: 'Sessions gender groups',
              icon: Person,
              rows: genderAge,
            },
            {
              title: 'Sessions football types',
              icon: FootballTypes,
              rows: ProviderInfo.FootballType,
            },
            {
              title: 'Sessions football types',
              icon: FaInitiative,
              rows: PlaysOnDayPrice,
            },
            ...(ProviderInfo?.DisabilityType.length > 1 ||
            ProviderInfo?.DisabilityType[0] !== 'Non Disability'
              ? [
                  {
                    title: 'Sessions disability types',
                    icon: FaInitiative,
                    rows: 'Inclusive Sessions',
                  },
                ]
              : []),
          ],
        }
      : EMPTY_STRING,
    events: ProviderInfo?.FaInitiative,
    providercontact: {
      title: 'Reception',
      details: {
        ...(OrganiserEmail ? { [EMAIL]: OrganiserEmail } : {}),
      },
    },
    contact: {
      title: 'Reception',
      details: {
        ...(TelephoneNumber ? { [PHONE_NUMBER]: TelephoneNumber } : {}),
        ...(EmailAddress ? { [EMAIL]: EmailAddress } : {}),
        ...(Website ? { [WEBSITE]: Website } : {}),
      },
    },
    organisername: OrganiserFirstName + ' ' + OrganiserLastName,
    providerid: ProviderId,
    sessionsCount: SessionInfo?.SessionCount,
    venueTabs: SessionTab?.map((session, index) => ({
      id: index,
      label: `${session.Month} (${session.CntSessionMonth})`,
    })),
    category: Category,
    sessions: Category,
  };
};

export default normaliseProviderDetails;
