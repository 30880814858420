import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, colors, spacing, typography, borderRadius } = useTheme();

  return {
    base: css`
      position: relative;
      display: grid;
      width: 195px;
      height: 170px;
      justify-items: center;
      align-items: center;
      text-align: center;
      border-radius: 8px;
      border: 1px solid ${colors.greyShade1};
      box-shadow: 0px 4px 6px rgba(91, 104, 133, 0.1);
      color: ${colors.navy};
      font-weight: 700;
      background-color: ${colors.white};
      text-decoration: none;
      transition: all 0.2s ease-out;
      outline: 0;

      &:hover {
        border-bottom: ${borderRadius.s} solid ${colors.red};
        background-color: ${colors.lightBlue};
        transform: translateY(-10px);
      }

      ${breakpoints.xl} {
        padding: ${spacing.s} 0;
        height: 235px;

        &:focus {
          outline: 0;
          border: ${borderRadius.s} solid ${colors.navy};
          transform: translateY(-10px);
        }
      }
    `,
    titleWrapper: css`
      display: flex;
      position: relative;
      margin: 0 ${spacing.s};

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -10px;
        width: 100%;
        border-bottom: 1px solid ${colors.red};
      }

      ${breakpoints.xl} {
        display: block;

        &::after {
          content: '';
          position: absolute;
          left: 50%;
          bottom: -10px;
          width: 66px;
          margin-left: -33px;
        }
      }
    `,
    title: isCenter => css`
      font-family: 'FS Dillon Bold';
      font-weight: bold;
      line-height: 1;
      width: 90%;
      margin: auto;
      ${isCenter &&
      css`
        text-transform: lowercase;

        &::first-letter {
          text-transform: uppercase;
        }
      `}
      ${breakpoints.l} {
        font-family: 'FS Dillon Bold';
        font-weight: bold;
        line-height: 1.1;
      }
    `,
    crest: css`
      margin: auto;
      width: 46px;
    `,
    crestDefault: css`
      margin: auto;
      width: 46px;
      color: ${colors.lightGrey};
    `,
    info: css`
      ${typography.body.mobile};
    `,
    teams: isCenter => css`
      ${isCenter &&
      css`
        padding: 0 8px;
      `}
    `,
    distance: isCenter => css`
      ${isCenter &&
      css`
        margin-top: 0.5rem;
      `}
      margin-top: ${spacing.xs};
      color: ${colors.darkGrey};

      svg {
        margin-right: ${spacing.xs};
      }
    `,
  };
};
