import { useEffect, useState } from 'react';

import { getPreferenceValue } from '../../components/ClubDetailsPage';
import { SELECTED_RECOMMENDATIONS_TYPE_ID } from '../../const';
import { useOnboardingDataContext } from '../../context/OnboardingData';
import { usePageInfo } from '../../context/pageInfo';
import { usePreferencesContext } from '../../context/preferences';
import { useSetContactFormDLContext } from '../../context/setContactFormDL';
import { useSetSelectedClubDetailsContext } from '../../context/setSelectedClubDetails';
import {
  getClubPageData,
  getMatchDetails,
  getSocialMediaData,
  getTeamDetails,
} from '../../services';
import normaliseTeamDetails from '../../utils/normaliseTeamDetails';
import useClubVenueCoordination from '../useClubVenueCoordination';
import useErrorBoundary from '../useErrorBoundary';

export default () => {
  const [teamPageData, setTeamPageData] = useState();
  const [isLoadingPageData, setIsLoadingPageData] = useState(true);
  const [socialDataLinks, setSocialDataLinks] = useState();
  const [teamDetails, setTeamDetails] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [pageInfo, setPageInfo] = usePageInfo();
  const { preferences } = usePreferencesContext();
  const { setContactFormDLValue } = useSetContactFormDLContext();
  const { [SELECTED_RECOMMENDATIONS_TYPE_ID]: selectedRecommendationType } =
    preferences || {};
  const { OnboardingContext } = useOnboardingDataContext();

  const { selectedClubDetails } = useSetSelectedClubDetailsContext();
  const { selectedTeamDetails } = selectedClubDetails;
  const wgsClubID =
    selectedClubDetails?.WgsClubId || selectedClubDetails?.accreditationid;
  const sourceLocation = useClubVenueCoordination(
    selectedRecommendationType,
    preferences
  );
  const throwError = useErrorBoundary();

  // set page info only when we have the team name
  useEffect(() => {
    if (!teamDetails?.title) {
      return;
    }

    setPageInfo('team', teamDetails.title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamDetails]);

  // set page info only when we have the team name
  useEffect(() => {
    if (!teamDetails || !pageInfo) {
      return;
    }
    const details = teamDetails;
    setContactFormDLValue(
      getPreferenceValue(
        pageInfo,
        preferences,
        details,
        'team info',
        false,
        OnboardingContext.gender_group
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [teamDetails, pageInfo]);

  useEffect(() => {
    getClubPageData()
      .then(({ data }) => {
        const clubPageDetails = data;
        const randomNumber = Math.round(Math.random() * 5) + 1;
        clubPageDetails.hero.images.desktop.src = `/images/desktop/club/hero/${randomNumber}.jpg`;
        clubPageDetails.hero.images.mobile.src = `/images/mobile/club/hero/${randomNumber}.jpg`;
        setTeamPageData(clubPageDetails);
        setIsLoadingPageData(false);
      })
      .catch(throwError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (!wgsClubID) {
      return;
    }
    getSocialMediaData(wgsClubID)
      .then(({ data }) => {
        if (data) {
          setSocialDataLinks(data);
        }
      })
      .catch(throwError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedClubDetails]);

  useEffect(() => {
    setIsLoading(true);

    // we send the teamId and WgsTeamId
    Promise.all([
      getTeamDetails(selectedTeamDetails?.TeamId, preferences).catch(
        throwError
      ),
      getMatchDetails(selectedTeamDetails?.WgsTeamId).catch(() => {}),
    ]).then(data => {
      if (data) {
        const teamDetailsRecord = data[0]?.data;
        const matchDetailsRecord = data[1]?.data;
        const { ...normalisedDetails } = normaliseTeamDetails({
          ...selectedClubDetails,
          ...teamDetailsRecord,
          ...matchDetailsRecord,
        });
        setTeamDetails(normalisedDetails);
        setIsLoading(false);
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return {
    teamPageData,
    isLoadingPageData,
    socialDataLinks,
    teamDetails,
    isLoading,
    preferences,
    sourceLocation,
    selectedClubDetails,
  };
};
