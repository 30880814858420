import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, typography } = useTheme();

  return {
    text: size => {
      const styles = size ? typography.headings[size] : typography.body;

      return css`
        ${styles.mobile};

        ${breakpoints.l} {
          ${styles.desktop};
        }
      `;
    },
  };
};
