import {
  BOY,
  EMPTY_STRING,
  GIRL,
  MEN,
  MIXED_VALUE,
  ONBOARDING_PREFERENCES,
  WOMEN,
  YOUTH_AGE,
} from '../../const';
import useLocalStorage from '../../hooks/useLocalStorage';

const getAgeTitle = (
  age,
  youth,
  adult = JSON.parse(sessionStorage.getItem('ISVOLUNTEER'))
    ? 'Mixed'
    : MIXED_VALUE
) => {
  const isYouth = parseInt(age, 10) <= YOUTH_AGE;
  return `${isYouth ? youth : adult}${
    youth === MIXED_VALUE || youth === 'Mixed' ? EMPTY_STRING : "s'"
  }`;
};

export default () => {
  const { getLocalItem } = useLocalStorage();
  const preferencesObj = JSON.parse(getLocalItem(ONBOARDING_PREFERENCES)) || {};
  const { Age, PlayWith } = preferencesObj;
  let ageTitle = EMPTY_STRING;

  if (preferencesObj && Age) {
    switch (PlayWith) {
      case 1:
      case 4:
        ageTitle = getAgeTitle(
          Age,
          JSON.parse(sessionStorage.getItem('ISVOLUNTEER')) ? 'Boy' : BOY,
          JSON.parse(sessionStorage.getItem('ISVOLUNTEER')) ? 'Men' : MEN
        );
        break;
      case 2:
      case 5:
        ageTitle = getAgeTitle(
          Age,
          JSON.parse(sessionStorage.getItem('ISVOLUNTEER')) ? 'Girl' : GIRL,
          JSON.parse(sessionStorage.getItem('ISVOLUNTEER')) ? 'Women' : WOMEN
        );
        break;
      default:
        ageTitle = getAgeTitle(
          Age,
          JSON.parse(sessionStorage.getItem('ISVOLUNTEER'))
            ? 'Mixed'
            : MIXED_VALUE
        );
        break;
    }
  }

  return ageTitle;
};
