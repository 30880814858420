import {
  AGE_ID,
  CLUB,
  DISABILITY_OPTION_ID,
  DISABILITY_TYPE,
  DISABILITY_TYPE_ID,
  FOOTBALL_TYPE_ID,
  KICKABOUT_CASUAL,
  LATITUDE_ID,
  LONGITUDE_ID,
  MO_PAGE_SIZE_VALUE,
  OPT_CURRENT_LOCATION_ID,
  PAGE_NUMBER,
  PAGE_NUMBER_VALUE,
  PAGE_SIZE,
  PAGE_SIZE_VALUE,
  PLAY_WITH_ID,
  SEARCH_FOR_ID,
  WEEKDAYS_ID,
} from '../../const';

export default (selectedAge, footballtype) => ({
  [SEARCH_FOR_ID]: '',
  [AGE_ID]: 0,
  [PLAY_WITH_ID]: '',
  // Boys Under 18 don't see the football type question in the onboarding,
  // this logic will assign a football type under the hood in that case
  [FOOTBALL_TYPE_ID]: selectedAge < 18 ? CLUB : KICKABOUT_CASUAL,
  [WEEKDAYS_ID]: '',
  [DISABILITY_OPTION_ID]: '',
  [DISABILITY_TYPE_ID]: [],
  [DISABILITY_TYPE]: '',
  [OPT_CURRENT_LOCATION_ID]: '',
  [LATITUDE_ID]: '',
  [LONGITUDE_ID]: '',
  [PAGE_NUMBER]: PAGE_NUMBER_VALUE,
  [PAGE_SIZE]: footballtype === 1 ? MO_PAGE_SIZE_VALUE : PAGE_SIZE_VALUE,
});
