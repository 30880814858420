import { MYSELF, OPT_CURRENT_LOCATION_ID, SOMEONE_ELSE } from '../../../const';

export default [
  {
    id: '703663f0-3c99-4c4e-ac80-62184ad0546f',
    questionOrder: 8,
    readableId: OPT_CURRENT_LOCATION_ID,
    type: 'location',
    title: {
      [MYSELF]: 'Lets find what’s closest to you!',
      [SOMEONE_ELSE]: 'Lets find what’s closest to them!',
    },
    answers: [
      {
        answerId: 1,
        analyticsId: 'current_location',
        title: 'Use current location',
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: null,
          },
        ],
      },
      {
        answerId: 2,
        analyticsId: 'enter_postcode',
        title: 'Enter postcode',
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: '4651266c-f0eb-479e-a93e-49d7829adefe',
          },
        ],
      },
    ],
  },
];
