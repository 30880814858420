import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, colors, spacing } = useTheme();

  return {
    dateFormat: css`
      color: ${colors.navy};
      border-bottom: 1px solid ${colors.greyShade1};
      margin: ${spacing.xs} 0 ${spacing.s};
      display: grid;
      grid-template-columns: 30px auto;
      align-items: center;
      padding-bottom: 0.5rem;
      min-height: 35px;
      font-weight: 700;

      ${breakpoints.m} {
        font-weight: 700;
      }
    `,
  };
};
