/** @jsxImportSource @emotion/react */

import { footerAnalytics } from '../../analyticsConsts';
import footerData from '../../mocks/data/footer';
import ExternalLink from '../ExternalLink';
import useStyles from './styles';

const { FOOTER_WRAPPER, FOOTER_CTA } = footerAnalytics;

const Footer = () => {
  const styles = useStyles();

  const { links } = footerData;
  return (
    <div css={styles.footer} id={FOOTER_WRAPPER}>
      <div css={styles.footerWrapper}>
        <div css={styles.leftContent}>
          <ul css={styles.list}>
            {links &&
              links.length > 0 &&
              links.map((link, index) => {
                return (
                  <li key={index}>
                    <ExternalLink
                      id={`${
                        link.id ? link.id : FOOTER_CTA.concat(`-${index}`)
                      }`}
                      css={styles.link}
                      className={link.className}
                      href={link.url}
                      download={link.isDownload}
                    >
                      {link.text}
                      {link.isDownload && (
                        <span css={styles.pdfDownload}>
                          (downloads pdf file)
                        </span>
                      )}
                    </ExternalLink>
                  </li>
                );
              })}
          </ul>
        </div>
        <div css={styles.rightContent}>
          The Football Association © 2001 - {new Date().getFullYear()} - All
          Rights Reserved
        </div>
      </div>
    </div>
  );
};

export default Footer;
