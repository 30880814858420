import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, borderRadius, colors, spacing } = useTheme();

  return {
    title: isClubInfo => css`
      margin-bottom: ${spacing.xs};
      text-decoration: underline ${colors.red} 2px;
      -webkit-text-decoration-line: underline;
      -webkit-text-decoration-color: ${colors.red};
      -webkit-text-decoration-style: solid;
      -webkit-text-decoration-thickness: 2px;
      display: inine-block;
      font-size: 1.75rem;
      line-height: 1.625rem;
      font-weight: 700;

      ${breakpoints.l} {
        font-size: 1.75rem;
        line-height: 1.625rem;
        font-weight: 700;

        ${!isClubInfo &&
        css`
          font-size: 2.5rem;
          line-height: 2.25rem;
        `}
      }
    `,
    section: (isVenueLocation, isProvider) => css`
      margin-bottom: ${isVenueLocation ? '1.5rem' : spacing.s};
      border-bottom: 1px solid ${colors.lightGrey};
      padding-bottom: ${isVenueLocation ? '1.5rem' : spacing.s};

      ${breakpoints.l} {
        ${isVenueLocation &&
        css`
          margin-bottom: ${spacing.m};
          padding-bottom: ${spacing.m};
        `}
      }
    `,
    sectionTitle: css`
      margin-bottom: ${spacing.s};
      font-weight: bold;
      color: ${colors.navy};
      letter-spacing: 0.02em;
      font-size: 1.25rem;

      ${breakpoints.l} {
        font-weight: bold;
        font-size: 1.25rem;
      }
    `,
    info: isProvider => css`
      color: ${colors.darkGrey};

      ${isProvider &&
      css`
        padding-bottom: 2rem;
      `}
    `,
    row: css`
      margin-bottom: ${spacing.xs};

      &:last-of-type {
        margin-bottom: 0;
      }
    `,
    link: css`
      display: flex;
      align-items: center;
      text-decoration: none;

      &:focus {
        outline: 0;
        text-decoration: underline;
        color: ${colors.navy};
        svg {
          color: ${colors.navy};
        }
      }
    `,
    icon: css`
      color: ${colors.red};
      flex-shrink: 0;
      width: 1.4375rem;
    `,
    text: isProvider => css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;

      ${isProvider &&
      css`
        text-transform: lowercase;
      `}
    `,
    stickyContact: (isClubInfo, isVenueLocation, isProvider) => css`
      height: 100%;

      ${
        isProvider &&
        css`
      position: sticky;
      top: 200px;
      height: auto;
      padding: 2.5rem 2.125rem 1rem 2.125rem;
            border-radius: ${borderRadius.xxl};
            box-shadow: 0px 1px 11px rgba(1, 30, 65, 0.07),
              0px 4px 20px rgba(1, 30, 65, 0.14);
          }
      `
      }

      h3 {
        display: inline-block;
        font-style: italic;
        margin-bottom: ${isVenueLocation ? spacing.m : spacing.s};
        color: ${colors.navy};
      }

      ${breakpoints.l} {
        padding-bottom: ${spacing.m};

      ${
        isClubInfo &&
        css`
          position: sticky;
          top: 200px;
          height: auto;

          ${breakpoints.l} {
            padding: 2.5rem 2.125rem;
            border-radius: ${borderRadius.xxl};
            box-shadow: 0px 1px 11px rgba(1, 30, 65, 0.07),
              0px 4px 20px rgba(1, 30, 65, 0.14);
          }
        `
      }

      ${
        isProvider &&
        css`
        ${breakpoints.l} {
          padding: 2.5rem 2.125rem 1rem 2.125rem;
        `
      }
        }
      }
    `,
    contactForm: css`
      background-color: ${colors.red};
      color: ${colors.white};
      display: inline-block;
    `,
    contactFormWrapper: css`
      display: grid;
      grid-auto-flow: column;
      align-items: center;
      margin-top: ${spacing.s};
      grid-template-columns: auto 1fr;
      align-items: center;
      grid-gap: 0.75rem;
      font-family: FS Dillon;
      border-bottom: 1px solid ${colors.lightGrey};
      margin-bottom: ${spacing.s};
      padding-bottom: ${spacing.s};

      span {
        color: ${colors.darkGrey};
        padding: 0.625rem 0;
      }

      button {
        padding: 10px;
        font-size: ${spacing.s};
      }
    `,
    bgIcon: css`
      background-color: ${colors.darkGrey};
      margin-right: ${spacing.xs};
      border-radius: ${borderRadius.l};
      height: 100%;
      display: inline-block;
      padding: 0.25rem;
      svg {
        color: ${colors.white};
        padding: 0.125rem;
        vertical-align: middle;
      }
    `,
    nameWrapper: css`
      font-size: 1.25rem;
      line-height: 1.375rem;

      ${breakpoints.l} {
        font-size: 1.25rem;
        line-height: 1.375rem;
      }
    `,
    venueDescription: css`
      background-color: ${colors.darkGrey};
      color: ${colors.white};
      border-radius: ${borderRadius.l};
      padding: ${spacing.s};
      display: grid;
      grid-template-columns: 40px auto;
      align-items: center;
      p {
        line-height: 1.5rem;
      }
    `,
  };
};
