/** @jsxImportSource @emotion/react */
import { useEffect, useState } from 'react';

import { usePreferencesContext } from '../../context/preferences';
import publicPath from '../../utils/publicPath';
import Image from '../Image';
import MOOnboardingCriteria from '../MOOnboardingCriteria';
import { getMOHeroProperties } from '../OnboardingModal/MiniOnboarding';
import Text from '../Text';
import useStyles from './styles';

const MOHero = ({
  textSize = 1,
  images,
  wrapperId,
  scrolldirectionContext,
  isDesktop,
  ...rest
}) => {
  const styles = useStyles();
  const [hasLoaded, setHasLoaded] = useState(false);
  const [MOPreferences, setMOPreferences] = useState(false);
  const { preferences } = usePreferencesContext();
  const { isLogo, title, img, linkText, category } = MOPreferences;

  useEffect(() => {
    const properties = getMOHeroProperties(preferences);
    setMOPreferences(properties);
  }, [preferences]);

  useEffect(() => {
    setTimeout(() => {
      setHasLoaded(true);
    }, 300);
  }, []);

  return (
    <div
      id={wrapperId}
      css={styles.hero(images, scrolldirectionContext)}
      {...rest}
    >
      <div
        data-test-id="hero-text"
        css={styles.textWrapper(hasLoaded)}
        {...rest}
      >
        {title && (
          <Text as="h1" size={textSize} css={styles.mainText}>
            {title}
          </Text>
        )}
        {isLogo && (
          <Text css={styles.imgWrapper}>
            <Image
              id={title}
              css={styles.image(category)}
              src={publicPath(isDesktop ? img.desktop : img.mobile)}
              alt={title}
            />
          </Text>
        )}
        <MOOnboardingCriteria
          linkText={linkText}
          preferences={preferences}
          MOPreferences={MOPreferences}
        />
      </div>
    </div>
  );
};

export default MOHero;
