import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const {
    colors,
    spacing,
    breakpoints,
    borderRadius,
    typography,
    screenSizes,
  } = useTheme();

  return {
    signPostWrapper: css`
      background-color: ${colors.white};
      position: relative;
      display: grid;
      width: 100%;
      padding: ${spacing.m} 0;
      margin-top: ${spacing.s};

      ${breakpoints.l} {
        margin-top: ${spacing.m};
        max-height: 500px;
        min-height: 500px;
      }

      ${breakpoints.xl} {
        padding: ${spacing.xl} 0;
      }
    `,
    signPostContainer: css`
      display: grid;
      border: 1px solid ${colors.lightGrey};
      border-radius: ${borderRadius.l};
      width: 90vw;
      margin: 0 auto;

      ${breakpoints.l} {
        grid-template-columns: 1fr 1fr;
        max-width: ${screenSizes.xl};
        max-height: 400px;
      }
    `,
    textWrapper: css`
      position: relative;
      z-index: 1;
      align-items: center;
      display: grid;
      order: 2;

      ${breakpoints.l} {
        order: 1;
      }
    `,
    mainText: css`
      font-size: 1.75rem;
      line-height: 1.625rem;
      font-weight: 700;
      margin-bottom: ${spacing.s};
      color: ${colors.navy};

      ${breakpoints.l} {
        ${typography.headings[4].desktop};
      }
    `,
    cta: css`
      background-color: ${colors.red};
      color: ${colors.white};
      text-transform: uppercase;
      max-width: 21.563rem;

      ${breakpoints.l} {
        width: 21.313rem;
      }
    `,
    description: css`
      fontSize: 1.25rem,
      lineHeight: ${spacing.m},
      padding: 4px 18px 12px 7px;
      letter-spacing: 0.02em;
      position: relative;
      margin-bottom: ${spacing.m};
      width: 90%;

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -${spacing.s};
        height: ${borderRadius.s};
        width: 60px;
        background: ${colors.red};
      }
    `,
    image: css`
      width: 100%;
      max-width: 100%;
      height: 100%;
      position: relative;
      object-fit: cover;
      border-radius: ${borderRadius.l} ${borderRadius.l} 0 0;

      ${breakpoints.l} {
        border-radius: 0 ${borderRadius.l} ${borderRadius.l} 0;
      }
    `,
    innerTextWrapper: css`
      padding: ${spacing.s};

      ${breakpoints.l} {
        padding: 0 ${spacing.xl};
      }

      ${breakpoints.xl} {
        padding: 0 ${spacing.xl} 0 6rem;
      }
    `,
    imgWrapper: css`
      order: 1;

      ${breakpoints.l} {
        order: 2;
      }
    `,
  };
};
