/** @jsxImportSource @emotion/react */
import { Fragment, useRef } from 'react';

import formatDate from '../../utils/normaliseVenueDetails/formatDate';
import HiddenContent from '../HiddenContent';
import { DateIcon } from '../Icons';
import SessionCard from '../SessionCard';
import Text from '../Text';
import useStyles from './styles';

export const INITIAL_SHOWN_CARDS_MOBILE = 8;

const VenueCard = ({
  venueTitle,
  onClick,
  isDesktop,
  analyticsIndex,
  data,
  rowData,
  isMiniOnboarding = false,
  logoDetails,
  centerName,
  footballtype,
}) => {
  const styles = useStyles();
  const cardToFocus = useRef();
  const { SessionId, SessionDateTime } = rowData;

  return (
    <Fragment>
      <li css={styles.teamHeading} key={SessionId} tabIndex={0}>
        {isDesktop && (
          <Text as="h4" css={styles.dateFormat}>
            <HiddenContent>
              {data?.CentreName || data?.TeamName}(
              {data?.DistanceFromUserLocation} miles)
            </HiddenContent>
            {SessionDateTime && (
              <Fragment>
                <DateIcon />
                <span>{formatDate(SessionDateTime)}</span>
              </Fragment>
            )}
          </Text>
        )}
        {!isDesktop && SessionDateTime && (
          <Text as="h4" css={styles.dateFormat}>
            <DateIcon />
            <span>{formatDate(SessionDateTime)}</span>
          </Text>
        )}
        <div css={styles.teamList} tabIndex={-1} ref={cardToFocus}>
          <SessionCard
            index={analyticsIndex}
            {...rowData}
            onClick={onClick}
            isMiniOnboarding={isMiniOnboarding}
            venueTitle={venueTitle || data?.CentreName}
            logoDetails={logoDetails}
            centerName={centerName}
            footballtype={footballtype}
            isDesktop={isDesktop}
          />
        </div>
      </li>
    </Fragment>
  );
};

export default VenueCard;
