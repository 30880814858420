import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, typography, colors, spacing } = useTheme();

  return {
    base: css`
      ${breakpoints.l} {
        text-align: center;
        margin: auto;
      }
    `,
    confirmationHeader: css`
      display: grid;
      grid-template-columns: 65px auto;
      align-items: center;
      button {
        display: inline-block;
        color: ${colors.darkGrey};
      }
      svg {
        color: ${colors.red};
      }

      ${breakpoints.l} {
        display: block;
      }
    `,
    title: css`
      margin-bottom: ${spacing.xs};
      display: inine-block;
      ${typography.headings[7].desktop};
      line-height: 1.625rem;
      font-weight: 700;
      color: ${colors.navy};
      padding: 1.5rem ${spacing.s} ${spacing.s} ${spacing.s};

      ${breakpoints.l} {
        ${typography.headings[7].desktop};
        line-height: 1.625rem;
        font-weight: 700;
        margin-top: ${spacing.xs};
        padding: ${spacing.xs};
      }
    `,
    smallTitle: css`
      border-top: 1px solid ${colors.white};
      padding: ${spacing.m} 0 ${spacing.xs} 0;
      ${typography.headings[7].desktop};
    `,
    subTitle: css`
      color: ${colors.darkGrey};
      padding: ${spacing.xs} 0 0 0;
    `,
    link: css`
      padding: 0 0 0.2rem 0;
      font-weight: normal;
      color: ${colors.darkGrey};
      letter-spacing: normal;
    `,
    linkHere: css`
      display: inline-block;
      font-weight: 700;
    `,
    instructions: css`
      ul {
        list-style: disc;
        padding-left: ${spacing.s};
        text-align: left;

        li {
          color: ${colors.darkGrey};
          padding-bottom: ${spacing.xs};
          ${typography.desktop};
        }
      }
    `,
  };
};
