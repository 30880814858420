/** @jsxImportSource @emotion/react */

import { useLayoutEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';

import { recommendationsPageAnalytics } from '../../analyticsConsts';
import {
  BOYS_OR_MEN,
  BOYS_OR_MEN_MIXED,
  GIRLS_OR_WOMEN,
  GIRLS_OR_WOMEN_MIXED,
  KICKABOUT_CASUAL,
  PLAY_WITH_ID,
} from '../../const';
import { useFilterPanelToggleContext } from '../../context/filterPanelToggle';
import { useMORecommendations } from '../../context/moRecommendations';
import { usePreferencesContext } from '../../context/preferences';
import { useRecommendations } from '../../context/recommendations';
import useErrorBoundary from '../../hooks/useErrorBoundary';
import useOnboardingURL from '../../hooks/useOnboardingURL';
import {
  getMiniOnboardingRecommendations,
  getRecommendations,
} from '../../services';
import Button from '../Button';
import LinkButton from '../LinkButton';
import Loader from '../Loader';
import Text from '../Text';
import useStyles from './styles';

const {
  RECOMMENDED_FOOTBALL_TYPE_CTA_EXPAND_SEARCH,
  RECOMMENDED_FOOTBALL_TYPE_LINK_FILTERS,
  RECOMMENDED_FOOTBALL_TYPE_LINK_NEW_SEARCH,
  RECOMMENDED_FOOTBALL_TYPE_NO_RESULTS_TITLE,
  RECOMMENDED_FOOTBALL_TYPE_NO_RESULTS_CONTAINER,
  RECOMMENDED_FOOTBALL_TYPE_NO_RESULTS_WRAPPER,
} = recommendationsPageAnalytics;

const NoResultsRecommendations = () => {
  const styles = useStyles();
  const history = useHistory();
  const { isFilterPanelToggled, setFilterPanelToggle } =
    useFilterPanelToggleContext();
  const [isLoading, setIsLoading] = useState(false);
  const loadingRef = useRef();
  const throwError = useErrorBoundary();
  const { preferences, setPreferences } = usePreferencesContext();
  const { SelectedFootballType: selectedFootballType, PlayWith: playWith } =
    preferences || {};
  const { setRecommendations } = useRecommendations();
  const getOnboardingURL = useOnboardingURL();
  const isMiniOnboarding = preferences?.hasOwnProperty('Category');
  const { setMORecommendations } = useMORecommendations();

  const getMixedRecommendations = () => {
    setIsLoading(true);
    const playWithID =
      playWith === BOYS_OR_MEN ? BOYS_OR_MEN_MIXED : GIRLS_OR_WOMEN_MIXED;

    const updatedPreference = {
      ...preferences,
      [PLAY_WITH_ID]: playWithID,
    };

    if (isMiniOnboarding) {
      getMiniOnboardingRecommendations(selectedFootballType, preferences)
        .then(({ data }) => {
          if (data) {
            setMORecommendations(data[0], selectedFootballType);
            // when we are sure the preferences have been set
            setPreferences(updatedPreference);
            setIsLoading(false);
          }
        })
        .catch(throwError);
      return;
    }
    Promise.all([
      getRecommendations(selectedFootballType, updatedPreference),
    ])
      .then(data => {
        if (data) {
          const recommendationRecords = data[0].data; // recommendation data
          setRecommendations(recommendationRecords, selectedFootballType);

          // when we are sure the preferences have been set
          setPreferences(updatedPreference);
          setIsLoading(false);
        }
      })
      .catch(throwError);
  };

  useLayoutEffect(() => {
    if (isLoading) {
      loadingRef.current?.focus();
    }
  }, [isLoading]);

  const isCasual = selectedFootballType === KICKABOUT_CASUAL;

  return (
    <div css={styles.base} id={RECOMMENDED_FOOTBALL_TYPE_NO_RESULTS_WRAPPER}>
      {!isLoading ? (
        <div
          css={styles.banner}
          id={RECOMMENDED_FOOTBALL_TYPE_NO_RESULTS_CONTAINER}
        >
          <Text
            as="h1"
            size={5}
            id={RECOMMENDED_FOOTBALL_TYPE_NO_RESULTS_TITLE}
            css={styles.header}
          >
            Sorry, we couldn’t find a recommendation
          </Text>
          <div css={styles.description}>
            {playWith === BOYS_OR_MEN && isCasual ? (
              <Text>
                Increase your chances of finding a game by including mixed
                football options in your search results
              </Text>
            ) : playWith === GIRLS_OR_WOMEN && isCasual ? (
              <Text>
                Expand your search results by including mixed games – a fun and
                friendly option in Casual football
              </Text>
            ) : (
              <Text>
                Try broadening
                <LinkButton
                  id={RECOMMENDED_FOOTBALL_TYPE_LINK_FILTERS}
                  css={styles.newSearch}
                  aria-label="click here to open filter panel"
                  onClick={() => setFilterPanelToggle(!isFilterPanelToggled)}
                >
                  your filters
                </LinkButton>{' '}
                like days and location, or start a
                <LinkButton
                  id={RECOMMENDED_FOOTBALL_TYPE_LINK_NEW_SEARCH}
                  css={styles.newSearch}
                  aria-label="Start new search"
                  onClick={() => history.push(getOnboardingURL)}
                >
                  new search
                </LinkButton>
              </Text>
            )}
          </div>
          {(playWith === BOYS_OR_MEN || playWith === GIRLS_OR_WOMEN) &&
            isCasual && (
              <div css={styles.bottom}>
                <Button
                  id={RECOMMENDED_FOOTBALL_TYPE_CTA_EXPAND_SEARCH}
                  css={styles.cta}
                  onClick={() => getMixedRecommendations()}
                >
                  expand search
                </Button>
              </div>
            )}
        </div>
      ) : (
        <Loader message="Loading mixed football results..." ref={loadingRef} />
      )}
    </div>
  );
};

export default NoResultsRecommendations;
