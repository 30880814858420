import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const {
    breakpoints,
    borderRadius,
    colors,
    spacing,
    typography,
    mediaQueries,
  } = useTheme();

  return {
    base: css`
      background-color: ${colors.lightGrey};
      border-radius: ${borderRadius.l} ${borderRadius.l} 0 0;
    `,
    tabs: isMapPage => css`
      display: grid;
      justify-content: center;
      grid-auto-flow: column;
      grid-gap: 1rem;
      padding: 1rem 1.5rem;

      ${isMapPage &&
      css`
        display: flex;
        justify-content: stretch;
      `}

      ${breakpoints.m} {
        padding: 1.5rem;
      }
    `,
    tab: (isSelected, isMapPage) => css`
      ${typography.headings[6].mobile};
      color: ${colors.navy};
      padding: 0.8125rem;
      letter-spacing: 0.01rem;
      font-size: 1rem;
      font-weight: 400;
      text-transform: capitalize;
      border: ${borderRadius.s} solid ${colors.darkGrey};
      outline: 0;
      background-color: ${colors.white};
      border-radius: 4px;
      max-width: 156px;
      height: 35px;

      ${!isMapPage &&
      css`
        min-width: 144px;
      `}

      ${breakpoints.m} {
        min-width: 144px;
      }

      &:focus-visible {
        border: ${borderRadius.s} solid ${colors.navy};
        background-color: ${colors.darkGrey};
        border-radius: ${borderRadius.m};
        color: ${colors.white};
      }

      ${mediaQueries.safariBrowser} {
        @supports (-webkit-appearance: none) {
          &:focus {
            border: ${borderRadius.s} solid ${colors.navy};
            background-color: ${colors.darkGrey};
            border-radius: ${borderRadius.m};
            color: ${colors.white};
          }
        }
      }

      &:not(:disabled):hover {
        border-radius: 4px;
        background-color: ${colors.darkGrey};
        color: ${colors.white};
      }

      ${isSelected &&
      css`
        color: ${colors.white};
        background-color: ${colors.darkGrey};

        &:not(:hover):focus-visible {
          color: ${colors.white};
          text-decoration: underline;
        }

        // for safari browser
        ${mediaQueries.safariBrowser} {
          @supports (-webkit-appearance: none) {
            &:not(:hover):focus {
              color: ${colors.white};
              text-decoration: underline;
            }
          }
        }
      `};

      ${breakpoints.m} {
        padding: 0.4rem ${spacing.xs};
        border: ${borderRadius.s} solid ${colors.darkGrey};
        max-width: 227px;
        width: 227px;
        font-size: 1rem;
      }
    `,
  };
};
