import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, borderRadius, colors, typography, spacing } = useTheme();

  return {
    base: css`
      height: 100%;
      display: grid;
      grid-template-rows: auto 1fr;
      grid-template-columns: 100%;
    `,
    hero: css`
      h1 {
        text-transform: capitalize;
        font-style: italic;
        text-align: center;
        font-size: 2.625rem;

        &::after {
          background: none;
        }

        ${breakpoints.l} {
          ${typography.headings[3].desktop};
          max-width: 100%;
          text-align: center;
          width: 85%;
          margin: 0 auto;
        }
      }
    `,
    card: css`
      position: relative;
      background-color: ${colors.white};
      border-radius: ${borderRadius.l};
      margin-bottom: ${spacing.m};
      box-shadow: 0px 4px 6px rgba(91, 104, 133, 0.1);

      ${breakpoints.xl} {
        grid-template-rows: unset;
        grid-gap: ${spacing.s};
      }
    `,
    navContent: css`
      margin-top: 0.0625rem;
      padding: ${spacing.m};

      &:not(:last-child) {
        border-bottom: 1px solid ${colors.lightGrey};
      }

      ${breakpoints.xl} {
        margin: 0 6rem;
        padding: ${spacing.m} 0;
      }
    `,
    arrowRotate: css`
      transform: rotate(180deg);
      margin-right: 0.3125rem;
      color: ${colors.navy};
    `,
    backLink: css`
      color: ${colors.darkGrey};
      margin: ${spacing.s} 0;
      text-decoration: none;
      display: inline-block;
      font-weight: normal;
      padding: 0;
    `,
    loader: css`
      padding: ${spacing.s};
    `,
  };
};
