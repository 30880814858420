/** @jsxImportSource @emotion/react */
import { CASUAL_LINK, CLUB_LINK } from '../../const';
import ExternalLink from '../ExternalLink';
import Text from '../Text';
import useStyles from './styles';

const CategoryHeroCard = ({
  title,
  benefitsItems,
  images,
  footballType,
  selectedType,
}) => {
  const styles = useStyles();

  return (
    <div css={styles.card}>
      <div css={styles.grid}>
        <div css={styles.top}>
          <Text as="h2" size="5" css={styles.title}>
            {title}
          </Text>
        </div>

        <div css={styles.middle}>
          <div css={styles.middleWrapper}>
            {benefitsItems &&
              benefitsItems.map((item, index) => (
                <div key={index}>
                  <span>- </span>
                  {item.value}
                </div>
              ))}
          </div>
          <div css={styles.bottom}>
            <ExternalLink
              css={styles.link}
              href={selectedType === 1 ? CASUAL_LINK : CLUB_LINK}
            >{`Learn more about ${footballType}`}</ExternalLink>
          </div>
        </div>
      </div>

      <div css={styles.imgWrapper}>
        {images && <div css={images && styles.imgSection(images)}></div>}
      </div>
    </div>
  );
};

export default CategoryHeroCard;
