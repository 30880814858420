/** @jsxImportSource @emotion/react */
import Text from '../Text';
import useStyles from './styles';

const CheckboxAnswer = ({
  children,
  isChecked,
  value,
  name,
  id,
  onChange,
  ...rest
}) => {
  const styles = useStyles();

  return (
    <label htmlFor={id} css={[styles.container, styles.hiddenInputs]} {...rest}>
      <input
        id={id}
        type="checkbox"
        value={value}
        aria-checked={isChecked}
        checked={isChecked}
        name={name}
        onChange={() => onChange()}
      />
      <Text>{children}</Text>
      <span css={styles.checkmark(isChecked)}></span>
    </label>
  );
};

export default CheckboxAnswer;
