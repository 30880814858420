import {
  DISABILITY_FACILITIES_MAPPING,
  PAGE_NUMBER,
  PAGE_SIZE,
  WALKING_FOOTBALL,
} from '../../const';

const venueSession = (
  isFiltersApplied,
  filteredSessions,
  unfilteredSessions
) => {
  return isFiltersApplied ? filteredSessions : unfilteredSessions;
};

const backURL = (previousPage, previousUrl) => {
  const previousCheck = previousUrl ? previousUrl : '/recommendation';
  return previousPage ? previousPage : previousCheck;
};

const venueData = (
  casualData,
  id,
  pitchfinderref,
  setIsProviderData,
  setProvidersData,
  providerRef
) => {
  const providerValue = providerItem => {
    setIsProviderData(providerItem ? true : false);
  };
  casualData?.map(casualItem => {
    if (casualItem?.RecommendationCentreCartDto && id.length > 10) {
      casualItem?.RecommendationCentreCartDto?.map(centerData => {
        if (centerData.CentreId === parseInt(id)) {
          pitchfinderref.current = centerData.PitchFinderId;
          centerData?.ProviderCartDto?.map(providerItem => {
            providerValue(providerItem);
            setProvidersData(prev => [...prev, providerItem]);
            return providerItem;
          });
        }
        return centerData;
      });
    }
    if (casualItem?.RecommendationProviderCartDto) {
      casualItem?.RecommendationProviderCartDto?.map(centerData => {
        if (
          centerData?.ProviderCartDto.length > 1 &&
          centerData?.PitchFinderId === parseInt(id)
        ) {
          centerData?.ProviderCartDto?.map(providerItem => {
            providerRef.current = providerItem.ProviderId;
            providerValue(providerItem);
            setProvidersData(prev => [...prev, providerItem]);
            return providerItem;
          });
        }
        return centerData;
      });
    }
    return casualItem;
  });
};

const providerPreviousPageName = previousUrl => {
  const resultsCheck =
    previousUrl?.indexOf('results') > -1 ? 'results' : 'recommendation';

  const mapLinkCheck = previousUrl?.indexOf('map') > -1 ? 'map' : resultsCheck;

  return previousUrl ? mapLinkCheck : 'recommendation';
};

const venuePreviousPage = (previousUrl, teamPage = false) => {
  const mapcheck = previousUrl?.indexOf('map') > -1 && 'map';
  const resultsCheck = previousUrl.indexOf('results') > -1 && 'results';
  const clubCheck = previousUrl.indexOf('club') > -1 && 'club';
  const resultClubCheck = clubCheck ? clubCheck : resultsCheck;
  const resultCheck = teamPage === true ? resultClubCheck : resultsCheck;
  const navigationCheck = mapcheck ? mapcheck : resultCheck;
  return previousUrl ? navigationCheck : 'recommendation';
};

const walkingFootball = Agegroup => {
  return Agegroup?.toLowerCase()===WALKING_FOOTBALL ? 'Walking' : Agegroup;
};

const providerCardImage = (
  isDesktop,
  desktopLogoRef,
  mobLogoRef,
  categoryHasIcon,
  categoryStringId
) => {
  const categoryType = categoryHasIcon ? categoryStringId : 'ef-logo';
  const mobileImg = mobLogoRef.current
    ? mobLogoRef.current
    : `/images/desktop/initiativeLogo/${categoryType}.svg`;
  const displayImg = isDesktop ? desktopLogoRef.current : mobileImg;
  return displayImg;
};

const teamsRow = (DivisionName, ageGroup, LeagueName) => {
  const leagueSelect =
    !LeagueName && DivisionName
      ? [`${ageGroup} ${DivisionName}`]
      : [`${ageGroup} TBD`];
  const divisionSelect =
    LeagueName && !DivisionName ? [`${ageGroup} ${LeagueName}`] : leagueSelect;
  return DivisionName && LeagueName
    ? [`${ageGroup} ${DivisionName} of ${LeagueName}`]
    : divisionSelect;
};

const facilityRow = Facility => {
  return [
    ...(Facility && Facility.CarPark && Facility.CarParkCapacity > 0
      ? [`${Facility.CarParkCapacity} Parking Spaces`]
      : []),
    ...(Facility && Facility.ChangingRooms ? ['Changing Rooms'] : []),
    ...(Facility && Facility.Floodlit ? ['Floodlights'] : []),
  ];
};

const disabilityRow = DisableFacility => {
  return (
    DisableFacility &&
    Object.keys(DisableFacility)
      .map(
        facility =>
          DisableFacility[facility] && DISABILITY_FACILITIES_MAPPING[facility]
      )
      .filter(Boolean)
  );
};

const casualDataCal = (
  casualMORecommendations,
  totalcenters,
  casualRecommendations
) => {
  return casualMORecommendations ? totalcenters : casualRecommendations;
};

const refreshingSession = (
  isLoading,
  venueDetails,
  filteredSessions,
  unfilteredSessions
) => {
  return isLoading && venueDetails && (filteredSessions || unfilteredSessions);
};

const titleName = (selectedRecommendationType, isFiltersApplied, Age) => {
  return selectedRecommendationType && isFiltersApplied
    ? `${selectedRecommendationType} sessions for ${Age} year olds`
    : 'All sessions at this venue';
};

const linkUrl = (selectedRecommendationType, Category, isDesktop) => {
  return (!!selectedRecommendationType || Category) && isDesktop;
};

const providerMLCard = (
  isMLCard,
  preferenceWithPagination,
  defaultPreferences,
  preferences
) => {
  const prefPage = preferenceWithPagination
    ? {
        ...defaultPreferences,
        ...preferences,
        [PAGE_SIZE]: 6,
        [PAGE_NUMBER]: 1,
      }
    : preferenceWithPagination;
  return isMLCard ? preferenceWithPagination : prefPage;
};

const mlCard = (
  isMLCard,
  preferenceWithPagination,
  isFiltersApplied,
  defaultPreferences,
  preferences
) => {
  const MLCheck = isFiltersApplied
    ? {
        ...defaultPreferences,
        ...preferences,
        [PAGE_SIZE]: 6,
        [PAGE_NUMBER]: 1,
      }
    : preferenceWithPagination;
  return isMLCard ? preferenceWithPagination : MLCheck;
};
const venueDetailsCond = (venueDetails, setVenueDetails, normalisedDetails) => {
  if (!venueDetails) {
    setVenueDetails(normalisedDetails);
  }
};
const filteredData = (
  isFiltersApplied,
  setFilteredSessions,
  filteredSessions,
  sessions,
  setUnfilteredSessions,
  unfilteredSessions
) => {
  if (isFiltersApplied) {
    setFilteredSessions(
      filteredSessions ? [...filteredSessions, ...sessions] : sessions
    );
  } else {
    setUnfilteredSessions(
      unfilteredSessions ? [...unfilteredSessions, ...sessions] : sessions
    );
  }
};

const recommendationListName = data => {
  const checkFirst =
    data?.providerName?.length === 1 ? data?.providerName : data.CentreName;
  const checkSecond = checkFirst
    ? data.CentreName.toLowerCase()
    : data.ClubName.toLowerCase();
  return checkSecond;
};

export {
  backURL,
  casualDataCal,
  disabilityRow,
  facilityRow,
  filteredData,
  linkUrl,
  mlCard,
  providerCardImage,
  providerMLCard,
  providerPreviousPageName,
  recommendationListName,
  refreshingSession,
  teamsRow,
  titleName,
  venueData,
  venueDetailsCond,
  venuePreviousPage,
  venueSession,
  walkingFootball,
};
