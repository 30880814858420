import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { spacing, colors, borderRadius, breakpoints, utility } = useTheme();

  return {
    base: css`
      display: flex;
      flex-wrap: wrap;
      margin: -${spacing.xs} 0 0 -${spacing.xs};
      width: calc(100% + ${spacing.xs});
    `,
    answer: isChecked => css`
      display: block;
      margin: 0.2rem 0 0 0.2rem;
      padding: ${spacing.xs};
      background-color: ${colors.lightGrey};
      color: ${colors.navy};
      cursor: pointer;
      border-radius: ${borderRadius.s};
      border: ${borderRadius.s} solid transparent;

      ${isChecked &&
      css`
        background-color: ${colors.darkGrey};
        color: ${colors.white};
      `}

      ${breakpoints.l} {
        margin: ${spacing.xs} 0 0 ${spacing.xs};
        padding: ${spacing.xs} ${spacing.s};
      }
    `,
    label: css`
      & > input[type='checkbox']:focus + span {
        border: ${borderRadius.s} solid ${colors.navy};
        border-radius: ${borderRadius.m};
        text-decoration: underline;
        background-color: ${colors.white};
      }

      & > input[type='checkbox']:checked:focus + span {
        border: ${borderRadius.s} solid ${colors.navy};
        border-radius: ${borderRadius.m};
        text-decoration: none;
        background-color: ${colors.darkGrey};
        color: ${colors.white};
      }

      & > input[type='checkbox']:hover + span {
        background-color: ${colors.greyShade1};
      }
    `,
    checkbox: css`
      ${utility.checkboxHidden};
      outline: none;
    `,
  };
};
