import axios from 'axios';

import b2cEnvVariables from '../b2cEnvVariables';
import {
  Api404,
  Api500,
  CLUB,
  KICKABOUT_CASUAL,
  MAX_DURATION,
  MAX_DURATION_OVERRIDE,
  SELECTED_RECOMMENDATIONS_TYPE_ID,
  SESSION_DURATION,
  UnspecifiedError,
} from '../const';
import clubPage from '../mocks/data/clubPage';
import moRecommendations from '../mocks/data/moRecommendations';
import onboarding from '../mocks/data/onboarding';
import recommendations from '../mocks/data/recommendations';
import venuePage from '../mocks/data/venuePage';
import volunteerOnboarding from '../mocks/data/volunteerOnboarding';
import AppError from '../utils/AppError';

const apiUrlMap = {
  [KICKABOUT_CASUAL]: 'casualrecommendation',
  [CLUB]: 'clubrecommendation',
};

const miniOnboardingApiUrlMap = {
  [KICKABOUT_CASUAL]: 'sessionrecommendation',
  [CLUB]: 'teamsrecommendation',
};

// const apiMLUrlMap = {
//   [KICKABOUT_CASUAL]: 'mlcasualrecommendation',
//   [CLUB]: 'mlclubrecommendation',
// };

const errorMapping = {
  404: Api404,
  500: Api500,
};

const appConfig = b2cEnvVariables(process.env.REACT_APP_ENVIRONMENT);

const baseURL = process.env.REACT_APP_API_URL;
const b2cURL = appConfig?.GlobalLogout;
const subscriptionKey = process.env.REACT_APP_SUBSCRIPTION_KEY;
const baseExternalURL = process.env.REACT_APP_API_URL_EXTERNAL;
const subscriptionKeyAccreditations =
  process.env.REACT_APP_SUBSCRIPTION_KEY_ACC;
const siteCoreBaseURL = process.env.REACT_APP_SITECORE_API_URL;

const baseHeaders = {
  'Content-Type': 'application/json',
  'Ocp-Apim-Subscription-Key': subscriptionKey,
};

const service = axios.create({
  baseURL,
  headers: baseHeaders,
});

service.interceptors.response.use(undefined, error => {
  const status = error.response?.status.toString();

  throw new AppError(
    status && status in errorMapping ? errorMapping[status] : UnspecifiedError
  );
});

// TODO: move normalisers to their specific files

// Preferences normaliser
const normalisePreferences = preferences => {
  const { [SESSION_DURATION]: SessionDuration } = preferences;

  // TODO: Move this logic to the back-end
  // Please refer to this ticket https://the-fa.atlassian.net/browse/EF-444
  // for more details about this change
  if (SessionDuration === MAX_DURATION) {
    return {
      ...preferences,
      SessionDuration: MAX_DURATION_OVERRIDE,
    };
  }

  return preferences;
};

// GET
export const getOnboardingQuestions = dataset => {
  return new Promise(resolve => {
    resolve(dataset ? { data: volunteerOnboarding } : { data: onboarding });
  });
};

export const getHomepageData = () => {
  const siteCoreAPIUrl = `${siteCoreBaseURL}/layout/render/jss?sc_site=discoverfootball-site&sc_apikey={68A451F8-7553-4D18-8C3D-4BDA0F132688}&item={352FCB53-6C93-4651-A54F-43EDA3890758}&sc_lang=en`;
  return service.get(siteCoreAPIUrl, {
    headers: {
      ...baseHeaders,
      'Ocp-Apim-Subscription-Key': subscriptionKeyAccreditations,
    },
  });
};

export const getRecommendationsPageData = () => {
  return new Promise(resolve => {
    resolve({ data: recommendations });
  });
};

export const getVenuePageData = () => {
  return new Promise(resolve => {
    resolve({ data: venuePage });
  });
};

export const getMORecommendationsPageData = () => {
  return new Promise(resolve => {
    resolve({ data: moRecommendations });
  });
};

export const getClubPageData = () => {
  return new Promise(resolve => {
    resolve({ data: clubPage });
  });
};

// get menus
export const getHeaderMenu = () => {
  // return new Promise(resolve => {
  //   resolve({ data: headerMenu });
  // });
  // const siteCoreAPIUrl = `${siteCoreBaseURL}/layout/placeholder/jss?placeholderName=header&item=/sitecore/content/EnglandFootball/home&sc_apikey={E2F3D43E-B1FD-495E-B4B1-84579892422A}`;
  const siteCoreAPIUrl = `${siteCoreBaseURL}/layout/placeholder/jss?placeholderName=header&item=/sitecore/content/EnglandFootball/Home&sc_apikey={E2F3D43E-B1FD-495E-B4B1-84579892422A}`;
  return service.get(siteCoreAPIUrl, {
    headers: {
      ...baseHeaders,
      'Ocp-Apim-Subscription-Key': subscriptionKeyAccreditations,
    },
  });
};

export const getSessionLogo = () => {
  const siteCoreAPIUrl = `${siteCoreBaseURL}/layout/placeholder/jss?placeholderName=FAInitiatives&item=/sitecore/content/DiscoverFootball/Home&sc_apikey={E2F3D43E-B1FD-495E-B4B1-84579892422A}`;
  return service.get(siteCoreAPIUrl, {
    headers: {
      ...baseHeaders,
      'Ocp-Apim-Subscription-Key': subscriptionKeyAccreditations,
    },
  });
};

export const getClubAccreditation = id => {
  return service.get(`${baseExternalURL}/${id}/accreditation`, {
    headers: {
      ...baseHeaders,
      'Ocp-Apim-Subscription-Key': subscriptionKeyAccreditations,
    },
  });
};

export const getClubContactDetails = id => {
  return service.get(`${baseExternalURL}/${id}/clubcontact`, {
    headers: {
      ...baseHeaders,
      'Ocp-Apim-Subscription-Key': subscriptionKeyAccreditations,
    },
  });
};

// POST
export const getRecommendations = (footballType, preferences) => {
  const endpoint = apiUrlMap[footballType];
  const normalisedPreferences = normalisePreferences(preferences);

  return service.post(`/${endpoint}`, normalisedPreferences);
};

//POST
export const getMiniOnboardingRecommendations = (footballType, preferences) => {
  const endpoint = miniOnboardingApiUrlMap[footballType];
  const normalisedPreferences = normalisePreferences(preferences);

  return service.post(`/${endpoint}`, normalisedPreferences);
};

//Global log-out API
export const globalLogOut = (itemtoken, userId, lastLogOn) => {
  return service.post(
    `${b2cURL}/ValidateSignInSessions`,
    {
      userId: userId,
      lastLogOnTime: lastLogOn,
    },
    {
      headers: {
        Authorization: itemtoken,
      },
    }
  );
};

//Revoking after global log-out
export const revokeSignIn = (itemtoken, accountUser) => {
  return service.post(
    `${b2cURL}/RevokeSignInSessions`,
    {
      userId: accountUser,
    },
    {
      headers: {
        Authorization: itemtoken,
      },
    }
  );
};

// ML Api is removed temporarily
// POST
// export const getMLRecommendations = (footballType, preferences) => {
//   const endpoint = apiMLUrlMap[footballType];
//   const normalisedPreferences = normalisePreferences(preferences);

//   return service.post(`/${endpoint}`, normalisedPreferences);
// };

export const getProviderDetails = (id, providerId, preferences) => {
  return service.post(`${baseURL}/provider`, {
    PitchFinderId: id,
    ProviderId:
      typeof providerId === 'string' ? providerId : providerId?.toString(),
    PlayWith: preferences.PlayWith,
    Age: preferences.Age,
    WeekDays: preferences.WeekDays,
    Disabilityoption: preferences.Disabilityoption,
    Latitude: preferences.Latitude,
    Longitude: preferences.Longitude,
    DisabilityType: preferences.DisabilityType,
    FootballType: preferences[SELECTED_RECOMMENDATIONS_TYPE_ID],
    PageNumber: preferences.PageNumber,
    PageSize: preferences.PageSize,
  });
};

export const getVenueDetails = (id, preferences) => {
  return service.post(`${baseURL}/centre`, {
    CentreId: id,
    Age: preferences.Age,
    PlayWith: preferences.PlayWith,
    FootballType: preferences[SELECTED_RECOMMENDATIONS_TYPE_ID],
    WeekDays: preferences.WeekDays,
    Disabilityoption: preferences.Disabilityoption,
    DisabilityType: preferences.DisabilityType,
    Latitude: preferences.Latitude,
    Longitude: preferences.Longitude,
    PageNumber: preferences.PageNumber,
    PageSize: preferences.PageSize,
  });
};

export const getClubDetails = (id, preferences) => {
  return service.post(`${baseURL}/club`, {
    ClubId: id,
    Age: preferences.Age,
    PlayWith: preferences.PlayWith,
    FootballType: preferences[SELECTED_RECOMMENDATIONS_TYPE_ID],
    WeekDays: preferences.WeekDays,
    Disabilityoption: preferences.Disabilityoption,
    DisabilityType: preferences.DisabilityType,
    Latitude: preferences.Latitude,
    Longitude: preferences.Longitude,
  });
};

export const getTeamDetails = (id, preferences) => {
  const url = !preferences
    ? `${baseURL}/team?FadwTeamId=${id}`
    : `${baseURL}/team?FadwTeamId=${id}&Latitude=${preferences.Latitude}&Longitude=${preferences.Longitude}`;
  return service.get(url, {
    headers: {
      ...baseHeaders,
      'Ocp-Apim-Subscription-Key': subscriptionKey,
    },
  });
};

export const getMatchDetails = id => {
  const WgsTeamID = id.toString().toLowerCase();
  return service.post(
    `${baseExternalURL}/team`,
    { WgsTeamIDs: [WgsTeamID] },
    {
      headers: {
        ...baseHeaders,
        'Ocp-Apim-Subscription-Key': subscriptionKeyAccreditations,
      },
    }
  );
};

// submit contact form
export const submitContactForm = requestPayload => {
  return service.post(`${baseURL}/notification`, requestPayload);
};

export const getSocialMediaData = WgsClubId => {
  return service.get(`${baseExternalURL}/${WgsClubId}/socialmedia`, {
    headers: {
      ...baseHeaders,
      'Ocp-Apim-Subscription-Key': subscriptionKeyAccreditations,
    },
  });
};
