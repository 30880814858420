/** @jsxImportSource @emotion/react */

import useStyles from './styles';

const ButtonAnswer = ({
  children,
  description,
  image,
  icon: Icon,
  keylineVariant = 1,
  isGender = false,
  ...rest
}) => {
  const styles = useStyles();
  const hasDescription = !!description;

  return (
    <button
      css={styles.btn({ keylineVariant, hasDescription, isGender })}
      {...rest}
    >
      <span>
        {Icon && <Icon />}
        {children}
      </span>
      {description && <span css={styles.description}>{description}</span>}
      {image && (
        <img
          width={hasDescription ? '33%' : '30%'}
          css={styles.image}
          alt=""
          src={image}
        />
      )}
    </button>
  );
};

export default ButtonAnswer;
