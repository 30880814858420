import { createContext, useCallback, useContext, useState } from 'react';

export const AccessTokenContext = createContext();

export const AccessTokenProvider = ({ children }) => {
  const [token, setToken] = useState({});

  const setTokenID = useCallback(accesstoken => {
    setToken(accesstoken);
  }, []);

  return (
    <AccessTokenContext.Provider
      value={{
        token,
        setTokenID,
      }}
    >
      {children}
    </AccessTokenContext.Provider>
  );
};

export const useAccessTokenContext = () => useContext(AccessTokenContext);
