import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors, breakpoints, spacing } = useTheme();

  return {
    link: css`
    font-size: 16px;
      color: ${colors.white};
      font-weight: 400;
      text-align: center;
      padding-top: ${spacing.xs};

      ${breakpoints.m} {
        font-size: 18px;
      }

      button {
        display: inline-block;
        padding: 0 3px;
      }
    `,
    externalLink: css`
      color: ${colors.white};
      font-weight: 400;
      font-size: 16px;
      padding-top: ${spacing.xs};
      display: inline-block;
      border: 1px solid transparent;
      max-width: 100%;
      width: 100%;
      margin: 0 auto;
      text-align: center;

      &:focus {
        outline: 0;
        border: 1px solid ${colors.white};
      }

      ${breakpoints.m} {
        font-size: 18px;
      }
    `,
    OnboardingWrapper: css`
      margin: 1.5rem 0 ${spacing.s};
    `,
    daysLink: css`
      text-transform: lowercase;
    `,
    footballLink: css`
      text-transform: capitalize;
    `,
  };
};
