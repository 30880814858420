import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, colors, spacing } = useTheme();

  return {
    link: (isMobile, isInline) => css`
      text-align: center;
      text-transform: uppercase;
      color: ${colors.blue};
      font-size: 0.875rem;

      ${breakpoints.l} {
        display: ${isInline ? 'inline-block' : 'block'};
        font-size: ${spacing.s};
      }

      ${isMobile &&
      css`
        display: ${isInline ? 'inline-block' : 'block'};
      `};
    `,
    directionSpace: css`
      padding-left: ${spacing.xs};
      font-size: 0.875rem;
    `,
    linkMobile: css`
      // since geo location is not working
      display: none;
    `,
  };
};
