import PropTypes from 'prop-types';
import React, { useLayoutEffect, useRef } from 'react';

import MapService from '../../utils/MapService';

const MapPageService = ({
  height = '100%',
  width = '100%',
  pushPins,
  mapOptions = {},
  selectedId,
  ...rest
}) => {
  const mapContainer = useRef(null);
  const map = useRef(null);

  useLayoutEffect(() => {
    const currentMap = map.current;

    if (mapContainer.current !== null) {
      map.current = new MapService(
        {
          container: mapContainer.current,
          ...mapOptions,
        },
        pushPins
      );
    }

    return () => {
      currentMap?.reset();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useLayoutEffect(() => {
    if (!map.current) {
      return;
    }

    map.current.removePins();
    map.current.addPins(pushPins);
  }, [pushPins]);

  useLayoutEffect(() => {
    if (!map.current) {
      return;
    }

    map.current.setSelected(selectedId);
  }, [selectedId]);

  return (
    <div
      ref={mapContainer}
      style={{ height: height, width: width }}
      {...rest}
    ></div>
  );
};

MapPageService.prototype = {
  height: PropTypes.string,
  width: PropTypes.string,
  pushPins: PropTypes.object,
  mapOptions: PropTypes.object,
  selectedId: PropTypes.number,
};

export default MapPageService;
