/** @jsxImportSource @emotion/react */
import { recommendationsPageAnalytics } from '../../analyticsConsts';
import { CLUB } from '../../const';
import Button from '../Button';
import useStyles from './styles';

const { MO_RECOMMENDED_CLUB_fURTHER_AWAY, MO_RECOMMENDED_VENUE_fURTHER_AWAY } =
  recommendationsPageAnalytics;

const MoreResultsSection = ({ onClick, selectedFootballType }) => {
  const styles = useStyles();

  return (
    <div css={styles.footer}>
      <div css={styles.textWrapper}>
        <div css={styles.mainText}>Not found something you fancy yet? </div>

        <Button
          id={
            selectedFootballType === CLUB
              ? MO_RECOMMENDED_CLUB_fURTHER_AWAY
              : MO_RECOMMENDED_VENUE_fURTHER_AWAY
          }
          css={styles.cta}
          onClick={onClick}
          primary
        >
          Find {selectedFootballType === CLUB ? 'clubs' : 'venues'} further away
        </Button>
      </div>
    </div>
  );
};

export default MoreResultsSection;
