/** @jsxImportSource @emotion/react */
import PropTypes from "prop-types";
import { useMemo } from 'react';

import { EMPTY_STRING } from '../../const';
import useMediaQuery from '../../hooks/useMediaQuery';
import useTheme from '../../hooks/useTheme';
import Button from '../Button';
import ContactDetails from '../ContactDetails';
import { Directions } from '../Icons';
import LinkDirection from '../LinkDirection';
import Map from '../MapPageService';
import Text from '../Text';
import useStyles from './styles';

const ContactMap = ({
  contact,
  contactAddress,
  title,
  titleHeader,
  subTitle,
  locationDetails,
  sourceLoction,
  onClick,
  centername,
  isVenueLocation = false,
  providerName,
  ...rest
}) => {
  const styles = useStyles();
  const isDesktop = useMediaQuery('screen and (min-width: 768px)');
  const { colors } = useTheme();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const locationDetailsObject = useMemo(() => [locationDetails], [
    locationDetails.latitude,
    locationDetails.longitude,
  ]);

  return (
    <div css={styles.base} className="contactMap">
      <ContactDetails
        title={titleHeader}
        subTitle={subTitle}
        contactDetails={contact}
        isVenueLocation={isVenueLocation}
        onClick={onClick}
      >
        <span css={styles.wrapText}>{contactAddress}</span>
      </ContactDetails>
      {locationDetails && (
        <div css={styles.locationDetailsWrapper}>
          <Text css={styles.sectionTitle} as="h3" size="6">
            {providerName? providerName:centername? centername.toLowerCase() : title?.toLowerCase()} location
          </Text>
          <Button.Link
            css={styles.link}
            aria-label="click here to redirect to bing map"
            to={`https://bing.com/maps?Rtp=pos${
              sourceLoction
                ? '.' + sourceLoction.latitude + '_' + sourceLoction.longitude
                : EMPTY_STRING
            }~pos.${locationDetails?.latitude}_${locationDetails?.longitude}~`}
            isExternal
            {...rest}
          >
            <Map
              pushPins={locationDetailsObject}
              mapOptions={{ zoom: 2 }}
              css={styles.map}
            />
          </Button.Link>
          <div css={styles.detailsWrapper}>
            <div css={styles.addressWrapper}>
              <Text as="h4" size="6">
                Address
              </Text>
              <Text as="p">{contactAddress}</Text>
            </div>
            {!isDesktop && (
              <div>
                <LinkDirection
                  {...locationDetails}
                  primary
                  css={styles.locationLink}
                  latitude={locationDetails.latitude}
                  longitude={locationDetails.longitude}
                  name={title}
                  icon={Directions}
                  iconColor={colors.white}
                  existingUrl={`https://bing.com/maps?rtp=pos${
                    sourceLoction
                      ? '.' +
                        sourceLoction.latitude +
                        '_' +
                        sourceLoction.longitude
                      : EMPTY_STRING
                  }~pos.${locationDetails?.latitude}_${
                    locationDetails?.longitude
                  }~`}
                  aria-label={`Directions to ${title} - opens in a new window`}
                >
                  Directions
                </LinkDirection>
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

ContactMap.prototype = {
  contact: PropTypes.object,
  contactAddress: PropTypes.string,
  title: PropTypes.string,
  titleHeader: PropTypes.string,
  subTitle: PropTypes.string,
  locationDetails: PropTypes.object,
  sourceLoction: PropTypes.object,
  onClick: PropTypes.func,
  centername: PropTypes.string,
  isVenueLocation: PropTypes.bool,
  providerName: PropTypes.string,
}

export default ContactMap;
