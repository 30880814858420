/** @jsxImportSource @emotion/react */
import { useEffect, useRef, useState } from 'react';

import { venueDetailsPageAnalytics } from '../../analyticsConsts';
import { CATEGORY_ICONS, EMPTY_STRING } from '../../const';
import { useOnboardingDataContext } from '../../context/OnboardingData';
import { usePreferencesContext } from '../../context/preferences';
import fullBookingDataLayer from '../../utils/fullBookingDataLayer';
import idFromString from '../../utils/idFromString';
import ExternalLink from '../ExternalLink';
import { ExternalLinkIcon } from '../Icons';
import Image from '../Image';
import Text from '../Text';
import useStyles from './styles';

const { VENUE_SESSION_CARD_TITLE, VENUE_SESSION_CARD_CTA_BOOKING } =
  venueDetailsPageAnalytics;

const formatTime = date => {
  const hours = date.getHours();
  const ampm = hours >= 12 ? 'pm' : 'am';
  const getMinute = ('0' + date.getMinutes()).slice(-2);
  const minutes = getMinute > 0 ? `:${getMinute}` : EMPTY_STRING;
  return `${hours % 12 || 12}${minutes} ${ampm}`;
};

const baseEfUrl = 'https://faevents.thefa.com/Book?SessionID=';

const getSessionText = percentageAvailable => {
  const availableSlots = parseInt(percentageAvailable);
  if (availableSlots > 29 && availableSlots <= 100) return 'Slots available';
  else if (availableSlots >= 19 && availableSlots <= 29)
    return 'Limited slots available!';
  else if (availableSlots > 0 && availableSlots < 19)
    return 'Final slots available!';
  else return 'session fully booked';
};

const SessionCard = ({
  index,
  SessionId,
  SessionName,
  FootballType,
  Gender,
  AgeGroup,
  Price,
  SessionDateTime,
  Category,
  SessionPercentageAvailable,
  AlternateBookingUrl,
  isMiniOnboarding,
  venueTitle,
  CategoryTypeId,
  logoDetails,
  footballtype,
  isDesktop,
}) => {
  const styles = useStyles();
  const categoryStringId = idFromString(Category);
  const [spaceAvailableText, setSpaceAvailableText] = useState();
  const [deskLogo, setDeskLogo] = useState();
  const [mobLogo, setMobLogo] = useState();
  const categoryHasIcon = CATEGORY_ICONS.includes(categoryStringId);
  const [sessionDateTimeValue, setSessionDateTimeValue] = useState();
  const { preferences } = usePreferencesContext();
  const { Age: age } = preferences;
  const { OnboardingContext } = useOnboardingDataContext();

  useEffect(() => {
    const spaceAvailable = getSessionText(SessionPercentageAvailable);
    setSpaceAvailableText(spaceAvailable);
    setSessionDateTimeValue(formatTime(new Date(SessionDateTime)));
  }, [SessionPercentageAvailable, SessionDateTime]);

  const isFullyBooked = spaceAvailableText === 'session fully booked';
  let desktopLogoRef = useRef();
  let mobLogoRef = useRef();

  useEffect(() => {
    if (logoDetails) {
      logoDetails?.session?.map(event => {
        const categoryId = event?.sessionId;
        if (parseInt(categoryId) === CategoryTypeId) {
          desktopLogoRef.current = event?.images?.desktop?.src;
          mobLogoRef.current = event?.images?.mobile?.src;
          setDeskLogo(desktopLogoRef.current);
          setMobLogo(mobLogoRef.current);
        }
        return categoryId;
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [logoDetails, CategoryTypeId]);

  const sitecoreLogo = isDesktop ? deskLogo : mobLogo;

  return (
    <div css={styles.sessionCardWrapper}>
      <div css={styles.container}>
        <div css={styles.base(isFullyBooked, isMiniOnboarding)}>
          <div css={styles.titleWrapper}>
            <Text
              id={`${VENUE_SESSION_CARD_TITLE}-${index}`}
              as="h4"
              size="6"
              css={styles.title}
            >
              {SessionName && SessionName.toString().toLowerCase()}
            </Text>
            {!isMiniOnboarding && (
              <Text css={styles.logoWrapper}>
                <Image
                  css={styles.image}
                  src={
                    sitecoreLogo
                      ? sitecoreLogo
                      : `/images/desktop/initiativeLogo/${
                          categoryHasIcon ? categoryStringId : 'ef-logo'
                        }.svg`
                  }
                  alt="category logo"
                />
              </Text>
            )}
          </div>
          <div css={styles.infoWrapper}>
            <label htmlFor="SESSION_TYPE">
              Session Type
              <Text id="SESSION_TYPE">{FootballType}</Text>
            </label>
            <label htmlFor="PRICE" css={styles.priceInfo}>
              Price
              <Text id="PRICE">
                {Price === 0 ? 'Free' : `£${Price.toFixed(2)}`}
              </Text>
            </label>
          </div>
          <div css={[styles.infoWrapper, styles.ageGroupWrapper]}>
            <label htmlFor="AGE_GROUP">
              Age
              <Text id="AGE_GROUP">{AgeGroup}</Text>
            </label>
            <label htmlFor="GENDER_GROUP">
              Gender
              <Text id="GENDER_GROUP">{Gender}</Text>
            </label>
            <label htmlFor="TIME">
              Time
              <Text id="TIME">{sessionDateTimeValue}</Text>
            </label>
          </div>
          <div css={styles.linkWrapper}>
            <ExternalLink
              id={`${VENUE_SESSION_CARD_CTA_BOOKING}-${index}`}
              href={`${
                AlternateBookingUrl
                  ? AlternateBookingUrl
                  : `${baseEfUrl}${SessionId}`
              }`}
              onClick={() => {
                fullBookingDataLayer({
                  event_name: 'cta_primary_click',
                  eventCategory: venueTitle,
                  eventAction: 'click - Full details & Booking',
                  eventLabel: SessionName,
                  eventparameter1: age,
                  eventparameter2: Category,
                  eventparameter3: OnboardingContext.gender_group,
                  isVenueDetails: true,
                  Age_group: age,
                  Category_type: Category?.toLowerCase(),
                  Gender_group: OnboardingContext.gender_group,
                  Venue_session: SessionName?.toLowerCase(),
                  Venue_name: venueTitle?.toLowerCase(),
                  link_text: 'full details & booking',
                  price: `${Price.toFixed(2)}`,
                  football_type: footballtype
                    ? footballtype?.toString()?.toLowerCase()
                    : FootballType
                    ? FootballType?.toLowerCase()
                    : '',
                });
              }}
              aria-label={`Full details & booking for ${SessionName} - opens in a new window`}
              css={styles.fullBooking}
            >
              Full details & booking{' '}
              <ExternalLinkIcon css={styles.externalLink} width="24" />
            </ExternalLink>
          </div>
        </div>
        <div
          css={styles.sessionBookedWrapper(!isFullyBooked, isMiniOnboarding)}
        >
          <Text css={styles.sessionText(isFullyBooked)}>
            {spaceAvailableText}
          </Text>
        </div>
      </div>
    </div>
  );
};

export default SessionCard;
