import { DISABILITY_TYPE_ID, MYSELF, SOMEONE_ELSE } from '../../../const';

export default [
  {
    id: 'e1f34caf-2e24-4eb6-8134-1c27fec41aa4',
    questionOrder: 7,
    readableId: DISABILITY_TYPE_ID,
    type: 'multiple',
    title: {
      [MYSELF]: 'Are you looking for disability options?',
      [SOMEONE_ELSE]: 'Are they looking for disability options?',
    },
    helpText:
      'Choose one or more of the options below for football tailored to disabilities',
    answers: [
      { answerId: 1, value: 'Amputee' },
      { answerId: 2, value: 'Blind' },
      { answerId: 3, value: 'Cerebral Palsy' },
      { answerId: 4, value: 'Deaf/Hearing Impaired' },
      { answerId: 5, value: 'Downs syndrome' },
      { answerId: 6, value: 'Dwarf' },
      { answerId: 7, value: 'Frame' },
      { answerId: 8, value: 'Learning Disability' },
      { answerId: 9, value: 'Mental Health' },
      { answerId: 10, value: 'Pan-Disability' },
      { answerId: 11, value: 'Partially Sighted' },
      { answerId: 12, value: 'Powerchair' },
      { answerId: 13, value: 'Autism' },
    ],
    ageRanges: [
      {
        min: 0,
        max: 0,
        nextQuestionId: '703663f0-3c99-4c4e-ac80-62184ad0546f',
      },
    ],
  },
];
