const normaliseSessionLogo = apiData => {
  const elements = apiData.elements.map(data => {
    return {
      elements: data?.fields,
    };
  });

  return {
    FA: elements.map((fields, index) => {
      return {
        id: index + 1,
        description: fields?.elements?.Description?.value,
        href: fields?.elements?.CTA_Link.value.href,
        sessionId: fields?.elements?.SessionType_ID?.value,
        initiativeLogo: {
          desktop: {
            src: fields?.elements?.Desktop_Image.value.src,
            height: fields?.elements?.Desktop_Image.value?.height,
            width: fields?.elements?.Desktop_Image.value?.width,
          },
          mobile: {
            src: fields?.elements?.Mobile_Image.value.src,
            height: fields?.elements?.Mobile_Image.value?.height,
            width: fields?.elements?.Mobile_Image.value?.width,
          },
        },
      };
    }),
    session: elements.map((fields, index) => {
      return {
        id: index + 1,
        sessionName: fields?.elements?.SessionName?.value,
        sessionId: fields?.elements?.SessionType_ID?.value,
        images: {
          desktop: {
            src: fields?.elements?.Desktop_Logo?.value?.src,
          },
          mobile: {
            src: fields?.elements?.Mobile_Logo?.value?.src,
          },
        },
      };
    }),
  };
};

export default normaliseSessionLogo;
