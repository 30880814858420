/** @jsxImportSource @emotion/react */

import { CompassRounded } from '../Icons';
import Link from '../Link';
import Text from '../Text';
import useStyles from './styles';

const MoreCard = ({ to, label, analyticsId, onClick }) => {
  const styles = useStyles();

  return (
    <div css={styles.moreCard} tabIndex="0">
      <Link id={analyticsId} to={to} css={styles.moreLink} onClick={onClick} tabIndex="-1">
        <CompassRounded />
        <Text>{label}</Text>
      </Link>
    </div>
  );
};

export default MoreCard;
