import { useEffect } from "react";

export default () => {
  useEffect(() => {
    const previousValue = document.body.style.overflow || "auto";

    document.body.style.overflow = "hidden";

    return () => {
      document.body.style.overflow = previousValue;
    };
  }, []);
};
