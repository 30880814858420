import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { typography, breakpoints, screenSizes, spacing } = useTheme();

  return {
    base: css`
      height: 100%;
      display: grid;
      grid-template-rows: auto auto auto;
      grid-template-columns: 100%;
    `,
    hero: css`
      background-position: center ${spacing.m};

      &::before {
        ${breakpoints.m} {
          top: 15vh;
        }

        ${breakpoints.xl} {
          top: 6vh;
        }
      }

      h1 {
        ${typography.headings[4].mobile};
        max-width: 90%;
        font-size: 42px;

        ${breakpoints.m} {
          ${typography.headings[3].desktop};
          font-size: 48px;
          max-width: 100%;
          margin: auto;
        }
      }

      grid-template-rows: 82% 18%;

      ${breakpoints.m} {
        margin-bottom: 7rem;
        grid-template-rows: 80% 20%;
      }

      ${breakpoints.xl} {
        margin-bottom: 9rem;
        grid-template-rows: 76% 24%;
        h1 {
          max-width: 100%;
          font-size: 48px;
        }
      }

      @media screen and (min-width: 1440px) {
        margin-bottom: 17rem;
      }
    `,
    wrapper: css`
      width: 90vw;
      max-width: 390px;
      margin: calc(100vh - 35vh) auto 0;
      padding: ${spacing.s} 0;

      @supports (-webkit-overflow-scrolling: touch) {
        margin: calc(100vh - 26vh) auto 0;
      }

      ${breakpoints.m} {
        max-width: ${screenSizes.m};
        margin-top: calc(100vh - 22vh);
      }

      ${breakpoints.xl} {
        max-width: 1196px;
        margin-top: 68vh;
        padding: 0;
        margin: calc(100vh - 22vh) auto 0;
      }

      @media screen and (min-width: 1440px) {
        margin: calc(100vh - 40vh) auto 0;
      }
    `,
    visibility: length => css`
      visibility: ${length > 0 ? 'visible' : 'hidden'};
    `,
    displayMLData: css`
      display: none;
    `,
  };
};
