import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

const faEventsCrop = '/images/common/fa-events-crop.svg';

export default () => {
  const { breakpoints, colors, spacing, typography, borderRadius } = useTheme();

  return {
    base: css`
      height: 100%;
      display: grid;
      grid-template-rows: auto auto;
      grid-template-columns: 100%;
    `,
    hero: css`
      h1 {
        ${typography.headings[4].mobile};

        ${breakpoints.m} {
          ${typography.headings[3].desktop};
        }
      }
    `,
    top: css`
      // display: flex;
      // align-items: center;
    `,
    middle: css`
      padding-top: ${spacing.xs};
      margin-top: ${spacing.xs};
      display: grid;
      grid-gap: ${spacing.m};

      ${breakpoints.xl} {
        grid-template-columns: auto max(325px);
      }
    `,
    wrapper: css`
      padding-top: ${spacing.s};
      margin-top: ${spacing.xs};
      display: grid;
      grid-gap: ${spacing.s};

      ${breakpoints.xl} {
        padding-top: 0;
        grid-template-columns: repeat(2, 1fr);
        grid-gap: 1.5rem;
      }
    `,
    title: css`
      font-size: 1.75rem;
      color: ${colors.navy};
      position: relative;
      text-decoration: underline ${colors.red} 2px;
      -webkit-text-decoration-line: underline;
      -webkit-text-decoration-color: ${colors.red};
      -webkit-text-decoration-style: solid;
      -webkit-text-decoration-thickness: 2px;
      margin: ${spacing.s} 0;

      ${breakpoints.l} {
        ${typography.headings[4].desktop};
        margin: 0;
      }
    `,
    rightContent: css`
      border-top: ${borderRadius.s} solid ${colors.red};
      padding: ${spacing.s};
      background-color: ${colors.lightGrey};
    `,
    wrapText: css`
      white-space: pre-wrap;
    `,
    venueFacilitiesWrapper: css`
      padding: ${spacing.xs} 0;
      ${breakpoints.m} {
        padding: 0;
      }
    `,
    eventsWrapper: css`
      margin-top: 1.5rem;
      border: 1px solid ${colors.lightGrey};
      border-radius: ${borderRadius.l};
      padding: 1.5rem;
      padding-bottom: ${spacing.xl};
      background: url(${publicPath(faEventsCrop)}) bottom right no-repeat;

      ${breakpoints.m} {
        padding-left: ${spacing.m};
        padding-right: ${spacing.m};
        padding-bottom: 5rem;
      }
    `,
    eventsTitle: css`
      margin-bottom: ${spacing.s};
    `,
    venueDescription: css`
      color: ${colors.darkGrey};
     
      ${breakpoints.l} {
        max-width: 60%;
      }
    `,
    events: css`
      display: flex;
      flex-wrap: wrap;

      > div {
        width: 100%;
        margin-bottom: 1.5rem;

        ${breakpoints.m} {
          margin-right: ${spacing.s};
          margin-bottom: 0;
          flex: 1;
          max-width: 50%;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    `,
  };
};
