import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors, spacing, breakpoints } = useTheme();

  return {
    panel: css`
      margin-top: 20vh;
    `,
    title: css`
      font-size: 1.75rem;
      line-height: 1.625rem;
      text-align: center;
      color: ${colors.navy};
      margin: auto;
      margin-bottom: ${spacing.s};
      max-width: 80%;

      ${breakpoints.l} {
        font-size: 1.75rem;
        line-height: 1.625rem;
      }
    `,
    btn: css`
      text-transform: uppercase;
      margin-bottom: ${spacing.xs};
    `,
    linkBtn: css`
      margin: ${spacing.s} auto 0;
      text-decoration: none;
      font-size: 0.875rem;
      font-weight: normal;
    `,
  };
};
