import { css } from '@emotion/react';

import { SCROLL_UP } from '../../const';
import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const {
    screenSizes,
    typography,
    breakpoints,
    spacing,
    colors,
    heights,
    borderRadius,
  } = useTheme();

  const getOverlayImagePath = (breakpoint, variation) =>
    publicPath(`/images/${breakpoint}/overlays/${variation}.svg`);

  return {
    hero: ({ mobile, desktop }, overlayVariation, scrollDirection) => {
      return css`
        display: grid;
        align-items: end;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        grid-template-rows: minmax(75vh, 350px) auto;
        align-items: center;
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 30%,
            rgba(1, 30, 65) 80%,
            rgba(1, 30, 65) 100%
          ),
          url('${mobile.src}') center center no-repeat;
        background-size: cover;

        align-content: space-around;
        padding: 0 ${spacing.s};
        margin-top: ${scrollDirection === SCROLL_UP
          ? 0
          : heights.mainNav.mobile};

        &::before {
          content: '';
          position: absolute;
          top: 30vh;
          left: 0;
          right: 60vh;
          width: 100%;
          height: Calc(100vw - 72vw);
          background: url(${getOverlayImagePath('mobile', overlayVariation)})
            left 0 no-repeat;
          background-size: cover;
          z-index: 1;
        }

        ${breakpoints.l} {
          display: block;
          background: url(${desktop.src}) 0 0 no-repeat;
          background-size: cover;
          background-position: top center;
          padding-top: ${spacing.l};
          margin-top: 0;

          &::before {
            top: 37vh;
            left: 0;
            width: 100%;
            // height: Calc(100vw - 60vw);
            height: 80vh;
            background: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0) 20%,
                rgba(1, 30, 65) 75%,
                rgba(1, 30, 65) 100%
              ),
              url(${getOverlayImagePath('desktop', overlayVariation)}) 0 0
                no-repeat;
            background-size: cover, contain;
            z-index: 1;
            outline: 0;

            ${breakpoints.l} {
              top: calc(100vh - 75vh);
            }
          }

          &::after {
            background: right 0 no-repeat;
            background-image: linear-gradient(
                to bottom,
                rgba(0, 0, 0, 0) 40%,
                rgba(1, 30, 65) 85%,
                rgba(1, 30, 65) 100%
              ),
              url(${publicPath(
                '/images/desktop/recommendations/keyline-provider.svg'
              )}),
              url(${publicPath('/images/common/play-watermark.svg')});
            background-position: right 20vh, right 20vh;
            background-repeat: no-repeat, no-repeat;
          }
        }
      `;
    },
    providerWrapper: css`
      display: flex;
      justify-content: center;
      grid-row-gap: 2.6rem;
    `,
    infoWrapper: (hasPadding, titleHeight, isvenue) => css`
      position: relative;
      max-width: 100%;
      margin-bottom: 4rem;

      ${breakpoints.m} {
        display: grid;
        grid-row-gap: ${spacing.xs};
        ${isvenue === 'false'
          ? css`
              margin-bottom: 5rem;
            `
          : css`
              margin: 0 auto calc(100vh - 88vh) auto;
            `}
      }

      ${breakpoints.l} {
        margin-top: calc(100vh - 24rem);
        // if title has 2 lines
        ${titleHeight < 550 &&
        css`
          margin-top: calc(100vh - 24rem);
        `}
      }

      ${breakpoints.xl} {
        top: 0;
        grid-template-columns: auto;
        width: 75%;
        padding: ${borderRadius.s};
      }
    `,
    textWrapper: (hasLoaded, isVenue) => css`
      @media screen and (min-width: 425px) {
        ${isVenue === 'true' &&
        css`
          margin-bottom: 4rem;
        `}
      }
      position: relative;
      z-index: 1;
      align-self: end;
      opacity: 0;
      transition: opacity 0.5s;
      width: 90vw;
      max-width: 390px;
      margin: 0 auto;

      ${hasLoaded &&
      css`
        opacity: 1;
      `}

      &.fadeOut {
        opacity: 0.3;
      }

      ${breakpoints.m} {
        max-width: ${screenSizes.xl};
        margin: 0 auto;
      }
    `,
    mainText: css`
      ${typography.headings[3].mobile};
      text-transform: capitalize;
      z-index: 2;
      text-align: center;
      position: relative;
      color: ${colors.white};
      transition: opacity 0.5s;
      padding-bottom: 6px;
      overflow: hidden;
      text-overflow: ellipsis;
      display: -webkit-box;
      -webkit-line-clamp: 2; /* number of lines to show */
      -webkit-box-orient: vertical;
      max-width: 95%;
      margin: 0 auto;

      ${breakpoints.l} {
        ${typography.headings[1].desktop};
        max-width: 50%;
        padding-bottom: 7px;
      }
    `,
    secondaryText: css`
      color: ${colors.white};
      margin-top: 1.8rem;

      ${breakpoints.l} {
        margin-top: 0.7rem;
      }
    `,
    distance: css`
      margin-top: ${spacing.s};
      padding: 0;
      width: auto;
      color: ${colors.white};
      background: none;
      font-weight: normal;

      &::before {
        content: '|';
        padding: 0 ${spacing.xs};
      }

      &:not(:disabled):hover,
      &:not(:disabled):focus {
        background: none;
      }
    `,
    address: distance => css`
      color: ${colors.white};
      letter-spacing: 0.02em;
      font-weight: normal;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      float: left;
      margin-top: ${spacing.s};
      max-width: ${distance ? '65%' : '95%'};
      font-size: 0.875rem;
      text-transform: capitalize;

      ${breakpoints.m} {
        max-width: 100%;
      }
    `,
    info: hasMoreInfo => css`
      ${typography.body.mobile};
      display: flex;
      justify-content: center;
      margin: ${spacing.xs} auto;
      font-size: 0.875rem;

      ${hasMoreInfo &&
      css`
        > p,
        > a {
          text-align: center;
          border-bottom: ${borderRadius.s} solid rgba(255, 255, 255, 0.2);
          padding-bottom: ${spacing.xs};
          font-size: 0.875rem;

          ${breakpoints.l} {
            font-size: ${spacing.s};
          }
        }
      `}

      &::before {
        content: '';
        position: absolute;
        left: Calc(100% - 61%);
        width: 70px;
        border-bottom: ${borderRadius.s} solid #e1261c;

        ${breakpoints.l} {
          left: Calc(100% - 55%);
        }
      }

      ${breakpoints.m} {
        margin: 0 auto;
        max-width: 85%;
      }
    `,
    moreInfo: hasMoreInfo => css`
      display: flex;
      justify-content: center;
      color: ${colors.white};
      text-transform: capitalize;

      > :first-of-type {
        margin-right: ${spacing.s};
        font-size: 0.875rem;

        ${breakpoints.l} {
          font-size: ${spacing.s};
        }
      }

      ${breakpoints.l} {
        display: grid;
        width: 40%;
        margin: 0 auto;

        ${hasMoreInfo &&
        css`
          grid-auto-flow: column;
          grid-template-columns: auto auto;
        `}
      }
    `,
    imageWrap: css`
      position: relative;
    `,
    backLink: css`
      color: ${colors.white};
      font-weight: normal;
      letter-spacing: 0.02em;
      text-decoration: none;

      &:focus {
        outline: none;
        span {
          text-decoration: underline;
          display: inline-block;
        }
      }
    `,
    backMobileLink: css`
      font-weight: normal;
      letter-spacing: 0.02em;
      text-decoration: none;
      width: 100%;
      height: 48px;
      line-height: 48px;
      background-color: ${colors.white};
      position: relative;
      color: ${colors.navy};
      padding-left: ${spacing.s};
      z-index: 1;
      margin-top: ${heights.mainNav.mobile};
    `,
    internalBackLink: css`
      text-align: left;
      line-height: 22px;
    `,
    arrowRotate: css`
      transform: rotate(180deg);
      margin-right: 5px;
      color: ${colors.navy};
    `,
  };
};
