/** @jsxImportSource @emotion/react */

import { Fragment, useEffect, useState } from 'react';

import { recommendationsPageAnalytics } from '../../analyticsConsts';
import { usePreferencesContext } from '../../context/preferences';
import useScrollFadeOut from '../../hooks/useScrollFadeOut';
import Button from '../Button';
import NoDisabilityResults from '../NoDisabilityResults';
import PanDisabilitySuggestion from '../PanDisabilitySuggestion';
import RecommendationCard from '../RecommendationCard';
import useStyles from './styles';

const PAGINATION_COUNT = 3;
const { CTA_VIEW_MORE_RECOMMENDATIONS, FOOTBALL_RECOMMENDATIONS_RESULT } =
  recommendationsPageAnalytics;

const RecommendationsList = ({
  recommendations,
  selectedFootballType,
  pageData,
}) => {
  const styles = useStyles();
  const [count, setCount] = useState(PAGINATION_COUNT);
  const [hasLoaded, setHasLoaded] = useState(false);
  const { preferences } = usePreferencesContext();
  const { Disabilityoption, DisabilityType } = preferences;
  const [isDisabilityResultsExist, setIsDisabilityResultsExist] =
    useState(false);
  const [noPanDisability, setNoPanDisability] = useState(false);

  useScrollFadeOut();

  useEffect(() => {
    setTimeout(() => {
      setHasLoaded(true);
    }, 300);
  }, []);

  const loadMore = () => setCount(recommendations.length);

  // check selected disbilities results are exist in API response.
  useEffect(() => {
    const isDisabilityResult = recommendations?.some(obj =>
      DisabilityType?.some(obj2 => obj.DisabilityId === obj2.DisabilityId)
    );
    setIsDisabilityResultsExist(isDisabilityResult);
  }, [DisabilityType, recommendations]);

  useEffect(() => {
    const noPanDisabilityType =
      !DisabilityType?.some(disable => disable.DisabilityId === 10) &&
      isDisabilityResultsExist &&
      recommendations.some(obj => obj.DisabilityId === 10);
    setNoPanDisability(noPanDisabilityType);
  }, [isDisabilityResultsExist, recommendations, DisabilityType]);

  const selectedPanDisability = recommendations?.filter(
    item => item.DisabilityId === 10
  );

  if (!recommendations || recommendations.length === 0) return null;

  return (
    <div
      data-test-id="list"
      id={FOOTBALL_RECOMMENDATIONS_RESULT}
      css={styles.recommendations(hasLoaded)}
    >
      {!isDisabilityResultsExist && Disabilityoption === 1 && (
        <NoDisabilityResults />
      )}
      {recommendations
        .slice(0, Disabilityoption === 2 ? count : recommendations.length)
        .map((item, index) => (
          <Fragment key={index}>
            {noPanDisability &&
              item.DisabilityId === 10 &&
              selectedPanDisability[0].FootballType === item.FootballType && (
                <PanDisabilitySuggestion />
              )}
            <RecommendationCard
              key={index}
              selectedFootballType={selectedFootballType}
              index={index + 1}
              introText={
                pageData?.find(
                  types => types.FootballType === item.FootballType
                )?.IntroText
              }
              {...item}
            />
          </Fragment>
        ))}
      {recommendations.length > count && Disabilityoption === 2 && (
        <div css={styles.loadMore}>
          <Button
            id={CTA_VIEW_MORE_RECOMMENDATIONS}
            primary
            css={styles.cta}
            onClick={loadMore}
          >
            View more recommendations
          </Button>
        </div>
      )}
    </div>
  );
};

export default RecommendationsList;
