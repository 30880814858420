/** @jsxImportSource @emotion/react */

import { Fragment } from 'react';
import { useHistory } from 'react-router';

import {
  EMPTY_STRING,
  RECOMMENDATION_PROVIDER,
  SELECTED_RECOMMENDATIONS_TYPE_ID,
} from '../../const';
import { useMLRecommendations } from '../../context/mlRecommendations';
import { usePreferencesContext } from '../../context/preferences';
import useStyles from '../ClubCard/styles';
import HiddenContent from '../HiddenContent';
import {Crest, Pin } from '../Icons';
import Link from '../Link';
import Text from '../Text';

const CenterCard = ({
  CentreId,
  analyticsId,
  CentreName,
  CrestImage,
  SessionCount,
  SessionPlayDays,
  SessionCartDto,
  ProviderCartDto,
  DistanceFromUserLocation,
  ageTitle,
  footballType,
  isMLCard = false,
  MlSessionCartDto,
  providerid,
  providerCard,
  pitchFinder,
  PitchFinderId,
}) => {
  const history = useHistory();
  const { preferences, setPreferences } = usePreferencesContext();
  const sessions = SessionCartDto
    ? SessionCartDto
    : ProviderCartDto;
  const providers = ProviderCartDto ? ProviderCartDto : EMPTY_STRING;
  const centersCard = SessionCartDto ? SessionCartDto : EMPTY_STRING;
  const styles = useStyles();
  const hasSessions = Array.isArray(sessions) && sessions.length > 0;
  const isCenter= true;

  const cardUrl = `${
    CentreId
      ? `/venue/${CentreId}`
      : providerid
      ? `/provider/${providerid}p${PitchFinderId}`
      : `/venue/${pitchFinder}`
  }`;

  const { setMLCard } = useMLRecommendations();
  const sessionProvider = centersCard && providers;

  const setRecommendationsPreference = e => {
    // we want to block the default link and only navigate
    // when we are sure the preferences have been set
    e.preventDefault();

    setPreferences({
      ...preferences,
      [SELECTED_RECOMMENDATIONS_TYPE_ID]: footballType,
    });

    // set clickable card is ML or not
    setMLCard(isMLCard);

    history.push(cardUrl);
  };

  return (
    <Link
      id={analyticsId}
      to={cardUrl}
      css={styles.base}
      onClick={setRecommendationsPreference}
    >
      <div css={styles.titleWrapper}>
        {CrestImage ? (
          <img css={styles.crest} src={CrestImage} alt="" />
        ) : (
          <Crest css={styles.crestDefault} />
        )}
        <Text as="h3" css={styles.title(isCenter)}>
          {CentreName}
        </Text>
      </div>
      <div css={styles.info}>
        {hasSessions && (
          <Fragment>
            <Text css={styles.teams(isCenter)}>
              <HiddenContent>
                {!providers && sessions
                  ? SessionCount === 1 && 'Suitable session '
                  : RECOMMENDATION_PROVIDER}
              </HiddenContent>
              {!providers || sessionProvider
                ? SessionCount === 1
                  ? `${sessions[0].OrganizationName}`
                  : `${SessionCount} suitable sessions`
                : RECOMMENDATION_PROVIDER}
            </Text>
            <Text css={styles.days}>
              <HiddenContent>Play on </HiddenContent>
              {SessionPlayDays}
            </Text>
            <Text css={styles.distance(isCenter)}>
              <HiddenContent>Distance </HiddenContent>
              <Pin />
              {DistanceFromUserLocation
                ? DistanceFromUserLocation
                : ProviderCartDto.DistanceFromUserLocation}{' '}
              Miles
            </Text>
          </Fragment>
        )}
      </div>
    </Link>
  );
};

export default CenterCard;
