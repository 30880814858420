/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

/** @jsxImportSource @emotion/react */
import { contactFormModalAnalytics } from '../../analyticsConsts';
import { HERE_LINK } from '../../const';
import ExternalLink from '../ExternalLink';
import { CircelTickIcon } from '../Icons';
import LinkButton from '../LinkButton';
import Text from '../Text';
import useStyles from './styles';

const { CONTACT_FROM_MESSAGE_CONFIRMATION } = contactFormModalAnalytics;

const ContactSuccess = ({ onClick }) => {
  const styles = useStyles();

  return (
    <div css={styles.base}>
      <div
        css={styles.confirmationHeader}
        id={CONTACT_FROM_MESSAGE_CONFIRMATION}
      >
        <CircelTickIcon />
        <Text as="h3" css={styles.title} size="7">
          Message sent
          <Text css={styles.subTitle}>
            <LinkButton css={styles.link} onClick={onClick}>
              Close
            </LinkButton>{' '}
            contact panel
          </Text>
        </Text>
      </div>
      <div css={styles.instructions}>
        <Text as="h3" css={[styles.title, styles.smallTitle]} size="7">
          What happens next
        </Text>
        <ul>
          <li>
            You should receive a reply from a club email address, not a personal
            email address
          </li>
          <li>
            Clubs are asked to ensure their invitation is to a venue the club
            regularly uses, during the relevant training hours and when other
            members are present
          </li>
          <li>
            You should be given the name and role of the person who is going to
            meet you and details of who else will be present, e.g. club
            committee members
          </li>
          <li>
            Clubs should always communicate through the parent/carer contact
            details for under-16s and should not ask for a child's mobile number
            or social media accounts
          </li>
          <li>
            Clubs are required to follow their safeguarding children policy and
            procedures at all times
          </li>
          <li>
            If you have a concern about the behaviour of a club/an individual
            connected to the club, you can report it to your local County FA
            (find County FA contact details{' '}
            <ExternalLink css={[styles.link, styles.linkHere]} href={HERE_LINK}>
              here
            </ExternalLink>
            )
          </li>
        </ul>
      </div>
    </div>
  );
};

ContactSuccess.prototype = {
  onClick: PropTypes.func,
};

export default ContactSuccess;
