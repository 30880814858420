const dayMapping = [
  'Sunday',
  'Monday',
  'Tuesday',
  'Wednesday',
  'Thursday',
  'Friday',
  'Saturday',
];

const monthMapping = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

const getSuffix = dayNumber => {
  if (dayNumber > 3 && dayNumber < 21) {
    return 'th';
  }

  switch (dayNumber % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};

export default date => {
  const dateObject = new Date(date);
  const weekDay = dayMapping[dateObject.getDay()];
  const day = dateObject.getDate();
  const month = monthMapping[dateObject.getMonth()];

  return `${weekDay} ${day}${getSuffix(dateObject.getDate())} ${month}`;
};
