import onboardingDataLayer from '../onboardingDataLayer';

export default data => {
  switch (true) {
    case JSON.parse(sessionStorage.getItem('ISMINIONBOARDING')):
      return onboardingDataLayer(data);
    case JSON.parse(sessionStorage.getItem('ISMINIONBOARDING')) === false:
      return onboardingDataLayer(data);
    default:
      return;
  }
};
