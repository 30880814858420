import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { spacing, colors, borderRadius, breakpoints, utility } = useTheme();

  return {
    base: css`
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      grid-gap: 10px;

      ${breakpoints.l} {
        justify-content: left;
        grid-gap: 0;
      }
    `,
    answer: isChecked => css`
      display: block;
      padding: 0.8rem 0;
      background-color: ${colors.lightGrey};
      color: ${colors.navy};
      cursor: pointer;
      border-radius: ${borderRadius.s};
      border: ${borderRadius.s} solid transparent;
      text-align: center;
      font-weight: 700;

      ${isChecked &&
      css`
        background-color: ${colors.darkGrey};
        color: ${colors.white};
      `}

      ${breakpoints.l} {
        margin: ${spacing.xs} 0 0 ${spacing.xs};
        padding: ${spacing.xs} ${spacing.m};
        height: 56px;
        line-height: 36px;
      }
    `,
    label: css`
      & > input[type='checkbox']:focus + span {
        border: ${borderRadius.s} solid ${colors.navy};
        border-radius: ${borderRadius.m};
        text-decoration: underline;
        background-color: ${colors.white};
      }

      & > input[type='checkbox']:checked:focus + span {
        border: ${borderRadius.s} solid ${colors.navy};
        border-radius: ${borderRadius.m};
        text-decoration: none;
        background-color: ${colors.darkGrey};
        color: ${colors.white};
      }

      & > input[type='checkbox']:hover + span {
        background-color: ${colors.greyShade1};
      }
      
      width: 90px;
      max-width: 100px;
      ${breakpoints.l} {
        flex: 1;
        flex-basis: 50%;
        text-align: center;
        width: 100%;
        max-width: 145px;
        margin: 7px 0 0 5px;  
      }
    `,
    checkbox: css`
      ${utility.checkboxHidden};
      outline: none;
    `,
  };
};
