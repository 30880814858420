import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, colors, spacing, borderRadius } = useTheme();

  return {
    middle: css`
      display: grid;
      border-top: 1px solid ${colors.lightGrey};
      padding: ${spacing.xs} 0;
    `,
    title: css`
      color: ${colors.navy};
      position: relative;
      margin: ${spacing.s} 0;
      font-size: 1.5rem;
    `,
    rightContent: css`
      border-top: ${borderRadius.s} solid ${colors.red};
      padding: ${spacing.s};
      background-color: ${colors.lightGrey};
    `,
    wrapText: css`
      white-space: pre-wrap;
    `,
    venueFacilitiesWrapper: css`
      padding: ${spacing.xs} 0;
    `,
    eventsTitle: css`
      margin-bottom: ${spacing.s};
    `,
    events: css`
      grid-gap: 0.3rem;
      display: flex;
      flex-wrap: wrap;
      ${breakpoints.m} {
        grid-gap: 0;
      }

      img {
        border-radius: ${borderRadius.l};
      }

      > div {
        width: 100%;
        margin-bottom: 1.5rem;

        ${breakpoints.m} {
          margin-right: ${spacing.m};
          margin-bottom: 0;
          flex: 1;
          max-width: 50%;

          &:last-of-type {
            margin-right: 0;
          }
        }
      }
    `,
  };
};
