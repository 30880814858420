/** @jsxImportSource @emotion/react */
import { useEffect, useReducer } from 'react';

import { AGE_ID, EMPTY_STRING } from '../../const';
import { useOnboardingDataContext } from '../../context/OnboardingData';
import ageGroupReducer from '../../reducers/ageGroup';
import getCategoryDataLayer from '../../utils/getCategoryDataLayer';
import getNextQuestionId from '../../utils/getNextQuestionId';
import idFromString from '../../utils/idFromString';
import NextNav from '../NextNav';
import useStyles from './styles';

const AgeGroupQuestion = ({
  readableId,
  answers,
  ageRanges,
  onAnswer,
  selectedAge,
  headerTitle,
}) => {
  const styles = useStyles();
  const [answersState, dispatch] = useReducer(ageGroupReducer, []);
  const selectedAnswer = JSON.parse(sessionStorage.getItem('ISVOLUNTEER'))
    ? answers?.map(item => item?.options)
    : answers.filter(item => item.age === parseInt(selectedAge, 10))[0].options;
  const { OnboardingContext, setOnboardingDataContext } =
    useOnboardingDataContext();

  const changeData = ageData => {
    switch (ageData) {
      case 'U6-U7':
        return ['U6,U7'];
      case 'U8-U10':
        return ['U8,U9,U10'];
      case 'U11-U12':
        return ['U11,U12'];
      case 'U13-U14':
        return ['U13,U14'];
      case 'U15-U16':
        return ['U15,U16'];
      case 'U17-U18':
        return ['U17,U18'];
      case 'Open Aged':
        return ['Open Aged'];
      default:
        return 'Open Aged';
    }
  };

  const onChange = event => {
    const ageData = event.target.value;
    const id = JSON.parse(sessionStorage.getItem('ISVOLUNTEER'))
      ? changeData(ageData)
      : event.target.value;
    if (event.target.checked) {
      dispatch({ type: 'add', payload: { id } });
    } else {
      dispatch({ type: 'remove', payload: { id } });
    }
  };

  useEffect(() => {
    setOnboardingDataContext({
      ...OnboardingContext,
      question: headerTitle,
      agegroup: answersState[0]?.AgeGroupId,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerTitle, answersState]);

  return (
    <div css={styles.base}>
      {selectedAnswer &&
        selectedAnswer.map(value => {
          const optionId = JSON.parse(sessionStorage.getItem('ISVOLUNTEER'))
            ? value
            : idFromString(value);
          const isChecked = !!answersState.find(answer =>
            JSON.parse(sessionStorage.getItem('ISVOLUNTEER'))
              ? answer.AgeGroupId.toString().split(',', 1)[0] ===
                value[0].split('-', 1)[0]
              : answer.AgeGroupId === value
          );

          return (
            <label css={styles.label} key={value}>
              <input
                css={styles.checkbox}
                type="radio"
                id={optionId}
                name={optionId}
                value={value}
                onClick={onChange}
              />
              <span css={styles.answer(isChecked)}>{value}</span>
            </label>
          );
        })}
      <NextNav
        disabled={answersState.length === 0}
        onClick={() => {
          onAnswer({
            answerData: {
              [readableId]: answersState.map(a => a.AgeGroupId).toString(),
              [JSON.parse(sessionStorage.getItem('ISVOLUNTEER'))
                ? AGE_ID
                : EMPTY_STRING]: JSON.parse(
                sessionStorage.getItem('ISVOLUNTEER')
              )
                ? answers?.filter(
                    item =>
                      answersState.map(a => a.AgeGroupId).toString() ===
                      item?.ageOptions.toString()
                  )[0]?.age
                : EMPTY_STRING,
            },
            nextQuestionId: getNextQuestionId({ ageRanges, selectedAge }),
          });
          getCategoryDataLayer({
            eventAction: `Step ${OnboardingContext.step} - Next CTA`,
            eventLabel: OnboardingContext.question,
            eventparameter1: `${OnboardingContext.age}${
              OnboardingContext.agegroup
                ? ' , ' + OnboardingContext.agegroup
                : ''
            }`,
            gender_group: OnboardingContext.gender_group,
            age_youth_group: OnboardingContext.agegroup,
            event_name: 'next_button_click',
          });
        }}
      />
    </div>
  );
};

export default AgeGroupQuestion;
