import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { breakpoints, colors, spacing } = useTheme();

  return {
    nestedLink: css`
      display: block;
      text-align: left;

      ${breakpoints.l} {
        &:hover {
          span {
            &::after {
              transform: translateX(0) rotate(-90deg);
              opacity: 1;
            }
          }
        }
      }
    `,
    children: isSubChild => css`
      position: relative;
      padding: 20px 0 12px 0;
      &:first-of-type {
        padding: 10px 0 12px 0;
      }
      border-bottom: 1px solid ${isSubChild ? 'transparent' : colors.greyShade1};

      ${breakpoints.l} {
        margin-right: 0.4rem;
        border-bottom: 0;
        padding: 20px 0 12px 0;
        &:first-of-type {
          padding: 20px 0 12px 0;
        }
        display: unset;
      }
    `,
    collapseBtn: css`
      border: 0;
      width: 44px;
      height: 30px;
      float: right;
      background: transparent;
      display: grid;
    `,
    subTitle: css`
      display: initial;
      color: ${colors.navy};
      background: transparent;
      text-decoration: none;
      font-size: 1.125rem;
      line-height: 1.625rem;
      font-weight: 500;
      text-transform: uppercase;
      position: relative;
      margin-bottom: 1rem;
      font-weight: normal;

      ${breakpoints.l} {
        display: flex;
        justify-content: flex-start;
        padding-bottom: 13px;
        color: ${colors.navy};
        span {
          &::after {
            content: '';
            background: url(${publicPath('/images/common/triangle-arrow.svg')})
              no-repeat center center;
            transform: translateX(-2rem) rotate(-90deg);
            transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
            opacity: 0;
            height: 20px;
            width: 20px;
            position: absolute;
            display: inline-block;
            z-index: 1;
            top: 2px;
          }
        }
        &:hover,
        &:focus {
          span {
            color: ${colors.blue};
            &::after {
              transform: translateX(0) rotate(-90deg);
              opacity: 1;
            }
          }
        }
      }
    `,
    link: css`
      color: ${colors.darkGrey};
      text-decoration: none;
      display: block;
      font-weight: normal;
      font-size: 18px;
      line-height: 26px;
      padding: 0.625rem 0.5rem 0.625rem 0;
      position: relative;
      color: ${colors.darkGrey};

      &:focus {
        color: ${colors.blue};
        span::after {
          content: '';
          background: url(${publicPath('/images/common/triangle-arrow.svg')})
            no-repeat center center;
          transform: translateX(-2rem) rotate(-90deg);
          transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
          opacity: 0;
          height: 20px;
          width: 20px;
          position: absolute;
          display: inline-block;
          z-index: 1;
          transform: translateX(0) rotate(-90deg);
          opacity: 1;
        }
      }

      span {
        position: relative;
        &::after {
          content: '';
          background: url(${publicPath('/images/common/triangle-arrow.svg')})
            no-repeat center center;
          transform: translateX(-2rem) rotate(-90deg);
          transition: transform 0.3s ease-in-out, opacity 0.3s ease-in-out;
          opacity: 0;
          height: 20px;
          width: 20px;
          position: absolute;
          display: inline-block;
          z-index: 1;
        }
      }

      ${breakpoints.l} {
        font-size: 0.875rem;
        line-height: 20px;
      }
    `,
    line: css`
      position: relative;
      
      ${breakpoints.l} {
        &::before {
          content: '';
          position: absolute;
          left: 0;
          width: 46px;
          z-index: 1;
          border-bottom: 1px solid ${colors.darkGrey};
          bottom: 0;
          z-index: 1;
          transition: width 0.4s ease-in-out;
        }

        &::after {
          content: '';
          border-bottom: 1px solid ${colors.lightBlue};
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
        }

        &:hover {
          background: transparent;
          color: ${colors.blue};
          text-decoration: none;

          &::before {
            width: 100%;
            border-bottom: 1px solid #0068b2;
          }
        }
      }
    `,
    externalArrow: css`
      font-size: ${spacing.xs};
      margin-left: ${spacing.xs};
    `,
    subChild: isSubChild => css`
      margin: 17px 0;
      border-top: 1px solid ${colors.greyShade1};
      padding: 16px 0 0 16px;

      animation: fadeInAnimation ease 3s 1 forwards;

      @keyframes fadeInAnimation {
        0% {
          opacity: ${isSubChild ? '0' : '1'};
        }
        100% {
          opacity: ${isSubChild ? '1' : '0'};
        }
      }

      ${breakpoints.l} {
        margin: 0;
        border-top: 1px solid transparent;
        padding: 0;
        animation: none;
      }
    `,
    collapseIcon: isOpened => css`
      width: 13px;
      height: 13px;
      position: relative;
      transform: translateY(0px) rotate(0deg);
      transition: 0.5s ease-in-out;
      cursor: pointer;
      margin: 0 auto;

      span {
        display: block;
        position: absolute;
        height: 2px;
        width: 100%;
        background: ${colors.red};
        border-radius: 9px;
        opacity: 1;
        right: 0;
        transform: translateY(0px) rotate(0deg);
        transition: 0.25s ease-in-out;

        &:nth-of-type(1) {
          top: 8px;
          transform-origin: right center;
        }

        &:nth-of-type(2) {
          top: 8px;
          transform: translateY(0px) rotate(90deg);
          transform-origin: center center;
        }

        ${isOpened &&
        css`
          &:nth-of-type(1) {
            opacity: 0;
          }
          &:nth-of-type(2) {
            transform: rotate(180deg);
          }
        `}
      }
    `,
  };
};
