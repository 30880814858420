import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { spacing, colors, breakpoints, borderRadius, utility, mediaQueries } =
    useTheme();

  return {
    link: css`
      display: flex;
      align-items: center;
      position: absolute;
      bottom: 0;
      right: 0;
      border: 1.5px solid transparent;
      color: ${colors.white};
      padding: 0.2rem;
      text-decoration: none;
      outline: 0;

      &:hover {
        outline: 0;
        text-decoration: underline;
      }

      &:focus:not(:hover) {
        color: ${colors.white};
      }

      &:disabled {
        ${utility.checkboxHidden}
      }

      svg {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-left: ${spacing.xs};
        transform: rotate(180deg);
      }

      ${breakpoints.l} {
        bottom: ${spacing.xl};
        right: 7rem;
        padding: 0.2rem;
        border: 1.5px solid transparent;

        &:focus:not(:hover) {
          border: 1.5px solid ${colors.white};
          border-radius: ${borderRadius.m};
          outline: 0;
          color: ${colors.white};
          text-decoration: underline;
        }
      }

      ${mediaQueries.zoomedIn} {
        bottom: ${spacing.xs};
        right: ${spacing.m};
        color: ${colors.navy};
        z-index: 2;

        &:hover {
          color: ${colors.blue};
        }
      }
    `,
  };
};
