export const EXPANDED = 'EXPANDED';
export const SHRUNK = 'SHRUNK';
export const STANDARD = 'STANDARD';
const EXPAND = 'EXPAND';
const EXPAND_MAP = 'EXPAND_MAP';
const SHRINK = 'SHRINK';
export const EXPAND_ACTION = { type: EXPAND };
export const EXPAND_MAP_ACTION = { type: EXPAND_MAP };
export const SHRINK_ACTION = { type: SHRINK };

export default (state, action) => {
  switch (action.type) {
    case EXPAND:
      if (state === EXPANDED) {
          return state;
      }

      if (state === SHRUNK) {
          return STANDARD;
      }

      return EXPANDED;
    case SHRINK:
      if (state === EXPANDED) {
        return STANDARD;
      }

      return state;
    case EXPAND_MAP:
      return SHRUNK;
    default:
      return state;
  }
};