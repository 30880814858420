/** @jsxImportSource @emotion/react */
import isempty from 'lodash.isempty';
import {
  Fragment,
  useEffect,
  useLayoutEffect,
  useReducer,
  useRef,
  useState,
} from 'react';
import { useHistory } from 'react-router-dom';

import { onBoardingModalAnalytics } from '../../analyticsConsts';
import {
  AGE_ID,
  CLUB,
  DONT_MIND,
  MO_PAGE_SIZE_VALUE,
  MYSELF,
  PAGE_SIZE,
  PLAY_WITH_ID,
  SEARCH_FOR_ID,
  SELECTED_FOOTBALL_TYPE_ID,
  SESSION_ID,
  SURFACE_TYPE,
  SURFACE_VALUES,
  TOTAL_STEPS,
} from '../../const';
import { useFilterPanelToggleContext } from '../../context/filterPanelToggle';
import { useMORecommendations } from '../../context/moRecommendations';
import { useNestedFilterPanelContext } from '../../context/nestedFilterPanel';
import { useOnboardingDataContext } from '../../context/OnboardingData';
import { usePreferencesContext } from '../../context/preferences';
import { useRecommendations } from '../../context/recommendations';
import useErrorBoundary from '../../hooks/useErrorBoundary';
import useLocalStorage from '../../hooks/useLocalStorage';
import useMediaQuery from '../../hooks/useMediaQuery';
import useModalOverflow from '../../hooks/useModalOverflow';
import useStorageFilters from '../../hooks/useStorageFilters';
import preferencesReducer from '../../reducers/preferences';
import {
  getMiniOnboardingRecommendations,
  getOnboardingQuestions,
  getRecommendations,
} from '../../services';
import getCategoryDataLayer from '../../utils/getCategoryDataLayer';
import uuid from '../../utils/uuid';
import ConfirmationQuestion from '../ConfirmationQuestion';
import { CircleArrow, CircleClose } from '../Icons';
import LinkButton from '../LinkButton';
import Loader from '../Loader';
import Modal from '../Modal';
import Question from '../Question';
import defaultPreferences from './DefaultPreferences';
import {
  filteredMiniOnboardingData,
  getCategoryName,
  getPayloadData,
  getRedirectUrl,
} from './MiniOnboarding';
import useStyles from './styles';

const { ONBOARDING_CTA_EXIT, ONBOARDING_CTA_PREVIOUS_QUESTION } =
  onBoardingModalAnalytics;

const OnboardingModal = ({ onClose, isVisible }) => {
  const styles = useStyles();
  const history = useHistory();
  const throwError = useErrorBoundary();
  const [questions, setQuestions] = useState([]);
  const [questionsHistory, setQuestionsHistory] = useState([]);
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [showOnboarding, setShowOnboarding] = useState(false);
  const [confirmationVisible, setConfirmationVisible] = useState(false);
  const [isSaving, setIsSaving] = useState(false);
  const [sessionId, setSessionId] = useState('');
  const [savedPreferences, dispatch] = useReducer(preferencesReducer, {});
  const { setPreferences, resetPreferences } = usePreferencesContext();
  const { setRecommendations, resetRecommendations } = useRecommendations();
  const { setMORecommendations, resetMORecommendations } =
    useMORecommendations();
  const { clearFiltersStorage } = useStorageFilters();
  const { resetFilterPanelToggle } = useFilterPanelToggleContext();
  const { resetNestedFilterPanel } = useNestedFilterPanelContext();
  const { getLocalItem } = useLocalStorage();
  const isIframe = JSON.parse(getLocalItem('ISIFRAME'));
  const { OnboardingContext } = useOnboardingDataContext();

  useEffect(() => {
    // Remove applied filters from session storage
    clearFiltersStorage();

    // Resetting contexts and local storage
    resetPreferences();
    resetRecommendations();
    resetMORecommendations();
    resetFilterPanelToggle();
    resetNestedFilterPanel();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isVisible]);

  const loader = useRef();

  const isNarrowScreen = useMediaQuery('screen and (max-height: 690px)');

  useModalOverflow();

  useEffect(() => {
    if (isVisible) {
      // Waiting a bit before transitioning
      setTimeout(() => {
        setShowOnboarding(true);
      }, 100);
    }
  }, [isVisible]);

  useEffect(() => {
    // Create session id
    setSessionId(uuid());
    getOnboardingQuestions( JSON.parse(sessionStorage.getItem('ISVOLUNTEER')))
      .then(({ data }) => {
        if (data) {
          const filteredData = filteredMiniOnboardingData(data);
          setQuestions(filteredData);
          // Add first question in questions history
          setQuestionsHistory([...questionsHistory, filteredData[0].id]);
        }
      })
      .catch(throwError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [JSON.parse(sessionStorage.getItem('ISVOLUNTEER'))]);

  const saveMiniOnboardingRecommendations = preferences => {
    const { SelectedFootballType } = preferences;
    getMiniOnboardingRecommendations(SelectedFootballType, preferences)
      .then(({ data }) => {
        if (data) {
          setMORecommendations(data[0], SelectedFootballType);
          setTimeout(() => {
            history.push('/results');
            setIsSaving(false);
          }, 500);
        }
      })
      .catch(throwError);
  };

  // Saves recommendations in context and local storage
  const saveRecommendations = preferences => {
    const { SelectedFootballType } = preferences;

    Promise.all([
      getRecommendations(SelectedFootballType, preferences).catch(throwError),
    ]).then(data => {
      if (data) {
        const recommendationRecords = data[0].data; // recommenation data
        setRecommendations(recommendationRecords, SelectedFootballType);

        setTimeout(() => {
          history.push('/recommendations');
          setIsSaving(false);
        }, 500);
      }
    });
  };

  const onAnswer = ({ answerData, nextQuestionId }) => {
    dispatch({ type: 'save', payload: answerData });

    // We reached the end of the onboarding. No questions left.
    if (!nextQuestionId) {
      setIsSaving(true);
      const onboardingPayload = getPayloadData(answerData);
      // Merge last answer data with current state
      const allPreferences = {
        ...savedPreferences,
        ...onboardingPayload,
        [SESSION_ID]: sessionId,
      };

      // Merge default values to send all properties to API request
      const preferencesWithDefault = {
        ...defaultPreferences(
          allPreferences[AGE_ID],
          allPreferences['FootballType']
        ),
        ...allPreferences,
      };

      const { FootballType } = preferencesWithDefault;

      const preferencesExtended = {
        ...preferencesWithDefault,
        // This helper property will make our lives easier to switch between
        // football types using the preferences context
        [SELECTED_FOOTBALL_TYPE_ID]:
          FootballType === DONT_MIND ? CLUB : FootballType,
        [SURFACE_TYPE]: SURFACE_VALUES.join(),
      };

      // Save recommendations (context & local storage)
      if (sessionStorage.getItem('ISMINIONBOARDING') === 'true') {
        const categoryName = getCategoryName(preferencesExtended);
        const updatedPreference = {
          ...preferencesExtended,
          Category: categoryName,
          [PAGE_SIZE]: MO_PAGE_SIZE_VALUE,
        };
        saveMiniOnboardingRecommendations(updatedPreference);
        // Save preferences (context & local storage)
        setPreferences(updatedPreference);
      } else {
        saveRecommendations(preferencesExtended);
        // Save preferences (context & local storage)
        setPreferences(preferencesExtended);
      }
    } else {
      // Get index of next question
      const nextQuestionIndex = questions.findIndex(
        question => question.id === nextQuestionId
      );
      setCurrentQuestionIndex(nextQuestionIndex);
      setQuestionsHistory([...questionsHistory, nextQuestionId]);
    }
  };

  const goBack = () => {
    if (questionsHistory.length > 1) {
      // pushing into data layer
      if (OnboardingContext) {
        getCategoryDataLayer({
          eventAction: `Step ${OnboardingContext.step} - Back CTA`,
          eventLabel: OnboardingContext.question,
          eventparameter1: `${OnboardingContext.age}${
            OnboardingContext.agegroup ? ' , ' + OnboardingContext.agegroup : ''
          }`,
          eventparameter2: OnboardingContext.disability,
          eventparameter3: OnboardingContext.location,
          location: OnboardingContext.location,
          gender_group: OnboardingContext.gender_group,
          event_name: 'back_button_click',
        });
      }
      const lastEntryIndex = questionsHistory.length - 1;
      const secondLastId = questionsHistory[questionsHistory.length - 2];

      // Get next question index to be shown (second last)
      const nextCurrentIndex = questions.findIndex(
        question => question.id === secondLastId
      );

      // Remove saved preference from upcoming question
      // We do this to ensure we only save values
      // of the most recent pathway and we are not keeping
      // old ones from previous pathways
      const currentQuestionReadableId = questions[nextCurrentIndex]?.readableId;
      dispatch({ type: 'remove', readableId: currentQuestionReadableId });

      // Update state with upcoming next question index
      setCurrentQuestionIndex(nextCurrentIndex);

      // Remove last entry from history
      const newHistory = questionsHistory.filter(
        (_, i) => i !== lastEntryIndex
      );
      // Update state with new history
      setQuestionsHistory(newHistory);
    }
  };

  const setDataLayer = (actionName, linkTextName, isExiting = false) => {
    if (!isempty(OnboardingContext)) {
      getCategoryDataLayer({
        eventAction: `Step ${OnboardingContext.step} - ${actionName}`,
        eventLabel: OnboardingContext.question,
        eventparameter1: `${OnboardingContext.age}${
          OnboardingContext.agegroup ? ' , ' + OnboardingContext.agegroup : ''
        }`,
        eventparameter2: OnboardingContext.disability,
        eventparameter3: OnboardingContext.location,
        location: OnboardingContext.location,
        gender_group: OnboardingContext.gender_group,
        link_text: `${linkTextName}`,
        event_name: `${
          actionName === 'Close icon'
            ? 'close_button_click'
            : 'cta_select_option_click'
        }`,
      });
    }
    if (isExiting) {
      const url = getRedirectUrl();
      if (url) {
        window.location.href = url;
        return;
      }
      history.push('/');
    }
  };
  // when we save we want to send the focus to the loader
  // so that it is announced to screen readers
  useLayoutEffect(() => {
    if (isSaving) {
      loader.current?.focus({ preventScroll: true });
    }
  }, [isSaving, loader]);

  return (
    <Modal
      isOpen={isVisible}
      onClose={() => {
        !isIframe && setConfirmationVisible(true);
      }}
      skipInitialFocus
    >
      <div css={styles.base(showOnboarding, isNarrowScreen)}>
        {confirmationVisible ? (
          <ConfirmationQuestion
            onClickYes={() => {
              setDataLayer('Exit - Yes', 'exit-yes', true);

              onClose();
            }}
            onClickNo={() => {
              setConfirmationVisible(false);
              setDataLayer('Exit - No', 'exit-no');
            }}
          />
        ) : (
          <Fragment>
            {isSaving ? (
              <Loader message="Finding football..." ref={loader} />
            ) : (
              <Fragment>
                <button
                  id={ONBOARDING_CTA_EXIT}
                  aria-label="Close"
                  css={styles.closeBtn}
                  onClick={() => {
                    setConfirmationVisible(true);
                    setDataLayer('Close icon');
                  }}
                >
                  {!isIframe && <CircleClose />}
                </button>
                {questions.length > 0 && (
                  <div css={styles.question}>
                    <Question
                      {...questions[currentQuestionIndex]}
                      onAnswer={onAnswer}
                      selectedAge={savedPreferences[AGE_ID]}
                      selectedGender={savedPreferences[PLAY_WITH_ID]}
                      searchFor={savedPreferences[SEARCH_FOR_ID] || MYSELF}
                      totalSteps={TOTAL_STEPS}
                      getPayloadData={getPayloadData}
                    />
                  </div>
                )}
                {questionsHistory.length > 1 && (
                  <LinkButton
                    id={ONBOARDING_CTA_PREVIOUS_QUESTION}
                    css={styles.backLink}
                    aria-label="Go to the previous question"
                    onClick={() => goBack()}
                  >
                    <CircleArrow /> Back
                  </LinkButton>
                )}
                <div css={styles.fakeButtonOverlay}></div>
              </Fragment>
            )}
          </Fragment>
        )}
      </div>
    </Modal>
  );
};

export default OnboardingModal;
