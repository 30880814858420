/** @jsxImportSource @emotion/react */
import { useState } from 'react';

import { recommendationsPageAnalytics } from '../../analyticsConsts';
import {
  CASUAL,
  CLUBS,
  KICKABOUT_CASUAL,
  SUBCATEGORY_TYPES
} from '../../const';
import useImage from '../../hooks/useImage';
import recommendationVideo from '../../mocks/data/recommendationVideo';
import idFromString from '../../utils/idFromString';
import publicPath from '../../utils/publicPath';
import { VideoPlayIcon } from '../Icons';
import Image from '../Image';
import useStyles from './styles';

const { RECOMMENDED_FOOTBALL_TYPE_IMAGE } = recommendationsPageAnalytics;

const RecommendationCardVideo = ({
  index,
  FootballType,
  selectedFootballType,
}) => {
  const styles = useStyles();
  const img = useImage();
  const [isToggled, setIsToggled] = useState(false);
  const categoryStringId = idFromString(FootballType.toString());
  const categoryHasImage = SUBCATEGORY_TYPES.includes(categoryStringId);

  return (
    <div css={styles.videoWrapper}>
      {!isToggled && (
        <button css={styles.imgWrapper} tabIndex={0} onClick={() => setIsToggled(true)}>
          <Image
            id={`${RECOMMENDED_FOOTBALL_TYPE_IMAGE}-${index}`}
            css={styles.image}
            src={publicPath(`/images/desktop/recommendations/video-placeholder/${img}/${
              categoryHasImage ? categoryStringId : SUBCATEGORY_TYPES[1]
            }.jpg`)}
            alt={FootballType}
          />
          <div css={styles.videoPlayIconWrapper}>
            <VideoPlayIcon />
          </div>
        </button>
      )}
      {isToggled && (
        <iframe
          id="iframeVideo"
          width="100%"
          height="250px"
          src={`${categoryHasImage && recommendationVideo[categoryStringId]
            ? recommendationVideo[categoryStringId]
            : selectedFootballType === KICKABOUT_CASUAL
              ? recommendationVideo[CASUAL]
              : recommendationVideo[CLUBS]
            }?autoplay=1`}
          title={`${FootballType} video`}
          frameBorder="0"
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
          allowFullScreen
        ></iframe>
      )}
    </div>
  );
};

export default RecommendationCardVideo;
