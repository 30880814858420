import publicPath from '../../utils/publicPath';

const homepage = {
  hero: {
    text: {
      main: 'Find football',
      secondary:
        'Let’s find the type of football that suits you, or someone you know.',
    },
    ctaText: 'Start searching now',
    images: {
      desktop: {
        src: publicPath('/images/desktop/home/homepage_bg.jpg'),
      },
      mobile: {
        src: publicPath('/images/mobile/home/homepage_bg.jpg'),
      },
    },
  },
  footballTypeHeadings: [
    {
      id: 1,
      heading: 'club football',
      description: 'Find a team to play in competitive leagues & competitons',
      href: 'https://www.englandfootball.com/play/club-football',
      images: {
        desktop: {
          src: publicPath('/images/desktop/home/club-football.jpg'),
        },
        mobile: {
          src: publicPath('/images/mobile/home/club-football.jpg'),
        },
      },
    },
    {
      id: 2,
      heading: 'casual football',
      description:
        'From 11-a-side to adapted forms of the game, find a kickabout that works for you. ',
      href: 'https://www.englandfootball.com/play/casual-football',
      images: {
        desktop: {
          src: publicPath('/images/desktop/home/casual-football.jpg'),
        },
        mobile: {
          src: publicPath('/images/mobile/home/casual-football.jpg'),
        },
      },
    },
  ],
  quickLinks: [
    {
      id: 1,
      heading: 'Football',
      href: 'https://www.englandfootball.com/play/11-a-side-football',
      description:
        '11v11 games tailored to age, experience, disabilities and more',
      images: {
        desktop: {
          src: publicPath('/images/desktop/home/traditional-football.png'),
        },
        mobile: {
          src: publicPath('/images/desktop/home/traditional-football.png'),
        },
      },
    },
    {
      id: 2,
      heading: 'Futsal',
      description: 'Indoor 5-a-side, played with a smaller, heavier ball',
      href: 'https://www.englandfootball.com/play/futsal',
      images: {
        desktop: {
          src: publicPath('/images/desktop/home/futsal-football.png'),
        },
        mobile: {
          src: publicPath('/images/desktop/home/futsal-football.png'),
        },
      },
    },
    {
      id: 3,
      heading: 'Walking football',
      description: 'Small-sided games adapted for over 50s',
      href: 'https://www.englandfootball.com/play/adult-football/walking-football',
      images: {
        desktop: {
          src: publicPath('/images/desktop/home/walking-football.png'),
        },
        mobile: {
          src: publicPath('/images/desktop/home/walking-football.png'),
        },
      },
    },
    {
      id: 4,
      heading: 'Small sided football',
      description: 'Reduced size forms of the game for all ages and abilities',
      href: 'https://www.englandfootball.com/play/small-sided-football',
      images: {
        desktop: {
          src: publicPath('/images/desktop/home/small-sided.png'),
        },
        mobile: {
          src: publicPath('/images/desktop/home/small-sided.png'),
        },
      },
    },
  ],
  faInitiatives: [
    {
      id: 1,
      description: 'Fun and friendly football for girls aged 5-11. ',
      href:
        'https://www.englandfootball.com/play/womens-and-girls-football/weetabix-wildcats',
      initiativeLogo: {
        desktop: {
          src: publicPath('/images/desktop/home/wildcats-logo.svg'),
        },
        mobile: {
          src: publicPath('/images/desktop/home/wildcats-logo.svg'),
        },
      },
      images: {
        desktop: {
          src: publicPath('/images/desktop/home/wildcats.png'),
        },
        mobile: {
          src: publicPath('/images/desktop/home/wildcats.png'),
        },
      },
    },
    {
      id: 2,
      description:
        'Casual football for everyone. Fancy a game? Just say, Just Play. ',
      href: 'https://www.englandfootball.com/play/adult-football/just-play',
      initiativeLogo: {
        desktop: {
          src: publicPath('/images/desktop/home/just-play-logo.svg'),
        },
        mobile: {
          src: publicPath('/images/desktop/home/just-play-logo.svg'),
        },
      },
      images: {
        desktop: {
          src: publicPath('/images/desktop/home/just-play.jpg'),
        },
        mobile: {
          src: publicPath('/images/desktop/home/just-play.jpg'),
        },
      },
    },
    // once received link for age-uk will uncomment below code
    // {
    //   id: 3,
    //   description:
    //     'Stay active with small-sided Walking Football for over 50s.',
    //   href: 'https://www.englandfootball.com/play/age-uk',
    //   initiativeLogo: {
    //     desktop: {
    //       src: publicPath('/images/desktop/home/age-uk-logo.svg'),
    //     },
    //     mobile: {
    //       src: publicPath('/images/desktop/home/age-uk-logo.svg'),
    //     },
    //   },
    //   images: {
    //     desktop: {
    //       src: publicPath('/images/desktop/home/age-uk.png'),
    //     },
    //     mobile: {
    //       src: publicPath('/images/desktop/home/age-uk.png'),
    //     },
    //   },
    // },
  ],
};

export default homepage;
