import { css, Global } from '@emotion/react';
import React from 'react';

import fsDillonBold from '../../fonts/fs-dillon/FSDillonProBold.otf';
import fsDillon from '../../fonts/fs-dillon/FSDillonProRegular.otf';
import useTheme from '../../hooks/useTheme';
import hexToRgba from '../../utils/hexToRgba';

const GlobalStyles = () => {
  const { colors, typography, breakpoints, screenSizes } = useTheme();

  return (
    <Global
      styles={css`
        @font-face {
          font-display: swap;
          font-family: 'FS Dillon';
          font-weight: 400;
          src: url('${fsDillon}') format('opentype');
        }
        @font-face {
          font-display: swap;
          font-family: 'FS Dillon Bold';
          font-weight: 700;
          src: url('${fsDillonBold}') format('opentype');
        }
        * {
          box-sizing: border-box;
        }
        html {
          -webkit-text-size-adjust: 100%;
        }

        body {
          -webkit-font-smoothing: antialiased;
          -moz-osx-font-smoothing: grayscale;
          color: ${colors.black};
          background-color: ${colors.lightGrey};
          font-family: 'FS Dillon', -apple-system, BlinkMacSystemFont,
            'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
            'Droid Sans', 'Helvetica Neue', sans-serif;
          ${typography.base};
          margin: 0;
        }
        html,
        body,
        #root,
        .container,
        #main {
          height: 100%;
        }
        html,
        body {
          max-width: 100%;
        }
        .spa-scroll-top-fix {
          display: contents;
        }
        body.modalOn {
          background-color: ${hexToRgba(colors.navy, '0.8')};
        }
        body {
          background-color: ${colors.lightGrey};
        }
        pre {
          white-space: pre-wrap; /* css-3 */
          white-space: -moz-pre-wrap; /* Mozilla, since 1999 */
          white-space: -pre-wrap; /* Opera 4-6 */
          white-space: -o-pre-wrap; /* Opera 7 */
          word-wrap: break-word; /* Internet Explorer 5.5+ */
        }
        strong {
          font-weight: 700;
        }
        h1,
        h2,
        h3,
        h4,
        h5,
        h6,
        p,
        ol,
        ul {
          margin: 0;
        }
        img {
          display: block;
        }
        ul {
          list-style: none;
          padding: 0;
          margin: 0;
        }

        .sr-only {
          clip: rect(0 0 0 0);
          clip-path: inset(50%);
          height: 1px;
          overflow: hidden;
          position: absolute;
          white-space: nowrap;
          top: 0;
          width: 1px;
        }

        /*
          Hides Sitecore Experience Editor markup,
          if you run the app in connected mode while the Sitecore cookies
          are set to edit mode.
        */
        .scChromeData,
        .scpm {
          display: none !important;
        }

        [data-reach-slider-input] {
          width: 100%;
        }

        [data-reach-slider-handle] {
          background: #bbc1cf;
          border: 5px solid ${colors.darkGrey};
          width: 27px;
          height: 27px;
          border-radius: 27px;
          cursor: pointer;
        }

        [data-reach-slider-range] {
          background: ${colors.darkGrey};
        }

        [data-reach-slider-track] {
          background: #ffffff;
        }

        .MicrosoftMap .as_container .line1,
        .MicrosoftMap .as_container .line2 {
          font-family: 'FS Dillon' !important;
          font-size: 1rem !important;
        }
        .MicrosoftMap .as_container_search {
          width: 310px !important;
        }

        .MicrosoftMap .as_container_search .asOuterContainer {
          border-color: ${colors.lightGrey} !important;
        }

        #root .MicrosoftMap .NavBar_Container.minified {
          position: absolute;
          margin: auto !important;
          bottom: 12% !important;
          top: auto !important;
          right: 2% !important;
        }

        ${breakpoints.l} {
          .MicrosoftMap .as_container_search {
            width: 367px !important;
          }
        }

        // hide carousel dots
        button.rec-dot {
          display: none;
        }

        // iframe onecookie banner styles
        .iframePage {
         #onetrust-banner-sdk {
          top: 0;
          background-color: transparent !important;
           #onetrust-policy, #onetrust-button-group  { 
            margin-left: 1em;
            margin-right: 1em;
           }
           .ot-sdk-container {
             width:100% !important;
             padding: 0;
           }

          .ot-sdk-row {
            background-color: ${colors.white};
          }

          ${breakpoints.l} {
            #onetrust-policy {
              margin-left: 2em;
            }
          }
        }
      }

        .iNsuIA {
          margin: 0 7px !important;
        }

        // below custom scroll styles supports chrome, ios, edge, opera browsers
        .scroller {
          ::-webkit-scrollbar {
            width: 5px;
            height: 3px;
            cursor: pointer;
          }

          ::-webkit-scrollbar-thumb {
            background-color: ${colors.blue};
            border-radius: 50px;
            -webkit-border-radius: 50px;
          }

          ::-webkit-scrollbar-track {
            box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
            -webkit-box-shadow: inset 0 0 4px rgba(0, 0, 0, 0.2);
            background-color: ${colors.white};
          }
        }

        // custom scroll styles for firefox
        @-moz-document url-prefix() {
          .scroller {
            scrollbar-width: thin;
            scrollbar-color: ${colors.blue} ${colors.lightGrey};
            cursor: pointer;
          }
        }

        .navigationOpen {
          overflow: hidden;  

          .NavigationInner { 
            width: 100%;
            height: 100vh;
            backdrop-filter: blur(16px);
            background: rgba(255,255,255,0.9);
          }
        }

        .contactMap {
          .MicrosoftMap {
             max-height: 200px;
          }

          #MicrosoftNav, .bm_LogoContainer, .bm_bottomRightOverlay {
            display: none;
          }
        }

        .mapPage {
          header {
            display: none;

            ${breakpoints.m} {
              display: grid;
            }
          }

          @media screen and (max-width: ${screenSizes.s}) {
            #filter-panel {
              top: 0;
              height: 100vh;
            }
          }

          #filter-toggle {
            width: 100%;
            
            @media screen and (max-width: ${screenSizes.m}) {
              margin-top: 0 !important;
            }

            ${breakpoints.m} {
              position: absolute;
              left: calc(375px + 30px);
              height: auto;
              width: 140px;
            }
          }
        }
      `}
    />
  );
};

export default GlobalStyles;
