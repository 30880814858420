import { css } from '@emotion/react';

import hexToRgba from '../../utils/hexToRgba';

const screenSizes = {
  s: '576px',
  m: '768px',
  l: '992px',
  xl: '1196px',
  xxl: '1600px',
};

const breakpoints = {
  s: `@media screen and (min-width: ${screenSizes.s})`,
  m: `@media screen and (min-width: ${screenSizes.m})`,
  l: `@media screen and (min-width: ${screenSizes.l})`,
  xl: `@media screen and (min-width: ${screenSizes.xl})`,
  xxl: `@media screen and (min-width: ${screenSizes.xxl})`,
};

const mediaQueries = {
  zoomedIn: `@media (min-aspect-ratio: 488/535) and (max-width: 767px)`, // 175% zoom level
  zoomedInL2: `@media (min-aspect-ratio: 488/535) and (max-width: 500px)`, // 300% zoom level
  zoomedInL3: `@media (min-aspect-ratio: 488/535) and (max-width: 420px)`, //400% zoom level
  iphoneZoom: `@media (min-aspect-ratio: 16/9) and (min-width: 375px) 
  and (max-width: 812px) 
  and (-webkit-min-device-pixel-ratio: 3)`, //for iphone-testing
  safariBrowser: `@media not all and (min-resolution:.001dpcm)`,
};

const colors = {
  white: '#FFFFFF',
  black: '#1D1D1B',
  blue: '#0068B2',
  darkBlue: '#004d83',
  lightBlue: '#B7C5E9',
  dodgerBlue: '#004F9F',
  red: '#E1261C',
  lightRed: '#ce1124',
  navy: '#011E41',
  grey: '#c4c4c4',
  greyShade1: '#BBC1CE',
  greyShade2: '#f8f8f8',
  lightGrey: '#ECEEF3',
  darkGrey: '#5B6885',
  darkRed: '#A01E21',
  boldRed: '#c30024',
};

const spacing = {
  xs: '0.5rem',
  s: '1rem',
  m: '2rem',
  l: '3rem',
  xl: '4rem',
  xxl: '5rem',
};

const borderRadius = {
  s: '2px',
  m: '4px',
  l: '8px',
  xl: '12px',
  xxl: '24px',
};

const heights = {
  mainNav: {
    mobile: '105px',
    desktop: '109px',
  },
  filterNav: '55px',
};

const headingStyle = {
  fontFamily: 'FS Dillon Bold',
  fontWeight: 700,
  fontStyle: 'italic',
  letterSpacing: '-0.02em',
};

const typography = {
  body: {
    desktop: {
      fontFamily: 'FS Dillon',
      fontSize: '1rem',
      lineHeight: '1.375rem',
      fontWeight: 400,
    },
    mobile: {
      fontFamily: 'FS Dillon',
      fontSize: '1rem',
      lineHeight: '1.375rem',
      fontWeight: 400,
    },
  },
  headings: {
    1: {
      desktop: {
        fontSize: '8.25rem',
        lineHeight: '6.25rem',
        ...headingStyle,
      },
      mobile: {
        fontSize: '8.25rem',
        lineHeight: '6.25rem',
        ...headingStyle,
      },
    },
    2: {
      desktop: {
        fontSize: '10.5rem',
        lineHeight: '7.5rem',
        ...headingStyle,
      },
      mobile: {
        fontSize: '7rem',
        lineHeight: '6rem',
        ...headingStyle,
      },
    },
    3: {
      desktop: {
        fontSize: '4.875rem',
        lineHeight: '4.25rem',
        ...headingStyle,
      },
      mobile: {
        fontSize: '4rem',
        lineHeight: '3rem',
        ...headingStyle,
      },
    },
    4: {
      desktop: {
        fontSize: '3rem',
        lineHeight: '2.625rem',
        ...headingStyle,
      },
      mobile: {
        fontSize: '2.4rem',
        lineHeight: '2.5rem',
        ...headingStyle,
      },
    },
    5: {
      desktop: {
        fontSize: '2.5rem',
        lineHeight: '2rem',
        ...headingStyle,
      },
      mobile: {
        fontSize: '2rem',
        lineHeight: '1.5rem',
        ...headingStyle,
      },
    },
    6: {
      desktop: {
        fontSize: '1.25rem',
        lineHeight: '1.375rem',
        ...headingStyle,
        fontFamily: 'FS Dillon',
        fontStyle: 'normal',
      },
      mobile: {
        fontSize: '1.25rem',
        lineHeight: '1.375rem',
        ...headingStyle,
        fontFamily: 'FS Dillon',
        fontStyle: 'normal',
      },
    },
    7: {
      desktop: {
        fontSize: '1.5rem',
        lineHeight: '1.625rem',
        ...headingStyle,
        fontFamily: 'FS Dillon',
        fontStyle: 'normal',
      },
      mobile: {
        fontSize: '1.25rem',
        lineHeight: '1.375rem',
        ...headingStyle,
        fontFamily: 'FS Dillon',
        fontStyle: 'normal',
      },
    },
  },
};

const utility = {
  buttonDefault: css`
    border: none;
    background: transparent;
    padding: 0;
    font: inherit;
    font-size: inherit;
    cursor: pointer;
    display: inherit;
    letter-spacing: inherit;
    line-height: inherit;
  `,
  checkboxHidden: css`
    clip: rect(0 0 0 0);
    clip-path: inset(50%);
    height: 1px;
    overflow: hidden;
    position: absolute;
    white-space: nowrap;
    top: 0;
    width: 1px;
  `,
  overlay: css`
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: fixed;
    overflow: hidden;
    z-index: 11;
    background-color: ${hexToRgba(colors.darkGrey, 0.6)};
  `,
  outline: css`
    outline: 1px solid ${colors.red};
  `,
};

export const theme = {
  screenSizes,
  breakpoints,
  borderRadius,
  heights,
  colors,
  mediaQueries,
  spacing,
  typography,
  utility,
};
