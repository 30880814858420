export default {
  home: {
    title: 'Home Page - Find Football',
    description: '',
    pageType: 'Home Page',
  },
  onboardingModal: {
    title: 'Onboarding - Find Football',
    description: '',
    pageType: 'Onboarding',
  },
  recommendations: {
    title: type => `${type} - Recommendations Pages - Find Football`,
    description: '',
    pageType: 'Recommendations Page',
  },
  miniOnboardingResults: {
    title: type => `${type} - Results Pages - Find Football`,
    description: '',
    pageType: 'Minionboarding Results Page',
  },
  map: {
    title: type => `${type} - Map Results - Find Football`,
    description: '',
    pageType: 'Map Results Page',
  },
  venue: {
    title: name => `${name} Venue and Sessions - Find Football`,
    description: '',
    pageType: 'Venue and Sessions Page',
  },
  club: {
    title: name => `${name} Club Details - Find Football`,
    description: '',
    pageType: 'Club Details Page',
  },
  team: {
    title: name => `${name} Team Details - Find Football`,
    description: '',
    pageType: 'Team Details Page',
  },
};
