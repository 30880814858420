/** @jsxImportSource @emotion/react */
import { useRef } from 'react';

import useScrollTop from '../../hooks/useScrollTop';
import Navigation from '../Navigation';

const Page = ({ children, isBanner = 'false' }) => {
  const pageRef = useRef();
  useScrollTop(pageRef);

  return (
    <div className="spa-scroll-top-fix" ref={pageRef} tabIndex={-1}>
      <Navigation isBanner={isBanner} />
      {children}
    </div>
  );
};

export default Page;
