/** @jsxImportSource @emotion/react */

import { recommendationsPageAnalytics } from '../../analyticsConsts';
import { CLUB, KICKABOUT_CASUAL, SELECTED_FOOTBALL_TYPE_ID } from '../../const';
import { usePreferencesContext } from '../../context/preferences';
import useOnboardingImage from '../../hooks/useOnboardingImage';
import categoryHeroData from '../../mocks/data/categoryHero';
import CategoryHero from '../CategoryHero';
import Tabs from '../Tabs';
import useStyles from './styles';

const { FOOTBALL_RECOMMENDATIONS_RESULTS_TABS } = recommendationsPageAnalytics;

const tabs = [
  {
    id: CLUB,
    label: 'Play club',
  },
  {
    id: KICKABOUT_CASUAL,
    label: 'Play casual',
  },
];

const SetImagePath = (data, SelectedFootballType) => {
  const useImageValue = `/images/desktop/recommendations/tab-images/${useOnboardingImage()}.jpg`;
  data[SelectedFootballType].images.desktop.src = useImageValue;
  data[SelectedFootballType].images.mobile.src = useImageValue;
  return data;
};

const FootballTypeTabs = () => {
  const styles = useStyles();
  const { preferences, setPreferences } = usePreferencesContext();
  const { SelectedFootballType } = preferences;
  SetImagePath(categoryHeroData, SelectedFootballType);

  const onClick = selected => {
    setPreferences({
      ...preferences,
      ...{ [SELECTED_FOOTBALL_TYPE_ID]: selected },
    });
  };

  return (
    <div css={styles.base} id={FOOTBALL_RECOMMENDATIONS_RESULTS_TABS}>
      <Tabs onClick={onClick} selectedType={SelectedFootballType} tabs={tabs} />
      <CategoryHero
        selectedType={SelectedFootballType}
        {...categoryHeroData[SelectedFootballType]}
      />
    </div>
  );
};

export default FootballTypeTabs;
