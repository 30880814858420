import { filtersPanelAnalytics } from '../../analyticsConsts';
import {
  CLUB,
  DISABILITY_FACILITY,
  DONT_MIND,
  KICKABOUT_CASUAL,
  LOCATION_FILTER,
  MAX_DURATION,
  MAX_SESSION_PRICE,
  MIN_SESSION_PRICE,
  MIXED_GENDER_FILTER,
  MULTI_FILTER,
  PLAY_WITH_ID,
  SESSION_DURATION,
  SESSION_PRICE,
  SLIDER_FILTER,
  SURFACE_TYPE,
  WEEKDAYS_ID,
} from '../../const';
import disabilityFacilityFormatter from '../../formatters/disabilityFacility';
import locationFormatter from '../../formatters/location';
import playWithFormatter from '../../formatters/playWith';
import sessionDurationFormatter from '../../formatters/sessionDuration';
import sessionPriceFormatter from '../../formatters/sessionPrice';
import surfaceFormatter from '../../formatters/surface';
import weekdaysFormatter from '../../formatters/weekdays';

const {
  FILTERS_PANEL_CTA_DAY_PREFERENCE,
  FILTERS_PANEL_CTA_LOCATION_PREFERENCE,
  FILTERS_SESSION_DURATION_PREFERENCE_CTA,
  FILTERS_PANEL_CTA_INCLUDE_MIXED_GENDER,
  FILTERS_PANEL_CTA_DISABILITY_FACILITY,
  FILTERS_PANEL_CTA_SURFACE_TYPE,
  FILTERS_PANEL_CTA_SESSION_PRICE,
} = filtersPanelAnalytics;

export const filterFormatters = {
  WeekDays: weekdaysFormatter,
  ReadableLocation: locationFormatter,
  SessionDuration: sessionDurationFormatter,
  PlayWith: playWithFormatter,
  DisabilityFacility: disabilityFacilityFormatter,
  SurfaceType: surfaceFormatter,
  SessionPrice: sessionPriceFormatter,
};

const defaultFilters = [
  {
    id: 1,
    analyticsId: FILTERS_PANEL_CTA_DAY_PREFERENCE,
    preferenceKey: [WEEKDAYS_ID],
    label: 'Day preference',
    type: MULTI_FILTER,
    valueFormatter: weekdaysFormatter,
    hasNestedFilter: true,
    areOptionsMandatory: true,
    helpText: 'Select the days you would like to play or train',
    options: [
      {
        id: 1,
        text: 'Monday',
        value: '1',
      },
      {
        id: 2,
        text: 'Tuesday',
        value: '2',
      },
      {
        id: 3,
        text: 'Wednesday',
        value: '3',
      },
      {
        id: 4,
        text: 'Thursday',
        value: '4',
      },
      {
        id: 5,
        text: 'Friday',
        value: '5',
      },
      {
        id: 6,
        text: 'Saturday',
        value: '6',
      },
      {
        id: 7,
        text: 'Sunday',
        value: '7',
      },
    ],
  },
  {
    id: 2,
    analyticsId: FILTERS_PANEL_CTA_LOCATION_PREFERENCE,
    preferenceKey: 'ReadableLocation',
    label: 'Location',
    type: LOCATION_FILTER,
    valueFormatter: locationFormatter,
    hasNestedFilter: true,
  },
];

export default {
  [CLUB]: defaultFilters,
  [KICKABOUT_CASUAL]: [
    ...defaultFilters,
    {
      id: 3,
      analyticsId: FILTERS_PANEL_CTA_SESSION_PRICE,
      preferenceKey: [SESSION_PRICE],
      label: 'Session price',
      type: SLIDER_FILTER,
      valueFormatter: sessionPriceFormatter,
      hasNestedFilter: true,
      helpText: 'Select the maximum price you are happy to pay for a session',
      config: {
        min: MIN_SESSION_PRICE,
        max: MAX_SESSION_PRICE,
        defaultValue: MAX_SESSION_PRICE,
        step: 1,
        hasSuffix: true,
      },
    },
    {
      id: 4,
      analyticsId: FILTERS_PANEL_CTA_SURFACE_TYPE,
      preferenceKey: [SURFACE_TYPE],
      label: 'Playing Surface',
      type: MULTI_FILTER,
      valueFormatter: surfaceFormatter,
      hasNestedFilter: true,
      areOptionsMandatory: true,
      helpText: 'Select the surface(s) you wish to play on',
      options: [
        {
          id: 1,
          text: '3G or Astroturf',
          value: '3G or Astroturf',
        },
        {
          id: 2,
          text: 'Grass',
          value: 'Grass',
        },
        {
          id: 3,
          text: 'Indoor',
          value: 'Indoor',
        },
        {
          id: 4,
          text: 'Others',
          value: 'Others',
        },
      ],
    },
    {
      id: 5,
      analyticsId: FILTERS_SESSION_DURATION_PREFERENCE_CTA,
      preferenceKey: [SESSION_DURATION],
      label: 'Session duration',
      type: SLIDER_FILTER,
      valueFormatter: sessionDurationFormatter,
      hasNestedFilter: true,
      helpText: 'Select the maximum duration you wish to play',
      config: {
        min: 1,
        max: MAX_DURATION,
        defaultValue: MAX_DURATION,
        step: 1,
        hasSuffix: true,
      },
    },
    {
      id: 6,
      analyticsId: FILTERS_PANEL_CTA_DISABILITY_FACILITY,
      preferenceKey: [DISABILITY_FACILITY],
      label: 'Disability facilities',
      type: MULTI_FILTER,
      areOptionsMandatory: false,
      valueFormatter: disabilityFacilityFormatter,
      hasNestedFilter: true,
      helpText: 'Select which facilities you need to be disability-friendly',
      options: [
        {
          id: 1,
          text: 'Toilets',
          value: 'Toilets',
        },
        {
          value: 'Parking',
          id: 2,
          text: 'Parking',
        },
        {
          text: 'Doorways',
          value: 'Doorways',
          id: 3,
        },
        {
          id: 4,
          value: 'Changing',
          text: 'Changing',
        },
        {
          text: 'Spectator Areas',
          id: 5,
          value: 'Spectator Areas',
        },
        {
          id: 6,
          text: 'Social',
          value: 'Social',
        },
        {
          id: 7,
          text: 'Reception',
          value: 'Reception',
        },
        {
          id: 8,
          text: 'Emergency Exits',
          value: 'Emergency Exits',
        },
        {
          id: 9,
          text: 'Activities',
          value: 'Activities',
        },
      ],
    },
    {
      id: 7,
      analyticsId: FILTERS_PANEL_CTA_INCLUDE_MIXED_GENDER,
      preferenceKey: [PLAY_WITH_ID],
      label: 'Include mixed gender results',
      type: MIXED_GENDER_FILTER,
      valueFormatter: playWithFormatter,
      hasNestedFilter: false,
    },
  ],
  [DONT_MIND]: defaultFilters,
};
