/** @jsxImportSource @emotion/react */
import {
  Fragment,
  useEffect,
  useLayoutEffect,
  useMemo,
  useReducer,
  useRef,
  useState,
} from 'react';
import { Link, Redirect } from 'react-router-dom';
import { useSwipeable } from 'react-swipeable';

import { recommendationsMapPageAnalytics } from '../../analyticsConsts';
import {
  CLUB,
  DISABILITY_TYPE,
  KICKABOUT_CASUAL,
  PAGE_NUMBER,
  PAGE_SIZE,
  SELECTED_FOOTBALL_TYPE_ID,
  SELECTED_RECOMMENDATIONS_TYPE_ID,
} from '../../const';
import { usePreferencesContext } from '../../context/preferences';
import { useRecommendations } from '../../context/recommendations';
import { useSetHeaderHeightContext } from '../../context/setHeaderHeight';
import useBodyClass from '../../hooks/useBodyClass';
import useErrorBoundary from '../../hooks/useErrorBoundary';
import useMediaQuery from '../../hooks/useMediaQuery';
import usePageLoadInfo from '../../hooks/usePageLoadInfo';
import normaliseSessionLogo from '../../normaliser/sessionlogo';
import trayStateReducer, {
  EXPAND_ACTION,
  EXPAND_MAP_ACTION,
  SHRINK_ACTION,
  STANDARD,
} from '../../reducers/trayState';
import { getRecommendations, getSessionLogo } from '../../services';
import normaliseRecommendations from '../../utils/normaliseRecommendations';
import AlertHiddenRegion from '../AlertHiddenRegion';
import Button from '../Button';
import { RightArrow } from '../Icons';
import Loader from '../Loader';
import ProviderCenterCard from '../ProviderCenterCard';
import ProviderClubCard from '../ProviderClubCard';
import ProvidersMap from '../ProvidersMap';
import Text from '../Text';
import useStyles from './styles';

const {
  CTA_BACK_TO_RECOMMENDATIONS,
  MAP_CTA_LOAD_MORE_RECOMMENDATIONS,
  RECOMMENDATIONS_RESULT_ON_MAP,
} = recommendationsMapPageAnalytics;

const CardMap = {
  [KICKABOUT_CASUAL]: ProviderCenterCard,
  [CLUB]: ProviderClubCard,
};

const SHOWN_INCREMENT = 10;

const MapPage = () => {
  useBodyClass('mapPage', '/recommendations/map');

  const styles = useStyles();
  const [mapLogo, setMapLogo] = useState();
  const throwError = useErrorBoundary();
  const { preferences } = usePreferencesContext();
  const {
    [SELECTED_FOOTBALL_TYPE_ID]: selectedFootballType,
    [SELECTED_RECOMMENDATIONS_TYPE_ID]: selectedRecommendationType,
    [DISABILITY_TYPE]: selectedDisabilityID,
  } = preferences || {};
  const { headerHeight } = useSetHeaderHeightContext();

  const { clubRecommendations, casualRecommendations, addRecommendations } =
    useRecommendations();
  // we pick the general type of recommendations the user has set (e.g. Club)
  const recommendationsGroup =
    selectedFootballType === CLUB ? clubRecommendations : casualRecommendations;
  // we extract the specific football type from the recommendations group (e.g. Futsal)
  const {
    TotalClubsRecords,
    TotalCentresRecords,
    RecommendationClubCartDto,
    RecommendationCentreCartDto,
    RecommendationProviderCartDto,
  } = useMemo(
    () =>
      recommendationsGroup?.find(
        group =>
          group.FootballType === selectedRecommendationType &&
          group.DisabilityId === selectedDisabilityID
      ) || {},
    [selectedRecommendationType, recommendationsGroup, selectedDisabilityID]
  );
  // here we try to read both values, since only one will be populated for a specific football type
  const totalRecommendations = TotalClubsRecords
    ? TotalClubsRecords
    : TotalCentresRecords;
  const recommendations = useMemo(() => {
    if (
      !RecommendationClubCartDto &&
      !RecommendationCentreCartDto &&
      !RecommendationProviderCartDto
    ) {
      return null;
    }
    const centerProviders = RecommendationCentreCartDto?.concat(
      RecommendationProviderCartDto
    );
    // we need to normalise the recommendations to make sure we offset
    // pins that are at the same location
    // sorting cards based on distance
    return normaliseRecommendations(
      RecommendationClubCartDto
        ? RecommendationClubCartDto
        : RecommendationCentreCartDto?.length < 0
        ? RecommendationProviderCartDto
        : centerProviders
    )?.sort((firstDistance, secondDistance) =>
      firstDistance.DistanceFromUserLocation >
      secondDistance.DistanceFromUserLocation
        ? 1
        : firstDistance.DistanceFromUserLocation <
          secondDistance.DistanceFromUserLocation
        ? -1
        : 0
    );
  }, [
    RecommendationClubCartDto,
    RecommendationCentreCartDto,
    RecommendationProviderCartDto,
  ]);

  const [trayState, dispatch] = useReducer(trayStateReducer, STANDARD);
  const handlers = useSwipeable({
    onSwipedUp: () => dispatch(EXPAND_ACTION),
    onSwipedDown: () => dispatch(SHRINK_ACTION),
    preventDefaultTouchmoveEvent: true,
  });

  const [numberShown, setNumberShown] = useState(SHOWN_INCREMENT);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const loadingRef = useRef();
  const idref = useRef();

  // We keep track of what index the next card will be once we load more cards
  const [nextLoadedFirstIndex, setNextLoadedFirstIndex] = useState();
  const firstOfNewLoadedRef = useRef();

  const [selectedId, setSelectedId] = useState();
  const isListView = !selectedId;

  const shownRecommendations = useMemo(
    () => recommendations?.slice(0, numberShown),
    [numberShown, recommendations]
  );

  const pageTitle = `${
    selectedFootballType === CLUB ? 'Club' : 'Casual'
  } ${selectedRecommendationType}`;

  // set the page title.
  usePageLoadInfo('map', pageTitle);

  const isDesktop = useMediaQuery('screen and (min-width: 768px)');

  // If the number of cards shown changes we want to
  // move the focus to the first new card
  useLayoutEffect(() => {
    setTimeout(() => firstOfNewLoadedRef.current?.focus(), 200);
  }, [numberShown]);

  // set path information for redirect
  useEffect(() => {
    localStorage.setItem('pathInfomation', window.location.pathname);
  }, []);

  useEffect(() => {
    getSessionLogo()
      .then(({ data }) => {
        const logoData = normaliseSessionLogo(data);
        setMapLogo(logoData);
      })
      .catch(throwError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // If the user loads more recommendations we want to move the focus to
  // the loading element
  useLayoutEffect(() => {
    setTimeout(() => loadingRef.current?.focus(), 200);
  }, [isLoadingMore]);

  if (!selectedFootballType || !selectedRecommendationType) {
    return <Redirect to="/" />;
  }

  const backToList = () => {
    setSelectedId();
  };

  const Card = CardMap[selectedFootballType];

  const showNext = () => {
    setNextLoadedFirstIndex(numberShown + 1);
    setNumberShown(numberShown + SHOWN_INCREMENT);
  };

  const getMoreResults = () => {
    if (!recommendations) {
      return;
    }

    // if we already have more than the current shown amount
    // show the next 10
    if (recommendations?.length > numberShown) {
      showNext();
      return;
    }

    setIsLoadingMore(true);

    getRecommendations(selectedFootballType, {
      ...preferences,
      // get the page after the current one
      [PAGE_NUMBER]:
        preferences['FootballType'] === 1
          ? Math.floor(recommendations?.length / 10) +
            (Number.isInteger(recommendations?.length / 10) ? 1 : 2)
          : Math.floor(recommendations?.length / preferences[PAGE_SIZE]) + 1,
    })
      .then(({ data }) => {
        if (data) {
          addRecommendations(data, selectedFootballType);
        }

        setIsLoadingMore(false);
        showNext();
      })
      .catch(throwError);
  };

  if (!recommendations) {
    return <p>No recommendations match the current filters</p>;
  }

  // We announce the new number of cards when it changes
  const ariaAnnouncement = `Showing ${numberShown} recommendations`;

  return (
    <Fragment>
      <AlertHiddenRegion text={ariaAnnouncement} />
      <div css={styles.base(isListView)}>
        <div css={styles.header(headerHeight)}>
          <Link
            id={CTA_BACK_TO_RECOMMENDATIONS}
            css={styles.backLink}
            to="/recommendations"
          >
            <RightArrow css={styles.arrowRotate} />
            <Text as="p" size={6} css={styles.headerTitle}>
              Back to recommendations
            </Text>
          </Link>
        </div>
        <div css={styles.mapAreaWrapper(headerHeight)}>
          <ProvidersMap
            data={shownRecommendations}
            handleSelect={setSelectedId}
            selectedId={selectedId}
            userLocation={{
              latitude: preferences.Latitude,
              longitude: preferences.Longitude,
            }}
            handleMapMovement={() => dispatch(EXPAND_MAP_ACTION)}
          />
        </div>
        <div
          css={styles.providerCardWrapper(isListView, trayState)}
          id={RECOMMENDATIONS_RESULT_ON_MAP}
        >
          {!isListView && (
            <div css={styles.backToWrapper}>
              <Button onClick={backToList} css={styles.primaryBtn}>
                <RightArrow css={styles.arrowRotate} /> list view
              </Button>
            </div>
          )}
          <div {...handlers} css={styles.totalResults(isListView)}>
            <span>{`${totalRecommendations} Result${
              totalRecommendations > 1 ? 's' : ''
            }`}</span>
          </div>
          <div css={styles.scrollContainer(isListView)}>
            <ul css={styles.providerCards(isListView)}>
              {shownRecommendations?.length > 0 &&
                shownRecommendations.map((providerData, index) => {
                  const id = providerData.ClubId || providerData.CentreId;
                  const isFirstOfNewLoaded = index === nextLoadedFirstIndex - 1;
                  return providerData.ProviderCartDto?.length < 2 &&
                    !providerData.SessionCartDto ? (
                    providerData.ProviderCartDto.map((providers, index) => {
                      idref.current = providers.ProviderId;
                      return (
                        <Card
                          index={index}
                          onClick={() =>
                            setSelectedId(id ? id : providers.ProviderId)
                          }
                          isSelected={
                            selectedId === (id ? id : providers.ProviderId)
                          }
                          key={id ? id : providers.ProviderId}
                          ref={isFirstOfNewLoaded ? firstOfNewLoadedRef : null}
                          mapCardLogo={mapLogo}
                          isDesktop={isDesktop}
                          providerid={idref.current}
                          {...providerData}
                        />
                      );
                    })
                  ) : providerData.SessionCartDto && providerData?.CentreId ? (
                    <Card
                      index={index}
                      onClick={() => setSelectedId(id)}
                      isSelected={selectedId === id}
                      key={id}
                      ref={isFirstOfNewLoaded ? firstOfNewLoadedRef : null}
                      mapCardLogo={mapLogo}
                      isDesktop={isDesktop}
                      {...providerData}
                    />
                  ) : !providerData.SessionCartDto &&
                    providerData.ProviderCartDto?.length > 1 ? (
                    <Card
                      index={index}
                      onClick={() => setSelectedId(providerData?.PitchFinderId)}
                      isSelected={selectedId === providerData?.PitchFinderId}
                      key={providerData?.PitchFinderId}
                      ref={isFirstOfNewLoaded ? firstOfNewLoadedRef : null}
                      mapCardLogo={mapLogo}
                      isDesktop={isDesktop}
                      pitchfinderid={providerData?.PitchFinderId}
                      {...providerData}
                    />
                  ) : (
                    <Card
                      index={index}
                      onClick={() =>
                        setSelectedId(id ? id : providerData?.PitchFinderId)
                      }
                      isSelected={
                        selectedId === id ? id : providerData?.PitchFinderId
                      }
                      key={id ? id : providerData?.PitchFinderId}
                      ref={isFirstOfNewLoaded ? firstOfNewLoadedRef : null}
                      mapCardLogo={mapLogo}
                      isDesktop={isDesktop}
                      pitchfinderid={providerData?.PitchFinderId}
                      {...providerData}
                    />
                  );
                })}
            </ul>
            <div css={styles.btnWrapper}>
              {isLoadingMore ? (
                <Loader
                  inline
                  message={`Loading more ${
                    selectedFootballType === CLUB ? 'clubs' : 'venues'
                  }`}
                  css={styles.loader}
                  ref={loadingRef}
                />
              ) : (
                totalRecommendations &&
                numberShown < totalRecommendations && (
                  <div css={styles.loadMore(isListView)}>
                    <Button
                      id={MAP_CTA_LOAD_MORE_RECOMMENDATIONS}
                      onClick={getMoreResults}
                      primary
                    >
                      Load more
                    </Button>
                  </div>
                )
              )}
            </div>
          </div>
        </div>
      </div>
    </Fragment>
  );
};

export default MapPage;
