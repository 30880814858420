/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useState } from 'react';

import { filtersPanelAnalytics } from '../../analyticsConsts';
import FilterWrapper from '../FilterWrapper';
import Slider from '../Slider';
import useStyles from './styles';

const { FILTERS_PANEL_SELECTED_SESSION_DURATION_PREFERENCE } =
  filtersPanelAnalytics;

const FilterSessionDuration = ({
  onSave,
  preferenceKey,
  config,
  currentValue,
  valueFormatter,
  ...rest
}) => {
  const styles = useStyles();
  const { defaultValue } = config;
  const [duration, setDuration] = useState(defaultValue);
  const [isSliderClicked, setIsSliderClicked] = useState(false);

  const onClick = () => {
    onSave({
      [preferenceKey]: isSliderClicked ? duration : currentValue,
    });
  };

  const setSliderValue = value => {
    setIsSliderClicked(true);
    setDuration(value);
  };

  return (
    <FilterWrapper
      currentValue={currentValue}
      onClick={onClick}
      disabled={currentValue === duration}
      config={config}
      valueFormatter={valueFormatter}
      {...rest}
    >
      <div css={styles.filterWrapper}>
        <Slider
          analyticsId={FILTERS_PANEL_SELECTED_SESSION_DURATION_PREFERENCE}
          {...config}
          currentValue={currentValue}
          valueFormatter={valueFormatter}
          onChange={value => {
            setSliderValue(value);
          }}
        />
      </div>
    </FilterWrapper>
  );
};

FilterSessionDuration.prototype = {
  onSave: PropTypes.func,
  preferenceKey: PropTypes.object,
  options: PropTypes.object,
  currentValue: PropTypes.number,
  valueFormatter: PropTypes.func,
};

export default FilterSessionDuration;
