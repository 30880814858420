import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { spacing, colors, breakpoints } = useTheme();

  return {
    ageText: css`
      color: ${colors.navy};
      margin-bottom: ${spacing.xs};
      font-size: 1.75rem;
      line-height: ${spacing.m};
      text-align: center;
      font-style: italic;

      span:nth-of-type(2) {
        font-weight: 400;
      }

      ${breakpoints.l} {
        font-size: 1.75rem;
        font-weight: 700;
        margin-bottom: ${spacing.s};
      }
    `,
  };
};
