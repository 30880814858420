/** @jsxImportSource @emotion/react */
import { forwardRef } from 'react';

import { REAPPLY_FILTERS, REMOVE_FILTERS } from '../../analyticsConsts';
import { CLUB } from '../../const';
import Button from '../Button';
import ExpandableList from '../ExpandableList';
import Text from '../Text';
import useStyles from './styles';

const VenueClubList = forwardRef(
  (
    {
      title,
      titleAnalyticsId,
      subtitle,
      data,
      type,
      isFiltered,
      toggleFiltered,
      canToggle,
      cardAction,
      isClubInfo = false,
      clubname,
    },
    ref
  ) => {
    const styles = useStyles();
    const { ref1, ref2 } = ref;

    return (
      <div css={styles.base} tabIndex={-1} ref={ref1}>
        <div css={styles.header}>
          <Text
            id={titleAnalyticsId}
            as="h2"
            size="5"
            css={styles.title(isClubInfo)}
          >
            {title}
          </Text>
          <Text css={styles.subtitle}>{subtitle}</Text>
        </div>
        <div css={styles.middle}>
          {canToggle && (
            <div css={styles.filterButtonWrapper}>
              <Button
                id={`${isFiltered ? REMOVE_FILTERS : REAPPLY_FILTERS}`}
                onClick={toggleFiltered}
                css={styles.button}
              >
                {isFiltered ? 'Remove filters' : 'Reapply filters'}
              </Button>
            </div>
          )}
          {data &&
            data.length > 0 &&
            data.map((group, index) => {
              const isLastElement = data.length === index + 1;
              const defaultTemplate = (
                <ExpandableList
                  index={index}
                  title={group.title}
                  ariaLabel={`${type === CLUB ? 'Teams' : 'Sessions'} on ${
                    group.title
                  }`}
                  list={group.data}
                  type={type}
                  cardAction={cardAction}
                  clubname={clubname}
                />
              );
              if (isLastElement) {
                return (
                  // add reference for last element
                  <div ref={ref2} key={group.title} css={styles.animateSession}>
                    {defaultTemplate}
                  </div>
                );
              } else {
                return <div key={group.title}>{defaultTemplate}</div>;
              }
            })}
        </div>
      </div>
    );
  }
);

export default VenueClubList;
