/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { EMAIL, PHONE_NUMBER, WEBSITE } from '../../const';
import { useSetContactFormDLContext } from '../../context/setContactFormDL';
import cleanUrl from '../../utils/cleanUrl';
import contactFormDataLayer from '../../utils/contactFormDataLayer';
import Button from '../Button';
import ExternalLink from '../ExternalLink';
import { AlertIcon, Email, Phone, Website } from '../Icons';
import Text from '../Text';
import useStyles from './styles';

const DETAILS_MAPPING = {
  [EMAIL]: { icon: Email, prefix: 'mailto:' },
  [WEBSITE]: { icon: Website },
  [PHONE_NUMBER]: { icon: Phone, prefix: 'tel:' },
};

const normaliseContactDetails = details => {
  if (!Object.keys(details)) {
    return [];
  }

  return Object.keys(details).map(detailKey => ({
    icon: DETAILS_MAPPING[detailKey].icon,
    text: cleanUrl(details[detailKey]),
    href: `${DETAILS_MAPPING[detailKey].prefix || ''}${details[detailKey]}`,
  }));
};

const ContactDetails = ({
  title,
  contactDetails,
  subTitle,
  isClubInfo = false,
  isVenueLocation = false,
  onClick,
  isProvider = false,
  organisername,
}) => {
  const styles = useStyles();
  const { contactFormDL } = useSetContactFormDLContext();

  const details =
    contactDetails?.details && normaliseContactDetails(contactDetails.details);

  return (
    <div css={styles.stickyContact(isClubInfo, isVenueLocation, isProvider)}>
      <Text as="h3" css={styles.title(isClubInfo)}>
        {title}
      </Text>
      {!isVenueLocation && (
        <Text css={[styles.section(isVenueLocation), styles.info(isProvider)]}>
          {subTitle}
        </Text>
      )}
      {details && details.length > 0 && (
        <Fragment>
          <div css={styles.section(isVenueLocation, isProvider)}>
            {contactDetails?.name && (
              <Text css={[styles.sectionTitle, styles.nameWrapper]}>
                {contactDetails.name}
              </Text>
            )}
            {organisername && (
              <Text css={[styles.sectionTitle, styles.nameWrapper]}>
                {organisername}
              </Text>
            )}
            <ul>
              {details.map(detail => {
                const { href: link, icon: Icon } = detail;
                return (
                  <li css={styles.row} key={detail.text}>
                    <ExternalLink
                      href={link}
                      css={styles.link}
                      aria-label={`Link - opens in a new window`}
                      target="_blank"
                      rel="noopener"
                    >
                      <span css={styles.bgIcon}>
                        <Icon css={styles.icon} />
                      </span>
                      <Text size={6} css={styles.text(isProvider)}>
                        {detail.text}
                      </Text>
                    </ExternalLink>
                  </li>
                );
              })}
            </ul>
          </div>
        </Fragment>
      )}
      {!isVenueLocation &&
        !isProvider &&
        (contactDetails?.details?.email ||
          contactDetails?.otherDetails?.email) && (
          <div css={styles.contactFormWrapper}>
            <Text as="p">
              <span>Or use our contact form</span>
            </Text>
            <Button
              css={styles.contactForm}
              aria-label={`Contact Form`}
              onClick={() => {
                onClick();
                contactFormDataLayer({
                  ...contactFormDL,
                  eventLabel: 'FF Club Contact Form CTA - Contact Form',
                  link_text: 'contact form',
                  event_name: 'cta_primary_click',
                });
              }}
            >
              Contact form
            </Button>
          </div>
        )}
      {isVenueLocation && (
        <div css={styles.venueDescription} role="alert">
          <AlertIcon />
          <Text>{subTitle}</Text>
        </div>
      )}
    </div>
  );
};

ContactDetails.prototype = {
  title: PropTypes.string,
  contactDetails: PropTypes.object,
  subTitle: PropTypes.string,
  isClubInfo: PropTypes.bool,
  isVenueLocation: PropTypes.bool,
  onClick: PropTypes.func,
  isProvider: PropTypes.bool,
};

export default ContactDetails;
