import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors, spacing, breakpoints } = useTheme();

  return {
    answer: css`
      padding-bottom: ${spacing.s};
    `,
    link: css`
      padding: ${spacing.xs};
    `,
    error: css`
      color: ${colors.red};
    `,
    locationInput: css`
      ${breakpoints.l} {
        width: 80%;
      }
    `,
  };
};
