import pdfData from '../../mocks/data/the-fa-slavery-human-trafficking-statement-2020.pdf';
const footerData = {
  links: [
    {
      text: 'Contact Us',
      url: 'https://help.thefa.com/support/home',
      isDownload: false,
    },
    {
      text: 'Privacy Policy',
      url: 'https://www.thefa.com/public/privacy',
      isDownload: false,
    },
    {
      text: 'Terms of use',
      url: 'https://www.thefa.com/public/terms',
      isDownload: false,
    },
    {
      text: 'Anti-Slavery',
      url: pdfData,
      isDownload: true,
    },
    {
      text: 'Cookie Settings',
      isDownload: false,
      className: 'ot-sdk-show-settings',
      id: 'ot-sdk-btn ot-sdk-fnav',
    },
  ],
};

export default footerData;
