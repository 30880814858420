/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { CASUAL_LINK, CLUB_LINK } from '../../const';
import useMediaQuery from '../../hooks/useMediaQuery';
import Button from '../Button';
import ContactDetails from '../ContactDetails';
import ExternalLink from '../ExternalLink';
import InfoFacilities from '../InfoFacilities';
import Text from '../Text';
import useStyles from '../VenueDetails/styles';
import useStylesClub from './styles';

const ClubDetails = ({
  footballtypes,
  accreditation,
  facilities,
  contact,
  contactAddress,
  county,
  title,
  onClick,
}) => {
  const styles = useStyles();
  const stylesClub = useStylesClub();
  const isDesktop = useMediaQuery('screen and (min-width: 768px)');

  return (
    <Fragment>
      <div css={stylesClub.middle}>
        <div css={styles.leftContent}>
          <div css={styles.top}>
            <Text as="h2" size="5" css={stylesClub.title}>
              About {title}
            </Text>
          </div>
          <div css={stylesClub.clubDescriptionWrapper}>
            {accreditation?.isAccreditation ? (
              <p>
                {title} is a football club who affiliates to {county}. This club
                is England Football Accredited. Recognised by the FA and their
                County FA for offering a consistent standard on and off the
                pitch, creating the ideal environments to nurture players,
                coaches and volunteers whilst delivering inclusive football for
                their local community. Joining a club is perfect for those who
                want to play regular competitive football and potentially enjoy
                training sessions too.
              </p>
            ) : (
              <p>
                {title} is a football club who affiliates to {county}, which
                means it enters one or more teams into organised regional
                leagues. Joining a club is perfect for those who want to play
                regular competitive football and potentially enjoy training
                sessions too.
              </p>
            )}
            <p>
              {' '}
              To find out more on this club, get in touch via the contact
              details below. You can also learn more about{' '}
              <ExternalLink
                css={styles.externalLink}
                href={CLUB_LINK}
                aria-label={`Learn more about club - opens in a new window`}
              >
                <span css={styles.footballLink}>Club football </span>
              </ExternalLink>{' '}
              and{' '}
              <ExternalLink
                css={styles.externalLink}
                href={CASUAL_LINK}
                aria-label={`Learn more about casual - opens in a new window`}
              >
                <span css={styles.footballLink}>Casual football</span>
              </ExternalLink>{' '}
              opportunities on our football type pages.{' '}
            </p>
          </div>
          <div css={styles.footballTypeWrapper}>
            {footballtypes && (
              <InfoFacilities isClubInfo={true} {...footballtypes} />
            )}
          </div>
          <div css={stylesClub.clubFacilitiesWrapper}>
            {facilities && <InfoFacilities isClubInfo={true} {...facilities} />}
          </div>
          <div css={stylesClub.imgWrapper}>
            <div css={stylesClub.imgContent}>
              <div css={stylesClub.textWrapper}>
                <Text as="h3" size="5">
                  Become a coach
                </Text>
                <Text as="p">Lorem ipsum dolor sit amet.</Text>
                <Button
                  aria-label={`Click to find out more Details- opens in new tab`}
                >
                  Find out more
                </Button>
              </div>
            </div>
          </div>
        </div>
        {isDesktop && (
          <div css={styles.rightContents}>
            <ContactDetails
              title="Club Contact"
              subTitle="For information about a specific team, please get in touch with the club contact."
              contactDetails={contact}
              isClubInfo={true}
              onClick={onClick}
            >
              <span css={styles.wrapText}>{contactAddress}</span>
            </ContactDetails>
          </div>
        )}
      </div>
    </Fragment>
  );
};

ClubDetails.prototype = {
  footballtypes: PropTypes.object,
  facilities: PropTypes.object,
  contact: PropTypes.object,
  contactAddress: PropTypes.string,
  county: PropTypes.string,
  title: PropTypes.string,
  onClick: PropTypes.func,
};

export default ClubDetails;
