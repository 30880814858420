import { AGE_ID, MYSELF, SOMEONE_ELSE } from '../../../const';

export default [
  {
    id: '346dbfab-03fa-4a3a-ab18-b461d0d56dc7',
    questionOrder: 2,
    readableId: AGE_ID,
    analyticsId: 'selected_age',
    type: 'age',
    title: {
      [MYSELF]: 'How old are you?',
      [SOMEONE_ELSE]: 'How old are they?',
    },
    inputTitle: {
      [MYSELF]: 'Enter your age (between 5 & 99)',
      [SOMEONE_ELSE]: 'Enter their age (between 5 & 99)',
    },
    helpText:
      'Football types depend on age, so this just helps us give you the best options',
    config: {
      min: 5,
      max: 99,
      step: 1,
      defaultValue: 5,
      labelMin: '5',
      labelMax: '99+',
    },
    ageRanges: [
      {
        min: 5,
        max: 17,
        nextQuestionId: 'd9572be5-72f2-42af-87ea-a3298c6eacd7',
      },
      {
        min: 18,
        max: 99,
        nextQuestionId: 'd43569c8-1a01-4c53-9575-128698905fbc',
      },
    ],
  },
];
