import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, borderRadius, colors, spacing, utility } = useTheme();

  return {
    base: css`
      border: 1px solid ${colors.darkGrey};
      padding: 1.5rem 1.5rem ${spacing.s};
      border-radius: ${borderRadius.l};
      cursor: pointer;
      width: 100%;
      margin: 0 auto;
      height: 100%;
      text-decoration: none;
      overflow-x: auto;
      background-color: white;
      text-align: left;
      font-family: 'FS Dillon';

      &:hover {
        svg {
          transition: 0.4s ease-in;
          transform: translateX(50%);
        }
      }

      ${breakpoints.m} {
        overflow-x: hidden;
      }
    `,
    title: css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${colors.navy};
      background-size: contain;
      background-position: ${borderRadius.s};
      text-decoration: underline 7px ${colors.red};
      text-transform: capitalize;
    `,
    titleWrapper: css`
      position: relative;
      margin-bottom: 0.5rem;
    `,
    infoWrapper: css`
      display: grid;
      color: ${colors.navy};
      grid-gap: 0.063rem;
      p {
        color: ${colors.darkGrey};
        display: block;
        font-size: 12px;
      }
    `,
    formatWrapper: css`
      display: grid;
      grid-auto-flow: column;
      }
    `,
    strongformat: css`
      font-size: 16px;
      color: ${colors.navy};
      display: grid;
    `,
    format: css`
      display: inline-grid;
      padding: 0 0 0 ${spacing.s};
      border-left: 1px solid ${colors.lightGrey};
    `,
    externalLink: css`
      vertical-align: middle;
      color: ${colors.red};
      margin-left: 0.3125rem;
    `,
    infoAddress: css`
      text-transform: Capitalize;
    `,
    linkWrapper: css`
      padding-top: ${spacing.xs};
      button {
        padding: 0;
        text-decoration: none;
        font-weight: normal;
        text-transform: uppercase;

        &:focus {
          outline: 0;
          color: ${colors.navy};
          text-decoration: underline;
          text-align: left;
          font-family: 'FS Dillon';
          svg {
            color: ${colors.navy};
          }
        }
      }
    `,
    link: css`
      color: ${colors.blue};
      font-size: 0.875rem;
    `,
    infoValue: css`
      width: 80%;
      display: inline-block;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
      padding-bottom: ${spacing.xs};
      font-weight: 700;
      color: ${colors.navy};

      ${breakpoints.m} {
        font-weight: 700;
      }
    `,
    TeamName: utility.checkboxHidden,
  };
};
