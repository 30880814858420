/** @jsxImportSource @emotion/react */
import { clubDetailsPageAnalytics } from '../../analyticsConsts';
import formatAddress from '../../utils/formatAddress';
import HiddenContent from '../HiddenContent';
import { RightArrow } from '../Icons';
import LinkButton from '../LinkButton';
import Text from '../Text';
import useStyles from './styles';

const { CLUB_TEAM_CARD_TITLE, CLUB_TEAM_CARD_CTA_CONTACT } =
  clubDetailsPageAnalytics;

const TeamCard = ({
  index,
  TeamName,
  League,
  FootballType,
  Division,
  PlaysOnDay,
  onClick,
  isOpen,
  clubname,
  ...rest
}) => {
  const styles = useStyles();

  const address = formatAddress({
    AddressLine1: rest.GroundAddressLine1,
    AddressLine2: rest.GroundAddressLine2,
    AddressLine3: rest.GroundAddressLine3,
    City: rest.GroundCity,
    PostCode: rest.GroundPostCode,
  });

  //converting clubname and teamname to array
  const splitClub = clubname?.toLowerCase().split(' ');
  const spliTeam = TeamName?.toLowerCase().split(' ');

  // trimming the string
  const trimClubName = clubname.toLowerCase().trim();
  var trimTeamName = TeamName.toLowerCase().trim();

  //filtering the clubname from teamname if string match returns null
  const filterTeam = spliTeam?.filter(value => !splitClub?.includes(value));
  const filterTeamTitle = filterTeam.join(' ');

  //filter for repeated names
  const filterMatched = spliTeam?.filter(value => splitClub?.includes(value));

  //finding match and replacing it
  const matchTeams = trimTeamName.match(trimClubName);
  const modifiedTeam = matchTeams
    ? trimTeamName.replace(matchTeams, '').trim()
    : filterTeamTitle.trim();

  // to remove repeated strings
  const removeRepeatedName = filterMatched.filter(
    (
      s => v =>
        s.has(v) || !s.add(v)
    )(new Set())
  );

  // checking whether name is repeated
  const filteredTeamName =
    !matchTeams && !filterTeamTitle ? removeRepeatedName.join(' ') : '';

  return (
    <button css={styles.base} onClick={onClick} >
      {TeamName && (
        <div css={styles.titleWrapper}>
          <Text
            id={`${CLUB_TEAM_CARD_TITLE}-${index}`}
            as="h4"
            size="6"
            css={styles.title}
          >
            {filteredTeamName
              ? filteredTeamName
              : modifiedTeam && modifiedTeam !== '-' && modifiedTeam !== '.'
              ? modifiedTeam
              : TeamName}
          </Text>
        </div>
      )}
      <div css={styles.infoWrapper}>
        {League && (
          <div>
            <strong css={styles.strongFormat}>League:</strong>
            <Text css={styles.infoValue}>{League}</Text>
          </div>
        )}
        {Division && (
          <div>
            <strong css={styles.strongFormat}>Division:</strong>
            <Text css={styles.infoValue}>{Division}</Text>
          </div>
        )}
        <div css={styles.formatWrapper}>
          <Text>
            <HiddenContent>Format </HiddenContent>
            {FootballType}
          </Text>
          <Text>
            <HiddenContent>Days </HiddenContent>
            {PlaysOnDay}
          </Text>
        </div>
        {address && (
          <div>
            <strong css={styles.strongFormat}>Ground Address:</strong>
            <Text css={styles.infoAddress}>{address}</Text>
          </div>
        )}
        {typeof onClick === 'function' && (
          <div css={styles.linkWrapper}>
            <LinkButton
              id={`${CLUB_TEAM_CARD_CTA_CONTACT}-${index}`}
              aria-label={`Show more about Team - opens in a new tab`}
            >
              Show more <span css={styles.TeamName}>for {TeamName}</span>
              <RightArrow css={styles.externalLink} />
            </LinkButton>
          </div>
        )}
      </div>
    </button>
  );
};

export default TeamCard;
