import publicPath from '../../utils/publicPath';

const venuePage = {
  hero: {
    images: {
      desktop: {
        src: publicPath(
          '/images/desktop/recommendations/provider-center-hero@x2.jpg'
        ),
      },
      mobile: {
        src: publicPath(
          '/images/mobile/recommendations/provider-center-hero@x2.jpg'
        ),
      },
    },
  },
  faInitiatives: [
    {
      id: 1,
      description: 'Fun and friendly football for girls aged 5-11. ',
      href: 'https://www.englandfootball.com/play/womens-and-girls-football/weetabix-wildcats',
      initiativeLogo: {
        desktop: {
          src: publicPath('/images/desktop/home/wildcats-logo.svg'),
        },
        mobile: {
          src: publicPath('/images/desktop/home/wildcats-logo.svg'),
        },
      },
      images: {
        desktop: {
          src: publicPath('/images/desktop/venue/fa-events/wildcats.png'),
          width: 754,
        },
        mobile: {
          src: publicPath('/images/desktop/venue/fa-events/wildcats.png'),
          width: 754,
        },
      },
    },
    {
      id: 2,
      description:
        'Casual football for everyone. Fancy a game? Just say, Just Play. ',
      href: 'https://www.englandfootball.com/play/adult-football/just-play',
      initiativeLogo: {
        desktop: {
          src: publicPath('/images/desktop/home/just-play-logo.svg'),
        },
        mobile: {
          src: publicPath('/images/desktop/home/just-play-logo.svg'),
        },
      },
      images: {
        desktop: {
          src: publicPath('/images/desktop/venue/fa-events/just-play.png'),
          width: 754,
        },
        mobile: {
          src: publicPath('/images/desktop/venue/fa-events/just-play.jpg'),
          width: 754,
        },
      },
    },
    {
      id: 3,
      description:
        'Stay active with small-sided Walking Football for over 50s.',
      href: 'https://www.englandfootball.com/play/age-uk',
      initiativeLogo: {
        desktop: {
          src: publicPath('/images/desktop/home/age-uk-logo.svg'),
        },
        mobile: {
          src: publicPath('/images/desktop/home/age-uk-logo.svg'),
        },
      },
      images: {
        desktop: {
          src: publicPath('/images/desktop/home/age-uk.png'),
          width: 752,
        },
        mobile: {
          src: publicPath('/images/desktop/home/age-uk.png'),
          width: 752,
        },
      },
    },
  ],
};

export default venuePage;
