import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { utility, colors, spacing, borderRadius } = useTheme();

  return {
    btn: primary => css`
      ${utility.buttonDefault};
      font-size: 0.875rem;
      font-weight: 400;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      color: ${colors.navy};
      background-color: ${colors.lightGrey};
      border-radius: ${borderRadius.s};
      padding: 0.85rem ${spacing.s};
      transition: all 0.3s ease-in;
      text-decoration: none;
      outline: 0;

      &:not(:disabled) {
        &:hover {
          background-color: ${colors.lightBlue};
        }
      }

      ${primary &&
      css`
        color: ${colors.white};
        background-color: ${colors.blue};

        &:not(:disabled) {
          &:hover {
            background-color: ${colors.darkBlue};
          }
        }
      `}

      &:disabled {
        background-color: ${colors.lightGrey};
        color: ${colors.darkGrey};
      }
    `,
  };
};
