import { createContext, useCallback, useContext, useState } from 'react';

import {
  CLUB,
  DONT_MIND,
  KICKABOUT_CASUAL,
  RECOMMENDATIONS_MO_CASUAL,
  RECOMMENDATIONS_MO_CLUB,
} from '../const';
import {
  getLocalItem,
  removeLocalItem,
  setLocalItem,
} from '../hooks/useLocalStorage';

const localKeyMapping = {
  [KICKABOUT_CASUAL]: RECOMMENDATIONS_MO_CASUAL,
  [CLUB]: RECOMMENDATIONS_MO_CLUB,
  [DONT_MIND]: RECOMMENDATIONS_MO_CLUB,
};

const localClubRecommendations = getLocalItem(RECOMMENDATIONS_MO_CLUB);
const clubRecommendationsValue =
  localClubRecommendations !== 'undefined'
    ? JSON.parse(localClubRecommendations)
    : null;
const localCasualRecommendations = getLocalItem(RECOMMENDATIONS_MO_CASUAL);
const casualRecommendationsValue =
  localCasualRecommendations !== 'undefined'
    ? JSON.parse(localCasualRecommendations)
    : null;

export const MORecommendationsContext = createContext();

// From the api we receive the next page of each football type
// so we need to merge the results for each of them
const mergeRecommendations = (oldRecommendationss, newRecommendations) => {
  const oldRecommendations = [];
  oldRecommendations.push(oldRecommendationss);

  const arrayKey = oldRecommendations[0]?.RecommendationClubCartDto
    ? 'RecommendationClubCartDto'
    : oldRecommendations[0]?.RecommendationCentreCartDto?.length < 0
    ? 'RecommendationProviderCartDto'
    : 'NULL';
  const arrayData = `${
    arrayKey === 'NULL' ? 'RecommendationProviderCartDto' : arrayKey
  }`;
  const arrayKeyData = `${
    arrayKey === 'NULL'
      ? 'RecommendationCentreCartDto'
      : 'RecommendationClubCartDto'
  }`;

  return [
    {
      ...oldRecommendations[0],
      [arrayData]: [
        ...oldRecommendations[0][arrayData],
        ...(arrayKeyData === 'RecommendationCentreCartDto'
          ? newRecommendations[0][arrayKeyData]
          : []),
        ...newRecommendations[0][arrayData],
      ],
    },
  ];
};

export const MORecommendationsProvider = ({ children }) => {
  const [clubRecommendationsContext, setClubRecommendationsContext] = useState(
    clubRecommendationsValue
  );
  const [casualRecommendationsContext, setCasualRecommendationsContext] =
    useState(casualRecommendationsValue);

  const setMORecommendations = useCallback((data, type = CLUB) => {
    const sortedData = [];
    sortedData.push(data);
    const localKey = localKeyMapping[type];
    setLocalItem(localKey, JSON.stringify(data));

    if (type === KICKABOUT_CASUAL) {
      setCasualRecommendationsContext(sortedData[0]);
      return;
    }

    setClubRecommendationsContext(data);
  }, []);

  // we export the add recommendations method to avoid
  // having the merging logic in each component
  const addMORecommendations = useCallback(
    (data, type) => {
      if (data.length === 0) {
        return;
      }

      if (type === KICKABOUT_CASUAL) {
        const newData = mergeRecommendations(
          casualRecommendationsContext,
          data
        );

        setCasualRecommendationsContext(newData[0]);
        setLocalItem(RECOMMENDATIONS_MO_CASUAL, JSON.stringify(newData[0]));
        return;
      }

      const newData = mergeRecommendations(clubRecommendationsContext, data);

      setClubRecommendationsContext(newData[0]);
      setLocalItem(RECOMMENDATIONS_MO_CLUB, JSON.stringify(newData[0]));
    },
    [casualRecommendationsContext, clubRecommendationsContext]
  );

  const resetMORecommendations = () => {
    removeLocalItem(RECOMMENDATIONS_MO_CLUB);
    setClubRecommendationsContext(null);
    removeLocalItem(RECOMMENDATIONS_MO_CASUAL);
    setCasualRecommendationsContext(null);
  };

  return (
    <MORecommendationsContext.Provider
      value={{
        clubMORecommendations: clubRecommendationsContext,
        casualMORecommendations: casualRecommendationsContext,
        setMORecommendations,
        addMORecommendations,
        resetMORecommendations,
      }}
    >
      {children}
    </MORecommendationsContext.Provider>
  );
};

export const useMORecommendations = () => useContext(MORecommendationsContext);
