import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, colors, borderRadius, spacing, mediaQueries } =
    useTheme();

  return {
    confirmation: css`
      display: grid;
      align-items: baseline;
      grid-gap: ${spacing.s};

      ${breakpoints.l} {
        grid-template-columns: 1fr 1fr;
        grid-gap: ${spacing.m};
        padding: ${spacing.xxl};

        button {
          &:focus:not(:hover) {
            text-decoration: underline;
          }
        }
      }

      ${mediaQueries.zoomedIn} {
        overflow: auto;
        height: 100%;
      }
    `,
    title: css`
      font-size: ${spacing.m};
      font-style: italic;
      line-height: 2.25rem;
      text-align: center;

      ${breakpoints.l} {
        text-align: left;
        font-size: 3.25rem;
        line-height: 3.125rem;
        outline: 0;
      }
    `,
    helpText: css`
      text-align: center;

      ${breakpoints.l} {
        text-align: left;
      }
    `,
    options: css`
      li {
        padding-bottom: ${spacing.xs};
      }
      button {
        width: 100%;
        font-size: 1.125rem;
        font-weight: 700;
        padding: 1.1rem ${spacing.s};
        border:  solid transparent;

        &:focus:not(:hover) {
          border: 3px solid ${colors.navy};
          border-radius: ${borderRadius.s};
          background-color: ${colors.white};
          outline: 0;
        }
      }

      ${mediaQueries.zoomedIn} {
        padding-left: 5px;
        padding-right: 5px;
      }
    `,
  };
};
