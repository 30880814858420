/** @jsxImportSource @emotion/react */
import { forwardRef, useLayoutEffect, useRef } from 'react';

import { recommendationsMapPageAnalytics } from '../../analyticsConsts';
import { EMPTY_STRING } from '../../const';
import HiddenContent from '../HiddenContent';
import { Pin } from '../Icons';
import Text from '../Text';
import useStyles from './styles';

const {
  CTA_PROVIDER_CLUB_CARD,
  PROVIDER_CLUB_CARD_TITLE,
} = recommendationsMapPageAnalytics;

const isAddressNullCheck = value => {
  return value !== null ? `${value},` : EMPTY_STRING;
};

const MOProviderClubCard = forwardRef(
  (
    {
      index,
      ClubName,
      ClubId,
      DistanceFromUserLocation,
      TeamsCartDto,
      TeamsCount,
      OfferedClubs,
      onClick,
      isSelected,
      Latitude,
      Longitude,
      ...rest
    },
    ref
  ) => {
    const cardRef = useRef();
    const styles = useStyles();
    const isTeamAvailable = TeamsCartDto && TeamsCartDto.length > 0;

    const onCheckAddress = address => {
      return `${
        address.AddressLine1 || address.AddressLine2
      }, ${isAddressNullCheck(address.AddressLine3)}
    ${isAddressNullCheck(address.City)} ${isAddressNullCheck(
        address.PostCode
      )}`;
    };

    const handleKeyPress = event => {
      const enterOrSpace = event.which === 13 || event.which === 32;

      if (enterOrSpace) {
        event.preventDefault();
        onClick(ClubId);
      }
    };

    // if the card gets selected move the focus to it
    useLayoutEffect(() => {
      if (isSelected && cardRef.current) {
        setTimeout(() => {
          cardRef.current.focus();
          cardRef.current.scrollIntoView({
            behaviour: 'smooth',
            block: 'nearest',
          });
        }, 300);
      }
    }, [isSelected]);

    const accessibleProps = isSelected ? {} : { role: 'button', tabIndex: 0 };

    return (
      <li tabIndex={-1} ref={ref}>
        <div
          {...accessibleProps}
          id={`${CTA_PROVIDER_CLUB_CARD}-${index}`}
          aria-expanded={isSelected}
          css={styles.base}
          onClick={() => onClick(ClubId)}
          onKeyPress={handleKeyPress}
          ref={cardRef}
        >
          <div css={styles.titleWrapper(ClubName)}>
            <Text
              id={`${PROVIDER_CLUB_CARD_TITLE}-${index}`}
              as="h3"
              size="6"
              css={styles.title(ClubName)}
            >
              {ClubName}
            </Text>
          </div>
          <div css={styles.info(isTeamAvailable)}>
            <div css={styles.address}>{onCheckAddress(rest)}</div>
            <div css={styles.distance}>
              <HiddenContent>Distance </HiddenContent>
              <Pin />
              <span>{DistanceFromUserLocation} Miles</span>
            </div>
          </div>
          {isTeamAvailable && (
            <div css={styles.search}>
              This club has {TeamsCount} teams relevant to your search
            </div>
          )}
        </div>
      </li>
    );
  }
);

export default MOProviderClubCard;
