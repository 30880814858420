import { css } from '@emotion/react';

import { SCROLL_UP } from '../../const';
import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const {
    screenSizes,
    typography,
    breakpoints,
    spacing,
    colors,
    heights,
    borderRadius,
  } = useTheme();

  return {
    hero: (
      { mobile, desktop },
      overlayVariation,
      scrollDirection,
      headerHeight,
      isRec
    ) => {
      return css`
        display: grid;
        align-items: end;
        position: fixed;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        grid-template-rows: 70% 30%;
        align-items: center;
        background: linear-gradient(
            to bottom,
            rgba(0, 0, 0, 0) 20%,
            rgba(1, 30, 65) ${isRec === 'true' ? '55%' : '75%'},
            rgba(1, 30, 65) 100%
          ),
          url(${mobile.src}) 75% 0 no-repeat;
        background-size: cover;
        padding: 0 ${spacing.s};
        margin-top: ${scrollDirection === SCROLL_UP
          ? 0
          : heights.mainNav.mobile};

        ${breakpoints.m} {
          grid-template-rows: 60% 40%;
          align-items: center;
          background: linear-gradient(
              to bottom,
              rgba(0, 0, 0, 0) 20%,
              rgba(1, 30, 65) 75%,
              rgba(1, 30, 65) 100%
            ),
            url(${desktop.src}) center 0 no-repeat;
          background-size: cover;
          margin-top: ${scrollDirection === SCROLL_UP ? 0 : headerHeight};

          &::before {
            top: 10vh;
            left: 0;
            width: 100%;
            height: 100vh;
            z-index: 1;
            outline: 0;

            ${breakpoints.l} {
              height: 700px;
              top: calc(100vh - 95vh);
            }
          }

          &::after {
            background: url(${publicPath(
                '/images/common/discover-football-watermark.svg'
              )})
              right center no-repeat;
          }
        }
      `;
    },
    textWrapper: hasLoaded => css`
      position: relative;
      z-index: 1;
      align-self: end;
      opacity: 0;
      transition: opacity 0.5s;
      width: 90vw;
      max-width: 390px;
      margin: 0 auto;
      margin: 0px auto calc(27vh);

      ${hasLoaded &&
      css`
        opacity: 1;
      `}

      &.fadeOut {
        opacity: 0.3;
      }

      ${breakpoints.m} {
        max-width: ${screenSizes.xl};
      }
    `,
    mainText: isHomePage => css`
      ${typography.headings[3].mobile};
      text-transform: capitalize;
      z-index: 2;
      position: relative;
      color: ${colors.white};
      max-width: unset;
      transition: opacity 0.5s;
      text-align: center;
      margin: auto;
      ${isHomePage &&
      css`
        text-transform: uppercase;
        max-width: 50%;
        left: 0;
        text-align: left;
        margin: 0;
      `}

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -15px;
        width: 70px;
        border-bottom: ${borderRadius.s} solid ${colors.red};
        left: Calc(100% - 61%);

        ${isHomePage &&
        css`
          left: 0;
        `}

        ${breakpoints.m} {
          left: Calc(100% - 53%);
        }
      }

      ${breakpoints.m} {
        ${typography.headings[1].desktop};
        max-width: 100%;
        font-family: FS Dillon;
        text-align: center;
      }

      ${breakpoints.l} {
        &::before {
          left: -8rem;
        }
      }
    `,
    secondaryText: css`
      color: ${colors.white};
      margin-top: 1.8rem;

      ${breakpoints.m} {
        margin-top: ${spacing.m};
        text-align: center;
        padding: 0.2rem 0 ${spacing.m} 0;
      }
    `,
    cta: css`
      background-color: ${colors.red};
      color: ${colors.white};
      text-transform: uppercase;
      margin-top: 1.5rem;
      border: 3px solid transparent;

      ${breakpoints.m} {
        margin: auto;
        max-width: 325px;
      }
        &:focus {
          outline: 0;
          background-color: transparent;
          border: 3px solid ${colors.white};
          border-radius: 0.25rem;
        }
        &:hover{
          border: 3px solid transparent;
          border-radius: 0rem;
        }
      }
    `,
  };
};
