import { css } from '@emotion/react';

import { EMPTY_STRING, SCROLL_DOWN } from '../../const';
import useTheme from '../../hooks/useTheme';

const panelWidth = '310px';

export default () => {
  const { colors, spacing, breakpoints, utility, borderRadius, heights } =
    useTheme();

  return {
    panel: (
      isToggled,
      isLargeDesktop,
      scrolldirectionContext,
      headerHeight
    ) => css`
      display: grid;
      grid-template-rows: ${!isLargeDesktop ? '10% 90%' : '5% 95%'};
      position: fixed;
      top: ${scrolldirectionContext === SCROLL_DOWN ? `${headerHeight}` : '0'};
      left: -375px;
      width: 375px;
      height: ${scrolldirectionContext === SCROLL_DOWN
        ? `calc(100vh - ${headerHeight})`
        : '100vh'};
      z-index: 20001;
      padding: ${spacing.m} ${spacing.m} ${spacing.s};
      background-color: ${colors.white};
      border-right: 1px solid ${colors.lightGrey};
      border-top: 1px solid ${colors.lightGrey};
      transition: left 0.3s ease-out;

      ${isToggled &&
      css`
        left: 0;
      `}

      ${breakpoints.m} {
        top: ${scrolldirectionContext === 'down'
          ? ` ${heights.mainNav.desktop}`
          : '0'};
        height: ${scrolldirectionContext === SCROLL_DOWN
          ? `calc(100vh - ${headerHeight})`
          : '100vh'};
      }
    `,
    top: css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: center;
      max-width: ${panelWidth};
    `,
    filters: isNested => css`
      overflow: ${isNested ? 'hidden' : EMPTY_STRING};
      margin-top: ${spacing.s};
    `,
    inner: isNested => css`
      display: grid;
      grid-template-columns: repeat(2, ${panelWidth});
      width: ${panelWidth};
      transform: translateX(0);
      transition: all 0.5s ease-out;

      ${isNested &&
      css`
        transform: translateX(-${panelWidth});
      `};
    `,
    column: css`
      outline: none;
      border-top: 1px solid ${colors.lightGrey};
    `,
    columnFooter: css`
      margin-top: ${spacing.s};
    `,
    title: css`
      position: relative;
      font-size: 1.75rem;
      color: ${colors.navy};

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: 0;
        width: 40%;
        height: ${borderRadius.s};
        background-color: ${colors.red};
      }

      ${breakpoints.l} {
        &::after {
          width: 60%;
        }
      }
    `,
    closeBtn: css`
      ${utility.buttonDefault};
      width: 30px;
      height: 30px;
      justify-self: end;
      border: ${borderRadius.s} solid transparent;
      color: ${colors.navy};
      &:focus {
        outline: 0;
        border: ${borderRadius.s} solid ${colors.navy};
      }
    `,
    noResults: css`
      color: ${colors.red};
      text-align: center;
      padding: ${spacing.s} 0;
    `,
    saveBtn: css`
      text-transform: uppercase;
      border: ${borderRadius.s} solid transparent;

      &:focus {
        outline: 0;
        border: ${borderRadius.s} solid ${colors.navy};
      }
    `,
    info: css`
      font-size: 0.875rem;
      line-height: 1.125rem;
      margin-top: ${spacing.s};
      color: ${colors.darkGrey};
    `,
    link: css`
      display: inline-block;
      outline: 0;
    `,
    scrollFilterData: css`
      height: Calc(100vh - 55%);
      overflow: auto;
      -webkit-overflow-scrolling: auto;
      padding-right: ${spacing.s};

      @media not all and (min-resolution: 0.001dpcm) {
        @supports (-webkit-appearance: none) {
          height: Calc(100vh - 60vh);
        }
      }
    `,
  };
};
