/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useReducer } from 'react';

import { EMPTY_STRING } from '../../const';
import useMediaQuery from '../../hooks/useMediaQuery';
import optionsReducer from '../../reducers/multiOptions';
import FilterWrapper from '../FilterWrapper';
import { Tick } from '../Icons';
import useStyles from './styles';

const MAX_OPTIONS = 6;

const FilterMultiple = ({
  onSave,
  preferenceKey,
  options,
  currentValue = '',
  areOptionsMandatory,
  ...rest
}) => {
  const styles = useStyles();
  const currentValueArray =
    (currentValue !== EMPTY_STRING && currentValue.split(',')) || [];
  const [optionsState, dispatch] = useReducer(
    optionsReducer,
    currentValueArray || []
  );
  const noOptionsSelected = optionsState.length === 0;
  const isLargeDesktop = useMediaQuery('screen and (min-width: 1680px)');
  const isMenuScrollable = options.length > MAX_OPTIONS && !isLargeDesktop;

  const onChange = event => {
    const selectedValue = event.target.value;
    const inOptions = !!optionsState.find(value => value === selectedValue);

    if (inOptions) {
      dispatch({ type: 'remove', payload: { value: selectedValue } });
    } else {
      dispatch({ type: 'add', payload: { value: selectedValue } });
    }
  };

  const onClick = () => {
    const filtersValue = optionsState.sort().join(',');

    onSave({ [preferenceKey]: filtersValue });
  };

  return (
    <FilterWrapper
      currentValue={currentValue}
      onClick={onClick}
      disabled={areOptionsMandatory ? noOptionsSelected : areOptionsMandatory}
      {...rest}
    >
      {options && (
        <div
          css={isMenuScrollable && styles.filterOptionWrapper}
          className="scroller"
        >
          <div css={styles.options}>
            {options.map(({ id, text, value: optionValue }) => {
              const isChecked = !!optionsState.find(
                value => value === optionValue
              );

              return (
                <label css={styles.label} key={id}>
                  <input
                    css={styles.checkbox}
                    type="checkbox"
                    id={`option-${id}`}
                    name={`option-${id}`}
                    value={optionValue}
                    onChange={onChange}
                  />
                  <span css={styles.option(isChecked)}>
                    {isChecked && <Tick css={styles.icon} />}
                    {text}
                  </span>
                </label>
              );
            })}
          </div>
        </div>
      )}
    </FilterWrapper>
  );
};

FilterMultiple.prototype = {
  onSave: PropTypes.func,
  preferenceKey: PropTypes.object,
  options: PropTypes.func,
  currentValue: PropTypes.string,
  areOptionsMandatory: PropTypes.bool,
};

export default FilterMultiple;
