import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors, spacing, borderRadius, typography, utility } = useTheme();

  return {
    filterItem: css`
      ${utility.buttonDefault};
      display: grid;
      grid-template-columns: 50% 40% 10%;
      align-items: center;
      width: 100%;
      padding: ${spacing.s} 0;
      border-bottom: 1px solid ${colors.lightGrey};
      outline: 0;

      @media only screen and (width: 768px) and (height: 1024px) and (orientation:portrait) {
        display: flex;
        column-gap: 30px;
      }
      
      svg {
        border: ${borderRadius.s} solid transparent;
        height: 23px;
        width: 23px;
        padding: ${borderRadius.s};
        margin-left: 3px;
        color: ${colors.navy};

        @media only screen and (width: 768px) and (height: 1024px) and (orientation: portrait) 
        {
          flex-basis: 10%;
        }
      }
       &:hover {
        outline: 0;
        background-color: ${colors.lightBlue};
      }

      &:focus {
          span:first-of-type {
          text-decoration: underline;
        }
        svg {
          border: ${borderRadius.s} solid ${colors.navy};
          border-radius: ${borderRadius.l};
          padding: ${borderRadius.s};
          height: 23px;
          width: 23px;
          margin-left: 3px;
          }
        }
      }
    }
    `,
    label: css`
      ${typography.headings[6].mobile};
      text-align: left;
      color: ${colors.navy};
      font-weight: 600;

      @media only screen and (width: 768px) and (height: 1024px) and (orientation: portrait) {
        flex-grow: 1;
        flex-basis: 50%;
      }
    `,
    value: hasValue => css`
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      border: 1px solid transparent;
      padding: 0.625rem ${spacing.s};
      color: ${colors.navy};

      @media only screen and (width: 768px) and (height: 1024px) and (orientation: portrait) {
        flex-basis: 40%;
      }

      ${hasValue &&
      css`
        border: 1px solid ${colors.blue};
        padding: 0.625rem ${spacing.s};

        &:hover {
          border: 1px solid ${colors.navy};
          padding: 0.625rem ${spacing.s};
        }
      `}
    `,
    arrow: css`
      justify-self: end;
    `,
  };
};
