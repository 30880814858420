import { createContext, useCallback, useContext, useState } from 'react';

import { ONBOARDING_PREFERENCES } from '../const';
import {
  getLocalItem,
  removeLocalItem,
  setLocalItem,
} from '../hooks/useLocalStorage';

const localPreferences = getLocalItem(ONBOARDING_PREFERENCES);
const preferencesValue = localPreferences ? JSON.parse(localPreferences) : {};

export const PreferencesContext = createContext();

export const PreferencesProvider = ({ children }) => {
  const [preferences, setPreferencesContext] = useState(preferencesValue);

  const setPreferences = useCallback(preferences => {
    setLocalItem(ONBOARDING_PREFERENCES, JSON.stringify(preferences));
    setPreferencesContext(preferences);
  }, []);

  const resetPreferences = () => {
    setPreferencesContext({});
    removeLocalItem(ONBOARDING_PREFERENCES);
  };

  return (
    <PreferencesContext.Provider
      value={{
        preferences,
        setPreferences,
        resetPreferences,
      }}
    >
      {children}
    </PreferencesContext.Provider>
  );
};

export const usePreferencesContext = () => useContext(PreferencesContext);
