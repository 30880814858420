import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { screenSizes, spacing } = useTheme();

  return {
    recommendations: hasLoaded => css`
      opacity: 0;
      transition: opacity 0.5s;
      transition-delay: 0.2s;

      ${hasLoaded &&
      css`
        opacity: 1;
      `}
    `,
    loadMore: css`
      width: 100%;
      max-width: ${screenSizes.xl};
    `,
    cta: css`
      padding: 0.75rem ${spacing.s};
      text-transform: uppercase;
      font-size: 0.875rem;
      font-weight: 400;
      max-width: 375px;
      margin: auto;
    `,
  };
};
