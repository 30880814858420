import { useEffect } from 'react';

export default () => {
  useEffect(() => {
    const recommendationsList = document.querySelector('[data-test-id="list"]');
    const heroText = document.querySelector('[data-test-id="hero-text"]');

    const listener = () => {
      if (!recommendationsList || !heroText) return;

      if (
        recommendationsList.getBoundingClientRect().top <=
        heroText.getBoundingClientRect().bottom - 100
      ) {
        heroText.classList.add('fadeOut');
      } else {
        heroText.classList.remove('fadeOut');
      }
    };
    document.addEventListener('scroll', listener);

    return () => {
      document.removeEventListener('scroll', listener);
    };
  }, []);
};
