/** @jsxImportSource @emotion/react */
import {
  InteractionRequiredAuthError,
  InteractionStatus,
} from '@azure/msal-browser';
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';

import { loginRequest } from './authConfig';
import b2cEnvVariables from './b2cEnvVariables';
import ErrorBoundary from './components/ErrorBoundary';
import GlobalStyles from './components/GlobalStyles';
import ContextProviders from './context/contextProviders';
import initGTagManager from './gtm';
import AppRoutes from './routes';
import { globalLogOut } from './services';

// GTM dataLayer is advised to keep at the top level
initGTagManager();

// if it's a iframe url then add class in body
window.location.href.indexOf('external') > -1
  ? document.body.classList.add('iframePage')
  : document.body.classList.remove('iframePage');

const appConfig = b2cEnvVariables(process.env.REACT_APP_ENVIRONMENT);
const App = () => {
  const { instance, inProgress, accounts } = useMsal();
  const [accessState, setAccessState] = useState();

  useEffect(() => {
    const accessTokenRequest = {
      scopes: appConfig.b2cScopes,
      account: accounts[0],
    };
    if (inProgress === InteractionStatus.None) {
      instance
        .acquireTokenSilent(accessTokenRequest)
        .then(accessTokenResponse => {
          // Acquire token silent success
          let accessToken = accessTokenResponse.accessToken;
          // calling global log-out API with access token
          setAccessState(accessToken);
          globalLogOut(
            accessToken,
            accounts[0]?.idTokenClaims?.sub,
            accounts[0]?.idTokenClaims?.lastLogonTime
          ).then(response => {
            if (response?.data?.status === 404) {
              localStorage.setItem(
                'rootUrl',
                localStorage.getItem('pathInfomation')
              );
              instance.logout(loginRequest);
              // revoking signin api after global logout
              localStorage.clear();
            }
          });
        })
        .catch(error => {
          if (error instanceof InteractionRequiredAuthError) {
            instance.acquireTokenRedirect(accessTokenRequest);
          }
        });
    }
  }, [instance, accounts, inProgress, accessState]);

  return (
    <div id="main">
      <GlobalStyles />
      <Router>
        <ErrorBoundary>
          <ContextProviders>
            <AppRoutes accesstoken={accessState} />
          </ContextProviders>
        </ErrorBoundary>
      </Router>
    </div>
  );
};

export default App;
