/**
 * We use a square to position our points with the following values
 *       (N)
 * (NW)7  0  1(NE)
 *  (W)6     2(E)
 * (SW)5  4  3(SE)
 *       (S)
 */
const getDirection = index => {
  const position = index % 8;
  const offset = Math.floor(index / 8) + 1;

  return { position, offset };
};
/**
 * Since we're not at the poles, we can use the dirty estimate that:
 * - 111,111 meters (111.111 km) in the y direction is 1 degree (of latitude)
 * - 111,111 * cos(latitude) meters in the x direction is 1 degree (of longitude)
 */
const generateClosePoint = (point, position, offset = 1) => {
  const direction = position >= 4 ? -1 : 1;
  const distance = 5 * offset * direction;

  const verticalSpace = distance / 111111;
  const horizontalSpace = distance / (111111 * Math.cos(point.Latitude));

  if (position === 0 || position === 4) {
    return {
      ...point,
      Latitude: point.Latitude + verticalSpace,
    };
  }

  if (position === 1 || position === 5) {
    return {
      Latitude: point.Latitude + verticalSpace,
      Longitude: point.Longitude + horizontalSpace,
    };
  }

  if (position === 2 || position === 6) {
    return {
      ...point,
      Longitude: point.Longitude + horizontalSpace,
    };
  }

  return {
    Latitude: point.Latitude - verticalSpace,
    Longitude: point.Longitude + horizontalSpace,
  };
};

const processLocationGroups = groupsArray => {
  return groupsArray?.reduce((acc, group) => {
    if (group.length === 1) {
      return [...acc, ...group];
    }

    const processedArray = group.map((location, index) => {
      const { position, offset } = getDirection(index);

      return {
        ...location,
        ...generateClosePoint(location, position, offset),
      };
    });

    return [...acc, ...processedArray];
  }, []);
};

/**
 * with this function we group the original array by coordinates,
 * each pair of lat/long is represented by an array and contains all the points
 * with the same coordinates
 */
const groupLocations = dataArray =>
  dataArray?.reduce((acc, current) => {
    if (!acc.length) {
      return [[current]];
    }

    const previousValue = acc[acc.length - 1];
    const { Latitude, Longitude } = current;

    // if the previous element has the same coordinates as the current element
    if (
      previousValue[0].Latitude === Latitude &&
      previousValue[0].Longitude === Longitude
    ) {
      // copy the array to avoid modifying directly
      const copiedArray = [...acc];
      // push the current element into the previous array
      copiedArray[copiedArray.length - 1].push(current);

      // return the copy
      return copiedArray;
    }

    // otherwise push the current element as a new set of coordinates
    return [...acc, [current]];
  }, []);

const normaliseRecommendations = data => {
  const groupedLocations = groupLocations(data);

  return processLocationGroups(groupedLocations);
};

export default normaliseRecommendations;
