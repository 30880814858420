/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';

import useStyles from './styles';

const LinkButton = ({ children, ...rest }) => {
  const styles = useStyles();

  return (
    <button css={styles.linkBtn} {...rest}>
      {children}
    </button>
  );
};

export default LinkButton;
