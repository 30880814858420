import publicPath from '../../utils/publicPath';

const recommendations = {
  hero: {
    1: {
      text: {
        main: 'Casual football recommendations',
      },
      images: {
        desktop: {
          src: publicPath('/images/desktop/recommendations/casual-hero@x1.jpg'),
        },
        mobile: {
          src: publicPath('/images/mobile/recommendations/casual-hero@x1.jpg'),
        },
      },
    },
    2: {
      text: {
        main: 'Club football recommendations',
      },
      images: {
        desktop: {
          src: publicPath('/images/desktop/recommendations/club-hero@x1.jpg'),
        },
        mobile: {
          src: publicPath('/images/mobile/recommendations/club-hero@x1.jpg'),
        },
      },
    },
    3: {
      text: {
        main: 'Football recommendations',
      },
      images: {
        desktop: {
          src: publicPath('/images/desktop/recommendations/mixed-hero@x2.jpg'),
        },
        mobile: {
          src: publicPath('/images/desktop/recommendations/mixed-hero@x2.jpg'),
        },
      },
    },
  },
  recommendations: [
    {
      FootballType: '11v11',
      IntroText: 'Get involved in competitive or social team football',
      Media: {
        src: publicPath('/images/desktop/recommendations/result.png'),
      },
    },
    {
      FootballType: 'Small Sided',
      IntroText:
        'Smaller formats of football for all ages, genders and abilities including matches up to 9-a-side and Mini-Soccer for kids',
      Media: {
        src: publicPath('/images/desktop/recommendations/result-2.png'),
      },
    },
    {
      FootballType: 'Futsal',
      IntroText: 'Get involved in competitive or social team football',
      Media: {
        src: publicPath('/images/desktop/recommendations/result.png'),
      },
    },
    {
      FootballType: 'Traditional',
      IntroText: 'Get involved in competitive or social team football',
      Media: {
        src: publicPath('/images/desktop/recommendations/result.png'),
      },
    },
    {
      FootballType: 'Futsal Extra',
      IntroText: 'Get involved in competitive or social team football',
      Media: {
        src: publicPath('/images/desktop/recommendations/result.png'),
      },
    },
    {
      FootballType: 'Futsal Extra 2',
      IntroText: 'Get involved in competitive or social team football',
      Media: {
        src: publicPath('/images/desktop/recommendations/result.png'),
      },
    },
    {
      FootballType: 'Futsal Extra 3',
      IntroText: 'Get involved in competitive or social team football',
      Media: {
        src: publicPath('/images/desktop/recommendations/result.png'),
      },
    },
  ],
};

export default recommendations;
