import { filterFormatters } from '../../components/FiltersPanel/filtersConfig';
import {
  FILTERS_APPLIED,
  FILTERS_APPLIED_CASUAL,
  LATITUDE_ID,
  LONGITUDE_ID,
} from '../../const';
import useLocalStorage from '../useLocalStorage';

// Removes "Latitude" and "Longitude" from filters
// as they are only needed for the API call
// Applies filter formatting to values
const normaliseFilterObject = obj => {
  const { [LATITUDE_ID]: lat, [LONGITUDE_ID]: lon, ...rest } = obj;

  for (const filterKey in rest) {
    // If filter has formatter then apply to value
    if (typeof filterFormatters[filterKey] === 'function') {
      rest[filterKey] = filterFormatters[filterKey](rest[filterKey]);
    }
  }

  return rest;
};

export default () => {
  const { setLocalItem, getLocalItem, removeLocalItem } = useLocalStorage();

  const currentFiltersLocal = getLocalItem(FILTERS_APPLIED);
  const currentFiltersParsed = JSON.parse(currentFiltersLocal) || {};

  const currentFiltersCasualLocal = getLocalItem(FILTERS_APPLIED_CASUAL);
  const currentFiltersCasualParsed =
    JSON.parse(currentFiltersCasualLocal) || {};

  const updateFiltersStorage = filtersApplied => {
    const normalised = normaliseFilterObject(filtersApplied);

    setLocalItem(
      FILTERS_APPLIED,
      JSON.stringify({ ...currentFiltersParsed, ...normalised })
    );
  };

  const updateFiltersCasualStorage = filtersApplied => {
    setLocalItem(
      FILTERS_APPLIED_CASUAL,
      JSON.stringify({ ...currentFiltersCasualParsed, ...filtersApplied })
    );
  };

  const clearFiltersStorage = () => {
    removeLocalItem(FILTERS_APPLIED);
    removeLocalItem(FILTERS_APPLIED_CASUAL);
  };

  return {
    clearFiltersStorage,
    updateFiltersStorage,
    updateFiltersCasualStorage,
    currentFiltersParsed,
    currentFiltersCasualParsed,
  };
};
