import { createContext, useCallback, useContext, useState } from 'react';

const SetPathInfoContext = createContext();

export const SetPathInfoProvider = ({ children }) => {
  const [pathInfo, setPathInfo] = useState("/");

  // Filter list item click handler
  const setPathInformation = useCallback(value => {
    setPathInfo(value);
  }, []);

  return (
    <SetPathInfoContext.Provider
      value={{ pathInfo, setPathInformation }}
    >
      {children}
    </SetPathInfoContext.Provider>
  );
};

export const useSetPathInfoContext = () =>
  useContext(SetPathInfoContext);
