/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Fragment } from 'react';
import { useHistory } from 'react-router';

import { SELECTED_RECOMMENDATIONS_TYPE_ID } from '../../const';
import { useMLRecommendations } from '../../context/mlRecommendations';
import { usePreferencesContext } from '../../context/preferences';
import HiddenContent from '../HiddenContent';
import { Crest, Pin } from '../Icons';
import Link from '../Link';
import Text from '../Text';
import useStyles from './styles';

const ClubCard = ({
  ClubId,
  analyticsId,
  ClubName,
  CrestImage,
  TeamCount,
  TeamsPlayDays,
  TeamsCartDto,
  DistanceFromUserLocation,
  ageTitle,
  footballType,
  MlTeamsCartDto,
  isMLCard = false,
}) => {
  const history = useHistory();
  const { preferences, setPreferences } = usePreferencesContext();
  const teams = isMLCard ? MlTeamsCartDto : TeamsCartDto;
  const styles = useStyles();
  const hasTeams = Array.isArray(teams) && teams.length > 0;
  const cardUrl = `/club/${ClubId}`;
  const { setMLCard } = useMLRecommendations();

  const setRecommendationsPreference = e => {
    // we want to block the default link and only navigate
    // when we are sure the preferences have been set
    e.preventDefault();

    setPreferences({
      ...preferences,
      [SELECTED_RECOMMENDATIONS_TYPE_ID]: footballType,
    });

    // set clickable card is ML or not
    setMLCard(isMLCard);

    history.push(cardUrl);
  };

  return (
    <Link
      id={analyticsId}
      css={styles.base}
      onClick={setRecommendationsPreference}
      to={cardUrl}
    >
      <div css={styles.titleWrapper}>
        {CrestImage ? (
          <img css={styles.crest} src={CrestImage} alt="" />
        ) : (
          <Crest css={styles.crestDefault} />
        )}
        <Text as="h3" css={styles.title}>
          {ClubName}
        </Text>
      </div>
      <div css={styles.info}>
        {hasTeams && (
          <Fragment>
            <Text css={styles.teams}>
              <HiddenContent>
                {TeamCount === 1 && 'Suitable teams '}
              </HiddenContent>
              {TeamCount === 1
                ? `${teams[0].TeamName}`
                : `${TeamCount} suitable teams`}
            </Text>
            <Text css={styles.days}>
              <HiddenContent>Play on </HiddenContent>
              {TeamsPlayDays}
            </Text>
            <Text css={styles.distance}>
              <HiddenContent>Distance </HiddenContent>
              <Pin />
              {DistanceFromUserLocation} Miles
            </Text>
          </Fragment>
        )}
      </div>
    </Link>
  );
};

ClubCard.prototype = {
  ClubId: PropTypes.number,
  analyticsId: PropTypes.string,
  ClubName: PropTypes.string,
  TeamCount: PropTypes.number,
  TeamsPlayDays: PropTypes.string,
  TeamsCartDto: PropTypes.object,
  DistanceFromUserLocation: PropTypes.number,
  ageTitle: PropTypes.string,
  footballType: PropTypes.string,
  MlTeamsCartDto: PropTypes.object,
  isMLCard: PropTypes.bool,
};

export default ClubCard;
