import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { utility } = useTheme();

  return {
    navigation: css`
      width: 100%;
      position: fixed;
      z-index: 17;
    `,
    overlay: css`
      ${utility.overlay};
    `,
  };
};
