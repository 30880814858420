import { createContext, useCallback, useContext, useState } from 'react';

import { getLocalItem, setLocalItem } from '../hooks/useLocalStorage';

const SetSelectedClubDetailsContext = createContext();

export const SetSelectedClubDetailsProvider = ({ children }) => {
  const [selectedClubDetails, setSelectedClubDetails] = useState(
    JSON.parse(getLocalItem('clubDetails'))
  );

  const setSelectedClubDetailsValue = useCallback(value => {
    setLocalItem('clubDetails', JSON.stringify(value));
    setSelectedClubDetails(value);
  }, []);

  return (
    <SetSelectedClubDetailsContext.Provider
      value={{ selectedClubDetails, setSelectedClubDetailsValue }}
    >
      {children}
    </SetSelectedClubDetailsContext.Provider>
  );
};

export const useSetSelectedClubDetailsContext = () =>
  useContext(SetSelectedClubDetailsContext);