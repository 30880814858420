import {
  DISABILITY_OPTION_ID,
  MYSELF,
  NO,
  NOT_SURE,
  SOMEONE_ELSE,
  YES,
} from '../../../const';

export default [
  {
    id: '191d8b36-171e-4227-836f-58a0b597744a',
    questionOrder: 6,
    readableId: DISABILITY_OPTION_ID,
    type: 'single',
    title: {
      [MYSELF]: 'Are you looking for disability options?',
      [SOMEONE_ELSE]: 'Are they looking for disability options?',
    },
    answerTypeQuestion: [
      { DisabilityId: 1 },
      { DisabilityId: 2 },
      { DisabilityId: 3 },
      { DisabilityId: 4 },
      { DisabilityId: 5 },
      { DisabilityId: 6 },
      { DisabilityId: 7 },
      { DisabilityId: 8 },
      { DisabilityId: 9 },
      { DisabilityId: 10 },
      { DisabilityId: 11 },
      { DisabilityId: 12 },
      { DisabilityId: 13 },
    ],
    answers: [
      {
        answerId: 1,
        analyticsId: 'disability_yes',
        title: 'Yes',
        answerType: 'basic',
        value: YES,
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: 'e1f34caf-2e24-4eb6-8134-1c27fec41aa4',
          },
        ],
      },
      {
        answerId: 2,
        analyticsId: 'disability_no',
        title: 'No',
        answerType: 'basic',
        value: NO,
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: '703663f0-3c99-4c4e-ac80-62184ad0546f',
          },
        ],
      },
      {
        answerId: 3,
        analyticsId: 'disability_not_sure',
        title: 'Not sure',
        answerType: 'secondary',
        value: NOT_SURE,
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: '703663f0-3c99-4c4e-ac80-62184ad0546f',
          },
        ],
      },
    ],
  },
];
