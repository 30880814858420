import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const {
    breakpoints,
    colors,
    spacing,
  } = useTheme();

  return {
    base: css`
      height: 100%;
      display: grid;
      grid-template-columns: repeat(auto-fill, minmax(298px, 1fr));

      ${breakpoints.l} {
        grid-template-columns: 1fr 1fr;
        grid-gap: ${spacing.m};
      }
    `,
    map: css`
      min-height: 154px;
      max-height: 154px;

      ${breakpoints.m} {
        min-height: 184px;
        max-height: 184px;
      }
    `,
    locationLink: css`
      width: auto;
      padding-left: 0;
      color: ${colors.white};
      background-color: ${colors.dodgerBlue};
    `,
    locationLinkArrow: css`
      margin-left: ${spacing.xs};
      color: ${colors.red};
    `,
    addressWrapper: css`
    p {
      white-space: pre-wrap;
      color: ${colors.darkGrey};
      text-transform: capitalize;
    }

    h4 {
       font-size: ${spacing.s};
       color: ${colors.navy};
    }
    `,
    sectionTitle: css`
      padding-bottom: ${spacing.s};
      color: ${colors.navy};
      text-transform: capitalize;
    `,
    iconColor: css`
      color: ${colors.white};
    `,
    link: css`
      // background: url(${publicPath('/images/mobile/club/map-placeholder.jpg')}) 0 0 no-repeat;
      background-size: contain;
      min-height: 154px;
      max-height: 154px;
      padding: 0;

      ${breakpoints.l} {
        // background: url(${publicPath('/images/desktop/club/map-placeholder.jpg')}) 0 0 no-repeat; 
        background-size: cover;
        min-height: 184px;
        max-height: 184px;
      }
    `,
    detailsWrapper: css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      align-items: flex-start;
      padding-top: ${spacing.s};

      ${breakpoints.m} {
        grid-template-columns: auto;
      }
    `,
    locationDetailsWrapper: css`
      padding: ${spacing.m} 0 ${spacing.m};
    `,
  };
};
