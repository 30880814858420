import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import { EXPANDED, SHRUNK } from '../../reducers/trayState';

export default () => {
  const { colors, breakpoints, spacing, borderRadius, heights } = useTheme();

  return {
    base: isListView => css`
      position: relative;
      height: 100%;
      display: grid;
      justify-content: center;
      grid-template-areas:
        'header'
        'map'
        'results';
      grid-template-rows: auto 1fr auto;
      grid-template-columns: 1fr;

      ${breakpoints.m} {
        grid-template-areas:
          'header map'
          'results map';
        grid-template-columns: min(50%, 375px) 1fr;
      }

      ${breakpoints.l} {
        grid-template-rows: auto calc(
            100vh - ${heights.mainNav.desktop} - ${heights.filterNav}
          );
        grid-template-columns: 375px 1fr;
      }

      ${breakpoints.l} {
        grid-template-rows: auto calc(
            100vh - ${heights.mainNav.desktop} - ${heights.filterNav}
          );
      }

      ${!isListView &&
      css`
        ${breakpoints.m} {
          // TODO: fix the map view
          grid-template-areas:
            'header header'
            'map map';
        }

        ${breakpoints.l} {
          grid-template-areas:
            'header map'
            'results map';
        }
      `}
    `,
    header: (headerHeight) => css`
      grid-area: header;
      padding: ${spacing.s};
      background: ${colors.white};
      margin-top: ${heights.filterNav};
      border-top: 1px solid ${colors.lightGrey};
      border-bottom: 1px solid ${colors.lightGrey};

      ${breakpoints.m} {
        // remove it once header changes are completed.
        // margin-top: calc(${headerHeight} + ${heights.filterNav});
        margin-top: ${heights.mainNav.desktop};
      }

      ${breakpoints.l} {
        margin-top: ${headerHeight};
      }
    `,
    backLink: css`
      color: ${colors.navy};

      &:focus {
        outline: 0;
        p {
          text-decoration: underline;
        }
      }
    `,
    headerTitle: css`
      display: inline-block;
    `,
    mapAreaWrapper: (headerHeight) => css`
      grid-area: map;

      ${breakpoints.l} {
        margin-top: ${headerHeight};
      }
    `,
    providerCardWrapper: (isListView, trayState) => css`
      position: relative;
      grid-area: results;
      height: clamp(170px, 65vh, 280px);
      margin-top: -2rem;
      padding: ${spacing.s};
      background: ${colors.lightGrey};
      border-radius: 20px 20px 0 0;
      box-shadow: 0px 4px 6px ${colors.black};
      align-items: center;
      background-color: ${colors.lightGrey};
      transition: all 0.2s ease-in-out;
      overflow: hidden;
      z-index: 100;

      ${trayState === EXPANDED &&
      css`
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        height: 75vh;
      `}

      ${trayState === SHRUNK &&
      css`
        transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        height: 55px;
      `}

      ${breakpoints.m} {
        margin-top: 0;
        border-radius: 0;
        height: 100%;
        z-index: unset;
      }

      ${breakpoints.l} {
        padding: 0;
        box-shadow: none;
      }

      ${!isListView &&
      css`
        padding-bottom: 0;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        height: calc(317px + ${spacing.xs} + ${spacing.s});
        overflow: visible;
        box-sizing: content-box;

        ${breakpoints.m} {
          position: absolute;
          bottom: 0;
          left: 0;
          border-radius: 20px 20px 0 0;
          height: auto;
          z-index: 100;
        }

        ${breakpoints.l} {
          position: relative;
          bottom: unset;
          left: unset;
          border-radius: 0;
          height: 100%;
        }
      `}
    `,

    btnWrapper: css`
      padding: ${spacing.s} 0 1.5rem 0;
    `,

    scrollContainer: isListView => css`
      position: relative;
      height: 90%;
      overflow: auto;

      ${isListView &&
      css`
        &:before {
          content: '';
          position: absolute;
          top: -${spacing.xs};
          left: 0;
          width: 100%;
          height: ${spacing.m};
          background: transparent;
          background: linear-gradient(
            180deg,
            ${colors.lightGrey} 0%,
            rgba(255, 255, 255, 0) 100%
          );
          z-index: 10;
        }
      `}
    `,
    providerCards: isListView => css`
      border-radius: ${borderRadius.l};
      align-items: center;

      ${!isListView &&
      css`
        padding: 0;
        display: contents;
        overflow-y: hidden;

        ${breakpoints.l} {
          display: block;
        }

        [aria-expanded='false'] {
          display: none;
        }

        ${breakpoints.l} {
          [aria-expanded='false'] {
            display: block;
          }
        }
      `}
    `,
    resultCard: css`
      border: ${borderRadius.m} solid ${colors.red};

      &:hover,
      &:focus {
        outline: 0;
        border: ${borderRadius.m} solid ${colors.navy};
      }
    `,
    totalResults: isListView => css`
      display: none;
      text-align: center;
      padding: ${spacing.s} 0;
      position: relative;

      span {
        padding-top: 5px;
        border-top: ${borderRadius.s} solid ${colors.black};
      }

      ${breakpoints.m} {
        span {
          padding-top: 0;
          border-top: 0;
        }
      }

      ${breakpoints.l} {
        display: block;
      }

      ${isListView &&
      css`
        display: block;
      `}
    `,
    primaryBtn: css`
      background-color: ${colors.blue};
      color: ${colors.white};
      border-radius: 50px;
      font-size: 14px;
      line-height: 18px;
      padding: 10px;
      font-weight: 400;
      text-transform: uppercase;
    `,
    backToWrapper: css`
      position: absolute;
      right: 10px;
      top: -3rem;
      z-index: 100;

      ${breakpoints.l} {
        display: none;
      }
    `,
    arrowRotate: css`
      transform: rotate(180deg);
      margin-right: 5px;
    `,
    loadMore: isListView => css`
      margin: 0 ${spacing.xs};
      text-transform: uppercase;
      button {
        text-transform: uppercase;
      }

      ${!isListView &&
      css`
        display: none;

        ${breakpoints.l} {
          display: block;
        }
      `}
    `,
    loader: css`
      margin-top: ${spacing.s};
      margin-bottom: ${spacing.s};
    `,
  };
};
