/** @jsxImportSource @emotion/react */

import { CHARLIMIT_HOMEPAGE } from '../../const';
import ExternalLink from '../ExternalLink';
import { RightArrow } from '../Icons';
import Text from '../Text';
import useStyles from './styles';

const QuickLinkCard = ({
  heading,
  description,
  images,
  indexVal,
  ctaId,
  href,
}) => {
  const styles = useStyles();

  return (
    <ExternalLink css={styles.cards} id={ctaId} href={href}>
      <div css={styles.innerWrapper}>
        <div css={styles.card}>
          <div css={styles.imgSection(images, indexVal)}></div>
          <div css={styles.infoSection}>
            <Text as='p' css={styles.header}>{heading}</Text>
            <Text css={styles.description}>
              {description.substring(0, CHARLIMIT_HOMEPAGE)}
            </Text>
            <Text
              css={styles.learnMore}
              aria-label={`Learn more about ${heading} - opens in a new window`}
            >
              <span>Learn More</span> <RightArrow css={styles.rightArrow} />
            </Text>
          </div>
        </div>
      </div>
    </ExternalLink>
  );
};

export default QuickLinkCard;
