import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { breakpoints, borderRadius, colors, spacing, utility } = useTheme();

  return {
    base: css`
      border: 1px solid ${colors.lightGrey};
      padding: 1.5rem 1.5rem ${spacing.s};
      border-radius: ${borderRadius.l};
      cursor: pointer;
      max-width: 300px;
      margin: 0 auto;
      height: 100%;
      background-color: ${colors.white};
      font-family: 'FS DILLON';

      &:hover {
        svg {
          transition: 0.4s ease-in;
          transform: translateX(50%);
        }
      }

      ${breakpoints.l} {
        max-width: 320px;
      }
    `,
    title: css`
      width: 90%;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      color: ${colors.navy};
      background: url(${publicPath(
          '/images/desktop/initiativeLogo/ef-logo.svg'
        )})
        no-repeat center left;
      background-size: contain;
      padding-left: ${spacing.m};
      background-position: ${borderRadius.s};
      text-transform: capitalize;
      text-align: left;
    `,
    titleWrapper: css`
      position: relative;
      margin-bottom: 1.5rem;
      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -14px;
        width: 27px;
        border-bottom: ${borderRadius.s} solid ${colors.red};
      }
    `,
    infoWrapper: css`
      display: grid;
      color: ${colors.navy};
      grid-gap: 0.3125rem;
      p {
        color: ${colors.darkGrey};
        max-width: 70%;
      }
      text-align: left;
    `,
    strongFormat: css`
      font-size: 16px;
    `,
    formatWrapper: css`
      > p {
        display: inline-block;
        &:not(:last-child):after {
          content: '|';
          padding: 0 ${spacing.xs};
          color: ${colors.lightGrey};
        }
      }
    `,
    externalLink: css`
      vertical-align: middle;
      color: ${colors.red};
      margin-left: 0.3125rem;
    `,
    infoAddress: css`
      text-transform: Capitalize;
    `,
    linkWrapper: css`
      padding-top: ${spacing.xs};
      button {
        padding: 0;
        text-decoration: none;
        font-weight: normal;
        text-transform: uppercase;

        &:focus {
          outline: 0;
          color: ${colors.navy};
          text-decoration: underline;
          svg {
            color: ${colors.navy};
          }
        }
      }
    `,
    infoValue: css`
      width: 75%;
      display: inline-block;
      padding-left: 0.3125rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      vertical-align: middle;
    `,
    TeamName: utility.checkboxHidden,
  };
};
