/** @jsxImportSource @emotion/react */

import { Fragment, useEffect, useRef, useState } from 'react';
import { Redirect } from 'react-router-dom';

import {
  CLUB,
  EMPTY_STRING,
  MINIONBOARDING_TABS,
  MO_PAGE_SIZE_VALUE,
  PAGE_NUMBER,
  RECOMMENDATIONS_PAGE_TITLE_MAPPING,
} from '../../const';
import { useMORecommendations } from '../../context/moRecommendations';
import { usePreferencesContext } from '../../context/preferences';
import { useScrollDirectionContext } from '../../context/scrollDirection';
import useErrorBoundary from '../../hooks/useErrorBoundary';
import useImage from '../../hooks/useImage';
import useMediaQuery from '../../hooks/useMediaQuery';
import usePageLoadInfo from '../../hooks/usePageLoadInfo';
import {
  getMiniOnboardingRecommendations,
  getMORecommendationsPageData,
} from '../../services';
import LinkTabs from '../LinkTabs';
import Loader from '../Loader';
import MOFAInitiativeEvent from '../MOFAInitiativeEvent';
import MOHero from '../MOHero';
import MORecommendationList from '../MORecommendationList';
import MoreResultsSection from '../MoreResultsSection';
import NoResultsRecommendations from '../NoResultsRecommendations';
import RecommendationFooter from '../RecommendationFooter';
import useStyles from './styles';

const SHOWN_INCREMENT = 5;

export const normalisedCenterData = recommendationsGroup => {
  return recommendationsGroup.map(date => {
    const totalSession = [];
    const totalProvider = [];
    const totalPitchFinder = [];
    const providerName = [];
    totalPitchFinder.push(date?.PitchFinderId);
    if (date.ProviderCartDto) {
      date?.ProviderCartDto?.map(group => {
        providerName.push(group.ProviderName);
        return totalProvider.push(group.ProviderId);
      });
    }
    date?.SessionCartDto &&
      date?.SessionCartDto?.map(group => {
        return group.sessionGroup.map(sessionData => {
          return totalSession.push(sessionData);
        });
      });
    return {
      ...date,
      totalSession,
      totalProvider,
      totalPitchFinder,
      providerName,
    };
  });
};

const SetImagePath = (data, FootballType, imgData) => {
  const dataKey = Object.keys(data.hero);
  dataKey.forEach(detailKey => {
    if (FootballType === parseInt(detailKey)) {
      data.hero[
        detailKey
      ].images.desktop.src = `/images/desktop/recommendations/hero/${imgData}.jpg`;

      data.hero[
        detailKey
      ].images.mobile.src = `/images/mobile/recommendations/hero/${imgData}.jpg`;
    }
  });
  return data;
};

const MiniOnboardingDetailPage = () => {
  const { scrolldirectionContext } = useScrollDirectionContext();
  const styles = useStyles();
  const throwError = useErrorBoundary();
  const [pageData, setPageData] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isLoadingMore, setIsLoadingMore] = useState(false);
  const [recommendations, setRecommendations] = useState([]);
  const [numberShown, setNumberShown] = useState(SHOWN_INCREMENT);
  const [isRecommendationSet, setIsRecommendationSet] = useState(false);
  const [selectedType, setSelectedType] = useState('LISTVIEW');
  const loadingRef = useRef();
  const { preferences } = usePreferencesContext();
  const isDesktop = useMediaQuery('screen and (min-width: 768px)');
  const [totalRecommendations, setTotalRecommendations] = useState();
  const { FootballType, SelectedFootballType: selectedFootballType } =
    preferences || {};
  const {
    clubMORecommendations,
    casualMORecommendations,
    addMORecommendations,
    setMORecommendations,
  } = useMORecommendations();
  // to get dynamic image url path
  const imagePath = useImage();
  const isClub = selectedFootballType === CLUB;

  // filter recommendation based on group
  // sorting data based on distance
  useEffect(() => {
    const recommendationsGroup = isClub
      ? clubMORecommendations
      : casualMORecommendations;
    setIsRecommendationSet(true);
    if (
      !recommendationsGroup?.RecommendationClubCartDto &&
      !recommendationsGroup?.RecommendationCentreCartDto
    ) {
      return null;
    }
    let normalisedData =
      recommendationsGroup?.RecommendationClubCartDto ||
      recommendationsGroup?.RecommendationCentreCartDto;

    const checkData =
      recommendationsGroup?.RecommendationCentreCartDto &&
      recommendationsGroup?.RecommendationProviderCartDto
        ? recommendationsGroup?.RecommendationCentreCartDto.concat(
            recommendationsGroup?.RecommendationProviderCartDto
          )
        : recommendationsGroup?.RecommendationCentreCartDto &&
          !recommendationsGroup?.RecommendationProviderCartDto
        ? recommendations?.RecommendationCentreCartDto
        : !recommendationsGroup?.RecommendationCentreCartDto &&
          recommendationsGroup?.RecommendationProviderCartDto &&
          recommendations?.RecommendationProviderCartDto;

    if (
      selectedFootballType !== CLUB &&
      (recommendationsGroup?.RecommendationCentreCartDto ||
        recommendationsGroup?.RecommendationProviderCartDto)
    ) {
      normalisedData = normalisedCenterData(checkData)?.sort(
        (currentData, nextData) =>
          currentData.DistanceFromUserLocation >
          nextData.DistanceFromUserLocation
            ? 1
            : currentData.DistanceFromUserLocation <
              nextData.DistanceFromUserLocation
            ? -1
            : 0
      );
    }
    setRecommendations(normalisedData);
    setTotalRecommendations(
      recommendationsGroup?.TotalClubsRecords ||
        recommendationsGroup?.TotalCentresRecords
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubMORecommendations, casualMORecommendations, selectedFootballType]);

  // set the page title based on user preference of football type.
  usePageLoadInfo(
    'miniOnboardingResults',
    `${RECOMMENDATIONS_PAGE_TITLE_MAPPING[FootballType]}`
  );

  useEffect(() => {
    getMORecommendationsPageData()
      .then(({ data }) => {
        if (data) {
          // set dynamic src for hero images
          data = SetImagePath(data, FootballType, imagePath);
          setPageData({ ...data });
        }
      })
      .catch(throwError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedFootballType, imagePath]);

  useEffect(() => {
    if (!recommendations && !!selectedFootballType) {
      const previouslyFocusedElement = document.activeElement;
      setIsLoading(true);

      getMiniOnboardingRecommendations(selectedFootballType, preferences)
        .catch(throwError)
        .then(({ data }) => {
          if (data) {
            const recommendationRecords = data[0].data;
            setMORecommendations(recommendationRecords, selectedFootballType);

            setIsLoading(false);
            setIsRecommendationSet(true);
            setTimeout(() => {
              // if the user focuses on the loading message
              // we want to focus back on the previously focused element
              if (loadingRef.current?.contains(document.activeElement)) {
                previouslyFocusedElement?.focus();
              }
            }, 100);
          }
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    preferences,
    selectedFootballType,
    setMORecommendations,
    recommendations,
    throwError,
  ]);

  const showNext = () => {
    setNumberShown(numberShown + SHOWN_INCREMENT);
  };

  const getMoreResults = () => {
    if (!recommendations) {
      return;
    }

    // if we already have more than the current shown amount
    // show the next 10
    if (recommendations.length > numberShown) {
      showNext();
      return;
    }

    setIsLoadingMore(true);
    const sortedData = [];
    getMiniOnboardingRecommendations(selectedFootballType, {
      ...preferences,
      // get the page after the current one
      [PAGE_NUMBER]:
        preferences['FootballType'] === 1
          ? preferences.PageNumber + 1
          : Math.floor(recommendations?.length / MO_PAGE_SIZE_VALUE) + 1,
    })
      .then(({ data }) => {
        if (data) {
          sortedData.push(data);
          addMORecommendations(sortedData[0], selectedFootballType);
        }
        if (preferences['FootballType'] === 1) {
          preferences[PAGE_NUMBER] = preferences.PageNumber + 1;
        }
        setIsLoadingMore(false);
        showNext();
      })
      .catch(throwError);
  };

  useEffect(() => {
    localStorage.setItem('pathInfomation', window.location.pathname);
  }, []);

  if (!selectedFootballType) {
    return <Redirect to="/" />;
  }

  return (
    <div css={styles.base}>
      {selectedFootballType && pageData?.hero && (
        <MOHero
          css={styles.hero}
          textSize={3}
          isDesktop={isDesktop}
          {...pageData.hero[FootballType]}
          scrolldirectionContext={scrolldirectionContext}
        />
      )}
      <section css={styles.wrapper}>
        <Fragment>
          {isLoading && (
            <Loader
              inline
              message={`Loading venues`}
              css={styles.loader}
              ref={loadingRef}
            />
          )}
          {!isLoading && recommendations && recommendations.length > 0 && (
            <Fragment>
              <LinkTabs
                onClick={setSelectedType}
                selectedType={selectedType}
                tabs={MINIONBOARDING_TABS}
                css={styles.tabs}
              />
              <div css={styles.venueListContainer} aria-live="polite">
                {recommendations &&
                  recommendations.length > 0 &&
                  recommendations.map((data, index) => {
                    return (
                      <div css={styles.venueRecommendWrapper} key={index}>
                        <MORecommendationList
                          data={data}
                          isDesktop={isDesktop}
                          selectedFootballType={selectedFootballType}
                        ></MORecommendationList>
                      </div>
                    );
                  })}
              </div>
            </Fragment>
          )}
        </Fragment>
        {isLoadingMore ? (
          <Loader
            inline
            message={`Loading more ${isClub ? 'clubs' : 'venues'}`}
            css={styles.loader}
            ref={loadingRef}
          />
        ) : totalRecommendations &&
          recommendations.length < totalRecommendations ? (
          <MoreResultsSection
            onClick={getMoreResults}
            selectedFootballType={selectedFootballType}
          />
        ) : (
          EMPTY_STRING
        )}
        {recommendations && recommendations.length > 0 && pageData && (
          <MOFAInitiativeEvent pageData={pageData}></MOFAInitiativeEvent>
        )}
      </section>
      {isRecommendationSet &&
        recommendations &&
        recommendations.length === 0 && (
          <div css={styles.noResultWrapper}>
            <NoResultsRecommendations />
          </div>
        )}
      <RecommendationFooter />
    </div>
  );
};

export default MiniOnboardingDetailPage;
