/** @jsxImportSource @emotion/react */
import { useMsal } from '@azure/msal-react';
import { useEffect, useState } from 'react';
import { useHistory } from 'react-router';

import { homePageAnalytics } from '../../analyticsConsts';
import { ONBOARDING_PARAM } from '../../const';
import { useFilterPanelToggleContext } from '../../context/filterPanelToggle';
import { useMLRecommendations } from '../../context/mlRecommendations';
import { useMORecommendations } from '../../context/moRecommendations';
import { useNestedFilterPanelContext } from '../../context/nestedFilterPanel';
import { usePageInfo } from '../../context/pageInfo';
import { usePreferencesContext } from '../../context/preferences';
import { useRecommendations } from '../../context/recommendations';
import useQueryParams from '../../hooks/useQueryParams';
import useStorageFilters from '../../hooks/useStorageFilters';
import homePage from '../../mocks/data/homepage';
import normaliseHomePageData from '../../normaliser/homepage';
import { getHomepageData } from '../../services';
import FootballTypeCard from '../FootballTypeCard';
import Footer from '../Footer';
import Hero from '../Hero';
import InitiativeCard from '../InitiativeCard';
import OnboardingModal from '../OnboardingModal';
import QuickLinkCard from '../QuickLinkCard';
import Text from '../Text';
import useStyles from './styles';

const {
  FA_INITIATIVE_TYPES,
  FOOTBALL_CATEGORY_TYPES,
  QUICK_LINK_TYPES,
  CTA_START_SEARCHING,
  HERO_BANNER,
  FOOTBALL_CATEGORIES,
  QUICK_LINKS,
  FA_INITIATIVES,
} = homePageAnalytics;

const HomePage = () => {
  const params = useQueryParams();
  const styles = useStyles();
  const [onboardingVisible, setOnboardingVisible] = useState(false);
  const [homeData, setHomeData] = useState({});
  const { resetPreferences } = usePreferencesContext();
  const { resetRecommendations } = useRecommendations();
  const {resetMORecommendations } = useMORecommendations();
  const { clearFiltersStorage } = useStorageFilters();
  const [, setPageInfo] = usePageInfo();
  const { resetMLRecommendations } = useMLRecommendations();
  const { resetFilterPanelToggle } = useFilterPanelToggleContext();
  const { resetNestedFilterPanel } = useNestedFilterPanelContext();
  const history = useHistory();
  const { accounts } = useMsal();

  useEffect(() => {
    // Remove applied filters from session storage
    clearFiltersStorage();

    // Resetting contexts and local storage
    resetPreferences();
    resetRecommendations();
    resetMORecommendations();
    resetMLRecommendations();
    resetFilterPanelToggle();
    resetNestedFilterPanel();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    getHomepageData()
      .then(({ data }) => {
        if (data) {
          const HomepageData = normaliseHomePageData(data);
          setHomeData(HomepageData);
        }
      })
      .catch(() => {
        setHomeData(homePage);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // set path information for b2c url redirect
    localStorage.setItem('pathInfomation', window.location.pathname);

    if (params.get(ONBOARDING_PARAM)) {
      setOnboardingVisible(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    // reset the page name when on-boarding modal is closed
    // user should only be at home page to close modal.
    onboardingVisible ? setPageInfo('onboardingModal') : setPageInfo('home');
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [onboardingVisible, accounts]);

  return (
    <div css={styles.base}>
      {homeData.hero && (
        <Hero
          isHomePage={true}
          wrapperId={HERO_BANNER}
          ctaId={CTA_START_SEARCHING}
          css={styles.hero}
          overlayVariation = {4}
          {...homeData.hero}
          ctaOnClick={() => setOnboardingVisible(true)}
        />
      )}
      <div>
        <section>
          {onboardingVisible && (
            <OnboardingModal
              onClose={() => {
                setOnboardingVisible(false);
                history.push('/');
              }}
              isVisible={onboardingVisible}
            />
          )}
        </section>
        <section css={styles.footballTypeSection} id={FOOTBALL_CATEGORIES}>
          <div css={styles.footballTypeContainer}>
            {homeData.footballTypeHeadings &&
              homeData.footballTypeHeadings.map((res, index) => {
                return (
                  <FootballTypeCard
                    ctaId={FOOTBALL_CATEGORY_TYPES[index]}
                    {...res}
                    key={index}
                  />
                );
              })}
          </div>
        </section>
        <section css={styles.quickLinkSection} id={QUICK_LINKS}>
          <div css={styles.quickLinkContent}>
            <div css={styles.infoSection}>
              <label css={styles.quickLinkHeader}>Quick links</label>
              <label css={styles.quicklinkDescription}>
                No matter the age, disability or skill level, there’s a form of
                the game to suit the player
              </label>
            </div>
            <div css={styles.quickLinkContainer}>
              {homeData.quickLinks &&
                homeData.quickLinks.map((res, index) => {
                  return (
                    <QuickLinkCard
                      ctaId={QUICK_LINK_TYPES[index]}
                      {...res}
                      key={index}
                      indexVal={index}
                    />
                  );
                })}
            </div>
          </div>
        </section>
        <section css={styles.everyOneGameSection} id={FA_INITIATIVES}>
          <div css={styles.everyOneGameContent}>
            <Text as="h1" size="2" css={styles.initiativeHeading}>
              <div>It’s everyone’s game</div>
            </Text>
            {homeData.faInitiatives && (
              <div
                css={styles.initiativeContainer(homeData.faInitiatives.length)}
              >
                {homeData.faInitiatives.map((res, index) => {
                  return (
                    <InitiativeCard
                      ctaId={FA_INITIATIVE_TYPES[index]}
                      {...res}
                      key={index}
                    />
                  );
                })}
              </div>
            )}
          </div>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default HomePage;
