/** @jsxImportSource @emotion/react */

// import useStyles from './styles';
import { Fragment, useCallback, useEffect, useRef, useState } from 'react';

import { DISABILITY_ID } from '../../const';
import { useOnboardingDataContext } from '../../context/OnboardingData';
import getCategoryDataLayer from '../../utils/getCategoryDataLayer';
import getNextQuestionId from '../../utils/getNextQuestionId';
import NextNav from '../NextNav';
import { getInitialAgeLimit } from '../OnboardingModal/MiniOnboarding';
import Text from '../Text';
import useStyles from './styles';

const getSelectedQuestion = () => {
  switch (true) {
    case JSON.parse(sessionStorage.getItem('ISLETGOGIRLSPLAY')):
      return DISABILITY_ID;
    case JSON.parse(sessionStorage.getItem('ISJUSTPLAY')):
      return 'd9572be5-72f2-42af-87ea-a3298c6eacd7';
    case JSON.parse(sessionStorage.getItem('ISVOLUNTEER')):
      return 'd9572be5-72f2-42af-87ea-a3298c6eacd7';
    case JSON.parse(sessionStorage.getItem('ISWILDCATS')):
      return DISABILITY_ID;
    case JSON.parse(sessionStorage.getItem('ISYOUTHCLUB')):
      return '346dbfab-03fa-4a3a-ab18-b461d0d56dc8';
    default:
      return;
  }
};

const AgeQuestion = ({
  readableId,
  analyticsId,
  inputTitle,
  ageRanges,
  onAnswer,
  headerTitle,
  ...rest
}) => {
  const styles = useStyles();
  const [age, setAge] = useState(null);
  const [validationError, setValidationError] = useState(false);
  const [startingRange, setStartingRange] = useState(5);
  const [properties, setProperties] = useState();
  const inputRef = useRef();
  const { OnboardingContext, setOnboardingDataContext } =
    useOnboardingDataContext();

  // get configuration based on type
  useEffect(() => {
    const configuration = getInitialAgeLimit(inputTitle[rest?.searchFor]);
    setProperties(configuration);
  }, [inputTitle, rest?.searchFor]);

  const { startingAge, ageText, endingAge, validationErrorMsg } =
    properties || {};

  useEffect(() => {
    setOnboardingDataContext({
      ...OnboardingContext,
      question: headerTitle,
      step: 1,
      age: undefined,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerTitle]);

  // set starting age
  useEffect(() => {
    setStartingRange(startingAge);
  }, [startingAge]);

  // Rule : Numeric range b/w 5-99.
  const validateAge = useCallback(
    age => {
      const numPattern = new RegExp(/^\d{1,2}$/g);
      return numPattern.test(age) && age >= startingRange && age <= endingAge;
    },
    [startingRange, endingAge]
  );

  const handleNextNav = useCallback(
    (age, readableId, ageRanges, onAnswer) => {
      const isInvalidAge = !validateAge(age);

      if (isInvalidAge) {
        setValidationError(isInvalidAge);
        return;
      }
      const selectedQuestion = getSelectedQuestion();
      // if no error, proceed to the next question.
      onAnswer({
        answerData: { [readableId]: age },
        nextQuestionId: getNextQuestionId({
          ageRanges,
          selectedAge: age,
          selectedQuestion,
        }),
      });
    },
    [validateAge]
  );

  return (
    <Fragment>
      <Text as="div" css={styles.base(validationError)}>
        <label htmlFor={analyticsId}>{ageText}</label>
        <input
          type="text"
          id={analyticsId}
          onChange={event => {
            setAge(event.target.value);
            setOnboardingDataContext({
              ...OnboardingContext,
              age: event.target.value,
            });
          }}
          onKeyPress={event => {
            if (!/[0-9]/.test(event.key)) {
              event.preventDefault();
            }
          }}
          onFocus={() => {
            inputRef.current.value = '';
            // to avoid 'Next' button to re-appear when input is cleaned on focus.
            setAge();
            setValidationError(false);
          }}
          ref={inputRef}
          minLength="1"
          maxLength="2"
        ></input>
        <Text as="div" css={styles.errorBox(validationError)} role="alert">
          {validationError && <p>{validationErrorMsg}</p>}
        </Text>
      </Text>
      <NextNav
        disabled={!age}
        onClick={() => {
          handleNextNav(age, readableId, ageRanges, onAnswer);
          getCategoryDataLayer({
            eventAction: 'Step 1 - Next CTA',
            eventLabel: headerTitle,
            eventparameter1: age,
            event_name: 'next_button_click',
          });
        }}
      />
    </Fragment>
  );
};

export default AgeQuestion;
