import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors, spacing, breakpoints, utility } = useTheme();

  return {
    answer: css`
      padding-bottom: ${spacing.s};

      ${breakpoints.l} {
        padding-bottom: 1.5rem;
      }

      &:last-child {
        padding-bottom: 0;

        button {
          margin: auto;
        }
      }
    `,
    hiddenInputs: isSelected => css`
      input {
        ${utility.checkboxHidden}
      }

      ${breakpoints.l} {
        input:focus-visible:not(:hover) + button {
          span {
            text-decoration: underline;
          }
          &::before {
            border: 3px solid ${colors.navy};
            border-radius: 3px;
            background-color: ${isSelected ? colors.lightBlue : colors.white};
          }
        }
      }
    `,
    button: (isSelected, bothAnswer) => css`
      ${bothAnswer === true &&
      css`
        &::before {
          background-color: ${colors.lightBlue};
        }
      `}

      ${bothAnswer === true &&
      isSelected === false &&
      css`
        &::before {
          background-color: ${colors.white};
        }
      `}
      svg {
        width: 20px;
        height: 20px;
      }
      ${bothAnswer === true &&
      isSelected === false &&
      css`
        &:hover {
          &::before {
            background-color: ${colors.lightBlue};
          }
        }
      `}

      ${breakpoints.m} {
        &:hover {
          &::before {
            background-color: ${colors.lightBlue};
          }
        }
      }

      ${isSelected &&
      css`
        &::before {
          background-color: ${colors.lightBlue};
        }
      `}

      ${!isSelected &&
      css`
        svg {
          display: none;
        }
      `}

      ${breakpoints.l} {
        padding: ${spacing.m};

        &::before {
          background-size: 45%;
        }
      }
    `,
    mixedTitle: css`
      color: ${colors.darkgray};
      font-size: 16px;
      line-height: 22px;
    `,
    visibility: isAnswerSelected => css`
      visibility: hidden;

      ${isAnswerSelected &&
      css`
        visibility: visible;
        animation: fade_in_show 1s ease-in alternate;

        @keyframes fade_in_show {
          0% {
            opacity: 0;
          }

          100% {
            opacity: 1;
          }
        }
      `}
    `,
  };
};
