/** @jsxImportSource @emotion/react */

import { recommendationsPageAnalytics } from '../../analyticsConsts';
import { AlertIcon } from '../Icons';
import useStyles from './styles';

const { RECOMMENDED_PAN_DISABILITY_SUGGESTION_WRAPPER } =
  recommendationsPageAnalytics;

const PanDisabilitySuggestion = () => {
  const styles = useStyles();

  return (
    <div
      css={styles.base}
      id={RECOMMENDED_PAN_DISABILITY_SUGGESTION_WRAPPER}
      role="alert"
    >
      <div css={styles.alertIcon}>
        <AlertIcon />
      </div>
      We've also included some pan disability options that you could participate
      in and might be suitable for your needs
    </div>
  );
};

export default PanDisabilitySuggestion;
