import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { spacing, utility } = useTheme();

  return {
    loader: (isInline, id) => css`
      margin: 0 auto;
      text-align: center;
      align-self: center;

      svg {
        width: 100%;
        max-width: 85px;
      }

      ${isInline &&
      css`
        display: flex;
        align-items: center;
        justify-content: center;

        svg {
          padding-right: ${spacing.xs};
          max-width: 50px;
        }
      `}

      #${id} {
        animation: logo-animation 3000ms linear infinite normal forwards;
      }

      @keyframes logo-animation {
        0% {
          transform: translate(45.669999px, 46.839997px) rotate(0deg);
        }

        100% {
          transform: translate(45.669999px, 46.839997px) rotate(1800deg);
        }
      }
    `,
    text: (hasText, inline) => css`
      ${!hasText && utility.checkboxHidden}

      &:focus {
        outline: none;
      }

      ${hasText &&
      inline &&
      css`
        text-align: center;
      `}
    `,
  };
};
