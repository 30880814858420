/** @jsxImportSource @emotion/react */
import { useHistory } from 'react-router-dom';

import { recommendationsPageAnalytics } from '../../analyticsConsts';
import useOnboardingURL from '../../hooks/useOnboardingURL';
import Button from '../Button';
import ExternalLink from '../ExternalLink';
import useStyles from './styles';

const {
  RECOMMENDED_FOOTBALL_TYPE_CTA_START_SEARCH,
  RECOMMENDED_FOOTBALL_TYPE_CTA_LEARN_MORE,
} = recommendationsPageAnalytics;

const COUNTY_FA_LINK =
  'https://www.englandfootball.com/explore/county-football-associations';

const RecommendationFooter = () => {
  const styles = useStyles();
  const history = useHistory();
  const getOnboardingURL = useOnboardingURL();

  return (
    <div css={styles.footer}>
      <div data-test-id="footer-text" css={styles.textWrapper}>
        <div css={styles.mainText}>Can’t find what you’re looking for?</div>

        <Button
          id={RECOMMENDED_FOOTBALL_TYPE_CTA_START_SEARCH}
          css={styles.cta}
          onClick={() => history.push(getOnboardingURL)}
        >
          Start new search
        </Button>

        <div css={[styles.link, styles.linkWidth]}>
          Alternatively,
          <ExternalLink
            id={RECOMMENDED_FOOTBALL_TYPE_CTA_LEARN_MORE}
            css={styles.link}
            href={COUNTY_FA_LINK}
          >
            contact your local County FA
          </ExternalLink>
          for more opportunities to{' '}
          {JSON.parse(sessionStorage.getItem('ISVOLUNTEER'))
            ? 'volunteer'
            : 'play'}
        </div>
      </div>
    </div>
  );
};

export default RecommendationFooter;
