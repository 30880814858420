import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { colors, breakpoints } = useTheme();

  return {
    siteNavigation: (
      isDesktop,
      isMenuOpened,
      isHamburgerMenu,
      headerHeight
    ) => css`
      text-align: center;
      transition: transform 0.4s ease-in-out;
      ${
        !isHamburgerMenu &&
        isDesktop &&
        css`
          transform: translateX(-100vw);
        `
      }
      ${
        isDesktop &&
        css`
          height: calc(100vh - ${headerHeight});
          background: ${colors.lightGrey};
          position: absolute;
          width: 100%;
        `
      }
      ${breakpoints.l} {
        display: ${isMenuOpened ? 'block' : 'none'};
      }
    `,
    menuContainer: (isSubMenuClicked) => css`
      height: calc(100% - 110px);
      -webkit-overflow-scrolling: touch;
      overflow-x: hidden;
      overflow-y: ${isSubMenuClicked ? 'hidden' : 'auto'};
      padding: 0 1rem 0 14px;
      position: absolute;
      width: 100%;

      ${breakpoints.l} {
        position: unset;
        overflow: hidden;
        height: 100%;
        padding: 0;
      }
    `,
    menuWrapper: (isDesktop, isBackMenu) => css`
      display: grid;
      grid-auto-flow: row;
      align-items: center;

      ${isDesktop &&
      isBackMenu &&
      css`
        animation: slide 0.4s ease-in-out 1 backwards;

        @keyframes slide {
          0% {
            transform: translateX(-100vw);
          }
          100% {
            transform: translateX(0%);
          }
        }
      `}

      ${breakpoints.l} {
        position: unset;
        overflow: hidden;
        height: 100%;
        grid-auto-flow: column;
        padding: 0;
      }
    `,
    topMenu: css`
      background-color: ${colors.navy};
      a {
        color: ${colors.white};
        text-decoration: none;
      }
    `,
    menuHeader: css`
      color: #e1261c;
      font-family: 'FS Dillon', arial, helvetica, sans-serif;
      font-size: 20px;
      line-height: 26px;
      text-transform: uppercase;
      padding: 12px 0;
      box-shadow: 0 2px 3px rgb(1 30 65 / 14%);
      background-color: #fff;
      text-align: left;
      padding-left: 1rem;

      span {
        margin: auto;
        display: flex;
      }

      button span {
        text-align: left;
        color: ${colors.red};
        font-size: 20px;
        display: inline-block;
        padding-left: 10px;
        text-transform: uppercase;
        font-family: 'FS Dillon', arial, helvetica, sans-serif;
        font-weight: 500;
      }
    `,
    leftArrowBtn: isMobile => css`
      padding: 0;
      right: 0;
      top: 0;
      border: 0;
      display: inline-block;
      background: transparent;
      margin-right: 1rem;
      svg {
        color: ${colors.darkGrey};
      }
      ${isMobile &&
      css`
        display: inline-block;
        text-align: left;
        &::after {
          content: '';
          background: url(${publicPath('/images/common/accordion-arrow.svg')})
            no-repeat;
          float: left;
          height: 13px;
          width: 0.625rem;
          transform: rotate(182deg);
          margin-top: 4px;
        }
      `}
    `,
  };
};
