/** @jsxImportSource @emotion/react */
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { useParams } from 'react-router-dom';

import { SELECTED_RECOMMENDATIONS_TYPE_ID } from '../../const';
import { useMLRecommendations } from '../../context/mlRecommendations';
import { usePreferencesContext } from '../../context/preferences';
import { useSetSelectedClubDetailsContext } from '../../context/setSelectedClubDetails';
import useClubAccreditation from '../../hooks/useClubAccreditation';
import useClubContactDetails from '../../hooks/useClubContactDetails';
import useClubVenueCoordination from '../../hooks/useClubVenueCoordination';
import useErrorBoundary from '../../hooks/useErrorBoundary';
import useMediaQuery from '../../hooks/useMediaQuery';
import {
  getClubDetails,
  getClubPageData,
  getSocialMediaData,
} from '../../services';
import normaliseClubDetails from '../../utils/normaliseClubDetails';
import ClubDetailsPage from '../ClubDetailsPage';
import ContactFormModal from '../ContactFormModal';
import Footer from '../Footer';
import Loader from '../Loader';
import defaultPreferences from '../VenuePage/defaultPreferences';
import useStyles from './styles';

const ClubPage = () => {
  const { id } = useParams();
  const throwError = useErrorBoundary();
  const [providerPageData, setProviderPageData] = useState();
  const [selectedTeamData, setSelectedTeamData] = useState();
  const [isLoadingPageData, setIsLoadingPageData] = useState(true);
  const [socialLinks, setSocialLinks] = useState();
  const isDesktop = useMediaQuery('screen and (min-width: 768px)');
  const { preferences } = usePreferencesContext();
  const { [SELECTED_RECOMMENDATIONS_TYPE_ID]: selectedRecommendationType } =
    preferences || {};
  const [clubDetails, setClubDetails] = useState();
  const [filteredTeams, setFilteredTeams] = useState();
  const [unfilteredTeams, setUnfilteredTeams] = useState();
  const [isOpen, setIsOpen] = useState(false);
  // when we get the club details we want to fetch the accreditation and contact details
  const accreditation = useClubAccreditation(clubDetails?.accreditationid);
  const contactDetails = useClubContactDetails(clubDetails?.accreditationid);
  const [isFiltersApplied, setIsFiltersApplied] = useState(
    // if we have a selected recommendation type then we'll use the preferences
    // when getting the provider details, otherwise we show all sessions
    !!selectedRecommendationType || !!preferences?.Category
  );
  const [isTeamDetailsLoading, setIsTeamDetailsLoading] = useState(false);
  const loadingRef = useRef();
  const listRef = useRef();
  const { isMLCard } = useMLRecommendations();
  const { setSelectedClubDetailsValue } = useSetSelectedClubDetailsContext();
  const styles = useStyles();
  const [contactFormVisible, setContactFormVisible] = useState(false);
  const sourceLocation = useClubVenueCoordination(
    selectedRecommendationType,
    preferences
  );
  const history = useHistory();

  useEffect(() => {
    getClubPageData()
      .then(({ data }) => {
        const clubPageDetails = data;
        const crypto = window.crypto;
        const randomNumber =
          Math.round(crypto.getRandomValues(new Uint8Array(10))[0] / 51) + 1;
        clubPageDetails.hero.images.desktop.src = `/images/desktop/club/hero/${randomNumber}.jpg`;
        clubPageDetails.hero.images.mobile.src = `/images/mobile/club/hero/${randomNumber}.jpg`;
        setProviderPageData(clubPageDetails);
        setIsLoadingPageData(false);
      })
      .catch(throwError);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // set path information
  useEffect(() => {
    localStorage.setItem('pathInfomation', window.location.pathname);
  }, []);

  useEffect(() => {
    // if we have already collected data for both states
    // or we are already loading the data
    // skip the fetching
    if (
      (isFiltersApplied && filteredTeams) ||
      (!isFiltersApplied && unfilteredTeams) ||
      isTeamDetailsLoading
    ) {
      return;
    }

    setIsTeamDetailsLoading(true);

    // we send the preferences only if we want the filters applied
    // otherwise we pass an empty object
    getClubDetails(
      id,
      (isMLCard && isFiltersApplied) || isFiltersApplied
        ? { ...defaultPreferences, ...preferences }
        : defaultPreferences
    )
      .then(({ data }) => {
        if (data) {
          const { teams, ...normalisedDetails } = normaliseClubDetails(data);
          // update the details only if we don't already have them
          // they don't change with filters
          if (!clubDetails) {
            setClubDetails(normalisedDetails);
          }

          if (isFiltersApplied) {
            setFilteredTeams(teams);
            setIsTeamDetailsLoading(false);
            return;
          }

          setUnfilteredTeams({
            ...{ teams: teams },
            totalTeams: normalisedDetails?.totalteams,
          });
          setIsTeamDetailsLoading(false);
        }
      })
      .catch(throwError);
  }, [
    filteredTeams,
    id,
    isMLCard,
    isFiltersApplied,
    unfilteredTeams,
    clubDetails,
    isTeamDetailsLoading,
    preferences,
    throwError,
  ]);

  useEffect(() => {
    if (!clubDetails?.accreditationid) {
      return;
    }
    getSocialMediaData(clubDetails?.accreditationid)
      .then(({ data }) => {
        if (data) {
          setSocialLinks(data);
        }
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubDetails]);

  // if we are loading and we already have the venue details and
  // at least one type of session then we are fetching
  // the other type of sessions
  const isRefreshingTeams =
    isTeamDetailsLoading && clubDetails && (filteredTeams || unfilteredTeams);

  // we are using useLayoutEffect here because we cannot rely on
  // setIsRefreshingDetails having run before we shift focus above
  // due to React async setting of state
  useLayoutEffect(() => {
    // if we're refreshing then shift focus to the loading message
    if (isRefreshingTeams) {
      loadingRef.current?.focus({ preventScroll: true });
      return;
    }
    // filtersApplied has changed
    // if both filtered and unfiltered data have been loaded
    // we can shift the focus to the list when the button is clicked
    if (filteredTeams && unfilteredTeams) {
      setTimeout(() => {
        listRef.current?.focus({ preventScroll: true });
      }, 300);
    }
  }, [filteredTeams, isFiltersApplied, isRefreshingTeams, unfilteredTeams]);

  const onTeamCardClick = selectedClub => {
    setSelectedClubDetailsValue({
      ...clubDetails,
      selectedTeamDetails: selectedClub,
    });
    setIsOpen(true);
    history.push(`/team/${selectedClub.TeamId}`);
    setSelectedTeamData(selectedClub);
  };

  if (isLoadingPageData) {
    return <Loader message="Loading club info" />;
  }

  return (
    <div css={styles.base}>
      <ClubDetailsPage
        onTeamCardClick={onTeamCardClick}
        clubDetails={clubDetails}
        providerPageData={providerPageData}
        isTeamDetailsLoading={isTeamDetailsLoading}
        filteredTeams={filteredTeams}
        unfilteredTeams={unfilteredTeams}
        isFiltersApplied={isFiltersApplied}
        setIsFilters={isFilteredApply => setIsFiltersApplied(isFilteredApply)}
        accreditation={accreditation}
        contactDetails={contactDetails}
        isDesktop={isDesktop}
        socialLinks={socialLinks}
        sourceLocation={sourceLocation}
        onClick={() => setContactFormVisible(true)}
      />
      {contactFormVisible && (
        <ContactFormModal
          onClose={() => {
            setContactFormVisible(false);
          }}
          isVisible={contactFormVisible}
          clubDetails={clubDetails}
          isClub={!isOpen}
          selectedTeamData={selectedTeamData}
        />
      )}
      <Footer />
    </div>
  );
};

export default ClubPage;
