/** @jsxImportSource @emotion/react */
import React from 'react';

import b2cEnvVariables from '../../b2cEnvVariables';
import ExternalLink from '../ExternalLink';
import { ExternalArrow } from '../Icons';
import LinkButton from '../LinkButton';
import Text from '../Text';
import useStyles from './styles';

const AccountDetails = ({ logOut, isDesktop }) => {
  const styles = useStyles();
  const appConfig = b2cEnvVariables(process.env.REACT_APP_ENVIRONMENT);

  return (
    <nav role="navigation" css={styles.base} id="myaccount">
      <ul>
        <li>
          <Text as="h2" css={styles.title}>
            ACCOUNT DETAILS
          </Text>
          <ul css={styles.menu}>
            <li>
              <ExternalLink
                css={styles.link}
                href={appConfig.MyAccountUrl}
                target={'_blank'}
              >
                My Account
                <ExternalArrow css={styles.externalArrow} />
              </ExternalLink>
            </li>
            <li>
              <LinkButton onClick={logOut}>Logout</LinkButton>
            </li>
          </ul>
        </li>
      </ul>
    </nav>
  );
};

export default AccountDetails;
