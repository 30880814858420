import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { utility, colors, spacing, borderRadius } = useTheme();

  return {
    hiddenInputs: css`
      input {
        ${utility.checkboxHidden}
      }

      input:focus:not(:focus-visible) + span:before {
        outline: none;
      }
    `,
    switch: css`
      position: relative;
      display: inline-block;
      width: 3.75rem;
      height: ${spacing.m};

      input {
        opacity: 0;
        width: 0;
        height: 0;

        &:checked {
          ~ span {
            &:focus {
              outline: 0;
              border: 1px solid ${colors.navy};

              &::before {
                background-color: ${colors.navy};
              }
            }

            &:before {
              transform: translateX(26px);
            }
            &:after {
              content: 'Yes';
              left: 36%;
            }
          }
        }
      }
    `,
    slider: isChecked => css`
      position: absolute;
      cursor: pointer;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      border: 1px solid ${isChecked ? colors.blue : 'transparent'};
      transition: 0.4s;
      border-radius: 6.25rem;
      background-color: ${isChecked ? colors.white : colors.lightGrey};
      outline: 0;

      &:before {
        content: '';
        position: absolute;
        height: 1.625rem;
        width: 1.625rem;
        left: ${borderRadius.m};
        bottom: ${borderRadius.s};
        background-color: ${isChecked ? colors.blue : colors.darkGrey};
        transition: 0.4s;
        border-radius: 50%;
      }

      &:after {
        content: 'No';
        color: ${isChecked ? colors.black : colors.darkGrey};
        display: block;
        position: absolute;
        transform: translate(-75%, -50%);
        top: 50%;
        left: 80%;
        font-size: 0.875rem;
        line-height: 1.125rem;
      }
    `,
  };
};
