/** @jsxImportSource @emotion/react */

import { Fragment, useEffect, useState } from 'react';

import {
  AGE,
  DISABILITY_ID,
  MEN,
  MIXED_VALUE,
  ONBOARDING_IMAGE_URL,
  WOMEN,
} from '../../const';
import { useOnboardingDataContext } from '../../context/OnboardingData';
import useOnboardingImage from '../../hooks/useOnboardingImage';
import getCategoryDataLayer from '../../utils/getCategoryDataLayer';
import getNextQuestionId from '../../utils/getNextQuestionId';
import publicPath from '../../utils/publicPath';
import ButtonAnswer from '../ButtonAnswer';
import CheckboxAnswer from '../CheckboxAnswer';
import { CircleTick } from '../Icons';
import NextNav from '../NextNav';
import useStyles from './styles';

// loading dynamic images based on age
const GetDynamicImages = (selectedAge = 60, answers) => {
  const { mandatory, optional } = answers;
  const url = `${ONBOARDING_IMAGE_URL}${AGE}/`;
  const selectedAgeValue = useOnboardingImage(selectedAge);
  mandatory[0].image = publicPath(`${url}${WOMEN}${selectedAgeValue}.png`);
  mandatory[1].image = publicPath(`${url}${MEN}${selectedAgeValue}.png`);
  optional.image = publicPath(`${url}${MIXED_VALUE}${selectedAgeValue}.png`);
  return answers;
};

const getSelectedQuestion = () => {
  switch (true) {
    case JSON.parse(sessionStorage.getItem('ISJUSTPLAY')):
    case JSON.parse(sessionStorage.getItem('ISAGEUK')):
    case JSON.parse(sessionStorage.getItem('ISYOUTHCLUB')):
    case JSON.parse(sessionStorage.getItem('ISADULTCLUB')):
    case JSON.parse(sessionStorage.getItem('ISVOLUNTEER')):
      return DISABILITY_ID;
    default:
      return;
  }
};

const MultipleOptionalQuestion = ({
  readableId,
  answers,
  multiOptional,
  onAnswer,
  selectedAge,
  headerTitle,
}) => {
  const styles = useStyles();
  const [selectedAnswer, setSelectedAnswer] = useState();
  const [optionalIsSelected, setOptionalIsSelected] = useState(false);
  const [isAnswerSelected, setIsAnswerSelected] = useState(false);
  const [questions, setQuestions] = useState();
  const [prevAnswer, setPrevAnswer] = useState(false);
  const [notselect, setNotselectAnswer] = useState();
  const [bothAnswer, setBothAnswer] = useState(false);
  const { OnboardingContext, setOnboardingDataContext } =
    useOnboardingDataContext();

  useEffect(() => {
    const dynamicImagePath = GetDynamicImages(selectedAge, answers);
    setQuestions(dynamicImagePath);
  }, [selectedAge, answers]);

  const { mandatory, optional } = questions || {};

  const getQuestionValue = () => {
    // Male or Female selected only
    if (!!optionalIsSelected) {
      return selectedAnswer?.value;
    }

    // Male or Female and Mixed combined
    if (!optionalIsSelected && selectedAnswer) {
      return selectedAnswer.combinedValue;
    }
  };

  const selectAnswer = answer => {
    setSelectedAnswer(answer);
    setIsAnswerSelected(true);
  };

  const selectDeselectAnswer = answer => {
    setBothAnswer(false);
    setNotselectAnswer(false);
    setPrevAnswer(answer.answerId);

    if (answer.answerId === prevAnswer) {
      setNotselectAnswer(true);
    }

    setIsAnswerSelected(true);

    if (notselect === false && prevAnswer === answer.answerId) {
      setSelectedAnswer(null);
      setPrevAnswer(null);
    } else {
      if (prevAnswer ? prevAnswer !== answer.answerId && !bothAnswer : false) {
        setNotselectAnswer(true);
        setSelectedAnswer(multiOptional?.mandate[0]);
        if (selectedAnswer?.answerId !== 5) {
          setBothAnswer(true);
        }
      } else {
        const filteranswer = mandatory.filter(
          answers => answers.answerId !== answer.answerId
        );
        setSelectedAnswer(bothAnswer === true ? filteranswer[0] : answer);
        if (bothAnswer === true && filteranswer[0]) {
          setPrevAnswer(filteranswer[0].answerId);
        }
        else{}
        setNotselectAnswer(false);
      }
    }
  };

  useEffect(() => {
    setOnboardingDataContext({
      ...OnboardingContext,
      question: headerTitle,
      gender_group:
        selectedAnswer?.title === 'Female football' && optionalIsSelected
          ? 'female'
          : selectedAnswer?.title === 'Female football' && !optionalIsSelected
          ? 'female | mixed'
          : selectedAnswer?.title === 'Male football' && optionalIsSelected
          ? 'male'
          : selectedAnswer?.title === 'Male football' && !optionalIsSelected
          ? 'male | mixed'
          : undefined,
      step: 2,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerTitle, selectedAnswer, optionalIsSelected]);

  return (
    <Fragment>
      {mandatory && (
        <ul role="none">
          {mandatory.map(answer => {
            const {
              answerId,
              analyticsId,
              title,
              value,
              combinedValue,
              answerType,
              ageRanges,
              ...rest
            } = answer;
            const isSelected = JSON.parse(sessionStorage.getItem('ISVOLUNTEER'))
              ? bothAnswer === true
                ? true
                : notselect === true
                ? false
                : selectedAnswer && selectedAnswer.answerId === answerId
              : selectedAnswer && selectedAnswer.answerId === answerId;

            return (
              <li key={answerId} css={styles.answer}>
                <label css={styles.hiddenInputs(isSelected)}>
                  <input
                    type="checkbox"
                    value={title}
                    onChange={() =>
                      JSON.parse(sessionStorage.getItem('ISVOLUNTEER')) === true
                        ? selectDeselectAnswer(answer)
                        : selectAnswer(answer)
                    }
                    checked={!!isSelected}
                    name={readableId}
                  />
                  <ButtonAnswer
                    id={analyticsId}
                    css={styles.button(isSelected, bothAnswer)}
                    icon={CircleTick}
                    isGender={true}
                    onClick={() =>
                      JSON.parse(sessionStorage.getItem('ISVOLUNTEER')) === true
                        ? selectDeselectAnswer(answer)
                        : selectAnswer(answer)
                    }
                    tabIndex={-1}
                    {...rest}
                  >
                    {title}
                  </ButtonAnswer>
                </label>
              </li>
            );
          })}
          {optional && (
            <li css={styles.visibility(isAnswerSelected)}>
              <CheckboxAnswer
                id={optional.analyticsId}
                css={styles.mixedTitle}
                name={`${readableId}-optional`}
                isChecked={!optionalIsSelected}
                value={answers.optional.title}
                onChange={() => setOptionalIsSelected(!optionalIsSelected)}
                tabIndex={-1}
              >
                Increase results with mixed football options
              </CheckboxAnswer>
            </li>
          )}
        </ul>
      )}
      <NextNav
        disabled={!selectedAnswer}
        onClick={() => {
          const selectedQuestion = getSelectedQuestion();
          onAnswer({
            answerData: { [readableId]: getQuestionValue() },
            nextQuestionId: getNextQuestionId({
              ageRanges: selectedAnswer
                ? selectedAnswer.ageRanges
                : optional.ageRanges,
              selectedAge,
              selectedQuestion,
            }),
          });
          getCategoryDataLayer({
            eventAction: `Step ${OnboardingContext.step} - Next CTA`,
            eventLabel: OnboardingContext.question,
            eventparameter1: `${OnboardingContext.age}${
              OnboardingContext.agegroup
                ? ' , ' + OnboardingContext.agegroup
                : ''
            }`,
            gender_group: OnboardingContext.gender_group,
            event_name: 'next_button_click',
          });
        }}
      />
    </Fragment>
  );
};

export default MultipleOptionalQuestion;
