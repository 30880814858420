/** @jsxImportSource @emotion/react */
import { forwardRef } from 'react';

import { CLUB } from '../../const';
import Loader from '../Loader';
import useStyles from './styles';

const RecommendationCardLoading = forwardRef(({ selectedType }, ref) => {
  const styles = useStyles();

  return (
    <div css={styles.card} ref={ref}>
      <Loader
        message={`Loading ${
          selectedType === CLUB ? 'clubs' : 'casual'
        } recommendations`}
      />
    </div>
  );
});

export default RecommendationCardLoading;
