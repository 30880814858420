import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, colors, spacing, borderRadius } = useTheme();

  return {
    title: css`
      display: inline-block;
      font-size: 1.75rem;
      color: ${colors.navy};
      margin: ${spacing.xs} 0;
      text-decoration: underline ${colors.red} 2px;
      -webkit-text-decoration-line: underline;
      -webkit-text-decoration-color: ${colors.red};
      -webkit-text-decoration-style: solid;
      -webkit-text-decoration-thickness: 2px;

      ${breakpoints.m} {
        color: ${colors.navy};
        font-size: ${spacing.l};
        line-height: 2.62rem;
      }
    `,
    textWrapper: css`
      color: ${colors.white};
      padding: 6rem ${spacing.s} 1.5rem 1.5rem;

      h3 {
        margin-bottom: 0.3rem;
        color ${colors.navy};
      }

      button {
        background-color: ${colors.red};
        color: ${colors.white};
        max-width: 11rem;
        margin-top: ${spacing.s};
      }
      
      ${breakpoints.l}{
        padding: ${spacing.s} ${spacing.s} 1.5rem ${spacing.xl};
      }
    `,
    imgWrapper: css`
      position: relative;
      padding-top: ${spacing.s};
    `,
    imgContent: css`
      background: url(/images/common/partner-promo-mobile.jpg) 0 0 no-repeat;
      border-radius: ${borderRadius.l};

      ${breakpoints.m} {
        background: url(/images/common/partner-promo.jpg) 0 0 no-repeat;
        min-height: 14.1875rem;
        background-size: cover;
        display: grid;
        align-content: end;
        width: 100%;
      }
    `,
    middle: css`
      padding-top: ${spacing.xs};
      display: grid;
      grid-gap: ${spacing.xl};

      ${breakpoints.l} {
        grid-template-columns: auto max(325px);
      }
    `,
    venueFacilitiesWrapper: css`
      padding: 1.6rem 0;

      ${breakpoints.l} {
        padding: ${spacing.xs} 0;
      }
    `,
    clubDescriptionWrapper: css`
      p {
        font-size: ${spacing.s};
        padding-bottom: 1.5rem;
        line-height: 1.375rem;
        letter-spacing: 0.02em;
        color: ${colors.darkGrey};
      }

      a {
        display: inline-block;
        color: ${colors.darkGrey};
      }
    `,
  };
};
