import { EVENT_ID } from '../../const';

const NOTIFICATION = {
  CLUB_CONTACT: 'Find Football Club Contact',
  WELFARE_OFFICER: 'Find Football Welfare Officer',
  PLAYER_PARENT: 'Find Football Player Parent',
};

export const getRequestPayload = (
  WgsClubID,
  data,
  isYouth,
  isPlayerParent,
  title
) => {
  if (isPlayerParent) {
    return {
      WgsClubId: WgsClubID,
      EventId: EVENT_ID.PLAYER_PARENT, // player parent event id
      NotificationType: NOTIFICATION.PLAYER_PARENT,
      EmailId: data.email,
      EmailSubjectData: {
        Firstname: data.firstName,
        ClubName: title,
        Subject: `Thanks for getting in touch about ${title} through Find Football.`,
      },
    };
  }
  return {
    WgsClubId: WgsClubID,
    EventId: isYouth ? EVENT_ID.CLUB_CONTACT : EVENT_ID.WELFARE_OFFICER,
    NotificationType: isYouth
      ? NOTIFICATION.CLUB_CONTACT
      : NOTIFICATION.WELFARE_OFFICER,
    EmailId: null,
    EmailSubjectData: {
      QueryFrom: `${data.firstName} ${data.lastName}`,
      Email: data.email,
      QueryIsAbout: data.queryIsAbout,
      PlayerAge: data.age,
      Subject: isYouth
        ? `${data.firstName} has messaged you through Find Football about ${title}`
        : `${title} has received an message through Find Football regarding a youth player`,
      QueryDescription: data.description,
    },
  };
};
