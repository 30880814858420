import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors, breakpoints } = useTheme();

  return {
    footer: css`
      background-color: ${colors.red};
      text-align: left;
      padding: 10px;
      height: 56px;
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      color: ${colors.white};
      text-decoration: none;
      float: left;

      div { 
        display: inline-block;
      }

      span {
        font-weight: bold;
        display: block;
        font-size: 18px;
      }

      button {
        background: none;
        float: right;
        border: none;
        color: ${colors.white};
        margin-top: 12px;
        svg {
          transform: rotate(0deg);
        }
      }

      ${breakpoints.l} {
        position: relative;
        padding: 0.7rem 2rem;
        height: 40px;
        &:hover {
          svg {
            transition: 0.5s ease-in;
            transform: translateX(50%);
          }
        }
        span {
          display: inline-block;
          padding-left: 0.3rem;
          font-size: 16px;
        }
        button {
          float: none;
          margin: 5px;
          cursor: pointer;
        }
      }
    `,
    rightArrow: css`
      transform: rotate(270deg);
      display: block;
      width: 100%;
    `,
  };
};
