/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import { CHARLIMIT_HOMEPAGE } from '../../const';
import ExternalLink from '../ExternalLink';
import { RightArrow } from '../Icons';
import Text from '../Text';
import useStyles from './styles';

const FootballTypeCard = ({ heading, description, images, ctaId, href }) => {
  const styles = useStyles();

  return (
    <ExternalLink
      css={styles.card}
      id={ctaId}
      href={href}
      aria-label={`Learn more about ${heading} - opens in a new window`}
      target="_blank"
    >
      <Text css={images && styles.imgSection(images)}></Text>
      <Text as="div" css={styles.infoSection}>
        <Text css={styles.header}>{heading}</Text>
        <Text css={styles.description}>
          {description.substring(0, CHARLIMIT_HOMEPAGE)}
        </Text>
        <Text css={styles.learnMore}>
          <span>Learn More</span> <RightArrow css={styles.rightArrow} />
        </Text>
      </Text>
    </ExternalLink>
  );
};

FootballTypeCard.prototype = {
  heading: PropTypes.string,
  description: PropTypes.string,
  images: PropTypes.object,
  ctaId: PropTypes.string,
  href: PropTypes.string,
};

export default FootballTypeCard;
