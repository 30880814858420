import { useMsal } from '@azure/msal-react';
import { useEffect } from 'react';

import { usePageInfo } from '../../context/pageInfo';

export default (pageId, pageTokens) => {
  const [, setPageInfo] = usePageInfo();
  const { accounts } = useMsal();

  // Update the page data here
  useEffect(() => {
    setPageInfo(pageId, pageTokens);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accounts]);
};
