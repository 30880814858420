import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { spacing, colors, utility, borderRadius, breakpoints, mediaQueries } =
    useTheme();

  return {
    modalSize: css`
      width: 686px;
      margin: 0 auto;
    `,
    base: css`
      display: grid;
      align-items: flex-start;
      position: absolute;
      z-index: 11;
      width: 100%;
      max-width: 375px;
      height: 100%;
      transition: transform 0.5s ease-out;
      background-color: ${colors.lightGrey};
      padding: ${spacing.s} 1.7rem;
      border-radius: ${borderRadius.xxl} ${borderRadius.xxl} 0 0;
      bottom: 0;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);

      ${breakpoints.m} {
        max-width: 686px;
        align-items: center;
        justify-content: center;
        height: 90%;
        padding: ${spacing.m} ${spacing.m} ${spacing.m} 3.5rem;
        background-color: ${colors.lightGrey};
        border-radius: ${borderRadius.xxl};
      }

      ${mediaQueries.zoomedIn} {
        background-position: 0 250%;
      }
    `,
    overlay: css`
      ${utility.overlay};
    `,
    closeBtn: css`
      ${utility.buttonDefault};
      position: absolute;
      border-radius: 50%;
      top: ${spacing.s};
      right: ${spacing.s};
      width: 40px;
      height: 40px;
      outline: 0;

      svg {
        padding: 0.1rem;
        border: ${borderRadius.s} solid transparent;
      }

      &:focus {
        svg {
          padding: 0.1rem;
          border: ${borderRadius.s} solid ${colors.navy};
          border-radius: 50%;
        }
      }

      ${breakpoints.l} {
        right: ${spacing.s};
      }

      ${mediaQueries.zoomedIn} {
        background: ${colors.white};
        z-index: 2;
      }
    `,
    question: css`
      overflow-y: auto;
      -webkit-overflow-scrolling: touch;
      height: 100%;
      padding-right: ${spacing.s};
      margin-top: ${spacing.s};

      ${breakpoints.l} {
        padding-right: ${spacing.m};
      }
    `,
    backLink: css`
      display: flex;
      padding: 0.2rem;
      border-radius: ${borderRadius.m};
      align-items: center;
      position: absolute;
      bottom: ${spacing.s};
      left: ${spacing.m};
      color: ${colors.white};
      border: 1.5px solid transparent;
      text-decoration: none;
      outline: 0;

      &:focus:not(:hover) {
        padding: 0.2rem;
        border: 1.5px solid ${colors.navy};
        border-radius: ${borderRadius.m};
        text-decoration: underline;
      }

      svg {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-right: ${spacing.xs};
      }

      &:hover {
        outline: 0;
        text-decoration: underline;
      }

      ${breakpoints.l} {
        bottom: ${spacing.xl};
        left: 7rem;
        color: ${colors.navy};
      }

      ${mediaQueries.zoomedIn} {
        bottom: ${spacing.xs};
        color: ${colors.navy};
        z-index: 2;

        &:hover {
          color: ${colors.blue};
          outline: 0;
        }
      }
    `,
    // When the button would go over the content (ie zoom)
    // we want a white background for the button
    fakeButtonOverlay: css`
      display: none;

      ${mediaQueries.zoomedIn} {
        display: block;
        position: absolute;
        bottom: ${spacing.xs};
        left: ${spacing.m};
        right: ${spacing.m};
        bottom: ${spacing.xs};
        height: 1.875rem;
        background: white;
        z-index: 1;
      }
    `,
    title: css`
      margin-top: 0.5rem;
      width: 94%;
      max-width: 94%;
      margin-left: 5px;
      color: ${colors.navy};

      ${breakpoints.m} {
        margin-top: 0;
        width: 100%;
      }
    `,
  };
};
