/** @jsxImportSource @emotion/react */
import { forwardRef, Fragment, useEffect, useState } from 'react';
import { useHistory } from 'react-router-dom';

import { BACK_TO_RESULTS } from '../../analyticsConsts';
import { ZERO } from '../../const';
import { useScrollDirectionContext } from '../../context/scrollDirection';
import useMediaQuery from '../../hooks/useMediaQuery';
import publicPath from '../../utils/publicPath';
import AccredationLevel from '../AccredationLevel';
import { Pin, RightArrow } from '../Icons';
import Link from '../Link';
import LinkButton from '../LinkButton';
import LinkDirection from '../LinkDirection';
import Text from '../Text';
import useStyles from './styles';

const HeroProvider = forwardRef(
  (
    {
      hasPreferences,
      textSize = 1,
      images,
      overlayVariation = 4,
      title,
      address,
      distance,
      latitude,
      longitude,
      type,
      county,
      accreditation,
      analyticsId,
      titleHeight,
      isInternalLink = false,
      onClick,
      previousPageData,
      sourceLoction,
      Category,
      backupUrl,
      isTeamPage,
      ...rest
    },
    titleRef,
    isvenue="false"
  ) => {
    const styles = useStyles();
    const [hasLoaded, setHasLoaded] = useState(false);
    const isTablet = useMediaQuery('screen and (min-width: 768px)');
    const { scrolldirectionContext } = useScrollDirectionContext();
    const history = useHistory();

    useEffect(() => {
      setTimeout(() => {
        setHasLoaded(true);
      }, 300);
    }, []);

    const hasMoreInfo =
      county || (accreditation && accreditation.isAccreditation);

    return (
      <div css={styles.heroWrapper}>
        {(hasPreferences || Category) && !isTablet && !isInternalLink && (
          <Link
            to={previousPageData?.backUrl}
            id={BACK_TO_RESULTS}
            css={styles.backMobileLink}
          >
            <RightArrow css={styles.arrowRotate} /> Back to{' '}
            {previousPageData?.name} view
          </Link>
        )}
        {(hasPreferences || Category) &&
          !isTablet &&
          isInternalLink &&
          !isTeamPage && (
            <Link
              id="back to club"
              css={[styles.backMobileLink, styles.internalBackLink]}
              to={backupUrl}
            >
              <RightArrow css={styles.arrowRotate} /> Back to{' '}
              {previousPageData?.name} view
            </Link>
          )}
        {(hasPreferences || Category) &&
          !isTablet &&
          isInternalLink &&
          isTeamPage && (
            <LinkButton
              id="back to club"
              css={[styles.backMobileLink, styles.internalBackLink]}
              onClick={history.goBack}
            >
              <RightArrow css={styles.arrowRotate} /> Back to{' '}
              {previousPageData?.name} view
            </LinkButton>
          )}
        <div
          css={styles.hero(images, overlayVariation, scrolldirectionContext)}
          {...rest}
        >
          <div css={styles.textWrapper(hasLoaded,isvenue)} {...rest}>
            <div css={styles.providerWrapper}>
              <div
                css={styles.infoWrapper(!hasPreferences, titleHeight, isvenue)}
                ref={titleRef}
              >
                {title && (
                  <Text
                    id={analyticsId}
                    as="h1"
                    size={textSize}
                    css={styles.mainText}
                  >
                    {title.toLowerCase()}
                  </Text>
                )}
                <div css={styles.info(hasMoreInfo)}>
                  {address && (
                    <Text css={styles.address(distance)}>{address}</Text>
                  )}
                  {distance && (
                    <Fragment>
                      {distance !== ZERO && (
                        <LinkDirection
                          latitude={latitude}
                          longitude={longitude}
                          name={title}
                          icon={Pin}
                          css={styles.distance}
                          iconColor={'#FFFFFF'}
                          sourceLocation={sourceLoction}
                          isInline
                          aria-label={`Directions - Distance ${distance} miles - opens in a new window`}
                        >
                          <span>{distance} Miles</span>
                        </LinkDirection>
                      )}
                    </Fragment>
                  )}
                </div>
                {hasMoreInfo && (
                  <div css={styles.moreInfo}>
                    {county && <Text>{county}</Text>}
                    {accreditation && accreditation.isAccreditation && title && (
                      <div css={styles.imageWrap}>
                        <img
                          src={publicPath(
                            `/images/common/ef-associated-club-rating.svg`
                          )}
                          alt={`This club has an FA Accreditation`}
                          width="100%"
                        />
                        {accreditation && (
                          <AccredationLevel {...accreditation} />
                        )}
                      </div>
                    )}
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
);

export default HeroProvider;
