import { MYSELF, POSTCODE_ID, SOMEONE_ELSE } from '../../../const';

export default [
  {
    id: '4651266c-f0eb-479e-a93e-49d7829adefe',
    questionOrder: 8,
    readableId: POSTCODE_ID,
    type: 'postcode',
    title: {
      [MYSELF]: 'Lets find what’s closest to you!',
      [SOMEONE_ELSE]: 'Lets find what’s closest to them!',
    },
    placeholder: 'Enter your postcode',
    ageRanges: [
      {
        min: 0,
        max: 0,
        nextQuestionId: null,
      },
    ],
  },
];
