import {
  CLUB,
  DONT_MIND,
  FOOTBALL_TYPE_ID,
  KICKABOUT_CASUAL,
  MYSELF,
  SOMEONE_ELSE,
} from '../../../const';
import publicPath from '../../../utils/publicPath';

export default [
  {
    id: '61459727-eb01-4eff-96d6-d677fdc63e3d',
    questionOrder: 4,
    readableId: FOOTBALL_TYPE_ID,
    type: 'single',
    title: {
      [MYSELF]: 'What are you interested in playing?',
      [SOMEONE_ELSE]: 'What are they interested in playing?',
    },
    answers: [
      {
        answerId: 1,
        analyticsId: 'casual_football',
        title: 'Casual',
        description: 'Informal play, less commitment',
        value: KICKABOUT_CASUAL,
        answerType: 'primary',
        image: publicPath('/images/common/cutouts/kickabout.png'),
        keylineVariant: 3,
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: '4760ff1b-41f2-4450-8bf4-83dc7384b594',
          },
        ],
      },
      {
        answerId: 2,
        analyticsId: 'club_football',
        title: 'Club',
        description: 'Regular team training  and matches',
        value: CLUB,
        answerType: 'primary',
        image: publicPath('/images/common/cutouts/male.png'),
        keylineVariant: 3,
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: '4760ff1b-41f2-4450-8bf4-83dc7384b594',
          },
        ],
      },
      {
        answerId: 3,
        analyticsId: 'do_not_mind',
        title: "I don't mind",
        answerType: 'secondary',
        value: DONT_MIND,
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: '4760ff1b-41f2-4450-8bf4-83dc7384b594',
          },
        ],
      },
    ],
  },
  {
    id: '123735b9-f394-4e48-800b-a6fcfd9a479d',
    questionOrder: 4,
    readableId: FOOTBALL_TYPE_ID,
    type: 'single',
    title: {
      [MYSELF]: 'What are you interested in playing?',
      [SOMEONE_ELSE]: 'What are they interested in playing?',
    },
    answers: [
      {
        answerId: 1,
        analyticsId: 'casual_football',
        title: 'Casual',
        description: 'Casual play, just for fun',
        value: KICKABOUT_CASUAL,
        answerType: 'primary',
        image: publicPath('/images/common/cutouts/casual.png'),
        keylineVariant: 2,
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: '4760ff1b-41f2-4450-8bf4-83dc7384b594',
          },
        ],
      },
      {
        answerId: 2,
        analyticsId: 'club_football',
        title: 'Club',
        description: 'Regular team training  and matches',
        value: CLUB,
        answerType: 'primary',
        image: publicPath('/images/common/cutouts/female.png'),
        keylineVariant: 3,
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: '4760ff1b-41f2-4450-8bf4-83dc7384b594',
          },
        ],
      },
      {
        answerId: 3,
        analyticsId: 'do_not_mind',
        title: "I don't mind",
        answerType: 'secondary',
        value: DONT_MIND,
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: '4760ff1b-41f2-4450-8bf4-83dc7384b594',
          },
        ],
      },
    ],
  },
  {
    id: 'dde0352b-5420-494a-bdee-0a7e5260e617',
    questionOrder: 4,
    readableId: FOOTBALL_TYPE_ID,
    type: 'single',
    title: {
      [MYSELF]: 'What are you interested in playing?',
      [SOMEONE_ELSE]: 'What are they interested in playing?',
    },
    answers: [
      {
        answerId: 1,
        analyticsId: 'kickabout_football',
        title: 'Kickabout',
        description: 'Casual play, just for fun',
        value: KICKABOUT_CASUAL,
        answerType: 'primary',
        image: publicPath('/images/common/cutouts/casual-girl.png'),
        keylineVariant: 2,
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: '4760ff1b-41f2-4450-8bf4-83dc7384b594',
          },
        ],
      },
      {
        answerId: 2,
        analyticsId: 'club_football',
        title: 'Club',
        description: 'Regular team training  and matches',
        value: CLUB,
        answerType: 'primary',
        image: publicPath('/images/common/cutouts/club-girl.png'),
        keylineVariant: 3,
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: '4760ff1b-41f2-4450-8bf4-83dc7384b594',
          },
        ],
      },
      {
        answerId: 3,
        analyticsId: 'do_not_mind',
        title: "I don't mind",
        answerType: 'secondary',
        value: DONT_MIND,
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: '4760ff1b-41f2-4450-8bf4-83dc7384b594',
          },
        ],
      },
    ],
  },
];
