import { css } from '@emotion/react';

export default () => {
  return {
    tabPanel: isSelected => css`
      outline:0;
      ${!isSelected &&
      css`
        display: none;
      `}
    `,
  };
};
