import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors, spacing, typography, utility } = useTheme();

  return {
    filterItem: css`
      ${utility.buttonDefault};
      display: grid;
      grid-template-columns: 80% 20%;
      align-items: center;
      width: 100%;
      padding: ${spacing.s} 0;
      border-bottom: 1px solid ${colors.lightGrey};
      cursor: pointer;
      outline: 0;
    `,
    label: css`
      ${typography.headings[6].mobile};
      text-align: left;
    `,
    toggleCheckbox: css`
      justify-self: end;
    `,
  };
};
