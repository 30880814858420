import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { borderRadius, breakpoints, colors, spacing } = useTheme();

  return {
    facilities: css`
      display: grid;
      grid-template-columns: 24px 1fr;
      align-items: flex-start;
      grid-row-gap: ${spacing.xs};
      border-top: 1px solid ${colors.lightGrey};
      padding: 10px 0;

      ${breakpoints.m} {
        border-top: 0;
        padding: 0;
      }
    `,
    title: css`
      color: ${colors.navy};
      text-align: center;
      font-size: 24px;
      letter-spacing: -0.01em;

      ${breakpoints.l} {
        display: grid;
        align-content: center;
      }
    `,
    subtitle: css`
      color: ${colors.darkGrey};
      text-align: center;
      position: relative;

      &:last-child {
        word-spacing: ${borderRadius.s};
      }

      &:nth-of-type(2n) {
        &::after {
          content: '';
          width: 16px;
          border-bottom: ${borderRadius.s} solid ${colors.red};
          position: absolute;
          top: 1.8rem;
          left: calc(100% - 50%);
        }
      }

      ${breakpoints.l} {
        color: ${colors.darkGrey};
        align-content: center;
      }
    `,
    positionWrapper: css`
      align-content: center;
      background-color: ${colors.navy};
      height: 42px;
      max-width: 164px;
      border-radius: ${borderRadius.l};
      padding: ${borderRadius.l};
      margin: 20px auto 8px auto;
    `,
    postion: css`
      text-align: center;
      border-radius: ${borderRadius.l} solid;
      font-style: italic;
      color: ${colors.white};
      font-size: 24px;
      letter-spacing: -0.01em;

      ${breakpoints.l} {
        font-style: italic;
        letter-spacing: -0.01em;
      }
    `,
    infoFacilities: css`
      background-color: ${colors.lightGrey};
      padding: ${spacing.s};
      border-radius: ${borderRadius.l};

      ${breakpoints.m} {
        padding: 1.5rem;
      }
    `,
    seasonText: css`
      font-size: 0.875rem;

      ${breakpoints.m} {
        font-size: 0.875rem;
      }
    `,
  };
};
