import { css } from '@emotion/react';
import { consts } from 'react-elastic-carousel';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { borderRadius, colors } = useTheme();

  return {
    carouselButton: (type, isGradient) => css`
      display: grid;
      button {
        position: relative;
        align-self: center;
        padding: 5px;
        height: 100%;
        background-color: transparent;
        color: ${colors.navy};
        border: ${borderRadius.s} solid transparent;

        &:focus {
          outline: 0;
          height: 100%;
          background-color: transparent;

          svg {
            color: ${type === consts.PREV ? colors.red : colors.white};
          }
        }

        &:focus-visible {
          svg {
            border: ${borderRadius.s} solid ${colors.white};
            border-radius: ${borderRadius.m};
          }
        }

        &:not(:disabled):hover,
        &:focus {
          outline: 0;
          background-color: transparent;
        }

        &:disabled {
          background-color: transparent;
          cursor: not-allowed;

          svg {
            color: ${colors.lightGrey};
          }

          &:focus {
            outline: 0;
            border: ${borderRadius.s} solid ${colors.navy};
            border-radius: ${borderRadius.m};
            // height: 20%;
            background-color: transparent;

            svg {
              color: ${colors.navy};
            }
          }
        }

        ${type === consts.PREV &&
        isGradient &&
        css`
          &::before {
            content: '';
            position: absolute;
            right: -2.3rem;
            width: 100%;
            height: 90%;
            top: 7%;
            bottom: 0;
            background: linear-gradient(
              270deg,
              ${colors.white} 0%,
              rgba(255, 255, 255, 0) 79.17%
            );
            transform: rotate(180deg);
            z-index: 1;
          }
        `}

        ${type !== consts.PREV &&
        isGradient &&
        css`
          &::after {
            content: '';
            position: absolute;
            right: 2.4rem;
            width: 100%;
            height: 90%;
            top: 7%;
            bottom: 0;
            background: linear-gradient(
              90deg,
              ${colors.lightRed} -37.5%,
              rgba(206, 17, 36, 0) 75%
            );
            transform: rotate(-180deg);
            border-top-left-radius: ${borderRadius.m};
            border-bottom-left-radius: ${borderRadius.m};
          }
        `}
      }
    `,
    arrowStyle: css`
      color: ${colors.navy};
      &:hover {
        color: ${colors.lightBlue};
      }
      &.active {
        color: ${colors.dodgerBlue};
      }
    `,
  };
};
