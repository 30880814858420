import { AccessTokenProvider } from './accessToken';
import { FilterPanelToggleProvider } from './filterPanelToggle';
import { MLRecommendationsProvider } from './mlRecommendations';
import { MORecommendationsProvider } from './moRecommendations';
import { NestedFilterPanelProvider } from './nestedFilterPanel';
import { OnboardingDataProvider } from './OnboardingData';
import { PageHistoryProvider } from './pageHistory';
import { PageInfoProvider } from './pageInfo';
import { PreferencesProvider } from './preferences';
import { RecommendationsProvider } from './recommendations';
import { ScrollDirectionProvider } from './scrollDirection';
import { SetContactFormDLProvider } from './setContactFormDL';
import { SetHeaderHeightProvider } from './setHeaderHeight';
import { SetPathInfoProvider } from './setPathInfo';
import { SetSelectedClubDetailsProvider } from './setSelectedClubDetails';

const ContextProviders = ({ children }) => {
  return (
    <PreferencesProvider>
      <SetHeaderHeightProvider>
        <OnboardingDataProvider>
          <SetContactFormDLProvider>
            <SetPathInfoProvider>
              <ScrollDirectionProvider>
                <PageHistoryProvider>
                  <NestedFilterPanelProvider>
                    <FilterPanelToggleProvider>
                      <PageInfoProvider>
                        <AccessTokenProvider>
                          <RecommendationsProvider>
                            <MLRecommendationsProvider>
                              <MORecommendationsProvider>
                                <SetSelectedClubDetailsProvider>
                                  {children}
                                </SetSelectedClubDetailsProvider>
                              </MORecommendationsProvider>
                            </MLRecommendationsProvider>
                          </RecommendationsProvider>
                        </AccessTokenProvider>
                      </PageInfoProvider>
                    </FilterPanelToggleProvider>
                  </NestedFilterPanelProvider>
                </PageHistoryProvider>
              </ScrollDirectionProvider>
            </SetPathInfoProvider>
          </SetContactFormDLProvider>
        </OnboardingDataProvider>
      </SetHeaderHeightProvider>
    </PreferencesProvider>
  );
};

export default ContextProviders;
