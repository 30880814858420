import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { utility, colors, spacing, borderRadius, breakpoints, typography } =
    useTheme();

  return {
    btn: ({ keylineVariant, hasDescription, isGender }) => {
      const keylineImgPath = `/images/common/keylines/keyline-${keylineVariant}.png`;

      return css`
        ${utility.buttonDefault};
        line-height: 1.375rem;
        position: relative;
        z-index: 1;
        display: block;
        color: ${colors.navy};
        width: 100%;
        max-width: 460px;
        max-height: 120px;
        text-align: left;
        padding: 1.3rem ${spacing.s};
        font-family: 'FS Dillon';
        font-size: 1.275rem;
        font-weight: 700;
        border-radius: ${borderRadius.s};
        outline: 0;

        span {
          display: flex;
          align-items: center;
        }

        svg {
          margin-right: ${spacing.xs};
        }

        &::before {
          content: '';
          display: block;
          position: absolute;
          z-index: -1;
          bottom: 0;
          left: 0;
          width: 100%;
          height: 100%;
          background: url(${publicPath(keylineImgPath)}) bottom right no-repeat;
          background-size: 55%;
          background-color: ${colors.lightGrey};
          transition: background-color 0.3s ease-in;
          outline: 0;
        }

        ${isGender === false &&
        css`
          &:hover {
            &::before {
              background-color: ${colors.lightBlue};
            }
          }
        `}

        ${breakpoints.l} {
          font-size: 1.5rem;
          padding: ${hasDescription ? '2rem' : '2.5rem'} ${spacing.m};
          outline: 0;

          &::before {
            background-size: 55%;
            outline: 0;
          }

          &:focus-visible:not(:hover) {
            &::before {
              background-color: ${colors.white};
            }
          }
        }
      `;
    },
    description: css`
      font-size: 0.8rem;
      font-weight: 400;
      color: ${colors.darkGrey};

      ${breakpoints.l} {
        ${typography.base};
      }
    `,
    image: css`
      display: block;
      position: absolute;
      bottom: 0;
      right: 0;
    `,
  };
};
