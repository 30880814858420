import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { spacing, colors, breakpoints } = useTheme();

  return {
    slider: ({ hasLabels, hasSuffix }) => css`
      background-color: ${colors.lightGrey};
      padding: ${spacing.m} 1.5rem;
      border-radius: 4px;
      position: relative;
      display: grid;
      ${hasLabels &&
      css`
        grid-template-columns: 8% auto 8%;
      `}
      justify-items: center;
      align-items: center;
      grid-gap: ${spacing.s};

      ${breakpoints.l} {
        padding: ${spacing.m};
        ${hasSuffix &&
        css`
          padding-top: ${spacing.l};
        `}
      }
    `,
    suffix: css`
      font-weight: 600;
      position: absolute;
      top: -30px;
      left: -5px;
      width: 40px;
    `,
  };
};
