import { createContext, useContext, useEffect, useRef } from 'react';
import { useHistory } from 'react-router';

import { PREVIOUSPAGE } from '../const';
import { setLocalItem } from '../hooks/useLocalStorage';

export const PageHistoryContext = createContext();

export const PageHistoryProvider = ({ children }) => {
  const history = useHistory();
  const previousPage = useRef();
  const currentPage = useRef();

  useEffect(() => {
    history.listen(({ pathname }) => {
      // if the page was changed
      if (pathname !== currentPage.current) {
        if (pathname.includes('/team')) {
          return;
        }
        // add the current page to the history
        previousPage.current = currentPage.current;
        // to get previous url during page refresh
        setLocalItem(PREVIOUSPAGE, currentPage.current);

        // change the current page to the new page
        currentPage.current = pathname;
      }
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <PageHistoryContext.Provider
      value={{
        previousPage: previousPage.current,
        currentPage: currentPage.current,
      }}
    >
      {children}
    </PageHistoryContext.Provider>
  );
};

export const usePageHistory = () => useContext(PageHistoryContext);
