import { useEffect, useState } from 'react';

import { EMPTY_STRING } from '../../const';
import useGeolocation from '../../hooks/useGeolocation';

export default (selectedRecommendationType, preferences) => {
  const [sourceLoction, setSourceLoction] = useState();
  const { geolocationData, getGeolocationData } = useGeolocation();

  // if preference is not there call geolocation method
  useEffect(() => {
    const hasPreferences = !!selectedRecommendationType;
    if (hasPreferences || preferences?.Category) {
      return;
    }
    getGeolocationData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedRecommendationType]);

  useEffect(() => {
    setSourceLoction(
      !!selectedRecommendationType
        ? {
            latitude: preferences.Latitude,
            longitude: preferences.Longitude,
          }
        : geolocationData?.coordinates
        ? {
            latitude: geolocationData.coordinates.latitude,
            longitude: geolocationData.coordinates.longitude,
          }
        : preferences
        ? {
            latitude: preferences.Latitude,
            longitude: preferences.Longitude,
          }
        : EMPTY_STRING
    );
  }, [selectedRecommendationType, preferences, geolocationData]);

  return sourceLoction;
};
