import { CarIcon, Disability, FaInitiative, FootballTypes, Person, Pitch } from '../../components/Icons';
import { DISABILITY_FACILITIES_MAPPING,EMPTY_STRING } from '../../const';
import formatAddress from '../formatAddress';

const normaliseClubDetails = apiData => {
  const {
    ClubName,
    DistanceFromUserLocation,
    ClubCounty,
    GroundName,
    TeamsCount,
    TeamsPreferenceCount,
    TeamsTab,
    Latitude,
    Longitude,
    Facility,
    PitchTypes,
    DisableFacility,
    WgsClubId,
    TeamsInfo,
    ClubId,
    ...rest
  } = apiData;

  const { FootballType, Gender, DisabilityType } = TeamsInfo;
 
  return {
    title: ClubName,
    clubid: ClubId,
    address: formatAddress(rest),
    contactAddress: formatAddress(rest, '\n'),
    county: ClubCounty,
    accreditationid: WgsClubId,
    distance: DistanceFromUserLocation,
    latitude: Latitude,
    longitude: Longitude,
    gender: Gender[0],
    footballtypes: {
      title: 'Football types played here',
      values: [
        {
          title: 'Teams football types',
          icon: FootballTypes,
          rows: FootballType,
        },
        {
          title: 'Teams gender groups',
          icon: Person,
          rows: Gender.map(gender => `${gender} Football`),
        },
        {
          title: 'Teams disability types',
          icon: FaInitiative,
          rows: DisabilityType,
        },
      ],
    },
    facilities: Facility ? {
      title: 'Club ground facilities',
      values: [
        {
          title: 'Facilities',
          icon: CarIcon,
          rows: [
            ...(Facility && Facility.CarPark && Facility.CarParkCapacity > 0
              ? [`${Facility.CarParkCapacity} Parking Spaces`]
              : []),
            ...(Facility && Facility.ChangingRooms ? ['Changing Rooms'] : []),
            ...(Facility && Facility.Floodlit ? ['Floodlights'] : []),
          ],
        },
        {
          title: 'Disability Facilities',
          icon: Disability,
          rows: DisableFacility && Object.keys(DisableFacility)
            .map(
              facility =>
                DisableFacility[facility] &&
                DISABILITY_FACILITIES_MAPPING[facility]
            )
            .filter(Boolean),
        },
        {
          title: 'Pitch types',
          icon: Pitch,
          rows: PitchTypes,
        },
      ],
    } : EMPTY_STRING,
    totalteams: TeamsPreferenceCount || TeamsCount,
    teams: TeamsTab.map(team => ({
      title: `${team.Gender} Football`,
      data: team.teamsGroup,
    })),
  };
};

export default normaliseClubDetails;
