import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors } = useTheme();
  return {
    ratingContainer: css`
     position: absolute;
     top: 0.6rem;
     padding-left: 3.75rem;
    `,
    rating: css`
        position: relative;
  
        display: inline-block;
        width: 0;
        height: 0;
        margin-left: 0.9em;
        margin-right: 0.9em;
        margin-bottom: 1.2em;
        
        border-right:  0.3em solid transparent;
        border-bottom: 0.7em  solid  ${colors.white};
        border-left:   0.3em solid transparent;
      
        /* Controlls the size of the stars. */
        font-size: 0.25rem;

        
        &:before, &:after {
          content: '';
          
          display: block;
          width: 0;
          height: 0;
          
          position: absolute;
          top: 0.6em;
          left: -1em;
        
          border-right:  1em solid transparent;
          border-bottom: 0.7em  solid  ${colors.white};
          border-left:   1em solid transparent;
        
          transform: rotate(-35deg);
        }
        
        &:after {  
          transform: rotate(35deg);
        }
      }
     `,
    displayNone: css`
     display: none;
   `
  };
};
