/** @jsxImportSource @emotion/react */

import { useLayoutEffect, useRef, useState } from 'react';

import { recommendationsPageAnalytics } from '../../analyticsConsts';
import {
  BOYS_OR_MEN,
  BOYS_OR_MEN_MIXED,
  GIRLS_OR_WOMEN,
  GIRLS_OR_WOMEN_MIXED,
  KICKABOUT_CASUAL,
  PLAY_WITH_ID,
} from '../../const';
import { useFilterPanelToggleContext } from '../../context/filterPanelToggle';
import { usePreferencesContext } from '../../context/preferences';
import { useRecommendations } from '../../context/recommendations';
import useErrorBoundary from '../../hooks/useErrorBoundary';
import {getRecommendations } from '../../services';
import Button from '../Button';
import LinkButton from '../LinkButton';
import Loader from '../Loader';
import Text from '../Text';
import useStyles from './styles';

const {
  RECOMMENDED_NO_RESULTS_DISABILITY_CTA_EXPAND_SEARCH,
  RECOMMENDED_NO_RESULTS_DISABILITY_LINK_FILTERS,
  RECOMMENDED_NO_RESULTS_DISABILITY_TITLE,
  RECOMMENDED_NO_RESULTS_DISABILITY_CONTAINER,
  RECOMMENDED_NO_RESULTS_DISABILITY_WRAPPER,
} = recommendationsPageAnalytics;

const NoDisabilityResults = () => {
  const styles = useStyles();
  const { isFilterPanelToggled, setFilterPanelToggle } =
    useFilterPanelToggleContext();
  const [isLoading, setIsLoading] = useState(false);
  const loadingRef = useRef();
  const throwError = useErrorBoundary();
  const { preferences, setPreferences } = usePreferencesContext();
  const { SelectedFootballType: selectedFootballType, PlayWith: playWith } =
    preferences || {};
  const { setRecommendations } = useRecommendations();

  const getMixedRecommendations = () => {
    setIsLoading(true);
    const playWithID =
      playWith === BOYS_OR_MEN ? BOYS_OR_MEN_MIXED : GIRLS_OR_WOMEN_MIXED;

    const updatedPreference = {
      ...preferences,
      [PLAY_WITH_ID]: playWithID,
    };

    Promise.all([
      getRecommendations(selectedFootballType, updatedPreference),
    ])
      .then(data => {
        if (data) {
          const recommendationRecords = data[0].data; // recommendation data
          setRecommendations(recommendationRecords, selectedFootballType);

          // when we are sure the preferences have been set
          setPreferences(updatedPreference);
          setIsLoading(false);
        }
      })
      .catch(throwError);
  };

  useLayoutEffect(() => {
    if (isLoading) {
      loadingRef.current?.focus();
    }
  }, [isLoading]);

  const isCasual = selectedFootballType === KICKABOUT_CASUAL;

  return (
    <div css={styles.base} id={RECOMMENDED_NO_RESULTS_DISABILITY_WRAPPER}>
      {!isLoading ? (
        <div
          css={styles.banner}
          id={RECOMMENDED_NO_RESULTS_DISABILITY_CONTAINER}
        >
          <Text
            as="h1"
            size={5}
            id={RECOMMENDED_NO_RESULTS_DISABILITY_TITLE}
            css={styles.header}
          >
            We couldn't find results matching your criteria.
          </Text>
          <div css={styles.description}>
            <Text>
              Try broadening
              <LinkButton
                id={RECOMMENDED_NO_RESULTS_DISABILITY_LINK_FILTERS}
                css={styles.newSearch}
                aria-label="click here to open filter panel"
                onClick={() => setFilterPanelToggle(!isFilterPanelToggled)}
              >
                your filters
              </LinkButton>{' '}
              like days and location, or have a look at the results below as
              they might be of interest
            </Text>
          </div>
          {(playWith === BOYS_OR_MEN || playWith === GIRLS_OR_WOMEN) &&
            isCasual && (
              <div css={styles.bottom}>
                <Button
                  id={RECOMMENDED_NO_RESULTS_DISABILITY_CTA_EXPAND_SEARCH}
                  css={styles.cta}
                  onClick={() => getMixedRecommendations()}
                >
                  expand search
                </Button>
              </div>
            )}
        </div>
      ) : (
        <Loader message="Loading mixed football results..." ref={loadingRef} />
      )}
    </div>
  );
};

export default NoDisabilityResults;
