import {
  ALL_DAYS,
  MYSELF,
  SOMEONE_ELSE,
  WEEKDAYS,
  WEEKDAYS_ID,
  WEEKENDS,
} from '../../../const';

export default [
  {
    id: '4760ff1b-41f2-4450-8bf4-83dc7384b594',
    questionOrder: 5,
    readableId: WEEKDAYS_ID,
    type: 'single',
    title: {
      [MYSELF]: 'When do you want to play?',
      [SOMEONE_ELSE]: 'When do they want to play?',
    },
    answers: [
      {
        answerId: 1,
        analyticsId: 'alldays',
        title: 'All days',
        answerType: 'basic',
        value: ALL_DAYS,
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: '191d8b36-171e-4227-836f-58a0b597744a',
          },
        ],
      },
      {
        answerId: 2,
        analyticsId: 'weekdays',
        title: 'Weekdays',
        answerType: 'basic',
        value: WEEKDAYS,
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: '191d8b36-171e-4227-836f-58a0b597744a',
          },
        ],
      },
      {
        answerId: 3,
        analyticsId: 'weekends',
        title: 'Weekends',
        answerType: 'basic',
        value: WEEKENDS,
        ageRanges: [
          {
            min: 0,
            max: 0,
            nextQuestionId: '191d8b36-171e-4227-836f-58a0b597744a',
          },
        ],
      },
    ],
  },
];
