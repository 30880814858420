/** @jsxImportSource @emotion/react */
import { Fragment, useCallback, useRef } from 'react';
import { useHistory } from 'react-router-dom';

import { recommendationsPageAnalytics } from '../../analyticsConsts';
import {
  CASUAL_INTRO_TEXT,
  CASUAL_LEARN_MORE_LINK,
  CLUB,
  CLUB_INTRO_TEXT,
  CLUB_LEARN_MORE_LINK,
  DISABILITY_INTRO_TEXT,
  DISABILITY_TYPE,
  EMPTY_STRING,
  KICKABOUT_CASUAL,
  RECOMMENDATION_DESCRIPTION,
  SELECTED_RECOMMENDATIONS_TYPE_ID,
} from '../../const';
import { usePreferencesContext } from '../../context/preferences';
import useAgeTitle from '../../hooks/useAgeTitle';
import useDisabilityTitle from '../../hooks/useDisabilityTitle';
import useMediaQuery from '../../hooks/useMediaQuery';
import idFromString from '../../utils/idFromString';
import Button from '../Button';
import CarouselCard from '../CarouselCard';
import CenterCard from '../CenterCard';
import ClubCard from '../ClubCard';
import ExternalLink from '../ExternalLink';
import { Compass } from '../Icons';
import MoreCard from '../MoreCard';
import RecommendationCardVideo from '../RecommendationCardVideo';
import Text from '../Text';
import useStyles from './styles';

const {
  RECOMMENDED_FOOTBALL_TYPE_CTA_VIEW_MORE_CARDS,
  RECOMMENDED_FOOTBALL_TYPE_TITLE,
  RECOMMENDED_FOOTBALL_TYPE_CTA_LEAR_MORE,
  RECOMMENDED_FOOTBALL_TYPE_CTA_VIEW_MAPS_OF,
  RECOMMENDED_FOOTBALL_TYPE_CTA_CLUB_CARD,
  RECOMMENDED_FOOTBALL_TYPE_CTA_VENUE_CARD,
} = recommendationsPageAnalytics;

const MAX_CARDS_DESKTOP = 10;
const MAX_CARDS_MOBILE = 10;

const RecommendationClubCard = ({
  index,
  FootballType,
  Disability,
  DisabilityId,
  selectedFootballType,
  RecommendationClubCartDto: clubs,
  RecommendationCentreCartDto: centers,
  RecommendationProviderCartDto: providers,
  TotalCentresRecords,
  TotalClubsRecords,
  TotalProvidersRecords,
}) => {
  const styles = useStyles();
  const history = useHistory();
  const { preferences, setPreferences } = usePreferencesContext();
  const isDesktop = useMediaQuery('screen and (min-width: 1205px)');
  const ageTitle = useAgeTitle();
  const disabilityTitle = useDisabilityTitle();

  const mapUrl = '/recommendations/map';

  const isClub = selectedFootballType === CLUB;
  const venueType = isClub ? 'clubs' : 'venue';
  const analyticsIdOfCard = isClub
    ? RECOMMENDED_FOOTBALL_TYPE_CTA_CLUB_CARD
    : RECOMMENDED_FOOTBALL_TYPE_CTA_VENUE_CARD;
  const providerCenters = centers?.concat(providers);

  // Min of 3 grid columns for club/centers cards
  // sorting cards based on distance
  const cards = (
    isClub ? clubs : centers?.length < 0 ? providers : providerCenters
  )?.sort((firstDistance, secondDistance) =>
    firstDistance.DistanceFromUserLocation >
    secondDistance.DistanceFromUserLocation
      ? 1
      : firstDistance.DistanceFromUserLocation <
        secondDistance.DistanceFromUserLocation
      ? -1
      : 0
  );

  const cardsTotal = cards && cards?.length;

  const columnsCountMobile = cardsTotal > 10 ? MAX_CARDS_MOBILE : cardsTotal;
  const sliceCount = isDesktop ? MAX_CARDS_DESKTOP : columnsCountMobile;

  const totalRecords = isClub ? TotalClubsRecords : TotalCentresRecords;

  const categoryStringId = idFromString(FootballType.toString());

  // dynamically set categoryName
  const categoryName = disabilityTitle
    ? DISABILITY_INTRO_TEXT
    : selectedFootballType === 1
    ? CASUAL_INTRO_TEXT
    : CLUB_INTRO_TEXT;

  const categoryHasText = categoryName.hasOwnProperty(categoryStringId);

  //dynamically set learn more about links
  const learnMoreLink =
    selectedFootballType === 1 ? CASUAL_LEARN_MORE_LINK : CLUB_LEARN_MORE_LINK;

  const categoryHasLink = learnMoreLink.hasOwnProperty(categoryStringId);

  const CardMap = {
    [KICKABOUT_CASUAL]: CenterCard,
    [CLUB]: ClubCard,
  };
  const providerIdRef = useRef();
  const providersCards = useRef();
  const pitchFinderRef = useRef();
  const Card = CardMap[selectedFootballType];

  const CardLayout = isDesktop && cardsTotal > 3 ? CarouselCard : 'div';

  const selectRecommendationsPreference = e => {
    // we want to block the default link and only navigate
    // when we are sure the preferences have been set
    e.preventDefault();

    setPreferences({
      ...preferences,
      [SELECTED_RECOMMENDATIONS_TYPE_ID]: FootballType,
      [DISABILITY_TYPE]: DisabilityId,
    });

    history.push(mapUrl);
  };

  // check total number of characters
  const getTotalStringCount = useCallback(() => {
    var string = `${FootballType}${ageTitle}${Disability}`;
    var count = (string || [])?.length;
    return count > 25 && Disability !== undefined;
  }, [FootballType, ageTitle, Disability]);

  const checkDisability = useCallback(() => {
    let string = `${FootballType}${ageTitle}${Disability}`;
    let count = (string || [])?.length;
    return count > 40 && DisabilityId === 10 && Disability !== undefined
      ? `-<br /> ${Disability}`
      : count > 29 && Disability !== undefined
      ? ` - ${Disability.split(' ')[0]} ${
          isDesktop ? '<br/>' : ''
        } ${Disability.split(' ').splice(1, 4).join(' ')}`
      : count > 25 && Disability !== undefined
      ? `<br /> ${Disability}`
      : ` - ${Disability}`;
  }, [FootballType, ageTitle, Disability, isDesktop, DisabilityId]);

  return (
    <div css={styles.card(index)} className="recommendationCard">
      <div css={styles.heading(getTotalStringCount())}>
        <div css={styles.circle}>{index}</div>
        <Text
          id={`${RECOMMENDED_FOOTBALL_TYPE_TITLE}-${index}`}
          as="h2"
          size="5"
          css={styles.title}
        >
          <span css={styles.ageTitle}>
            {ageTitle} {FootballType}
            {Disability && (
              <span
                dangerouslySetInnerHTML={{ __html: checkDisability() }}
              ></span>
            )}
          </span>
        </Text>
      </div>

      <div css={styles.grid}>
        <div css={styles.middle}>
          <Text css={styles.categoryText}>
            {categoryHasText && categoryName[categoryStringId]}
          </Text>
          <RecommendationCardVideo
            FootballType={FootballType}
            selectedFootballType={selectedFootballType}
          />
        </div>

        <div css={styles.bottom}>
          {categoryHasLink && (
            <ExternalLink
              id={`${RECOMMENDED_FOOTBALL_TYPE_CTA_LEAR_MORE}-${index}`}
              css={styles.link}
              href={learnMoreLink[categoryStringId].link}
              aria-hidden={categoryHasLink ? 'false' : 'true'}
            >{`Learn more about ${learnMoreLink[categoryStringId].name}`}</ExternalLink>
          )}
        </div>
      </div>

      <div css={styles.grid}>
        <div css={styles.middle}>
          <Text css={styles.introDecription}>
            {RECOMMENDATION_DESCRIPTION[selectedFootballType]}
          </Text>
          <div css={[styles.grid, !isDesktop && styles.gradientFeather]}>
            {cards && (
              <Fragment>
                <CardLayout
                  css={
                    isDesktop && cardsTotal > 3
                      ? EMPTY_STRING
                      : styles.cards(cardsTotal, totalRecords)
                  }
                >
                  {cards.slice(0, sliceCount).map((card, index) => {
                    card?.ProviderCartDto?.map(providersDetails => {
                      providerIdRef.current = providersDetails.ProviderId;
                      if (card.ProviderCartDto?.length > 1) {
                        providersCards.current = card;
                        providerIdRef.current = undefined;
                        pitchFinderRef.current = card.PitchFinderId;
                      }
                      return providersDetails.ProviderId;
                    });
                    return (
                      <Card
                        analyticsId={`${analyticsIdOfCard}-${index}`}
                        key={index}
                        ageTitle={ageTitle}
                        footballType={FootballType}
                        providerid={providerIdRef.current}
                        providerCard={providersCards.current}
                        pitchFinder={pitchFinderRef.current}
                        {...card}
                      />
                    );
                  })}
                  {totalRecords > 10 && (
                    <MoreCard
                      analyticsId={`${RECOMMENDED_FOOTBALL_TYPE_CTA_VIEW_MORE_CARDS}-${index}`}
                      to={mapUrl}
                      onClick={selectRecommendationsPreference}
                      label={`View more ${venueType}`}
                    />
                  )}
                </CardLayout>
              </Fragment>
            )}
          </div>
        </div>
        <div css={styles.bottom}>
          <div css={styles.moreCards}>
            <Button.Link
              id={`${RECOMMENDED_FOOTBALL_TYPE_CTA_VIEW_MAPS_OF}_${venueType}-${index}`}
              primary
              css={styles.mapCta}
              to={mapUrl}
              onClick={selectRecommendationsPreference}
            >
              {`View maps of ${venueType} near you`} <Compass />
            </Button.Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default RecommendationClubCard;
