import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors, spacing, typography, borderRadius } = useTheme();

  return {
    inputWrapper: css`
      display: flex;
      border: 1px solid ${colors.lightGrey};
      border-radius: ${borderRadius.m};
      align-items: center;
      padding: 0 ${spacing.m};
      width: 100%;

      svg {
        color: ${colors.darkGrey};
      }
    `,
    input: css`
      ${typography.base};
      appearance: none;
      background-color: ${colors.white};
      border: none;
      font: inherit;
      color: ${colors.navy};
      line-height: inherit;
      padding: ${spacing.s};
      transition-duration: 0.2s;
      transition-property: border-color;
      width: 100%;

      &:hover,
      &:focus {
        border-color: ${colors.navy};
        outline: none;
      }

      /* Chrome/Opera/Safari */
      &::-webkit-input-placeholder {
        color: ${colors.navy};
      }

      /* firefox */
      &::-moz-placeholder {
        color: ${colors.navy};
        opacity: 1;
      }

      /* IE 10+ */
      :-ms-input-placeholder {
        color: ${colors.navy};
      }
    `,
    error: css`
      color: ${colors.red};
    `,
  };
};
