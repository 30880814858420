import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { borderRadius, breakpoints, colors, spacing, typography } = useTheme();

  return {
    base: css`
      height: 100%;
      border-bottom: 1px solid ${colors.lightGrey};
      &:not(:last-child) {
        border-bottom: 1px solid ${colors.lightGrey};
      }
    `,
    header: (isClubPage, isMapPage) => css`
      margin-bottom: 0.2rem;
      display: grid;
      grid-auto-flow: column;
      grid-template-columns: 85% auto;
      padding-right: ${spacing.s};
      align-items: flex-start;

      ${isClubPage &&
      css`
        padding-left: 4rem;
        grid-template-columns: 65% auto;
        position: relative;

        &::after {
          content: '';
          position: absolute;
          left: 0;
          top: 0;
          bottom: 0;
          width: 48px;
          height: 48px;
          border: 1px solid ${colors.darkGrey};
          border-radius: 50%;
          background: url('/images/desktop/initiativeLogo/ef-logo.svg') center
            center no-repeat;
          background-size: 30px 30px;
        }

        ${isMapPage &&
        css`
          grid-template-columns: 60% auto;
          background-size: 42px 50px;
        `}

        ${breakpoints.m} {
          background-size: contain;

          ${isMapPage &&
          css`
            background-size: 42px 65px;
          `}
        }
      `}

      a {
        background-color: transparent;
        color: ${colors.navy};
        text-decoration: none;
        display: inline-block;
        display: flex;
        justify-content: flex-end;
      }

      span {
        margin-top: 4px;
        padding-left: 5px;
        text-decoration: underline;

        ${isClubPage &&
        css`
          margin-top: 8px;
        `}

        ${breakpoints.m} {
          margin-top: 8px;
        }

        ${breakpoints.l} {
          margin-top: 4px;
          ${!isMapPage &&
          css`
            padding-right: ${spacing.s};
          `}
        }
      }

      svg {
        transform: rotate(360deg);
        width: 32px;
        height: 32px;
      }

      ${breakpoints.m} {
        align-items: center;
        margin-bottom: 0.1rem;
        grid-template-columns: 70% auto;

        ${isClubPage &&
        isMapPage &&
        css`
          grid-template-columns: 68% auto;
        `}

        ${!isMapPage &&
        css`
          grid-template-columns: 85% auto;
        `}
      }

      ${breakpoints.l} {
        grid-template-columns: 70% auto;
        padding-right: ${spacing.s};

        ${!isMapPage &&
        css`
          grid-template-columns: 85% auto;
          padding-right: 0;
        `}

        svg {
          width: 24px;
          height: 24px;
        }
      }

      ${breakpoints.xl} {
        grid-template-columns: 55% auto;

        ${isClubPage &&
        css`
          grid-template-columns: 68% auto;
        `}

        ${!isMapPage &&
        css`
          grid-template-columns: 80% auto;
        `}
      }
    `,
    button: css`
      border: 1px solid ${colors.blue};
      width: 100%;
      background: none;
      color: ${colors.navy};
      text-transform: uppercase;

      &:not([disabled]):hover {
        border-color: ${colors.blue};
        background: ${colors.blue};
        color: ${colors.white};
      }

      &:focus {
        border-color: ${colors.blue};
        background: ${colors.blue};
        color: ${colors.white};
        text-decoration: underline;
      }
    `,
    title: (isClubPage, isMapPage) => css`
      display: inline-block;
      color: ${colors.navy};
      position: relative;
      text-transform: capitalize;
      font-size: 20px;

      ${isClubPage &&
      css`
        margin-bottom: 10px;

        ${breakpoints.m} {
          margin-bottom: 0;
        }
      `}

      ${isClubPage &&
      isMapPage &&
      css`
        margin-bottom: 10px;

        ${breakpoints.m} {
          margin-bottom: 0;
        }
      `}

      &::after {
        content: '';
        position: absolute;
        left: 0;
        bottom: -5px;
        width: 40px;
        border-bottom: 2px solid ${colors.red};
      }

      ${breakpoints.m} {
        font-size: 24px;
      }
    `,
    distance: css`
      font-size: 14px;
      line-height: 19px;
      font-weight: 400;
      color: ${colors.navy};
      margin-top: 0;

      ${breakpoints.m} {
        font-size: 14px;
        line-height: 19px;
        font-weight: 400;
        margin-top: ${spacing.xs};
      }
    `,
    subtitle: css`
      display: block;
      color: ${colors.darkGrey};
    `,
    jumpSession: css`
      ${typography.headings[6].mobile};
      font-weight: 500;
      padding-bottom: ${spacing.s};
      padding-top: ${spacing.s};
      border-top: 1px solid ${colors.lightGrey};
    `,
    animateSession: css`
      opacity: 0;
      animation: fade_in_show 1.5s ease-in-out 0s forwards;

      @keyframes fade_in_show {
        from {
          opacity: 0;
          transform: translate3d(0, 40%, 0);
        }

        to {
          opacity: 1;
          transform: none;
        }
      }
    `,
    venueInfo: css`
      padding-bottom: 0.5rem;
      font-weight: 600;
      font-size: 14px;
      white-space: no-wrap;

      ${breakpoints.m} {
        padding-top: 0.5rem;
        // padding-bottom: 0;
        font-size: 18px;
      }
    `,
    border: css`
      border-bottom: 1px solid ${colors.lightGrey};
    `,
    venueDescription: isMapPage => css`
      background-color: ${colors.lightGrey};
      color: ${colors.navy};
      border-radius: ${borderRadius.l};
      padding: 1.5rem 1.5rem 0.5rem 1.5rem;
      display: grid;
      grid-template-columns: 12% auto;
      align-items: inherit;
      margin-top: 1.5rem;
      margin-bottom: 1.5rem;
      border-radius: ${borderRadius.l};
      margin-right: 1.5rem;
      p {
        line-height: 1.5rem;
      }

      ${isMapPage === false &&
      css`
        ${breakpoints.m} {
          grid-template-columns: 5% 95%;
          padding: 0.5rem 4.5rem 0.5rem 4.5rem;
          margin-right: 0;
        }
      `}

      ${isMapPage === true &&
      css`
        margin-right: 0.5rem;

        ${breakpoints.m} {
          margin-right: 1rem;
        }
      `}
    `,

    link: (isDesktop, isMapPage) => css`
      font-size: 14px;
      font-weight: 400;
      text-transform: uppercase;
      padding-top: 1rem;
      display: block;
      line-height: 24px;
      color: ${colors.blue};
      text-decoration: none;

      ${(isDesktop === false || isMapPage === true) &&
      css`
        line-height: 45px;
        &::before {
          content: '';
          ${isMapPage === false &&
          css`
            position: absolute;
          `}

          display: block;
          width: 40px;
          border-bottom: 1px solid ${colors.red};
        }
      `}

      ${breakpoints.m} {
        font-size: 16px;
        ${isMapPage === false &&
        css`
          display: inline;
        `}
        padding-left: ${isDesktop === false || isMapPage === true
          ? 0
          : '1.2rem'};
      }
    `,
    moreInfo: css`
      padding-top: 1rem;

      ${breakpoints.m} {
        padding-top: 0;
      }
    `,
    rightArrow: css`
      color: ${colors.red};
      margin-left: 0.3125rem;
    `,
    alertIcon: css`
      ${breakpoints.m} {
        margin-top: 0.4rem;
      }
    `,
    showmore: css`
      padding-left: 0.5rem;
    `,
    sessionContainer: (isMapPage, cardsLength, isClubPage) => css`
      display: grid;
      grid-gap: ${spacing.s};
      padding-bottom: ${spacing.s};
      margin: ${spacing.s} ${cardsLength > 1 ? 0 : spacing.s} ${spacing.s} 0;
      ${cardsLength > 1 &&
      css`
        grid-template-columns: repeat(${cardsLength}, minmax(253px, auto));
        align-items: center;
        overflow-x: auto;
        -webkit-overflow-scrolling: auto;
        padding-right: ${spacing.s};

        ${isClubPage &&
        css`
          justify-content: stretch;
        `}
      `}

      ${breakpoints.m} {
        padding-bottom: ${spacing.s};
        grid-template-columns: none;

        ${cardsLength <= 2 &&
        isClubPage &&
        css`
          justify-content: stretch;
        `}

        ${!isMapPage &&
        css`
          grid-template-columns: repeat(3, minmax(312px, 1fr));
        `}
      }
    `,
    buttonWrapper: css`
      padding: 0 ${spacing.s} ${spacing.m} 0;

      ${breakpoints.m} {
        padding: 0 ${spacing.s} 2.5rem 0;
      }
    `,
    linkDirSection: css`
      padding-right: ${spacing.s};
    `,
    iconStyle: isClubPage => css`
      max-width: 32px;

      ${isClubPage &&
      css`
        max-width: 24px;
      `}

      ${breakpoints.m} {
        max-width: 24px;
      }
    `,
    info: css`
      color: ${colors.navy};
    `,
    secondaryBtn: css`
      background-color: ${colors.white};
      border: 1px solid ${colors.blue};
      margin: 1rem 0;
    `,
  };
};
