/**
 * Application Error class. An instance can be thrown with a member of the `AppErrorId` enum.
 */
export default class AppError extends Error {
  /**
   * Create a AppError instance
   *
   * @param id Expects a type of error
   */
  constructor(id) {
    super(id);
    this.id = id;
  }
}
