import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { spacing, colors, borderRadius, breakpoints } = useTheme();

  return {
    base: hasError => css`
      display: flex;
      flex-direction: column;
      align-items: center;

      ${breakpoints.l} {
        align-items: start;
      }

      label {
        cursor: pointer;
        color: ${hasError ? colors.darkRed : colors.navy};
        margin-bottom: ${spacing.xs};
      }

      input {
        width: 10.12rem;
        border: 0.1875rem solid;
        border-color: ${hasError ? colors.darkRed : colors.lightGrey};
        border-radius: ${borderRadius.m};
        padding: ${spacing.s} ${spacing.xl};
        text-align: center;
        color: ${hasError ? colors.darkRed : ''};
        outline: 0;

        &:focus {
          border: 0.1875rem solid ${colors.navy};
          border-radius: ${borderRadius.m};
        }
      }
    `,
    errorBox: hasError => css`
      color: ${colors.white};
      background-color: ${colors.darkRed};
      border: 1px solid ${colors.darkRed};
      border-radius: ${borderRadius.l};
      margin-top: ${spacing.s};
      position: relative;
      opacity: ${hasError ? `1` : `0`};

      ${hasError &&
      css`
        transition: opacity 0.5s;
        -webkit-transition: opacity 0.5s;
      `}

      &::before {
        right: 45%;
        top: 0;
        border: solid transparent;
        content: ' ';
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-color: rgba(0, 128, 0, 0);
        border-right-color: ${colors.darkRed};
        border-width: 15px;
        margin-top: -27px;
        transform: rotate(90deg);

        ${breakpoints.l} {
          right: 68%;
        }
      }

      p {
        padding: ${spacing.s};
      }
    `,
  };
};
