export default eventDetails => {
  window.dataLayer.push({
    event: 'All Events',
    eventCategory: eventDetails?.eventCategory,
    eventAction: 'click',
    eventLabel: eventDetails?.eventLabel,
    eventparameter1: eventDetails?.eventparameter1,
    eventparameter2: eventDetails?.eventparameter2,
    eventparameter3: eventDetails?.eventparameter3,
    eventparameter4: eventDetails?.eventparameter4,
    eventparameter5: eventDetails?.eventparameter5,
    category_type: eventDetails?.category_type?.toLowerCase(),
    link_text: eventDetails?.link_text?.toLowerCase(),
    age_group: eventDetails?.eventparameter4,
    gender_group: eventDetails?.eventparameter5,
    club_name: eventDetails?.eventparameter1?.toLowerCase(),
    team_name: eventDetails?.eventparameter2?.toLowerCase(),
    item_list_name: eventDetails?.item_list_name?.toLowerCase(),
    form_name: 'club contact form',
    event_name: eventDetails?.event_name?.toLowerCase(),
    submission_status: eventDetails?.submission_status?.toLowerCase(),
  });
  window.dataLayer.push({
    event: 'Removal Tag',
    eventparameter1: undefined,
    eventparameter2: undefined,
    eventparameter3: undefined,
    eventparameter4: undefined,
    eventparameter5: undefined,
    category_type: undefined,
    link_text: undefined,
    age_group: undefined,
    gender_group: undefined,
    club_name: undefined,
    team_name: undefined,
    item_list_name: undefined,
    form_name: undefined,
    event_name: undefined,
    submission_status: undefined,
  });
};
