import React, { Component } from 'react';

import { Api404, Api500 } from '../../const';
import AppError from '../../utils/AppError';
import Page404 from '../Page404';
import Page500 from '../Page500';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  static getDerivedStateFromError(error) {
    return { error };
  }

  componentDidCatch(error, errorInfo) {
    console.error(error, errorInfo);
  }

  handleReset = () => {
    this.setState({ error: undefined });
  };

  render() {
    const { error } = this.state;

    // If we have an error, work out what it is and how to show it
    if (error) {
      if (error instanceof AppError) {
        switch (error.id) {
          case Api404:
            return <Page404 resetError={this.handleReset} />;
          case Api500:
            return <Page500 resetError={this.handleReset} />;
          default:
            return <Page500 resetError={this.handleReset} />;
        }
      }

      return <Page500 resetError={this.handleReset} />;
    }

    // If we have no error, render children
    return this.props.children;
  }
}

export default ErrorBoundary;
