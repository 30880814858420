/** @jsxImportSource @emotion/react */
import useMOFAInitiatives from '../../hooks/useMOFAInitiatives';
import Text from '../Text';
import VenueEvent from '../VenueEvents';
import useStyles from './styles';

const MOFAInitiativeEvent = ({ pageData }) => {
  const styles = useStyles();

  const faEvents = useMOFAInitiatives(pageData);

  return (
    <div css={styles.faInititativeWrapper}>
      { faEvents?.length > 0 && (
        <div css={styles.middle}>
          <Text as="h3" size="7" css={styles.title}>
            You may also be interested in
          </Text>
          <div css={styles.content}>
            <div css={styles.eventsWrapper}>
              <div css={styles.events}>
                {faEvents?.map(event => (
                  <VenueEvent key={event.id} {...event} />
                ))}
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default MOFAInitiativeEvent;
