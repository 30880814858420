import { getCategoryName } from '../../components/OnboardingModal/MiniOnboarding';

export default eventDetails => {
  const categoryName = getCategoryName();
  window.dataLayer.push({
    event: 'All Events',
    eventCategory: eventDetails?.eventCategory
      ? eventDetails?.eventCategory
      : 'FF Onboarding',
    eventAction: eventDetails?.eventAction,
    eventLabel: eventDetails?.eventLabel,
    eventparameter1: eventDetails?.eventparameter1,
    eventparameter2: eventDetails?.eventparameter2,
    eventparameter3: eventDetails?.eventparameter3,
    eventparameter4: categoryName ? categoryName : undefined,
    age_group: eventDetails?.Age_group,
    category_type: eventDetails?.Category_type,
    gender_group: eventDetails?.Gender_group,
    venue_session: eventDetails?.Venue_session,
    venue_name: eventDetails?.Venue_name,
    link_text: eventDetails?.link_text,
    event_name: eventDetails?.event_name,
    journey: categoryName ? categoryName : 'find football',
    football_type: eventDetails?.football_type,
    price: eventDetails?.price,
  });
  window.dataLayer.push({
    event: 'Removal Tag',
    eventparameter1: undefined,
    eventparameter2: undefined,
    eventparameter3: undefined,
    eventparamenter4: undefined,
    age_group: undefined,
    category_type: undefined,
    gender_group: undefined,
    venue_session: undefined,
    venue_name: undefined,
    link_text: undefined,
    event_name: undefined,
    journey: undefined,
    football_type: undefined,
    price: undefined,
  });
};
