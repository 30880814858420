/** @jsxImportSource @emotion/react */

import { forwardRef } from 'react';

import useStyles from './styles';

const Text = forwardRef(({ as: Tag = 'p', size, children, ...rest }, ref) => {
  const styles = useStyles();

  return (
    <Tag css={styles.text(size)} {...rest} ref={ref}>
      {children}
    </Tag>
  );
});

export default Text;
