/** @jsxImportSource @emotion/react */
import { Fragment, useState } from 'react';

import { useOnboardingDataContext } from '../../context/OnboardingData';
import getCategoryDataLayer from '../../utils/getCategoryDataLayer';
import getNextQuestionId from '../../utils/getNextQuestionId';
import LocationAutoSuggest from '../LocationAutoSuggest';
import NextNav from '../NextNav';
import useStyles from './styles';

const PostcodeQuestion = ({
  placeholder,
  onAnswer,
  ageRanges,
  selectedAge,
}) => {
  const styles = useStyles();
  const [locationData, setLocationData] = useState({});
  const [errorMessage, setErrorMessage] = useState();
  const locationCaptured = locationData.latitude && locationData.longitude;
  const { OnboardingContext, setOnboardingDataContext } =
    useOnboardingDataContext();

  const onError = message => setErrorMessage(message);

  return (
    <Fragment>
      <LocationAutoSuggest
        placeholder={placeholder}
        onError={onError}
        setLocationData={value => {
          setLocationData(value);
          setOnboardingDataContext({
            ...OnboardingContext,
            location:
              value.readableLocation +
              `${
                value.secondaryLocation ? ', ' + value.secondaryLocation : ''
              }`,
          });
        }}
        css={styles.locationInput}
      />
      {errorMessage && <div css={styles.error}>{errorMessage}</div>}
      {locationCaptured && (
        <NextNav
          onClick={() => {
            onAnswer({
              answerData: {
                Latitude: locationData.latitude,
                Longitude: locationData.longitude,
                ReadableLocation: locationData.readableLocation,
              },
              nextQuestionId: getNextQuestionId({
                ageRanges,
                selectedAge,
              }),
            });
            getCategoryDataLayer({
              eventAction: `Step ${OnboardingContext.step} - Next CTA`,
              eventLabel: OnboardingContext.question,
              eventparameter1: `${OnboardingContext.age}${
                OnboardingContext.agegroup
                  ? ' , ' + OnboardingContext.agegroup
                  : ''
              }`,
              eventparameter2: OnboardingContext.disability,
              eventparameter3: locationData.readableLocation,
              gender_group: OnboardingContext.gender_group,
              location: OnboardingContext.location,
              event_name: 'next_button_click',
            });
            setOnboardingDataContext({
              ...OnboardingContext,
              location: locationData.readableLocation,
            });
          }}
        />
      )}
    </Fragment>
  );
};

export default PostcodeQuestion;
