/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from 'react';

import { EF_LOGO_HREF } from '../../const';
import { useSetHeaderHeightContext } from '../../context/setHeaderHeight';
import useLocalStorage from '../../hooks/useLocalStorage';
import useMediaQuery from '../../hooks/useMediaQuery';
import useScroll from '../../hooks/useScrollHeader';
import normaliseHomePageData from '../../normaliser/homepage';
import { getHomepageData } from '../../services';
import publicPath from '../../utils/publicPath';
import B2cSignIn from '../B2cSignIn';
import Button from '../Button';
import ExternalLink from '../ExternalLink';
import { CircleCloseBanner, CircleCloseMobile, CloseMenuIcon } from '../Icons';
import NavMenu from '../NavMenu';
import getHeaderMenu from './getHeaderMenu';
import useStyles from './styles';

const Navigation = ({ withBorder, onClick, isBanner = 'false' }) => {
  const styles = useStyles();
  const { scrollDirection } = useScroll();
  const isMobile = useMediaQuery(`screen and (max-width: 992px)`);
  const [isHamburgerMenu, setIsHamburgerMenu] = useState(false);
  const [isB2cMenu, setIsB2cMenu] = useState(false);
  const [closeBanner, setCloseBanner] = useState(true);
  const isMenuOpened =
    (!isHamburgerMenu && !isMobile) || (isHamburgerMenu && isMobile);
  const { getLocalItem } = useLocalStorage();
  const isIframe = JSON.parse(getLocalItem('ISIFRAME'));

  const { menuList, dropDownKeys, stylingObjects } = getHeaderMenu();
  const isShowTopMenu = stylingObjects?.IsPodcastBanner?.value;
  const { headerHeight, setHeaderHeightValue } = useSetHeaderHeightContext();
  const [homeData, setHomeData] = useState({});

  useEffect(() => {
    document.body.classList.toggle('navigationOpen', isHamburgerMenu);
  }, [isHamburgerMenu]);

  const accountDetailsClick = useCallback(() => {
    setIsB2cMenu(true);
  }, []);

  const ticketEngland = stylingObjects?.OfficialSegment?.value?.text;
  const ticketEnglandHref = stylingObjects?.OfficialSegment?.value?.href;
  const merchandise = stylingObjects?.PodcastSegment?.value?.text;
  const merchandiseHref = stylingObjects?.PodcastSegment?.value?.href;

  useEffect(() => {
    if (!stylingObjects) {
      return;
    }
    setHeaderHeightValue(
      stylingObjects?.IsPodcastBanner?.value
        ? isMobile
          ? '106px'
          : '105px'
        : isMobile
        ? '105px'
        : '74px'
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [stylingObjects, setHeaderHeightValue]);

  useEffect(() => {
    getHomepageData()
      .then(({ data }) => {
        if (data) {
          const HomepageData = normaliseHomePageData(data);
          setHomeData(HomepageData);
        }
      })
      .catch(() => {});
  }, []);

  const isLogo =
    homeData?.bannerInfo?.mobileLogo || homeData?.bannerInfo?.desktopLogo;

  const closeBannerAction = () => {
    setCloseBanner(!closeBanner);
  };

  return (
    <header
      data-test-id="navigation-header-text"
      css={styles.navigation(withBorder, scrollDirection)}
    >
      {!isIframe && (
        <div>
          {isShowTopMenu && (
            <ul css={styles.topMenu}>
              <li>
                <a href={merchandiseHref}>{merchandise}</a>
              </li>
              <li>
                <a href={ticketEnglandHref}>{ticketEngland}</a>
              </li>
            </ul>
          )}
          <div css={styles.inner} className="NavigationInner">
            <div css={styles.logoWrapper(isMobile)}>
              <ExternalLink
                href={EF_LOGO_HREF}
                aria-current="page"
                css={styles.navlink}
                onClick={onClick}
                target=""
              >
                <img
                  css={styles.logoImage}
                  alt="England Football logo"
                  src={publicPath('/images/ef-logo.svg')}
                />
              </ExternalLink>
              {isMobile && isB2cMenu && (
                <Button
                  css={styles.hamburger}
                  aria-label={!isB2cMenu ? 'Navigation Menu' : 'Close'}
                  aria-controls="main-nav"
                  aria-haspopup={isB2cMenu}
                  aria-expanded={isB2cMenu}
                  onClick={() => {
                    setIsB2cMenu(false);
                  }}
                >
                  <CloseMenuIcon />
                </Button>
              )}
              {isMobile && !isB2cMenu && (
                <Button
                  css={styles.hamburger}
                  aria-label={!isHamburgerMenu ? 'Navigation Menu' : 'Close'}
                  aria-controls="main-nav"
                  aria-haspopup={isHamburgerMenu}
                  aria-expanded={isHamburgerMenu}
                  onClick={() => {
                    setIsHamburgerMenu(!isHamburgerMenu);
                  }}
                >
                  <div css={styles.navIcon4(isHamburgerMenu)}>
                    <span></span>
                    <span></span>
                    <span></span>
                  </div>
                </Button>
              )}
            </div>
            <NavMenu
              isMobile={isMobile}
              isMenuOpened={isMenuOpened}
              menuList={menuList}
              dropDownKeys={dropDownKeys}
              stylingObjects={stylingObjects}
              isHamburgerMenu={isHamburgerMenu}
              headerHeight={headerHeight}
            />
            <B2cSignIn
              isMobile={isMobile}
              isB2cMenu={isB2cMenu}
              isHamburgerMenu={isHamburgerMenu}
              accountDetailsClick={accountDetailsClick}
            ></B2cSignIn>
          </div>
        </div>
      )}
      {homeData?.bannerInfo?.bannerText &&
        closeBanner &&
        isBanner === 'true' && (
          <div
            css={styles.innerGlobal(
              homeData?.bannerInfo?.desktopBgImage,
              homeData?.bannerInfo?.mobileBgImage,
              homeData?.bannerInfo?.bgColor
            )}
            className="NavigationInner"
          >
            <a
              css={styles.bannerClick}
              href={homeData?.bannerInfo?.bannerUrl}
              target="_blank"
              rel="nofollow noopener noreferrer"
            >
              <ExternalLink href={homeData?.bannerInfo?.bannerUrl}>
                {isLogo && (
                  <img
                    css={styles.logoImageGlobal}
                    alt="Big Football logo"
                    src={
                      isMobile
                        ? publicPath(homeData?.bannerInfo?.mobileLogo)
                        : publicPath(homeData?.bannerInfo?.desktopLogo)
                    }
                  />
                )}
              </ExternalLink>
              <div css={styles.globalText(homeData?.bannerInfo?.textColor)}>
                {homeData?.bannerInfo?.bannerText}
                <a
                  href={homeData?.bannerInfo?.textLinkUrl}
                  css={styles.globalExternal(homeData?.bannerInfo?.textColor)}
                >
                  {homeData?.bannerInfo?.textLink}
                </a>
              </div>
            </a>
            <button css={styles.alignLogo} onClick={closeBannerAction}>
              {homeData?.bannerInfo?.closeButton === true &&
                (!isMobile ? (
                  <CircleCloseBanner
                    css={styles.globalLogo}
                    fill="transparent"
                  />
                ) : (
                  <CircleCloseMobile
                    css={styles.globalLogo}
                    fill="transparent"
                  />
                ))}
            </button>
          </div>
        )}
    </header>
  );
};

export default Navigation;
