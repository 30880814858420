import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { borderRadius, breakpoints, colors } = useTheme();

  return {
    base: css`
      width: 100%;
      padding: 8px 20px 34px 20px;
      height: 100vh;
      z-index: 1;

      ${breakpoints.l} {
        position: absolute;
        box-shadow: 0 34px 34px rgb(0 0 0 / 25%),
          inset 0 1px 3px rgb(255 255 255 / 50%);
        backdrop-filter: blur(81px);
        border-radius: ${borderRadius.xl};
        right: 0;
        left: auto;
        width: auto;
        height: auto;
        min-width: 354px;
        min-height: 208px;
        top: 60px;
        height: 100%;
      }

      background: ${colors.greyShade2};
      background-blend-mode: hard-light;

      &::before {
        border: transparent;
        height: -29px;
        content: '';
        border-width: 1.25rem;
        border-style: solid;
        border-color: transparent transparent ${colors.greyShade2} transparent;
        position: absolute;
        top: -2rem;
        right: 0.8rem;
      }
    `,

    title: css`
      color: ${colors.darkGrey};
      text-align: left;
      padding-bottom: 1.6rem;
      border-bottom: 1px solid rgba(91, 104, 133, 0.1);
      font-size: 1.5rem;
      font-weight: 700;
      padding: 20px 0;
      font-style: italic;

      ${breakpoints.l} {
        font-size: 1.5rem;
        font-weight: 700;
      }
    `,
    link: css`
      padding: 0;
    `,
    menu: css`
      text-align: left;
      margin-top: 24px;
      border-bottom: 1px solid rgba(91, 104, 133, 0.1);

      li {
        border: ${borderRadius.s} solid transparent;

        &:last-child {
          padding-bottom: 24px;
        }

        a,
        button {
          text-decoration: none;
          color: ${colors.darkGrey};
          font-size: 1.125rem;
          padding: 0;
          text-decoration: none;
          font-weight: normal;
          padding: 10px 8px;

          &:hover,
          &:focus {
            color: ${colors.red};
            text-decoration: underline;
          }
        }
      }
    `,
    externalArrow: css`
      margin-left: 5px;
      padding: 1px;
    `,
  };
};
