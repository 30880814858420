/** @jsxImportSource @emotion/react */
import useStyles from './styles';

const AccredationLevel = ({ accreditationLevel }) => {
  const accredite = accreditationLevel;
  const styles = useStyles();

  return (
    <div css={[styles.ratingContainer, styles.displayNone]}>
      {[...Array(accredite)].map((data, index) => {
        return <div key={index} css={styles.rating} />;
      })}
    </div>
  );
};

export default AccredationLevel;
