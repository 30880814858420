/** @jsxImportSource @emotion/react */

import { useEffect, useLayoutEffect, useRef, useState } from 'react';

import {
  AGE_GROUP_QUESTION,
  AGE_GROUP_VOLUNTEER_QUESTION,
  AGE_QUESTION,
  EMPTY_STRING,
  GEOLOCATION_QUESTION,
  MULTI_OPTIONAL_QUESTION,
  MULTI_QUESTION,
  POSTCODE_QUESTION,
  SINGLE_QUESTION,
  SLIDER_QUESTION,
} from '../../const';
import AgeGroupQuestion from '../AgeGroupQuestion';
import AgeQuestion from '../AgeQuestion';
import ExternalLink from '../ExternalLink';
import GeolocationQuestion from '../GeolocationQuestion';
import MultipleOptionalQuestion from '../MultipleOptionalQuestion';
import MultipleQuestion from '../MultipleQuestion';
import {
  ageHeaderTitle,
  genderHeaderTitle,
} from '../OnboardingModal/MiniOnboarding';
import PostcodeQuestion from '../PostcodeQuestion';
import ProgressBar from '../ProgressBar';
import SingleQuestion from '../SingleQuestion';
import SliderQuestion from '../SliderQuestion';
import Text from '../Text';
import useStyles from './styles';

const Questions = {
  [SINGLE_QUESTION]: SingleQuestion,
  [MULTI_OPTIONAL_QUESTION]: MultipleOptionalQuestion,
  [MULTI_QUESTION]: MultipleQuestion,
  [SLIDER_QUESTION]: SliderQuestion,
  [GEOLOCATION_QUESTION]: GeolocationQuestion,
  [POSTCODE_QUESTION]: PostcodeQuestion,
  [AGE_QUESTION]: AgeQuestion,
  [AGE_GROUP_QUESTION]: AgeGroupQuestion,
  [AGE_GROUP_VOLUNTEER_QUESTION]: AgeGroupQuestion,
};

const Question = ({
  type,
  title,
  questionOrder,
  helpText,
  totalSteps,
  infoLink,
  selectedAge,
  selectedGender,
  helpTextSecondary,
  ...rest
}) => {
  const titleRef = useRef();
  const styles = useStyles();
  const { searchFor } = rest;
  const [headerTitle, setHeaderTitle] = useState();
  const questionTitle = searchFor ? title[searchFor] : EMPTY_STRING;

  useEffect(() => {
    setHeaderTitle(
      !selectedAge
        ? ageHeaderTitle(questionTitle)
        : !selectedGender && questionOrder === 3
        ? genderHeaderTitle(questionTitle)
        : questionOrder === 6 &&
          JSON.parse(sessionStorage.getItem('ISVOLUNTEER'))
        ? 'Would you like to volunteer in a disability team?'
        : questionTitle
    );
  }, [questionTitle, selectedAge, selectedGender, questionOrder]);

  // when the question is changed we want to focus on the new one
  useLayoutEffect(() => {
    setTimeout(() => {
      titleRef.current?.focus({ preventScroll: true });
    }, 200);
  }, [rest.id]);

  if (!type) {
    return null;
  }

  const CurrentQuestion = Questions[type];
  const progress = (questionOrder * 100) / totalSteps;

  return (
    <div css={styles.base}>
      <div>
        <ProgressBar progress={progress} css={styles.progressBar} />
        <Text
          as="h1"
          size="2"
          id={rest.readableId}
          css={styles.title(rest?.readableId)}
          ref={titleRef}
          tabIndex={-1}
        >
          {headerTitle}
        </Text>
        {helpText && (
          <Text css={styles.helpText(rest?.readableId)}>
            {selectedAge &&
            !selectedGender &&
            questionOrder === 3 &&
            JSON.parse(sessionStorage.getItem('ISVOLUNTEER'))
              ? 'Please choose the gender(s) that best suits you'
              : helpText}
          </Text>
        )}
        {helpTextSecondary && (
          <Text css={[styles.helpText, styles.helpSecText]}>
            {helpTextSecondary}
          </Text>
        )}
      </div>
      <div css={styles.answers}>
        <fieldset css={styles.fieldset}>
          <legend>{questionTitle}</legend>
          <CurrentQuestion
            selectedAge={selectedAge}
            questionOrder={questionOrder}
            headerTitle={headerTitle}
            {...rest}
          />
        </fieldset>
        {infoLink && infoLink.href && (
          <ExternalLink css={styles.infoLink} href={infoLink.href}>
            {infoLink.text}
          </ExternalLink>
        )}
      </div>
    </div>
  );
};

export default Question;
