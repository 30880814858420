/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';

import ExternalLink from '../ExternalLink';
import Text from '../Text';
import useStyles from './styles';

const InitiativeCard = ({ heading, description, href, ...rest }) => {
  const styles = useStyles();
  const { images, ctaId } = rest;

  return (
    <div css={styles.cards}>
      <div css={styles.card}>
        <ExternalLink
          id={ctaId}
          href={href}
          css={styles.imgSection({ images })}
          aria-label={`Learn more about ${description} - opens in a new window`}
        >
          <Text as="p" css={styles.description}>
            {description}
          </Text>
        </ExternalLink>
      </div>
    </div>
  );
};

InitiativeCard.prototype = {
  heading: PropTypes.string,
  description: PropTypes.string,
  href: PropTypes.string,
};

export default InitiativeCard;
