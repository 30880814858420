/** @jsxImportSource @emotion/react */

import { Fragment } from 'react';

import useMediaQuery from '../../hooks/useMediaQuery';
import ContactDetails from '../ContactDetails';
import InfoFacilities from '../InfoFacilities';
import MatchDetails from '../MatchDetails';
import Text from '../Text';
import useStyles from '../VenueDetails/styles';
import useStylesClub from './styles';

const TeamInfo = ({ teamDetails, contact, onClick }) => {
  const {
    teaminfo,
    facilities,
    nextfixtures,
    contactAddress,
    title,
  } = teamDetails;
  const styles = useStyles();
  const stylesClub = useStylesClub();
  const isMobile = useMediaQuery('screen and (max-width: 768px)');

  return (
    <Fragment>
      <div css={stylesClub.middle}>
        <div css={styles.leftContent}>
          <div css={styles.top}>
            <Text as="h2" size="5" css={stylesClub.title}>
              About {title}
            </Text>
          </div>
          <div css={styles.footballTypeWrapper}>
            {teaminfo && (
              <InfoFacilities
                isClubInfo={true}
                {...teaminfo}
                isTeamInfo={true}
              />
            )}
          </div>
          <div css={stylesClub.venueFacilitiesWrapper}>
            {facilities && <InfoFacilities isClubInfo={true} {...facilities} />}
          </div>
          <div css={stylesClub.matchFacilitiesWrapper}>
            {nextfixtures && (
              <MatchDetails isClubInfo={true} {...nextfixtures} />
            )}
          </div>
        </div>
        {!isMobile && (
          <div css={styles.rightContents}>
            <ContactDetails
              title="Club Contact"
              contactDetails={contact}
              isClubInfo={true}
              subTitle="For information about a specific team, please get in touch with the club contact."
              onClick={onClick}
            >
              <span css={styles.wrapText}>{contactAddress}</span>
            </ContactDetails>
          </div>
        )}
      </div>
    </Fragment>
  );
};

export default TeamInfo;
