import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const {
    screenSizes,
    spacing,
    colors,
    utility,
    borderRadius,
    breakpoints,
    mediaQueries,
  } = useTheme();

  return {
    base: (isVisible, isNarrowScreen) => css`
      display: grid;
      align-items: center;
      justify-items: center;
      position: absolute;
      z-index: 11;
      width: 100%;
      bottom: 0;
      left: 0;
      max-width: 375px;
      height: 95%;
      max-height: 800px;
      margin-left: 50%;
      transition: transform 0.5s ease-out;
      transform: translateX(-50%) translateY(100%);
      border-top-left-radius: 25px;
      border-top-right-radius: 25px;
      background: url(${publicPath('/images/mobile/waves-solid.png')}) left
        bottom no-repeat;
      background-color: ${colors.white};
      padding: ${spacing.s} ${spacing.m};

      &::before {
        content: '';
        position: absolute;
        z-index: -1;
        top: 0;
        left: 0;
        background: url(${publicPath('/images/mobile/waves-outline.png')}) 0 0
          no-repeat;
        width: 100%;
        height: 120px;
      }

      ${isVisible &&
      css`
        transform: translate(-50%, 0%);

        ${breakpoints.m} {
          margin-left: 0;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      `}

      ${breakpoints.l} {
        align-items: center;
        max-width: ${screenSizes.l};
        height: 80%;
        max-height: 720px;
        border-radius: 8px;
        background: url(${publicPath('/images/desktop/waves-solid.png')}) bottom
          right no-repeat;
        background-size: 65%;
        background-color: ${colors.white};
        padding: ${isNarrowScreen ? '2rem 7rem' : '7rem'};

        &::before {
          background: url(${publicPath('/images/desktop/waves-outline.png')}) 0 -50px
            no-repeat;
          background-size: 50%;
          height: 100%;
          top: 0;
        }

        /* EDGE CASE OF SUPER NARROW SCREEN
          Using this instead of media-query in the CSS
          as for some reason max-height is not being
          picked up by the browser */
        ${isNarrowScreen &&
        css`
          li {
            padding-bottom: ${spacing.s};

            button {
              padding: 1.6rem;
            }
          }
        `}

        ${breakpoints.xl} {
          max-width: 1190px;
        }
      }

      ${mediaQueries.zoomedIn ||
      mediaQueries.zoomedInL2 ||
      mediaQueries.zoomedInL3} {
        background-position: 0 250%;
      }

      ${mediaQueries.iphoneZoom} {
        background-position: 0 250%;
      }
    `,
    overlay: css`
      ${utility.overlay};
    `,
    closeBtn: css`
      ${utility.buttonDefault};
      position: absolute;
      border-radius: 50%;
      top: ${spacing.s};
      right: ${spacing.s};
      width: 40px;
      height: 40px;
      outline: 0;

      svg {
        padding: 0.1rem;
        border: 2px solid transparent;
        color: ${colors.navy};
      }

      &:focus {
        svg {
          padding: 0.1rem;
          border: ${borderRadius.s} solid ${colors.navy};
          border-radius: 50%;
        }
      }

      ${breakpoints.l} {
        right: auto;
        left: ${spacing.s};
      }

      ${mediaQueries.zoomedIn ||
      mediaQueries.zoomedInL2 ||
      mediaQueries.zoomedInL3} {
        background: ${colors.white};
        z-index: 2;
      }

      ${mediaQueries.iphoneZoom} {
        background: ${colors.white};
        z-index: 2;
      }
    `,
    question: css`
      @media (max-width: ${screenSizes.l}) {
        height: 100%;
        display: grid;
        align-items: center;
        position: relative;
      }

      ${mediaQueries.zoomedIn ||
      mediaQueries.zoomedInL2 ||
      mediaQueries.zoomedInL3} {
        position: unset;
        overflow: auto;
      }

      ${mediaQueries.iphoneZoom} {
        position: unset;
        overflow: auto;
      }
    `,
    backLink: css`
      display: flex;
      padding: 0.2rem;
      border-radius: ${borderRadius.m};
      align-items: center;
      position: absolute;
      bottom: ${spacing.s};
      left: ${spacing.m};
      color: ${colors.white};
      border: 1.5px solid transparent;
      text-decoration: none;
      outline: 0;

      &:focus:not(:hover) {
        padding: 0.2rem;
        border: 1.5px solid ${colors.navy};
        border-radius: ${borderRadius.m};
        text-decoration: underline;
      }

      svg {
        display: inline-block;
        width: 30px;
        height: 30px;
        margin-right: ${spacing.xs};
      }

      &:hover {
        outline: 0;
        text-decoration: underline;
      }

      ${breakpoints.l} {
        bottom: ${spacing.xl};
        left: 7rem;
        color: ${colors.navy};
      }

      ${mediaQueries.zoomedIn ||
      mediaQueries.zoomedInL2 ||
      mediaQueries.zoomedInL3} {
        bottom: ${spacing.xs};
        color: ${colors.navy};
        z-index: 2;

        &:hover {
          color: ${colors.blue};
          outline: 0;
        }
      }

      ${mediaQueries.iphoneZoom} {
        bottom: ${spacing.xs};
        color: ${colors.navy};
        z-index: 2;

        &:hover {
          color: ${colors.blue};
          outline: 0;
        }
      }
    `,
    // When the button would go over the content (ie zoom)
    // we want a white background for the button
    fakeButtonOverlay: css`
      display: none;

      ${mediaQueries.zoomedIn ||
      mediaQueries.zoomedInL2 ||
      mediaQueries.zoomedInL3} {
        display: block;
        position: absolute;
        bottom: ${spacing.xs};
        left: ${spacing.m};
        right: ${spacing.m};
        bottom: ${spacing.xs};
        height: 1.875rem;
        background: white;
        z-index: 1;
      }

      ${mediaQueries.iphoneZoom} {
        display: block;
        position: absolute;
        bottom: ${spacing.xs};
        left: ${spacing.m};
        right: ${spacing.m};
        bottom: ${spacing.xs};
        height: 1.875rem;
        background: white;
        z-index: 1;
      }
    `,
  };
};
