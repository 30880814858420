import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, colors, spacing, borderRadius, typography } = useTheme();

  return {
    matchDetailsWrapper: hasAddress => css`
      ${hasAddress &&
      css`
        border-radius: ${borderRadius.l};
      `}
    `,
    facilitiesWrapper: hasAddress => css`
      background-color: ${colors.lightGrey};
      padding: 1.5rem ${spacing.s};
      margin-top: 1.5rem;
      border-radius: ${borderRadius.l};

      ${hasAddress &&
      css`
        border-radius: ${borderRadius.l} ${borderRadius.l} 0 0;
      `}
    `,
    title: css`
      color: ${colors.navy};
      text-align: center;

      ${breakpoints.l} {
        display: grid;
        align-content: center;
        letter-spacing: -0.01em;
      }
    `,
    subtitle: css`
      font-style: normal;
      font-size: 0.875rem;
      line-height: 1.375rem;
      color: ${colors.darkGrey};
      text-align: center;
      padding-bottom: ${spacing.s};
      font-weight: 400;

      &::after {
        content: '';
        width: ${spacing.s};
        border-bottom: ${borderRadius.s} solid ${colors.red};
        margin: 5px auto 0;
      }

      ${breakpoints.l} {
        color: ${colors.darkGrey};
        font-weight: 400;
        font-size: ${spacing.s};
        font-style: normal;
        line-height: 1.375rem;
        display: grid;
        align-content: center;
        letter-spacing: 0.01em;
      }
    `,
    fixturesTeam: css`
      display: grid;
      grid-template-columns: 1fr auto 1fr;
      text-align: center;
      margin: 0 auto;
      color: ${colors.navy};
    `,
    matcTitle: css`
      display: grid;
      align-content: center;

      &::after {
        content: '';
        position: absolute;
        left: Calc(100% - 68%);
        width: 40px;
        border-bottom: ${borderRadius.s} solid ${colors.red};
      }
    `,
    locationAddress: css`
      color: ${colors.navy};
      text-align: center;
      font-weight: 700;
      font-size: 0.875rem;

      ${breakpoints.l} {
        font-size: ${spacing.s};
        font-weight: 700;
      }
    `,
    mapIcon: css`
      background: url('/images/common/team-location-pin.svg') no-repeat;
      height: 40px;
      width: 30px;
    `,
    addressContainer: css`
      display: grid;
      grid-template-columns: auto auto;
      align-items: center;
      justify-content: center;
      grid-gap: 5px;
    `,
    address: css`
      width: 100%;
      bottom: 0;
      background-color: #dadee7;
      align-content: center;
      padding: ${spacing.s} 0 ${spacing.s} 0;
      border-radius: 0 0 ${borderRadius.l} ${borderRadius.l};
    `,
    matchDateTime: css`
      color: ${colors.darkGrey};
      font-size: 0.875rem;
      font-weight: normal;

      ${breakpoints.l} {
        display: grid;
        align-content: center;
        font-size: ${spacing.s};
        font-weight: normal;
      }
    `,
    infoFacilities: css`
      background-color: ${colors.lightGrey};
      border-radius: ${borderRadius.l} ${borderRadius.l} 0 0;
    `,
    nextFixture: css`
      position: relative;
      > div {
        font-weight: bold;
      }
      display: grid;
      align-items: flex-start;
      grid-row-gap: 5px;
      justify-content: center;
      grid-template-rows: minmax(80px, 80px) auto;
      justify-self: center;

      ${breakpoints.l} {
        align-items: center;
        letter-spacing: -0.01em;
        grid-template-rows: auto;
        grid-template-columns: auto auto;
        grid-row-gap: 0;
        > div {
          &:first-of-type {
            margin-right: 0.625rem;
          }
        }
      }
    `,
    imgWrapper: css`
      background-color: ${colors.white};
      border-radius: 50%;
      padding: ${spacing.s};
      height: 72px;
      width: 72px;
      margin: 0 auto;
    `,
    ageGroupHome: css`
      text-align: center;

      ${breakpoints.l} {
        text-align: right;
      }
    `,
    roadNameAgeGroup: css`
      text-align: center;

      ${breakpoints.l} {
        text-align: left;
      }
    `,
    fixturesSplit: css`
      margin: 0 5px;
      padding-top: ${spacing.s};

      ${breakpoints.l} {
        padding-top: 0;
        display: grid;
        align-items: center;
        margin: 0.625rem;
      }
    `,
    fixturesSplitVersus: css`
      margin: 0.625rem;
      font-size: 1.8125rem;
      font-weight: 700;

      ${breakpoints.l} {
        font-size: 1.8125rem;
        font-weight: 700;
        line-height: ${spacing.m};
      }
    `,
    nextFixtureName: css`
      ${breakpoints.l} {
        ${typography.headings[7].desktop};
        text-align: right;
      }
    `,
    fixturesSplitScore: css`
      background-color: ${colors.navy};
      color: ${colors.white};
      padding: 7px 10px;
      border-radius: ${borderRadius.s};
      font-size: 1.875rem;

      ${breakpoints.l} {
        ${typography.headings[7].desktop};
        margin: 0 ${spacing.s};
      }
    `,
    roadNameWrapper: css`
      justify-content: flex-start;
      grid-template-rows: minmax(80px, 80px) auto;
      align-items: flex-start;

      ${breakpoints.l} {
        grid-template-rows: auto;
        justify-self: flex-start;
        align-items: center;
      }
    `,
    homeNameWrapper: css`
      justify-content: flex-start;
      ${breakpoints.l} {
        justify-content: flex-end;
      }
    `,
    homeNameFixture: css`
      order: 2;

      ${breakpoints.l} {
        order: unset;
      }
    `,
  };
};
