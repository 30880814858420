import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors, spacing, breakpoints } = useTheme();

  return {
    answer: isPrimary => css`
      padding-bottom: ${spacing.s};

      button {
        border: 3px solid transparent;
      }

      ${breakpoints.l} {
        padding-bottom: ${isPrimary ? spacing.m : spacing.s};

        ${isPrimary &&
      css`
          button {
            border: 3px solid transparent;
            outline:0;

            &:not(:hover):focus {
              outline: 0;
              border: 3px solid ${colors.navy};
              background-color: ${colors.white};

              span {
              text-decoration: underline;
              }
            }
          }
          
          &:last-child {
            padding-bottom: 0;
    
            button {
              margin: auto;
              outline:0;

              &:focus:not(:hover) {
                color: ${colors.navy};
              }
            }
      `}
      }

      &:last-child {
        padding-bottom: 0;

        button {
          margin: auto;
          outline: 0;

          &:focus:not(:hover) {
            color: ${colors.navy};
          }
        }
      }
    `,
    cta: css`
      font-size: 1.125rem;
      font-weight: 700;
      padding: 1.1rem ${spacing.s};
    `,
  };
};
