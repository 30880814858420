export default ({ ageRanges, selectedAge, selectedQuestion }) => {
  if (!ageRanges || ageRanges.length === 0) return '';
  // If there's just one age range in the array
  // we can assume there's no age restrictions
  if (selectedQuestion) {
    return selectedQuestion;
  }
  if (ageRanges.length === 1) {
    return ageRanges[0].nextQuestionId;
  } else {
    // Get the next question ID based on the selected age
    const selectedRange = ageRanges.filter(
      range => selectedAge >= range.min && selectedAge <= range.max
    );
    return selectedRange[0] ? selectedRange[0].nextQuestionId : '';
  }
};
