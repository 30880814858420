import {
  ADULTCLUB_URL,
  AGE_UK_URL,
  JUST_PLAY_URL,
  LETGOGIRLSPLAY_URL,
  ONBOARDING_IFRAME_URL,
  ONBOARDING_URL,
  SQUAD_GIRLS_URL,
  VOLUNTEER_URL,
  WILDCATS_URL,
  YOUTH_CLUB_URL,
} from '../../const';

export default () => {
  switch (true) {
    case JSON.parse(sessionStorage.getItem('ISIFRAME')):
      return ONBOARDING_IFRAME_URL;
    case JSON.parse(sessionStorage.getItem('ISLETGOGIRLSPLAY')):
      return LETGOGIRLSPLAY_URL;
    case JSON.parse(sessionStorage.getItem('ISWILDCATS')):
      return WILDCATS_URL;
    case JSON.parse(sessionStorage.getItem('ISJUSTPLAY')):
      return JUST_PLAY_URL;
    case JSON.parse(sessionStorage.getItem('ISAGEUK')):
      return AGE_UK_URL;
    case JSON.parse(sessionStorage.getItem('ISYOUTHCLUB')):
      return YOUTH_CLUB_URL;
    case JSON.parse(sessionStorage.getItem('ISADULTCLUB')):
      return ADULTCLUB_URL;
    case JSON.parse(sessionStorage.getItem('ISSQUADGIRLS')):
      return SQUAD_GIRLS_URL;
    case JSON.parse(sessionStorage.getItem('ISVOLUNTEER')):
      return VOLUNTEER_URL;
    default:
      return ONBOARDING_URL;
  }
};
