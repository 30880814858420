import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import { EXPANDED, SHRUNK } from '../../reducers/trayState';

export default () => {
  const { colors, breakpoints, spacing, borderRadius, heights } = useTheme();

  return {
    base: css`
      position: relative;
      height: 100%;
      display: grid;
      justify-content: center;
      grid-template-areas:
        'header'
        'map'
        'results';
      grid-template-rows: auto 1fr auto;
      grid-template-columns: 1fr;

      ${breakpoints.m} {
        grid-template-areas:
          'header map'
          'results map';
        grid-template-columns: min(50%, 375px) 1fr;
      }

      ${breakpoints.l} {
        grid-template-rows: auto calc(
            100vh - ${heights.mainNav.desktop} - ${heights.filterNav}
          );
        grid-template-columns: 375px 1fr;
      }

      ${breakpoints.l} {
        grid-template-rows: auto calc(
            100vh - ${heights.mainNav.desktop} - ${heights.filterNav}
          );
      }
    `,
    header: css`
      grid-area: header;
      background: ${colors.white};
      margin-top: ${heights.filterNav};
      border-top: 1px solid ${colors.lightGrey};
      border-bottom: 1px solid ${colors.lightGrey};

      a {
        max-width: 100%;
        width: 100%;
      }

      ${breakpoints.m} {
        margin-top: ${heights.mainNav.desktop};
      }

      ${breakpoints.l} {
        margin-top: ${heights.mainNav.desktop};
      }
    `,
    backLink: css`
      color: ${colors.navy};

      &:focus {
        outline: 0;
        p {
          text-decoration: underline;
        }
      }
    `,
    headerTitle: css`
      display: inline-block;
    `,
    mapAreaWrapper: css`
      grid-area: map;

      ${breakpoints.l} {
        margin-top: ${heights.mainNav.desktop};
      }
    `,
    providerCardWrapper: (isListView, trayState) => css`
      position: relative;
      grid-area: results;
      height: clamp(170px, 65vh, 280px);
      margin-top: -2rem;
      background: ${colors.white};
      box-shadow: 0px 4px 6px ${colors.black};
      align-items: center;
      transition: all 0.2s ease-in-out;
      margin-left: calc((100vw - 100%) / -2);
      width: 100vw;
      z-index: 100;

      ${trayState === EXPANDED &&
      css`
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        height: 75vh;
      `}

      ${trayState === SHRUNK &&
      css`
        transition: all 0.2s cubic-bezier(0.4, 0, 0.2, 1);
        height: 55px;
      `}

      ${breakpoints.m} {
        overflow: hidden;
        margin-top: 0;
        border-radius: 0;
        height: 100%;
        margin-left: 0;
        width: 100%;
        z-index: initial;
      }

      ${breakpoints.l} {
        padding: 0;
        box-shadow: none;
        width: 100%;
      }

      ${!isListView &&
      css`
        padding-bottom: 0;
        transition: all 0.3s cubic-bezier(0.4, 0, 0.2, 1);
        height: calc(317px + ${spacing.xs} + ${spacing.s});
        box-sizing: content-box;

        ${breakpoints.m} {
          position: relative;
          height: 100%;
          background-color: ${colors.white};
          display: block;
          justify-content: center;
          height: 100%;
        }

        ${breakpoints.l} {
          position: relative;
          bottom: unset;
          left: unset;
          border-radius: 0;
          height: 100%;
        }
      `}
    `,
    scrollContainer: css`
      position: relative;
      height: 90%;
      background-color: ${colors.white};
      display: flex;
      justify-content: center;

      ${breakpoints.m} {
        height: 90%;
        display: block;
      }
    `,
    providerCards: isListView => css`
      border-radius: ${borderRadius.l};
      overflow-y: scroll;
      height: 100%;
      align-items: center;
      padding: 0 ${spacing.s} ${spacing.m};
      display: ${isListView ? 'block' : 'none'};
    `,
    resultCard: css`
      border: ${borderRadius.m} solid ${colors.red};

      &:hover,
      &:focus {
        outline: 0;
        border: ${borderRadius.m} solid ${colors.navy};
      }
    `,
    totalResults: isListView => css`
      display: none;
      text-align: center;
      padding: ${spacing.s} 0 1.2rem 0;
      position: relative;
      color: ${colors.navy};

      span {
        padding-top: 8px;
        border-top: ${borderRadius.s} solid ${colors.black};
      }

      ${breakpoints.m} {
        span {
          padding-top: 0;
          border-top: 0;
        }
      }

      ${breakpoints.l} {
        display: block;
      }

      ${isListView &&
      css`
        display: block;
      `}
    `,
    primaryBtn: css`
      background-color: ${colors.lightGrey};
      color: ${colors.navy};
      text-align: left;
      font-size: 1rem;
      line-height: 18px;
      padding: 10px 23px;
      font-weight: 400;
      display: inline-block;

      &:focus,
      &:not(:disabled):hover {
        background-color: transparent;
      }

      ${breakpoints.m} {
        background-color: ${colors.white};
      }
    `,
    backToWrapper: css`
      background-color: ${colors.white};
      border-bottom: 1px solid ${colors.lightGrey};

      ${breakpoints.m} {
        padding: 0;
      }
    `,
    arrowRotate: css`
      transform: rotate(180deg);
      margin-right: 5px;
    `,
    loadMore: isListView => css`
      margin: ${spacing.m} ${spacing.xs} 0 ${spacing.xs};
      text-transform: uppercase;
      button {
        text-transform: uppercase;
      }

      ${!isListView &&
      css`
        display: none;

        ${breakpoints.l} {
          display: block;
        }
      `}
    `,
    loader: css`
      margin-top: ${spacing.s};
      margin-bottom: ${spacing.s};
    `,
    recommendationListWrapper: cardsLength => css`
      padding: ${spacing.s} ${cardsLength > 1 ? 0 : spacing.s} 1.5rem 1.5rem;
      height: 100%;
      width: 100%;
      overflow-y: auto;
      -webkit-overflow-scrolling: auto;

      ${breakpoints.m} {
        padding: 0.3rem ${spacing.xs} 1.5rem 1.2rem;
      }

      ${breakpoints.xl} {
        padding: ${spacing.s} ${spacing.xs} 1.5rem 1.2rem;
      }
    `,
  };
};
