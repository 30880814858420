import isempty from 'lodash.isempty';

import {
  CarIcon,
  Disability,
  FaInitiative,
  FootballTypes,
  Person,
  Pitch,
} from '../../components/Icons';
import {
  DISABILITY_FACILITIES_MAPPING,
  DISABILITY_TYPES_MAPPING,
  EMAIL,
  EMPTY_STRING,
  PHONE_NUMBER,
  WEBSITE,
} from '../../const';
import formatAddress from '../../utils/formatAddress';

const normaliseVenueDetails = apiData => {
  const {
    CentreName,
    DisableFacility,
    DistanceFromUserLocation,
    PitchTypes,
    Facility,
    SessionInfo,
    EmailAddress,
    Website,
    TelephoneNumber,
    SessionTab,
    Latitude,
    Longitude,
    ...rest
  } = apiData;

  const isDisableFacilityAvailable =
    DisableFacility &&
    Object.keys(DisableFacility).every(value => !DisableFacility[value]);
  const isFacilityAvailable =
    Facility && Object.keys(Facility).every(value => !Facility[value]);

  return {
    title: CentreName,
    address: formatAddress(rest),
    contactAddress: formatAddress(rest, '\n'),
    distance: DistanceFromUserLocation,
    latitude: Latitude,
    longitude: Longitude,
    facilities:
      isFacilityAvailable && isDisableFacilityAvailable && isempty(PitchTypes)
        ? EMPTY_STRING
        : {
            title: 'Venue Facilities',
            values: [
              {
                title: 'Facilities',
                icon: CarIcon,
                rows: [
                  ...(Facility.CarPark && Facility.CarParkCapacity > 0
                    ? [`${Facility.CarParkCapacity} Parking Spaces`]
                    : []),
                  ...(Facility.ChangingRooms ? ['Changing Rooms'] : []),
                  ...(Facility.Floodlit ? ['Floodlights'] : []),
                ],
              },
              {
                title: 'Pitch types',
                icon: Pitch,
                rows: PitchTypes,
              },
              {
                title: 'Disability Facilities',
                icon: Disability,
                rows: Object.keys(DisableFacility)
                  .map(
                    facility =>
                      DisableFacility[facility] &&
                      DISABILITY_FACILITIES_MAPPING[facility]
                  )
                  .filter(Boolean),
              },
            ],
          },
    footballtypes: !isempty(
      SessionInfo.FootballType &&
        SessionInfo.DisabilityType &&
        SessionInfo.Gender
    )
      ? {
          title: 'Football types played here',
          values: [
            {
              title: 'Sessions football types',
              icon: FootballTypes,
              rows: SessionInfo.FootballType,
            },
            {
              title: 'Sessions disability types',
              icon: FaInitiative,
              rows: SessionInfo.DisabilityType.map(
                type => DISABILITY_TYPES_MAPPING[type]
              ).filter(Boolean),
            },
            {
              title: 'Sessions gender groups',
              icon: Person,
              rows: SessionInfo.Gender.map(gender => `${gender} Football`),
            },
          ],
        }
      : EMPTY_STRING,
    events: SessionInfo.FaInitiative,
    contact: {
      title: 'Reception',
      details: {
        ...(TelephoneNumber ? { [PHONE_NUMBER]: TelephoneNumber } : {}),
        ...(EmailAddress ? { [EMAIL]: EmailAddress } : {}),
        ...(Website ? { [WEBSITE]: Website } : {}),
      },
    },
    sessionsCount: SessionInfo.SessionCount,
    venueTabs: SessionTab.map((session, index) => ({
      id: index,
      label: `${session.Month} (${session.CntSessionMonth})`,
    })),
    sessions: SessionTab.map(date => {
      const sessionArr = [];
      return date.sessionGroup.map(group => {
        const datevalue = new Date(group.SessionDateTime).getDate();
        if (!sessionArr.includes(datevalue)) {
          group['sessionDate'] = group.SessionDateTime;
          sessionArr.push(datevalue);
        }
        return group;
      });
    }),
  };
};

export default normaliseVenueDetails;
