import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors, spacing, breakpoints, screenSizes, utility } = useTheme();

  return {
    footer: css`
      background-color: ${colors.black};
      color: ${colors.white};
      position: relative;
      font-weight: normal;
      font-size: 0.875rem;
      line-height: 1.125rem;
      padding: 1.5rem 1.5rem ${spacing.xs};
      letter-spacing: 0.02em;

      ${breakpoints.m} {
        padding: ${spacing.s} ${spacing.m};
      }
    `,
    footerWrapper: css`
      display: grid;

      ${breakpoints.m} {
        grid-template-columns: auto 1fr;
        grid-column-gap: ${spacing.s};
      }
    `,
    leftContent: css`
      border-bottom: 1px solid ${colors.darkGrey};
      padding-bottom: ${spacing.s};
      ${breakpoints.m} {
        border-bottom: none;
        padding-bottom: 0;
      }
    `,
    rightContent: css`
      justify-self: center;
      padding: ${spacing.s} 0;

      ${breakpoints.m} {
        justify-self: end;
        padding: 0;
      }
    `,
    list: css`
      display: grid;

      ${breakpoints.m} {
        grid-auto-flow: column;
      }
      grid-gap: ${spacing.s};

      li > a {
        color: ${colors.white};
        font-weight: normal;
        text-decoration: none;
        cursor: pointer;
      }

      @media screen and (max-width: ${screenSizes.m}) {
        grid-template-columns: repeat(3, 1fr);
      }
    `,
    pdfDownload: utility.checkboxHidden,
  };
};
