import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { colors, breakpoints } = useTheme();

  return {
    footer: css`
      background-color: ${colors.white};
      position: relative;
      display: grid;
      align-items: center;
      justify-items: center;
      width: 100%;
      grid-auto-rows: minmax(300px, auto);
      max-height: 300px;
      border-top: 1px solid ${colors.lightGrey};

      &::before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 40%;
        height: 100%;
        background: url(${publicPath(
            '/images/desktop/crops/left-phone-crop.svg'
          )})
          left 0 no-repeat;
        background-size: contain;

        ${breakpoints.m} {
          background: url(${publicPath(
              '/images/desktop/crops/left-result-crop.svg'
            )})
            left 0 no-repeat;
          background-size: contain;
        }
      }

      &::after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        width: 35%;
        height: 100%;
        background: url(${publicPath(
            '/images/desktop/crops/right-phone-crop.svg'
          )})
          right bottom no-repeat;
        background-size: contain;

        ${breakpoints.m} {
          background: url(${publicPath(
              '/images/desktop/crops/right-result-crop.svg'
            )})
            right bottom no-repeat;
          background-size: contain;
        }
      }
    `,
    textWrapper: css`
      position: relative;
      z-index: 1;
      text-align: center;
    `,
    mainText: css`
      font-size: 20px;
      line-height: 22px;
      color: ${colors.navy};
      letter-spacing: -0.02em;
    `,
    cta: css`
      background-color: ${colors.red};
      color: ${colors.white};
      text-transform: uppercase;
      max-width: 345px;
      margin: 1.5rem auto;

      ${breakpoints.l} {
        width: 21.5625rem;
      }
    `,
    link: css`
      display: inline-block;
      color: ${colors.navy};
      font-style: normal;
      font-weight: normal;
      letter-spacing: 0.02em;
      line-height: 22px;
      padding: 0 0.2rem 0 0.2rem;
    `,
    linkWidth: css`
      width: 75%;

      ${breakpoints.m} {
        width: 100%;
      }
    `,
  };
};
