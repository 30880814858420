import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, colors, spacing, typography } = useTheme();

  return {
    base: css`
      background-color: ${colors.white};
      box-shadow: 0px 4px 6px rgba(91, 104, 133, 0.1);
      border-radius: 8px;
      padding: ${spacing.s};
      margin-bottom: ${spacing.s};
      position: relative;

      ${breakpoints.xl} {
        padding: ${spacing.m} ${spacing.l};
        padding-bottom: 0;
        margin-bottom: ${spacing.m};
      }
    `,
    tabs: css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      border: 1px solid ${colors.blue};
    `,
    tab: isSelected => css`
      ${typography.headings[6].mobile};
      color: ${colors.blue};
      padding: 0.8125rem;
      letter-spacing: 0.01rem;
      font-size: 0.875rem;
      font-weight: 400;
      text-transform: uppercase;
      border-radius: 0;

      ${isSelected &&
      css`
        color: ${colors.white};
      `};

      ${breakpoints.xl} {
        padding: ${spacing.s};
      }
    `,
  };
};
