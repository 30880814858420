import { createContext, useCallback, useContext, useState } from 'react';

const ScrollDirectionContext = createContext({
  setScrolldirectionContext: () => { },
});

export const ScrollDirectionProvider = ({ children }) => {
  const [scrolldirectionContext, setScrolldirectionContext] = useState("down");

  // Filter list item click handler
  const setScroll = useCallback(value => {
    if (!value) {
      return;
    }
    setScrolldirectionContext(value);
  }, []);

  return (
    <ScrollDirectionContext.Provider
      value={{ scrolldirectionContext, setScroll }}
    >
      {children}
    </ScrollDirectionContext.Provider>
  );
};

export const useScrollDirectionContext = () =>
  useContext(ScrollDirectionContext);
