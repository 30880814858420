import { useEffect, useState } from 'react';

import { getClubAccreditation } from '../../services';

const getAccreditation = accreditation => {
  if (!['Accredited'].includes(accreditation.accreditationStatus)) {
    if (!['In Progress'].includes(accreditation.accreditationStatus)) {
      return;
    }
  }
  return {
    isAccreditation: true,
    accreditationLevel: accreditation.accreditationLevel,
  };
};

export default id => {
  const [accreditation, setAccreditation] = useState();

  useEffect(() => {
    if (!id) {
      return;
    }

    getClubAccreditation(id)
      .then(({ data }) => {
        setAccreditation(getAccreditation(data));
      })
      // don't throw an error if we don't have the accreditation
      // fail gracefully
      .catch(() => {});
  }, [id]);

  return accreditation;
};
