import { useLayoutEffect, useRef, useState } from 'react';

export default () => {
  const elementRef = useRef();
  const resizeObserver = useRef();
  const [height, setHeight] = useState();

  useLayoutEffect(() => {
    if (!elementRef.current) {
      return;
    }

    const element = elementRef.current;

    setHeight(element?.getBoundingClientRect().top + element?.offsetHeight);

    const listener = () => {
      setHeight(element?.getBoundingClientRect().top + element?.offsetHeight);
    };

    if (typeof window !== 'undefined') {
      resizeObserver.current = new window.ResizeObserver(listener);
      resizeObserver.current.observe(element);
      window.addEventListener('resize', listener);
    }

    return () => {
      resizeObserver.current?.unobserve(element);
      resizeObserver.current?.disconnect();
      document.removeEventListener('resize', listener);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [elementRef.current]);

  return {
    height,
    elementRef,
  };
};
