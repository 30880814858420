// Google tag manager
import gTagManager from 'react-gtm-module';

const initGTagManager = () => {
  // may be replace || with ?? in the future.
  window.dataLayer = window.dataLayer || [];

  if (!process.env.REACT_APP_LOCAL) {
    gTagManager.initialize({
      gtmId: process.env.REACT_APP_GTM_ID,
      ...(process.env.REACT_APP_GTM_AUTH && {
        auth: process.env.REACT_APP_GTM_AUTH,
      }),
      ...(process.env.REACT_APP_GTM_PREVIEW && {
        preview: process.env.REACT_APP_GTM_PREVIEW,
      }),
    });
  }
};

export default initGTagManager;
