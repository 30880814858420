const recommendationVideo = {
  'club': 'https://www.youtube.com/embed/BLnUEm8hOfM',
  'casual': 'https://www.youtube.com/embed/8W2N5IRedIs',
  '11v11': 'https://www.youtube.com/embed/b4BoZKY2UFw',
  'walking-football': 'https://www.youtube.com/embed/850ydOP13hs',
  'futsal': 'https://www.youtube.com/embed/Xk3bS3F6hGE',
  'small-sided': 'https://www.youtube.com/embed/jb-Cqanx6SE',
};

export default recommendationVideo;
