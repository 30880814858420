/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { useState } from 'react';

import useMediaQuery from '../../hooks/useMediaQuery';
import { Chevron } from '../Icons';
import useStyles from './styles';

const CustomSelectDropDown = ({
  id,
  optionsList,
  selectedOptionValue,
  dropDownData,
}) => {
  const [isOptionsOpen, setIsOptionsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState(0);
  const styles = useStyles();
  const isDesktop = useMediaQuery('screen and (min-width: 768px)');

  const toggleOptions = () => {
    setIsOptionsOpen(!isOptionsOpen);
  };

  const selectedValue = ({ index, data }) => {
    dropDownData(data);
    setSelectedOption(index);
    setIsOptionsOpen(!isOptionsOpen);
  };

  const handleListKeyDown = e => {
    switch (e.key) {
      case 'Escape':
        e.preventDefault();
        setIsOptionsOpen(false);
        break;
      case 'ArrowUp':
        e.preventDefault();
        setSelectedOption(
          selectedOption - 1 >= 0 ? selectedOption - 1 : optionsList.length - 1
        );
        break;
      case 'ArrowDown':
        e.preventDefault();
        setSelectedOption(
          selectedOption === optionsList.length - 1 ? 0 : selectedOption + 1
        );
        break;
      default:
        break;
    }
  };

  return (
    <div css={styles.wrapper} id={id}>
      {isDesktop && (
        <div css={styles.container}>
          <button
            type="button"
            onClick={toggleOptions}
            css={styles.dropDownButton}
            aria-haspopup="listbox"
            aria-expanded={isOptionsOpen}
            tabIndex={0}
            onKeyDown={handleListKeyDown}
            onChange={selectedOptionValue(selectedOption)}
          >
            {optionsList[selectedOption].option}
            <span css={styles.arrow(isOptionsOpen)}>
              <Chevron />
            </span>
          </button>
          {isOptionsOpen && (
            <ul
              role="listbox"
              css={styles.list}
              aria-activedescendant={optionsList[selectedOption].option}
              tabIndex={-1}
            >
              {optionsList.map((data, index) => {
                return (
                  <option
                    key={data.id}
                    css={styles.optionstyle}
                    tabIndex={0}
                    aria-selected={selectedOption === index}
                    onClick={() => selectedValue({ index, data: data.option })}
                  >
                    {data.option}
                  </option>
                );
              })}
            </ul>
          )}
        </div>
      )}
      {!isDesktop && (
        <div css={styles.selectWrapper}>
          <select
            onChange={e => {
              selectedValue({ data: e.target.value });
              selectedOptionValue(e.target.value);
            }}
          >
            {optionsList.map((data, index) => {
              return (
                <option
                  key={data.id}
                  onChange={() => {
                    selectedValue({ index, data: data.option });
                  }}
                >
                  {data.option}
                </option>
              );
            })}
          </select>
          <span css={styles.holder}></span>
        </div>
      )}
    </div>
  );
};

CustomSelectDropDown.propTypes = {
  id: PropTypes.string,
  optionsList: PropTypes.object,
  selectedOptionValue: PropTypes.func,
  dropDownData: PropTypes.func,
};

export default CustomSelectDropDown;
