/** @jsxImportSource @emotion/react */
import { forwardRef, Fragment, useLayoutEffect, useRef } from 'react';

import { recommendationsMapPageAnalytics } from '../../analyticsConsts';
import { EMPTY_STRING } from '../../const';
import Button from '../Button';
import HiddenContent from '../HiddenContent';
import { Directions, Pin } from '../Icons';
import LinkDirection from '../LinkDirection';
import Text from '../Text';
import useStyles from './styles';

const {
  CTA_PROVIDER_CLUB_CARD,
  PROVIDER_CLUB_CARD_TITLE,
  MORE_INFO,
  MAP_DIRECTION,
} = recommendationsMapPageAnalytics;

const onCheckDays = currentDay => {
  if (currentDay.IsPlaysOnMonday) {
    return 'Monday';
  } else if (currentDay.IsPlaysOnTuesday) {
    return 'Tuesday';
  } else if (currentDay.IsPlaysOnWednesday) {
    return 'Wednesday';
  } else if (currentDay.IsPlaysOnThursday) {
    return 'Thursday';
  } else if (currentDay.IsPlaysOnFriday) {
    return 'Friday';
  } else if (currentDay.IsPlaysOnSaturday) {
    return 'Saturday';
  } else {
    return 'Sunday';
  }
};

const isAddressNullCheck = value => {
  return value !== null ? `${value},` : EMPTY_STRING;
};

const ProviderClubCard = forwardRef(
  (
    {
      index,
      ClubName,
      ClubId,
      DistanceFromUserLocation,
      TeamsCartDto,
      TeamCount,
      OfferedClubs,
      onClick,
      isSelected,
      Latitude,
      Longitude,
      ...rest
    },
    ref
  ) => {
    const cardRef = useRef();
    const styles = useStyles();
    const isTeamAvailable = TeamsCartDto && TeamsCartDto.length > 0;

    const onCheckAddress = address => {
      return `${
        address.AddressLine1 || address.AddressLine2
      }, ${isAddressNullCheck(address.AddressLine3)}
    ${isAddressNullCheck(address.City)} ${isAddressNullCheck(
        address.PostCode
      )}`;
    };

    const handleKeyPress = event => {
      const enterOrSpace = event.which === 13 || event.which === 32;

      if (enterOrSpace) {
        event.preventDefault();
        onClick(ClubId);
      }
    };

    // if the card gets selected move the focus to it
    useLayoutEffect(() => {
      if (isSelected && cardRef.current) {
        setTimeout(() => {
          cardRef.current.focus();
          cardRef.current.scrollIntoView({
            behaviour: 'smooth',
            block: 'nearest',
          });
        }, 300);
      }
    }, [isSelected]);

    const accessibleProps = isSelected ? {} : { role: 'button', tabIndex: 0 };

    return (
      <li css={styles.wrapper(isSelected)} tabIndex={-1} ref={ref}>
        <div
          {...accessibleProps}
          id={`${CTA_PROVIDER_CLUB_CARD}-${index}`}
          aria-expanded={isSelected}
          css={styles.base}
          onClick={() => onClick(ClubId)}
          onKeyPress={handleKeyPress}
          ref={cardRef}
        >
          <div css={styles.titleWrapper}>
            <Text
              id={`${PROVIDER_CLUB_CARD_TITLE}-${index}`}
              as="h3"
              size="6"
              css={styles.title}
            >
              {ClubName}
            </Text>
          </div>
          <div css={styles.info(isTeamAvailable)}>
            <div css={styles.address}>{onCheckAddress(rest)}</div>
            <div css={styles.distance}>
              <HiddenContent>Distance </HiddenContent>
              <Pin />
              <span>{DistanceFromUserLocation} Miles</span>
            </div>
          </div>
          {isTeamAvailable && (
            <div css={styles.search}>
              {TeamCount} sessions match your search
            </div>
          )}
          {isSelected && (
            <Fragment>
              <div css={styles.teamInfoWrapper}>
                {isTeamAvailable &&
                  TeamsCartDto.map(teamData => (
                    <div key={teamData.TeamId} css={styles.teamInfo}>
                      <Text css={styles.teamName}>
                        <HiddenContent>Team name </HiddenContent>
                        {teamData.TeamName}
                      </Text>
                      <Text css={styles.teamPlayDay(false)}>
                        <HiddenContent>Plays on </HiddenContent>
                        {onCheckDays(teamData)}
                      </Text>
                    </div>
                  ))}
              </div>
              {OfferedClubs && (
                <div css={styles.venueOfferWrapper}>
                  <Text css={styles.venueOffer}>
                    {isTeamAvailable
                      ? 'This venue also offers:'
                      : 'Next available session:'}
                  </Text>
                  <Text css={styles.otherType}>{OfferedClubs}</Text>
                </div>
              )}
              <div css={styles.buttonWrapper}>
                <div css={styles.options}>
                  <Button.Link
                    primary
                    id={`${MORE_INFO}-${index}`}
                    css={styles.primaryBtn}
                    to={`/club/${ClubId}`}
                    aria-label={`More info on ${ClubName}`}
                  >
                    More info
                  </Button.Link>
                  {isTeamAvailable && (
                    <LinkDirection
                      id={`${MAP_DIRECTION}-${index}`}
                      css={styles.secondaryBtn}
                      latitude={Latitude}
                      longitude={Longitude}
                      name={ClubName}
                      icon={Directions}
                      aria-label={`Directions to ${ClubName} - opens in a new window`}
                    >
                      Directions
                    </LinkDirection>
                  )}
                </div>
              </div>
            </Fragment>
          )}
        </div>
      </li>
    );
  }
);

export default ProviderClubCard;
