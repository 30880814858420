import { PASSWORD_RESET_REQUEST } from './authConfig';

const AuthRedirect = msalInstance => {
  // Handle the redirect flows
  if (!localStorage['msal.idtoken']) {
    msalInstance
      .handleRedirectPromise()
      .then(tokenResponse => {
        if (
          tokenResponse.idToken != null ||
          tokenResponse.idToken !== undefined
        ) {
          localStorage.setItem('msal.idtoken', tokenResponse.idToken);
          if (localStorage.getItem('rootUrl')) {
            window.location.href = localStorage.getItem('rootUrl');
          }
        }
        // Handle redirect response
      })
      .catch(error => {
        if (error) {
          // Handle redirect error
          localStorage.setItem('error', error);
          let errorInLocal = localStorage.getItem('error');
          if (errorInLocal.indexOf('AADB2C90118') > -1) {
            redirectIfForgotPassword();
          }
          if (errorInLocal.indexOf('AADB2C90091') > -1) {
            if (localStorage.getItem('rootUrl')) {
              window.location.href = localStorage.getItem('rootUrl');
            } else {
              window.location.href = localStorage.getItem('pathInfomation');
            }
          }
        }
      });
  }

  const redirectIfForgotPassword = () => {
    localStorage.removeItem('error');
    msalInstance.loginRedirect(PASSWORD_RESET_REQUEST);
  };
};

export default AuthRedirect;
