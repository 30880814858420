const days = {
  1: 'Monday',
  2: 'Tuesday',
  3: 'Wednesday',
  4: 'Thursday',
  5: 'Friday',
  6: 'Saturday',
  7: 'Sunday',
};

const weekdays = ['1', '2', '3', '4', '5'];
const weekends = ['6', '7'];
const any = [...weekdays, ...weekends];

const strToArray = str => str.split(',');

const arraysMatched = (base, comparison) =>
  JSON.stringify(base) === JSON.stringify(comparison);

export default daysString => {
  const daysArray = strToArray(daysString);

  if (daysArray.length === 1) {
    return days[daysString];
  }
  if (arraysMatched(daysArray, weekdays)) {
    return 'Weekdays';
  }
  if (arraysMatched(daysArray, weekends)) {
    return 'Weekends';
  }
  if (arraysMatched(daysArray, any)) {
    return 'Any';
  }
  if (daysArray.length < 7) {
    return `${days[daysArray[0]]} +${daysArray.length - 1}`;
  }
};
