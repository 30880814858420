/** @jsxImportSource @emotion/react */
import { forwardRef, Fragment } from 'react';

import { filtersPanelAnalytics } from '../../analyticsConsts';
import { SCROLL_UP } from '../../const';
import { useScrollDirectionContext } from '../../context/scrollDirection';
import { useSetHeaderHeightContext } from '../../context/setHeaderHeight';
import useStorageFilters from '../../hooks/useStorageFilters';
import { Stripes } from '../Icons';
import useStyles from './styles';

const { FILTERS_PANEL_CTA_OPEN } = filtersPanelAnalytics;

const FiltersToggle = forwardRef(
  ({ isToggled, onClick }, filtersToggleBtnRef) => {
    const styles = useStyles();
    const { currentFiltersParsed } = useStorageFilters();
    const currentFiltersKeys = Object.keys(currentFiltersParsed);
    const currentFiltersLength = currentFiltersKeys.length;
    const { headerHeight } = useSetHeaderHeightContext();
    const { scrolldirectionContext } = useScrollDirectionContext();

    const styleHeader = {
      active: {
        marginTop: headerHeight,
      },
      hidden: {
        marginTop: '0',
      },
    };

    return (
      <div
        id="filter-toggle"
        css={styles.toggle}
        style={
          scrolldirectionContext === SCROLL_UP
            ? styleHeader.hidden
            : styleHeader.active
        }
      >
        <div css={styles.inner}>
          <button
            id={FILTERS_PANEL_CTA_OPEN}
            css={styles.toggleBtn}
            onClick={onClick}
            aria-expanded={isToggled}
            aria-controls="filter-panel"
            ref={filtersToggleBtnRef}
          >
            <Stripes /> <span>Filters</span>
            <span css={styles.counter} aria-hidden="true">
              {currentFiltersLength}
            </span>
          </button>
          {currentFiltersLength > 0 && (
            <div css={styles.valuesWrapper}>
              {currentFiltersKeys.map((key, index) => (
                <Fragment>
                  <div css={styles.value} key={index}>
                    {currentFiltersParsed[key]}
                  </div>
                </Fragment>
              ))}
            </div>
          )}
        </div>
      </div>
    );
  }
);

export default FiltersToggle;
