import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, colors, screenSizes, borderRadius } = useTheme();

  return {
    cards: css`
      position: relative;
      text-decoration: none;
      > div {
        border-bottom: 1px solid ${colors.white};
      }

      &:last-of-type > div {
        border: none;
      }

      ${breakpoints.l} {
        > div {
          border: none;
        }

        &:nth-of-type(1) > div,
        &:nth-of-type(3) > div {
          border-bottom: 1px solid ${colors.white};
        }

        :nth-of-type(2) {
          margin-top: -8rem;
        }

        :nth-of-type(3) {
          margin-top: 8rem;
        }

        &:hover,
        &:focus {
          outline: 0;
          p:nth-of-type(1){
            text-decoration: underline;
            display: inline-block;
            text-decoration-color:${colors.lightBlue};
          }
          &::after {
            content: '';
            position: absolute;
            top: 0;
            z-index: 1;
            border-radius: 8px;
            transition: all 0.5s ease;
            background: linear-gradient(
              180deg,
              rgba(47, 46, 46, 0.64) 0%,
              rgba(0, 79, 158, 0.64) 100%
            );

            ${breakpoints.l} {
              width: 17.5rem;
              height: 12.5rem;
            }
          }

          a,
          span,
          svg
         {
            color: ${colors.darkGrey};
          }
          p::after {
            background: ${colors.darkGrey};
          }
          p:nth-of-type(1){
            &:after{
            opacity 1;
            transform: translate3d(-100%, 0, 0);
        
           } 
          }
          svg {
            transition: 0.6s ease-in;
            transform: translateX(50%);
          }
        }
      }
    `,
    innerWrapper: css`
      padding-bottom: 1.5rem;
    `,
    card: css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      grid-auto-rows: 8.3rem;
      align-items: center;

      ${breakpoints.l} {
        grid-auto-rows: 12.5rem;
        max-width: 17.5rem;
        grid-template-columns: 100% 85%;
      }
    `,
    header: css`
      color: ${colors.navy};
      font-family: 'FS Dillon Bold';
      font-style: italic;
      text-transform: capitalize;
      font-size: 28px;
      line-height: 26px;
      position: relative;
      display: inline;

      ${breakpoints.l} {
        font-size: 42px;
        line-height: 40px;
        font-weight: 700;
      }
    `,
    description: css`
      display: grid;
      @media (min-width: ${screenSizes.l}) and (max-width: ${screenSizes.xl}) {
        grid-template-columns: 85% 10%;
      }
      color: ${colors.darkGrey};
      padding: 0;
      letter-spacing: 0.02rem;
      font-size: 14px;
      line-height: 18px;
      ${breakpoints.l} {
        padding: 4px 18px 12px 0;
        font-size: 16px;
        line-height: 22px;
        margin-bottom: 10px;
        &::after {
          content: '';
          position: absolute;
          left: 0;
          bottom: 0;
          height: ${borderRadius.s};
          width: 3.75rem;
          background: ${colors.red};
        }
      }
      position: relative;
    `,
    infoSection: css`
      padding: 5px 10px;

      ${breakpoints.l} {
        padding: 10px 10px 10px 20px;
        min-width: 15.625rem;
      }
    `,
    learnMore: css`
      color: ${colors.blue};
      cursor: pointer;
      position: relative;
      text-decoration: none;
      text-transform: uppercase;
      padding: 5px 5px 5px 0;
      font-size: 14px;
      font-weight: normal;
      span {
        padding-right: 5px;
      }
      ${breakpoints.l} {
        font-size: 16px;
      }
    `,
    imgSection: ({ desktop, mobile }, indexVal) => css`
      border-radius: 8px;
      width: 100%;
      height: 100%;
      background: url(${mobile.src}) 0 0 no-repeat;
      background-size: cover;
      position: relative;
      border-radius: 8px;

      &::after {
        content: '';
        position: absolute;
        width: 100%;
        height: 100%;
        background: url(${`/images/desktop/home/crops/crop-${indexVal}.svg`});
        background-position: bottom right;
        background-repeat: no-repeat;
      }

      ${breakpoints.l} {
        background: url(${desktop.src}) 0 0 no-repeat;
        background-size: cover;
      }
    `,
    rightArrow: css`
      color: ${colors.red};
    `,
  };
};
