import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, colors, spacing, borderRadius } = useTheme();

  return {
    image: css`
      width: 100%;
      max-width: 100%;
      height: 100%;
      position: relative;
      margin: ${spacing.xs} 0;
      outline: 0;

      ${breakpoints.m} {
        margin: ${spacing.s} 0;
      }
    `,
    imgWrapper: css`
      position: relative;
      height: 100%;
      cursor: pointer;
      outline: 0;
      border: none;
      background-color: white;
      padding: 0;
      width: 100%;

      img {
        border: ${borderRadius.s} solid transparent;
      }

      &:focus {
        img {
          border: ${borderRadius.s} solid ${colors.navy};
        }
      }
    `,
    videoPlayIconWrapper: css`
      width: 100%;
      position: absolute;
      text-align: center;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
    `,
    videoWrapper: css`
      margin-top: ${spacing.s};
  }
  `,
  };
};
