import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { utility } = useTheme();

  return {
    hidden: css`
      ${utility.checkboxHidden}

      padding: 0 !important;
      margin: 0 !important;
    `,
  };
};
