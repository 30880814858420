import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { colors,borderRadius, spacing, utility } = useTheme();

  return {
    options: css`
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-gap: ${spacing.xs};
      padding: ${spacing.s} 0;
    `,
    label: css`
      & > input[type='checkbox']:focus + span {
        border: borderRadius solid ${colors.navy} ;
        border-radius: ${borderRadius.m};
      }
    `,
    option: isChecked => css`
      display: block;
      cursor: pointer;
      font-size: 0.875rem;
      font-weight: 400;
      background-color: ${colors.white};
      color: ${colors.navy};
      border-radius: ${borderRadius.s};
      padding: 0.85rem ${spacing.s};
      transition: background-color 0.3s ease-in;
      border: 1px solid ${colors.blue};
      text-align: center;

      ${isChecked &&
      css`
        color: ${colors.white};
        background-color: ${colors.darkGrey};
      `}

      input[type='checkbox']:focus & {
        border: ${borderRadius.s} solid ${colors.navy} ;
        border-radius: ${borderRadius.m};
      }
    `,
    icon: css`
      margin-right: 0.3125rem;
    `,
    checkbox: css`
      ${utility.checkboxHidden};
    `,
    filterOptionWrapper: css`
      overflow: auto;
      -webkit-overflow-scrolling: auto;
      padding-right: ${spacing.xs};
      height: Calc(100vh - 63vh);
    `,
  };
};
