/** @jsxImportSource @emotion/react */

import useStyles from './styles';

// This component should be used along the Tabs component,
// it uses the same pattern for `id` and `aria-labelledby`
const TabPanel = ({ selectedType, id, children }) => {
  const styles = useStyles();

  const isSelected = selectedType === id;

  return (
    <div
      role="tabpanel"
      id={`tabpanel-${id}`}
      aria-labelledby={`tab-${id}`}
      tabIndex={isSelected ? 0 : -1}
      css={styles.tabPanel(isSelected)}
    >
      {children}
    </div>
  );
};

export default TabPanel;
