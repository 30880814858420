/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Fragment } from 'react';

import { EF_LOGO_HREF, EMPTY_STRING } from '../../const';
import ExternalLink from '../ExternalLink';
import useStyles from './styles';

const MenuCard = ({
  title,
  childrens,
  id,
  childIndex,
  keysForChildren,
  subTitleClicked,
  isMobile,
  url,
}) => {
  const styles = useStyles();
  const isExternal = /^https?:\/\//i.test(url);
  const href = isExternal ? url : `${EF_LOGO_HREF}${url}`;

  return (
    <Fragment>
      {childrens && (
        <li css={styles.children(keysForChildren[id])}>
          <ExternalLink
            css={[styles.subTitle, styles.line]}
            href={href}
            target={isExternal ? '_blank' : EMPTY_STRING}
          >
            <span>{title}</span>
          </ExternalLink>
          {childrens && childrens.length > 0 && isMobile && (
            <button
              css={[styles.dropdownBtn, styles.collapseBtn]}
              onClick={() => isMobile && subTitleClicked(childIndex)}
              id={id}
            >
              <div id={id} css={styles.collapseIcon(keysForChildren[id])}>
                <span></span>
                <span></span>
              </div>
            </button>
          )}
          {keysForChildren[id] && (
            <ul
              role="region"
              aria-labelledby={`accordion-button-${childIndex}`}
              css={styles.subChild(keysForChildren[id])}
            >
              {childrens &&
                childrens.map((childMenu, childrenIndex) => {
                  const isExternal = /^https?:\/\//i.test(childMenu.url);
                  const href = isExternal
                    ? childMenu.url
                    : `${EF_LOGO_HREF}${childMenu.url}`;
                  return (
                    <li key={childrenIndex} css={styles.nestedLink}>
                      <ExternalLink
                        css={[styles.link, styles.line]}
                        href={href}
                        target={isExternal ? '_blank' : EMPTY_STRING}
                      >
                        <span>{childMenu.title}</span>
                      </ExternalLink>
                    </li>
                  );
                })}
            </ul>
          )}
        </li>
      )}
    </Fragment>
  );
};

MenuCard.prototype = {
  title: PropTypes.string,
  childrens: PropTypes.object,
  id: PropTypes.string,
  childIndex: PropTypes.string,
  keysForChildren: PropTypes.object,
  subTitleClicked: PropTypes.func,
  isMobile: PropTypes.bool,
  url: PropTypes.string,
};

export default MenuCard;
