import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, colors, borderRadius, spacing } = useTheme();

  return {
    card: css`
      height: 100%;
      position: relative;
      &:nth-of-type(1) {
        margin-top: -4rem;
      }
      display: grid;
      text-decoration: none;
      grid-template-rows: 13.875rem 1fr;
      max-width: 22.6875rem;
      grid-auto-flow: row;
      z-index: 2;

      ${breakpoints.m} {
        max-width: 100%;
        grid-template-rows: 100%;
        &:nth-of-type(odd) {
          @media screen and (min-width: 771px) {
            margin-top: -16rem;
          }
          margin-top: -12rem;
        }
        margin-top: -8rem;
      }

      &:hover,
      &:focus {
        outline: 0;
        &::after {
          content: '';
          position: absolute;
          transition: all 0.1s ease;
          background: linear-gradient(
            180deg,
            rgba(47, 46, 46, 0.64) 0%,
            rgba(0, 79, 158, 0.64) 100%
          );
          width: 100%;
          height: 13.875rem;

          ${breakpoints.l} {
            height: 100%;
          }
          top: 0;
        }
        label,
        span,
        a,
        svg {
          color: ${colors.white};
        }

        p::after {
          background: ${colors.darkGrey};
        }

        svg {
          transition: 0.6s ease-in;
          transform: translateX(50%);
        }

        div {
          border-top: ${borderRadius.s} solid ${colors.darkGrey};
        }
        ${breakpoints.m} {
          p:nth-of-type(1) {
            &:after {
              border-bottom: 10px solid ${colors.darkBlue};
              content: '';
              position: absolute;
              left: 0;
              bottom: -2px;
              width: 200px;
              display: inline-block;
              z-index: -1;
            }
          }
        }
      }
    `,
    header: css`
      font-family: 'FS Dillon';
      font-style: italic;
      text-transform: capitalize;
      font-size: 42px;
      line-height: 40px;
      position: relative;
      display: inline-block;
      color: ${colors.white};

      &:after {
        width: 0%;
        animation: increase 2s;
        animation-fill-mode: forwards;
      }

      @keyframes increase {
        100% {
          width: 100%;
        }
      }

      ${breakpoints.l} {
        font-size: 64px;
        line-height: 48px;
        font-weight: 700;
      }
    `,
    description: css`
      color: ${colors.lightBlue};
      padding: 4px 18px 12px 7px;
      letter-spacing: 0.02rem;
      line-height: 22px;
      position: relative;
      margin-bottom: 10px;
      display: grid;
      grid-template-columns: 95%;
      font-weight: 400;
      &::after {
        content: '';
        position: absolute;
        left: 5px;
        bottom: 0;
        height: ${borderRadius.s};
        width: 60px;
        background: ${colors.red};
      }
    `,
    infoSection: css`
      border-top: ${borderRadius.s} solid ${colors.red};
      width: 100%;
      cursor: pointer;
      background-color: ${colors.navy};
      padding: 8px 0;

      ${breakpoints.l} {
        left: ${spacing.xl};
        padding: 26px 0 0 18px;
        position: absolute;
        z-index: 2;
        bottom: -7rem;
        width: Calc(100% - 4rem);
      }

      ${breakpoints.xl} {
        padding: 35px 20px 0 30px;
        background-color: ${colors.navy};
      }
    `,
    learnMore: css`
      color: ${colors.white};
      cursor: pointer;
      position: relative;
      text-transform: uppercase;
      padding: 5px;
      font-size: 14px;
      font-weight: normal;
      span {
        padding-right: 5px;
      }

      ${breakpoints.l} {
        font-size: 0.875rem;
      }
    `,
    rightArrow: css`
      color: ${colors.red};
    `,
    imgSection: ({ desktop, mobile }) => css`
      width: 100%;
      height: 100%;
      background: url(${mobile.src}) 0 0 no-repeat;
      background-size: cover;
      position: relative;

      ${breakpoints.l} {
        background: url(${desktop.src}) 0 0 no-repeat;
        background-size: cover;
      }
    `,
  };
};
