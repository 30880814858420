import { EMPTY_STRING, MAX_TEXT } from '../../const';

export default (price, { max = 0 } = {}) => {
  if (!/\d/.test(price)) return EMPTY_STRING;

  let priceValue = price;

  let suffix = EMPTY_STRING;

  // Eg.: if max is 5 we want to show 5+

  if (priceValue === 0) {
    return 'Free';
  } else {
    return `£${priceValue} ${
      priceValue === max ? `${suffix}+` : suffix
    } ${MAX_TEXT}`;
  }
};
