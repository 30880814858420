export const setLocalItem = (item, value) =>
  sessionStorage.setItem(item, value);

export const getLocalItem = item => sessionStorage.getItem(item);

export const removeLocalItem = item => sessionStorage.removeItem(item);

export default () => ({
  setLocalItem,
  getLocalItem,
  removeLocalItem,
});
