import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, colors, spacing, typography, borderRadius } = useTheme();

  return {
    base: css`
      background-color: ${colors.white};
      box-shadow: 0px 4px 6px rgba(91, 104, 133, 0.1);
      border-radius: 8px;
      position: relative;
      display: grid;
      align-items: center;
      justify-items: center;
      width: 100%;
      padding: ${spacing.m} ${spacing.s};
      grid-auto-columns: 1fr;
      text-align: center;
      margin-bottom: 2rem;

      ${breakpoints.m} {
        min-height: 216px;
        max-height: 256px;
        padding: 0;
      }
    `,
    header: css`
      font-size: 1.75rem;
      line-height: 1.625rem;
      padding: 0 0 ${spacing.s} 0;
      width: 80%;
      margin: 0 auto;

      ${breakpoints.m} {
        ${typography.headings[5].desktop};
        width: 100%;
        padding: 25px 25px 15px 25px;
        line-height: 2.25rem;
      }

      ${breakpoints.xl} {
        padding: 0 0 ${spacing.s} 0;
      }

      color: ${colors.navy};
      position: relative;
      margin-bottom: ${spacing.s};
      text-align: center;
      &::before {
        content: '';
        position: absolute;
        right: 0;
        bottom: 0;
        height: ${borderRadius.s};
        width: 100px;
        background: ${colors.red};
        left: 50%;
        transform: translate(-50%, -50%);
      }
    `,
    description: css`
      width: 90%;
      margin: 0 auto;
      p {
        color: ${colors.darkGrey};
        line-height: 1.3rem;
        padding: 0 ${spacing.s};

        ${breakpoints.m} {
          line-height: 1.375rem;
        }
      }

      ${breakpoints.m} {
        width: 100%;
      }
    `,
    newSearch: css`
      color: ${colors.darkGrey};
      display: inline-block;
      font-weight: bold;
      margin-left: 3px;
      text-decoration: none;
      border-bottom: 2px solid ${colors.darkGrey};
      display: inline-block;
      padding: 0;
    `,
    cta: css`
      background-color: ${colors.red};
      color: ${colors.white};
      text-transform: uppercase;
      max-width: 17.625rem;
      margin: 0 auto;
      margin-top: ${spacing.s};
      text-transform: uppercase;
      line-height: 1.75rem;

      ${breakpoints.l} {
        width: 17.625rem;
      }
    `,
  };
};
