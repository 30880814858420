/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import React, {
  Fragment,
  useEffect,
  useLayoutEffect,
  useRef,
  useState,
} from 'react';

import {
  BACK_TO_RESULTS,
  clubDetailsPageAnalytics,
} from '../../analyticsConsts';
import {
  CLUB,
  CLUB_TAB,
  EMPTY_STRING,
  PREVIOUSPAGE,
  SELECTED_RECOMMENDATIONS_TYPE_ID,
} from '../../const';
import { useMLRecommendations } from '../../context/mlRecommendations';
import { useOnboardingDataContext } from '../../context/OnboardingData';
import { usePageHistory } from '../../context/pageHistory';
import { usePageInfo } from '../../context/pageInfo';
import { usePreferencesContext } from '../../context/preferences';
import { useSetContactFormDLContext } from '../../context/setContactFormDL';
import useElementHeight from '../../hooks/useElementHeight';
import useLocalStorage from '../../hooks/useLocalStorage';
import ClubDetails from '../ClubDetails';
import useStylesClub from '../ClubPage/styles';
import ContactMap from '../ContactMap';
import HeroProvider from '../HeroProvider';
import { RightArrow } from '../Icons';
import Image from '../Image';
import Link from '../Link';
import Loader from '../Loader';
import SocialMedia from '../SocialMedia';
import StickyScrollSpyNav from '../StickyScrollSpyNav';
import Text from '../Text';
import VenueClubList from '../VenueClubList';
import { getWeekdays } from '../VenuePage';
import { providerPreviousPageName } from '../VenuePage/conditionCheck';
import useStyles from './styles';

const { CLUB_NAME_HEADING, CLUB_TEAM_TITLE, CLUB_DETAILS_WRAPPER } =
  clubDetailsPageAnalytics;

export const getPreferenceValue = (
  pageInfo,
  preference,
  details,
  text,
  isClubInfo,
  gender
) => {
  const { title } = details;

  return {
    eventCategory: pageInfo?.title,
    eventparameter1: details?.clubname ? details?.clubname : title,
    eventparameter2: !isClubInfo ? title : EMPTY_STRING,
    eventparameter3: text,
    eventparameter4: preference?.Age ? preference?.Age : EMPTY_STRING,
    eventparameter5: gender ? gender : EMPTY_STRING,
    item_list_name: text,
  };
};

const ClubDetailsPage = ({
  onTeamCardClick,
  clubDetails,
  providerPageData,
  isTeamDetailsLoading,
  filteredTeams,
  unfilteredTeams,
  isFiltersApplied,
  setIsFilters,
  accreditation,
  contactDetails,
  isDesktop,
  socialLinks,
  onClick,
  sourceLocation,
}) => {
  const { preferences } = usePreferencesContext();
  const { OnboardingContext } = useOnboardingDataContext();
  const {
    [SELECTED_RECOMMENDATIONS_TYPE_ID]: selectedRecommendationType,
    Age,
    WeekDays,
    Category,
  } = preferences || {};
  const loadingRef = useRef();
  const listRef = useRef();
  const [backUrl, setBackUrl] = useState();
  const [pageInfo, setPageInfo] = usePageInfo();
  const { height: titleHeight, elementRef: titleElement } = useElementHeight();
  const { isMLCard } = useMLRecommendations();
  const styles = useStyles();
  const stylesClub = useStylesClub();
  const { getLocalItem } = useLocalStorage();
  const previousUrl = getLocalItem(PREVIOUSPAGE);
  const { previousPage } = usePageHistory();
  const { setContactFormDLValue } = useSetContactFormDLContext();

  useEffect(() => {
    if (!previousPage && !previousUrl) {
      return;
    }
    setBackUrl(
      previousPage
        ? previousPage
        : previousUrl
        ? previousUrl
        : '/recommendation'
    );
  }, [previousPage, previousUrl]);

  const previousPageName = providerPreviousPageName(previousUrl);
  // set page info only when we have the club name
  useEffect(() => {
    if (!clubDetails?.title) {
      return;
    }

    setPageInfo('club', clubDetails.title);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [clubDetails]);

  // set page info only when we have the club name
  useEffect(() => {
    if (!clubDetails || !pageInfo) {
      return;
    }
    const details = clubDetails;
    setContactFormDLValue(
      getPreferenceValue(
        pageInfo,
        preferences,
        details,
        'club info',
        true,
        OnboardingContext.gender_group
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pageInfo, clubDetails]);

  // if we are loading and we already have the venue details and
  // at least one type of session then we are fetching
  // the other type of sessions
  const isRefreshingTeams =
    isTeamDetailsLoading && clubDetails && (filteredTeams || unfilteredTeams);

  // we are using useLayoutEffect here because we cannot rely on
  // setIsRefreshingDetails having run before we shift focus above
  // due to React async setting of state
  useLayoutEffect(() => {
    // if we're refreshing then shift focus to the loading message
    if (isRefreshingTeams) {
      loadingRef.current?.focus({ preventScroll: true });
      return;
    }
    // filtersApplied has changed
    // if both filtered and unfiltered data have been loaded
    // we can shift the focus to the list when the button is clicked
    if (filteredTeams && unfilteredTeams) {
      setTimeout(() => {
        listRef.current?.focus({ preventScroll: true });
      }, 300);
    }
  }, [filteredTeams, isFiltersApplied, isRefreshingTeams, unfilteredTeams]);

  // if there is no selected recommendations type or
  // the filters are removed show different title
  const title =
    selectedRecommendationType && isFiltersApplied
      ? JSON.parse(sessionStorage.getItem('ISVOLUNTEER')) === true
        ? `${selectedRecommendationType} Teams`
        : `${Age} year old ${selectedRecommendationType} Teams`
      : 'All teams at this club';

  // if there is no selected recommendations type or
  // the filters are removed don't show subtitle
  const subtitle =
    selectedRecommendationType && isFiltersApplied
      ? `${
          clubDetails?.totalteams
        } Teams that match my search playing on ${getWeekdays(WeekDays)}`
      : `${unfilteredTeams?.totalTeams} Teams at this club`;

  const clubTeams = isFiltersApplied ? filteredTeams : unfilteredTeams?.teams;

  return (
    <div css={styles.base(isDesktop)}>
      <section>
        {providerPageData && (
          <HeroProvider
            title={clubDetails?.title}
            address={clubDetails?.address}
            distance={clubDetails?.distance}
            latitude={clubDetails?.latitude}
            longitude={clubDetails?.longitude}
            county={clubDetails?.county}
            accreditation={accreditation}
            css={stylesClub.hero}
            type={'club'}
            textSize={3}
            ref={titleElement}
            titleHeight={titleHeight}
            hasPreferences={!!selectedRecommendationType}
            analyticsId={CLUB_NAME_HEADING}
            previousPageData={{ name: previousPageName, backUrl: backUrl }}
            backupUrl={backUrl}
            sourceLoction={sourceLocation}
            Category={Category}
            {...providerPageData.hero}
          />
        )}
      </section>
      <section
        id={CLUB_DETAILS_WRAPPER}
        css={styles.wrapper(titleHeight, !!selectedRecommendationType)}
      >
        <div css={stylesClub.card}>
          {isTeamDetailsLoading && (
            <Loader css={stylesClub.loader} message="Loading club Details" />
          )}
          {!isTeamDetailsLoading && (
            <Fragment>
              <StickyScrollSpyNav
                nav={CLUB_TAB}
                titleHeight={titleHeight}
                title={clubDetails?.title}
              >
                <section ref={React.createRef()} css={stylesClub.navContent}>
                  {(!!selectedRecommendationType || Category) && isDesktop && (
                    <Link
                      id={BACK_TO_RESULTS}
                      to={backUrl}
                      css={stylesClub.backLink}
                    >
                      <RightArrow css={stylesClub.arrowRotate} />{' '}
                      <span>Back to {previousPageName} view</span>
                    </Link>
                  )}
                  <div css={styles.score}>
                    <Image
                      css={styles.image}
                      src={`/images/common/respect.svg`}
                      alt=""
                    />
                    <Text as="h2" css={styles.title}>
                      Score |<span> 96%</span>
                    </Text>
                  </div>
                  {clubDetails && (
                    <Fragment>
                      <ClubDetails
                        {...clubDetails}
                        contact={contactDetails}
                        accreditation={accreditation}
                        county={clubDetails?.county}
                        onClick={onClick}
                      />
                    </Fragment>
                  )}
                </section>
                <section ref={React.createRef()} css={stylesClub.navContent}>
                  {isRefreshingTeams && (
                    <Loader
                      message={
                        isFiltersApplied
                          ? 'Reapplying filters'
                          : 'Removing filters'
                      }
                      ref={loadingRef}
                    />
                  )}
                  {clubTeams && (
                    <VenueClubList
                      title={title}
                      isClubInfo={true}
                      titleAnalyticsId={CLUB_TEAM_TITLE}
                      subtitle={subtitle}
                      data={clubTeams}
                      type={CLUB}
                      canToggle={selectedRecommendationType && !isMLCard}
                      isFiltered={isFiltersApplied}
                      toggleFiltered={() => setIsFilters(!isFiltersApplied)}
                      ref={listRef}
                      cardAction={onTeamCardClick}
                      clubname={clubDetails?.title}
                    />
                  )}
                </section>
                <section ref={React.createRef()} css={stylesClub.navContent}>
                  {clubDetails && (
                    <ContactMap
                      {...clubDetails}
                      titleHeader="Club Contact"
                      subTitle="For information about a specific team, please get in touch with the club contact."
                      locationDetails={{
                        latitude: clubDetails.latitude,
                        longitude: clubDetails.longitude,
                        title: 'New Club',
                      }}
                      contact={contactDetails}
                      onClick={onClick}
                      sourceLoction={sourceLocation}
                    />
                  )}
                </section>
              </StickyScrollSpyNav>
            </Fragment>
          )}
          {socialLinks && (
            <SocialMedia
              title={clubDetails?.title}
              socialLinks={socialLinks}
            ></SocialMedia>
          )}
        </div>
      </section>
    </div>
  );
};

ClubDetailsPage.prototype = {
  onTeamCardClick: PropTypes.func,
  clubDetails: PropTypes.object,
  providerPageData: PropTypes.object,
  isTeamDetailsLoading: PropTypes.bool,
  filteredTeams: PropTypes.object,
  unfilteredTeams: PropTypes.object,
  isFiltersApplied: PropTypes.bool,
  setIsFilters: PropTypes.func,
  contactDetails: PropTypes.object,
  isDesktop: PropTypes.bool,
  socialLinks: PropTypes.object,
  onClick: PropTypes.func,
  sourceLocation: PropTypes.object,
};

export default ClubDetailsPage;
