import { useEffect, useMemo, useState } from 'react';

export default query => {
  const mediaQuery = useMemo(() => window.matchMedia(query), [query]);
  const [state, setState] = useState(mediaQuery.matches);

  useEffect(() => {
    const handleChange = ({ matches }) => {
      setState(matches);
    };

    // Safari does not support newer syntax
    // `mediaQuery.addEventListener('change', handleChange)`
    mediaQuery.addListener(handleChange);

    return () => {
      // Safari does not support newer syntax
      // `mediaQuery.removeEventListener('change', handleChange)`
      mediaQuery.removeListener(handleChange);
    };
  }, [mediaQuery]);

  return state;
};
