import { useState } from 'react';

export const LOADING_STATE = 'loading';

export default () => {
  const [geolocationData, setGeolocationData] = useState();

  const getGeolocationData = () => {
    setGeolocationData({
      status: LOADING_STATE,
      statusMessage: 'Getting current location',
    });

    navigator.geolocation.getCurrentPosition(
      position => {
        const {
          coords: { latitude, longitude },
        } = position;

        if (latitude && longitude) {
          setGeolocationData({
            coordinates: { latitude, longitude },
            status: 'success',
            statusMessage: 'Current location saved successfully',
          });
        }
      },
      error => {
        if (error.code === error.PERMISSION_DENIED) {
          setGeolocationData({
            status: 'denied',
            statusMessage: 'Current location denied. Try entering a postcode',
          });
        }
      }
    );
  };

  return {
    geolocationData,
    getGeolocationData,
  };
};
