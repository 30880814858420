export default (savedPreferences, action) => {
  switch (action.type) {
    case 'save':
      return { ...savedPreferences, ...action.payload };
    case 'remove': {
      const {
        [action.readableId]: removed,
        ...filteredPreferences
      } = savedPreferences;
      return filteredPreferences;
    }
    default:
      return savedPreferences;
  }
};
