/** @jsxImportSource @emotion/react */
import isequal from 'lodash.isequal';
import omit from 'lodash.omit';
import PropTypes from 'prop-types';
import { Fragment, useEffect, useLayoutEffect, useRef, useState } from 'react';

import { filtersPanelAnalytics } from '../../analyticsConsts';
import {
  CLUB,
  DONT_MIND,
  KICKABOUT_CASUAL,
  LOCATION_FILTER,
  MAP_PAGE,
  MULTI_FILTER,
  SELECTED_RECOMMENDATIONS_TYPE_ID,
  SESSION_DURATION,
  SLIDER_FILTER,
} from '../../const';
import { useMORecommendations } from '../../context/moRecommendations';
import { useNestedFilterPanelContext } from '../../context/nestedFilterPanel';
import { usePreferencesContext } from '../../context/preferences';
import { useRecommendations } from '../../context/recommendations';
import { useScrollDirectionContext } from '../../context/scrollDirection';
import { useSetHeaderHeightContext } from '../../context/setHeaderHeight';
import useErrorBoundary from '../../hooks/useErrorBoundary';
import useMediaQuery from '../../hooks/useMediaQuery';
import useOnboardingURL from '../../hooks/useOnboardingURL';
import useStorageFilters from '../../hooks/useStorageFilters';
import {
  getMiniOnboardingRecommendations,
  getRecommendations,
} from '../../services';
import objectsDiff from '../../utils/objectsDiff';
import Button from '../Button';
import FilterListItem from '../FilterListItem';
import FilterLocation from '../FilterLocation';
import FilterMultiple from '../FilterMultiple';
import FilterSessionDuration from '../FilterSessionDuration';
import FilterUnsavedPanel from '../FilterUnsavedPanel';
import { CircleArrow, CircleX } from '../Icons';
import Link from '../Link';
import Loader from '../Loader';
import MixedFilterItem from '../MixedFilterItem';
import Text from '../Text';
import filtersConfig from './filtersConfig';
import useStyles from './styles';

const {
  FILTERS_PANEL_CTA_CLOSE,
  FILTERS_PANEL_CTA_BACK_TO,
  FILTERS_PANEL_CTA_APPLY,
  FILTERS_PANEL_CTA_NEW_SEARCH,
  FILTERS_PANEL_FILTER_TYPES,
  FILTERS_PANEL_NESTED_FILTER,
  FILTERS_PANEL_CTA_OPEN,
} = filtersPanelAnalytics;

const DELAY = 600;

const filtersMapping = {
  [MULTI_FILTER]: FilterMultiple,
  [LOCATION_FILTER]: FilterLocation,
  [SLIDER_FILTER]: FilterSessionDuration,
};

const FiltersPanel = ({ isToggled, onClose, page }) => {
  const styles = useStyles();
  const throwError = useErrorBoundary();
  const {
    updateFiltersStorage,
    updateFiltersCasualStorage,
    currentFiltersCasualParsed,
  } = useStorageFilters();
  const { selectedFilterContext, setSelectedFilters, isNested, setNested } =
    useNestedFilterPanelContext();

  const [isLoading, setIsLoading] = useState(false);
  const [isExiting, setIsExiting] = useState(false);
  const { preferences, setPreferences } = usePreferencesContext();
  const [unsavedPreferences, setUnsavedPreferences] = useState(preferences);
  const { setRecommendations, resetRecommendations } = useRecommendations();
  const [filteredRecommendations, setFilteredRecommendations] = useState();
  const filtersRef = useRef(null);
  const nestedRef = useRef(null);
  const loadingRef = useRef(null);
  const scrollDiv = useRef(null);
  const { scrolldirectionContext } = useScrollDirectionContext();
  const getOnboardingURL = useOnboardingURL();
  const { headerHeight } = useSetHeaderHeightContext();
  const { setMORecommendations } = useMORecommendations();
  const isMiniOnboarding =
    sessionStorage.getItem('ISMINIONBOARDING') === 'true';

  const { SelectedFootballType, FootballType, PlayWith } = preferences;
  const filtersData = filtersConfig[SelectedFootballType];
  const unsavedChanges = !isequal(
    omit(preferences, ['SelectedRecommendationsType']),
    omit(unsavedPreferences, ['SelectedRecommendationsType'])
  );
  const isLargeDesktop = useMediaQuery('screen and (min-width: 1680px)');
  const isMenuScrollable = SelectedFootballType !== CLUB && !isLargeDesktop;

  const Filter =
    selectedFilterContext && filtersMapping[selectedFilterContext.type];

  const noResults =
    filteredRecommendations && filteredRecommendations.length === 0;

  useEffect(() => {
    // we need to cancel the timeout if we unmount before execution
    let timeout;

    if (isNested) {
      // Delay for CSS transition to finish
      // before focusing nested panel
      timeout = setTimeout(() => {
        nestedRef.current.focus();
      }, DELAY);
    } else {
      setFilteredRecommendations();
      // Delay for CSS transition to finish
      // before removing filter from panel
      timeout = setTimeout(() => {
        setSelectedFilters();
        filtersRef.current?.focus();
      }, DELAY);
    }

    return () => {
      clearTimeout(timeout);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isNested]);

  useLayoutEffect(() => {
    if (isToggled) {
      filtersRef.current?.focus();
    }
  }, [isToggled]);

  useLayoutEffect(() => {
    if (isLoading) {
      loadingRef.current?.focus();
    }
  }, [isLoading]);

  // this runs when the user changes the selected football type
  useEffect(() => {
    let preferencesTmp = preferences;

    // If we changed to "Club", remove "Casual" preferences
    // before sending with the request
    if (SelectedFootballType === CLUB) {
      const { [SESSION_DURATION]: SessionDuration, ...restPreferences } =
        preferencesTmp;

      // update the Casual filters temporary storage
      updateFiltersCasualStorage({
        SessionDuration,
      });

      preferencesTmp = restPreferences;
    } else {
      // Merge "Casual" preferences back to main object
      preferencesTmp = { ...preferencesTmp, ...currentFiltersCasualParsed };
      // Reset temp casual preferences local
      updateFiltersCasualStorage();
    }

    setPreferences(preferencesTmp);
    setUnsavedPreferences(preferencesTmp);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [SelectedFootballType, PlayWith]);

  const onPanelBack = () => setNested(false);

  const onPanelClose = () => {
    if (unsavedChanges) {
      setIsExiting(true);
    } else {
      onClose();
    }
  };

  // Single filter save handler
  const onFilterSave = updatedPreference => {
    const unsavedPref = {
      ...preferences,
      ...unsavedPreferences,
      ...updatedPreference,
      [SELECTED_RECOMMENDATIONS_TYPE_ID]:
        preferences[SELECTED_RECOMMENDATIONS_TYPE_ID],
    };
    // Update state object with new filter value
    setUnsavedPreferences(unsavedPref);
    // Delay to show updated results
    // before transitioning back to main panel
    setTimeout(() => {
      onPanelBack();
    }, DELAY);
  };

  const onMOFilterSaveAll = () => {
    if (unsavedPreferences && unsavedPreferences['FootballType'] === 1) {
      unsavedPreferences.PageNumber = 1;
    }
    setIsLoading(true);

    let unsavedObj = unsavedPreferences;
    getMiniOnboardingRecommendations(SelectedFootballType, unsavedObj)
      .then(({ data }) => {
        if (data) {
          setFilteredRecommendations(data);
          if (data.length > 0) {
            setMORecommendations(data[0], SelectedFootballType);
            // Update preferences context
            setPreferences(unsavedObj);
            const diffPreferences = objectsDiff(unsavedObj, preferences);
            // Save applied filters in storage
            updateFiltersStorage(diffPreferences);
            // Close panel
            onClose();
          }
          setIsLoading(false);
        }
      })
      .catch(throwError);
  };

  // Main save handler (saves all changes at once)
  const onFilterSaveAll = () => {
    setIsLoading(true);

    let unsavedObj = unsavedPreferences;

    if (FootballType === DONT_MIND) {
      const unselectedType =
        SelectedFootballType === CLUB ? KICKABOUT_CASUAL : CLUB;

      // For the "I don't mind" case, we also need to
      // fetch data from the unselected tab
      Promise.all([
        getRecommendations(SelectedFootballType, unsavedObj),
      ])
        .then(data => {
          const recommendationRecords = data[0].data; // recommendation data
          // Update recommendations context
          setRecommendations(recommendationRecords, unselectedType);
          resetRecommendations();
        })
        .catch(throwError);
    }

    Promise.all([
      getRecommendations(SelectedFootballType, unsavedObj),
    ])
      .then(data => {
        if (data) {
          let recommendationRecords = data[0].data; // recommendation data
          const backupData = recommendationRecords;
          //filter data based on footballType for map page
          if (page === MAP_PAGE && recommendationRecords.length > 0) {
            recommendationRecords = recommendationRecords.filter(
              selectedItem => {
                return (
                  selectedItem['FootballType'] ===
                  preferences['SelectedRecommendationsType']
                );
              }
            );
          }

          setFilteredRecommendations(recommendationRecords);

          // Only update states if there's results
          if (recommendationRecords.length > 0) {

            // Update preferences context
            setPreferences(unsavedObj);

            // Update recommendations context
            setRecommendations(
              page === MAP_PAGE ? backupData : recommendationRecords,
              SelectedFootballType
            );

            const diffPreferences = objectsDiff(unsavedObj, preferences);

            // Save applied filters in storage
            updateFiltersStorage(diffPreferences);

            // Close panel
            onClose();
          }
          setIsLoading(false);
        }
      })
      .catch(throwError);
  };

  return (
    <div
      id="filter-panel"
      className="filterPanel"
      css={styles.panel(
        isToggled,
        isLargeDesktop,
        scrolldirectionContext,
        headerHeight
      )}
      aria-hidden={!isToggled}
      aria-labelledby={FILTERS_PANEL_CTA_OPEN}
    >
      <div css={styles.top}>
        <Text as="h2" size="5" css={styles.title}>
          Filters
        </Text>
        {!isExiting && (
          <Fragment>
            {selectedFilterContext && isNested ? (
              <button
                id={FILTERS_PANEL_CTA_BACK_TO}
                aria-label="Back"
                css={styles.closeBtn}
                onClick={() => {
                  onPanelBack();
                }}
              >
                <CircleArrow />
              </button>
            ) : (
              <button
                id={FILTERS_PANEL_CTA_CLOSE}
                aria-label="Close"
                css={styles.closeBtn}
                onClick={() => {
                  onPanelClose();
                  setFilteredRecommendations();
                  scrollDiv.current.scrollTo(0, 0);
                }}
                tabIndex={isToggled ? 0 : -1}
              >
                <CircleX />
              </button>
            )}
          </Fragment>
        )}
      </div>
      {isLoading ? (
        <Loader message="Saving changes" ref={loadingRef} />
      ) : (
        <div css={styles.filters(isNested)}>
          <div css={styles.inner(isNested)} id={FILTERS_PANEL_FILTER_TYPES}>
            {/* Main level */}
            <div tabIndex={-1} ref={filtersRef} css={styles.column}>
              {isExiting ? (
                <FilterUnsavedPanel
                  onExit={() => {
                    setIsExiting(false);
                    setUnsavedPreferences(preferences);
                    onClose();
                    // to reset no result section
                    setFilteredRecommendations();
                  }}
                  onSaveExit={() => {
                    setIsExiting(false);
                    isMiniOnboarding ? onMOFilterSaveAll() : onFilterSaveAll();
                  }}
                  onGoBack={() => setIsExiting(false)}
                />
              ) : (
                <Fragment>
                  <div
                    className="scroller"
                    ref={scrollDiv}
                    css={isMenuScrollable && styles.scrollFilterData}
                  >
                    {filtersData &&
                      filtersData.map(({ id, ...filter }) => {
                        const { preferenceKey, hasNestedFilter } = filter;
                        const unsavedPreferenceValue =
                          unsavedPreferences[preferenceKey];
                        return hasNestedFilter ? (
                          <FilterListItem
                            key={id}
                            currentValue={unsavedPreferenceValue}
                            {...filter}
                            onClick={() => {
                              setSelectedFilters(filter);
                              setNested(true);
                            }}
                            isOpen={isToggled}
                          />
                        ) : (
                          <MixedFilterItem
                            key={id}
                            currentValue={unsavedPreferenceValue}
                            {...filter}
                            onClick={value => {
                              setSelectedFilters(filter);
                              setNested(false);
                              onFilterSave({
                                [preferenceKey]: value,
                              });
                            }}
                            isOpen={isToggled}
                          />
                        );
                      })}
                  </div>
                  <div css={styles.columnFooter}>
                    {noResults && (
                      <p css={styles.noResults}>
                        No results match your filter criteria
                      </p>
                    )}
                  </div>
                  <div css={styles.btnWrapper}>
                    <Button
                      primary
                      id={FILTERS_PANEL_CTA_APPLY}
                      css={styles.saveBtn}
                      onClick={() => {
                        isMiniOnboarding
                          ? onMOFilterSaveAll()
                          : onFilterSaveAll();
                      }}
                      disabled={!unsavedChanges || isLoading}
                    >
                      {isLoading ? 'Applying filters...' : 'Apply filters'}
                    </Button>
                    <p css={styles.info}>
                      To modify your age, who you want to play with, or to
                      explore club football, you need to{' '}
                      <Link
                        id={FILTERS_PANEL_CTA_NEW_SEARCH}
                        css={styles.link}
                        to={getOnboardingURL}
                        tabIndex={isToggled ? 0 : -1}
                      >
                        start a new search
                      </Link>
                    </p>
                  </div>
                </Fragment>
              )}
            </div>
            {/* Nested filter */}
            {selectedFilterContext && isNested && (
              <div
                tabIndex={-1}
                ref={nestedRef}
                css={styles.column}
                id={FILTERS_PANEL_NESTED_FILTER}
              >
                <Filter
                  currentValue={
                    unsavedPreferences[selectedFilterContext.preferenceKey]
                  }
                  onSave={onFilterSave}
                  {...selectedFilterContext}
                />
              </div>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

FiltersPanel.prototype = {
  isToggled: PropTypes.bool,
  onClose: PropTypes.func,
  page: PropTypes.string,
};

export default FiltersPanel;
