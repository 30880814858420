/** @jsxImportSource @emotion/react */
import { Fragment, useEffect } from 'react';

import {
  AGE,
  BASIC_CTA,
  BOYS_OR_MEN,
  CASUAL,
  CLUBS,
  DISABILITY_TYPE_ID,
  EMPTY_STRING,
  GIRLS_OR_WOMEN,
  MEN,
  MIXED_VALUE,
  ONBOARDING_IMAGE_URL,
  PRIMARY_CTA,
  SECONDARY_CTA,
  WOMEN,
} from '../../const';
import { useOnboardingDataContext } from '../../context/OnboardingData';
import useOnboardingImage from '../../hooks/useOnboardingImage';
import getCategoryDataLayer from '../../utils/getCategoryDataLayer';
import getNextQuestionId from '../../utils/getNextQuestionId';
import publicPath from '../../utils/publicPath';
import Button from '../Button';
import ButtonAnswer from '../ButtonAnswer';
import HiddenContent from '../HiddenContent';
import LinkButton from '../LinkButton';
import useStyles from './styles';

const AnswerCtas = {
  [PRIMARY_CTA]: ButtonAnswer,
  [SECONDARY_CTA]: LinkButton,
  [BASIC_CTA]: Button,
};

const getSelectedQuestion = () => {
  switch (true) {
    case JSON.parse(sessionStorage.getItem('ISVOLUNTEER')):
      return '703663f0-3c99-4c4e-ac80-62184ad0546f';
    default:
      return;
  }
};

// based on gender and age load dynamic images
const GetDynamicImages = (answers, selectedAge, playWith) => {
  const url = ONBOARDING_IMAGE_URL;
  const selectedAgeValue = useOnboardingImage(selectedAge);
  const gender =
    playWith === GIRLS_OR_WOMEN
      ? WOMEN
      : playWith === BOYS_OR_MEN
      ? MEN
      : MIXED_VALUE;

  // casual images
  answers[0].image = publicPath(
    `${url}${
      gender === MIXED_VALUE ? AGE : CASUAL
    }/${gender}${selectedAgeValue}.png`
  );

  // club images
  answers[1].image = publicPath(
    `${url}${
      gender === MIXED_VALUE ? CLUBS : AGE
    }/${gender}${selectedAgeValue}.png`
  );
  return answers;
};

const SingleQuestion = ({
  readableId,
  answerTypeQuestion,
  answers,
  onAnswer,
  selectedAge,
  questionOrder,
  headerTitle,
  ...rest
}) => {
  const styles = useStyles();
  const data =
    questionOrder === 4
      ? GetDynamicImages(answers, selectedAge, rest.selectedGender)
      : answers;
  const describeId = `${readableId}-description`;
  const { OnboardingContext, setOnboardingDataContext } =
    useOnboardingDataContext();

  useEffect(() => {
    setOnboardingDataContext({
      ...OnboardingContext,
      question: headerTitle,
      step: 2,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [headerTitle]);

  return (
    <Fragment>
      {data && (
        // We need role none to have the buttons speak the legend on focus
        <ul role="none">
          {data.map(
            ({
              answerId,
              analyticsId,
              title,
              value,
              answerType,
              ageRanges,
              ...rest
            }) => {
              const Cta = AnswerCtas[answerType];
              const isPrimary =
                answerType === PRIMARY_CTA || answerType === BASIC_CTA;
              return (
                <li key={answerId} css={styles.answer(isPrimary)}>
                  <Cta
                    id={analyticsId}
                    onClick={() => {
                      const selectedQuestion = getSelectedQuestion();
                      onAnswer({
                        answerData:
                          JSON.parse(sessionStorage.getItem('ISVOLUNTEER')) ===
                          true
                            ? {
                                [readableId]: value || title,
                                [DISABILITY_TYPE_ID]:
                                  title === 'No' || title === 'Not sure'
                                    ? []
                                    : answerTypeQuestion,
                              }
                            : {
                                [readableId]: value || title,
                              },

                        nextQuestionId: getNextQuestionId({
                          ageRanges,
                          selectedAge,
                          selectedQuestion,
                        }),
                      });
                      setOnboardingDataContext({
                        ...OnboardingContext,
                        disability:
                          title === 'No'
                            ? EMPTY_STRING
                            : OnboardingContext.disability,
                      });
                      getCategoryDataLayer({
                        eventAction: `${title}`,
                        eventLabel: OnboardingContext.question,
                        eventparameter1: `${OnboardingContext.age}${
                          OnboardingContext.agegroup
                            ? ' , ' + OnboardingContext.agegroup
                            : ''
                        }`,
                        gender_group: OnboardingContext.gender_group,
                        link_text: `${title.toLowerCase()}`,
                        event_name: 'cta_select_option_click',
                      });
                    }}
                    css={styles.cta}
                    aria-describedby={describeId}
                    {...rest}
                  >
                    <span>{title}</span>
                  </Cta>
                </li>
              );
            }
          )}
        </ul>
      )}
      <HiddenContent id={describeId}>
        Click to select and go to the next question
      </HiddenContent>
    </Fragment>
  );
};

export default SingleQuestion;
