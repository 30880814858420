/** @jsxImportSource @emotion/react */
import { RightArrow } from '../Icons';
import useStyles from './styles';

const NavMenuFooter = ({stylingObjects }) => {
  const styles = useStyles();
  const stylingFooter = stylingObjects?.DestinationLink?.value;

  return (
    <a css={styles.footer} href={stylingFooter.href} id={stylingFooter.id}>
      <div
        dangerouslySetInnerHTML={{ __html: stylingFooter.text }}
      ></div>
      <button css={styles.arrowBtn}>
        <RightArrow css={styles.rightArrow} />
      </button>
    </a>
  );
};

export default NavMenuFooter;
