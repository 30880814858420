import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { utility } = useTheme();

  return {
    overlay: css`
      ${utility.overlay};
    `,
    loader: css`
      outline: 0;
    `,
  };
};
