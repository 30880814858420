import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, borderRadius, colors, spacing, typography, mediaQueries } = useTheme();

  return {
    base: css`
      background-color: ${colors.white};
      border-radius: ${borderRadius.l};
    `,
    tabs: css`
      display: grid;
      grid-template-columns: 1fr 1fr;
      border: ${borderRadius.s} solid ${colors.blue};
    `,
    tab: isSelected => css`
      ${typography.headings[6].mobile};
      color: ${colors.blue};
      padding: 0.8125rem;
      letter-spacing: 0.01rem;
      font-size: 0.875rem;
      font-weight: 400;
      text-transform: uppercase;
      border: ${borderRadius.s} solid transparent;
      outline: 0;

      &:focus-visible {
        border: ${borderRadius.s} solid ${colors.navy};
        background-color: ${colors.white};
        border-radius: 0;
      }

      ${mediaQueries.safariBrowser} { 
        @supports (-webkit-appearance:none) {
          &:focus {
            border: ${borderRadius.s} solid ${colors.navy};
            background-color: ${colors.white};
            border-radius: 0;
          }
        }
      }

      &:not(:disabled):hover {
        border-radius: 0;
      }

      ${isSelected &&
      css`
        color: ${colors.white};
        border-radius: 0;
        background-color: ${colors.blue};
        
        &:not(:hover):focus-visible {
          color: ${colors.navy};
          text-decoration: underline;
        }

        // for safari browser
        ${mediaQueries.safariBrowser} { 
          @supports (-webkit-appearance:none) {
            &:not(:hover):focus {
              color: ${colors.navy};
              text-decoration: underline;
            }
          }
        }
      `};

      ${breakpoints.xl} {
        padding: ${spacing.s};
        border: ${borderRadius.s} solid transparent;
      }
    `,
  };
};
