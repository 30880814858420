/** @jsxImportSource @emotion/react */
import { useCallback, useEffect, useState } from 'react';

import { EF_LOGO_HREF, EMPTY_STRING } from '../../const';
import ExternalLink from '../ExternalLink';
import { ArrowTriangle } from '../Icons';
import MenuCard from '../MenuCard';
import NavMenuFooter from '../NavMenuFooter';
import useStyles from './styles';

const MenuContent = ({
  handleMouseEnter,
  handleMouseLeave,
  childrens,
  title,
  id,
  stateKeys,
  url,
  isMobile,
  stylingObjects,
  headerHeight,
}) => {
  const styles = useStyles();
  const [keysForChildren, setKeysForChildren] = useState();
  const backgroundImg = stylingObjects?.BackgroundCrop?.value?.src;
  const stylingFooter = stylingObjects?.DestinationLink?.value;

  useEffect(() => {
    const keys = childrens.map(
      // creating values for existing id since value of id is null in API response
      (dropdown, index) => {
        const crypto = window.crypto;
        const randomNumber = Math.round(crypto.getRandomValues(new Uint8Array(10))[0] / 42)
        dropdown.id = `${index}_${randomNumber}`;
        return dropdown.id;
      }
    );
    const object = keys.reduce((acc, curr) => {
      // desktop - submenus should appear by default
      // mobile - submenus should be hidden initially
      acc[curr] = !isMobile;
      return acc;
    }, {});
    setKeysForChildren({ ...object });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const subTitleClicked = useCallback(
    selectedValue => {
      // set all the values of id as false before set value for selected id
      Object.keys(keysForChildren).forEach(data => {
        if (data !== selectedValue) {
          if (keysForChildren) {
            keysForChildren[data] = false;
          }
        }
      });
      setKeysForChildren({
        ...keysForChildren,
        [selectedValue]: keysForChildren
          ? !keysForChildren[selectedValue]
          : EMPTY_STRING,
      });
    },
    [keysForChildren]
  );
  const isExternal = /^https?:\/\//i.test(url);
  const href = isExternal ? url : `${EF_LOGO_HREF}${url}`;

  return (
    <li css={styles.dropdownContainer}>
      <div
        onMouseOver={!isMobile ? handleMouseEnter : undefined}
        onMouseLeave={!isMobile ? handleMouseLeave : undefined}
        css={styles.container(isMobile)}
      >
        <ExternalLink
          id={id}
          css={[
            styles.dropdown,
            stateKeys[id] ? styles.activeMenu : EMPTY_STRING,
          ]}
          href={href}
          target={isExternal ? '_blank' : EMPTY_STRING}
        >
          <span>{title}</span>
        </ExternalLink>
        {childrens &&
          childrens.length > 0 &&
          !isMobile &&
          childrens.slice(0, 5) && (
            <button
              css={styles.dropdownBtn}
              onClick={e => {
                handleMouseEnter(e);
              }}
              onKeyPress={handleMouseEnter}
              id={id}
              aria-label="click to open submenu"
            >
              <ArrowTriangle css={styles.dropdownIcon(stateKeys[id])} />
            </button>
          )}
        {childrens && childrens.length > 0 && isMobile && (
          <button
            css={[styles.dropdownBtn, styles.rightArrowBtn(true)]}
            onClick={e => {
              handleMouseEnter(e);
            }}
            aria-label="click to open submenu"
            id={id}
          ></button>
        )}
        {stateKeys[id] &&
          childrens &&
          childrens.length > 0 &&
          childrens.slice(0, 5) && (
            <div
              css={styles.dropdownContent(
                backgroundImg,
                stateKeys[id],
                isMobile,
                headerHeight
              )}
              className="scroller"
            >
              <div css={styles.dropdownMenu}>
                <ul
                  css={styles.grid(childrens.length > 5 ? 5 : childrens.length)}
                >
                  {childrens.slice(0, 5).map(menuItem => {
                    return (
                      <MenuCard
                        keysForChildren={keysForChildren}
                        id={id}
                        subTitleClicked={value => {
                          subTitleClicked(value);
                        }}
                        key={menuItem.id}
                        childIndex={menuItem.id}
                        isMobile={isMobile}
                        {...menuItem}
                      />
                    );
                  })}
                </ul>
                <ul css={styles.noChildGrid}>
                  {childrens &&
                    childrens.length > 5 &&
                    childrens
                      .slice(5, childrens.length)
                      .map((menuItem, index) => {
                        const isExternal = /^https?:\/\//i.test(menuItem.url);
                        const href = isExternal
                          ? menuItem.url
                          : `${EF_LOGO_HREF}${menuItem.url}`;
                        return (
                          <li key={menuItem.id} css={styles.noChildRow}>
                            <ExternalLink
                              id={id}
                              css={styles.subTitle}
                              href={href}
                              target={isExternal ? '_blank' : EMPTY_STRING}
                            >
                              {menuItem.title}
                            </ExternalLink>
                          </li>
                        );
                      })}
                </ul>
              </div>
              {stylingObjects && stylingFooter && !isMobile && (
                <NavMenuFooter stylingObjects={stylingObjects} />
              )}
            </div>
          )}
      </div>
    </li>
  );
};

export default MenuContent;
