/** @jsxImportSource @emotion/react */
import { useEffect, useRef } from 'react';

import { useFilterPanelToggleContext } from '../../context/filterPanelToggle';
import useScrollTop from '../../hooks/useScrollTop';
import FiltersPanel from '../FiltersPanel';
import FiltersToggle from '../FiltersToggle';
import Navigation from '../Navigation';
import useStyles from './styles';

const PageWithFilters = ({ children, pageName }) => {
  const pageRef = useRef();
  useScrollTop(pageRef);
  const styles = useStyles();
  const {
    isFilterPanelToggled,
    setFilterPanelToggle,
    isFilterEnabled,
    setFilterEnabledTab,
  } = useFilterPanelToggleContext();
  const filtersToggleBtnRef = useRef(null);

  useEffect(() => {
    filtersToggleBtnRef.current.focus();
  }, [isFilterPanelToggled]);

  return (
    <div className="spa-scroll-top-fix" ref={pageRef} tabIndex={-1}>
      <div css={styles.navigation}>
        <Navigation withBorder />
        <FiltersToggle
          isToggled={isFilterPanelToggled}
          onClick={() => {
            setFilterPanelToggle(!isFilterPanelToggled);
            setFilterEnabledTab(!isFilterEnabled);
          }}
          ref={filtersToggleBtnRef}
        />
      </div>
      <FiltersPanel
        page={pageName}
        isToggled={isFilterPanelToggled}
        onClose={() => {
          setFilterEnabledTab(false);
          setFilterPanelToggle(false);
        }}
      />
      {children}
      {isFilterPanelToggled && <div css={styles.overlay} />}
    </div>
  );
};

export default PageWithFilters;
