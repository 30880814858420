/** @jsxImportSource @emotion/react */

import { Fragment } from 'react';

import LeaguePosition from '../LeaguePosition';
import MatchDetails from '../MatchDetails';
import Text from '../Text';
import useStyles from './styles';

const TeamStatus = ({
  lastresults,
  standingspositions,
  title,
}) => {
  const styles = useStyles();

  return (
    <Fragment>
      <div css={styles.middle}>
        <div css={styles.leftContent}>
          <div css={styles.top}>
            <Text as="h2" size="5" css={styles.title}>
              {title} stats
            </Text>
          </div>
          {lastresults && <div css={styles.venueFacilitiesWrapper}>
            <MatchDetails isClubInfo={true} {...lastresults} />
          </div>
          }
          {standingspositions && <div css={styles.leagueFacilitiesWrapper}>
            <LeaguePosition isClubInfo={true} {...standingspositions} />
          </div>
          }
        </div>
      </div>
      <div css={styles.imgWrapper}>
        <div css={styles.imgContent}></div>
      </div>
    </Fragment>
  );
};

export default TeamStatus;
