export default dayNumber => {
  if (dayNumber > 3 && dayNumber < 21) {
    return 'th';
  }

  switch (dayNumber % 10) {
    case 1:
      return 'st';
    case 2:
      return 'nd';
    case 3:
      return 'rd';
    default:
      return 'th';
  }
};
