import { EMPTY_STRING } from '../../const';

const isAddressNullCheck = (value, separator) => {
  return value ? `${value.trim()}${separator || EMPTY_STRING}` : EMPTY_STRING;
};

const formatAddress = (address, separator = ', ') => {
  return `${isAddressNullCheck(
    address.AddressLine1 || address.AddressLine2,
    separator
  ).toLowerCase()}${isAddressNullCheck(address.AddressLine3, separator).toLowerCase()}${isAddressNullCheck(
    address.City || address.CityOrTown,
    separator
  ).toLowerCase()}${isAddressNullCheck(address.PostCode).toUpperCase()}`;
};

export default formatAddress;
