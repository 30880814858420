import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';
import publicPath from '../../utils/publicPath';

export default () => {
  const { colors, borderRadius } = useTheme();

  return {
    dropDownButton: css`
      border: 0;
      background-color: ${colors.white};
      color: ${colors.darkGrey};
      border-radius: ${borderRadius.m};
      width: 100%;
      padding: 10px 8px;
      justify-content: flex-start;
      text-align: left;

      &:hover,
      &:not(:disabled):hover {
        cursor: pointer;
        background-color: ${colors.white};
        color: ${colors.darkGrey};
      }
    `,
    arrow: isOptionsOpen => css`
      position: absolute;
      right: 0;
      top: 0;
      bottom: 0;
      padding: 5px 10px;
      text-align: center;
      border-radius: 0 ${borderRadius.s} ${borderRadius.s} 0;
      background-color: ${colors.blue};
      display: grid;
      color: ${colors.white};
      align-items: center;
      svg {
        transform: rotate(${isOptionsOpen ? '270deg' : '90deg'});
        > g {
          fill: ${colors.blue};
        }
      }
    `,
    container: css`
      position: relative;
      ul {
        border: 1px solid ${colors.lightGrey};
        list-style: none;
        padding: ${borderRadius.m} 0;
        position: absolute;
        width: 100%;

        li {
          padding: 6px 10px;
          color: ${colors.darkGrey};
          cursor: pointer;
          font-size: 0.875rem;

          &:active,
          &:focus,
          &:hover,
          &[aria-selected='true'] {
            background: ${colors.blue};
            color: ${colors.white};
          }
        }
      }
    `,
    list: css`
      background-color: ${colors.white};
    `,
    optionstyle: css`
      padding: 6px 10px;
      color: #5b6885;
      cursor: pointer;
      font-size: 0.875rem;
      &:active,
      &:focus,
      &:hover,
      &[aria-selected='true'] {
        background: ${colors.blue};
        color: ${colors.white};
      }
    `,
    selectWrapper: css`
      position: relative;
      select {
        color: ${colors.darkGrey};
        width: 100%;

        // supports in IOS versions only
        @supports (-webkit-touch-callout: none) {
          height: 33px;
          color: ${colors.darkGrey};
          background-color: ${colors.white};
        }
      }
    `,
    holder: css`
      position: absolute;
      display: inline-block;
      right: 0;
      top: 0;
      width: 50px;
      height: 32px;
      background: url(${publicPath('/images/common/dropdown.jpg')}) no-repeat
        right center;
      pointer-events: none;
    `,
  };
};
