import isEmpty from 'lodash.isempty';
import { useEffect, useRef, useState } from 'react';

import { getHeaderMenu } from '../../services';

const GetHeaderMenu = () => {
  const isMounted = useRef(true);
  const [menuList, setMenuList] = useState([]);
  const [dropDownKeys, setDropDownKeys] = useState({});
  const [stylingObjects, setStylingObjects] = useState();

  useEffect(() => {
    getHeaderMenu()
      .then(({ data }) => {
        if (isMounted.current && data) {
          const links = data.elements[0].fields.links;
          setStylingObjects(data.elements[0].fields.navigationFields);
          const formattedData = links[0].childrens.map((item, index) => {
            const childrenArray = [];
            item.childrens.map(child2 => {
              if (isEmpty(child2.childrens)) {
                childrenArray.push(child2);
                return { ...child2, childrenArray };
              }
              return child2;
            });
            return { ...item, ...{ id: index }, childrenArray };
          });
          setMenuList(formattedData);
        }
      })
      .catch(() => {});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (isEmpty(menuList)) {
      return;
    }
    // set keys for dropdown
    const keys = menuList.map(dropdown => dropdown.id);
    const object = keys.reduce((acc, curr) => {
      acc[curr] = false;
      return acc;
    }, {});
    setDropDownKeys({ ...object });
  }, [menuList]);

  return { menuList, dropDownKeys, stylingObjects };
};

export default GetHeaderMenu;
