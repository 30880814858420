const hex3Characters = /\w/g;
const hex6Characters = /\w\w/g;

export default (hex, alpha) => {
  const matches = hex.match(hex.length < 6 ? hex3Characters : hex6Characters);

  if (!matches) {
    return hex;
  }

  const [r, g, b] = matches.map((seg) =>
    parseInt(seg.repeat(2 / seg.length), 16)
  );

  return `rgba(${r},${g},${b},${alpha})`;
};
