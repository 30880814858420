import { AGE_GROUP_ID, MYSELF } from '../../../const';

export default [
  {
    id: '346dbfab-03fa-4a3a-ab18-b461d0d56dc8',
    questionOrder: 2,
    readableId: AGE_GROUP_ID,
    analyticsId: 'selected_age_group',
    type: 'age_group',
    title: {
      [MYSELF]: 'Which age group are you interested in?',
      Mychild: 'Which age group are you interested in?',
    },
    helpText:
      'Youth players need to compete within permitted age groups so that games are fair and fun.',
    helpTextSecondary:
      'Choose one age group so we can show you the right recommendations for you',
    answers: [
      {
        age: 5,
        options: ['U6', 'U7'],
      },
      {
        age: 6,
        options: ['U6', 'U7', 'U8'],
      },
      {
        age: 7,
        options: ['U7', 'U8', 'U9'],
      },
      {
        age: 8,
        options: ['U8', 'U9', 'U10'],
      },
      {
        age: 9,
        options: ['U9', 'U10', 'U11'],
      },
      {
        age: 10,
        options: ['U10', 'U11', 'U12'],
      },
      {
        age: 11,
        options: ['U11', 'U12', 'U13'],
      },
      {
        age: 12,
        options: ['U12', 'U13', 'U14'],
      },
      {
        age: 13,
        options: ['U13', 'U14', 'U15'],
      },
      {
        age: 14,
        options: ['U14', 'U15', 'U16'],
      },
      {
        age: 15,
        options: ['U15', 'U16', 'U17'],
      },
      {
        age: 16,
        options: ['U16', 'U17', 'U18', 'Open Aged'],
      },
      {
        age: 17,
        options: ['U17', 'U18', 'Open Aged'],
      },
      {
        age: 18,
        options: ['U18', 'Open Aged'],
      },
    ],
    ageRanges: [
      {
        min: 0,
        max: 0,
        nextQuestionId: 'd9572be5-72f2-42af-87ea-a3298c6eacd7',
      },
    ],
  },
];
