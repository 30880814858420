import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { utility, colors, spacing } = useTheme();

  return {
    linkBtn: css`
      ${utility.buttonDefault};
      display: block;
      color: ${colors.blue};
      text-decoration: underline;
      font-weight: 700;
      padding: ${spacing.xs} ${spacing.m};
      transition: color 0.5s;
    `,
  };
};
