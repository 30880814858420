import PropTypes from 'prop-types';

/** @jsxImportSource @emotion/react */
import { filtersPanelAnalytics } from '../../analyticsConsts';
import Button from '../Button';
import LinkButton from '../LinkButton';
import Text from '../Text';
import useStyles from './styles';

const {
  FILTERS_PANEL_UNSAVED_CHANGES,
  FILTERS_PANEL_UNSAVED_CHANGES_CTA_SAVE,
  FILTERS_PANEL_UNSAVED_CHANGES_CTA_EXIT,
  FILTERS_PANEL_UNSAVED_CHANGES_CTA_BACK,
} = filtersPanelAnalytics;

const FilterUnsavedPanel = ({ onSaveExit, onExit, onGoBack }) => {
  const styles = useStyles();

  return (
    <div id={FILTERS_PANEL_UNSAVED_CHANGES} css={styles.panel}>
      <div>
        <Text as="h2" size="5" css={styles.title}>
          You have unsaved changes to filters
        </Text>
        <Button
          primary
          id={FILTERS_PANEL_UNSAVED_CHANGES_CTA_SAVE}
          css={styles.btn}
          onClick={onSaveExit}
        >
          Save and exit
        </Button>
        <Button
          primary
          id={FILTERS_PANEL_UNSAVED_CHANGES_CTA_EXIT}
          css={styles.btn}
          onClick={onExit}
        >
          Exit without saving
        </Button>
        <LinkButton
          id={FILTERS_PANEL_UNSAVED_CHANGES_CTA_BACK}
          css={styles.linkBtn}
          onClick={onGoBack}
        >
          Go Back
        </LinkButton>
      </div>
    </div>
  );
};

FilterUnsavedPanel.prototype = {
  onSaveExit: PropTypes.func,
  onExit: PropTypes.func,
  onGoBack: PropTypes.func,
};

export default FilterUnsavedPanel;
