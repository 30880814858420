import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

export default (className = '', matchPathname) => {
  const { pathname } = useLocation();
  const matched = pathname === matchPathname;

  if (!matched) return;

  useEffect(() => {
    document.querySelector('body').classList.add(className);

    return () => {
      document.querySelector('body').classList.remove(className);
    };
  }, [className]);
};
