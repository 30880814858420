/** @jsxImportSource @emotion/react */
import PropTypes from 'prop-types';
import { Fragment, useEffect } from 'react';

import { useOnboardingDataContext } from '../../context/OnboardingData';
import useGeolocation, { LOADING_STATE } from '../../hooks/useGeolocation';
import getCategoryDataLayer from '../../utils/getCategoryDataLayer';
import getNextQuestionId from '../../utils/getNextQuestionId';
import Button from '../Button';
import Loader from '../Loader';
import NextNav from '../NextNav';
import useStyles from './styles';

const GeolocationQuestion = ({
  readableId,
  answers,
  onAnswer,
  selectedAge,
  headerTitle,
}) => {
  const styles = useStyles();
  const { geolocationData, getGeolocationData } = useGeolocation();
  const { coordinates, status, statusMessage } = geolocationData || {};

  const locationAnswer = answers[0];
  const postcodeAnswer = answers[1];
  const { OnboardingContext, setOnboardingDataContext } =
    useOnboardingDataContext();

  useEffect(() => {
    setOnboardingDataContext({
      ...OnboardingContext,
      title: locationAnswer.title,
      question: headerTitle,
      step: 3,
      location: coordinates,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [coordinates, headerTitle]);

  return (
    <Fragment>
      {answers && (
        <ul role="none">
          <li css={styles.answer}>
            {navigator.geolocation && (
              <Button
                id={locationAnswer.analyticsId}
                onClick={() => {
                  getGeolocationData();
                  getCategoryDataLayer({
                    eventAction: `Step ${OnboardingContext.step} - ${locationAnswer.title}`,
                    eventLabel: OnboardingContext.question,
                    eventparameter1: `${OnboardingContext.age}${
                      OnboardingContext.agegroup
                        ? ' , ' + OnboardingContext.agegroup
                        : ''
                    }`,
                    eventparameter2: OnboardingContext.disability,
                    gender_group: OnboardingContext.gender_group,
                    link_text: `${locationAnswer.title.toLowerCase()}`,
                    event_name: 'cta_select_option_click',
                  });
                }}
              >
                {locationAnswer.title}
              </Button>
            )}
          </li>
          <li css={styles.answer}>
            <Button
              id={postcodeAnswer.analyticsId}
              onClick={() => {
                onAnswer({
                  answerData: {
                    [readableId]: 'No',
                  },
                  nextQuestionId: getNextQuestionId({
                    ageRanges: postcodeAnswer.ageRanges,
                    selectedAge,
                  }),
                });
                getCategoryDataLayer({
                  eventAction: `Step ${OnboardingContext.step} - ${postcodeAnswer.title}`,
                  eventLabel: OnboardingContext.question,
                  eventparameter1: `${OnboardingContext.age}${
                    OnboardingContext.agegroup
                      ? ' , ' + OnboardingContext.agegroup
                      : ''
                  }`,
                  eventparameter2: OnboardingContext.disability,
                  gender_group: OnboardingContext.gender_group,
                  link_text: `${postcodeAnswer.title.toLowerCase()}`,
                  event_name: 'cta_select_option_click',
                });
              }}
            >
              {postcodeAnswer.title}
            </Button>
          </li>
        </ul>
      )}
      <div aria-live="polite">
        {status === LOADING_STATE ? (
          <Loader inline message={statusMessage} />
        ) : (
          statusMessage
        )}
      </div>
      <NextNav
        disabled={!coordinates}
        onClick={() => {
          onAnswer({
            answerData: {
              [readableId]: 'Yes',
              Latitude: coordinates.latitude,
              Longitude: coordinates.longitude,
            },
            nextQuestionId: getNextQuestionId({
              ageRanges: locationAnswer.ageRanges,
              selectedAge,
            }),
          });
          getCategoryDataLayer({
            eventAction: `Step ${OnboardingContext.step} - ${OnboardingContext.title}`,
            eventLabel: OnboardingContext.question,
            eventparameter1: `${OnboardingContext.age}${
              OnboardingContext.agegroup
                ? ' , ' + OnboardingContext.agegroup
                : ''
            }`,
            eventparameter2: OnboardingContext.disability,
            eventparameter3: OnboardingContext.location,
            gender_group: OnboardingContext.gender_group,
            location: OnboardingContext.location,
            event_name: 'next_button_click',
          });
        }}
      />
    </Fragment>
  );
};

GeolocationQuestion.prototype = {
  readableId: PropTypes.string,
  answers: PropTypes.object,
  onAnswer: PropTypes.func,
  selectedAge: PropTypes.string,
  headerTitle: PropTypes.string,
};

export default GeolocationQuestion;
