/** @jsxRuntime classic */
/** @jsx jsx */
import { jsx } from '@emotion/react';
import { Suspense, useEffect, useRef } from 'react';
import { createPortal } from 'react-dom';

import useKeyDown from '../../hooks/useKeyDown';
import useModalOverflow from '../../hooks/useModalOverflow';
import Loader from '../Loader';
import useStyles from './styles';

const Modal = ({ onClose, isOpen, skipInitialFocus, children }) => {
  const styles = useStyles();
  const modalRef = useRef(null);
  const overlayRef = useRef(null);

  useModalOverflow();

  useEffect(() => {
    if (!skipInitialFocus) {
      modalRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleTabKey = event => {
    const focusableElements = modalRef?.current?.querySelectorAll('input, button');

    const firstElement = focusableElements && focusableElements[0];
    const lastElement =
      focusableElements && focusableElements[focusableElements.length - 1];

    if (!event.shiftKey) {
      if (focusableElements[focusableElements.length - 1] === event.target) {
        event.preventDefault();
        firstElement.focus();
      }
    }
    /*redirect first shift+tab to last input*/
    else {
      if (focusableElements[0] === event.target) {
        event.preventDefault();
        lastElement.focus();
      }
      else{
        
      }
    }
  };

  useKeyDown('Escape', onClose);
  useKeyDown('Tab', handleTabKey);

  const modalRoot = document.getElementById('modal-root');

  return modalRoot
    ? createPortal(
      <div
        css={styles.overlay}
        role="dialog"
        aria-modal="true"
        aria-hidden={!isOpen}
        ref={overlayRef}
      >
        <div tabIndex={-1} ref={modalRef} css={styles.loader}>
          <Suspense fallback={<Loader />}>{children}</Suspense>
        </div>
      </div>,
      modalRoot
    )
    : null;
};

export default Modal;
