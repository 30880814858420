import { css } from '@emotion/react';

import useTheme from '../../hooks/useTheme';

export default () => {
  const { breakpoints, colors, spacing } = useTheme();

  return {
    base: css`
      height: 100%;
    `,
    header: css`
      margin-bottom: ${spacing.s};
    `,
    middle: css``,
    filterButtonWrapper: css`
      display: flex;
      justify-content: flex-end;

      // this is to make sure on really small
      // screens the title and button dont overlap
      @media screen and (min-width: 340px) {
        margin-bottom: -1.5rem;
      }
    `,
    button: css`
      border: 1px solid ${colors.blue};
      width: auto;
      background: none;
      color: ${colors.blue};
      text-transform: uppercase;

      &:not([disabled]):hover {
        border-color: ${colors.blue};
        background: ${colors.blue};
        color: ${colors.white};
      }

      &:focus {
        border-color: ${colors.blue};
        background: ${colors.white};
        color: ${colors.black};
        text-decoration: underline;
      }
    `,
    title: isClubInfo => css`
      display: inline-block;
      font-size: 1.75rem;
      color: ${colors.navy};
      text-decoration: underline ${colors.red} 2px;
      -webkit-text-decoration-line: underline;
      -webkit-text-decoration-color: ${colors.red};
      -webkit-text-decoration-style: solid;
      -webkit-text-decoration-thickness: 2px;

      ${!isClubInfo &&
      css`
        margin: ${spacing.s} 0;
      `}
      margin-bottom: ${spacing.xs};

      ${breakpoints.m} {
        font-size: ${spacing.l};
      }
    `,
    subtitle: css`
      display: block;
      color: ${colors.darkGrey};
    `,
    animateSession: css`
      opacity: 0;
      animation: fade_in_show 1.5s ease-in-out 0s forwards;

      @keyframes fade_in_show {
        from {
          opacity: 0;
          transform: translate3d(0, 40%, 0);
        }

        to {
          opacity: 1;
          transform: none;
        }
      }
    `,
  };
};
